import * as React from 'react';
import './container.css';
import AccountBalances from "../../Components/AccountBalances";
import PastDueLeases from "../../Components/PastDueLeases";
import MaintenanceRequestWidget from "../../Components/MaintenanceRequest/Widget/MaintenanceRequestWidget";
import TenantMessagesWidget from "../../Components/TenantMessages/Widget/TenantMessagesWidget";
import { getDashboardItems } from '../../Api/DashboardApi';
import {useContext, useEffect, useState} from 'react';
import {DashboardItemsDto} from '../../types';
import {PortfolioIdContext} from "../PrivateMainContainer";

const DashboardContainer: React.FunctionComponent = (p) => {

    const portfolioId = useContext(PortfolioIdContext)
    const [dashboardItems, setDashboardItems] = useState<DashboardItemsDto>();

    useEffect(() => {
        if (portfolioId !== "") {
            getDashboardItems()
                .then(setDashboardItems)
        }
    },[portfolioId]);

    function getDashboardContent() {
        if (dashboardItems) {
            return (
                <>
                    <div className={"row"}>
                        <div className={"col-6 mb-3"} hidden={!dashboardItems.pastDueLeases}>
                            {dashboardItems.pastDueLeases ? <PastDueLeases/> : <></>}
                        </div>
                        <div className={"col-6 mb-3"} hidden={!dashboardItems.tenantMessages}>
                            {dashboardItems.tenantMessages ? <TenantMessagesWidget/> : <></>}
                        </div>
                        <div className={"col-6 mb-3"} hidden={!dashboardItems.maintenanceRequest}>
                            {dashboardItems.maintenanceRequest ? <MaintenanceRequestWidget/> : <></>}
                        </div>
                        {/*<div className={"col-6"}>*/}
                        {/*    <InboundLeads/>*/}
                        {/*</div>*/}
                        {/*<div className={"col-6"}>*/}
                        {/*    <PendingApplications/>*/}
                        {/*</div>*/}
                    </div>
                </>
            )
        }
    }


    return (
        <>
            {getDashboardContent()}
        </>
    )
};

export default DashboardContainer;
