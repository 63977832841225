import * as React from 'react';
import {CSSProperties} from "react";

interface ButtonProps {
    type: ButtonTypes
    color: ButtonColors
    className?: string
    disabled?: boolean
    loading?: boolean
    label: string | JSX.Element
    loadingLabel?: string | JSX.Element
    style?: CSSProperties
    tabIndex?: number
    dataTarget?: string
    onClick?: React.MouseEventHandler
    onMouseDown?: React.MouseEventHandler
}

export enum ButtonTypes {
    SUBMIT="submit",
    RESET="reset",
    BUTTON="button"
}

export enum ButtonColors {
    LINK="btn-link",
    LIGHT="btn-light",
    DARK="btn-dark",
    PRIMARY="btn-primary",
    SECONDARY="btn-secondary",
    INFO="btn-info",
    WARNING="btn-warning",
    DANGER="btn-danger",
    SUCCESS="btn-success"
}

const Button: React.FunctionComponent<ButtonProps> = (p) => {
    return (<>
        <button
            disabled={p.disabled || p.loading}
            type={p.type}
            className={"d-flex btn " + p.color + " " + p.className}
            style={p.style}
            onClick={p.onClick}
            onMouseDown={p.onMouseDown}
            data-toggle={p.dataTarget ? "collapse" : undefined}
            data-target={p.dataTarget}
            aria-expanded={false}
            tabIndex={p.tabIndex}
        >
            <div className="d-flex justify-content-center align-items-center h-100 w-100">
                <span hidden={!p.loading} className="spinner-border spinner-border-sm me-2" role="status" aria-hidden={true} />
                <span hidden={!p.loading}>{p.loadingLabel}</span>
                <span hidden={p.loading}>{p.label}</span>
                {p.dataTarget && <span className="visually-hidden">Toggle Collapse</span>}
            </div>
        </button>
    </>);
};

export default Button;
