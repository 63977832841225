import { AUTH_CODE_EXPIRED_TOKEN, currentSession, doLogoutRedirect } from "./Auth";

export const CORE_API_ENDPOINT = (window.location.hostname === "localhost" && parseInt(window.location.port) >= 3000 && parseInt(window.location.port) < 4000)
    ? (window.location.protocol + '//' + window.location.hostname + ":8085/core/api")
    : (window.location.protocol + '//' + window.location.host + "/core/api");

export enum HttpMethod {
    GET = "GET", HEAD = "HEAD", POST = "POST", PUT = "PUT", PATCH = "PATCH", DELETE = "DELETE", OPTIONS = "OPTIONS", TRACE = "TRACE"
}

const regex = /profiles\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\/templates/g

export const serializeFilter = (obj: any) => {
    const str = [];
    for (const p in obj)
        if (obj.hasOwnProperty(p)) {
            if (obj[p] && obj[p].length > 0) {
                str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
            }
        }
    return str.join("&");
}



export const fetchWithAuth = (path: string, method: HttpMethod, payload?: string) => {
    return currentSession()
        .then((o) => {
                return fetch(CORE_API_ENDPOINT + path, {
                    mode: 'cors',
                    credentials: 'include',
                    headers: {
                        Authorization: 'Bearer ' + o.access_token,
                        'Content-Type': 'application/json',
                        Pragma: 'no-cache'
                    },
                    method: method,
                    body: payload
                })
                    .then(handleResponse, handleNetworkError)
            }
        )
        .catch((e) => {
            if (e.code === AUTH_CODE_EXPIRED_TOKEN) {
                //doLogoutRedirect();
            }
            throw e;
        })
}

export function handleResponse(response: Response) {
    if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType) {
            if (contentType.includes("application/json")) {
                return response.json()
            }
            if (contentType.includes("text")) {
                return response.text()
            }
        }
        return response;
    } else {
        return response.json().then(function (error) {
            throw error;
        });
    }
}

export function handleNetworkError(error: Error) {
    throw error;
}
