import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import AmenityDayTabs, {AmenityDayViews} from "../Tabs/AmenityDayTabs";
import {Property} from "../../../types";
import {getProperty} from "../../../Api/PropertyApi";
import LoadingOverlay from "../../LoadingOverlay";
import FormFieldBuilder from "../../Form/FieldBuilder/FormFieldBuilder";
import {AmenityDay, createAmenity} from "../../../Api/AmenityApi";
import {ApplyAll, CopyFrom} from "../View/AmenityDayView";
import {AmenityDayFormInterface} from "../Forms/AmenityDayForm";
import AmenityForm from "../Forms/AmenityForm";
import {assignFormFieldValues, copyAmenityDay, setAmenityDay} from "../Service/AmenityService";
import AddOrEditAmenityDay from "../AddOrEdit/AddOrEditAmenityDay";
import Alert, {AlertProps, AlertType} from "../../Alert";

const AddAmenity: React.FunctionComponent = () => {

    const { propertyId } = useParams();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [dayView, setDayView] = useState<AmenityDayViews>(AmenityDayViews.SUNDAY);
    const [property, setProperty] = useState<Property>();
    const [error, setError] = useState<Error>();
    const [alert, setAlert] = useState<AlertProps>();

    const [name, setName] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [sunday, setSunday] = useState<AmenityDay>();
    const [monday, setMonday] = useState<AmenityDay>();
    const [tuesday, setTuesday] = useState<AmenityDay>();
    const [wednesday, setWednesday] = useState<AmenityDay>();
    const [thursday, setThursday] = useState<AmenityDay>();
    const [friday, setFriday] = useState<AmenityDay>();
    const [saturday, setSaturday] = useState<AmenityDay>();

    assignFormFieldValues(AmenityForm.name, name, setName);
    assignFormFieldValues(AmenityForm.location, location, setLocation);
    assignFormFieldValues(AmenityForm.description, description, setDescription);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (sunday && monday && tuesday && wednesday && thursday && friday && saturday) {
            createAmenity(propertyId, {
                    name: name,
                    location: location,
                    description: description,
                    sunday: sunday,
                    monday: monday,
                    tuesday: tuesday,
                    wednesday: wednesday,
                    thursday: thursday,
                    friday: friday,
                    saturday: saturday
                }
            ).then(amenity => history.push({pathname: "/p/m/tenant-services/amenities/" + propertyId + "/manage/" + amenity.uuid}))
                .catch(setError);
        }
    }

    const onReset = (e: FormEvent) => {
        e.preventDefault();
        setName("");
        setLocation("");
        setDescription("");
        setSunday(undefined);
        setMonday(undefined);
        setTuesday(undefined);
        setWednesday(undefined);
        setThursday(undefined);
        setFriday(undefined);
        setSaturday(undefined);
        setRefresh(!refresh);
        setAlert({ type: AlertType.INFO, message: "Configuration has been reset" })
    }

    const copyFrom: CopyFrom = (from, form) => {
        switch (from) {
            case AmenityDayViews.SUNDAY: copyAmenityDay(sunday, form); break;
            case AmenityDayViews.MONDAY: copyAmenityDay(monday, form); break;
            case AmenityDayViews.TUESDAY: copyAmenityDay(tuesday, form); break;
            case AmenityDayViews.WEDNESDAY: copyAmenityDay(wednesday, form); break;
            case AmenityDayViews.THURSDAY: copyAmenityDay(thursday, form); break;
            case AmenityDayViews.FRIDAY: copyAmenityDay(friday, form); break;
            case AmenityDayViews.SATURDAY: copyAmenityDay(saturday, form); break;
        }
        setAlert({ type: AlertType.INFO, message: "Configuration copied from " + from + " to " + dayView})
    }

    const applyAll: ApplyAll = async (form: AmenityDayFormInterface) => {
        await setAmenityDay(sunday, form);
        await setAmenityDay(monday, form);
        await setAmenityDay(tuesday, form);
        await setAmenityDay(wednesday, form);
        await setAmenityDay(thursday, form);
        await setAmenityDay(friday, form);
        await setAmenityDay(saturday, form);
        setRefresh(!refresh);
        setAlert({ type: AlertType.INFO, message: "Configuration applied to all days from " + dayView })
    }

    useEffect(() => {
        setLoading(true);
        getProperty(propertyId)
            .then(setProperty)
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [propertyId]);

    return (loading ? <LoadingOverlay show={loading} /> : <>
        <div className="container-xxl m-0">
            <div className="row">
                <div className={"col-lg-12 mb-3 "}>
                    <h3>Add Amenity</h3>
                    <h5>{property?.name} </h5>
                    {property?.address.addressLine1}<br/>
                    {property?.address.addressLine2}<br hidden={(property?.address.addressLine2) !== undefined}/>
                    {property?.address.city}, {property?.address.stateCode} {property?.address.zip}
                </div>
            </div>
            <div className={"row form-container"}>
                <div className="col-lg-12">
                    <form onSubmit={onSubmit} onReset={onReset}>
                        <div className="row widget">
                            <div className="col-md-4 bg-light">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-12 mt-2 mb-2">
                                                <h5>Details</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-2">
                                                <FormFieldBuilder field={AmenityForm.name} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-2">
                                                <FormFieldBuilder field={AmenityForm.location} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-2">
                                                <FormFieldBuilder field={AmenityForm.description} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="btn-group mb-3 mt-3" role="btn-group">
                                                <button type="reset" className="btn btn-outline-primary">Reset</button>
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            {error &&
                                                <Alert type={AlertType.DANGER} message={error.message} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="row">
                                    <div className="col-md-12 bg-light mb-3">
                                        <AmenityDayTabs view={dayView} setView={setDayView} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div hidden={dayView !== AmenityDayViews.SUNDAY}>
                                            <AddOrEditAmenityDay form={AmenityForm.sunday} day={sunday} setDay={setSunday} copyFrom={copyFrom} applyAll={applyAll} refresh={refresh} />
                                        </div>
                                        <div hidden={dayView !== AmenityDayViews.MONDAY}>
                                            <AddOrEditAmenityDay form={AmenityForm.monday} day={monday} setDay={setMonday} copyFrom={copyFrom} applyAll={applyAll} refresh={refresh} />
                                        </div>
                                        <div hidden={dayView !== AmenityDayViews.TUESDAY}>
                                            <AddOrEditAmenityDay form={AmenityForm.tuesday} day={tuesday} setDay={setTuesday} copyFrom={copyFrom} applyAll={applyAll} refresh={refresh} />
                                        </div>
                                        <div hidden={dayView !== AmenityDayViews.WEDNESDAY}>
                                            <AddOrEditAmenityDay form={AmenityForm.wednesday} day={wednesday} setDay={setWednesday} copyFrom={copyFrom} applyAll={applyAll} refresh={refresh} />
                                        </div>
                                        <div hidden={dayView !== AmenityDayViews.THURSDAY}>
                                            <AddOrEditAmenityDay form={AmenityForm.thursday} day={thursday} setDay={setThursday} copyFrom={copyFrom} applyAll={applyAll} refresh={refresh} />
                                        </div>
                                        <div hidden={dayView !== AmenityDayViews.FRIDAY}>
                                            <AddOrEditAmenityDay form={AmenityForm.friday} day={friday} setDay={setFriday} copyFrom={copyFrom} applyAll={applyAll} refresh={refresh} />
                                        </div>
                                        <div hidden={dayView !== AmenityDayViews.SATURDAY}>
                                            <AddOrEditAmenityDay form={AmenityForm.saturday} day={saturday} setDay={setSaturday} copyFrom={copyFrom} applyAll={applyAll} refresh={refresh} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {alert &&
                                            <Alert type={alert.type} message={alert.message} />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>)

}

export default AddAmenity;
