import * as React from "react";
import {TenantPackage} from "../../../../Api/PackageManangementApi";
import FormFieldBuilder from "../../../Form/FieldBuilder/FormFieldBuilder";
import OverviewTenantPackageForm from "../../Forms/OverviewTenantPackageForm";
import moment from "moment/moment";

const OverviewTenantPackage: React.FunctionComponent<{tenantPackage: TenantPackage, unitName: string}> = ({tenantPackage, unitName}) => {

    OverviewTenantPackageForm.unitName.value = unitName;
    OverviewTenantPackageForm.addressedToName.value = tenantPackage.addressedToName;
    OverviewTenantPackageForm.description.value = tenantPackage?.description;
    OverviewTenantPackageForm.dateReceived.value = moment(tenantPackage.dateReceived).format("MM/DD/YYYY");
    OverviewTenantPackageForm.datePickedUp.value = tenantPackage.datePickedUp ? moment(tenantPackage.datePickedUp).format("MM/DD/YYYY") : '';
    OverviewTenantPackageForm.pickupInstructions.value = tenantPackage?.pickupInstructions;
    OverviewTenantPackageForm.pickupPersonName.value = tenantPackage?.pickupPersonName;

    return (<>
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <FormFieldBuilder field={OverviewTenantPackageForm.unitName} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormFieldBuilder field={OverviewTenantPackageForm.dateReceived} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <FormFieldBuilder field={OverviewTenantPackageForm.addressedToName} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <FormFieldBuilder field={OverviewTenantPackageForm.description} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <FormFieldBuilder field={OverviewTenantPackageForm.pickupInstructions} />
                    </div>
                    <div className="col-md-6 mb-3">
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <FormFieldBuilder field={OverviewTenantPackageForm.pickupPersonName} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <FormFieldBuilder field={OverviewTenantPackageForm.datePickedUp} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default OverviewTenantPackage;
