import * as React from "react";
import {useEffect, useState} from "react";
import {doActivateRedirect} from "../../Auth";
import {APP_PORTFOLIO_LANDING} from "../../types";
import {useParams} from "react-router";

const ActivateCallbackHandler: React.FunctionComponent = () => {

    const [doError, setDoError] = useState(false);
    const {userUUID} = useParams();

    useEffect(() => {
        doActivateRedirect(APP_PORTFOLIO_LANDING, userUUID);
    }, []);

    return (<>
        <h3 hidden={!doError} className={"text-center pt-3"}>There was an error processing your request.</h3>
    </>);
};

export default ActivateCallbackHandler;