import * as React from 'react';
import { FormEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router";
import { addRecurringTransactionToLease, getTransactionAccountsByLease } from "../../../../../Api/LeaseApi";
import FormFieldBuilder, { assignFormFieldValues, SelectOptions } from "../../../../Form/FieldBuilder/FormFieldBuilder";
import CreateRecurringTransactionForm from "../Form/CreateRecurringTransactionForm";
import Alert, { AlertType } from "../../../../Alert";
import Input from '../../../../Form/Input';
import { GenericInputRegex, formInputValidate } from '../../../../../types';

type CreateRecurringTransactionParam = {
    done: Function
}

const CreateRecurringTransaction: React.FunctionComponent<CreateRecurringTransactionParam> = (p) => {

    const { leaseId } = useParams();

    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [stopDate, setStopDate] = useState("");
    const [amount, setAmount] = useState("");
    const [dayOfMonth, setDayOfMonth] = useState("");
    const [type, setType] = useState("");
    const [accounts, setAccounts] = useState<SelectOptions[]>([]);
    const [description, setDescription] = useState("");
    const [error, setError] = useState("");
    const [endDateRequired, setEndDateRequired] = useState<boolean>(true);


    assignFormFieldValues({ field: CreateRecurringTransactionForm.startDate, value: startDate, setValue: setStartDate });
    assignFormFieldValues({
        field: CreateRecurringTransactionForm.description,
        value: description,
        setValue: setDescription
    });
    assignFormFieldValues({
        field: CreateRecurringTransactionForm.dayOfMonth,
        value: dayOfMonth,
        setValue: setDayOfMonth
    });
    assignFormFieldValues({ field: CreateRecurringTransactionForm.type, value: type, setValue: setType });
    assignFormFieldValues({ field: CreateRecurringTransactionForm.amount, value: amount, setValue: setAmount });

    useEffect(() => {
        if (leaseId) {
            getTransactionAccountsByLease(leaseId)
                .then((az) => {
                    CreateRecurringTransactionForm.type.defaultSelectOptions?.forEach(d => {
                        accounts.push({
                            value: d.value,
                            label: d.label,
                            disabled: d.disabled
                        })
                    });
                    az.forEach(ta => {
                        accounts.push({
                            value: ta.uuid,
                            label: ta.name,
                            disabled: false
                        })
                    })
                    setAccounts([...accounts]);
                })
                .catch((e) => console.error(e));
        }
    }, [leaseId])


    const clear = () => {
        setStartDate("");
        setStopDate("");
        setAmount("");
        setDayOfMonth("");
        setType("");
        setDescription("");
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError("");
        addRecurringTransactionToLease(leaseId, {
            startDate: startDate,
            endDate: stopDate,
            amount: parseFloat(amount),
            dayOfMonth: dayOfMonth,
            description: description,
            accountUuid: type
        })
            .then((a) => {
                clear();
                p.done();
            })
            .catch((e) => setError(e.message))
            .finally(() => setLoading(false));


    }
    return ((accounts.length > 0) ? <>
        <form onSubmit={onSubmit}>
            <h5>Add Recurring Transaction</h5>
            <div className="row">
                <div className="col-lg-8">
                    <div className="row">
                        <div className={"col-md-6 mb-1"}>
                            <FormFieldBuilder field={CreateRecurringTransactionForm.description} />
                        </div>
                        <div className={"col-md-6 mb-1"}>
                            <FormFieldBuilder field={CreateRecurringTransactionForm.amount} />
                        </div>

                        <div className={"col-md-6 mb-1"}>
                            <FormFieldBuilder field={CreateRecurringTransactionForm.startDate} />
                        </div>
                        <div className={"col-md-6 mb-1"}>
                            <FormFieldBuilder field={CreateRecurringTransactionForm.type}
                                selectOptions={accounts} />
                        </div>
                        <div className={"col-md-6 mb-1"}>
                            <Input id={"stopDate"}
                                       type={"date"}
                                       label={"Stop Date"}
                                       required={false}
                                       disabled={!endDateRequired}
                                       invalidFeedbackText={GenericInputRegex.helpText}
                                       validation={GenericInputRegex.expression}
                                       doValidate={formInputValidate}
                                       value={stopDate}
                                       setValue={setStopDate}
                                />
                        </div>
                        <div className={"col-md-6 mb-1"}>
                            <div className="form-check" style={{marginTop:28}}>
                                <input className="form-check-input" type="checkbox"
                                    id="defaultCheck1"
                                    checked={!endDateRequired.valueOf()}
                                    disabled={false}
                                    value={String(endDateRequired)}
                                    onChange={e => {
                                        let value = e.target.checked;
                                        setEndDateRequired(!endDateRequired);
                                        if (value) {
                                            setStopDate("");
                                        }
                                    }} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                    No Stop Date
                                </label>
                            </div>
                        </div>

                        

                        <div className={"col-md-6 mb-1"}>
                            <FormFieldBuilder field={CreateRecurringTransactionForm.dayOfMonth} />
                            <p>The transaction should occur at least 7 days before the lease due date.</p>
                        </div>
                        <div className="d-flex justify-content-start gap-2 mt-3">
                            <button type={"submit"} className={"btn btn-danger"}
                                disabled={loading}
                                onClick={(e) => {
                                    p.done();
                                }}>
                                Cancel
                            </button>
                            <button type={"submit"} className={"btn btn-primary me-3"}
                                disabled={loading}>
                                Add
                            </button>
                        </div>
                        {error && <Alert type={AlertType.DANGER} message={error} />}
                    </div>
                </div>
            </div>
        </form>
    </> : <></>);
};

export default CreateRecurringTransaction;
