import React, {FormEvent, useEffect, useState} from "react";
import PageLayout from "../../../Layouts/PageLayout";
import {getReconcile, reconcileCCMPayment, reconcilePayout} from "../../../Api/PortfolioApi";
import {getPortfolioId} from "../../../helpers";
import {DateRegex, formInputValidate, ReconcileDto} from "../../../types";
import {Link} from "react-router-dom";
import Input from "../../../Components/Form/Input";
import {useLocation} from "react-router";

const ViewReconcile: React.FunctionComponent = () => {
    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    const [reconcile, setReconcile] = useState<ReconcileDto>();
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingReconcile, setLoadingReconcile] = useState(false);
    const [selectedCCMPayment, setSelectedCCMPayment] = useState("");
    const [postedDate, setPostedDate] = useState("")


    function load() {
        setLoading(true);
        getReconcile(getPortfolioId())
            .then(r => {
                setReconcile(r);
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        load();
    }, []);


    function markPayoutAsPosted(id: string) {
        setLoadingReconcile(true);
        reconcilePayout(getPortfolioId(), id, {
            date: postedDate
        })
            .then(r => {
                load();
                setPostedDate("")
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoadingReconcile(false));
    }

    function markPaymentAsPosted(id: string) {
        setSelectedCCMPayment(id);
        reconcileCCMPayment(getPortfolioId(), id)
            .then(r => {
                load();
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setSelectedCCMPayment(""));
    }


    return <PageLayout title={"Pending ACH Transactions"}>
        {loading && <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>}
        {errorMessage && <div className="alert alert-danger" role="alert">
            {errorMessage}
        </div>}

        <div className={"row"}>
            <div className={"col-md-12"}>
                <p>The following payments are pending and will be batched into a payout shortly.</p>
                <table className="table table-sm">
                    <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Method</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Lease</th>
                        <th scope="col">Property</th>
                        <th scope="col">Unit</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        reconcile?.pendingAchPayments.map(p => (
                            <tr key={p.uuid}>
                                <td>{p.transactionDate}</td>
                                <td>{p.method}</td>
                                <td>{currencyFormat.format(p.amount)}</td>
                                <td><Link
                                    to={"/p/m/customers/leases/" + getPortfolioId() + "/view/" + p.lease.uuid}>{p.lease.externalId}</Link>
                                </td>
                                <td>{p.property.name}</td>
                                <td>{p.property.unitName}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>

    </PageLayout>
}

export default ViewReconcile;