import {BankAccount} from "../types";
import {fetchWithAuth, HttpMethod} from "../api";
import {getPortfolioId} from "../helpers";

export interface Amenity {
    uuid?: string,
    propertyId?: string,
    name: string,
    description: string,
    location: string,
    sunday: AmenityDay,
    monday: AmenityDay,
    tuesday: AmenityDay,
    wednesday: AmenityDay,
    thursday: AmenityDay,
    friday: AmenityDay,
    saturday: AmenityDay
    reservations?: AmenityReservation[]
}

export interface AmenityDay {
    open: boolean,
    openTime: SimpleTime,
    closeTime: SimpleTime,
    minReservationMinutes: number,
    maxReservationMinutes: number,
    timeslotBlockMinutes: number,
    cancelBeforeMinute: number,
    basePrice: number,
    pricePerMinute: number
}

export interface AmenityReservation {
    uuid: string,
    accountId: string,
    date: string,
    startTime: SimpleTime,
    endTime: SimpleTime,
    status: AmenityReservationStatus
}

export interface CreateAmenityReservation {
    accountId: string,
    amenityId: string,
    date: string,
    startTime: SimpleTime,
    endTime: SimpleTime,
    bankAccount: BankAccount
}

export interface SimpleTime {
    hour: number,
    minute: number
}

export enum AmenityReservationStatus {
    RESERVED="RESERVED",IN_PROGRESS="IN_PROGRESS",COMPLETED="COMPLETED",UNKNOWN=""
}

export enum AmenityIsOpen {
    YES="Yes",NO="No"
}

type getAmenities = (propertyId: string) => Promise<Amenity[]>;
type getAmenity = (propertyId: string, amenityId: string) => Promise<Amenity>;
type createAmenity = (propertyId: string, amenity: Amenity) => Promise<Amenity>;
type updateAmenity = (propertyId: string, amenity: Amenity) => Promise<Amenity>;
type deleteAmenity = (propertyId: string, amenityId: string) => Promise<void>;
type createAmenityReservation = (propertyId: string, reservation: CreateAmenityReservation) => Promise<Amenity>;

const AMENITY_API_URL = (propertyId: string, pathExtention = "") => {
    return "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/amenities" + pathExtention;
}

export const getAmenities: getAmenities = (async propertyId => {
    return fetchWithAuth(AMENITY_API_URL(propertyId), HttpMethod.GET);
});

export const getAmenity: getAmenity = (async (propertyId, amenityId) => {
    return fetchWithAuth(AMENITY_API_URL(propertyId, "/" + amenityId), HttpMethod.GET);
});

export const createAmenity: createAmenity = (async (propertyId, amenity) => {
    return fetchWithAuth(AMENITY_API_URL(propertyId), HttpMethod.POST, JSON.stringify(amenity));
});

export const updateAmenity: updateAmenity = (async (propertyId, amenity) => {
    return fetchWithAuth(AMENITY_API_URL(propertyId, "/" + amenity.uuid), HttpMethod.PUT, JSON.stringify(amenity));
});

export const deleteAmenity: deleteAmenity = (async (propertyId, amenityId) => {
    return fetchWithAuth(AMENITY_API_URL(propertyId, "/" + amenityId), HttpMethod.DELETE);
});

export const createAmenityReservation: createAmenityReservation = (async (propertyId, reservation) => {
    return fetchWithAuth(AMENITY_API_URL(propertyId, "/" + reservation.amenityId + "/reservations"), HttpMethod.POST, JSON.stringify(reservation));
});
