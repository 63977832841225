import * as React from "react";
import {BootstrapValidateClass, FormElementValidation} from "../../../types";
import Input from "../Input";
import File from "../File";
import Select from "../Select";
import TextArea from "../TextArea";
import Date from "../Date";
import {v4} from "uuid";
import Range from "../Range/Range";
import TimeLength from "../TimeLength/TimeLength";
import Password from "../Password/Password";
import Checkbox from "../Checkbox/Checkbox";
import StateSelect from "../../StateSelect";
import Color from "../Color";
import Datalist from "../Datalist";
import { useEffect, useState } from "react";
import { InputType } from "../Input";

export interface FormBuilderField {
    type: FormFieldType,
    element: FormElementValidation,
    label: string,
    value: any,
    setValue: Function,
    placeholder?: string,
    disableStyling?: boolean,
    disabled?: boolean | false
    readonly?: boolean | false
    inputFieldType?: InputFieldType,
    defaultSelectOptions?: SelectOptions[],
    textAreaRows?: number,
    step?: number | string,
    min?: number | string,
    max?: number | string,
    minLength?: number,
    maxLength?: number,
    accept?: string,
    loading?: boolean,
    maxSize?: number,
    hasError?: boolean,
    hasErrorText?: string,
}

export interface SelectOptions {
    value: any,
    label: string,
    disabled?: boolean | false
}

export enum FormFieldType {
    INPUT = "INPUT",
    TEXT_AREA = "TEXT_AREA",
    SELECT = "SELECT",
    STATE_SELECT = "STATE_SELECT",
    DATE = "DATE",
    TIME_LENGTH = "TIME_LENGTH",
    RANGE = "RANGE",
    PASSWORD = "PASSWORD",
    CHECKBOX = "CHECKBOX",
    FILE = "FILE",
    COLOR = "COLOR",
    DATA_LIST = "DATA_LIST",
    TEXT = "TEXT",
    EMAIL = "EMAIL",
    NUMBER = "NUMBER"
}

export enum InputFieldType {
    TEXT = "text",
    DATE = "date",
    NUMBER = "number",
    TIME = "time",
}

export interface AssignFormFieldProps {
    field: FormBuilderField,
    value: any,
    setValue?: Function,
    typeOverride?: FormFieldType,
    inputTypeOverride?: InputFieldType,
    disabledOverride?: boolean,
    requiredOverride?: boolean,
    loading?: boolean,
    hasError?: boolean,
    hasErrorText?: string
}

export interface FormOption {
    label: string,
    value?: any,
    disabled?: boolean,
    bgColor?: string
}

export type AssignFormFieldValues = (props: AssignFormFieldProps) => void;


export const assignFormFieldValues: AssignFormFieldValues = (props) => {
    props.field.value = props.value;
    props.field.setValue = props.setValue ? props.setValue : () => {
    };
    if (props.typeOverride !== undefined) {
        props.field.type = props.typeOverride;
    }
    if (props.inputTypeOverride !== undefined) {
        props.field.inputFieldType = props.inputTypeOverride;
    }
    if (props.requiredOverride !== undefined) {
        props.field.element.required = props.requiredOverride;
    }
    if (props.disabledOverride !== undefined) {
        props.field.disabled = props.disabledOverride;
    }
    if (props.loading !== undefined) {
        props.field.loading = props.loading;
    }
    if (props.hasError !== undefined) {
        props.field.hasError = props.hasError;
    }
    if (props.hasErrorText !== undefined) {
        props.field.hasErrorText = props.hasErrorText;
    }
}

export const deepCopySelectOptions = (field: FormBuilderField): SelectOptions[] => {
    const options: SelectOptions[] = [];
    field.defaultSelectOptions?.forEach(o => {
        options.push({value: o.value, label: o.label, disabled: o.disabled});
    })
    return options;
}


const FormFieldBuilder: React.FunctionComponent<{ field: FormBuilderField, selectOptions?: SelectOptions[], className?:string }> = ({field, selectOptions, className }) => {
    const [validateClass, setValidateClass] = useState(BootstrapValidateClass.unvalidated);
    useEffect(() => {
        if (field.disableStyling || field.value === undefined || field.value.length === 0 || field.disabled || typeof field.value === "boolean") {
            setValidateClass(BootstrapValidateClass.unvalidated);
        }
        else if (field.element.validate(field.value, field.element.required, field.element.regex.expression)) {
            setValidateClass(BootstrapValidateClass.isvalid);
        }
        else {
            setValidateClass(BootstrapValidateClass.isinvalid);
        }
    }, [field.value, field.disabled, field.element])
    switch (field.type) {
        case FormFieldType.FILE:
            return (
                <File key={field.element.id}
                      id={field.element.id}
                      validation={field.element.regex.expression}
                      doValidate={field.element.validate}
                      value={field.value || ''}
                      setValue={field.setValue ? field.setValue : () => {
                      }}
                      required={field.element.required}
                      label={field.label}
                      disabled={field.disabled}
                      accept={field.accept || ''}
                      loading={field.loading || false}
                      maxSize={field.maxSize}
                      hasError={field.hasError}
                      hasErrorText={field.hasErrorText}/>
            )
        case FormFieldType.COLOR:
            return (
                <Color key={field.element.id}
                       id={field.element.id}
                       validation={field.element.regex.expression}
                       doValidate={field.element.validate}
                       value={field.value || ''}
                       setValue={field.setValue ? field.setValue : () => {
                       }}
                       required={field.element.required}
                       label={field.label}
                       disabled={field.disabled}/>
            )
        case FormFieldType.INPUT:
            return (
                <Input
                    key={field.element.id}
                    id={field.element.id}
                    validation={field.element.regex.expression}
                    doValidate={field.element.validate}
                    value={field.value || ''}
                    setValue={field.setValue ? field.setValue : () => {
                    }}
                    required={field.element.required}
                    invalidFeedbackText={field.element.regex.helpText}
                    type={field.inputFieldType || InputFieldType.TEXT}
                    label={field.label}
                    step={field.step}
                    min={field.min}
                    disabled={field.disabled}
                    placeholder={field.placeholder}
                />
            )
        case FormFieldType.SELECT:
            return (
                <Select
                    key={field.element.id}
                    id={field.element.id}
                    validation={field.element.regex.expression}
                    doValidate={field.element.validate}
                    value={field.value}
                    setValue={field.setValue ? field.setValue : () => {
                    }}
                    required={field.element.required}
                    invalidFeedbackText={field.element.regex.helpText}
                    label={field.label}
                    disabled={field.disabled}
                >
                    {selectOptions?.map((option) => {
                        return <option
                            key={field.element.id + "_" + option.value + "_" + v4()}
                            value={option.value}
                            disabled={option.disabled}
                        >
                            {option.label}
                        </option>
                    })}
                </Select>
            )
        case FormFieldType.STATE_SELECT:
            return (
                <StateSelect
                    id={field.element.id}
                    validation={field.element.regex.expression}
                    noSelectionText={"Select a State..."}
                    doValidate={field.element.validate}
                    value={field.value || ''}
                    setValue={field.setValue ? field.setValue : () => {
                    }}
                    required={field.element.required}
                    invalidFeedbackText={field.element.regex.helpText}
                    label={field.label}
                    disabled={field.disabled}
                />
            )
        case FormFieldType.TEXT_AREA:
            return (
                <TextArea
                    key={field.element.id}
                    id={field.element.id}
                    validation={field.element.regex.expression}
                    doValidate={field.element.validate}
                    value={field.value || ''}
                    setValue={field.setValue ? field.setValue : () => {
                    }}
                    required={field.element.required}
                    invalidFeedbackText={field.element.regex.helpText}
                    label={field.label}
                    disabled={field.disabled}
                    rows={field.textAreaRows || 3}
                    placeholder={field.placeholder}
                />
            )
        case FormFieldType.DATE:
            return (
                <Date
                    key={field.element.id}
                    id={field.element.id}
                    validation={field.element.regex.expression}
                    doValidate={field.element.validate}
                    value={field.value || ''}
                    setValue={field.setValue ? field.setValue : () => {
                    }}
                    required={field.element.required}
                    invalidFeedbackText={field.element.regex.helpText}
                    label={field.label}
                    disabled={field.disabled}
                    placeholder={field.placeholder}
                />
            )
        case FormFieldType.TIME_LENGTH:
            return (
                <TimeLength
                    key={field.element.id}
                    id={field.element.id}
                    validation={field.element.regex.expression}
                    doValidate={field.element.validate}
                    value={field.value || ''}
                    setValue={field.setValue ? field.setValue : () => {
                    }}
                    required={field.element.required}
                    invalidFeedbackText={field.element.regex.helpText}
                    label={field.label}
                    step={field.step}
                    disabled={field.disabled}
                    placeholder={field.placeholder}
                />
            )
        case FormFieldType.RANGE:
            return (
                <Range
                    key={field.element.id}
                    id={field.element.id}
                    validation={field.element.regex.expression}
                    doValidate={field.element.validate}
                    value={field.value || ''}
                    setValue={field.setValue ? field.setValue : () => {
                    }}
                    required={field.element.required}
                    invalidFeedbackText={field.element.regex.helpText}
                    label={field.label}
                    disabled={field.disabled}
                    step={field.step}
                    min={field.min}
                    max={field.max}
                    placeholder={field.placeholder}
                />
            )
        case FormFieldType.PASSWORD:
            return (
                <Password
                    key={field.element.id}
                    id={field.element.id}
                    validation={field.element.regex.expression}
                    doValidate={field.element.validate}
                    value={field.value || ''}
                    setValue={field.setValue ? field.setValue : () => {
                    }}
                    required={field.element.required}
                    maxLength={Number(field.max)}
                    minLength={Number(field.min)}
                    invalidFeedbackText={field.element.regex.helpText}
                    label={field.label}
                    placeholder={field.placeholder}
                    disabled={field.disabled}
                />
            )
        case FormFieldType.CHECKBOX:
            return (
                <Checkbox
                    key={field.element.id}
                    id={field.element.id}
                    validateClass={''}
                    value={field.value || ''}
                    setValue={field.setValue ? field.setValue : () => {
                    }}
                    required={field.element.required}
                    invalidFeedbackText={field.element.regex.helpText}
                    label={field.label}
                    disabled={field.disabled}
                />
            )
            case FormFieldType.DATA_LIST:
            return (
                <Datalist
                    key={field.element.id}
                    id={field.element.id}
                    validateClass={validateClass}
                    value={field.value || ''}
                    setValue={field.setValue ? field.setValue : () => {}}
                    required={field.element.required}
                    invalidFeedbackText={field.element.regex.helpText}
                    placeholder={field.placeholder}
                    label={field.label}
                    disabled={field.disabled}
                    type={InputType.PHONE}
                    minLength={field.minLength}
                    maxLength={field.maxLength}
                    options={selectOptions ? selectOptions : []}
                    className={className}
                />
            )
            case FormFieldType.TEXT:
                return (
                    <Input
                    key={field.element.id}
                    id={field.element.id}
                    validation={field.element.regex.expression}
                    doValidate={field.element.validate}
                    value={field.value || ''}
                    setValue={field.setValue ? field.setValue : () => {}}
                    required={field.element.required}
                    invalidFeedbackText={field.element.regex.helpText}
                    placeholder={field.placeholder}
                    label={field.label}
                    disabled={field.disabled}
                    type={InputType.TEXT}
                    minLength={field.minLength}
                    maxLength={field.maxLength}
                    />
                )
                case FormFieldType.EMAIL:
            return (
                <Input
                    key={field.element.id}
                    id={field.element.id}
                    validation={field.element.regex.expression}
                    doValidate={field.element.validate}
                    value={field.value || ''}
                    setValue={field.setValue ? field.setValue : () => {}}
                    required={field.element.required}
                    invalidFeedbackText={field.element.regex.helpText}
                    placeholder={field.placeholder}
                    label={field.label}
                    disabled={field.disabled}
                    type={InputType.EMAIL}
                    minLength={field.minLength}
                    maxLength={field.maxLength}
                />
            )
            case FormFieldType.NUMBER:
            return (
                <Input
                    key={field.element.id}
                    id={field.element.id}
                    validation={field.element.regex.expression}
                    doValidate={field.element.validate}
                    value={field.value || ''}
                    setValue={field.setValue ? field.setValue : () => {}}
                    required={field.element.required}
                    invalidFeedbackText={field.element.regex.helpText}
                    placeholder={field.placeholder}
                    label={field.label}
                    disabled={field.disabled}
                    type={InputType.NUMBER}
                    step={field.step}
                    min={field.min}
                    max={field.max}
                />
            )
    }

    
};

export default FormFieldBuilder;
