import * as React from "react";
import {Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import ViewConversations from "./View/ViewConversations";
import AutoSelectProperty from "../Form/SelectProperty/AutoSelectProperty";

const TenantMessagesRouter: React.FunctionComponent = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <AutoSelectProperty name={"Tenant Messages"}/>
            </Route>
            <Route exact path={path + "/:propertyId"}>
                <ViewConversations />
            </Route>
            <Route path={path + "/:propertyId/:conversationId"}>
                <ViewConversations />
            </Route>
        </Switch>
    )
}

export default TenantMessagesRouter;
