import React from "react";
import { Link } from "react-router-dom";
import { useLocation, useRouteMatch } from "react-router";
import ViewPropertyInfo from "../../Components/PropertyInfo/ViewPropertyInfo";
import { Property } from "../../types";
import Alert, { AlertProps } from "../../Components/Alert";

interface Props {
    title: string
    addButtonLabel?: string
    addButtonPath?: string
    addInviteLabel?: string
    addInvitePath?: string
    property?: Property
    propertyId?: string
    alert?: AlertProps
    applicationPage?: boolean,
    hidePropertyInfo?: boolean
}

const PageLayout: React.FunctionComponent<Props> = (p) => {

    const location = useLocation()

    function getButtonContent() {
        if (p.applicationPage) {
            return <>
                <div>
                    {p.addInviteLabel &&
                        <Link to={p.addInvitePath ? p.addInvitePath : (location.pathname + "/invite")}>
                            <button title="Test hover context" type="button" className="btn btn-secondary m-0">{p.addInviteLabel}</button>
                        </Link>
                    }

                    {p.addButtonLabel &&
                        <Link to={p.addButtonPath ? p.addButtonPath : (location.pathname + "/add")}>
                            <button type="button" className="btn btn-primary m-0">{p.addButtonLabel}</button>
                        </Link>
                    }
                </div>
            </>
        } else {
            return <>
                <div className="justify-content-left">
                    {p.addButtonLabel &&
                        <Link to={p.addButtonPath ? p.addButtonPath : (location.pathname + "/add")}>
                            <button type="button" className="btn btn-primary m-0">{p.addButtonLabel}</button>
                        </Link>
                    }
                </div>
            </>
        }
    }

    return <>
        <div className="mb-5 position-relative">
            <div className="d-flex justify-content-between align-items-center">
                {p.title && <h3>{p.title}</h3>}
                {getButtonContent()}
            </div>
            {p.property && <span hidden={p.hidePropertyInfo}>
                <ViewPropertyInfo property={p.property} />
            </span>}
            {p.propertyId && <span hidden={p.hidePropertyInfo}>
                <div className="mt-4">
                    <ViewPropertyInfo />
                </div>
            </span>}
            {p.title && <hr />}
            {p.alert &&
                <div className="">
                    <Alert type={p.alert.type} message={p.alert.message} />
                </div>
            }
            {p.children}
        </div>
    </>
}

export default PageLayout