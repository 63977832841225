import * as React from 'react';

import Input from "../../../Form/Input";
import {
    BootstrapValidateClass,
    DollarAmountRegex,
    formInputValidate, formSelectValidate,
    GenericInputRegex, GenericNumberRegex,
    GenericSelectRegex,
    TransactionAccount, USAccountNameRegex, USBankAccountNumberRegex, USBankRoutingNumberRegex
} from "../../../../types";
import Select from "../../../Form/Select";
import {FormEvent, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import DateInput from "../../../Form/Date";
import {addTransactionToLease, getTransactionAccountsByLease} from "../../../../Api/LeaseApi";
import {getIdempotentPaymentKey} from "../../../../Api/PaymentApi";
import Card from "../../../Card";
import Alert, {AlertType} from "../../../Alert";

type CreateTransactionParam = {
    done: Function
}

const CreateTransaction: React.FunctionComponent<CreateTransactionParam> = (p) => {
    const {leaseId} = useParams();
    const [transactionDate, setTransactionDate] = useState("");
    const [transactionDescription, setTransactionDescription] = useState("");
    const [transactionAmount, setTransactionAmount] = useState("");
    const [account, setAccount] = useState("");
    const [accounts, setAccounts] = useState<TransactionAccount[]>([]);

    const [paymentMethod, setPaymentMethod] = useState("");
    const [accountHolderName, setAccountHolderName] = useState("");
    const [accountType, setAccountType] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");
    const [checkSerialNumber, setCheckSerialNumber] = useState("");

    const [verfiyAccountNumber, setVerifyAccountNumber] = useState("");
    const [accountNumbersMatch, setAccountNumbersMatch] = useState(BootstrapValidateClass.unvalidated)

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const clear = () => {
        setTransactionAmount("");
        setTransactionDate("");
        setTransactionDescription("");
        setAccount("");
        setPaymentMethod("");
        setAccountHolderName("");
        setAccountType("");
        setAccountNumber("");
        setRoutingNumber("");
        setCheckSerialNumber("");
        setVerifyAccountNumber("");
    }

    useEffect(() => {
        if (leaseId) {
            getTransactionAccountsByLease(leaseId)
                .then((az) => {
                    setAccounts(az);
                })
                .catch((e) => console.error(e));
        }
    }, [leaseId])

    useEffect(() => {
        if (accountNumber && verfiyAccountNumber && accountNumber === verfiyAccountNumber) {
            setAccountNumbersMatch(BootstrapValidateClass.isvalid);
        } else if (!verfiyAccountNumber) {
            setAccountNumbersMatch(BootstrapValidateClass.unvalidated)
        } else {
            setAccountNumbersMatch(BootstrapValidateClass.isinvalid);
        }
    }, [accountNumber, verfiyAccountNumber])

    function getPaymentType() {
        if(paymentMethod && paymentMethod === "MANUAL") {
            return "rent-payment";
        }
        if(paymentMethod && paymentMethod === "MANUAL_SECURITY_DEPOSIT") {
            return "security-deposit-payment";
        }
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (account !== "payment"
            || (account === "payment" && paymentMethod.startsWith("MANUAL"))
            || (account === "payment" && verfiyAccountNumber && accountNumbersMatch === BootstrapValidateClass.isvalid)) {
            setError("")
            getIdempotentPaymentKey()
                .then((i) => {
                    setLoading(true);
                    addTransactionToLease(leaseId, {
                        accountId: (account === "payment") ? undefined : account,
                        amount: parseFloat(transactionAmount),
                        date: transactionDate,
                        description: transactionDescription,
                        type: getPaymentType(),
                        payment: (account !== "payment" || paymentMethod.startsWith("MANUAL")) ? undefined : {
                            idempotentKey: i.key,
                            method: paymentMethod,
                            amount: parseFloat(transactionAmount),
                            signature: "signature on file",
                            bankAccount: {
                                accountHolderName: accountHolderName,
                                accountType: accountType,
                                accountNumber: accountNumber,
                                routingNumber: routingNumber
                            },
                            checkSerialNumber: checkSerialNumber
                        }

                    })
                        .then((r) => {
                            if (r.ok) {
                                clear();
                                p.done()
                            }
                        })
                        .catch((e) => setError(e.message))
                        .finally(() => setLoading(false));
                })
                .catch((e) => setError(e.message))
        } else {
            setError("Issue with Account Number")
        }

    }

    function getAccountVerify() {
        if (paymentMethod && !paymentMethod.startsWith("MANUAL")) {
            return <div className="col-lg-6 mb-1">
                <label htmlFor="verifyAccountNumber">
                    <span>Confirm Account Number</span>
                    <span style={{paddingLeft: 5, color: "var(--bs-danger)"}}>*</span>
                </label>
                <input id="verifyAccountNumber"
                       className={"form-control " + accountNumbersMatch}
                       required={account === "payment"}
                       type={"text"}
                       onChange={e => {
                           let value = e.target.value;
                           setVerifyAccountNumber(value);
                       }}
                       value={verfiyAccountNumber}>

                </input>
                <div className={"invalid-feedback"}>
                    {"Account numbers do not match"}
                </div>
            </div>
        }
    }

    return (<>
        <Card title="Create Transaction">
            <div className="col-lg-8">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-lg-6 mb-1">
                            <DateInput id={"date"}
                                       label={"Date"}
                                       required={true}
                                       invalidFeedbackText={GenericInputRegex.helpText}
                                       validation={GenericInputRegex.expression}
                                       doValidate={formInputValidate}
                                       value={transactionDate}
                                       setValue={setTransactionDate}
                            />
                        </div>
                        <div className="col-lg-6 mb-1">
                            <Input id={"description"}
                                   type={"text"}
                                   label={"Description"}
                                   required={true}
                                   invalidFeedbackText={GenericInputRegex.helpText}
                                   validation={GenericInputRegex.expression}
                                   doValidate={formInputValidate}
                                   value={transactionDescription}
                                   setValue={setTransactionDescription}
                            />
                        </div>
                        <div className="col-lg-6 mb-1">
                            <Select id={"account"}
                                    label={"Type"}
                                    required={true}
                                    invalidFeedbackText={GenericSelectRegex.helpText}
                                    validation={GenericSelectRegex.expression}
                                    doValidate={formInputValidate}
                                    value={account}
                                    setValue={setAccount}
                            >
                                <option value={""} disabled={true}>Select a transaction type...</option>
                                <option value={"payment"}>Payment</option>
                                {
                                    accounts.map((a) => (
                                        <option key={a.uuid} value={a.uuid}>{a.name}</option>
                                    ))
                                }

                            </Select>
                        </div>
                        <div className="col-lg-6 mb-1">
                            <Input id={"transactionAmount"}
                                   type={"number"}
                                   step={0.01}
                                   min={0}
                                   label={"Amount"}
                                   required={true}
                                   invalidFeedbackText={DollarAmountRegex.helpText}
                                   validation={DollarAmountRegex.expression}
                                   doValidate={formInputValidate}
                                   value={transactionAmount}
                                   setValue={setTransactionAmount}
                            />
                        </div>

                        <div hidden={account !== "payment"} className="col-lg-6 mb-1">
                            <Select id={"paymentMethod"}
                                    label={"Payment Method"}
                                    required={account === "payment"}
                                    invalidFeedbackText={GenericSelectRegex.helpText}
                                    validation={GenericSelectRegex.expression}
                                    doValidate={formSelectValidate}
                                    value={paymentMethod}
                                    setValue={setPaymentMethod}
                            >
                                <option disabled={true} value={""}>Select a method...</option>
                                <option value={"ACH_CHECK_CONVERSION"}>ACH Check Conversion</option>
                                <option value={"ACH_RECURRING"}>Recurring ACH</option>
                                <option value={"ACH_PREARRANGED"}>Prearranged ACH</option>
                            </Select>
                        </div>
                        <div hidden={paymentMethod === "" || paymentMethod.startsWith("MANUAL")} className="col-lg-6 mb-1">
                            <Select id={"accountType"}
                                    label={"Account Type"}
                                    required={account === "payment" && !paymentMethod.startsWith("MANUAL")}
                                    invalidFeedbackText={GenericSelectRegex.helpText}
                                    validation={GenericSelectRegex.expression}
                                    doValidate={formSelectValidate}
                                    value={accountType}
                                    setValue={setAccountType}
                            >
                                <option disabled={true} value={""}>Select an account type...</option>
                                <option value={"personal_checking"}>Personal Checking</option>
                                <option value={"personal_savings"}>Personal Savings</option>
                                <option value={"business_checking"}>Business Checking</option>
                                <option value={"business_savings"}>Business Savings</option>
                            </Select>
                        </div>
                        <div hidden={paymentMethod === "" || paymentMethod.startsWith("MANUAL")} className="col-lg-6 mb-1">
                            <Input id={"accountHolderName"}
                                   type={"text"}
                                   label={"Account Holder Name"}
                                   required={account === "payment" && !paymentMethod.startsWith("MANUAL")}
                                   invalidFeedbackText={USAccountNameRegex.helpText}
                                   validation={USAccountNameRegex.expression}
                                   doValidate={formInputValidate}
                                   value={accountHolderName}
                                   setValue={setAccountHolderName}
                            />
                        </div>
                        <div hidden={paymentMethod === "" || paymentMethod.startsWith("MANUAL")} className="col-lg-6 mb-1">
                            <Input id={"routingNumber"}
                                   type={"text"}
                                   label={"Routing Number"}
                                   required={account === "payment" && !paymentMethod.startsWith("MANUAL")}
                                   invalidFeedbackText={USBankRoutingNumberRegex.helpText}
                                   validation={USBankRoutingNumberRegex.expression}
                                   doValidate={formInputValidate}
                                   value={routingNumber}
                                   setValue={setRoutingNumber}
                            />
                        </div>
                        <div hidden={paymentMethod === "" || paymentMethod.startsWith("MANUAL")} className="col-lg-6 mb-1">
                            <Input id={"accountNumber"}
                                   type={"text"}
                                   label={"Account Number"}
                                   required={account === "payment" && !paymentMethod.startsWith("MANUAL")}
                                   invalidFeedbackText={USBankAccountNumberRegex.helpText}
                                   validation={USBankAccountNumberRegex.expression}
                                   doValidate={formInputValidate}
                                   value={accountNumber}
                                   setValue={setAccountNumber}
                            />
                        </div>
                        {getAccountVerify()}
                        <div className={"col-lg-6 mb-1"} hidden={paymentMethod !== "ACH_CHECK_CONVERSION"}>
                            <Input id={"checkSerialNumber"} validation={GenericNumberRegex.expression}
                                   doValidate={formInputValidate}
                                   value={checkSerialNumber}
                                   setValue={setCheckSerialNumber}
                                   required={paymentMethod === "ACH_CHECK_CONVERSION"}
                                   invalidFeedbackText={GenericNumberRegex.helpText}
                                   type={"text"} label={"Check Serial Number"}
                            />
                        </div>
                        <div className="col-lg-12">
                            <button className={"btn btn-primary"} type={"submit"} style={{marginTop: 20}}>
                                <span hidden={loading}>
                                    Submit
                                </span>
                                <span hidden={!loading}>
                                    <span className="spinner-border spinner-border-sm" role="status"
                                          aria-hidden="true"></span>
                                    Loading...
                                </span>
                            </button>
                        </div>
                        <div className="col-lg-12 mt-3">
                            {error && <Alert type={AlertType.DANGER} message={error}/>}
                        </div>
                    </div>
                </form>
            </div>
        </Card>
    </>);
};

export default CreateTransaction;
