import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {
    ApplicationStatus,
    FormElementValidation, formInputValidate,
    formSelectValidate, GenericInputRegex, GenericSelectRegex,
    Vehicle,
    VehicleType, YearRegex
} from "../../../types";
import Alert, {AlertProps, AlertType} from "../../Alert";
import {useHistory, useParams} from "react-router";
import StateSelect from "../../StateSelect";
import {getPortfolioId} from "../../../helpers";
import LoadingOverlay from "../../LoadingOverlay";
import Select from "../../Form/Select";
import Input from "../../Form/Input";
import {getApplication, updateApplication} from "../../../Api/ApplicationApi";
import ApplicationStageLayout from "../../../Layouts/ApplicationStageLayout";
import ButtonNew, {ButtonColors, ButtonTypes} from "../../ButtonNew";

type Stage6Props = {
    handleInvalidApplicationStatus: Function
    applicationId: string
    setStage: Function
}

const Stage6: React.FunctionComponent<Stage6Props> = (p) => {

    const { propertyId } = useParams();

    const [error, setError] = useState<Error>()
    const [alert, setAlert] = useState<AlertProps>()
    const [loading, setLoading] = useState(false);
    const [doRedirectToStage5, setDoRedirectToStage5] = useState(false);
    const [doRedirectToStage7, setDoRedirectToStage7] = useState(false);
    const history = useHistory();
    const [doRedirectToStage1, setDoRedirectToStage1] = useState(false);
    const [doHandleInvalidApplicationStatus, setDoHandleInvalidApplicationStatus] = useState(false);

    //form
    const [vehicles, setVehicles] = useState(new Array<Vehicle>());

    const [type, setType] = useState("");
    const [make, setMake] = useState("");
    const [model, setModel] = useState("");
    const [year, setYear] = useState("");
    const [color, setColor] = useState("");
    const [licensePlate, setLicensePlate] = useState("");
    const [registeredState, setRegisteredState] = useState("");

    type Form = {
        type: FormElementValidation,
        make: FormElementValidation,
        model: FormElementValidation,
        year: FormElementValidation,
        color: FormElementValidation,
        licensePlate: FormElementValidation,
        registeredState: FormElementValidation
    }

    const form: Form = {
        type: {
            id: "type",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        make: {
            id: "make",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        model: {
            id: "model",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        year: {
            id: "year",
            required: true,
            validate: formInputValidate,
            regex: YearRegex
        },
        color: {
            id: "color",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        licensePlate: {
            id: "licensePlate",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        registeredState: {
            id: "registeredState",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        }
    };


    useEffect(() => {
        if (doHandleInvalidApplicationStatus) {
            p.handleInvalidApplicationStatus();
        }
    }, [doHandleInvalidApplicationStatus, p]);

    useEffect(() => {
        if (doRedirectToStage1) {
            setDoRedirectToStage1(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/1"
            });
        }

    }, [doRedirectToStage1, history])


    useEffect(() => {
        console.log("stage 6 init");
        p.setStage(6)
        setLoading(true);
        getApplication(getPortfolioId(), p.applicationId)
            .then((a) => {
                if (a.status !== ApplicationStatus.approved) {
                    if (!a.unit || (a.unit && !a.unit.uuid)) {
                        console.log("redirect to stage 1, missing unit")
                        setDoRedirectToStage1(true);
                        return;
                    }
                    setVehicles(a.vehicles || []);
                    setLoading(false);
                } else {
                    setDoHandleInvalidApplicationStatus(true);
                }
            })
            .catch(e => {
                doShowAlert("Failed to start application: " + e.message, AlertType.DANGER);
                setLoading(false);
            })


    }, [p])

    const saveAndContinueStage7 = () => {
        setLoading(true);
        updateApplication(getPortfolioId(), p.applicationId, {
            vehicles: vehicles
        })
            .then((a) => {
                setDoRedirectToStage7(true);
            })
            .catch(e => {
                doShowAlert("Failed to add addresses: " + e.message, AlertType.DANGER);
            }).finally(() => setLoading(false));
    }

    const clearForm = () => {
        setType("");
        setMake("");
        setModel("");
        setYear("");
        setColor("");
        setLicensePlate("");
        setRegisteredState("");
    }

    const doShowAlert = (message: string, type: AlertType) => {
        setAlert({type, message})
    }

    useEffect(() => {
        if (doRedirectToStage7) {
            setDoRedirectToStage7(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/7"
            });
        }

    }, [doRedirectToStage7, history])

    useEffect(() => {
        if (doRedirectToStage5) {
            setDoRedirectToStage5(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/5"
            });
        }

    }, [doRedirectToStage5, history])


    const validateForm = (form: Form): boolean => {
        if (!form.type.validate(type, form.type.required, form.type.regex.expression)) {
            doShowAlert("Vehicle type required.", AlertType.DANGER);
            return false;
        }
        if (!form.make.validate(make, form.make.required, form.make.regex.expression)) {
            doShowAlert("Invalid make.", AlertType.DANGER);
            return false;
        }
        if (!form.model.validate(model, form.model.required, form.model.regex.expression)) {
            doShowAlert("Invalid model.", AlertType.DANGER);
            return false;
        }
        if (!form.year.validate(year, form.year.required, form.year.regex.expression)) {
            doShowAlert("Invalid year.", AlertType.DANGER);
            return false;
        }
        if (!form.color.validate(color, form.color.required, form.color.regex.expression)) {
            doShowAlert("Invalid color.", AlertType.DANGER);
            return false;
        }
        if (!form.licensePlate.validate(licensePlate, form.licensePlate.required, form.licensePlate.regex.expression)) {
            doShowAlert("Invalid license plate.", AlertType.DANGER);
            return false;
        }
        if (!form.registeredState.validate(registeredState, form.registeredState.required, form.registeredState.regex.expression)) {
            doShowAlert("Registered state required.", AlertType.DANGER);
            return false;
        }
        return true;
    };

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!validateForm(form)) {
            return;
        }
        vehicles.push({
            uuid: "",
            color: color,
            licensePlate: licensePlate,
            make: make,
            model: model,
            registeredState: registeredState,
            type: VehicleType[type as keyof typeof VehicleType],
            year: parseInt(year)
        })
        setVehicles([...vehicles])
        clearForm();
    }

    const deleteVehicle = (v: Vehicle) => {
        setVehicles(vehicles.filter(veh => veh !== v));
    }

    const [isStageValid, setIsStageValid] = useState<boolean>(false)

    return (loading ? <div className="col-md-6"><LoadingOverlay /></div> : <>
        <ApplicationStageLayout
            error={error}
            alert={alert}
            title="Vehicles"
            description="Please provide vehicle information."
        >
            <div className={"row"}>
                <div className={"col-lg-6 col-md-8 mb-5"}>
                    <form onSubmit={onSubmit}>
                        <div className={"row"}>
                            <div className={"col-md-6 mb-2"}>

                                <Select id={form.type.id} validation={form.type.regex.expression}
                                        doValidate={form.type.validate}
                                        value={type} setValue={setType}
                                        required={form.type.required}
                                        invalidFeedbackText={form.type.regex.helpText} label={"Type"}>
                                    <option value={""} disabled={true}>Select a vehicle type...</option>
                                    <option value={"SUV"}>SUV</option>
                                    <option value={"Truck"}>Truck</option>
                                    <option value={"Car"}>Car</option>
                                    <option value={"Van"}>Van</option>
                                    <option value={"Compact"}>Compact</option>
                                </Select>
                            </div>
                            <div className={"col-md-6 mb-2"}>

                                <Input id={form.make.id} validation={form.make.regex.expression}
                                       doValidate={form.make.validate}
                                       value={make}
                                       setValue={setMake}
                                       required={form.make.required}
                                       invalidFeedbackText={form.make.regex.helpText}
                                       type={"text"} label={"Make"}
                                />
                            </div>
                            <div className={"col-md-6 mb-2"}>

                                <Input id={form.model.id} validation={form.model.regex.expression}
                                       doValidate={form.model.validate}
                                       value={model}
                                       setValue={setModel}
                                       required={form.model.required}
                                       invalidFeedbackText={form.model.regex.helpText}
                                       type={"text"} label={"Model"}
                                />
                            </div>
                            <div className={"col-md-6 mb-2"}>

                                <Input id={form.year.id} validation={form.year.regex.expression}
                                       doValidate={form.year.validate}
                                       value={year}
                                       setValue={setYear}
                                       required={form.year.required}
                                       invalidFeedbackText={form.year.regex.helpText}
                                       type={"number"} label={"Year"} maxLength={4} minLength={4} placeholder={"yyyy"}
                                />
                            </div>
                            <div className={"col-md-6 mb-2"}>

                                <Input id={form.color.id} validation={form.color.regex.expression}
                                       doValidate={form.color.validate}
                                       value={color}
                                       setValue={setColor}
                                       required={form.color.required}
                                       invalidFeedbackText={form.color.regex.helpText}
                                       type={"text"} label={"Color"}
                                />
                            </div>
                            <div className={"col-md-6 mb-2"}>

                                <Input id={form.licensePlate.id} validation={form.licensePlate.regex.expression}
                                       doValidate={form.licensePlate.validate}
                                       value={licensePlate}
                                       setValue={setLicensePlate}
                                       required={form.licensePlate.required}
                                       invalidFeedbackText={form.licensePlate.regex.helpText}
                                       type={"text"} label={"License plate number"}
                                />
                            </div>
                            <div className="col-md-12 mb-2">

                                <StateSelect id={form.registeredState.id} validation={form.registeredState.regex.expression}
                                             noSelectionText={"Select a State..."}
                                             doValidate={form.registeredState.validate}
                                             value={registeredState} setValue={setRegisteredState}
                                             required={form.registeredState.required}
                                             invalidFeedbackText={form.registeredState.regex.helpText}
                                             label={"Registered state"}/>
                            </div>
                            <div className={"col-md-12 mt-3 mb-2"}>
                                <button type="submit" className="btn btn-primary mr-3">Add vehicle</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-12 mb-2 table-responsive"}>
                    <table className="table table-striped">
                        <thead>
                        <tr>

                            <th scope="col">Type</th>
                            <th scope="col">Make</th>
                            <th scope="col">Model</th>
                            <th scope="col">Year</th>
                            <th scope="col">Color</th>
                            <th scope="col">License plate number</th>
                            <th scope="col">Registered State</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>

                        {
                            vehicles?.map((v, index) => (
                                <tr key={"v_" + index}>
                                    <td>{v.type}</td>
                                    <td>{v.make}</td>
                                    <td>{v.model}</td>
                                    <td>{v.year}</td>
                                    <td>{v.color}</td>
                                    <td>{v.licensePlate}</td>
                                    <td>{v.registeredState}</td>
                                    <td>
                                        <button type="button" className="btn btn-danger btn-sm"
                                                onClick={e => deleteVehicle(v)}
                                        >Remove
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }


                        </tbody>
                    </table>
                </div>
                <div className="d-flex justify-content-start gap-2 mt-3">
                    <ButtonNew
                        type={ButtonTypes.BUTTON}
                        color={ButtonColors.SECONDARY}
                        label="Back"
                        onClick={() => setDoRedirectToStage5(true)}
                    />
                    <ButtonNew
                        type={ButtonTypes.BUTTON}
                        color={ButtonColors.PRIMARY}
                        label="Save & Continue"
                        loading={loading}
                        onClick={saveAndContinueStage7}
                    />
                </div>
            </div>
        </ApplicationStageLayout>
    </>);
};

export default Stage6;
