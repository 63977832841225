import React, { ErrorInfo } from 'react';
import {useCallback, useEffect, useState} from 'react';

interface State {
    hasError: boolean;
  }
  
  interface Props {
    children: React.ReactNode;
  }

class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error: Error): State {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
      // You can also log the error to an error reporting service
    }
  
    render(): React.ReactNode {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <h1>Something went wrong.</h1>;
      }
  
      return this.props.children; 
    }
  }
  
  
  export default ErrorBoundary;