import {
    DateRegex,
    FormElementValidation,
    formInputValidate,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex
} from "../../../types";

interface EditMaintenanceRequestForm {
    category: FormElementValidation,
    description: FormElementValidation,
    entryPermission: FormElementValidation,
    accessDetails: FormElementValidation,
    scheduledDate: FormElementValidation,
    technicalNotes: FormElementValidation,
    assignedMaintenanceUser: FormElementValidation,
    status: FormElementValidation
}

const EditMaintenanceRequestForm: EditMaintenanceRequestForm = {
    category: {
        id: "category",
        required: true,
        validate: formSelectValidate,
        regex: GenericSelectRegex
    },
    description: {
        id: "description",
        required: false,
        validate: formInputValidate,
        regex: GenericInputRegex
    },
    entryPermission: {
        id: "entryPermission",
        required: false,
        validate: formSelectValidate,
        regex: GenericSelectRegex
    },
    accessDetails: {
        id: "accessDetails",
        required: false,
        validate: formInputValidate,
        regex: GenericInputRegex
    },
    scheduledDate: {
        id: "scheduledDate",
        required: false,
        validate: formInputValidate,
        regex: DateRegex
    },
    technicalNotes: {
        id: "technicalNotes",
        required: false,
        validate: formInputValidate,
        regex: GenericInputRegex
    },
    assignedMaintenanceUser: {
        id: "assignedMaintenanceUser",
        required: false,
        validate: formSelectValidate,
        regex: GenericSelectRegex
    },
    status: {
        id: "status",
        required: false,
        validate: formSelectValidate,
        regex: GenericSelectRegex
    }
}

export default EditMaintenanceRequestForm;
