import {
    FormBuilderField,
    FormFieldType,
    InputFieldType
} from "../../Form/FieldBuilder/FormFieldBuilder";
import {formInputValidate, GenericInputRegex} from "../../../types";
import AmenityDayForm, {AmenityDayFormInterface} from "./AmenityDayForm";

export interface AmenityForm {
    propertyId?: FormBuilderField,
    name: FormBuilderField,
    description: FormBuilderField,
    location: FormBuilderField,
    sunday: AmenityDayFormInterface,
    monday: AmenityDayFormInterface,
    tuesday: AmenityDayFormInterface,
    wednesday: AmenityDayFormInterface,
    thursday: AmenityDayFormInterface,
    friday: AmenityDayFormInterface,
    saturday: AmenityDayFormInterface
}

const AmenityForm: AmenityForm = {
    name: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.TEXT,
        element: {
            id: 'name',
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Name',
        value: undefined,
        setValue: () => {}
    },
    description: {
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 4,
        element: {
            id: 'description',
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Description',
        value: undefined,
        setValue: () => {}
    },
    location: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.TEXT,
        element: {
            id: 'location',
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Location',
        value: undefined,
        setValue: () => {}
    },
    sunday: AmenityDayForm("sun"),
    monday: AmenityDayForm("mon"),
    tuesday: AmenityDayForm("tues"),
    wednesday: AmenityDayForm("wed"),
    thursday: AmenityDayForm("thur"),
    friday: AmenityDayForm("fri"),
    saturday: AmenityDayForm("sat")
}

export default AmenityForm;
