import {FormBuilderField, FormFieldType} from "../../../Components/Form/FieldBuilder/FormFieldBuilder";
import {
    DateRegex,
    EmailRegex,
    FirstNameRegex,
    formInputValidate, formSelectValidate,
    GenericInputRegex, GenericSelectRegex,
    LastNameRegex,
    PhoneNumberRegex
} from "../../../types";

interface JourneyLeaseForm {
    firstName: FormBuilderField
    lastName: FormBuilderField
    email: FormBuilderField
    phone: FormBuilderField
    notes: FormBuilderField
    followUpDate: FormBuilderField
    closed: FormBuilderField
}

const JourneyLeaseForm: JourneyLeaseForm = {
    firstName: {
        type: FormFieldType.INPUT,
        element: {
            id: 'firstName',
            required: true,
            validate: formInputValidate,
            regex: FirstNameRegex
        },
        label: 'First Name',
        value: undefined,
        setValue: () => {}
    },
    lastName: {
        type: FormFieldType.INPUT,
        element: {
            id: 'lastName',
            required: true,
            validate: formInputValidate,
            regex: LastNameRegex
        },
        label: 'Last Name',
        value: undefined,
        setValue: () => {}
    },
    email: {
        type: FormFieldType.INPUT,
        element: {
            id: 'email',
            required: false,
            validate: formInputValidate,
            regex: EmailRegex
        },
        label: 'Email',
        value: undefined,
        setValue: () => {}
    },
    phone: {
        type: FormFieldType.INPUT,
        element: {
            id: 'phone',
            required: false,
            validate: formInputValidate,
            regex: PhoneNumberRegex
        },
        label: 'Phone',
        value: undefined,
        setValue: () => {}
    },
    notes: {
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 7,
        element: {
            id: 'notes',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Notes',
        value: undefined,
        setValue: () => {}
    },
    followUpDate: {
        type: FormFieldType.DATE,
        element: {
            id: 'followUpDate',
            required: false,
            validate: formInputValidate,
            regex: DateRegex
        },
        label: 'Follow Up',
        value: undefined,
        setValue: () => {}
    },
    closed: {
        type: FormFieldType.SELECT,
        element: {
            id: 'closedOut',
            required: false,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        label: 'Status',
        defaultSelectOptions: [
            {
                value: "false",
                label: "Open",
            },
            {
                value: "true",
                label: "Closed",
            },
        ],
        value: undefined,
        setValue: () => {}
    },
}

export default JourneyLeaseForm;
