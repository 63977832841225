import {FormBuilderField, FormFieldType} from "../../Form/FieldBuilder/FormFieldBuilder";
import {AmenityReservationStatus} from "../../../Api/AmenityApi";
import {DateRegex, formInputValidate, formSelectValidate, GenericSelectRegex, UuidRegex} from "../../../types";

interface AmenityReservationSearchForm {
    accountId: FormBuilderField,
    date: FormBuilderField,
    status: FormBuilderField
}

const AmenityReservationSearchForm: AmenityReservationSearchForm = {
    accountId: {
        type: FormFieldType.SELECT,
        element: {
            id: 'accountId',
            required: false,
            validate: formSelectValidate,
            regex: UuidRegex,
        },
        label: "Unit",
        defaultSelectOptions: [{
            value: '',
            label: 'All'
        }],
        value: undefined,
        setValue: () => {}
    },
    date: {
        type: FormFieldType.DATE,
        element: {
            id: 'date',
            required: false,
            validate: formInputValidate,
            regex: DateRegex,
        },
        label: 'Date',
        value: undefined,
        setValue: () => {}
    },
    status: {
        type: FormFieldType.SELECT,
        element: {
            id: 'status',
            required: false,
            validate: formInputValidate,
            regex: GenericSelectRegex,
        },
        label: 'Status',
        defaultSelectOptions: [
            {
                value: AmenityReservationStatus.UNKNOWN,
                label: "All"
            },
            {
                value: AmenityReservationStatus.RESERVED,
                label: "Reserved"
            },
            {
                value: AmenityReservationStatus.IN_PROGRESS,
                label: "In Progress"
            },
            {
                value: AmenityReservationStatus.COMPLETED,
                label: "Completed"
            },
        ],
        value: undefined,
        setValue: () => {}
    },
}

export default AmenityReservationSearchForm;
