import * as React from 'react';
import {useEffect, useState} from 'react';
import {BootstrapValidateClass, ValidateElementFunction} from "../../../types";
import RequiredLabel from "../../RequiredLabel";

type TextAreaProps = {
    id: string,
    validation: RegExp,
    doValidate: ValidateElementFunction,
    value: string,
    setValue: Function,
    required: boolean,
    maxLength?: number,
    invalidFeedbackText: string,
    label: string,
    placeholder?: string,
    disabled?: boolean,
    rows: number
}

const TextArea: React.FunctionComponent<TextAreaProps> = (p) => {

    const [validateClass, setValidateClass] = useState(BootstrapValidateClass.unvalidated);
    const {value} = p;

    useEffect(() => {
        if (value.length === 0 || p.disabled) {
            setValidateClass(BootstrapValidateClass.unvalidated);
            return;
        }
        if (p.doValidate(value, p.required, p.validation)) {
            setValidateClass(BootstrapValidateClass.isvalid);
        } else {
            setValidateClass(BootstrapValidateClass.isinvalid);
        }
    }, [value, p])

    return (<>
        <label htmlFor={p.id}>{!p.disabled && p.required ? <RequiredLabel>{p.label}</RequiredLabel> : p.label}</label>
        <textarea className={"form-control " + validateClass}
               id={p.id} disabled={p.disabled} rows={p.rows}
               maxLength={p.maxLength} placeholder={p.placeholder}
               required={p.required} value={p.value} onChange={e => {
            let value = e.target.value;
            p.setValue(value);
        }}/>
        <div className="invalid-feedback">
            {p.invalidFeedbackText}
        </div>
    </>);
};

export default TextArea;
