import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod} from "../api";

export interface CommunityEvent {
    uuid: string;
    name: string;
    category: string;
    description: string;
    startTime: string;
    endTime: string;
}

export interface CreateCommunityEvent {
    name: string;
    category: string;
    description: string;
    startTime: string;
    endTime: string;
}

type GetCommunityEvents = (propertyId: string) => Promise<CommunityEvent[]>;
type GetCommunityEvent = (propertyId: string, eventId: string) => Promise<CommunityEvent>;
type PostCommunityEvent = (propertyId: string, event: CreateCommunityEvent) => Promise<CommunityEvent>;
type PutCommunityEvent = (propertyId: string, event: CommunityEvent) => Promise<CommunityEvent>;
type DeleteCommunityEvent = (propertyId: string, eventId: string) => Promise<void>;

const pathBuilder = (propertyId: string, extension = "") => {
    return "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/community-events/" + extension;
}

export const getCommunityEvents: GetCommunityEvents = (propertyId) => {
    const path = pathBuilder(propertyId);
    return fetchWithAuth(path, HttpMethod.GET);
}

export const getCommunityEvent: GetCommunityEvent = (propertyId, eventId) => {
    const path = pathBuilder(propertyId, eventId);
    return fetchWithAuth(path, HttpMethod.GET);
}

export const createCommunityEvent: PostCommunityEvent = (propertyId, event) => {
    console.log(event);
    const path = pathBuilder(propertyId);
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(event));
}

export const updateCommunityEvent: PutCommunityEvent = (propertyId, event) => {
    const path = pathBuilder(propertyId, event.uuid);
    return fetchWithAuth(path, HttpMethod.PUT, JSON.stringify(event));
}

export const deleteCommunityEvent: DeleteCommunityEvent = (propertyId, eventId) => {
    const path = pathBuilder(propertyId, eventId);
    return fetchWithAuth(path, HttpMethod.DELETE);
}
