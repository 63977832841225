import React, {useEffect, useState} from "react";
import {getTicketIdFromUuid} from "../../helpers";
import {getJourneyLeases, JourneyLease, JourneyLeaseStep, journeySteps} from "../../Api/JourneyApi";
import {useHistory, useParams} from "react-router";
import Alert, {AlertProps, AlertType} from "../../Components/Alert";
import Button from "../../Components/Button";
import {useLocation} from "react-router-dom";
import PageList from "../../Components/PageList";
import FormFieldBuilder, {assignFormFieldValues} from "../../Components/Form/FieldBuilder/FormFieldBuilder";
import SearchJourneyLeasesForm from "./Forms/SearchJourneyLeasesForm";
import SearchJourneyLeaseForm from "./Forms/SearchJourneyLeasesForm";
import JourneyLeaseItem from "./JourneyLeaseItem/JourneyLeaseItem";
import moment from "moment";
import PageLayout from "../../Layouts/PageLayout";

const JourneyLeases: React.FunctionComponent = () => {

    const { propertyId } = useParams();
    const { pathname } = useLocation();
    const history = useHistory();

    const [error, setError] = useState<Error>();
    const [alert, setAlert] = useState<AlertProps>();

    const [searchToggle, setSearchToggle] = useState<boolean>(false)
    const [searchTicketId, setSearchTicketId] = useState<string>("")
    const [searchFirstName, setSearchFirstName] = useState<string>("")
    const [searchLastName, setSearchLastName] = useState<string>("")
    const [searchEmail, setSearchEmail] = useState<string>("")
    const [searchPhone, setSearchPhone] = useState<string>("")
    const [searchFollowUpDate, setSearchFollowUpDate] = useState<string>("")
    const [searchClosed, setSearchClosed] = useState<boolean>(false)

    const [journeyLeases, setJourneyLeases] = useState<JourneyLease[]>([])
    const [filteredJourneyLeases, setFilteredJourneyLeases] = useState<JourneyLease[]>([])
    const [stepFilterId, setStepFilterId] = useState<string>("")
    const [pageListItems, setPageListItems] = useState<JSX.Element[]>([])

    assignFormFieldValues({field: SearchJourneyLeasesForm.ticketId, value: searchTicketId, setValue: setSearchTicketId})
    assignFormFieldValues({field: SearchJourneyLeasesForm.firstName, value: searchFirstName, setValue: setSearchFirstName})
    assignFormFieldValues({field: SearchJourneyLeasesForm.lastName, value: searchLastName, setValue: setSearchLastName})
    assignFormFieldValues({field: SearchJourneyLeasesForm.email, value: searchEmail, setValue: setSearchEmail})
    assignFormFieldValues({field: SearchJourneyLeasesForm.phone, value: searchPhone, setValue: setSearchPhone})
    assignFormFieldValues({field: SearchJourneyLeasesForm.followUpDate, value: searchFollowUpDate, setValue: setSearchFollowUpDate})
    assignFormFieldValues({field: SearchJourneyLeasesForm.closed, value: searchClosed, setValue: setSearchClosed})

    const clearFilters = () => {
        setSearchTicketId("")
        setSearchFirstName("")
        setSearchLastName("")
        setSearchEmail("")
        setSearchPhone("")
        setSearchFollowUpDate("")
        setSearchClosed(false)
    }

    useEffect(() => {
        let filteredJL = journeyLeases.filter(() => true);
        if (stepFilterId !== "") {
            filteredJL = journeyLeases.filter(jl => jl.step === stepFilterId);
        }
        if (searchTicketId !== "") {
            filteredJL = filteredJL.filter(jl => getTicketIdFromUuid(jl.uuid) === searchTicketId)
        }
        if (searchFirstName !== "") {
            filteredJL = filteredJL.filter(jl => jl.journeyUser.firstName === searchFirstName)
        }
        if (searchLastName !== "") {
            filteredJL = filteredJL.filter(jl => jl.journeyUser.lastName === searchLastName)
        }
        if (searchEmail !== "") {
            filteredJL = filteredJL.filter(jl => jl.journeyUser.email === searchEmail)
        }
        if (searchPhone !== "") {
            filteredJL = filteredJL.filter(jl => jl.journeyUser.phone === searchPhone)
        }
        if (searchFollowUpDate !== "") {
            filteredJL = filteredJL.filter(jl => moment(jl.followUpDate).date() === moment(searchFollowUpDate).date())
        }
        setFilteredJourneyLeases(filteredJL)
    }, [journeyLeases, stepFilterId]);

    useEffect(() => {
        getJourneyLeases(propertyId, searchClosed)
            .then(setJourneyLeases)
            .catch(setError)
    }, [searchToggle])

    useEffect(() => {
        const items: JSX.Element[] = []
        filteredJourneyLeases.reverse().sort((a, b) => {
            const aDate = moment(a.followUpDate).startOf('day')
            const bDate = moment(b.followUpDate).startOf('day')
            if (a.followUpDate === undefined || aDate.isAfter(bDate)) {
                return 1
            }
            if (b.followUpDate === undefined || aDate.isBefore(bDate)) {
                return -1
            }
            return 0
        }).map((journeyLease) => {
            const step = journeySteps.find(s => s.id === journeyLease.step)
            let actionItemPath = ""
            let width = Math.ceil(100 / journeySteps.length)
            if (step) {
                switch (journeyLease.step) {
                    case JourneyLeaseStep.ONE:
                        width *= 1;
                        actionItemPath = step.actionItem?.path(propertyId, journeyLease.uuid)
                        break;
                    case JourneyLeaseStep.TWO:
                        width *= 2;
                        actionItemPath = step.actionItem?.path(propertyId, journeyLease.uuid)
                        break;
                    case JourneyLeaseStep.THREE:
                        width *= 3;
                        actionItemPath = step.actionItem?.path(propertyId, journeyLease.tenantApplicationId)
                        break;
                    case JourneyLeaseStep.FOUR:
                        width *= 4;
                        actionItemPath = step.actionItem?.path(propertyId, journeyLease.tenantApplicationId)
                        break;
                    case JourneyLeaseStep.FIVE:
                        width *= 5;
                        actionItemPath = step.actionItem?.path(propertyId, journeyLease.tenantApplicationId)
                        break;
                    /*
                    case JourneyLeaseStep.SIX:
                        width *= 6;
                        actionItemPath = step.actionItem?.path(journeyLease.leaseId)
                        break;

                     */
                }
                items.push(
                    <JourneyLeaseItem journeyLease={journeyLease} step={step} width={width} onClick={() => {
                        history.push({pathname: '/p/m/customers/prospective-tenants/' + propertyId + '/' + journeyLease.uuid})
                    }} />
                )
            }
        })
        setPageListItems(items)
    }, [filteredJourneyLeases])

    return <>
        <PageLayout title="Prospective Tenants" propertyId={propertyId} addButtonLabel="Add" addButtonPath={pathname + "/add"}>
            <div className="row">
                <div className="d-flex justify-content-start">
                </div>
            </div>
            <div className="row">
                <div className="col p-2 mx-3">
                    <div className="row">
                        {error && <Alert type={AlertType.DANGER} message={error.message} />}
                        {alert && <Alert type={alert.type} message={alert.message} />}
                    </div>
                    <div className="row">
                        <div className="col-1">
                            <div className="d-flex justify-content-center">
                                <h6 className="mt-1">Follow Up?</h6>
                            </div>
                        </div>
                        <div className="col-11">
                            <div className="progress shadow-sm" style={{height: 30}}>
                                {journeySteps.map(step => {
                                    const width = Math.ceil(100 / journeySteps.length)
                                    let bgColorClass = "bg-" + step.bgColor
                                    if (stepFilterId !== "" && stepFilterId !== step.id.toString()) {
                                        bgColorClass += " bg-opacity-25"
                                    }
                                    return (
                                        <div key={step.id} className={"clickable progress-bar " + bgColorClass} role="progressbar" style={{width: width + "%"}} aria-valuenow={width}
                                             aria-valuemin={0} aria-valuemax={100} onClick={e => {
                                            e.preventDefault();
                                            if (stepFilterId === step.id.toString()) {
                                                setStepFilterId("")
                                            }
                                            else {
                                                setStepFilterId(step.id.toString())
                                            }
                                        }
                                        }><h6 className="my-auto">{step.label}</h6></div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-2">
                        <button className="btn dropdown-toggle shadow-none" type="button" role="button" data-bs-toggle="collapse" data-bs-target={"#collapse_filters"}>Filters</button>
                    </div>
                </div>
            </div>
            <div className="collapse" id="collapse_filters">
                <div className="row mt-3 px-2">
                    <div className="d-flex gap-4 justify-content-between">
                        <div className="w-100 d-grid gap-2">
                            <div>
                                <FormFieldBuilder field={SearchJourneyLeaseForm.ticketId} />
                            </div>
                            <div>
                                <FormFieldBuilder field={SearchJourneyLeaseForm.followUpDate} />
                            </div>
                        </div>
                        <div className="w-100 d-grid gap-2">
                            <div>
                                <FormFieldBuilder field={SearchJourneyLeaseForm.firstName} />
                            </div>
                            <div>
                                <FormFieldBuilder field={SearchJourneyLeaseForm.lastName} />
                            </div>
                        </div>
                        <div className="w-100 d-grid gap-2">
                            <div>
                                <FormFieldBuilder field={SearchJourneyLeaseForm.email} />
                            </div>
                            <div className="d-flex gap-4">
                                <div className="w-50">
                                    <FormFieldBuilder field={SearchJourneyLeaseForm.phone} />
                                </div>
                                <div className="w-50">
                                    <FormFieldBuilder field={SearchJourneyLeaseForm.closed} selectOptions={SearchJourneyLeasesForm.closed.defaultSelectOptions} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end my-4">
                        <div className="btn-group">
                            <button className="btn btn-outline-secondary" type="button" role="button" onClick={() => clearFilters()}>Clear</button>
                            <button className="btn btn-primary" type="button" role="button" onClick={() => setSearchToggle(!searchToggle)}>Apply</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row border-top mx-2 pt-4">
                <div className="col px-1">
                    <PageList defaultPaginationSize={25} items={pageListItems} />
                </div>
            </div>
        </PageLayout>
    </>
}

export default JourneyLeases;
