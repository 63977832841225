import * as React from 'react';
import {Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import SearchUnits from "./SearchUnits/SearchUnits";
import ManageFrontDeskInstruction from "./Instructions/ManageFrontDeskInstruction";
import ManageTenantGuest from "./Guests/ManageTenentGuest";
import AddFrontDeskInstruction from "./Instructions/Add/AddFrontDeskInstruction";
import AddTenantGuest from "./Guests/Add/AddTenantGuest";
import AutoSelectProperty from "../Form/SelectProperty/AutoSelectProperty";

const FrontDeskRouter: React.FunctionComponent = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <AutoSelectProperty name={"Front Desk Information"} />
            </Route>
            <Route exact path={path + "/:propertyId"}>
                <SearchUnits />
            </Route>
            <Route exact path={path + "/:propertyId/accounts/:accountId/instructions/add"}>
                <AddFrontDeskInstruction />
            </Route>
            <Route exact path={path + "/:propertyId/accounts/:accountId/instructions/:instructionId"}>
                <ManageFrontDeskInstruction />
            </Route>
            <Route exact path={path + "/:propertyId/accounts/:accountId/guests/add"}>
                <AddTenantGuest />
            </Route>
            <Route exact path={path + "/:propertyId/accounts/:accountId/guests/:guestId"}>
                <ManageTenantGuest />
            </Route>
        </Switch>
    )
}

export default FrontDeskRouter;
