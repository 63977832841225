import * as React from 'react';
import {Redirect, Route, Switch, useRouteMatch} from "react-router";
import ManageLeases from "../../Components/ManageLeases";
import InboundLeads from "../../Components/InboundLeads";
import ApplicationsContainer from "../ApplicationsContainer";
import JourneyContainer from "../JourneyContainer";
import TenantDirectory from "../../Pages/Tenants/TenantDirectory";
import OwnerDirectory from "../../Pages/Owners/OwnerDirectory";
import PropertyDirectory from "../../Pages/Properties/PropertyDirectory";
import ViewOwner from "../../Pages/Customers/Owner/View";
import CommunicationLetters from "../../Components/CommunicationLetters";

const CustomersContainer: React.FunctionComponent = () => {

    let {path} = useRouteMatch();

    return (<>
        <Switch>
            <Route exact path={path}>
                <Redirect to={path + "/applications"} />
            </Route>
            <Route path={path + "/applications"}>
                <ApplicationsContainer />
            </Route>
            <Route path={path + "/leases"}>
                <ManageLeases />
            </Route>
            <Route path={path + "/leads"}>
                <InboundLeads />
            </Route>
            <Route path={path + "/prospective-tenants"}>
                <JourneyContainer />
            </Route>
            <Route path={path + "/tenant-directory"}>
                <TenantDirectory />
            </Route>
            <Route path={path + "/owner-directory"}>
                <OwnerDirectory />
            </Route>
            <Route path={path + "/property-directory"}>
                <PropertyDirectory />
            </Route>
            <Route path={path + "/businesses/:businessId"}>
                <ViewOwner/>
            </Route>
            <Route path={path + "/letters"}>
                <CommunicationLetters/>
            </Route>

        </Switch>
    </>);
};

export default CustomersContainer;