import * as React from 'react';
import {Link} from "react-router-dom";
import {useRouteMatch} from "react-router";
import {FormEvent, useEffect, useState} from "react";
import {
    AddressLine1Regex, AddressLine2Regex, CityRegex,
    FormElementValidation,
    formInputValidate,
    GenericSelectRegex,
    Property, PropertyNameRegex, ZipRegex
} from "../../../types";
import StateSelect from "../../StateSelect";
import LoadingOverlay from "../../LoadingOverlay";
import Input from "../../Form/Input";
import Table from "../../Table";
import {getProperties} from "../../../Api/PropertyApi";
import PageLayout from "../../../Layouts/PageLayout";


const SearchProperties: React.FunctionComponent = () => {

    const [reloadToggle, setReloadToggle] = useState<boolean>(false)
    const [searchResults, setSearchResults] = useState(new Array<Property>());
    let {path} = useRouteMatch();
    const [name, setName] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [disabled, setDisabled] = useState("false");
    const [loading, setLoading] = useState(false);

    type Form = {
        name: FormElementValidation,
        addressLine1: FormElementValidation,
        addressLine2: FormElementValidation,
        city: FormElementValidation,
        state: FormElementValidation,
        zip: FormElementValidation,
    }

    const form: Form = {
        name: {
            id: "name",
            required: false,
            validate: formInputValidate,
            regex: PropertyNameRegex
        },
        addressLine1: {
            id: "addressLine1",
            required: false,
            validate: formInputValidate,
            regex: AddressLine1Regex
        },
        addressLine2: {
            id: "addressLine2",
            required: false,
            validate: formInputValidate,
            regex: AddressLine2Regex
        },
        city: {
            id: "city",
            required: false,
            validate: formInputValidate,
            regex: CityRegex
        },
        state: {
            id: "state",
            required: false,
            validate: formInputValidate,
            regex: GenericSelectRegex
        },
        zip: {
            id: "zip",
            required: false,
            validate: formInputValidate,
            regex: ZipRegex
        }
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setReloadToggle(!reloadToggle)
    }

    useEffect(() => {
        setLoading(true)
        getProperties({
            name: name,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            stateCode: state,
            zip: zip,
            disabled: disabled
        })
            .then((data) => {
                setSearchResults(data);
            }
            ).finally(() => setLoading(false))
    }, [reloadToggle])

    return <>
        <PageLayout title="Properties" addButtonLabel="Add Property">
            <p>Search for properties using the following attributes.</p>
            <div className="col-lg-8 mb-3">
                <form onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <Input id={form.name.id} validation={form.name.regex.expression}
                                   doValidate={form.name.validate}
                                   value={name}
                                   setValue={setName}
                                   required={form.name.required}
                                   invalidFeedbackText={form.name.regex.helpText}
                                   type={"text"} label={"Name"}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <Input id={form.addressLine1.id} validation={form.addressLine1.regex.expression}
                                   doValidate={form.addressLine1.validate}
                                   value={addressLine1}
                                   setValue={setAddressLine1}
                                   required={form.addressLine1.required}
                                   invalidFeedbackText={form.addressLine1.regex.helpText}
                                   type={"text"} label={"Address"}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <Input id={form.addressLine2.id} validation={form.addressLine2.regex.expression}
                                   doValidate={form.addressLine2.validate}
                                   value={addressLine2}
                                   setValue={setAddressLine2}
                                   required={form.addressLine2.required}
                                   invalidFeedbackText={form.addressLine2.regex.helpText}
                                   type={"text"} label={"Address 2"}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <Input id={form.city.id} validation={form.city.regex.expression}
                                   doValidate={form.city.validate}
                                   value={city}
                                   setValue={setCity}
                                   required={form.city.required}
                                   invalidFeedbackText={form.city.regex.helpText}
                                   type={"text"} label={"City"}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <StateSelect id={form.state.id} validation={form.state.regex.expression}
                                         noSelectionText={"All states"}
                                         doValidate={form.state.validate}
                                         value={state} setValue={setState}
                                         required={form.state.required}
                                         invalidFeedbackText={form.state.regex.helpText}
                                         label={"State"}/>
                        </div>
                        <div className="col-md-3 mb-2">
                            <Input id={form.zip.id} validation={form.zip.regex.expression}
                                   doValidate={form.zip.validate}
                                   value={zip}
                                   setValue={setZip}
                                   required={form.zip.required}
                                   invalidFeedbackText={form.zip.regex.helpText}
                                   type={"number"} label={"Zip"}
                            />
                        </div>
                        <div className="col-md-3 mb-2">
                            <label htmlFor={"disabled"}>Disabled?</label>
                            <select id={"disabled"} className="form-control form-select"
                                    value={disabled} onChange={e => setDisabled(e.target.value)}
                                    required={true}
                            >
                                <option value={"false"}>No</option>
                                <option value={"true"}>Yes</option>
                            </select>
                        </div>

                    </div>
                    <button disabled={loading} type="submit" className="btn btn-primary mt-3">Search</button>
                </form>
            </div>
            {loading && <LoadingOverlay />}
            <Table headers={["Name", "Address"]} defaultPaginationSize={10}>
                {
                    searchResults.map((p, index) => (
                        <tr key={p.uuid + "_" + index}>
                            <td>{p.name}</td>
                            <td>{(p.address.addressLine1 || "") + " " + (p.address.addressLine2 || "") + " " +
                                (p.address.city || "") + " " + (p.address.stateCode || "") + " " + (p.address.zip || "")}</td>
                            <td><Link to={path + "/edit/" + p.uuid}><button className="btn btn-sm btn-primary">Edit</button></Link></td>
                        </tr>
                    ))
                }
            </Table>
        </PageLayout>
    </>
};

export default SearchProperties;
