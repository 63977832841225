import * as React from "react";
import {useEffect, useState} from "react";
import {ARAccount} from "../../../../types";
import {checkInTenantGuest, TenantGuest} from "../../../../Api/TenantGuestApi";
import {getTicketIdFromUuid} from "../../../../helpers";
import FormFieldBuilder, {assignFormFieldValues, FormFieldType} from "../../../Form/FieldBuilder/FormFieldBuilder";
import TenantGuestForm from "../Forms/TenantGuestForm";
import Table from "../../../Table";
import moment from "moment/moment";
import {useParams} from "react-router";
import Alert, {AlertProps, AlertType} from "../../../Alert";
import {CheckCircleFill, XCircleFill} from "../../../Icons/Icons";

const CheckInTenantGuest: React.FunctionComponent<{account: ARAccount, guest: TenantGuest, done: Function}> = ({account, guest, done}) => {

    const { propertyId, accountId, guestId } = useParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [alert, setAlert] = useState<AlertProps>();
    const [error, setError] = useState<Error>();
    const [isValid, setIsValid] = useState<boolean>(false);

    assignFormFieldValues({field: TenantGuestForm.accountId, value: account.unitName});
    assignFormFieldValues({field: TenantGuestForm.firstName, value: guest.firstName, disabledOverride: true});
    assignFormFieldValues({field: TenantGuestForm.lastName, value: guest.lastName, disabledOverride: true});
    assignFormFieldValues({field: TenantGuestForm.allowedKey, value: guest.allowedKey ? "Yes" : "No", disabledOverride: true, typeOverride: FormFieldType.INPUT});
    assignFormFieldValues({field: TenantGuestForm.secretPhrase, value: guest.secretPhrase, disabledOverride: true});
    assignFormFieldValues({field: TenantGuestForm.permanent, value: guest.permanent ? "Yes" : "No", disabledOverride: true, typeOverride: FormFieldType.INPUT});
    assignFormFieldValues({field: TenantGuestForm.startDate, value: guest.permanent ? "Permanent" : moment(guest.startDate).format("MM / DD / YYYY"), disabledOverride: true, typeOverride: FormFieldType.INPUT});
    assignFormFieldValues({field: TenantGuestForm.endDate, value: guest.permanent ? "Permanent" : moment(guest.endDate).format("MM / DD / YYYY"), disabledOverride: true, typeOverride: FormFieldType.INPUT});

    const checkIn = () => {
        setLoading(true);
        checkInTenantGuest(propertyId, accountId, guestId)
            .then(() => {
                setLoading(false);
                done();
            })
            .catch(setError);
    }

    useEffect(() => {
        if (guest.permanent) {
            setIsValid(true);
        }
        else {
            const now = moment();
            if (now.isSameOrAfter(guest.startDate) && now.isSameOrBefore(guest.endDate)) {
                setIsValid(true);
            }
            else {
                setAlert({ type: AlertType.WARNING, message: "Guest in unable to check in at this time."})
                setIsValid(false);
            }
        }

    }, [guest.permanent, guest.startDate, guest.endDate])


    return (<>
        <div className="row p-1">
            <div className="col-12">
                <div className="row">
                    <h4>Tenant Guest</h4>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Id</label>
                        <h6>{getTicketIdFromUuid(guest.uuid)}</h6>
                    </div>
                    <div className="col">
                        <label>Verified</label>
                        <h6>{guest.verified ? <CheckCircleFill color="green"/> : <XCircleFill color="red"/>}</h6>
                    </div>
                    <div className="col">
                        <label>Created By Management</label>
                        <h6>{guest.createdByManagement ? <CheckCircleFill color="green"/> : <XCircleFill color="red"/>}</h6>
                    </div>
                    <div className="col">
                        <label>Updated By</label>
                        <h6>{guest.updatedBy.firstName + " " + guest.updatedBy.lastName}</h6>
                    </div>
                </div>
            </div>
        </div>
        <div className="row p-1">
            <div className="col-6">
                <div className="row d-grid gap-2">
                    <div className="row">
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.accountId} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.firstName} />
                        </div>
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.lastName} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.allowedKey} selectOptions={TenantGuestForm.allowedKey.defaultSelectOptions}/>
                        </div>
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.permanent} selectOptions={TenantGuestForm.permanent.defaultSelectOptions}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.startDate} />
                        </div>
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.endDate} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormFieldBuilder field={TenantGuestForm.secretPhrase} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-6">
                <div className="col-12 widget">
                    <Table
                        headers={[
                            "#",
                            "Date",
                            "Checked In By"
                        ]}
                        defaultPaginationSize={5}>
                        {guest.checkInDates.sort((a, b) => { return moment(a.date).isBefore(moment(b.date)) ? 1 : 0}).map((checkIn, i) => (
                            <tr key={checkIn.date + "_" + i}>
                                <td>{guest.checkInDates.length - i}</td>
                                <td>{moment(checkIn.date).format("MMM Do, YYYY @ h:mm:ss a")}</td>
                                <td>{checkIn.checkedInBy.firstName + " " + checkIn.checkedInBy.lastName}</td>
                            </tr>
                        ))}
                    </Table>
                </div>
                <div className="col-12 mt-3">
                    {alert && <Alert type={alert.type} message={alert.message} />}
                </div>
                <div className="col-12 mt-3">
                    <button type="button" className="btn btn-primary" disabled={!isValid} onClick={() => checkIn()}>Check In</button>
                </div>
            </div>
        </div>
    </>)
}

export default CheckInTenantGuest;
