import React, { useEffect, useState, useRef } from "react";
import PageLayout from "../../../Layouts/PageLayout";
import { getTenants } from "../../../Api/PortfolioApi";
import { getPortfolioId } from "../../../helpers";
import { Tenant } from "../../../types";
import { TabulatorFull as Tabulator } from "tabulator-tables";

const ViewTenantDirectory: React.FunctionComponent = () => {
    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const tableRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        getTenants(getPortfolioId())
            .then((tz) => {
                setTenants(tz);
            })
            .catch((e) => setErrorMessage(e.message));
    }, []);

    useEffect(() => {
        if (tableRef.current && tenants.length) {
            const columns = [
                { title: "Last Name", field: "lastName", sorter: "string" as const },
                { title: "First Name", field: "firstName", sorter: "string" as const },
                { title: "Phone", field: "phone", sorter: "string" as const },
                { title: "Email", field: "email", formatter: (cell: any) => `<a href="mailto:${cell.getValue()}">${cell.getValue()}</a>` },
                {
                    title: "Lease Id",
                    field: "lease.externalId",
                    formatter: (cell: any) => {
                        const leaseUuid = cell.getRow().getData().lease.uuid;
                        const externalId = cell.getValue();
                        return `<a href="/p/m/customers/leases/${getPortfolioId()}/view/${leaseUuid}">${externalId}</a>`;
                    },
                },
                { title: "Start Date", field: "lease.startDate", sorter: "date" as const },
                { title: "End Date", field: "lease.endDate", sorter: "date" as const },
                { title: "Month to Month", field: "lease.isMonthToMonth", formatter: (cell: any) => (cell.getValue() ? "Yes" : "No") },
                { title: "Past Due", field: "lease.isPastDue", formatter: (cell: any) => (cell.getValue() ? "Yes" : "No") },
                {
                    title: "Property",
                    field: "property.name",
                    formatter: (cell: any) => {
                        const property = cell.getRow().getData().property;
                        return `<a href="/p/m/realestate/properties/edit/${property.uuid}">${property.name} - ${property.unitName}</a>`;
                    },
                },
            ];

            const table = new Tabulator(tableRef.current, {
                data: tenants,
                layout: "fitDataStretch",
                columns: columns,
                pagination: false,
                paginationSize: 25,
                initialSort: [{ column: "lastName", dir: "asc" }],
            });


            const exportButton = document.createElement("button");
            exportButton.textContent = "Export to PDF";
            exportButton.className = "btn btn-primary mb-3";
            exportButton.onclick = () => {
                table.download("pdf", "TenantDirectory.pdf", {
                    orientation: "portrait",
                    title: "Tenant Directory Report",
                });
            };

            if (tableRef.current?.parentElement) {
                tableRef.current.parentElement.insertBefore(exportButton, tableRef.current);
            }

            return () => {
                table.destroy();
            };
        }
    }, [tenants]);

    return (
        <PageLayout title={"Tenant Directory"}>
            <div className="row">
                <div className="col-md-12">
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    <div ref={tableRef}></div>
                </div>
            </div>
        </PageLayout>
    );
};

export default ViewTenantDirectory;
