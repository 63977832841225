import React, {useCallback, useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import Table from "../../../Components/Table";
import {PhoneNumber} from "../../../types";
import {getPropertyPhoneNumbers} from "../../../Api/PropertyApi";
import {useParams} from "react-router";
import moment from "moment/moment";
import parsePhoneNumber from "libphonenumber-js";
import PhoneNumberSearch from "../PhoneNumberSearch";


const PropertyPhoneNumbers: React.FunctionComponent = () => {
    const location = useLocation();

    const [phoneNumbers, setPhoneNumbers] = useState<PhoneNumber[]>([]);
    const [doAddPhoneNumber, setDoAddPhoneNumber] = useState(false);
    const [addPhoneNumberTag, setAddPhoneNumberTag] = useState("");
    const [hideReleased, setHideReleased] = useState(false);
    const {propertyUuid} = useParams();

    const load = useCallback(() => {
        getPropertyPhoneNumbers(propertyUuid)
            .then((pz) => {
                setPhoneNumbers(pz.sort((a, b) => Number(b.isActive) - Number(a.isActive)));
            })
            .catch((e) => console.error(e))
    }, [propertyUuid])

    useEffect(() => {
        load();
    }, [load]);

    function showAddBtn(pz: PhoneNumber[], tag: string) {

        if (tag === "notifications" && pz.filter((p) => p.tag === "notifications" && p.isActive).length !== 0) {
            return <></>;
        } else {
            return <>
                <div>
                    <button onClick={() => {
                        setDoAddPhoneNumber(true)
                        setAddPhoneNumberTag(tag)
                    }} type={"button"} className={"btn btn-primary"}>Add
                        Phone Number
                    </button>
                </div>
            </>
        }
    }

    function showStatus(isActive: boolean) {
        if (!isActive) {
            return <span
                className="badge rounded-pill bg-danger">Released</span>
        } else {
            return <span
                className="badge rounded-pill bg-success">Active</span>
        }
    }

    function showNumbers(tag: string) {
        return <>
            <div className={"row"}>
                <div className={"col"}>
                    <Table headers={["Phone Number", "Status", "Date Added"]} defaultPaginationSize={10}>
                        {
                            phoneNumbers.filter((p) => {
                                if (!hideReleased) {
                                    return p.tag === tag && p.isActive
                                } else {
                                    return p.tag === tag
                                }
                            }).map((p) => (
                                <tr key={p.uuid}>
                                    <td>{parsePhoneNumber(p.phoneNumber)?.formatNational()}</td>
                                    <td>
                                        {showStatus(p.isActive)}
                                    </td>
                                    <td>{moment(p.createdAt).format("MMM Do, YYYY")}</td>
                                    <td hidden={!p.isActive}>
                                        <Link to={location.pathname + "/" + p.uuid}>Edit</Link>
                                    </td>
                                    <td hidden={p.isActive}></td>
                                </tr>
                            ))}
                    </Table>
                </div>
            </div>
        </>
    }

    function getContent() {
        if (doAddPhoneNumber) {
            return getAddPhoneNumberComponent();
        } else {
            return getStartComponent();
        }
    }


    function getAddPhoneNumberComponent() {
        return <>
            <PhoneNumberSearch tag={addPhoneNumberTag} title={"Add Phone Number"} onComplete={(phoneNumber: string) => {
                setDoAddPhoneNumber(false);
                load();
            }} onBack={() => setDoAddPhoneNumber(false)}/>
        </>
    }

    function getStartComponent() {
        return <>
            <div id={"container"} className={"p-3"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <h4>Property Phone Numbers</h4>
                        <p>Phone numbers for sending Notifications, Marketing Campaigns and receiving Inbound
                            Communications. </p>
                        <button className="btn dropdown-toggle" type="button" role="button" data-bs-toggle="collapse"
                                data-bs-target={"#collapse_filters"}>Filters
                        </button>
                        <div className="collapse" id="collapse_filters">
                            <div className={"row"}>
                                <div className={"col"}>
                                    <button type="button" className="btn btn-outline-secondary" onClick={() => setHideReleased(true)} data-bs-toggle="collapse"
                                        data-bs-target={"#collapse_filters"}>Show Released Numbers
                                    </button>
                                    <button type="button" className="btn btn-outline-secondary" onClick={() => setHideReleased(false)} data-bs-toggle="collapse"
                                            data-bs-target={"#collapse_filters"}>Hide Released Numbers
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col"}>
                        <form className={"form-container"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h5>Notifications</h5>
                                    <p>This phone number will be used to communicate to Tenants within the property via
                                        SMS
                                        to send reminders such as Past Due Balances, Auto-Pay Scheduled,
                                        and other Reminders or customized messages. Please note that Tenants will only
                                        receive SMS messages if they have opted to receive on their Communication
                                        Preferences. Each Property is limited to a single Active Notification
                                        Number.</p>
                                    {showAddBtn(phoneNumbers, "notifications")}
                                </div>
                            </div>
                            {showNumbers("notifications")}
                        </form>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col"}>
                        <form className={"form-container"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h5>Inbound Communications</h5>
                                    <p>This phone number will be used to track Inbound Communications such as SMS,
                                        Voicemail, and Callback Requests for the property.</p>
                                    {showAddBtn(phoneNumbers, "inbound-communications")}
                                </div>
                            </div>
                            {showNumbers("inbound-communications")}
                        </form>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col"}>
                        <Link to={location.pathname.substring(0, location.pathname.length - "/phone-numbers".length)}>
                            <button className={"btn btn-danger"}>Go Back</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    }


    return <>
        {getContent()}
    </>

}

export default PropertyPhoneNumbers;