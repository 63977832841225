import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {
    AddressLine1Regex,
    AddressLine2Regex,
    CityRegex, DollarAmountRegex,
    FloorPlan, FloorPlanService,
    FormElementValidation,
    formInputValidate,
    formSelectValidate, GenericInputRegex, GenericNumberRegex,
    GenericSelectRegex,
    Property,
    PropertyNameRegex, Term, UnitService,
    ZipRegex
} from "../../../types";
import {useLocation, useParams} from "react-router";
import Input from "../../Form/Input";
import Alert, {AlertProps, AlertType} from "../../Alert";
import {Link} from "react-router-dom";
import LoadingOverlay from "../../LoadingOverlay";
import Select from "../../Form/Select";
import StateSelect from "../../StateSelect";
import Table from "../../Table";
import {getFloorPlans, getFloorPlanServices, getFloorPlanTerms} from '../../../Api/FloorPlanApi';
import {
    addUnitService, addUnitTerms,
    deleteUnitService, deleteUnitTerms,
    getUnit,
    getUnitServices,
    getUnitTerms,
    updateUnit
} from "../../../Api/UnitApi";
import {getProperty} from "../../../Api/PropertyApi";
import PageLayout from "../../../Layouts/PageLayout";
import ImageCard from "../../ImageCard";
import ButtonNew, {ButtonColors, ButtonTypes} from "../../ButtonNew";
import Card from "../../Card";

const EditUnit: React.FunctionComponent = () => {

    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

    const { search, pathname } = useLocation()
    const query = new URLSearchParams(search)
    const success = query.has("success")
    const {propertyUuid, unitUuid} = useParams();

    const [loading, setLoading] = useState(false);
    const [property, setProperty] = useState<Property>();
    const [floorPlans, setFloorPlans] = useState<FloorPlan[]>([])

    const [alert, setAlert] = useState<AlertProps>()

    const [unitName, setUnitName] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [floorPlan, setFloorPlan] = useState("");
    const [floorLevel, setFloorLevel] = useState("");
    const [deposit, setDeposit] = useState("");

    const [termUnit, setTermUnit] = useState("");
    const [length, setLength] = useState("");
    const [price, setPrice] = useState("");
    const [optional, setOptional] = useState("no");
    const [unitTerms, setUnitTerms] = useState<Term[]>([]);
    const [updateTerms, setUpdateTerms] = useState(true);
    const [floorPlansTerms, setFloorPlansTerms] = useState<Term[]>([]);

    const [addServicePrice, setAddServicePrice] = useState("");
    const [addServiceName, setAddServiceName] = useState("");
    const [addServiceDescription, setAddServiceDescription] = useState("");
    const [addServiceUnits, setAddServiceUnits] = useState("");
    const [unitServices, setUnitServices] = useState<UnitService[]>([]);
    const [floorPlanServices, setFloorPlanServices] = useState<FloorPlanService[]>([]);
    const [updateUnitServices, setUpdateUnitServices] = useState(false);
    const [disableUnit, setDisableUnit] = useState("");
    const [imageUrl, setImageUrl] = useState<string>()

    const [isSaving, setIsSaving] = useState<boolean>(false)

    const [isDefaultTermValid, setIsDefaultTermValid] = useState<boolean>(false)
    const [isDefaultAdditionalServiceValid, setIsDefaultAdditionalServiceValid] = useState<boolean>(false)

    useEffect(() => {
        if (success && unitName !== "") {
            doShowAlert("Successfully added unit: " + unitName, AlertType.SUCCESS);
        }
    }, [success, unitName])

    useEffect(() => {
        setIsDefaultTermValid(
            termUnit !== "" &&
            (termUnit === "month-to-month" || length !== "") &&
            price !== ""
        )
    }, [termUnit, length, price])

    useEffect(() => {
        setIsDefaultAdditionalServiceValid(
            optional !== "" &&
            addServicePrice !== "" &&
            addServiceName !== "" &&
            addServiceDescription !== "" &&
            addServiceUnits !== ""
        )
    }, [optional, addServicePrice, addServiceName, addServiceDescription, addServiceUnits])

    type Form = {
        unitName: FormElementValidation,
        addressLine1: FormElementValidation,
        addressLine2: FormElementValidation,
        city: FormElementValidation,
        state: FormElementValidation,
        zip: FormElementValidation,
        floorPlan: FormElementValidation,
        floorLevel: FormElementValidation,
        deposit: FormElementValidation,
        type: FormElementValidation,
        termUnit: FormElementValidation,
        length: FormElementValidation,
        price: FormElementValidation,
        disabled: FormElementValidation,
        optional: FormElementValidation,
        addServicePrice: FormElementValidation,
        addServiceName: FormElementValidation,
        addServiceDescription: FormElementValidation,
        addServiceUnits: FormElementValidation
    }

    const form: Form = {
        unitName: {
            id: "name",
            required: false,
            validate: formInputValidate,
            regex: PropertyNameRegex
        },
        floorLevel: {
            id: "floorLevel",
            required: false,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        deposit: {
            id: "deposit",
            required: false,
            validate: formInputValidate,
            regex: DollarAmountRegex
        },
        addressLine1: {
            id: "addressLine1",
            required: false,
            validate: formInputValidate,
            regex: AddressLine1Regex
        },
        addressLine2: {
            id: "addressLine2",
            required: false,
            validate: formInputValidate,
            regex: AddressLine2Regex
        },
        city: {
            id: "city",
            required: false,
            validate: formInputValidate,
            regex: CityRegex
        },
        state: {
            id: "state",
            required: false,
            validate: formInputValidate,
            regex: GenericSelectRegex
        },
        zip: {
            id: "zip",
            required: false,
            validate: formInputValidate,
            regex: ZipRegex
        },
        floorPlan: {
            id: "floorPlan",
            required: false,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        type: {
            id: "type",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        termUnit: {
            id: "termUnit",
            required: false,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        length: {
            id: "length",
            required: false,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        price: {
            id: "price",
            required: false,
            validate: formInputValidate,
            regex: DollarAmountRegex
        },
        disabled: {
            id: "disabled",
            required: false,
            validate: formInputValidate,
            regex: GenericSelectRegex
        },
        optional: {
            id: "optional",
            required: true,
            validate: formInputValidate,
            regex: GenericSelectRegex
        },
        addServiceName: {
            id: "addServiceName",
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        addServiceDescription: {
            id: "addServiceDescription",
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        addServicePrice: {
            id: "addServicePrice",
            required: false,
            validate: formInputValidate,
            regex: DollarAmountRegex
        },
        addServiceUnits: {
            id: "addServiceUnits",
            required: false,
            validate: formInputValidate,
            regex: GenericSelectRegex
        },
    }



    useEffect(() => {
        if(floorPlan && updateTerms) {
            setUpdateTerms(false);
            Promise.all([
                getFloorPlanTerms(propertyUuid, floorPlan)
                    .then(data => data),
                getUnitTerms(propertyUuid, unitUuid)
                    .then(data => data)
            ])
                .then(values => {
                    setFloorPlansTerms(values[0]);
                    setUnitTerms(values[1]);
                })
                .catch(e => console.error(e))
                .finally(() => setLoading(false));
        }
    }, [propertyUuid, unitUuid, floorPlan, updateTerms]);

    useEffect(() => {
        if(floorPlan) {
            getFloorPlanServices(propertyUuid, floorPlan)
                .then((s) => {
                    setFloorPlanServices(s);
                })
                .catch((e) => console.error(e));
        }
    }, [floorPlan])

    useEffect(() => {
        if (updateUnitServices) {
            setUpdateUnitServices(false);
            Promise.all([
                getUnitServices(propertyUuid, unitUuid),
                getFloorPlanServices(propertyUuid, floorPlan)
            ])
                .then(values => {
                    setUnitServices(values[0]);
                    setFloorPlanServices(values[1]);
                })
                .catch((e) => {
                    console.error(e);
                })
        }
    }, [updateUnitServices, propertyUuid, unitUuid, floorPlan])

    useEffect(() => {

        setLoading(true);
        Promise.all([
            getProperty(propertyUuid)
                .then(data => data),
            getUnit(propertyUuid, unitUuid)
                .then(data => data),
            getFloorPlans(propertyUuid)
                .then(data => data),
            getUnitServices(propertyUuid, unitUuid)
        ])
            .then(values => {
                setProperty(values[0]);
                setFloorPlans(values[2]);
                setUnitServices(values[3]);
                const unit = values[1];
                if(unit.floorLevel !== undefined) {
                    setFloorLevel(unit.floorLevel + "");
                }
                if(unit.deposit !== undefined) {
                    setDeposit(unit.deposit+"" );
                }
                setUnitName(unit.name || "");
                setAddressLine1(unit.address.addressLine1 || "");
                setAddressLine2(unit.address.addressLine2 || "");
                setCity(unit.address.city || "");
                setState(unit.address.stateCode || "");
                setZip(unit.address.zip + "" || "");
                setFloorPlan(unit.floorPlan.uuid || "");
                setDisableUnit(unit.disabled ? "disabled" : "enabled");
                setImageUrl(unit.imageUrl)
            })
            .catch((e) => console.error(e))
            .finally(() => setLoading(false));

    }, [propertyUuid, unitUuid]);

    const doShowAlert = (message: string, type: AlertType) => {
        setAlert({type, message})
    }

    const validateForm = (form: Form): boolean => {
        if (!property) {
            doShowAlert("Failed to add unit.  Could not retrieve property details", AlertType.DANGER);
            return false;
        }
        if (!form.unitName.validate(unitName, form.unitName.required, form.unitName.regex.expression)) {
            doShowAlert("Unit name invalid.", AlertType.DANGER);
            return false;
        }
        if (!form.addressLine1.validate(addressLine1, form.addressLine1.required, form.addressLine1.regex.expression)) {
            doShowAlert("Address invalid.", AlertType.DANGER);
            return false;
        }
        if (!form.addressLine2.validate(addressLine2, form.addressLine2.required, form.addressLine2.regex.expression)) {
            doShowAlert("Address 2 invalid.", AlertType.DANGER);
            return false;
        }
        if (!form.city.validate(city, form.city.required, form.city.regex.expression)) {
            doShowAlert("City invalid.", AlertType.DANGER);
            return false;
        }
        if (!form.state.validate(state, form.state.required, form.state.regex.expression)) {
            doShowAlert("Select a state.", AlertType.DANGER);
            return false;
        }
        if (!form.zip.validate(zip, form.zip.required, form.zip.regex.expression)) {
            doShowAlert("Zip invalid.", AlertType.DANGER);
            return false;
        }
        if (!form.floorPlan.validate(floorPlan, form.floorPlan.required, form.floorPlan.regex.expression)) {
            doShowAlert("Select a floor plan.", AlertType.DANGER);
            return false;
        }
        return true;

    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!validateForm(form)) {
            return;
        }
        setIsSaving(true)
        updateUnit(propertyUuid, unitUuid, {
            name: unitName,
            floorLevel: floorLevel,
            deposit: deposit,
            address: {
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                stateCode: state,
                zip: zip
            },
            floorPlan: {
                uuid: floorPlan
            },
            disabled: disableUnit === "disabled" ? true : false,
            imageUrl: imageUrl || ""
        })
            .then(data => {
                setUpdateTerms(true);
                doShowAlert("Successfully updated unit: " + unitName, AlertType.SUCCESS);
            })
            .catch(e => {
                doShowAlert("Failed to update unit: " + e.message, AlertType.DANGER);
            })
            .finally(() => setIsSaving(false))
    }

    const getDisplayNameOfTerm = (units: string, length: string) => {
        switch (units) {
            case "daily":
                return "Daily";
            case "week-to-week":
                return "Week-to-Week";
            case "month-to-month":
                return "Month-to-Month";
            case "fixed_day":
                return length + " Day" + ((parseInt(length) > 1) ? "s" : "");
            case "fixed_week":
                return length + " Week" + ((parseInt(length) > 1) ? "s" : "");
            case "fixed_month":
                return length + " Month" + ((parseInt(length) > 1) ? "s" : "");
            case "fixed_year":
                return length + " Year" + ((parseInt(length) > 1) ? "s" : "");
            default:
                return "";
        }
    }

    const addFloorPlanTerm = () => {
        addUnitTerms(propertyUuid, unitUuid, {
            units: termUnit,
            price: price,
            length: (length === undefined || length === "") ? "0" : length
        }).then((r) => {
            setTermUnit("");
            setPrice("");
            setLength("");
            setUpdateTerms(true);
        }).catch((e) => {
            console.error(e);
        })
    }

    const deleteTerm = (termUuid: string) => {
        deleteUnitTerms(propertyUuid, unitUuid, termUuid)
            .then((r) => {
                setUpdateTerms(true);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const deleteService = (serviceUuid: string) => {
        deleteUnitService(propertyUuid, unitUuid, serviceUuid)
            .then((r) => {
                setUpdateUnitServices(true);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const addUnitAdditionalService = () => {
        addUnitService(propertyUuid, unitUuid, {
            units: addServiceUnits,
            price: addServicePrice,
            name: addServiceName,
            description: addServiceDescription,
            optional: (optional === "yes")
        }).then((r) => {
            setAddServiceUnits("");
            setAddServicePrice("");
            setAddServiceName("");
            setAddServiceDescription("");
            setUpdateUnitServices(true);
            setOptional("no");
        }).catch((e) => {
            console.error(e);
        })
    }

    return (loading ? <LoadingOverlay /> : <>
        <PageLayout
            title="Edit Unit"
            property={property}
            alert={alert}
        >
            <div className="d-flex justify-content-start gap-5">
                <div>
                    <ImageCard
                        imageUrl={imageUrl}
                        setImageUrl={setImageUrl}
                        canUpload={true}
                        onError={m => setAlert({type: AlertType.DANGER, message: m})}
                        required={true}
                    />
                </div>
                <div className="col-lg-6">
                    <div className="mb-4">
                        <Card title="General Information">
                            <div className={"row"}>
                                <div className={"col-lg-6 mb-1"}>
                                    <Input id={form.unitName.id} validation={form.unitName.regex.expression}
                                           doValidate={form.unitName.validate}
                                           value={unitName}
                                           setValue={setUnitName}
                                           required={form.unitName.required}
                                           invalidFeedbackText={form.unitName.regex.helpText}
                                           type={"text"} label={"Unit name"}
                                    />
                                </div>
                                <div className={"col-lg-6 mb-1"}>
                                    <Input id={form.floorLevel.id} validation={form.floorLevel.regex.expression}
                                           doValidate={form.floorLevel.validate}
                                           value={floorLevel}
                                           setValue={setFloorLevel}
                                           required={form.floorLevel.required}
                                           invalidFeedbackText={form.floorLevel.regex.helpText}
                                           type={"number"} label={"Floor level"}
                                    />
                                </div>
                                <div className={"col-lg-6 mb-1"}>
                                    <Input id={form.deposit.id} validation={form.deposit.regex.expression}
                                           doValidate={form.deposit.validate}
                                           value={deposit}
                                           setValue={setDeposit}
                                           required={form.deposit.required}
                                           invalidFeedbackText={form.deposit.regex.helpText}
                                           type={"number"} label={"Deposit"}
                                    />
                                    <p>This unit will inherit the deposit amount set on the floor plan unless overridden here.</p>
                                </div>
                                <div className={"col-lg-6 mb-1"}>
                                    <Select id={form.floorPlan.id} validation={form.floorPlan.regex.expression}
                                            doValidate={form.floorPlan.validate}
                                            value={floorPlan} setValue={setFloorPlan}
                                            required={form.floorPlan.required}
                                            invalidFeedbackText={form.floorPlan.regex.helpText} label={"Floor plan"}>
                                        <option value={""} disabled={true}>Select a floor plan</option>
                                        {
                                            floorPlans.map((fp, index) => (
                                                <option key={fp.uuid + "_" + index} value={fp.uuid}>{fp.name}</option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                <div className={"col-lg-6 mb-1"}>
                                    <Input id={form.addressLine1.id} validation={form.addressLine1.regex.expression}
                                           doValidate={form.addressLine1.validate}
                                           value={addressLine1}
                                           setValue={setAddressLine1}
                                           required={form.addressLine1.required}
                                           invalidFeedbackText={form.addressLine1.regex.helpText}
                                           type={"text"} label={"Address"}
                                    />
                                </div>
                                <div className={"col-lg-6 mb-1"}>
                                    <Input id={form.addressLine2.id} validation={form.addressLine2.regex.expression}
                                           doValidate={form.addressLine2.validate}
                                           value={addressLine2}
                                           setValue={setAddressLine2}
                                           required={form.addressLine2.required}
                                           invalidFeedbackText={form.addressLine2.regex.helpText}
                                           type={"text"} label={"Address 2"}
                                    />
                                </div>
                                <div className={"col-lg-4 mb-1"}>
                                    <Input id={form.city.id} validation={form.city.regex.expression}
                                           doValidate={form.city.validate}
                                           value={city}
                                           setValue={setCity}
                                           required={form.city.required}
                                           invalidFeedbackText={form.city.regex.helpText}
                                           type={"text"} label={"City"}
                                    />
                                </div>
                                <div className={"col-lg-4 mb-1"}>
                                    <StateSelect id={form.state.id} validation={form.state.regex.expression}
                                                 noSelectionText={"All states"}
                                                 doValidate={form.state.validate}
                                                 value={state} setValue={setState}
                                                 required={form.state.required}
                                                 invalidFeedbackText={form.state.regex.helpText}
                                                 label={"State"}/>
                                </div>
                                <div className={"col-lg-4 mb-1"}>
                                    <Input id={form.zip.id} validation={form.zip.regex.expression}
                                           doValidate={form.zip.validate}
                                           value={zip}
                                           setValue={setZip}
                                           required={form.zip.required}
                                           invalidFeedbackText={form.zip.regex.helpText}
                                           type={"number"} label={"Zip"}
                                    />
                                </div>
                                <div className={"col-lg-6 mt-3 mb-1"}>
                                    <Select id={form.disabled.id} validation={form.disabled.regex.expression}
                                            doValidate={form.disabled.validate}
                                            value={disableUnit} setValue={setDisableUnit}
                                            required={form.disabled.required}
                                            invalidFeedbackText={form.disabled.regex.helpText} label={"Unit Status"}>
                                        <option disabled={true} value={""}>Select Unit Status</option>
                                        <option value={"enabled"}>Enabled</option>
                                        <option value={"disabled"}>Disabled</option>
                                    </Select>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="mb-4">
                        <Card title="Terms">
                            <div className={"row"}>
                                <div className={"col-lg-3 mb-1"}>
                                    <Select id={form.termUnit.id} validation={form.termUnit.regex.expression}
                                            doValidate={form.termUnit.validate}
                                            value={termUnit}
                                            setValue={setTermUnit}
                                            required={form.termUnit.required}
                                            invalidFeedbackText={form.termUnit.regex.helpText}
                                            label={"Unit"}
                                    >
                                        <option disabled={true} value={""}>Choose a term...</option>
                                        <option value={"month-to-month"}>Month-to-Month</option>
                                        <option value={"fixed_month"}>Month(s)</option>
                                    </Select>
                                </div>
                                <div className={"col-lg-3 mb-1"}>
                                    <Input id={form.length.id} validation={form.length.regex.expression}
                                           doValidate={form.length.validate}
                                           value={length}
                                           setValue={setLength}
                                           required={form.length.required}
                                           disabled={!termUnit.startsWith("fixed_")}
                                           invalidFeedbackText={form.length.regex.helpText}
                                           type={"text"} label={"Length"}
                                    />
                                </div>
                                <div className={"col-lg-3 mb-1"}>
                                    <Input id={form.price.id} validation={form.price.regex.expression}
                                           doValidate={form.price.validate}
                                           value={price}
                                           setValue={setPrice}
                                           required={form.price.required}
                                           invalidFeedbackText={form.price.regex.helpText}
                                           type={"text"} label={"Price"}
                                    />
                                </div>
                                <div className={"col-lg-3 mb-1"}>
                                    <button type={"button"} className={"btn btn-primary"}
                                            style={{marginTop: 20}}
                                            disabled={!isDefaultTermValid}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                addFloorPlanTerm();
                                            }}>
                                        Add
                                    </button>
                                </div>
                                <div className="col-md-12">
                                    <Table headers={["Term", "Price"]} defaultPaginationSize={10}>
                                        {
                                            floorPlansTerms.map((t, index) => (
                                                <tr key={t.uuid + "_" + index}>
                                                    <td>{getDisplayNameOfTerm(t.units, t.length)}</td>
                                                    <td>{currencyFormat.format(parseFloat(t.price))}</td>
                                                    <td></td>
                                                </tr>
                                            ))
                                        }
                                        {
                                            unitTerms.map((t, index) => (
                                                <tr key={t.uuid + "_" + index}>
                                                    <td>{getDisplayNameOfTerm(t.units, t.length)}</td>
                                                    <td>{currencyFormat.format(parseFloat(t.price))}</td>
                                                    <td className="d-flex justify-content-end">
                                                        <button className={"btn btn-sm btn-primary"}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    deleteTerm(t.uuid);
                                                                }}
                                                        >Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </Table>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="mb-4">
                        <Card title="Additional Services">
                            <div className={"row"}>
                                <div className={"col-lg-4 mb-1"}>
                                    <Select id={form.addServiceUnits.id} validation={form.addServiceUnits.regex.expression}
                                            doValidate={form.addServiceUnits.validate}
                                            value={addServiceUnits}
                                            setValue={setAddServiceUnits}
                                            required={form.addServiceUnits.required}
                                            invalidFeedbackText={form.addServiceUnits.regex.helpText}
                                            label={"Unit"}
                                    >
                                        <option disabled={true} value={""}>Choose one...</option>
                                        <option value={"monthly"}>Monthly</option>
                                    </Select>
                                </div>
                                <div className={"col-lg-4 mb-1"}>
                                    <Select id={form.optional.id} validation={form.optional.regex.expression}
                                            doValidate={form.optional.validate}
                                            value={optional}
                                            setValue={setOptional}
                                            required={form.optional.required}
                                            invalidFeedbackText={form.optional.regex.helpText}
                                            label={"Optional"}
                                    >
                                        <option value={""} disabled>Please Select...</option>
                                        <option value={"no"}>No</option>
                                        <option value={"yes"}>Yes</option>
                                    </Select>
                                </div>
                                <div className={"col-lg-4 mb-1"}>
                                    <Input id={form.addServiceName.id} validation={form.addServiceName.regex.expression}
                                           doValidate={form.addServiceName.validate}
                                           value={addServiceName}
                                           setValue={setAddServiceName}
                                           required={form.addServiceName.required}
                                           invalidFeedbackText={form.addServiceName.regex.helpText}
                                           type={"text"} label={"Name"}
                                    />
                                </div>
                                <div className={"col-lg-4 mb-1"}>
                                    <Input id={form.addServiceDescription.id}
                                           validation={form.addServiceDescription.regex.expression}
                                           doValidate={form.addServiceDescription.validate}
                                           value={addServiceDescription}
                                           setValue={setAddServiceDescription}
                                           required={form.addServiceDescription.required}
                                           invalidFeedbackText={form.addServiceDescription.regex.helpText}
                                           type={"text"} label={"Description"}
                                    />
                                </div>
                                <div className={"col-lg-4 mb-1"}>
                                    <Input id={form.addServicePrice.id} validation={form.addServicePrice.regex.expression}
                                           doValidate={form.addServicePrice.validate}
                                           value={addServicePrice}
                                           setValue={setAddServicePrice}
                                           required={form.addServicePrice.required}
                                           invalidFeedbackText={form.addServicePrice.regex.helpText}
                                           type={"number"} label={"Price"}
                                    />
                                </div>
                                <div className={"col-lg-4 mb-1"}>
                                    <button type={"button"} className={"btn btn-primary"}
                                            style={{marginTop: 20}}
                                            disabled={!isDefaultAdditionalServiceValid}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                addUnitAdditionalService()
                                            }}>
                                        Add
                                    </button>
                                </div>
                                <div className="col-lg-12">
                                    <Table headers={["Term", "Optional", "Name", "Description", "Price"]} defaultPaginationSize={10}>
                                        {
                                            floorPlanServices.map((us, index) => (
                                                <tr key={us.uuid + "_" + index}>
                                                    <td>{us.units}</td>
                                                    <td>{(us.optional) ? "Yes" : "No"}</td>
                                                    <td>{us.name}</td>
                                                    <td>{us.description}</td>
                                                    <td>{currencyFormat.format(parseFloat(us.price))}</td>
                                                    <td></td>
                                                </tr>
                                            ))
                                        }
                                        {
                                            unitServices.map((us, index) => (
                                                <tr key={us.uuid + "_" + index}>
                                                    <td>{us.units}</td>
                                                    <td>{(us.optional) ? "Yes" : "No"}</td>
                                                    <td>{us.name}</td>
                                                    <td>{us.description}</td>
                                                    <td>{currencyFormat.format(parseFloat(us.price))}</td>
                                                    <td className="d-flex justify-content-end">
                                                        <button className={"btn btn-sm btn-primary"}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    deleteService(us.uuid);
                                                                }}
                                                        >Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </Table>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="d-flex justify-content-start gap-2">
                        <Link to={"/p/m/realestate/units/" + propertyUuid}>
                            <button type="button" className="btn btn-secondary"
                                    onClick={() => {
                                    }}>Back
                            </button>
                        </Link>
                        <ButtonNew
                            type={ButtonTypes.BUTTON}
                            color={ButtonColors.PRIMARY}
                            label="Save"
                            loading={isSaving}
                            onClick={onSubmit}
                            style={{width: "5rem"}}
                        />
                    </div>
                </div>
            </div>
        </PageLayout>
    </>);
};

export default EditUnit;
