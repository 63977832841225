import * as React from "react";
import {useCallback, useContext, useEffect, useState} from "react";
import { getProfileId, getPropertyTemplates } from "../../Api/EsignApi";
import { ESIGN_UI_ENDPOINT } from "../../Api/EsignApi";
import Table from "../../Components/Table";
import moment from "moment";
import { Template } from "../../types";
import { AUTH_ACCESS_TOKEN, AUTH_REFRESH_TOKEN, AUTH_EXPIRES_IN, AUTH_SCOPE, AUTH_TOKEN_TYPE, AUTH_CLOCK_START } from "../../Auth";
import {PropertyIdContext} from "../PrivateMainContainer";

const DocumentsContainer: React.FunctionComponent = () => {

    const propertyId = useContext(PropertyIdContext)

    const [profileId, setProfileId] = useState("");
    const [showCreateTemplate, setShowCreateTemplate] = useState<boolean>(false);
    const [showReviewTemplate, setShowReviewTemplate] = useState<boolean>(false);
    const [selectedTemplate,setSelectedTemplate] = useState("");
    const [sortBy, setSortBy] = useState("latest");

    const [propertyTemplates, setPropertyTemplates] = useState<Template[]>([]);

    const load = useCallback(() => {
        if (propertyId) {
            getProfileId(propertyId).then((p) => {
                setProfileId(p.uuid);
            })
            getPropertyTemplates(propertyId).then((t) => {
                setPropertyTemplates(t);
            })
        }
    }, [propertyId]);

    useEffect(() => {
        load();
    }, [load]);

    function iframePathBuilder(profileId: string, ext?: string) {

        const access_token = localStorage.getItem(AUTH_ACCESS_TOKEN);
        const refresh_token = localStorage.getItem(AUTH_REFRESH_TOKEN);
        const expires_in = localStorage.getItem(AUTH_EXPIRES_IN);
        const scope = localStorage.getItem(AUTH_SCOPE);
        const token_type = localStorage.getItem(AUTH_TOKEN_TYPE);
        const clock_start = localStorage.getItem(AUTH_CLOCK_START);
        let redirect_uri;
        
        if (ext) {
             redirect_uri = "/p/m/" + profileId + "/templates/" + ext + "?inFrame=true";
        } else {
             redirect_uri = "/p/m/" +profileId + "/templates?inFrame=true";
        }

        if (access_token && refresh_token && expires_in && scope && token_type && clock_start && redirect_uri) {
            let path = ESIGN_UI_ENDPOINT + "iframe_callback" + "?access_token=" + access_token + "&refresh_token=" + refresh_token + "&expires_in=" + expires_in + "&scope="
            + scope + "&token_type=" + token_type + "&clock_start=" + clock_start + "&redirect_uri=" + encodeURIComponent(redirect_uri);
            return path;
        } else {
            return ""
        }
    }

    function onCreate() {
        setShowCreateTemplate(true);
    }

    function onReview(templateId: string) {
        setSelectedTemplate(templateId);
        setShowReviewTemplate(true);
    }

    const onBack = function () {
        setShowCreateTemplate(false);
        setShowReviewTemplate(false);
        load();
    }

    function renderEsignIframe(src: string) {
        return <>
            <div style={{ height: '120vh' }}>
                <button className='btn btn-secondary' onClick={onBack}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z" />
                    </svg>
                    <span className='ms-1'> Return to list</span>
                </button>
                <iframe src={src} style={{ margin: 'auto', width: '100%', height: '100%' }}></iframe>
            </div>
        </>
    }

    function getContent() {
        if (!showCreateTemplate && !showReviewTemplate) {
            return <>
                <div className="row">
                    <div className="col">
                        <h3>Esign Templates</h3>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col">
                        <button className="btn btn-primary" onClick={onCreate}>Create New Template</button>
                    </div>
                </div>

                <div className={"row"}>
                        <div className={"col"}>
                            <div className="d-flex float-end">
                                <div className="dropdown mt-1">
                                    <button className="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuOffset" data-bs-toggle="dropdown" aria-expanded="false">
                                        Sort By
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuOffset">
                                        <li><a className="dropdown-item" onClick={() => { setSortBy("latest") }}>Created At - Latest</a></li>
                                        <li><a className="dropdown-item" onClick={() => { setSortBy("oldest") }}>Created At - Oldest</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                <div className={"row"}>
                    <div className={"col"}>
                        <Table headers={["Created At", "Name", "Last Modified", "Description", "Status", "Days to Complete", ""]}
                            defaultPaginationSize={10}>
                            {propertyTemplates.sort((t1,t2) => (sortBy === "latest" ? (t1.createdAt > t2.createdAt ? -1 : 1) 
                            : (t1.createdAt < t2.createdAt ? -1 : 1)) )
                            .map(t =>
                                <tr key={t.uuid}>
                                    <td>{moment(t.createdAt).calendar()}</td>
                                    <td>{t.name}</td>
                                    <td>{moment(t.lastModified).calendar()}</td>
                                    <td>{t.description}</td>
                                    <td>{t.status}</td>
                                    <td>{t.daysToComplete}</td>
                                    <td style={{ textAlign: 'center' }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-square" viewBox="0 0 16 16"
                                        onClick={() => onReview(t.uuid)}>
                                            <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                        </svg>
                                    </td>
                                </tr>
                            )}
                        </Table>
                    </div>
                </div>
            </>
        } else {
            if (showCreateTemplate) {
                return renderEsignIframe(iframePathBuilder(profileId,"create"));
            } else if (showReviewTemplate && selectedTemplate) {
                return renderEsignIframe(iframePathBuilder(profileId,selectedTemplate));
            }
        }
    }

    return (<>
        {getContent()}
    </>);
};

export default DocumentsContainer;