import * as React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { CommunityLink, getCommunityLinkById } from "../../../Api/CommunityLinkApi";
import { getTicketIdFromUuid } from "../../../helpers";
import ViewPropertyInfo from "../../PropertyInfo/ViewPropertyInfo";
import Tabs, { Tab } from "../../Tabs/Tabs";
import EditCommunityLink from "../EditCommunityLink";
import OverviewCommunityLink from "../OverviewCommunityLink/OverviewCommunityLink";
const ManageCommunityLinks: React.FunctionComponent = () => {

    enum CommunityLinkView {
        OVERVIEW = "OVERVIEW", EDIT = "EDIT"
    }

    const { propertyUuid, communityLinkUuid } = useParams();

    const [view, setView] = useState<string>(CommunityLinkView.OVERVIEW);
    const [communityLink, setCommunityLink] = useState<CommunityLink>();

    const tabs: Tab[] = [
        {
            targetView: CommunityLinkView.OVERVIEW,
            label: "Overview"
        },
        {
            targetView: CommunityLinkView.EDIT,
            label: "Edit"
        }
    ]

    const fetchCommunityLink = () => {
        Promise.all([
            getCommunityLinkById(propertyUuid, communityLinkUuid)
                .then(data => data)
                .then(communityLink => {
                    setCommunityLink(communityLink)
                })
        ])
    }

    useEffect(() => {
        fetchCommunityLink();
    }, [propertyUuid, communityLinkUuid])

    return (
        <React.Fragment>
            <Tabs view={view} setView={setView} tabs={tabs} />
            <div className="col-md-12 widget">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <h3>Community Link</h3>
                        <label htmlFor="tp_uuid">Link Id</label>
                        <h5 id="tp_uuid">{getTicketIdFromUuid(communityLink?.uuid || '')}</h5>
                        <label htmlFor="tp_pn">Property</label>
                        <ViewPropertyInfo />

                    </div>
                    <div className="col-md-6 mb-3">
                        <div hidden={view !== CommunityLinkView.OVERVIEW}>
                            {communityLink &&
                                <OverviewCommunityLink communityLink={communityLink} />
                            }
                        </div>

                        <div hidden={view !== CommunityLinkView.EDIT}>
                            {communityLink &&
                                <EditCommunityLink communityLink={communityLink} done={() => {
                                    setView(CommunityLinkView.OVERVIEW)
                                    fetchCommunityLink()
                                }}/>
                            }
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">

                    </div>
                </div>

                <div className="row">

                </div>
            </div>
        </React.Fragment>
    )
}

export default ManageCommunityLinks;