import * as React from "react";
import {useHistory, useParams} from "react-router";
import {useContext, useEffect, useState} from "react";
import {Property} from "../../../types";
import {Amenity, getAmenities} from "../../../Api/AmenityApi";
import {getProperty} from "../../../Api/PropertyApi";
import LoadingOverlay from "../../LoadingOverlay";
import {Link, useLocation} from "react-router-dom";
import Table from "../../Table";
import {getTicketIdFromUuid} from "../../../helpers";
import PageLayout from "../../../Layouts/PageLayout";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";

const ViewAmenities: React.FunctionComponent = () => {

    const propertyId = useContext(PropertyIdContext)
    const { pathname } = useLocation();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(true);
    const [property, setProperty] = useState<Property>();
    const [amenities, setAmenities] = useState<Amenity[]>([]);

    useEffect(() => {
        if(propertyId) {
            setLoading(true);
            Promise.all([
                getProperty(propertyId)
                    .then(data => data),
                getAmenities(propertyId)
                    .then(data => data)
            ])
                .then(values => {
                    setProperty(values[0]);
                    setAmenities(values[1]);
                })
                .catch(console.error)
                .finally(() => setLoading(false));
        }
    }, [propertyId]);

    return <>
        {property ?
            <PageLayout title="Amenities" property={property} addButtonLabel="Add Amenity">
                <Table
                    headers={[
                        "Id",
                        "Name",
                        "Description",
                        "Location"
                    ]}
                    defaultPaginationSize={10}>
                    {amenities?.map(amenity => (
                        <tr className={"clickable"} key={amenity.uuid + "_am"}
                            onClick={() => history.push({ pathname: pathname + "/manage/" + amenity.uuid })}
                        >
                            <td>{getTicketIdFromUuid(amenity.uuid)}</td>
                            <td>{amenity.name}</td>
                            <td>{amenity.description}</td>
                            <td>{amenity.location}</td>
                        </tr>
                    ))}
                </Table>
            </PageLayout> :
            <LoadingOverlay />
        }
    </>
}

export default ViewAmenities;
