import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {
    Applicant,
    ApplicationStatus, DateRegex, DollarAmountRegex,
    Employment,
    FormElementValidation, formInputValidate,
    formSelectValidate, FullNameRegex, GenericSelectRegex, PhoneNumberRegex
} from "../../../types";
import Alert, {AlertProps, AlertType} from "../../Alert";
import {useHistory, useParams} from "react-router";
import {getPortfolioId} from "../../../helpers";
import LoadingOverlay from "../../LoadingOverlay";
import Select from "../../Form/Select";
import Input from "../../Form/Input";
import {getApplication, updateApplication} from "../../../Api/ApplicationApi";
import ApplicationStageLayout from "../../../Layouts/ApplicationStageLayout";
import ButtonNew, {ButtonColors, ButtonTypes} from "../../ButtonNew";

type Stage4Props = {
    handleInvalidApplicationStatus: Function
    applicationId: string
    setStage: Function
}

const Stage4: React.FunctionComponent<Stage4Props> = (p) => {

    const { propertyId } = useParams();

    const [error, setError] = useState<Error>()
    const [alert, setAlert] = useState<AlertProps>()
    const [loading, setLoading] = useState(false);
    const [doRedirectToStage3, setDoRedirectToStage3] = useState(false);
    const [doRedirectToStage5, setDoRedirectToStage5] = useState(false);
    const history = useHistory();
    const [doRedirectToStage1, setDoRedirectToStage1] = useState(false);
    const [doHandleInvalidApplicationStatus, setDoHandleInvalidApplicationStatus] = useState(false);

    //form
    const [applicants, setApplicants] = useState(new Array<Applicant>());

    const [applicant, setApplicant] = useState("");
    const [employerName, setEmployerName] = useState("");
    const [employerContactPerson, setEmployerContactPerson] = useState("");
    const [employerPhone, setEmployerPhone] = useState("");
    const [occupation, setOccupation] = useState("");
    const [monthlyGrossIncome, setMonthlyGrossIncome] = useState("");
    const [startDate, setStartDate] = useState("");
    const [noEndDate, setNoEndDate] = useState(false);
    const [endDate, setEndDate] = useState("");

    type Form = {
        applicant: FormElementValidation,
        employerName: FormElementValidation,
        employerContactPerson: FormElementValidation,
        employerPhone: FormElementValidation,
        occupation: FormElementValidation,
        monthlyGrossIncome: FormElementValidation,
        startDate: FormElementValidation,
        endDate: FormElementValidation
    }

    const form: Form = {
        applicant: {
            id: "applicant",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        employerName: {
            id: "employerName",
            required: true,
            validate: formInputValidate,
            regex: FullNameRegex
        },
        employerContactPerson: {
            id: "employerContactPerson",
            required: true,
            validate: formInputValidate,
            regex: FullNameRegex
        },
        employerPhone: {
            id: "employerPhone",
            required: true,
            validate: formInputValidate,
            regex: PhoneNumberRegex
        },
        occupation: {
            id: "occupation",
            required: true,
            validate: formInputValidate,
            regex: FullNameRegex
        },
        monthlyGrossIncome: {
            id: "monthlyGrossIncome",
            required: true,
            validate: formInputValidate,
            regex: DollarAmountRegex
        },
        startDate: {
            id: "startDate",
            required: true,
            validate: formInputValidate,
            regex: DateRegex
        },
        endDate: {
            id: "endDate",
            required: true,
            validate: formInputValidate,
            regex: DateRegex
        }
    };


    useEffect(() => {
        if (doHandleInvalidApplicationStatus) {
            p.handleInvalidApplicationStatus();
        }
    }, [doHandleInvalidApplicationStatus, p]);

    useEffect(() => {
        if (doRedirectToStage1) {
            setDoRedirectToStage1(false);
            history.push({
                pathname: "/p/m/customers/applications/add/1"
            })
        }

    }, [doRedirectToStage1, history])

    useEffect(() => {
        console.log("stage 4 init");
        p.setStage(4)
        setLoading(true);
        getApplication(getPortfolioId(), p.applicationId)
            .then((a) => {
                if (a.status !== ApplicationStatus.approved) {
                    if (a.applicants && a.applicants.length > 0) {
                        setApplicants(a.applicants);
                    }
                    if (!a.unit || (a.unit && !a.unit.uuid)) {
                        console.log("redirect to stage 1, missing unit")
                        setDoRedirectToStage1(true);
                        return;
                    }
                    setLoading(false);
                } else {
                    setDoHandleInvalidApplicationStatus(true);
                }
            })
            .catch(e => {
                doShowAlert("Failed to start application: " + e.message, AlertType.DANGER);
                setLoading(false);
            })
    }, [p])

    const saveAndContinueStage5 = () => {
        setLoading(true);
        updateApplication(getPortfolioId(), p.applicationId, {
            applicants: applicants
        })
            .then((a) => {
                setDoRedirectToStage5(true);
            })
            .catch(e => {
                doShowAlert("Failed to add employments: " + e.message, AlertType.DANGER);
            }).finally(() => setLoading(false));
    }

    const clearForm = () => {
        setApplicant("");
        setEmployerName("");
        setEmployerContactPerson("");
        setEmployerPhone("");
        setOccupation("");
        setMonthlyGrossIncome("");
        setStartDate("");
        setNoEndDate(false);
        setEndDate("");
    }

    const doShowAlert = (message: string, type: AlertType) => {
        setAlert({type, message})
    }

    useEffect(() => {
        if (doRedirectToStage5) {
            setDoRedirectToStage5(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/5"
            });
        }

    }, [doRedirectToStage5, history])

    useEffect(() => {
        if (doRedirectToStage3) {
            setDoRedirectToStage3(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/3"
            });
        }

    }, [doRedirectToStage3, history]);

    const validateForm = (form: Form): boolean => {
        if (!form.applicant.validate(applicant, form.applicant.required, form.applicant.regex.expression)) {
            doShowAlert("An applicant must be selected.", AlertType.DANGER);
            return false;
        }
        if (!form.employerName.validate(employerName, form.employerName.required, form.employerName.regex.expression)) {
            doShowAlert("Invalid employer name.", AlertType.DANGER);
            return false;
        }
        if (!form.employerContactPerson.validate(employerContactPerson, form.employerContactPerson.required, form.employerContactPerson.regex.expression)) {
            doShowAlert("Invalid employer contact person.", AlertType.DANGER);
            return false;
        }
        if (!form.employerPhone.validate(employerPhone, form.employerPhone.required, form.employerPhone.regex.expression)) {
            doShowAlert("Invalid employer phone.", AlertType.DANGER);
            return false;
        }
        if (!form.occupation.validate(occupation, form.occupation.required, form.occupation.regex.expression)) {
            doShowAlert("Invalud occupation.", AlertType.DANGER);
            return false;
        }
        if (!form.monthlyGrossIncome.validate(monthlyGrossIncome, form.monthlyGrossIncome.required, form.monthlyGrossIncome.regex.expression)) {
            doShowAlert("Invalid monthly gross income.", AlertType.DANGER);
            return false;
        }
        if (!form.startDate.validate(startDate, form.startDate.required, form.startDate.regex.expression)) {
            doShowAlert("Invalid start date.", AlertType.DANGER);
            return false;
        }
        if (!form.endDate.validate(endDate, !noEndDate, form.endDate.regex.expression)) {
            doShowAlert("Invalid end date.", AlertType.DANGER);
            return false;
        }
        return true;
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!validateForm(form)) {
            return;
        }
        applicants.forEach(i => {
            if (i.uuid === applicant) {
                let employment: Employment = {
                    uuid: "",
                    employerName: employerName,
                    employmentContactPerson: employerContactPerson,
                    employerPhone: employerPhone,
                    monthlyGrossIncome: parseFloat(monthlyGrossIncome),
                    occupation: occupation,
                    startDate: startDate,
                    noEndDate: noEndDate,
                    endDate: (endDate === "") ? undefined : endDate
                }
                if (i.employmentIncomeSources) {
                    i.employmentIncomeSources.push(employment)
                } else {
                    i.employmentIncomeSources = [employment];
                }
            }
        })
        setApplicants([...applicants]);
        clearForm();
    }

    const deleteEmployment = (a: Applicant, e: Employment) => {
        applicants.forEach(app => {
            if (app === a) {
                app.employmentIncomeSources = app.employmentIncomeSources.filter(emp => emp !== e);
            }
        })
        setApplicants([...applicants]);
    }

    const [isStageValid, setIsStageValid] = useState<boolean>(false)

    useEffect(() => {
        setIsStageValid(applicants.filter(a => a.employmentIncomeSources?.length > 0).length === applicants.length)
    }, [applicants])

    return (loading ? <div className="col-md-6"><LoadingOverlay /></div> : <>
        <ApplicationStageLayout
            error={error}
            alert={alert}
            title="Employment"
            description="Please provide current employment information for all applicants and lease guarantors"
        >
            <form onSubmit={onSubmit}>
                <div className={"col-lg-6 col-md-8 mb-5"}>
                    <div className={"row"}>
                        <div className="col-md-12 mb-2">
                            <Select id={form.applicant.id} validation={form.applicant.regex.expression}
                                    doValidate={form.applicant.validate}
                                    value={applicant} setValue={setApplicant}
                                    required={form.applicant.required}
                                    invalidFeedbackText={form.applicant.regex.helpText} label={"Applicant"}>
                                <option value={""} disabled={true}>Select an applicant...</option>
                                {
                                    applicants.map((a, index) => (
                                        <option key={"appl_" + index}
                                                value={a.uuid}>{a.firstName + " " + a.lastName}</option>
                                    ))
                                }
                            </Select>
                        </div>
                        <div className={"col-md-6 mb-2"}>
                            <Input id={form.employerName.id} validation={form.employerName.regex.expression}
                                   doValidate={form.employerName.validate}
                                   value={employerName}
                                   setValue={setEmployerName}
                                   required={form.employerName.required}
                                   invalidFeedbackText={form.employerName.regex.helpText}
                                   type={"text"} label={"Employer name"}
                            />
                        </div>
                        <div className={"col-md-6 mb-2"}>
                            <Input id={form.employerContactPerson.id}
                                   validation={form.employerContactPerson.regex.expression}
                                   doValidate={form.employerContactPerson.validate}
                                   value={employerContactPerson}
                                   setValue={setEmployerContactPerson}
                                   required={form.employerContactPerson.required}
                                   invalidFeedbackText={form.employerContactPerson.regex.helpText}
                                   type={"text"} label={"Employer contact person"}
                            />
                        </div>
                        <div className={"col-md-6 mb-2"}>
                            <Input id={form.employerPhone.id} validation={form.employerPhone.regex.expression}
                                   doValidate={form.employerPhone.validate}
                                   value={employerPhone}
                                   setValue={setEmployerPhone}
                                   placeholder={"xxx-xxx-xxxx"}
                                   required={form.employerPhone.required}
                                   invalidFeedbackText={form.employerPhone.regex.helpText}
                                   type={"text"} label={"Employer phone"}
                            />
                        </div>
                        <div className={"col-md-6 mb-2"}>
                            <Input id={form.occupation.id} validation={form.occupation.regex.expression}
                                   doValidate={form.occupation.validate}
                                   value={occupation}
                                   setValue={setOccupation}
                                   required={form.occupation.required}
                                   invalidFeedbackText={form.occupation.regex.helpText}
                                   type={"text"} label={"Occupation"}
                            />
                        </div>
                        <div className={"col-md-6 mb-2"}>
                            <Input id={form.monthlyGrossIncome.id} validation={form.monthlyGrossIncome.regex.expression}
                                   doValidate={form.monthlyGrossIncome.validate}
                                   value={monthlyGrossIncome}
                                   setValue={setMonthlyGrossIncome}
                                   required={form.monthlyGrossIncome.required}
                                   invalidFeedbackText={form.monthlyGrossIncome.regex.helpText}
                                   type={"number"} label={"Monthly gross income"}
                            />
                        </div>
                        <div className={"col-md-6 mb-2"}>
                            <Input id={form.startDate.id} validation={form.startDate.regex.expression}
                                   doValidate={form.startDate.validate}
                                   value={startDate}
                                   setValue={setStartDate}
                                   required={form.startDate.required}
                                   invalidFeedbackText={form.startDate.regex.helpText}
                                   type={"date"} label={"Start date"}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value=""
                                       id="defaultCheck1"
                                       checked={noEndDate}
                                       onChange={e => {
                                           let value = e.target.checked;
                                           setNoEndDate(value);
                                           if (value) {
                                               setEndDate("");
                                           }
                                       }}/>
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                    Current Employer
                                </label>
                            </div>
                        </div>
                        <div className={"col-md-6 mb-2"}>

                            <Input id={form.endDate.id} validation={form.endDate.regex.expression}
                                   doValidate={form.endDate.validate}
                                   value={endDate}
                                   setValue={setEndDate}
                                   required={!noEndDate}
                                   disabled={noEndDate}
                                   invalidFeedbackText={form.endDate.regex.helpText}
                                   type={"date"} label={"End date"}
                            />
                        </div>
                        <div className={"col-md-12 mb-2"}>
                            <button type="submit" className="btn btn-primary mr-3">Add employment</button>
                        </div>
                    </div>
                </div>
            </form>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <div className={"table-responsive"}>
                        <table className="table table-striped">
                            <thead>
                            <tr>

                                <th scope="col">Applicant</th>
                                <th scope="col">Employer name</th>
                                <th scope="col">Employer contact person</th>
                                <th scope="col">Employer phone</th>
                                <th scope="col">Start date</th>
                                <th scope="col">End date</th>
                                <th scope="col">Monthly gross income</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                applicants.filter(ap => ap.employmentIncomeSources).map((ap, apindex) => (
                                    ap.employmentIncomeSources.map((emp, empindex) => (
                                        <tr key={"app_" + apindex + "emp_" + empindex}>
                                            <td>{ap.firstName + " " + ap.lastName}</td>
                                            <td>{emp.employerName}</td>
                                            <td>{emp.employmentContactPerson}</td>
                                            <td>{emp.employerPhone}</td>
                                            <td>{emp.startDate}</td>
                                            <td>{emp.endDate}</td>
                                            <td>{emp.monthlyGrossIncome}</td>
                                            <td>
                                                <button type="button" className="btn btn-danger btn-sm"
                                                        onClick={e => deleteEmployment(ap, emp)}
                                                >Remove
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="d-flex justify-content-start gap-2 mt-3">
                    <ButtonNew
                        type={ButtonTypes.BUTTON}
                        color={ButtonColors.SECONDARY}
                        label="Back"
                        onClick={() => setDoRedirectToStage3(true)}
                    />
                    <ButtonNew
                        type={ButtonTypes.BUTTON}
                        color={ButtonColors.PRIMARY}
                        label="Save & Continue"
                        loading={loading}
                        disabled={false}
                        onClick={saveAndContinueStage5}
                    />
                </div>
            </div>
        </ApplicationStageLayout>
    </>);
};

export default Stage4;
