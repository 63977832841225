import React from "react";
import {CommunityEvent} from "../../../Api/CommunityEventsApi";
import {getTicketIdFromUuid} from "../../../helpers";
import CommunityEventForm from "../Forms/CommunityEventForm";
import FormFieldBuilder, {
    assignFormFieldValues,
    FormFieldType,
    InputFieldType
} from "../../Form/FieldBuilder/FormFieldBuilder";
import moment from "moment";

const OverviewCommunityEvent: React.FunctionComponent<{event: CommunityEvent | undefined}> = ({event}) => {

    assignFormFieldValues({field: CommunityEventForm.name, value: event?.name, disabledOverride: true});
    assignFormFieldValues({field: CommunityEventForm.category, value: event?.category, disabledOverride: true});
    assignFormFieldValues({field: CommunityEventForm.description, value: event?.description, disabledOverride: true});
    assignFormFieldValues({field: CommunityEventForm.startDate, value: moment(event?.startTime).format("MM / DD / YYYY"), typeOverride: FormFieldType.INPUT, disabledOverride: true});
    assignFormFieldValues({field: CommunityEventForm.startTime, value: moment(event?.startTime).format("hh:ss A"), inputTypeOverride: InputFieldType.TEXT, disabledOverride: true});
    assignFormFieldValues({field: CommunityEventForm.endDate, value: moment(event?.endTime).format("MM / DD / YYYY"), typeOverride: FormFieldType.INPUT, disabledOverride: true});
    assignFormFieldValues({field: CommunityEventForm.endTime, value: moment(event?.endTime).format("hh:ss A"), inputTypeOverride: InputFieldType.TEXT, disabledOverride: true});

    return <>
        <div className="row p-1">
            <div className="col-12">
                <label>Id</label>
                <h6>{getTicketIdFromUuid(event?.uuid)}</h6>
            </div>
            <div className="col-md-6">
                <div className="row">
                    <div className="col mb-2">
                        <FormFieldBuilder field={CommunityEventForm.name} />
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-2">
                        <FormFieldBuilder field={CommunityEventForm.category} />
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-2">
                        <FormFieldBuilder field={CommunityEventForm.description} />
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="row">
                    <div className="col mb-2">
                        <FormFieldBuilder field={CommunityEventForm.startDate} />
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-2">
                        <FormFieldBuilder field={CommunityEventForm.startTime} />
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-2">
                        <FormFieldBuilder field={CommunityEventForm.endDate} />
                    </div>
                </div>
                <div className="row">
                    <div className="col mb-2">
                        <FormFieldBuilder field={CommunityEventForm.endTime} />
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default OverviewCommunityEvent;
