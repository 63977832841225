import React, {FormEvent, useState} from "react";
import {getAvailablePhoneNumbers} from "../../../Api/PhoneApi";
import {AddPhoneNumberBody, AvailablePhoneNumber} from "../../../types";
import Table from "../../../Components/Table";
import FormFieldBuilder, {assignFormFieldValues} from "../../../Components/Form/FieldBuilder/FormFieldBuilder";
import PhoneNumberSearchForm from "./Forms/PhoneNumberSearchForm";
import {addPropertyPhoneNumber} from "../../../Api/PropertyApi";
import {useParams} from "react-router";

type Params = {
    tag: string,
    title: string,
    onComplete: Function,
    onBack: Function
}
const PhoneNumberSearch: React.FunctionComponent<Params> = (p) => {

    const {propertyUuid} = useParams();

    const [availablePhoneNumbers, setAvailablePhoneNumbers] = useState<AvailablePhoneNumber[]>([]);
    const [areaCode, setAreaCode] = useState("");
    const [loading, setLoading] = useState(false);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");


    assignFormFieldValues({field: PhoneNumberSearchForm.areaCode, value: areaCode, setValue: setAreaCode})

    const onsubmit = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        getAvailablePhoneNumbers(areaCode)
            .then((r) => {
                setAvailablePhoneNumbers(r);
            }).catch((e) => console.error(e.message))
            .finally(() => setLoading(false));
    };

    const onAdd = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        let phoneNumberBody: AddPhoneNumberBody = {
            phoneNumber: selectedPhoneNumber,
            tag: p.tag
        };
        addPropertyPhoneNumber(propertyUuid, phoneNumberBody)
            .then(() => {
                p.onComplete(phoneNumberBody.phoneNumber);
            })
            .catch((e) => console.error(e.message)).finally(() => setLoading(false));
    };

    function getSearchComponent() {
        return <>
            <div className={"row"}>
                <div className={"col"}>
                    <h4>Search Available Phone Numbers</h4>
                    <p>Find readily available phone numbers to associate with your property for sending notifications,
                        marketing campaigns, and receiving inbound
                        communications. </p>
                </div>
            </div>
            <form className={"form-container"} onSubmit={onsubmit}>
                <div className={"row"}>
                    <div className={"col"}>
                        <FormFieldBuilder field={PhoneNumberSearchForm.areaCode}/>
                    </div>
                    <div className={"col"} style={{paddingTop: 22}}>
                        <button className={"btn btn-primary"}>Search</button>
                    </div>
                    <div className={"col"}></div>
                </div>
            </form>

            <div className={"row"}>
                <div className={"col"}>
                    <div hidden={!loading} className="spinner-grow text-primary"
                         role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <div hidden={loading}>
                        <Table headers={["Phone Number"]} defaultPaginationSize={10}>
                            {
                                availablePhoneNumbers.map((p) => (
                                    <tr key={p.phoneNumber}>
                                        <td>{p.phoneNumber}</td>
                                        <td>
                                            <button className={"btn btn-link text-primary"} onClick={() => {
                                                setSelectedPhoneNumber(p.phoneNumber)
                                            }}>Select Phone Number
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </Table>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <button onClick={() => p.onBack()} type={"button"} className={"btn btn-danger"}>Go Back</button>
                </div>
            </div>
        </>
    }

    function getAddPhoneNumber(title: string) {
        return <>
            <div className={"row"}>
                <div className={"col"}>
                    <h4>{title}</h4>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <form className={"form-container"} onSubmit={onAdd}>
                        <div className={"row"}>
                            <h5>Confirm Phone Number</h5>
                            <p>Please review the below information before confirming the addition of the following phone
                                to the property. Once the request is submitted the action cannot
                                be undone. Any fees outlined in your subscription package regarding phone numbers will
                                be billed to the account
                                associated with the property.</p>
                            <h6><b>Selected phone number:</b> {selectedPhoneNumber}</h6>
                            <h6><b>Type:</b> <span
                                className="badge rounded-pill bg-secondary">{p.tag}</span></h6>

                            <div hidden={loading} className={"col"} style={{paddingTop: 10}}>
                                <button className={"btn btn-success"}>Confirm</button>
                            </div>

                        </div>
                        <div hidden={!loading} className="spinner-grow text-primary"
                             role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </form>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <button className={"btn btn-danger"} onClick={() => {
                        setSelectedPhoneNumber("")
                    }}>Cancel
                    </button>
                </div>
            </div>
        </>
    }

    function getContent() {
        if (selectedPhoneNumber) {
            return getAddPhoneNumber(p.title);
        } else {
            return getSearchComponent();
        }
    }

    return <>
        <div id={"container"} className={"p-3"}>
            {getContent()}
        </div>
    </>
};

export default PhoneNumberSearch;