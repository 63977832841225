import React, {FormEvent, useEffect, useState} from "react";
import {CommunityEvent, deleteCommunityEvent, updateCommunityEvent} from "../../../Api/CommunityEventsApi";
import CommunityEventForm from "../Forms/CommunityEventForm";
import FormFieldBuilder, {
    assignFormFieldValues,
    FormFieldType,
    InputFieldType
} from "../../Form/FieldBuilder/FormFieldBuilder";
import {useHistory, useParams} from "react-router";
import {AlertProps} from "../../Alert";
import moment from "moment";
import {getTicketIdFromUuid} from "../../../helpers";

const EditCommunityEvent: React.FunctionComponent<{event: CommunityEvent, done: Function}> = ({event, done}) => {

    const { propertyId } = useParams();
    const history = useHistory();

    const [alert, setAlert] = useState<AlertProps>();
    const [error, setError] = useState<Error>();
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    const [name, setName] = useState<string>("");
    const [category, setCategory] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [startDate, setStartDate] = useState<string>("");
    const [startTime, setStartTime] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");

    const [isValid, setIsValid] = useState<boolean>(false);

    assignFormFieldValues({field: CommunityEventForm.name, value: name, setValue: setName, disabledOverride: false});
    assignFormFieldValues({field: CommunityEventForm.category, value: category, setValue: setCategory, disabledOverride: false});
    assignFormFieldValues({field: CommunityEventForm.description, value: description, setValue: setDescription, disabledOverride: false});
    assignFormFieldValues({field: CommunityEventForm.startDate, value: startDate, setValue: setStartDate, typeOverride: FormFieldType.DATE, disabledOverride: false});
    assignFormFieldValues({field: CommunityEventForm.startTime, value: startTime, setValue: setStartTime, inputTypeOverride: InputFieldType.TIME, disabledOverride: false});
    assignFormFieldValues({field: CommunityEventForm.endDate, value: endDate, setValue: setEndDate, typeOverride: FormFieldType.DATE, disabledOverride: false});
    assignFormFieldValues({field: CommunityEventForm.endTime, value: endTime, setValue: setEndTime, inputTypeOverride: InputFieldType.TIME, disabledOverride: false});

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (isValid) {
            updateCommunityEvent(propertyId, {
                uuid: event.uuid,
                name,
                category,
                description,
                startTime: startDate + "T" + startTime + ":00",
                endTime: endDate + "T" + endTime + ":00",
            })
                .then(() => done())
                .catch(setError)
        }
    }

    const onReset = (e: FormEvent) => {
        e.preventDefault();
        setName(event.name);
        setCategory(event.category);
        setDescription(event.description);
        setStartDate(moment(event.startTime).format("YYYY-MM-DD"));
        setStartTime(moment(event.startTime).format("HH:mm"));
        setEndDate(moment(event.endTime).format("YYYY-MM-DD"));
        setEndTime(moment(event.endTime).format("HH:mm"));
    }

    const onDelete = () => {
        deleteCommunityEvent(propertyId, event.uuid)
            .then(() => history.push("/p/m/tenant-services/community-events/" + propertyId))
            .catch(setError);
    }

    useEffect(() => {
        const isNameValid = name !== "";
        const isCategoryValid = category !== "";
        const isDescriptionValid = description !== "";
        const isStartDateValid = startDate !== "";
        const isStartTimeValid = startTime !== "";
        const isEndDateValid = endDate !== "";
        const isEndTimeValid = endTime !== "";
        setIsValid(isNameValid && isCategoryValid && isDescriptionValid && isStartDateValid && isStartTimeValid && isEndDateValid && isEndTimeValid);
    }, [name, category, description, startDate, startTime, endDate, endTime])

    useEffect(() => {
        setName(event.name);
        setCategory(event.category);
        setDescription(event.description);
        setStartDate(moment(event.startTime).format("YYYY-MM-DD"));
        setStartTime(moment(event.startTime).format("HH:mm"));
        setEndDate(moment(event.endTime).format("YYYY-MM-DD"));
        setEndTime(moment(event.endTime).format("HH:mm"));
    }, [event])

    return <>
        {confirmDelete && <>
            <div className="alert alert-warning" role="alert">
                <p>Are you sure you would like to delete this community event?  This action cannot be undone.</p>
                <button className="btn btn-danger me-2" type="button" onClick={() => setConfirmDelete(false)}>No</button>
                <button className="btn btn-success" type="button" onClick={() => onDelete()}>Yes</button>
            </div>
        </>}
        <form onSubmit={onSubmit} onReset={onReset}>
            <div className="row p-1 form-container">
                <div className="col-12">
                    <label>Id</label>
                    <h6>{getTicketIdFromUuid(event?.uuid)}</h6>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col mb-2">
                            <FormFieldBuilder field={CommunityEventForm.name} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mb-2">
                            <FormFieldBuilder field={CommunityEventForm.category} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mb-2">
                            <FormFieldBuilder field={CommunityEventForm.description} />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col mb-2">
                            <FormFieldBuilder field={CommunityEventForm.startDate} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mb-2">
                            <FormFieldBuilder field={CommunityEventForm.startTime} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mb-2">
                            <FormFieldBuilder field={CommunityEventForm.endDate} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mb-2">
                            <FormFieldBuilder field={CommunityEventForm.endTime} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row p-1">
                <div className="col-12">
                    <div className="d-flex w-100 justify-content-between">
                        <button type="button" className="btn btn-outline-danger" onClick={() => setConfirmDelete(true)}>Delete</button>
                        <div className="btn-group">
                            <button className="btn btn-outline-primary" type="reset">Reset</button>
                            <button className="btn btn-primary" type="submit" disabled={!isValid}>Update</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>
}

export default EditCommunityEvent;
