import * as React from 'react';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import Input from "../../../Form/Input";
import { DateRegex, DayOfMonthRegex, GenericInputRegex, GenericSelectRegex, LateFeeDto, TransactionAccount, formInputValidate } from "../../../../types";
import { Link, useParams } from "react-router-dom";
import Alert, { AlertProps, AlertType } from "../../../Alert";
import Card from "../../../Card";
import Select from '../../../Form/Select';
import { LateFeeFrequency, LateFeeType } from '../../../../Pages/LateFeeConfig/Forms/LateFeeForm';
import Table from '../../../Table';
import { getLateFeeAccountName, renderPercentOrDollarAmount } from '../../../../helpers';

type TermsProp = {
    next: Function,
    startDate: string,
    endDate: string,
    isMonthToMonth: boolean,
    dueDate: string
    accounts: TransactionAccount[];
}

const Terms: React.FunctionComponent<TermsProp> = (p) => {
    const { applicationId } = useParams();
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [startDateIsValid, setStartDateIsValid] = useState(false);
    const [addLateFee, setAddLateFee] = useState<boolean>(false);
    const [lateFeeDtoList, setLateFeeDtoList] = useState<LateFeeDto[]>([])
    const [lateFeeExists, setLateFeeExists] = useState<boolean>(false);

    const [alert, setAlert] = useState<AlertProps>()
    const [endDate, setEndDate] = useState("");
    const [endDateIsValid, setEndDateIsValid] = useState(false);

    const [isMonthToMonth, setIsMonthToMonth] = useState(false);

    const [dueDate, setDueDate] = useState("");
    const [dueDateIsValid, setDueDateIsValid] = useState(false);
    const [lateFeeConfig, setLateFeeConfig] = useState<LateFeeDto>();
    const [lateFeeDescription, setLateFeeDescription] = useState("");
    const [lateFeeType, setLateFeeType] = useState("");
    const [gracePeriod, setGracePeriod] = useState("");
    const [feeAmount, setFeeAmount] = useState("");
    const [frequency, setFrequency] = useState("");
    const [recievableAccountId, setRecievableAccountId] = useState("");
    const [accounts, setAccounts] = useState<TransactionAccount[]>([]);

    const doShowAlert = (message: string, type: AlertType) => {
        setAlert({ type, message })
    }

    useEffect(() => {
        if (p.startDate) {
            setStartDate(p.startDate);
        }
        if (p.endDate) {
            setEndDate(p.endDate);
        }
        if (p.isMonthToMonth) {
            setIsMonthToMonth(p.isMonthToMonth);
        }
        if (p.dueDate) {
            setDueDate(p.dueDate)
        }
        if (p.accounts) {
            setAccounts(p.accounts);
            forceRecievableAccountValue();
        }
    }, [p.dueDate, p.endDate, p.isMonthToMonth, p.startDate, p.accounts]);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!dueDateIsValid) {
            doShowAlert("Invalid due date", AlertType.DANGER);
            return;
        }
        if (!startDateIsValid) {
            doShowAlert("Invalid start date", AlertType.DANGER);
            return;
        }
        if (!isMonthToMonth && !endDateIsValid) {
            doShowAlert("Invalid end date", AlertType.DANGER);
            return;
        }
        p.next({
            startDate: startDate,
            endDate: endDate,
            dueDate: dueDate,
            isMonthToMonth: isMonthToMonth,
            lateFeeDtoList: lateFeeDtoList
        });
    }

    function verifyLateFeeDto(d: LateFeeDto) {
        return (d.amount && d.description && d.feeType && d.frequency && d.gracePeriod && d.revenueAccountId);
    }

    function onCancel() {
        setAddLateFee(false)
        clearLateFee()
    }

    function clearLateFee() {
        setGracePeriod("")
        setFrequency("")
        setLateFeeType("")
        setLateFeeDescription("")
        setFeeAmount("")
    }

    //Below function only here for MVP1

    function forceRecievableAccountValue() {
        let tenantFeeAccount = p.accounts.filter(p => p.name.toLowerCase() === "tenant fee")[0];
        if (tenantFeeAccount) {
            setRecievableAccountId(tenantFeeAccount.uuid);
        }
    }

    useEffect(() => {
        setLateFeeConfig({
            gracePeriod: Number(gracePeriod),
            frequency: frequency,
            feeType: lateFeeType,
            amount: Number(feeAmount),
            revenueAccountId: recievableAccountId,
            description: lateFeeDescription
        });
    }, [gracePeriod, frequency, lateFeeType, feeAmount, recievableAccountId, lateFeeDescription]);

    const onAdd = () => {
        if (lateFeeConfig && verifyLateFeeDto(lateFeeConfig)) {
            lateFeeDtoList.push(lateFeeConfig)
            setLateFeeDtoList([...lateFeeDtoList])
            onCancel()
        }
    }

    const onDelete = (index: number) => {
        const updatedList = [...lateFeeDtoList.slice(0, index), ...lateFeeDtoList.slice(index + 1)]
        setLateFeeDtoList(updatedList)
        onCancel()
    }

    useEffect(() => {
        if (lateFeeDtoList.length !== 0) {
            setLateFeeExists(true)
        } else {
            setLateFeeExists(false)
        }
    }, [lateFeeDtoList])

    function getLateFeeContent() {
        return <>
            <div hidden={addLateFee} className='text-start'>
                <button className={"btn btn-sm btn-primary"} type={"submit"} onClick={() => setAddLateFee(true)}>{lateFeeExists ? "Add Additional Late Fee" : "Add Late Fee"}</button>
            </div>
            <div hidden={!addLateFee}>
                <div className={"row"}>
                    <div className={"col-lg-6 mb-3"}>
                        <p className={"small mb-2"}>When should we charge this late fee?</p>
                        <Input id={"gracePeriod"}
                            value={gracePeriod}
                            setValue={setGracePeriod}
                            required={addLateFee}
                            doValidate={formInputValidate}
                            validation={DayOfMonthRegex.expression}
                            invalidFeedbackText={DayOfMonthRegex.helpText}
                            type={"number"}
                            label={"Days after due date"}
                        />
                    </div>

                    <div className={"col-lg-6 mb-3"}>
                        <p className={"small mb-2"}>How often should this fee be charged ?</p>
                        <Select id={"frequency"}
                            value={frequency}
                            setValue={setFrequency}
                            required={addLateFee}
                            doValidate={formInputValidate}
                            validation={GenericSelectRegex.expression}
                            invalidFeedbackText={GenericSelectRegex.helpText}
                            label={"Frequency"}
                        >
                            <option value={""} selected disabled hidden>Select an option...
                            </option>
                            <option value={LateFeeFrequency.ONCE}> Once
                            </option>
                            <option value={LateFeeFrequency.DAILY}>Daily</option>
                            <option value={LateFeeFrequency.WEEKLY}>Weekly</option>
                            <option value={LateFeeFrequency.MONTHLY}>Monthly</option>
                        </Select>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-lg-6 mb-3"}>
                        <p className={"small mb-2"}>How should we calculate this fee?</p>

                        <Select id={"LateFeeType"}
                            value={lateFeeType}
                            setValue={setLateFeeType}
                            required={addLateFee}
                            doValidate={formInputValidate}
                            validation={GenericSelectRegex.expression}
                            invalidFeedbackText={GenericSelectRegex.helpText}
                            label={"Late Fee Type"}
                        >
                            <option value={""} selected disabled hidden>Select an option...
                            </option>
                            <option value={LateFeeType.FIXED_AMOUNT}> Fixed Amount
                            </option>
                            <option value={LateFeeType.PERCENT}>Percent of Balance</option>
                        </Select>
                    </div>
                    <div className={"col-lg-6 mb-1"}>
                        <p className={"small mb-2"}>How much should we charge?</p>
                        {lateFeeType === LateFeeType.PERCENT ?
                            <Input id={"description"}
                                value={feeAmount}
                                setValue={setFeeAmount}
                                disabled={!lateFeeType}
                                placeholder="%"
                                min={0}
                                max={100}
                                required={addLateFee}
                                doValidate={formInputValidate}
                                validation={GenericInputRegex.expression}
                                invalidFeedbackText={GenericInputRegex.helpText}
                                type={"number"}
                                label={"Late Fee Percent (%)"}
                            />
                            :
                            <Input id={"fixedAmount"}
                                value={feeAmount}
                                setValue={setFeeAmount}
                                disabled={!lateFeeType}
                                required={addLateFee}
                                min={0}
                                doValidate={formInputValidate}
                                validation={GenericInputRegex.expression}
                                invalidFeedbackText={GenericInputRegex.helpText}
                                type={"number"}
                                label={"Late Fee Amount ($)"}
                            />
                        }
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-lg-6 mb-3"}>
                        <p className={"small mb-2"}>Which Account should the late fees be added to?</p>
                        <Select id={"recievableAccount"}
                            value={recievableAccountId}
                            setValue={setRecievableAccountId}
                            required={addLateFee}
                            doValidate={formInputValidate}
                            validation={GenericSelectRegex.expression}
                            invalidFeedbackText={GenericSelectRegex.helpText}
                            label={"Account"}
                            disabled={true}> //disabled for MVP1
                            {/* <option value={""} selected disabled hidden>Select an option...
                                </option> */}
                            {accounts.filter(p => p.name.toLowerCase() === "tenant fee").map((p) => {
                                return <>
                                    <option value={p.uuid}>{p.name}</option>
                                </>
                            })}
                        </Select>
                    </div>
                    <div className={"col-lg-6 mb-3"}>
                        <p className={"small mb-2"}>How we should we label this charge for Tenants?</p>
                        <Input id={"description"}
                            value={lateFeeDescription}
                            setValue={setLateFeeDescription}
                            required={addLateFee}
                            doValidate={formInputValidate}
                            validation={GenericInputRegex.expression}
                            invalidFeedbackText={GenericInputRegex.helpText}
                            type={"text"}
                            label={"Description"}
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col'>
                        <button className={"btn btn-danger"} type={"button"} onClick={onCancel} >Cancel</button>
                        <button className={"btn btn-primary"} type={"button"} onClick={onAdd}>Add Late Fee</button>
                    </div>
                </div>
            </div>
            <div>
                <div className='row' hidden={!lateFeeExists}>
                    <Table headers={["Description", "Days after due date", "Type", "Frequency", "Amount / Percent", "Account", ""]} defaultPaginationSize={5}>
                        {lateFeeDtoList.sort((a, b) => Number(a.gracePeriod) - Number(b.gracePeriod)).map((f, index) => (
                            <tr key={index}>
                                <td>{f.description}</td>
                                <td>{f.gracePeriod}</td>
                                <td>{f.feeType}</td>
                                <td>{f.frequency}</td>
                                <td>{renderPercentOrDollarAmount(f)}</td>
                                <td>{getLateFeeAccountName(accounts, f.revenueAccountId)}</td>
                                <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    className="bi bi-trash3" viewBox="0 0 16 16" onClick={() => onDelete(index)} >
                                    <path
                                        d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                </svg></td>
                            </tr>
                        ))}
                    </Table>
                </div>
            </div>
        </>
    }

    return (<>
        <form onSubmit={onSubmit}>
            <Card title="Terms">
                <div className={"row"}>
                    <div className={"col-md-6 mb-3"}>
                        <Input id={"dueDate"} validation={DayOfMonthRegex.expression}
                            doValidate={formInputValidate}
                            value={dueDate}
                            setValue={setDueDate}
                            required={true}
                            invalidFeedbackText={DayOfMonthRegex.helpText}
                            setValidFlag={(isValid: boolean) => {
                                setDueDateIsValid(isValid);
                            }}
                            type={"number"} label={"Due Date"}
                        />
                    </div>
                    <div className={"col-md-6 mb-3"}>
                        <Input id={"startDate"} validation={DateRegex.expression}
                            doValidate={formInputValidate}
                            value={startDate}
                            setValue={setStartDate}
                            required={true}
                            invalidFeedbackText={DateRegex.helpText}
                            setValidFlag={(isValid: boolean) => {
                                setStartDateIsValid(isValid);
                            }}
                            type={"date"} label={"Start Date"}
                        />
                    </div>
                </div>
                <div className={"row"}>
                    <div className="col-md-6 pt-4 mb-3">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox"
                                id="defaultCheck1"
                                checked={isMonthToMonth}
                                onChange={e => {
                                    let value = e.target.checked;
                                    setIsMonthToMonth(value);
                                    if (value) {
                                        setEndDate("");
                                    }
                                }} />
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                Month to Month?
                            </label>
                        </div>
                    </div>
                    <div className={"col-md-6 mb-3"}>
                        <Input id={"endDate"} validation={DateRegex.expression}
                            disabled={isMonthToMonth}
                            doValidate={formInputValidate}
                            value={endDate}
                            setValue={setEndDate}
                            required={!isMonthToMonth}
                            invalidFeedbackText={DateRegex.helpText}
                            setValidFlag={(isValid: boolean) => {
                                setEndDateIsValid(isValid);
                            }}
                            type={"date"} label={"End Date"}
                        />
                    </div>
                </div>
                <hr />
                <div className={"col mb-3"}>
                    <h5>Late Fee Configuration</h5>
                    {getLateFeeContent()}
                </div>
                <hr />
                <div className="d-flex justify-content-end mt-3">
                    <button type={"submit"} className={"btn btn-primary"} disabled={loading}>
                        Next
                    </button>
                </div>
            </Card>
        </form>
    </>);
};

export default Terms;