import * as React from 'react';
import {FormEvent, useEffect, useState} from "react";
import Input from "../../Form/Input";
import Select from "../../Form/Select";
import {
    FormElementValidation,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex, IncomeStatementReport,
    Property
} from "../../../types";
import {getProperties} from "../../../Api/PropertyApi";
import {getIncomeStatementReport} from "../../../Api/ReportApi";
const dateFormat = require('dateformat');


const IncomeStatement: React.FunctionComponent = () => {

    const [loading, setLoading] = useState(false);
    const [runDate, setRunDate] = useState("");
    const [cashBasis, setCashBasis] = useState("no");
    const [property, setProperty] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [incomeStatementReport, setIncomeStatementReport] = useState<IncomeStatementReport>()

    const [properties, setProperties] = useState<Property[]>([]);

    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});


    type Form = {
        property: FormElementValidation,
        startDate: FormElementValidation,
        endDate: FormElementValidation,
        cashBasis: FormElementValidation
    }

    const form: Form = {
        property: {
            id: "property",
            required: false,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        startDate: {
            id: "startDate",
            required: true,
            validate: formSelectValidate,
            regex: GenericInputRegex
        },
        endDate: {
            id: "endDate",
            required: true,
            validate: formSelectValidate,
            regex: GenericInputRegex
        },
        cashBasis: {
            id: "cashBasis",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
    }

    const fetchProperties = () => {
        setLoading(true);
        getProperties({})
            .then((data) => {
                    setProperties(data);
                }
            ).finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchProperties();
    }, []);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        getIncomeStatementReport(property, startDate, endDate, cashBasis === "yes" ? true : false)
            .then(r => {
                setRunDate(dateFormat(new Date(), "mmmm dS, yyyy, h:MM:ss TT"));
                setIncomeStatementReport(r);
            })
            .catch(e => {
                console.error(e);
            })
            .finally(() => setLoading(false));
    }


    return (<>
        <div className="row">
            <div className={"col-md-12 mb-3"}>
                <h3>Income Statement Report</h3>
                <h5>{cashBasis === "yes" ? "Cash " : "Accrual"} Basis</h5>
                <h5 hidden={runDate === undefined || runDate === ""}>As of {runDate}</h5>
            </div>
        </div>
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className={"col-md-6 mb-3"}>
                    <Input id={form.startDate.id} validation={form.startDate.regex.expression}
                           doValidate={form.startDate.validate}
                           value={startDate}
                           setValue={setStartDate}
                           required={form.startDate.required}
                           invalidFeedbackText={form.startDate.regex.helpText}
                           type={"date"} label={"Start Date"}
                    />
                </div>
                <div className={"col-md-6 mb-3"}>
                    <Input id={form.endDate.id} validation={form.endDate.regex.expression}
                           doValidate={form.endDate.validate}
                           value={endDate}
                           setValue={setEndDate}
                           required={form.endDate.required}
                           invalidFeedbackText={form.endDate.regex.helpText}
                           type={"date"} label={"End Date"}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <Select id={form.property.id} validation={form.property.regex.expression}
                            doValidate={form.property.validate}
                            value={property} setValue={setProperty}
                            required={form.property.required}
                            invalidFeedbackText={form.property.regex.helpText} label={"Property"}>
                        <option value={""} disabled={false}>All properties</option>
                        {
                            properties.map((p: Property) => (
                                <option key={p.uuid + "_p"}
                                        value={p.uuid}>{p.name + " - " + p.address.city + ", " + p.address.stateCode}</option>
                            ))
                        }
                    </Select>
                </div>
                <div className="col-md-6 mb-3">
                    <Select id={form.cashBasis.id} validation={form.cashBasis.regex.expression}
                            doValidate={form.cashBasis.validate}
                            value={cashBasis} setValue={setCashBasis}
                            required={form.cashBasis.required}
                            invalidFeedbackText={form.cashBasis.regex.helpText} label={"Cash Basis?"}>
                        <option value={"no"} disabled={false}>No</option>
                        <option value={"yes"} disabled={false}>Yes</option>
                    </Select>
                </div>
                <div className={"col-md-12 mb-3"}>
                    <button type={"submit"} className={"btn btn-primary"}

                    >Run Report
                    </button>
                </div>
            </div>
        </form>

        <div className={"row"}>
            <div className={"col-md-12 mb-3"}>
                <div className={"table-responsive"}>
                    <table className="table">
                        <thead>

                        </thead>
                        <tbody>
                        <tr>
                            <td><strong>Revenue</strong></td>
                            <td></td>
                        </tr>
                        {
                            incomeStatementReport?.revenues.map(r => (
                                <tr>
                                    <td className={"pl-5"}>{r.name}</td>
                                    <td>{currencyFormat.format(r.total)}</td>
                                </tr>
                            ))
                        }

                        <tr>
                            <td className={"pb-5"}><strong>Total Revenue</strong></td>
                            <td>{currencyFormat.format(incomeStatementReport?.totalRevenue || 0)}</td>
                        </tr>
                        <tr>
                            <td className={"border-top-0"}><strong>Expenses</strong></td>
                            <td className={"border-top-0"}></td>
                        </tr>
                        {
                            incomeStatementReport?.expenses.map(e => (
                                <tr>
                                    <td className={"pl-5"}>{e.name}</td>
                                    <td>{currencyFormat.format(e.total)}</td>
                                </tr>
                            ))
                        }

                        <tr>
                            <td><strong>Total Expenses</strong></td>
                            <td>{currencyFormat.format(incomeStatementReport?.totalExpenses || 0)}</td>
                        </tr>
                        <tr className={"bt-double"}>
                            <td><strong>Net Income</strong></td>
                            <td>{currencyFormat.format(incomeStatementReport?.netIncome || 0)}</td>
                        </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </>);
};

export default IncomeStatement;
