import * as React from "react";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import PageLayout from "../../../Layouts/PageLayout";
import {getOwnerStatement} from "../../../Api/ReportApi";
import {OwnerStatementDto} from "../../../types";


const ViewOwnerStatement: React.FunctionComponent = () => {
    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    let {reportId} = useParams();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [ownerStatement, setOwnerStatement] = useState<OwnerStatementDto>()

    useEffect(() => {
        if (reportId) {
            setLoading(true);
            getOwnerStatement(reportId)
                .then((r) => {
                    setOwnerStatement(r);
                })
                .catch((e) => setErrorMessage(e.message))
                .finally(() => setLoading(false));
        }
    }, [reportId]);
    return <>
        {loading && <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>}
        {errorMessage && <div className="alert alert-danger" role="alert">
            {errorMessage}
        </div>}
        {ownerStatement && <PageLayout title={"Owner Statement"}>
            <button className={"btn btn-primary me-3 mb-3"} onClick={(e) => {
                e.preventDefault();
                var element = document.getElementById('pdf-print');
                // @ts-ignore
                html2pdf(element, {
                    margin: 10,
                    filename: "Owner Statement"
                });
            }}>Download PDF
            </button>

            <button className={"btn btn-primary mb-3"} onClick={(e) => {
                e.preventDefault();
                window.print();
            }}>Print
            </button>
            <div id={"pdf-print"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <h5>{ownerStatement?.portfolio.name}</h5>
                        {ownerStatement?.portfolio.addressLine1}<br/>
                        <span
                            hidden={!ownerStatement?.portfolio.addressLine2}>{ownerStatement?.portfolio.addressLine2}<br/></span>
                        {ownerStatement?.portfolio.city}, {ownerStatement?.portfolio.stateCode} {ownerStatement?.portfolio.zip}<br/>
                        {ownerStatement?.portfolio.phone}<br/>
                        {ownerStatement?.portfolio.url}<br/>
                    </div>
                    <div className={"col text-end"}>
                        <img width={200} alt={"logo"} src={"/assets/dist/img" + ownerStatement?.portfolio.logoUrl}/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col"}>
                        <hr/>
                    </div>
                </div>
                <div className={"row mb-3"}>
                    <div className={"col"}>
                        <h5>{ownerStatement?.owner.name}</h5>
                        {ownerStatement?.owner.addressLine1}<br/>
                        <span
                            hidden={!ownerStatement?.owner.addressLine2}>{ownerStatement?.owner.addressLine2}<br/></span>
                        {ownerStatement?.owner.city}, {ownerStatement?.owner.stateCode} {ownerStatement?.owner.zip}<br/>
                    </div>
                    <div className={"col"}>
                        <h3>Owner Statement</h3>
                        <p>{ownerStatement.fromDate} - {ownerStatement.toDate}</p>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col"}>
                        <h5>Consolidated Summary ({ownerStatement.propertySummaries.length} properties)</h5>
                        <hr/>
                    </div>
                </div>
                <div className={"row"} style={{pageBreakAfter: "always"}}>
                    <div className={"col"}>
                        <table className={"table table-striped table-fixed"}>

                            <tbody>
                            <tr>
                                <td colSpan={2}>Beginning Balance</td>
                                <td style={{width: 200}}>{currencyFormat.format(ownerStatement.beginningBalance)}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Cash In</td>
                                <td>{currencyFormat.format(ownerStatement.cashIn)}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Cash Out</td>
                                <td>{currencyFormat.format(ownerStatement.cashOut)}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Owner Disbursements</td>
                                <td>{currencyFormat.format(ownerStatement.ownerDisbursements)}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Ending Cash Balance</td>
                                <td>{currencyFormat.format(ownerStatement.endingCashBalance)}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Property Reserve</td>
                                <td>{currencyFormat.format(ownerStatement.propertyReserve)}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Prepayments</td>
                                <td>{currencyFormat.format(ownerStatement.prepayments)}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}><strong>Net Owner Funds</strong></td>
                                <td><strong>{currencyFormat.format(ownerStatement.netOwnerFunds)}</strong></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    ownerStatement.propertySummaries.map(p => (
                        <div key={p.uuid} style={{pageBreakAfter: "always"}}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h5>{p.name} - {p.addressLine1} {p.addressLine2} {p.city}, {p.stateCode} {p.zip}</h5>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h5>Property Cash Summary</h5>
                                    <hr/>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <table className={"table table-striped table-fixed"}>

                                        <tbody>
                                        <tr>
                                            <td colSpan={2}>Beginning Balance</td>
                                            <td style={{width: 200}}>{currencyFormat.format(p.beginningBalance)}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>Cash In</td>
                                            <td>{currencyFormat.format(p.cashIn)}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>Cash Out</td>
                                            <td>{currencyFormat.format(p.cashOut)}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>Owner Disbursements</td>
                                            <td>{currencyFormat.format(p.ownerDisbursements)}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Ending Cash Balance</td>
                                            <td>{currencyFormat.format(p.endingCashBalance)}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>Property Reserve</td>
                                            <td>{currencyFormat.format(p.propertyReserve)}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td>Prepayments</td>
                                            <td>{currencyFormat.format(p.prepayments)}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}><strong>Net Owner Funds</strong></td>
                                            <td><strong>{currencyFormat.format(p.netOwnerFunds)}</strong></td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h5>Transactions</h5>
                                    <hr/>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>

                                    <table className={"table table-striped table-fixed"}>
                                        <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Cash In</th>
                                            <th>Cash Out</th>
                                            <th>Balance</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td></td>
                                            <td>Beginning cash balance as of {ownerStatement.fromDate}</td>
                                            <td></td>
                                            <td></td>
                                            <td>{currencyFormat.format(p.beginningBalance)}</td>
                                        </tr>
                                        {
                                            p.transactions.map((t, index) => (
                                                <tr key={"t_" + index}>
                                                    <td>{t.date}</td>
                                                    <td>{t.description}</td>
                                                    <td>{currencyFormat.format(t.cashIn)}</td>
                                                    <td>{currencyFormat.format(t.cashOut)}</td>
                                                    <td>{currencyFormat.format(t.balance)}</td>
                                                </tr>
                                            ))
                                        }
                                        <tr>
                                            <td></td>
                                            <td>Ending cash balance</td>
                                            <td></td>
                                            <td></td>
                                            <td>{currencyFormat.format(p.endingCashBalance)}</td>
                                        </tr>

                                        </tbody>
                                        <tfoot>
                                        <tr>
                                            <td colSpan={2} className={"text-end"}><strong>Total</strong></td>
                                            <td><strong>{currencyFormat.format(p.cashIn)}</strong></td>
                                            <td><strong>{currencyFormat.format(Math.abs(p.cashOut + p.ownerDisbursements))}</strong></td>
                                            <td><strong>{currencyFormat.format(p.beginningBalance + p.cashIn + p.cashOut + p.ownerDisbursements)}</strong></td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </PageLayout>}
    </>
};
export default ViewOwnerStatement;