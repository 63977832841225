import * as React from 'react';
import {Link} from "react-router-dom";
import {useRouteMatch} from "react-router";
import {FormEvent, useCallback, useEffect, useState} from "react";
import FormFieldBuilder, {assignFormFieldValues} from "../../../Components/Form/FieldBuilder/FormFieldBuilder";
import EditPropertyForm from "../../../Components/ManageProperties/EditProperty/Forms/EditPropertyForm";
import SearchUserForm from "../Forms/SearchUserForm";
import JourneyLeaseForm from "../../JourneyLeases/Forms/JourneyLeaseForm";
import {getUsersInPortfolio} from "../../../Api/UserApi";
import {AppUser, Role} from "../../../types";
import Table from "../../../Components/Table";
import PageLayout from "../../../Layouts/PageLayout";
import {convertEnumToReadableString} from "../../../helpers";


const SearchUsers: React.FunctionComponent = () => {
    let {path} = useRouteMatch();

    const [users, setUsers] = useState<AppUser[]>([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    assignFormFieldValues({field: SearchUserForm.firstName, value: firstName, setValue: setFirstName});
    assignFormFieldValues({field: SearchUserForm.lastName, value: lastName, setValue: setLastName});
    assignFormFieldValues({field: SearchUserForm.email, value: email, setValue: setEmail});

    const search = () => {
        getUsersInPortfolio(firstName, lastName, email)
            .then((uz) => {
                setUsers(uz);
            })
            .catch((e) => console.error(e.message));
    }

    const load = useCallback(() => {
        search();
    }, [])
    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        search();

    }

    useEffect(() => {
        load()
    }, []);

    const clear = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
    }

    const getRolesComponent = (rz: Role[]) => {
        return rz.map((r) => (
            <span key={r.uuid} className="badge rounded-pill bg-primary me-2">{r.name}</span>
        ))
    }

    return (<>
        <PageLayout title="Users" addButtonLabel={"Add"} addButtonPath={path + "/add"}>
            <form onSubmit={onSubmit}>
                <div className={"row"}>
                    <h5>Find a user</h5>
                    <div className={"col-md-3 mb-3"}>
                        <FormFieldBuilder field={SearchUserForm.firstName}/>
                    </div>
                    <div className={"col-md-3 mb-3"}>
                        <FormFieldBuilder field={SearchUserForm.lastName}/>
                    </div>
                    <div className={"col-md-3 mb-3"}>
                        <FormFieldBuilder field={SearchUserForm.email}/>
                    </div>
                    <div className={"col-md-12 mb-3"}>
                        <button type="submit" className="btn btn-primary" >Search</button>
                        <button type="button" className="btn btn-primary ms-3" onClick={() => clear()}>Clear</button>
                    </div>
                </div>
            </form>
            <hr />
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <Table headers={["First Name", "Last Name", "Email", "Roles"]} defaultPaginationSize={10}>
                        {
                            users.map((u) => (
                                <tr key={u.uuid}>
                                    <td>{u.firstName}</td>
                                    <td>{u.lastName}</td>
                                    <td>{u.email}{!u.activated ? <em> (pending invite)</em> : <></>}</td>
                                    <td>{getRolesComponent(u?.roles || [])}</td>
                                    <td><Link to={path + "/" + u.uuid + "/edit"} className="btn btn-sm btn-primary">Edit</Link></td>
                                </tr>
                            ))
                        }
                    </Table>
                </div>
            </div>
        </PageLayout>
    </>);
};

export default SearchUsers;