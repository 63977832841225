import React, {FormEvent, useContext, useEffect, useState} from "react";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";
import {
    AddressLine1Regex, AddressLine2Regex, CityRegex,
    DateRegex,
    DollarAmountRegex,
    formInputValidate,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex, PhoneNumberRegex,
    PropertyAccount, PropertyExpense, PropertyVendor, ZipRegex
} from "../../../types";
import {
    addPropertyExpense,
    addVendor,
    getPropertyExpenseAccounts,
    getPropertyExpenses,
    getVendors, markExpenseAsPaid
} from "../../../Api/PropertyApi";
import PageLayout from "../../../Layouts/PageLayout";
import Input from "../../../Components/Form/Input";
import Select from "../../../Components/Form/Select";
import StateSelect from "../../../Components/StateSelect";
import Table from "../../../Components/Table";
import moment from "moment/moment";

const AddPropertyExpense: React.FunctionComponent = () => {

    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    const propertyId = useContext(PropertyIdContext)
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [expenseAccounts, setExpenseAccounts] = useState<PropertyAccount[]>([]);
    const [transactionDate, setTransactionDate] = useState("");
    const [description, setDescription] = useState("");
    const [amount, setAmount] = useState("");
    const [selectedAccountId, setSelectedAccountId] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [doAddVendor, setDoAddVendor] = useState(false);
    const [selectedVendorId, setSelectedVendorId] = useState("");
    const [vendors, setVendors] = useState<PropertyVendor[]>([]);
    const [loadingVendor, setLoadingVendor] = useState(false);

    const [vendorName, setVendorName] = useState("");
    const [vendorPhone, setVendorPhone] = useState("");
    const [businessAddressLine1, setBusinessAddressLine1] = useState("");
    const [businessAddressLine2, setBusinessAddressLine2] = useState("");
    const [businessCity, setBusinessCity] = useState("");
    const [businessState, setBusinessState] = useState("");
    const [businessZip, setBusinessZip] = useState("");
    const [loadingAddVendor, setLoadingAddVendor] = useState(false);
    const [loadingPropertyExpenses, setLoadingPropertyExpenses] = useState(false);
    const [expenses, setExpenses] = useState<PropertyExpense[]>([]);
    const [payExpenseId, setPayExpenseId] = useState("");
    const [payExpenseDate, setPayExpenseDate] = useState("");
    const [markAsPaidLoading, setMarkAsPaidLoading] = useState(false);


    function clear() {
        setTransactionDate("");
        setDescription("");
        setAmount("");
        setSelectedAccountId("");
        setErrorMessage("");
        setSelectedVendorId("");
    }

    useEffect(() => {
        if (propertyId) {
            setLoading(true);
            getPropertyExpenseAccounts(propertyId)
                .then((e) => {
                    setExpenseAccounts(e);
                })
                .catch((e) => setErrorMessage(e.message))
                .finally(() => setLoading(false));

            loadVendors();
            loadExpenses();

        }
    }, [propertyId]);

    function loadExpenses() {
        setLoadingPropertyExpenses(true);
        getPropertyExpenses(propertyId)
            .then(pz => {
                setExpenses(pz);
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoadingPropertyExpenses(false));
    }

    function doSubmit(e: FormEvent) {
        e.preventDefault();
        clear();
        setLoading(true);
        addPropertyExpense(propertyId, {
            amount: parseFloat(amount),
            description: description,
            date: transactionDate,
            accountId: selectedAccountId,
            vendorId: selectedVendorId
        })
            .then(e => {
                clear();
                setSuccessMessage("Expense added.");
                loadExpenses();
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }

    function loadVendors() {
        setLoadingVendor(true);
        getVendors(propertyId)
            .then(vz => {
                setVendors(vz)
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoadingVendor(false));
    }

    function clearVendor() {
        setVendorName("");
        setVendorPhone("");
        setBusinessAddressLine1("");
        setBusinessAddressLine2("");
        setBusinessCity("");
        setBusinessState("");
        setBusinessZip("");
        setSelectedVendorId("");
    }


    function handleVendor() {
        setLoadingAddVendor(true);
        addVendor(propertyId, {
            name: vendorName,
            phone: vendorPhone,
            address: {
                addressLine1: businessAddressLine1,
                addressLine2: businessAddressLine2,
                city: businessCity,
                stateCode: businessState,
                zip: businessZip
            }
        })
            .then((v) => {
                clearVendor();
                setDoAddVendor(false);
                setSelectedVendorId(v.uuid);
                loadVendors();
            })
            .catch((e) => setErrorMessage(e.message))
            .finally(() => setLoadingAddVendor(false));
    }

    function getVendor() {
        if (doAddVendor) {
            return <>
                <div className={"col-md-3 mb-3"}>
                    <Input id={"name"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={vendorName}
                           setValue={setVendorName}
                           required={true}
                           invalidFeedbackText={GenericSelectRegex.helpText}
                           type={"text"} label={"Name"}
                    />
                </div>
                <div className={"col-md-3 mb-2"}>
                    <Input id={"phone"} validation={PhoneNumberRegex.expression}
                           doValidate={formInputValidate}
                           value={vendorPhone}
                           setValue={setVendorPhone}
                           required={false}
                           invalidFeedbackText={PhoneNumberRegex.helpText}
                           type={"text"} label={"Phone"}
                    />
                </div>
                <div className={"col-md-6 mb-2"}>
                </div>
                <div className={"col-md-6 mb-2"}>
                    <Input id={"businessaddressLine1"} validation={AddressLine1Regex.expression}
                           doValidate={formInputValidate}
                           value={businessAddressLine1}
                           setValue={setBusinessAddressLine1}
                           required={true}
                           invalidFeedbackText={AddressLine1Regex.helpText}
                           type={"text"} label={"Address"}
                    />
                </div>
                <div className={"col-md-6 mb-2"}>
                    <Input id={"businessaddressLine2"} validation={AddressLine2Regex.expression}
                           doValidate={formInputValidate}
                           value={businessAddressLine2}
                           setValue={setBusinessAddressLine2}
                           required={false}
                           invalidFeedbackText={AddressLine2Regex.helpText}
                           type={"text"} label={"Address 2"}
                    />
                </div>
                <div className={"col-md-4 mb-2"}>
                    <Input id={"businesscity"} validation={CityRegex.expression}
                           doValidate={formInputValidate}
                           value={businessCity}
                           setValue={setBusinessCity}
                           required={true}
                           invalidFeedbackText={CityRegex.helpText}
                           type={"text"} label={"City"}
                    />
                </div>
                <div className="col-md-3 mb-2">
                    <StateSelect id={"businessstate"} validation={GenericSelectRegex.expression}
                                 noSelectionText={"Select a state..."}
                                 doValidate={formSelectValidate}
                                 value={businessState} setValue={setBusinessState}
                                 required={true}
                                 invalidFeedbackText={GenericSelectRegex.helpText}
                                 label={"State"}/>
                </div>
                <div className={"col-md-3 mb-2"}>
                    <Input id={"businesszip"} validation={ZipRegex.expression}
                           doValidate={formInputValidate}
                           value={businessZip}
                           setValue={setBusinessZip}
                           required={true}
                           invalidFeedbackText={ZipRegex.helpText}
                           type={"number"} label={"Zip"}
                    />
                </div>
                <div className={"col-md-12 mb-2"}>
                    <button type={"button"} className={"btn btn-primary"} onClick={(e) => {
                        e.preventDefault();
                        handleVendor();
                    }}>Save Vendor
                    </button>
                </div>
            </>
        } else {
            return <>
                <div className={"col-md-3 mb-3"}>
                    <Select id={"vendor"} validation={GenericSelectRegex.expression}
                            doValidate={formSelectValidate} value={selectedVendorId} setValue={setSelectedVendorId}
                            required={true}
                            invalidFeedbackText={GenericSelectRegex.helpText} label={"Vendor"}>
                        <option disabled={true} value={""}>Choose one...</option>
                        {
                            vendors.map(e => (
                                <option key={e.uuid} value={e.uuid}>{e.name}</option>
                            ))
                        }
                    </Select>
                </div>
                <div className={"col-md-3 mb-3"} style={{paddingTop: 20}}>
                    <button type={"button"} className={"btn btn-primary"} onClick={(e) => {
                        e.preventDefault();
                        setDoAddVendor(true);
                    }}>Create Vendor
                    </button>
                </div>
            </>
        }
    }

    function doMarkExpenseAsPaid(id: string) {
        setMarkAsPaidLoading(true);
        markExpenseAsPaid(propertyId, id, {
            payDate: payExpenseDate
        })
            .then((r) => {
                loadExpenses();
            })
            .catch((e) => setErrorMessage(e.message))
            .finally(() => setMarkAsPaidLoading(false))
    }

    function getPayAction(id: string) {
        if (id === payExpenseId) {
            return <span>
                <Input id={"date"} validation={DateRegex.expression}
                       doValidate={formInputValidate}
                       value={payExpenseDate}
                       setValue={setPayExpenseDate}
                       required={true}
                       invalidFeedbackText={DateRegex.helpText}
                       type={"date"} label={"Payment Date"}
                />
                <button className={"btn-primary btn-sm btn"} onClick={() => doMarkExpenseAsPaid(id)}>Submit</button>
            </span>
        }
        return <button className={"btn-primary btn-sm btn"} onClick={() => setPayExpenseId(id)}>Pay</button>
    }

    return <PageLayout title={"Add Expense"} propertyId={propertyId}>
        <form onSubmit={doSubmit}>
            <div className={"row"}>

                <div className={"col-md-3 mb-3"}>
                    <Input id={"date"} validation={DateRegex.expression}
                           doValidate={formInputValidate}
                           value={transactionDate}
                           setValue={setTransactionDate}
                           required={true}
                           invalidFeedbackText={DateRegex.helpText}
                           type={"date"} label={"Date"}
                    />
                </div>
                <div className={"col-md-3 mb-3"}>
                    <Select id={"account"} validation={GenericSelectRegex.expression}
                            doValidate={formSelectValidate} value={selectedAccountId} setValue={setSelectedAccountId}
                            required={true}
                            invalidFeedbackText={GenericSelectRegex.helpText} label={"Account"}>
                        <option disabled={true} value={""}>Choose one...</option>
                        {
                            expenseAccounts.map(e => (
                                <option key={e.uuid} value={e.uuid}>{e.code} - {e.description}</option>
                            ))
                        }
                    </Select>
                </div>
                <div className={"col-md-3 mb-3"}>
                    <Input id={"description"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={description}
                           setValue={setDescription}
                           required={true}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"text"} label={"Description"}
                    />
                </div>
                <div className={"col-md-3 mb-3"}>
                    <Input id={"amount"} validation={DollarAmountRegex.expression}
                           doValidate={formInputValidate}
                           value={amount}
                           setValue={setAmount}
                           required={true}
                           invalidFeedbackText={DollarAmountRegex.helpText}
                           type={"number"} label={"Amount"}
                    />
                </div>
                {getVendor()}
                <div className={"col-md-12 mb-3"} style={{paddingTop: 20}}>
                    <button disabled={doAddVendor} type={"submit"} className={"btn btn-primary"}>Submit</button>
                </div>

            </div>
        </form>
        <div className={"row"}>
            <div className={"col-md-12"}>
                <div hidden={!successMessage} className="alert alert-success" role="alert">
                    {successMessage}
                </div>

                <div hidden={!errorMessage} className="alert alert-success" role="alert">
                    {errorMessage}
                </div>
            </div>
        </div>
        <div className={"row"}>
            <div className={"col-md-12"}>
                <h3>Pay Expenses</h3>
            </div>
        </div>
        <div className={"row"}>
            <div className={"col-md-12"}>
                <Table headers={["Date", "Description", "Account", "Vendor", "Amount", ""]} defaultPaginationSize={10}>
                    {
                        expenses.map(e => (
                            <tr key={e.uuid}>
                                <td>{moment(e.transactionDate).format("MMM Do, YYYY")}</td>
                                <td>{e.description}</td>
                                <td>{e.account.code}-{e.account.description}</td>
                                <td>{e.vendor.name}</td>
                                <td>{currencyFormat.format(e.amount)}</td>
                                <td>
                                    {getPayAction(e.uuid)}
                                </td>
                            </tr>
                        ))
                    }
                </Table>
            </div>
        </div>
    </PageLayout>
}
export default AddPropertyExpense;