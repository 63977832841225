import {FormBuilderField, FormFieldType} from "../../Form/FieldBuilder/FormFieldBuilder";
import {formSelectValidate, GenericSelectRegex} from "../../../types";

interface NavigationForm {
    portfolioId: FormBuilderField
    propertyId: FormBuilderField
}

const NavigationForm: NavigationForm = {
    portfolioId: {
        type: FormFieldType.SELECT,
        label: "Portfolio",
        element: {
            id: 'portfolioId',
            validate: formSelectValidate,
            regex: GenericSelectRegex,
            required: false
        },
        value: undefined,
        setValue: () => {},
        defaultSelectOptions: [{
            label: "Please Select...",
            value: "",
            disabled: true
        }]
    },
    propertyId: {
        type: FormFieldType.SELECT,
        label: "Property",
        element: {
            id: 'propertyId',
            validate: formSelectValidate,
            regex: GenericSelectRegex,
            required: false
        },
        value: undefined,
        setValue: () => {},
        defaultSelectOptions: [{
            label: "Please Select...",
            value: "",
            disabled: true
        }]
    },
}

export default NavigationForm