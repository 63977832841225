import * as React from 'react';
import {Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import ViewTenantPackages from "./View/ViewTenantPackages";
import ManageTenantPackage from "./Manage/ManageTenantPackage";
import AutoSelectProperty from "../Form/SelectProperty/AutoSelectProperty";

const TenantPackageRouter: React.FunctionComponent = () => {
    let {path} = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <AutoSelectProperty name={"Tenant Packages"} />
            </Route>
            <Route exact path={path + "/:propertyId"}>
                <ViewTenantPackages />
            </Route>
            <Route exact path={path + "/:propertyId/manage/:tenantPackageId"}>
                <ManageTenantPackage />
            </Route>
        </Switch>
    )
}

export default TenantPackageRouter;
