import * as React from "react";
import {AmenityDayFormInterface} from "../Forms/AmenityDayForm";
import FormFieldBuilder from "../../Form/FieldBuilder/FormFieldBuilder";
import {AmenityDayViews} from "../Tabs/AmenityDayTabs";

export type CopyFrom = (from: AmenityDayViews, form: AmenityDayFormInterface) => void;
export type ApplyAll = (form: AmenityDayFormInterface) => void;

const AmenityDayView: React.FunctionComponent<{form: AmenityDayFormInterface, copyFrom?: CopyFrom, applyAll?: ApplyAll}> = ({form, copyFrom, applyAll}) => {

    const copyFromOnClick = (e: React.MouseEvent<HTMLLIElement>, from: AmenityDayViews) => {
        e.preventDefault();
        console.log(from);
        if (copyFrom) {
            copyFrom(from, form);
        }
    }

    const applyAllOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (applyAll) {
            applyAll(form);
        }
    }

    return (<>
        <div className="row">
        </div>
        <div className="row">
            <div className="col-md-12 mb-3">
                <div className="row">
                    <div className="col-md-5 mb-3">
                        <div className="col-md-12 mt-3 mb-3">
                            <h5>Availability</h5>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <FormFieldBuilder field={form.isOpen} selectOptions={form.isOpen.defaultSelectOptions} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-6 mb-2">
                                <FormFieldBuilder field={form.openTime} />
                            </div>
                            <div className="col-xxl-6 mb-2">
                                <FormFieldBuilder field={form.closeTime} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-6 mb-2">
                                <FormFieldBuilder field={form.basePrice} />
                            </div>
                            <div className="col-xxl-6 mb-2">
                                <FormFieldBuilder field={form.pricePerMinute} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 mb-3">
                        <div className="col-md-12 mt-3 mb-3">
                            <h5>Reservations</h5>
                        </div>
                        <div className="row">
                            <div className="col-xxl-6 mb-2">
                                <FormFieldBuilder field={form.minReservationMinutes} />
                            </div>
                            <div className="col-xxl-6 mb-2">
                                <FormFieldBuilder field={form.maxReservationMinutes} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xxl-6 mb-2">
                                <FormFieldBuilder field={form.timeslotBlockMinutes} />
                            </div>
                            <div className="col-xxl-6 mb-2">
                                <FormFieldBuilder field={form.cancelBeforeMinute} />
                            </div>
                        </div>
                        {copyFrom && applyAll &&
                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6 pt-1 mt-3">
                                    <div className="row">
                                        <div className="btn-group btn-group" role="group">
                                            <div className="btn-group" role="group">
                                                <button id="ad_copy_dropdown" type="button" className="btn btn-outline-secondary dropdown-toggle" data-bs-toggle="dropdown">Copy...</button>
                                                <ul className="dropdown-menu">
                                                    <li className="dropdown-item" onClick={e => copyFromOnClick(e, AmenityDayViews.SUNDAY)}>Sunday</li>
                                                    <li className="dropdown-item" onClick={e => copyFromOnClick(e, AmenityDayViews.MONDAY)}>Monday</li>
                                                    <li className="dropdown-item" onClick={e => copyFromOnClick(e, AmenityDayViews.TUESDAY)}>Tuesday</li>
                                                    <li className="dropdown-item" onClick={e => copyFromOnClick(e, AmenityDayViews.WEDNESDAY)}>Wednesday</li>
                                                    <li className="dropdown-item" onClick={e => copyFromOnClick(e, AmenityDayViews.THURSDAY)}>Thursday</li>
                                                    <li className="dropdown-item" onClick={e => copyFromOnClick(e, AmenityDayViews.FRIDAY)}>Friday</li>
                                                    <li className="dropdown-item" onClick={e => copyFromOnClick(e, AmenityDayViews.SATURDAY)}>Saturday</li>
                                                </ul>
                                            </div>
                                            <button type="button" className="btn btn-primary" onClick={e => applyAllOnClick(e)}>Apply To All</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default AmenityDayView;
