import * as React from 'react';
import {Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import ViewLeads from "./ViewLeads";
import AutoSelectProperty from "../Form/SelectProperty/AutoSelectProperty";

const InboundLeads: React.FunctionComponent = () => {
    let {path} = useRouteMatch();
    return (<>
        <Switch>
            <Route exact path={path}>
                <AutoSelectProperty name={"Journey Leases"} />
            </Route>
            <Route exact path={path + "/:propertyId"}>
                <ViewLeads />
            </Route>
        </Switch>

    </>);
};

export default InboundLeads;