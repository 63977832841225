import * as React from 'react';
import {useEffect, useState} from 'react';
import {BootstrapValidateClass, ValidateElementFunction} from "../../../types";
import RequiredLabel from "../../RequiredLabel";

type InputProps = {
    id: string,
    validation: RegExp,
    doValidate: ValidateElementFunction,
    value: string,
    setValue: Function,
    required: boolean,
    maxLength?: number,
    minLength?: number,
    invalidFeedbackText: string,
    type: string,
    label: string,
    placeholder?: string,
    disabled?: boolean,
    setValidFlag?: Function,
    step?: number | string,
    min?: number | string,
    max?: number | string,
}

export enum InputType {
    TEXT="text",
    NUMBER="number",
    EMAIL="email",
    PHONE="tel",
    DATE="date",
    TIME="time",
    COLOR="color"
}

const Input: React.FunctionComponent<InputProps> = (p) => {

    const [validateClass, setValidateClass] = useState(BootstrapValidateClass.unvalidated);
    const {value} = p;

    useEffect(() => {
        if (value.length === 0 || p.disabled) {
            setValidateClass(BootstrapValidateClass.unvalidated);
            if(p.setValidFlag) {
                p.setValidFlag(false);
            }
            return;
        }
        if (p.doValidate(value, p.required, p.validation)) {
            setValidateClass(BootstrapValidateClass.isvalid);
            if(p.setValidFlag) {
                p.setValidFlag(true);
            }
        } else {
            setValidateClass(BootstrapValidateClass.isinvalid);
            if(p.setValidFlag) {
                p.setValidFlag(false);
            }
        }

    }, [value, p])

    const getLabelComponent = () => {
        if(p.label) {
            return <label htmlFor={p.id}>{!p.disabled && p.required ?
                <RequiredLabel>{p.label}</RequiredLabel> : p.label}</label>
        }
    }

    return (<>
        {getLabelComponent()}
        <input type={p.type} className={"form-control " + validateClass}
               id={p.id} disabled={p.disabled}
               maxLength={p.maxLength} minLength={p.minLength} placeholder={p.placeholder}
               required={p.required} value={p.value} step={p.step} min={p.type === InputType.NUMBER ? 0 : p.min} max={p.max} onChange={e => {
            let value = e.target.value;
            p.setValue(value);
        }}/>
        <div className="invalid-feedback">
            {p.invalidFeedbackText}
        </div>
    </>);
};

export default Input;
