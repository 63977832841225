import * as React from "react";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe, Stripe} from "@stripe/stripe-js";
import {SubscriptionPaymentMethod} from "../../../types";
import SubscriptionAddPaymentMethod from "../AddPaymentMethod";
import {useEffect, useState} from "react";
import {getStripePublicKey} from "../../../Api/StripePublicKeyApi";
import AddPaymentMethod from "../../../Components/CreatePortfolio/AddPaymentMethod";


type Params = {
    setPaymentSourceId: Function,
    savedCards: SubscriptionPaymentMethod[],
    doDelete: Function
}
const UpdateCard: React.FunctionComponent<Params> = (p) => {

    const [stripePromise, setStripePromise] = useState<Stripe>();

    useEffect(() => {
        getStripePublicKey()
            .then((r) => {
                loadStripe(r.key)
                    .then(s => {
                        if(s) {
                            setStripePromise(s);
                        }
                    })
                    .catch(e => console.error(e));

            })
            .catch((e) => console.error(e.message));
    }, []);


    const getElements = () => {
        if(stripePromise) {
            return <Elements stripe={stripePromise}>
                <SubscriptionAddPaymentMethod
                    setCardId={(c: string) => p.setPaymentSourceId(c)}
                    savedCards={p.savedCards}
                    doDelete={(id: string) => p.doDelete(id)}
                />
            </Elements>
        }
        return <></>
    }

    return <>

        <div className={"row"}>
            <div className={"col mb-3 control"}>
                {getElements()}
            </div>
        </div>

    </>
}

export default UpdateCard;