import * as React from 'react';
import {Link} from "react-router-dom";
import {useRouteMatch} from "react-router";
import {useEffect, useState} from "react";
import {PropertyGroup} from "../../../types";
import Table from "../../Table";
import {getPropertyGroups} from "../../../Api/PropertyGroupApi";


const SearchPropertyGroups: React.FunctionComponent = () => {
    let {path} = useRouteMatch();

    const [propertyGroups, setPropertyGroups] = useState(new Array<PropertyGroup>());

    useEffect(() => {
        getPropertyGroups()
            .then((data) => {
                setPropertyGroups(data);
            })
    }, [])


    return (<>
        <div className={"row"}>
            <div className={"col-md-12 mb-3"}>
                <h4>Property Groups</h4>
                <Link to={path + "/add"}>
                    <button type="button" className="btn btn-primary">Add new property group</button>
                </Link>
            </div>

        </div>
        <div className={"row"}>
            <div className={"col-lg-6 mb-3"}>
                <Table headers={["Name"]} defaultPaginationSize={10}>
                    {
                        propertyGroups.map(p => (
                            <tr key={p.uuid + "_p"}>
                                <td>{p.name}</td>
                                <td><Link to={path + "/edit/" + p.uuid}>Edit</Link></td>
                            </tr>
                        ))
                    }
                </Table>

            </div>
        </div>
    </>);
};

export default SearchPropertyGroups;
