import React from "react";

const MenuToggleButton: React.FunctionComponent = () => {
    return <>
        <button className="navbar-toggler collapsed" type="button"
                data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
                aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
    </>
}

export default MenuToggleButton