import React from "react";
import Alert, {AlertProps, AlertType} from "../../Components/Alert";
import Card from "../../Components/Card";

interface Props {
    title: string
    description: string
    error?: Error | undefined
    alert?: AlertProps | undefined
}

const ApplicationStageLayout: React.FunctionComponent<Props> = (p) => {
    return <>
        {p.error && <Alert type={AlertType.DANGER} message={p.error.message} />}
        {p.alert && <Alert type={p.alert.type} message={p.alert.message} />}
        <div className="mb-5">
            <Card title={p.title}>
                <p>{p.description}</p>
                <div className="row">
                    {p.children}
                </div>
            </Card>
        </div>
    </>
}

export default ApplicationStageLayout