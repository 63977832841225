import React, { FormEvent, useEffect, useState } from "react";
import { ApplicationInviteBody, sendApplicationInvite } from "../../Api/ApplicationApi";
import { useHistory, useParams } from "react-router";
import Card from "../Card";
import Input from "../Form/Input";
import { EmailRegex, Property, formInputValidate } from "../../types";
import ViewPropertyInfo from "../PropertyInfo/ViewPropertyInfo";
import LoadingOverlay from "../LoadingOverlay";
import { getProperty } from "../../Api/PropertyApi";
import Alert, { AlertType } from "../Alert";
import TextArea from "../Form/TextArea";


const InviteToApply: React.FunctionComponent = () => {
    const { propertyId } = useParams();
    const [property, setProperty] = useState<Property>();
    const [email, setEmail] = useState("");
    const [note, setNote] = useState("Please fill out an application with the link below, thank you!");
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(true);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [alertType, setAlertType] = useState<AlertType>(AlertType.DANGER);
    const [alertMessage, setAlertMessage] = useState("");
    const [sendingEmail, setSendingEmail] = useState<boolean>(false);


    useEffect(() => {
        setLoading(true);
        getProperty(propertyId)
            .then(setProperty)
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [propertyId]);

    const doShowAlert = (message: string, type: AlertType) => {
        setShowAlert(true);
        setAlertMessage(message);
        setAlertType(type);
    }

    const sendEmail = (e: FormEvent) => {
        setSendingEmail(true);
        e.preventDefault();
        let body: ApplicationInviteBody = {};
        if (email) {
            body.email = email
        }
        if (note) {
            body.note = note;
        }
        sendApplicationInvite(propertyId, body).then((r) => {
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId
            });
        })
    }

    const onCancel = () => {
        history.push({
            pathname: "/p/m/customers/applications/" + propertyId
        });
    }

    return (loading ? <LoadingOverlay show={loading} /> : <>
        <Card title="Invite to Apply">
            <div className="">
                <p className={"mt-3"}>Send an email to an applicant with a link to apply for the property below.</p>
                {property && <ViewPropertyInfo property={property} />}
                <hr></hr>
                <Alert type={alertType} message={alertMessage} show={showAlert} />
            </div>

            <form onSubmit={sendEmail}>
                {!sendingEmail ? <>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <Input id={"email"} validation={EmailRegex.expression}
                            doValidate={formInputValidate}
                            value={email}
                            setValue={setEmail}
                            required={true}
                            invalidFeedbackText={EmailRegex.helpText}
                            type={"text"} label={"Applicant Email"}
                        />
                    </div>
                    <div className="col-md-6">
                        <TextArea id={"note"}
                        rows={3}
                            validation={/.+/}
                            doValidate={formInputValidate}
                            invalidFeedbackText=""
                            value={note}
                            setValue={setNote}
                            required={true}
                            label={"Note to Applicant"}
                        />
                    </div>
                </div> </> : <LoadingOverlay show={true}/>}
                <div className="mt-2">
                    <button disabled={sendingEmail} className={"btn btn-primary"} type={"submit"}>Send Invitation</button>
                    <button disabled={sendingEmail} className={"btn btn-danger"} type={"button"} onClick={onCancel}>Cancel</button>
                </div>
            </form>
        </Card>
    </>)
}

export default InviteToApply;