import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {
    Applicant,
    ApplicantType,
    ApplicationStatus,
    DriversLicenseRegex,
    EmailRegex,
    FirstNameRegex,
    FormElementValidation,
    formInputValidate,
    formSelectValidate,
    GenericSelectRegex,
    LastNameRegex,
    MiddleInitialRegex,
    PhoneNumberRegex,
    SsnRegex
} from "../../../types";
import Alert, {AlertProps, AlertType} from "../../Alert";
import {useHistory, useParams} from "react-router";
import StateSelect from "../../StateSelect";
import {getPortfolioId} from "../../../helpers";
import LoadingOverlay from "../../LoadingOverlay";
import Input from "../../Form/Input";
import Select from "../../Form/Select";
import {getApplication, updateApplication} from "../../../Api/ApplicationApi";
import ApplicationStageLayout from "../../../Layouts/ApplicationStageLayout";
import ButtonNew, {ButtonColors, ButtonTypes} from "../../ButtonNew";

type Stage2Props = {
    handleInvalidApplicationStatus: Function
    applicationId: string
    setStage: Function
}

const Stage2: React.FunctionComponent<Stage2Props> = (p) => {

    const history = useHistory();
    const { propertyId } = useParams();


    const [error, setError] = useState<Error>()
    const [alert, setAlert] = useState<AlertProps>()
    const [loading, setLoading] = useState(false);
    const [doRedirectToStage3, setDoRedirectToStage3] = useState(false);

    //form data
    const [applicantType, setApplicantType] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleInitial, setMiddleInitial] = useState("");
    const [lastName, setLastName] = useState("");
    const [maidenName, setMaidenName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [ssn, setSsn] = useState("");
    const [dlNumber, setDlNumber] = useState("");
    const [dlState, setDlState] = useState("");
    const [smoke, setSmoke] = useState("");
    const [gender, setGender] = useState("");
    const [isPrimary, setIsPrimary] = useState(false);

    type Form = {
        applicantType: FormElementValidation
        firstName: FormElementValidation,
        middleInitial: FormElementValidation,
        lastName: FormElementValidation,
        maidenName: FormElementValidation,
        email: FormElementValidation,
        phone: FormElementValidation,
        ssn: FormElementValidation,
        dlNumber: FormElementValidation,
        dlState: FormElementValidation,
        smoke: FormElementValidation,
        gender: FormElementValidation
    }

    const form: Form = {
        applicantType: {
            id: "applicantType",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        firstName: {
            id: "firstName",
            required: true,
            validate: formInputValidate,
            regex: FirstNameRegex
        },
        middleInitial: {
            id: "middleInitial",
            required: false,
            validate: formInputValidate,
            regex: MiddleInitialRegex
        },
        lastName: {
            id: "lastName",
            required: true,
            validate: formInputValidate,
            regex: LastNameRegex
        },
        maidenName: {
            id: "maidenName",
            required: false,
            validate: formInputValidate,
            regex: LastNameRegex
        },
        email: {
            id: "email",
            required: true,
            validate: formInputValidate,
            regex: EmailRegex
        },
        phone: {
            id: "phone",
            required: true,
            validate: formInputValidate,
            regex: PhoneNumberRegex
        },
        ssn: {
            id: "ssn",
            required: true,
            validate: formInputValidate,
            regex: SsnRegex
        },
        dlNumber: {
            id: "dlNumber",
            required: true,
            validate: formInputValidate,
            regex: DriversLicenseRegex
        },
        dlState: {
            id: "dlState",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        smoke: {
            id: "smoke",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        gender: {
            id: "gender",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        }
    };

    const [applicants, setApplicants] = useState(new Array<Applicant>())

    const [doRedirectToStage1, setDoRedirectToStage1] = useState(false);
    const [doHandleInvalidApplicationStatus, setDoHandleInvalidApplicationStatus] = useState(false);

    useEffect(() => {
        if (doHandleInvalidApplicationStatus) {
            p.handleInvalidApplicationStatus();
        }
    }, [doHandleInvalidApplicationStatus, p]);

    useEffect(() => {
        if (doRedirectToStage1) {
            setDoRedirectToStage1(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/1"
            });
        }

    }, [doRedirectToStage1, history])

    useEffect(() => {
        console.log("stage 2 init");
        p.setStage(2)
        setLoading(true);
        getApplication(getPortfolioId(), p.applicationId)
            .then((a) => {
                if (a.status !== ApplicationStatus.approved) {
                    if (!a.unit || (a.unit && !a.unit.uuid)) {
                        console.log("redirect to stage 1, missing unit")
                        setDoRedirectToStage1(true);
                        return;
                    }
                    if (a.applicants) {
                        setApplicants(a.applicants);
                    }
                    setLoading(false);
                } else {
                    setDoHandleInvalidApplicationStatus(true);
                }
            })
            .catch(e => {
                doShowAlert("Failed to start application: " + e.message, AlertType.DANGER);
                setLoading(false);
            })


    }, [p])

    const validateForm = (form: Form): boolean => {
        if (!form.firstName.validate(firstName, form.firstName.required, form.firstName.regex.expression)) {
            doShowAlert("Invalid first name", AlertType.DANGER);
            return false;
        }
        if (!form.middleInitial.validate(middleInitial, form.middleInitial.required, form.middleInitial.regex.expression)) {
            doShowAlert("Invalid middle initial", AlertType.DANGER);
            return false;
        }
        if (!form.lastName.validate(lastName, form.lastName.required, form.lastName.regex.expression)) {
            doShowAlert("Invalid last name", AlertType.DANGER);
            return false;
        }
        if (!form.maidenName.validate(maidenName, form.maidenName.required, form.maidenName.regex.expression)) {
            doShowAlert("Invalid maiden name", AlertType.DANGER);
            return false;
        }
        if (!form.email.validate(email, form.email.required, form.email.regex.expression)) {
            doShowAlert("Invalid email", AlertType.DANGER);
            return false;
        }
        if (!form.phone.validate(phone, form.phone.required, form.phone.regex.expression)) {
            doShowAlert("Invalid phone", AlertType.DANGER);
            return false;
        }
        if (!form.ssn.validate(ssn, form.ssn.required, form.ssn.regex.expression)) {
            doShowAlert("Invalid ssn/sin", AlertType.DANGER);
            return false;
        }
        if (!form.dlNumber.validate(dlNumber, form.dlNumber.required, form.dlNumber.regex.expression)) {
            doShowAlert("Invalid driver's license number", AlertType.DANGER);
            return false;
        }
        if (!form.dlState.validate(dlState, form.dlState.required, form.dlState.regex.expression)) {
            doShowAlert("Invalid driver's license state", AlertType.DANGER);
            return false;
        }
        if (!form.smoke.validate(smoke, form.smoke.required, form.smoke.regex.expression)) {
            doShowAlert("Invalid smoke selection", AlertType.DANGER);
            return false;
        }
        if (!form.gender.validate(gender, form.gender.required, form.gender.regex.expression)) {
            doShowAlert("Invalid gender selection", AlertType.DANGER);
            return false;
        }
        return true;
    }

    const saveAndContinueStage3 = () => {
        let hasPrimary = false;
        applicants.forEach(a => {
            if(a.primary === true) {
                hasPrimary = true;
            }
        })
        if(!hasPrimary) {
            doShowAlert("Missing primary applicant.", AlertType.DANGER);
            return;
        }
        setLoading(true);
        updateApplication(getPortfolioId(), p.applicationId, {
            applicants: applicants
        })
            .then((a) => {
                setDoRedirectToStage3(true);
            })
            .catch(e => {
                doShowAlert("Failed to add applicants: " + e.message, AlertType.DANGER);
            }).finally(() => setLoading(false));
    }

    const clearForm = () => {
        setApplicantType("");
        setFirstName("");
        setMiddleInitial("");
        setLastName("");
        setMaidenName("");
        setEmail("");
        setPhone("");
        setSsn("");
        setDlNumber("");
        setDlState("");
        setSmoke("");
        setGender("");
        setIsPrimary(false);
    }

    const doShowAlert = (message: string, type: AlertType) => {
        setAlert({type, message})
    }

    useEffect(() => {
        if (doRedirectToStage3) {
            setDoRedirectToStage3(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/3"
            });
        }

    }, [doRedirectToStage3, history])


    const onAddApplicantSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!validateForm(form)) {
            return;
        }
        if(isPrimary && applicants.length > 0) {
            applicants.forEach((a, index) => {
                a.primary = false;
            })
        }

        applicants.push({
            uuid: "",
            applicantType: ApplicantType[applicantType as keyof typeof ApplicantType],
            firstName: firstName,
            middleInitial: middleInitial,
            lastName: lastName,
            maidenName: maidenName,
            email: email,
            phone: phone,
            ssn: ssn,
            dlNumber: dlNumber,
            dlState: dlState,
            smoke: (smoke === "yes"),
            gender: gender,
            applicantAddresses: [],
            employmentIncomeSources: [],
            primary: isPrimary || applicants.length === 0
        });
        setApplicants(applicants)
        clearForm();
    }

    const deleteApplicant = (a: Applicant) => {
        setApplicants(applicants.filter(i => i !== a));
    }

    return (loading ? <div className="col-md-6"><LoadingOverlay /></div> : <>
        <ApplicationStageLayout
            error={error}
            alert={alert}
            title="Applicants"
            description="Please provide all applicants and lease guarantors"
        >
            <form onSubmit={onAddApplicantSubmit}>
            <div className="row">
                <div className="col-lg-6 mb-5">
                        <div className={"row"}>
                            <div className="col-md-6 mb-2">
                                <Select id={form.applicantType.id} validation={form.applicantType.regex.expression}
                                        doValidate={form.applicantType.validate}
                                        value={applicantType} setValue={setApplicantType}
                                        required={form.applicantType.required}
                                        invalidFeedbackText={form.applicantType.regex.helpText} label={"Type"}>
                                    <option value={""} disabled={true}>Select an applicant type...</option>
                                    <option value="occupant">Occupant</option>
                                    <option value="cooccupant">Co-occupant</option>
                                    <option value="guarantor">Lease guarantor only</option>
                                </Select>
                            </div>
                            <div className="col-md-6 mb-2 pt-4">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox"
                                           id="defaultCheck1"
                                           checked={isPrimary}
                                           onChange={e => {
                                               let value = e.target.checked;
                                               setIsPrimary(value);

                                           }}/>
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        Primary applicant
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Input id={form.firstName.id} validation={form.firstName.regex.expression}
                                       doValidate={form.firstName.validate}
                                       value={firstName}
                                       setValue={setFirstName}
                                       required={form.firstName.required}
                                       invalidFeedbackText={form.firstName.regex.helpText}
                                       type={"text"} label={"First name"}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <Input id={form.middleInitial.id} validation={form.middleInitial.regex.expression}
                                       doValidate={form.middleInitial.validate}
                                       value={middleInitial}
                                       setValue={setMiddleInitial}
                                       required={form.middleInitial.required}
                                       invalidFeedbackText={form.middleInitial.regex.helpText}
                                       type={"text"} label={"Middle initial"} maxLength={1}
                                />
                            </div>
                            <div className="col-md-6 mb-3">
                                <Input id={form.lastName.id} validation={form.lastName.regex.expression}
                                       doValidate={form.lastName.validate}
                                       value={lastName}
                                       setValue={setLastName}
                                       required={form.lastName.required}
                                       invalidFeedbackText={form.lastName.regex.helpText}
                                       type={"text"} label={"Last name"}
                                />
                            </div>
                            <div className={"col-md-6 mb-2"}>
                                <Input id={form.maidenName.id} validation={form.maidenName.regex.expression}
                                       doValidate={form.maidenName.validate}
                                       value={maidenName}
                                       setValue={setMaidenName}
                                       required={form.maidenName.required}
                                       invalidFeedbackText={form.maidenName.regex.helpText}
                                       type={"text"} label={"Maiden name"}
                                />
                            </div>
                            <div className={"col-md-6 mb-2"}>
                                <Input id={form.email.id} validation={form.email.regex.expression}
                                       doValidate={form.email.validate}
                                       value={email}
                                       setValue={setEmail}
                                       required={form.email.required}
                                       invalidFeedbackText={form.email.regex.helpText}
                                       type={"email"} label={"Email"}
                                />
                            </div>
                            <div className={"col-md-6 mb-2"}>
                                <Input id={form.phone.id} validation={form.phone.regex.expression}
                                       doValidate={form.phone.validate}
                                       value={phone}
                                       setValue={setPhone}
                                       required={form.phone.required}
                                       placeholder={"xxx-xxx-xxxx"}
                                       invalidFeedbackText={form.phone.regex.helpText}
                                       type={"tel"} label={"Phone"}
                                />
                            </div>
                            <div className={"col-md-6 mb-2"}>
                                <Input id={form.ssn.id} validation={form.ssn.regex.expression}
                                       doValidate={form.ssn.validate}
                                       value={ssn}
                                       setValue={setSsn}
                                       required={form.ssn.required}
                                       placeholder={"xxx-xx-xxxx"}
                                       invalidFeedbackText={form.ssn.regex.helpText}
                                       type={"text"} label={"SSN"}
                                />
                            </div>
                            <div className={"col-md-6 mb-2"}>
                                <Input id={form.dlNumber.id} validation={form.dlNumber.regex.expression}
                                       doValidate={form.dlNumber.validate}
                                       value={dlNumber}
                                       setValue={setDlNumber}
                                       required={form.dlNumber.required}
                                       invalidFeedbackText={form.dlNumber.regex.helpText}
                                       type={"text"} label={"Driver's license number"}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <StateSelect id={form.dlState.id} validation={form.dlState.regex.expression}
                                             noSelectionText={"Select a State..."}
                                             doValidate={form.dlState.validate}
                                             value={dlState} setValue={setDlState}
                                             required={form.dlState.required}
                                             invalidFeedbackText={form.dlState.regex.helpText}
                                             label={"Driver's license state"}/>

                            </div>
                            <div className="col-md-6 mb-2">
                                <Select id={form.smoke.id} validation={form.smoke.regex.expression}
                                        doValidate={form.smoke.validate}
                                        value={smoke} setValue={setSmoke}
                                        required={form.smoke.required}
                                        invalidFeedbackText={form.smoke.regex.helpText} label={"Smoke?"}>
                                    <option value="" disabled={true}>Choose...</option>
                                    <option value={"no"}>No</option>
                                    <option value={"yes"}>Yes</option>
                                </Select>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Select id={form.gender.id} validation={form.gender.regex.expression}
                                        doValidate={form.gender.validate}
                                        value={gender} setValue={setGender}
                                        required={form.gender.required}
                                        invalidFeedbackText={form.gender.regex.helpText} label={"Gender"}>
                                    <option value="" disabled={true}>Select a gender...</option>
                                    <option value="prefer_not_to_say">Prefer not to say</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </Select>
                            </div>
                            <div className={"col-md-12 mt-2"}>
                                <button type="submit" className="btn btn-primary">
                                    Add to applicants
                                </button>
                            </div>
                        </div>
                </div>
                <div className={"col-12"}>
                    <div className={"table-responsive"}>
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">First name</th>
                                <th scope="col">Middle name</th>
                                <th scope="col">Last name</th>
                                <th scope="col">Maiden name</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">SSN/SIN</th>
                                <th scope="col">Driver's license number</th>
                                <th scope="col">Driver's license state</th>
                                <th scope="col">Smoke?</th>
                                <th scope="col">Type</th>
                                <th scope="col">Primary</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                applicants.map((a, index) => (
                                    <tr key={"app_" + index}>

                                        <td>{a.firstName}</td>
                                        <td>{a.middleInitial}</td>
                                        <td>{a.lastName}</td>
                                        <td>{a.maidenName}</td>
                                        <td>{a.gender}</td>
                                        <td>{a.email}</td>
                                        <td>{a.phone}</td>
                                        <td>{a.ssn}</td>
                                        <td>{a.dlNumber}</td>
                                        <td>{a.dlState}</td>
                                        <td>{a.smoke ? "Yes" : "No"}</td>
                                        <td>{a.applicantType}</td>
                                        <td>{a.primary === true ? "Yes" : "No"}</td>
                                        <td>
                                            <button type="button" className="btn btn-danger btn-sm"
                                                    onClick={e => deleteApplicant(a)}
                                            >Remove
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-start gap-2 mt-3">
                        <ButtonNew
                            type={ButtonTypes.BUTTON}
                            color={ButtonColors.SECONDARY}
                            label="Back"
                            onClick={() => setDoRedirectToStage1(true)}
                        />
                        <ButtonNew
                            type={ButtonTypes.BUTTON}
                            color={ButtonColors.PRIMARY}
                            label="Save & Continue"
                            loading={loading}
                            disabled={applicants.length === 0}
                            onClick={saveAndContinueStage3}
                        />
                    </div>
                </div>
            </div>
            </form>
        </ApplicationStageLayout>
    </>);
};

export default Stage2;
