import {
    AddPhoneNumberBody,
    Address,
    ARAgingSummary, BankAccount,
    Business,
    PhoneNumber,
    PhoneNumberTag,
    Property,
    PropertyAccount,
    PropertyExpense,
    PropertyGeneralLedger, PropertyVendor
} from "../types";
import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod, serializeFilter} from "../api";
import Deposits from "../Pages/Accounting/Deposits";
import AddManualJournalEntry from "../Components/Accounting/DataEntry/AddManualJournalEntry";

interface GetPropertiesFilter {
    name?: string,
    addressLine1?: string,
    addressLine2?: string,
    city?: string,
    stateCode?: string,
    zip?: string,
    disabled?: string
}

interface AddPropertyBody {
    bankStatementName: string,
    emailReplyTo: string,
    managementFeePercent: number,
    name: string,
    ownerId: string,
    rentTrustAccountId?: string,
    securityDepositTrustAccountId?: string,
    reserveAmount: number,
    address: {
        addressLine1: string,
        addressLine2: string,
        city: string,
        stateCode: string,
        zip: string
    },
}

interface EditPropertyBody {
    name: string,
    ownerId: string,
    managementFeePercent: number,
    emailReplyTo: string,
    bankStatementName: string,
    reserveAmount: number,
    address: Address,
    quoteTheme: {
        propertyLogoImageId: string
        backgroundImageId: string
        navBarColor: string
    }
}

export interface PropertyMenuItems {
    makeAPayment: boolean;
    automaticPayments: boolean;
    paymentMethods: boolean;
    paymentHistory: boolean;
    instructions: boolean;
    guests: boolean;
    packages: boolean;
    maintenanceRequests: boolean;
    amenities: boolean;
    communityEvents: boolean;
    communityLinks: boolean;
}

type AddPropertyExpenseBody = {
    accountId: string,
    description: string,
    amount: number,
    date: string,
    vendorId: string
}

type AddPropertyVendor = {
    name: string,
    phone: string,
    address: Address,
    bankAccount?: BankAccount
}

type MarkAsPaidBody = {
    payDate: string
}

type DepositBody = {
    date: string,
    amount: number,
    description: string
}

type AddManualJournalEntryBody = {
    date: string,
    description: string,
    account1: string,
    debit1: number,
    credit1: number,
    account2: string,
    debit2: number,
    credit2: number
}

type getPropertyMenuItems = (propertyId: string) => Promise<PropertyMenuItems>
type getProperties = (filter: GetPropertiesFilter) => Promise<Property[]>;
type getPropertiesByPortfolioId = (portfolioId: string) => Promise<Property[]>;
type getProperty = (id: string) => Promise<Property>;
type getPropertyExpenseAccounts = (id: string) => Promise<PropertyAccount[]>;
type getPropertyAccounts = (id: string) => Promise<PropertyAccount[]>;
type getPropertyExpenses = (id: string) => Promise<PropertyExpense[]>;
type addPropertyExpense = (id: string, body: AddPropertyExpenseBody) => Promise<PropertyExpense>;
type markExpenseAsPaid = (id: string, expenseId: string, body: MarkAsPaidBody) => Promise<Response>;
type deposit = (id: string, body: DepositBody) => Promise<Response>;
type getPropertyGeneralLedger = (id: string, startDate: string, endDate: string) => Promise<PropertyGeneralLedger>;
type addProperty = (body: AddPropertyBody) => Promise<Property>;
type addManualJournalEntry = (id: string, body: AddManualJournalEntryBody) => Promise<Response>;
type editProperty = (uuid: string, body: EditPropertyBody) => Promise<Property>;
type getArAgingSummary = () => Promise<ARAgingSummary>;
type getVendors = (propertyId: string) => Promise<PropertyVendor[]>;
type addVendor = (propertyId: string, body: AddPropertyVendor) => Promise<PropertyVendor>;
type createPropertyBusiness = (propertyId: string, body: Business) => Promise<Business>;
type deletePropertyBusiness = (propertyId: string) => Promise<Response>;
type assignPropertyToBusiness = (propertyId: string, businessId: string) => Promise<Response>;
type disableProperty = (id: string) => Promise<Response>;
type enableProperty = (id: string) => Promise<Response>;
type getPropertyPhoneNumbers = (id: string) => Promise<PhoneNumber[]>;
type editPropertyPhoneNumberTag = (propertyId: string, phoneId: string, body: PhoneNumberTag) => Promise<PhoneNumberTag>
type addPropertyPhoneNumber = (propertyId: string, body: AddPhoneNumberBody) => Promise<AddPhoneNumberBody>
type releasePropertyPhoneNumber = (propertyId: string, phoneId: string) => Promise<void>;
type editPropertyMenuItems = (propertyId:string, body: PropertyMenuItems) => Promise<void>;

export const getPropertyMenuItems: getPropertyMenuItems = (async (propertyId:string) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/menuItems";
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getVendors: getVendors = (async (propertyId:string) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/vendors";
    return fetchWithAuth(path, HttpMethod.GET);
});

export const addVendor: addVendor = (async (propertyId: string, body: AddPropertyVendor) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/vendors";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const editPropertyMenuItems: editPropertyMenuItems = (async (propertyId: string, body: PropertyMenuItems) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/menuItems";
    return fetchWithAuth(path, HttpMethod.PUT, JSON.stringify(body));
});

export const addPropertyPhoneNumber: addPropertyPhoneNumber = (async (propertyId: string, body) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/phone-numbers";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const releasePropertyPhoneNumber: releasePropertyPhoneNumber = (async (propertyId: string, phoneId: string) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/phone-numbers/" + phoneId;
    console.log(path);
    return fetchWithAuth(path, HttpMethod.DELETE);
});

export const editPropertyPhoneNumberTag: editPropertyPhoneNumberTag = (async (propertyId: string, phoneId: string, body) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/phone-numbers/" + phoneId ;
    return fetchWithAuth(path, HttpMethod.PUT, JSON.stringify(body));
});

export const getPropertyPhoneNumbers: getPropertyPhoneNumbers = async (id) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + id + "/phone-numbers";
    return fetchWithAuth(path, HttpMethod.GET);
};

export const getProperties: getProperties = async (filter) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties" + "?" + serializeFilter(filter);
    return fetchWithAuth(path, HttpMethod.GET);
};

export const getPropertiesByPortfolioId: getPropertiesByPortfolioId = async (portfolioId) => {
    const path = "/portfolios/" + portfolioId + "/properties?disabled=false";
    return fetchWithAuth(path, HttpMethod.GET);
};

export const getProperty: getProperty = async (id) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + id;
    return fetchWithAuth(path, HttpMethod.GET);
};

export const getPropertyExpenseAccounts: getPropertyExpenseAccounts = async (id) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + id + "/expense-accounts";
    return fetchWithAuth(path, HttpMethod.GET);
};

export const getPropertyAccounts: getPropertyAccounts = async (id) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + id + "/mje-accounts";
    return fetchWithAuth(path, HttpMethod.GET);
};

export const getPropertyExpenses: getPropertyExpenses = async (id) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + id + "/expenses";
    return fetchWithAuth(path, HttpMethod.GET);
};

export const addPropertyExpense: addPropertyExpense = async (id, body) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + id + "/expenses";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
};

export const addManualJournalEntry: addManualJournalEntry = async (id, body) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + id + "/manual-journal-entries";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
};

export const markExpenseAsPaid: markExpenseAsPaid = async (id, expenseId, body) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + id + "/expenses/" + expenseId + "/mark-as-paid";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
};

export const deposit: deposit = async (id, body) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + id + "/deposits";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
};

export const getPropertyGeneralLedger: getPropertyGeneralLedger = async (id, startDate, endDate) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + id + "/general-ledger?startDate=" + startDate + "&endDate=" + endDate;
    return fetchWithAuth(path, HttpMethod.GET);
};

export const addProperty: addProperty = (async body => {
    const path = "/portfolios/" + getPortfolioId() + "/properties";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const getArAgingSummary: getArAgingSummary = async () => {
    const path = "/portfolios/" + getPortfolioId() + "/reports/ar-aging-summary";
    return fetchWithAuth(path, HttpMethod.GET);
};

export const createPropertyBusiness: createPropertyBusiness = (async (propertyId, body) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/business";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const deletePropertyBusiness: deletePropertyBusiness = (async (propertyId) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/business";
    return fetchWithAuth(path, HttpMethod.DELETE);
});

export const assignPropertyToBusiness: assignPropertyToBusiness = (async (propertyId, businessId) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/business/" + businessId;
    return fetchWithAuth(path, HttpMethod.POST);
});

export const editProperty: editProperty = (async (uuid: string, body) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + uuid;
    return fetchWithAuth(path, HttpMethod.PUT, JSON.stringify(body));
});

export const disableProperty: disableProperty = (async (uuid) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + uuid + "/disable";
    return fetchWithAuth(path, HttpMethod.POST);
});

export const enableProperty: enableProperty = (async (uuid) => {
    const path = "/portfolios/" + getPortfolioId() + "/properties/" + uuid + "/enable";
    return fetchWithAuth(path, HttpMethod.POST);
});
