import {FormBuilderField, FormFieldType, InputFieldType} from "../../../Form/FieldBuilder/FormFieldBuilder";
import {
    DateRegex,
    formInputValidate,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex
} from "../../../../types";

interface TenantGuestForm {
    accountId: FormBuilderField,
    firstName: FormBuilderField,
    lastName: FormBuilderField,
    details: FormBuilderField,
    vehicleDescription: FormBuilderField,
    vehicleTagNumber: FormBuilderField,
    allowedKey: FormBuilderField,
    secretPhrase: FormBuilderField,
    permanent: FormBuilderField,
    startDate: FormBuilderField,
    endDate: FormBuilderField
}

const TenantGuestForm: TenantGuestForm = {
    accountId: {
        type: FormFieldType.INPUT,
        element: {
            id: "accountId",
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        disabled: true,
        label: "Unit",
        value: undefined,
        setValue: () => {}
    },
    firstName: {
        type: FormFieldType.INPUT,
        element: {
            id: "firstName",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "First Name",
        value: undefined,
        setValue: () => {}
    },
    lastName: {
        type: FormFieldType.INPUT,
        element: {
            id: "lastName",
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "Last Name",
        value: undefined,
        setValue: () => {}
    },
    details: {
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 8,
        element: {
            id: "details",
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "Details",
        value: undefined,
        setValue: () => {}
    },
    vehicleDescription: {
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 2,
        element: {
            id: "vehicleDescription",
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "Vehicle Description",
        value: undefined,
        setValue: () => {}
    },
    vehicleTagNumber: {
        type: FormFieldType.INPUT,
        element: {
            id: "vehicleTagNumber",
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "Vehicle Licence Plate",
        value: undefined,
        setValue: () => {}
    },
    allowedKey: {
        type: FormFieldType.SELECT,
        element: {
            id: "allowedKey",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex,
        },
        label: "Allowed Key",
        defaultSelectOptions: [
            {
                value: "No",
                label: "No"
            },
            {
                value: "Yes",
                label: "Yes"
            },
        ],
        value: undefined,
        setValue: () => {}
    },
    secretPhrase: {
        type: FormFieldType.PASSWORD,
        element: {
            id: "secretPhrase",
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "Secret Phrase",
        value: undefined,
        setValue: () => {}
    },
    permanent: {
        type: FormFieldType.SELECT,
        element: {
            id: "permanent",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex,
        },
        label: "Permanent",
        defaultSelectOptions: [
            {
                value: "No",
                label: "No"
            },
            {
                value: "Yes",
                label: "Yes"
            },
        ],
        value: undefined,
        setValue: () => {}
    },
    startDate: {
        type: FormFieldType.DATE,
        element: {
            id: "startDate",
            required: false,
            validate: formInputValidate,
            regex: DateRegex,
        },
        label: "Start Date",
        value: undefined,
        setValue: () => {}
    },
    endDate: {
        type: FormFieldType.DATE,
        element: {
            id: "endDate",
            required: false,
            validate: formInputValidate,
            regex: DateRegex,
        },
        label: "End Date",
        value: undefined,
        setValue: () => {}
    },
}

export default TenantGuestForm;
