import * as React from 'react';
import {Route, Switch, useRouteMatch} from "react-router";
import SearchLeases from "./SearchLeases";
import ViewLease from "./ViewLease";
import AutoSelectProperty from "../Form/SelectProperty/AutoSelectProperty";

const ManageLeases: React.FunctionComponent = () => {

    let {path} = useRouteMatch();

    return (<>
        <Switch>
            <Route exact path={path}>
                <AutoSelectProperty name={"Leases"} />
            </Route>
            <Route exact path={path + "/:propertyId"}>
                <SearchLeases />
            </Route>
            <Route exact path={path + "/:propertyId/view/:leaseId"}>
                <ViewLease/>
            </Route>
        </Switch>
    </>);
};

export default ManageLeases;