import React, {useCallback, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {getPropertyPhoneNumbers, releasePropertyPhoneNumber} from "../../../Api/PropertyApi";
import parsePhoneNumber from 'libphonenumber-js'
import {Link, useLocation} from "react-router-dom";
import PhoneNumberSearch from "../PhoneNumberSearch";


const EditPropertyPhoneNumber: React.FunctionComponent = () => {

    const location = useLocation();
    const history = useHistory();
    const {propertyUuid} = useParams();
    const {phoneNumberId} = useParams();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [showReplace, setShowReplace] = useState(false);
    const [currentPhoneNumberTag, setCurrentPhoneNumberTag] = useState("");
    const [replacementPhoneNumber, setReplacementPhoneNumber] = useState("");
    const [loading, setLoading] = useState(false);


    const load = useCallback(() => {
        getPropertyPhoneNumbers(propertyUuid)
            .then((pz) => {
                let filtered = pz.filter((p) => p.uuid === phoneNumberId);
                if (filtered.length > 0) {
                    setCurrentPhoneNumberTag(filtered[0].tag)
                    setPhoneNumber(parsePhoneNumber(filtered[0].phoneNumber)?.formatNational() || "")
                }
            })
            .catch((e) => console.error(e))
    }, [propertyUuid, phoneNumberId])

    useEffect(() => {
        load();
    }, [load]);

    const onRelease = () => {
        setLoading(true);
        releasePropertyPhoneNumber(propertyUuid, phoneNumberId).then(() => {
            history.push({
                pathname: location.pathname.substring(0, location.pathname.length - (phoneNumberId.length + 1))
            });
        }).catch((e) => console.error(e.message))
            .finally(() => setLoading(false));
    }

    function getAddPhoneNumberComponent() {
        return <>
            <PhoneNumberSearch tag={currentPhoneNumberTag} title={"Replace Phone Number"} onComplete={(phoneNumber: string) => {
                setShowReplace(false);
                setReplacementPhoneNumber(phoneNumber)
                onRelease();
            }} onBack={() => {
                setShowReplace(false)
            }}/>
        </>
    }

    function getContent() {
        console.log("DEBUG:");
        console.log({showReplace});

        if (showReplace) {
            return getAddPhoneNumberComponent();
        } else {
            return getEditPropertyComponent();
        }
    }

    function getEditPropertyComponent() {
        return <>
            <div id={"container"} className={"p-3"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <h4>Edit Property Phone Number</h4>
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col"}>
                        <h6>Selected Phone Number: {phoneNumber}</h6>
                        <form className={"form-container"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h6><b>Release Phone Number</b></h6>
                                    <p>By Releasing a Phone Number, the phone number will no longer be associated with
                                        the
                                        property. This will leave any incoming or outgoing
                                        communications unfulfilled. Releasing a Notification phone number will
                                        remove the option for tenants to receive SMS communications.
                                        Releasing a Inbound Communication phone number will prevent any communications
                                        to
                                        the selected phone number from being processed. Please ensure tenants are made
                                        aware
                                        of any number
                                        release.</p>
                                    <b>Once a Phone Number is released, the action cannot be undone.</b>
                                    <div hidden={loading}>
                                        <button type={"button"} className={"btn btn-danger"}
                                                style={{float: "left", marginBottom: 22, marginTop: 22}}
                                                onClick={onRelease}>Release Phone
                                            Number
                                        </button>
                                    </div>
                                    <div hidden={!loading} className="spinner-grow text-primary"
                                         role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form className={"form-container"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <h6><b>Replace Phone Number</b></h6>
                                    <p>By Replacing a Phone Number, the current phone number will no longer be
                                        associated
                                        with the property. This will leave any incoming or outgoing
                                        communications unfulfilled. Replacing a Notification phone number will preserve
                                        the
                                        option for tenants to receive SMS communications, however, the notifications
                                        will be
                                        coming from a different number. Replacing an Inbound Communication phone number
                                        will
                                        prevent any communications to the current phone number from being processed.
                                        Please
                                        ensure tenants are made aware of any number replacement.</p>
                                    <b>Once a Phone Number is replaced, the action cannot be undone.</b>
                                    <div>
                                        <button type={"button"} onClick={() => setShowReplace(true)}
                                                className={"btn btn-primary"}
                                                style={{float: "left", marginBottom: 22, marginTop: 22}}>Replace Phone
                                            Number
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <div className={"row"} style={{paddingTop: 22}}>
                    <div className={"col"}>
                        <Link to={location.pathname.substring(0, location.pathname.length - (propertyUuid.length + 1))}>
                            <button className={"btn btn-danger"}>Go Back</button>
                        </Link>
                    </div>
                </div>

            </div>
        </>
    }


    return <>
        {getContent()}
    </>
}

export default EditPropertyPhoneNumber;
