import {CashAccount, IncomeStatementReport, OwnerReportDto, OwnerStatementDto, PastDueLease} from "../types";
import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod} from "../api";

type getIncomeStatementReport = (propertyId: string, startDate: string, endDate: string, cashBasis: boolean) => Promise<IncomeStatementReport>;
type getCashAccountReport = () => Promise<CashAccount[]>;
type getPastDueLeaseReport = () => Promise<PastDueLease[]>;
type getOwnerStatement = (reportId: string) => Promise<OwnerStatementDto>;
type getOwnerReports = (ownerId: string) => Promise<OwnerReportDto[]>;

export const getIncomeStatementReport: getIncomeStatementReport = (async (propertyId: string, startDate: string, endDate: string, cashBasis: boolean) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/reports/income-statement" + "?startDate=" + startDate + "&endDate=" + endDate + "&propertyId=" + propertyId + "&cashBasis=" + cashBasis, HttpMethod.GET);
});

export const getCashAccountReport: getCashAccountReport = (async () => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/reports/cash-accounts", HttpMethod.GET);
});

export const getPastDueLeaseReport: getPastDueLeaseReport = (async () => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/reports/past-due-leases", HttpMethod.GET);
});

export const getOwnerStatement: getOwnerStatement = (async (reportId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/reports/owner-statement/" + reportId, HttpMethod.GET);
});

export const getOwnerReports: getOwnerReports = (async (ownerId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/reports/owner-reports/" + ownerId, HttpMethod.GET);
});
