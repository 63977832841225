import * as React from 'react';
import {Redirect, Route, Switch, useRouteMatch} from "react-router";
import SearchUsers from "../../Pages/ManageUsers/SearchUsers";
import AddUser from "../../Pages/ManageUsers/AddUser";
import EditUser from "../../Pages/ManageUsers/EditUser";
import Subscriptions from "../../Pages/Subscriptions";
import PortfolioSettings from "../../Pages/Settings/Portfolio";

const SettingsContainer: React.FunctionComponent = () => {
    let {path} = useRouteMatch();
    return (<>
        <Switch>
            <Route exact path={path}>
                <Redirect to={path + "/users"} />
            </Route>
            <Route exact path={path + "/subscriptions"}>
                <Subscriptions />
            </Route>
            <Route exact path={path + "/users"}>
                <SearchUsers/>
            </Route>
            <Route exact path={path + "/users/:userId/edit"}>
                <EditUser/>
            </Route>
            <Route exact path={path + "/users/add"}>
                <AddUser/>
            </Route>
            <Route exact path={path + "/portfolio"}>
                <PortfolioSettings/>
            </Route>
        </Switch>
    </>);
};

export default SettingsContainer;
