import * as React from "react";
import {Redirect, Switch, useRouteMatch} from "react-router";
import { Route } from "react-router-dom";
import CommunityLinkRouter from "../../Components/CommunityLinks/CommunityLinkRouter";
import TenantPackageRouter from "../../Components/TenantPackage/TenantPackageRouter";
import AmenityRouter from "../../Components/Amenity/AmenityRouter";
import FrontDeskRouter from "../../Components/FrontDesk/FrontDeskRouter";
import TenantMessagesRouter from "../../Components/TenantMessages/TenantMessagesRouter";
import CommunityEventsRouter from "../../Components/CommunityEvents/CommunityEventsRouter";

const TenantServicesContainer: React.FunctionComponent = () => {
    let { path } = useRouteMatch();
    return (
       <Switch>
           <Route exact path={path}>
               <Redirect to={path + "/amenities"} />
           </Route>
           <Route path={path + "/amenities"}>
               <AmenityRouter />
           </Route>
           <Route path={path + "/community-events"}>
               <CommunityEventsRouter />
           </Route>
           <Route exact={false} path={path + "/community-links"}>
               <CommunityLinkRouter />
           </Route>
           <Route path={path + "/front-desk"}>
               <FrontDeskRouter />
           </Route>
           <Route path={path + "/messages"}>
               <TenantMessagesRouter />
           </Route>
           <Route path={path + "/packages"}>
               <TenantPackageRouter />
           </Route>
       </Switch>
    )
}

export default TenantServicesContainer;
