import * as React from "react";
import {Elements} from "@stripe/react-stripe-js";
import AddPaymentMethod from "../AddPaymentMethod";
import {loadStripe, Stripe} from "@stripe/stripe-js";
import './index.css'
import {useEffect, useState} from "react";
import {getStripePublicKey} from "../../../Api/StripePublicKeyApi";


type Params = {
    setPaymentSourceId: Function,
    amount: number,
    firstName: string,
    lastName: string
}
const BillingInformationCard: React.FunctionComponent<Params> = (p) => {

    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

    const [stripePromise, setStripePromise] = useState<Stripe>();

    useEffect(() => {
        getStripePublicKey()
            .then((r) => {
                    loadStripe(r.key)
                        .then(s => {
                            if(s) {
                                setStripePromise(s);
                            }
                        })
                        .catch(e => console.error(e));

            })
            .catch((e) => console.error(e.message));
    }, []);

    // const stripePromise = loadStripe("pk_test_51LnNqDC6wgekOM7kIXEaLHsg4tSRRLgB3rCmMkYdE6RBEp03BB06dLJ1YzBznq6va5tweEKrqZYy7lKCZBJztBnk00q0ST0JAs");

    const getElements = () => {
        if(stripePromise) {
            return <Elements stripe={stripePromise}>
                <AddPaymentMethod
                    setCardId={(c: string) => p.setPaymentSourceId(c)}
                    savedCards={[]}
                />
            </Elements>
        }
        return <></>
    }

    return <>
        <div className={"row justify-content-md-center"}>
            <div className={"col-lg-6 mb-3"}>
                <h4>Billing Information</h4>
            </div>
        </div>
        <div className={"row justify-content-md-center"}>
            <div className={"col-lg-6 mb-3"}>
                <h5>Your card will be charged {currencyFormat.format(p.amount)} today.</h5>
            </div>
        </div>
        <div className={"row"}>
            <div className={"col mb-3 control"}>
                {getElements()}
            </div>
        </div>

    </>
}

export default BillingInformationCard;