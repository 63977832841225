
import {formInputValidate, GenericInputRegex, URLRegex} from "../../../types";
import {FormBuilderField, FormFieldType, InputFieldType} from "../../Form/FieldBuilder/FormFieldBuilder";

interface CommunityLinkForm {
    name: FormBuilderField,
    url: FormBuilderField,
    description: FormBuilderField,
    category: FormBuilderField,
}


const CommunityLinkForm: CommunityLinkForm = {

    name:{
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 1,
        element: {
            id: 'name',
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Link name',
        value: undefined,
        setValue: () => {}
    },
    url:{
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 1,
        element: {
            id: 'URL',
            required: true,
            validate: formInputValidate,
            regex: URLRegex
        },
        label: 'Link Address',
        value: undefined,
        setValue: () => {}
    },
    description:{
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 1,
        element: {
            id: 'description',
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Description',
        value: undefined,
        setValue: () => {}
    },
    category:{
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 1,
        element: {
            id: 'category',
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Category',
        value: undefined,
        setValue: () => {}
    }

}

export default CommunityLinkForm;
