import * as React from 'react';
import Table from "../../../Table";
import {FormEvent, useCallback, useEffect, useState} from "react";
import {EmailRegex, formInputValidate, GenericInputRegex, LeaseUser} from "../../../../types";
import {Link, useParams} from "react-router-dom";
import Input from "../../../Form/Input";
import {addUserToLease, deleteUserOnLease, getUsersOnLease} from "../../../../Api/LeaseApi";
import Card from "../../../Card";
import {resendUserInvite, userPasswordReset} from "../../../../Api/PortfolioApi";
import Checkbox from "../../../Form/Checkbox/Checkbox";
import {getPortfolioId} from "../../../../helpers";

const Users: React.FunctionComponent = (p) => {
    const {leaseId} = useParams();
    const [users, setUsers] = useState<LeaseUser[]>([]);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [selectedResendInvite, setSelectedResendInvite] = useState("");
    const [selectedResetPassword, setSelectedResetPassword] = useState("");
    const [resetEmail, setResetEmail] = useState("");
    const [resetPassword, setResetPassword] = useState("");
    const [resetPasswordSendEmail, setResetPasswordSendEmail] = useState(true);
    const [isLoadingResetPassword, setIsLoadingResetPassword] = useState(false);
    const [resetPasswordError, setResetPasswordError] = useState("");

    const doAdd = () => {
        setMessage("");
        if (email.trim() !== "") {
            addUserToLease(leaseId, email)
                .then((u) => {
                    setEmail("");
                    load();
                })
                .catch((e) => {
                    console.error(e);
                    setMessage(e.message);
                });
        }
    }

    useEffect(() => {
        setResetPassword(generateRandomString);
    }, [resetEmail]);

    useEffect(() => {
        if (selectedResendInvite) {
            resendUserInvite(selectedResendInvite)
                .then((r) => {
                })
                .catch(e => setMessage(e.message))
                .finally(() => setSelectedResendInvite(""));
        }
    }, [selectedResendInvite]);

    function getSpinner(id: string) {
        if (id === selectedResendInvite) {
            return <>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span className="visually-hidden" role="status">Loading...</span>
            </>
        }
        return <></>;
    }

    function getSpinnerResetPassword() {
        if (isLoadingResetPassword) {
            return <>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span className="visually-hidden" role="status">Loading...</span>
            </>
        }
        return <></>;
    }

    function getResendInviteButton(id?: string, activated?: boolean) {
        if (id && !activated) {
            return <button className={"btn btn-primary me-3"} type={"button"} onClick={(e) => {
                e.preventDefault();
                setSelectedResendInvite(id);
            }}>
                Resend Invite
            </button>
        }
    }

    function getResetPassword(id: string, email: string, activated: boolean) {
        if (id) {
            return <button className={"btn btn-primary me-3"} type={"button"} onClick={(e) => {
                e.preventDefault();
                setSelectedResetPassword(id);
                setResetEmail(email);
            }}>
                {getSpinner(id)}
                {activated ? 'Reset' : 'Set'} Password
            </button>
        }
    }

    const doDelete = (userId?: string) => {
        if (userId) {
            deleteUserOnLease(leaseId, userId)
                .then((r) => {
                    if (r.ok) {
                        load();
                    }
                })
                .catch((e) => {
                    console.error(e);
                    setMessage(e.message);
                })
        }
    }

    const load = useCallback(() => {
        getUsersOnLease(leaseId)
            .then((uz) => {
                setUsers(uz);
            })
            .catch((e) => console.error(e))
    }, [leaseId])

    useEffect(() => {
        load();
    }, [load])

    function getManageUsersView() {
        return <>
            <div className="row">
                <div className="col-lg-6">
                    <Input id={"email"} validation={EmailRegex.expression} doValidate={formInputValidate}
                           value={email} setValue={setEmail} required={true}
                           invalidFeedbackText={EmailRegex.helpText} type={"email"} label={"Email"}/>
                </div>
                <div className="col-lg-6">
                    <button type={"button"} className={"btn btn-primary"} style={{marginTop: 20}}
                            onClick={(e) => {
                                doAdd();
                            }}>Add
                    </button>
                </div>
                <div hidden={!message} className="col-md-12">
                    <div className="alert alert-danger mt-1" role="alert">
                        {message}
                    </div>
                </div>
                <div className="col-md-12">
                    <Table headers={["Email", "First Name", "Last Name", ""]} defaultPaginationSize={10}>
                        {
                            users.map((u) => (
                                <tr key={u.uuid}>
                                    <td>{u.email}{!u.activated ? <em> (pending invite)</em> : <></>}</td>
                                    <td>{u.firstName}</td>
                                    <td>{u.lastName}</td>
                                    <td>
                                        {getResendInviteButton(u.uuid, u.activated)}
                                        {u.uuid && u.activated !== undefined && getResetPassword(u.uuid, u.email, u.activated)}
                                        <button className={"btn btn-danger me-3"} onClick={(e) => {
                                            e.preventDefault();
                                            doDelete(u.uuid)
                                        }}>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </Table>
                </div>
            </div>
        </>
    }

    function generateRandomString() {
        const lowerCase = "abcdefghijkmnpqrstuvwxyz";
        const upperCase = "ABCDEFGHJKLMNPQRSTUVWXYZ";
        const numbers = "0123456789";
        const allChars = lowerCase + upperCase + numbers;

        let result = "";

        // Ensure at least one character from each group
        result += lowerCase[Math.floor(Math.random() * lowerCase.length)];
        result += upperCase[Math.floor(Math.random() * upperCase.length)];
        result += numbers[Math.floor(Math.random() * numbers.length)];

        // Fill the rest with random characters from the combined set
        for (let i = 3; i < 8; i++) {
            result += allChars[Math.floor(Math.random() * allChars.length)];
        }

        // Shuffle the result to make the order random
        result = result.split('').sort(() => Math.random() - 0.5).join('');

        return result;
    }

    function clear() {
        setResetPasswordError("");
        setSelectedResetPassword("");
        setResetPassword("");
        setResetPasswordSendEmail(true);
        setResetEmail("");
    }

    function doPasswordReset(e: FormEvent) {
        e.preventDefault();
        setResetPasswordError("");
        setIsLoadingResetPassword(true);
        userPasswordReset(getPortfolioId(), selectedResetPassword, {
            password: resetPassword,
            sendEmail: resetPasswordSendEmail,
            userType: "tenant"
        })
            .then(r => {
                clear()
                load();
            })
            .catch((e) => setResetPasswordError(e.message))
            .finally(() => setIsLoadingResetPassword(false))
    }

    function getContent() {
        if (selectedResetPassword) {
            return <>
                {resetPasswordError && <div className="alert alert-danger" role="alert">
                    {resetPasswordError}
                </div>}
                <form onSubmit={doPasswordReset}>
                <div className={"row"}>
                    <div className={"col-md-6 mb-3"}>
                        <Input id={"email"} validation={EmailRegex.expression} doValidate={formInputValidate}
                               value={resetEmail} setValue={setResetEmail} required={true} disabled={true}
                               invalidFeedbackText={EmailRegex.helpText} type={"email"} label={"Email"}/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-6 mb-3"}>
                        <Input id={"password"} validation={GenericInputRegex.expression} doValidate={formInputValidate}
                               value={resetPassword} setValue={setResetPassword} required={true} disabled={true}
                               invalidFeedbackText={GenericInputRegex.helpText} type={"text"} label={"Password"}/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-6 mb-3"}>
                        <Checkbox
                            id={"checkbox"}
                            validateClass={''}
                            value={resetPasswordSendEmail}
                            setValue={setResetPasswordSendEmail}
                            required={false}
                            invalidFeedbackText={GenericInputRegex.helpText}
                            label={"Send email to user with password"}
                            disabled={false}
                        />
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <button type={"button"} className={"btn btn-secondary"} onClick={() => {
                            clear();
                        }}>
                            Back
                        </button>
                        <button type={"submit"} className={"btn btn-primary"}>
                            {getSpinnerResetPassword()}
                            Save
                        </button>
                    </div>

                </div>
                </form>
            </>;
        }
        return getManageUsersView();
    }

    return (<>
        <Card title="Users">
            {getContent()}
        </Card>
    </>);
};

export default Users;
