import * as React from 'react';
import {FormEvent, useCallback, useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import Alert, {AlertType} from "../../Alert";
import StateSelect from "../../StateSelect";
import Modal from "../../Modal";
import Input from "../../Form/Input";
import {
    AddressLine1Regex,
    AddressLine2Regex,
    BankStatementNameRegex,
    Business,
    CityRegex,
    DollarAmountRegex,
    EINRegex,
    EmailRegex,
    FileMetadata,
    FirstNameRegex,
    formInputValidate,
    formSelectValidate,
    GenericSelectRegex,
    GetBankAccount,
    LastNameRegex, PercentRegex,
    PhoneNumberRegex,
    PropertyNameRegex,
    SsnRegex,
    ZipRegex
} from "../../../types";
import Select from "../../Form/Select";
import {
    disableProperty,
    editProperty,
    editPropertyMenuItems,
    enableProperty,
    getProperty,
    getPropertyMenuItems,
    PropertyMenuItems
} from "../../../Api/PropertyApi";
import FormFieldBuilder, {assignFormFieldValues} from "../../Form/FieldBuilder/FormFieldBuilder";
import EditPropertyForm from "./Forms/EditPropertyForm";
import {getFile, uploadFile} from "../../../Api/FileApi";
import PageLayout from "../../../Layouts/PageLayout";
import Card from "../../Card";
import {addOwner, getOwners, getTrustAccounts} from "../../../Api/PortfolioApi";
import {getPortfolioId} from "../../../helpers";


const EditProperty: React.FunctionComponent = () => {

    let TENANT_UI_ENDPOINT = "https://tenant.propertymq.com";

    if (window.location.hostname === "localhost" && parseInt(window.location.port) >= 3000 && parseInt(window.location.port) < 4000)
        TENANT_UI_ENDPOINT = window.location.protocol + '//' + window.location.hostname + ":3000";


    if (window.location.hostname === "localhost" && parseInt(window.location.port) >= 4000 && parseInt(window.location.port) < 5000)
        TENANT_UI_ENDPOINT = window.location.protocol + '//' + window.location.hostname + ":4000";

    const [name, setName] = useState("");
    const [bankStatementName, setBankStatementName] = useState("");
    const [emailReplyTo, setEmailReplyTo] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(AlertType.SUCCESS);
    const [showDisableModal, setShowDisableModal] = useState(false);

    const [businessStructure, setBusinessStructure] = useState("");
    const [ein, setEin] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [phone, setPhone] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [ssn, setSsn] = useState("")

    const [businessAddressLine1, setBusinessAddressLine1] = useState("");
    const [businessAddressLine2, setBusinessAddressLine2] = useState("");
    const [businessCity, setBusinessCity] = useState("");
    const [businessState, setBusinessState] = useState("");
    const [businessZip, setBusinessZip] = useState("");

    const [bankAccountName, setBankAccountName] = useState("");

    const [propertyLogoImageId, setPropertyLogoImageId] = useState();
    const [propertyLogoImageMetadata, setPropertyLogoImageMetadata] = useState<FileMetadata>();
    const [propertyLogoImage, setPropertyLogoImage] = useState();
    const [propertyLogoImageLoading, setPropertyLogoImageLoading] = useState(false);
    const [propertyLogoImageHasError, setPropertyLogoImageHasError] = useState(false);
    const [propertyLogoImageHasErrorText, setPropertyLogoImageHasErrorText] = useState("");

    const [backgroundImageId, setBackgroundImageId] = useState();
    const [backgroundImageMetadata, setBackgroundImageMetadata] = useState<FileMetadata>();
    const [backgroundImageLoading, setBackgroundImageLoading] = useState(false);
    const [backgroundImageHasError, setBackgroundImageHasError] = useState(false);
    const [backgroundImageHasErrorText, setBackgroundImageHasErrorText] = useState("");
    const [backgroundImage, setBackgroundImage] = useState();
    const [navBarColor, setNavBarColor] = useState("");
    const [propertyMenuItems, setPropertyMenuItems] = useState<PropertyMenuItems>();
    const [selectedRentTrustAccount, setSelectedRentTrustAccount] = useState("");
    const [selectedSecurityDepositTrustAccount, setSelectedSecurityDepositTrustAccount] = useState("");
    const [trustAccounts, setTrustAccounts] = useState<GetBankAccount[]>([]);
    const [loadingTrustAccounts, setLoadingTrustAccounts] = useState(false);
    const [loadingAddRentTrustAccount, setLoadingAddRentTrustAccount] = useState(false);
    const [loadingAddSecurityDepositAccount, setLoadingAddSecurityDepositAccount] = useState(false);
    const [loadingOwners, setLoadingOwners] = useState(false);
    const [owners, setOwners] = useState<Business[]>([]);
    const [selectedOwner, setSelectedOwner] = useState("");

    const [reserveAmount, setReserveAmount] = useState("")
    const [doAddOwner, setDoAddOwner] = useState(false);
    const [addOwnerError, setAddOwnerError] = useState("");
    const [loadingAddOwner, setLoadingAddOwner] = useState(false);
    const [managementFeePercent, setManagementFeePercent] = useState("")

    const {propertyUuid} = useParams();

    function load() {
        setLoadingTrustAccounts(true);
        getTrustAccounts(getPortfolioId())
            .then(tz => {
                setTrustAccounts(tz);
            })
            .catch((e) => {
                console.error(e.message);
            })
            .finally(() => setLoadingTrustAccounts(false));

        setLoadingOwners(true);
        getOwners(getPortfolioId())
            .then((oz) => {
                setOwners(oz);
            })
            .catch((e) => console.error(e.message))
            .finally(() => setLoadingOwners(false));
    }

    useEffect(() => {
        load();
    }, []);

    assignFormFieldValues({
        field: EditPropertyForm.propertyLogoImageUrl,
        value: propertyLogoImage,
        setValue: setPropertyLogoImage,
        loading: propertyLogoImageLoading,
        hasError: propertyLogoImageHasError,
        hasErrorText: propertyLogoImageHasErrorText

    });
    assignFormFieldValues({
        field: EditPropertyForm.backgroundImageUrl,
        value: backgroundImage,
        setValue: setBackgroundImage,
        loading: backgroundImageLoading,
        hasError: backgroundImageHasError,
        hasErrorText: backgroundImageHasErrorText
    });
    assignFormFieldValues({field: EditPropertyForm.navBarColor, value: navBarColor, setValue: setNavBarColor});

    useEffect(() => {
        if (propertyLogoImageId) {
            setPropertyLogoImageLoading(true);
            getFile(propertyLogoImageId)
                .then((r) => {
                    setPropertyLogoImageMetadata(r);
                })
                .catch((e) => console.log(e))
                .finally(() => setPropertyLogoImageLoading(false));
        }
    }, [propertyLogoImageId]);

    useEffect(() => {
        if (backgroundImageId) {
            setBackgroundImageLoading(true);
            getFile(backgroundImageId)
                .then((r) => {
                    setBackgroundImageMetadata(r);
                })
                .catch((e) => console.log(e))
                .finally(() => setBackgroundImageLoading(false));
        }
    }, [backgroundImageId]);

    useEffect(() => {
        if (propertyLogoImage) {

            setPropertyLogoImageLoading(true);
            setPropertyLogoImageHasError(false);
            setPropertyLogoImageHasErrorText("");
            uploadFile(propertyLogoImage)
                .then((r) => {
                    setPropertyLogoImageId(r.uuid);
                })
                .catch((e) => {
                    setPropertyLogoImageHasError(true);
                    setPropertyLogoImageHasErrorText("Error uploading file: " + e.message)
                })
                .finally(() => setPropertyLogoImageLoading(false));

        }
    }, [propertyLogoImage])

    useEffect(() => {
        if (backgroundImage) {

            setBackgroundImageLoading(true);
            setBackgroundImageHasError(false);
            setBackgroundImageHasErrorText("");
            uploadFile(backgroundImage)
                .then((r) => {
                    setBackgroundImageId(r.uuid);
                })
                .catch((e) => {
                    setBackgroundImageHasError(true);
                    setBackgroundImageHasErrorText("Error uploading file: " + e.message)
                })
                .finally(() => setBackgroundImageLoading(false));

        }
    }, [backgroundImage])

    const loadMenuItems = useCallback(() => {
        getPropertyMenuItems(propertyUuid).then((data) => {
            setPropertyMenuItems(data);
        })
            .catch(e => console.error(e));
    }, [propertyUuid])


    const editMenuItems = () => {
        if (propertyMenuItems) {
            editPropertyMenuItems(propertyUuid, propertyMenuItems)
                .then(() => loadMenuItems())
                .catch(e => console.error(e));
        }
    }


    useEffect(() => {
        setLoading(true);
        getProperty(propertyUuid)
            .then((data) => {
                setName(data.name || "");
                if (data.owner?.person) {
                    setFirstName(data.owner.person.firstName);
                    setLastName(data.owner.person.lastName);
                    if (data.owner.person.ssn) {
                        setSsn("**" + data.owner.person.ssn);
                    }

                }
                setManagementFeePercent(data.managementFeePercent + "" || "")
                setReserveAmount(data.reserveAmount + "" || "")
                setEmailReplyTo(data.emailReplyTo || "");
                setBankStatementName(data.bankStatementName || "")
                setAddressLine1(data.address.addressLine1 || "");
                setAddressLine2(data.address.addressLine2 || "");
                setCity(data.address.city || "");
                setState(data.address.stateCode || "");
                setZip(data.address.zip + "" || "");
                setDisabled(data.disabled || false);
                if (data.quoteTheme) {

                    setNavBarColor(data.quoteTheme.navBarColor);
                    setPropertyLogoImageId(data.quoteTheme.propertyLogoImageId);
                    setBackgroundImageId(data.quoteTheme.backgroundImageId);
                }
                setSelectedOwner(data?.owner?.uuid || "");

                if(data?.rentTrustAccount) {
                    setSelectedRentTrustAccount(data?.rentTrustAccount.uuid)
                }
                if(data?.securityDepositTrustAccount) {
                    setSelectedSecurityDepositTrustAccount(data?.securityDepositTrustAccount.uuid)
                }
                loadMenuItems();
            })
            .catch(e => {
                doShowAlert("Failed to load property: " + e.message, AlertType.DANGER);
                console.error(e.message)
            }).finally(() => setLoading(false))
    }, [propertyUuid])


    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        doHideAlert();

        setLoading(true);
        editProperty(propertyUuid, {
            name: name,
            managementFeePercent: parseFloat(managementFeePercent),
            emailReplyTo: emailReplyTo,
            bankStatementName: bankStatementName,
            reserveAmount: parseFloat(reserveAmount),
            ownerId: selectedOwner,
            address: {
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                stateCode: state,
                zip: zip
            },
            quoteTheme: {
                navBarColor: navBarColor,
                propertyLogoImageId: propertyLogoImageId,
                backgroundImageId: backgroundImageId
            }
        })
            .then((data) => {
                doShowAlert("Successfully edited property: " + name, AlertType.SUCCESS);
            })
            .catch(e => {
                doShowAlert("Failed editing property: " + e.message, AlertType.DANGER);
            }).finally(() => setLoading(false))
    }

    const handleDisable = () => {
        doHideAlert();
        setLoading(true);
        disableProperty(propertyUuid)
            .then((ok) => {
                    doShowAlert("Successfully disabled property: " + name, AlertType.SUCCESS);
                    setDisabled(true);
                }
            ).catch(e => {
            doShowAlert("Failed to disable property: " + e.message, AlertType.DANGER);
        }).finally(() => {
            setLoading(false)
        })
    }

    const doShowAlert = (message: string, type: AlertType) => {
        setShowAlert(true);
        setAlertMessage(message);
        setAlertType(type);
    }

    const doHideAlert = () => {
        setShowAlert(false);
    }

    const doDisableModalCancel = () => {
        setShowDisableModal(false);
    }

    const doDisableModalOk = () => {
        setShowDisableModal(false);
        handleDisable();
    }

    const doEnableProperty = () => {
        doHideAlert();
        setLoading(true);
        enableProperty(propertyUuid)
            .then(() => {
                doShowAlert("Successfully enabled property: " + name, AlertType.SUCCESS);
                setDisabled(false);
            }).catch(e => {
            doShowAlert("Failed to enable property: " + e.message, AlertType.DANGER);
        })
            .finally(() => setLoading(false));
    }

    const bytesPrettyPrint = (sizeInBytes: number) => {
        let inMb = (sizeInBytes / 1048576);
        if (inMb > .01) {
            return inMb.toFixed(2) + " MB"
        } else {
            return "<0.01 MB"
        }
    }

    function getName() {
        if(businessStructure && businessStructure !== "individual" && businessStructure !== "single_member_llc") {
            return <><div className={"col-md-6"}>
                <Input id={"businessName"} validation={PropertyNameRegex.expression}
                       doValidate={formInputValidate}
                       value={businessName}
                       setValue={setBusinessName}
                       required={true}
                       invalidFeedbackText={PropertyNameRegex.helpText}
                       type={"text"} label={"Legal Name"}
                />
                <p>Entities legal name e.g. The View LLC.</p>
            </div>
                <div className={"col-md-6"}>
                    <Input id={"firstName"} validation={FirstNameRegex.expression}
                           doValidate={formInputValidate}
                           value={firstName}
                           setValue={setFirstName}
                           required={true}
                           invalidFeedbackText={FirstNameRegex.helpText}
                           type={"text"} label={"Manager First Name"}
                    />
                </div>
                <div className={"col-md-6"}>
                    <Input id={"lastName"} validation={LastNameRegex.expression}
                           doValidate={formInputValidate}
                           value={lastName}
                           setValue={setLastName}
                           required={true}
                           invalidFeedbackText={LastNameRegex.helpText}
                           type={"text"} label={"Manager Last Name"}
                    />
                </div>
            </>
        } else if(businessStructure === "single_member_llc") {
            return <>
                <div className={"col-md-6"}>
                    <Input id={"businessName"} validation={PropertyNameRegex.expression}
                           doValidate={formInputValidate}
                           value={businessName}
                           setValue={setBusinessName}
                           required={true}
                           invalidFeedbackText={PropertyNameRegex.helpText}
                           type={"text"} label={"Legal Name"}
                    />
                    <p>Entities legal name e.g. The View LLC.</p>
                </div>
                <div className={"col-md-6"}>
                    <Input id={"firstName"} validation={FirstNameRegex.expression}
                           doValidate={formInputValidate}
                           value={firstName}
                           setValue={setFirstName}
                           required={true}
                           invalidFeedbackText={FirstNameRegex.helpText}
                           type={"text"} label={"First Name"}
                    />
                </div>
                <div className={"col-md-6"}>
                    <Input id={"lastName"} validation={LastNameRegex.expression}
                           doValidate={formInputValidate}
                           value={lastName}
                           setValue={setLastName}
                           required={true}
                           invalidFeedbackText={LastNameRegex.helpText}
                           type={"text"} label={"Last Name"}
                    />
                </div>
            </>
        } else if(businessStructure === "individual") {
            return <>
                <div className={"col-md-6"}>
                    <Input id={"firstName"} validation={FirstNameRegex.expression}
                           doValidate={formInputValidate}
                           value={firstName}
                           setValue={setFirstName}
                           required={true}
                           invalidFeedbackText={FirstNameRegex.helpText}
                           type={"text"} label={"First Name"}
                    />
                </div>
                <div className={"col-md-6"}>
                    <Input id={"lastName"} validation={LastNameRegex.expression}
                           doValidate={formInputValidate}
                           value={lastName}
                           setValue={setLastName}
                           required={true}
                           invalidFeedbackText={LastNameRegex.helpText}
                           type={"text"} label={"Last Name"}
                    />
                </div>
            </>
        }
    }

    function getSSNorEIN() {
        if(businessStructure && businessStructure !== "individual" && businessStructure !== "single_member_llc") {
            return <div className={"col-md-6 mb-2"}>
                <Input id={"ein"} validation={EINRegex.expression}
                       doValidate={formInputValidate}
                       value={ein}
                       setValue={setEin}
                       required={false}
                       invalidFeedbackText={EINRegex.helpText}
                       type={"text"} label={"EIN"}
                />
            </div>
        } else if(businessStructure === "individual" || businessStructure === "single_member_llc") {
            return <div className={"col-md-6 mb-2"}>
                <Input id={"ssn"} validation={SsnRegex.expression}
                       doValidate={formInputValidate}
                       value={ssn}
                       setValue={setSsn}
                       required={false}
                       invalidFeedbackText={SsnRegex.helpText}
                       type={"text"} label={"SSN"}
                />
            </div>
        }
    }

    function handleOwner() {
        setLoadingAddOwner(true);
        addOwner(getPortfolioId(), {
            businessStructure: businessStructure,
            ein: ein,
            person: {
                firstName: firstName,
                lastName: lastName,
                ssn: ssn
            },
            name: businessName,
            phone: phone,
            typeOfBusiness: (businessStructure === "individual") ? "individual" : "company",
            address: {
                addressLine1: businessAddressLine1,
                addressLine2: businessAddressLine2,
                city: businessCity,
                stateCode: businessState,
                zip: businessZip
            }
        })
            .then((b) => {
                load();
                setSelectedOwner(b.uuid || "");
                setDoAddOwner(false);
            })
            .catch((e) => setAddOwnerError(e.message))
            .finally(() => setLoadingAddOwner(false));
    }

    function getOwnerInfo() {
        if (doAddOwner) {
            return <div className="row">
                <div className={"col-md-6 mb-2"}>
                    <Select id={"businessStructure"}
                            validation={GenericSelectRegex.expression}
                            disabled={false}
                            doValidate={formSelectValidate}
                            value={businessStructure} setValue={setBusinessStructure}
                            required={true}
                            invalidFeedbackText={GenericSelectRegex.helpText}
                            label={"Business Structure"}>
                        <option value="" disabled={true}>Select an option...</option>
                        <option value="sole_proprietorship">Sole Proprietorship</option>
                        <option value="single_member_llc">Single-member LLC</option>
                        <option value="multi_member_llc">Multi-member LLC</option>
                        <option value="private_partnership">Private Partnership</option>
                        <option value="public_partnership">Public Partnership</option>
                        <option value="private_corporation">Private Corporation</option>
                        <option value="public_corporation">Public Corporation</option>
                        <option value="individual">Individual</option>
                    </Select>
                </div>
                {getName()}
                {getSSNorEIN()}
                <div className={"col-md-6 mb-2"}>
                    <Input id={"phone"} validation={PhoneNumberRegex.expression}
                           doValidate={formInputValidate}
                           value={phone}
                           setValue={setPhone}
                           required={true}
                           invalidFeedbackText={PhoneNumberRegex.helpText}
                           type={"text"} label={"Phone"}
                    />
                </div>

                <div className={"col-md-6 mb-2"}>
                    <Input id={"businessaddressLine1"} validation={AddressLine1Regex.expression}
                           doValidate={formInputValidate}
                           value={businessAddressLine1}
                           setValue={setBusinessAddressLine1}
                           required={true}
                           invalidFeedbackText={AddressLine1Regex.helpText}
                           type={"text"} label={"Address"}
                    />
                </div>
                <div className={"col-md-6 mb-2"}>
                    <Input id={"businessaddressLine2"} validation={AddressLine2Regex.expression}
                           doValidate={formInputValidate}
                           value={businessAddressLine2}
                           setValue={setBusinessAddressLine2}
                           required={false}
                           invalidFeedbackText={AddressLine2Regex.helpText}
                           type={"text"} label={"Address 2"}
                    />
                </div>
                <div className={"col-md-4 mb-2"}>
                    <Input id={"businesscity"} validation={CityRegex.expression}
                           doValidate={formInputValidate}
                           value={businessCity}
                           setValue={setBusinessCity}
                           required={true}
                           invalidFeedbackText={CityRegex.helpText}
                           type={"text"} label={"City"}
                    />
                </div>
                <div className="col-md-4 mb-2">
                    <StateSelect id={"businessstate"} validation={GenericSelectRegex.expression}
                                 noSelectionText={"Select a state..."}
                                 doValidate={formSelectValidate}
                                 value={businessState} setValue={setBusinessState}
                                 required={true}
                                 invalidFeedbackText={GenericSelectRegex.helpText}
                                 label={"State"}/>
                </div>
                <div className={"col-md-4 mb-2"}>
                    <Input id={"businesszip"} validation={ZipRegex.expression}
                           doValidate={formInputValidate}
                           value={businessZip}
                           setValue={setBusinessZip}
                           required={true}
                           invalidFeedbackText={ZipRegex.helpText}
                           type={"number"} label={"Zip"}
                    />
                </div>
                <div className={"col-md-12"}>
                    <button disabled={loadingAddRentTrustAccount} type={"button"} className={"btn btn-primary"}
                            onClick={(e) => {
                                e.preventDefault();
                                handleOwner();

                            }}>
                        Save
                        <span hidden={!loadingAddRentTrustAccount}>
                           <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                           <span className="visually-hidden" role="status">Saving...</span>
                       </span>
                    </button>
                    <button type={"button"} className={"btn btn-danger"} onClick={(e) => {
                        e.preventDefault();
                        setDoAddOwner(false);
                    }}>Cancel
                    </button>
                </div>
                <div className={"col-md-12 mt-3"}>
                    {addOwnerError && <Alert type={AlertType.DANGER} message={addOwnerError} />}
                </div>
            </div>
        } else {
            return <div className={"row"}>
                <div className={"col-md-5 mb-3"}>
                    <Select id={"owner"} validation={GenericSelectRegex.expression}
                            doValidate={formSelectValidate} value={selectedOwner}
                            setValue={setSelectedOwner} required={false}
                            invalidFeedbackText={GenericSelectRegex.helpText} label={"Choose an Owner"}>
                        <option disabled={true} value={""}>Select one...</option>
                        {
                            owners.map((t, index) => (
                                <option key={t.uuid} value={t.uuid}>{t.name}</option>
                            ))
                        }
                    </Select>
                </div>
                <div className={"col-md-1 mb-3"} style={{textAlign: 'center', paddingTop: 28}}>
                    -or-
                </div>
                <div className={"col-md-6 mb-3"} style={{paddingTop: 20}}>
                    <button type={"button"} className={"btn btn-primary"} onClick={(e) => {
                        e.preventDefault();
                        setDoAddOwner(true);
                    }}>Create Owner
                    </button>
                </div>
            </div>
        }
    }

    return (<>
        <PageLayout title="Edit Property" propertyId={propertyUuid} hidePropertyInfo={true}>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-4">
                        <Card title="Property Details">
                            <div className={"row"}>
                                <div className={"col-12"}>
                                    <Input id={"name"} validation={PropertyNameRegex.expression}
                                           doValidate={formInputValidate}
                                           value={name}
                                           setValue={setName}
                                           required={true}
                                           invalidFeedbackText={PropertyNameRegex.helpText}
                                           type={"text"} label={"Name"}
                                    />
                                    <p className="small mb-2">The publicly displayed name of the property</p>
                                </div>
                                <div className={"col-12"}>
                                    <Input id={"bankStatement"} validation={BankStatementNameRegex.expression}
                                           doValidate={formInputValidate}
                                           value={bankStatementName}
                                           setValue={setBankStatementName}
                                           required={true}
                                           invalidFeedbackText={BankStatementNameRegex.helpText}
                                           type={"text"} label={"Bank Statement Name"}
                                    />
                                    <p className="small mb-2">Name that will appear on a tenant's bank statement</p>
                                </div>
                                <div className={"col-12"}>
                                    <Input id={"emailReplyTo"} validation={EmailRegex.expression}
                                           doValidate={formInputValidate}
                                           value={emailReplyTo}
                                           setValue={setEmailReplyTo}
                                           required={true}
                                           invalidFeedbackText={EmailRegex.helpText}
                                           type={"text"} label={"Email Reply-to"}
                                    />
                                    <p className="small mb-0">Automated emails sent to tenants will use this email
                                        reply-to.</p>
                                </div>
                                <div className={"col-md-12"}>
                                    <Input id={"reserveAmount"} validation={DollarAmountRegex.expression}
                                           doValidate={formInputValidate}
                                           value={reserveAmount}
                                           setValue={setReserveAmount}
                                           required={true}
                                           invalidFeedbackText={DollarAmountRegex.helpText}
                                           type={"number"} label={"Reserve Amount"}
                                    />
                                    <p>Cash reserve amount for the property.</p>
                                </div>
                                <div className={"col-md-12"}>
                                    <Input id={"mgmtFee"} validation={PercentRegex.expression}
                                           doValidate={formInputValidate}
                                           value={managementFeePercent}
                                           setValue={setManagementFeePercent}
                                           required={true}
                                           invalidFeedbackText={PercentRegex.helpText}
                                           type={"number"} label={"Management Fee Percent"}
                                           step={.01} min={0} max={100}
                                    />
                                    <p>Management fee percent charged to owners.</p>
                                </div>
                            </div>
                        </Card>
                        <div className="mt-4">
                            <Card title="Property Address">
                                <p>Physical location of the property advertised publicly.</p>
                                <div className="row">
                                    <div className={"col-md-6 mb-1"}>
                                        <Input id={"addressLine1"} validation={AddressLine1Regex.expression}
                                               doValidate={formInputValidate}
                                               value={addressLine1}
                                               setValue={setAddressLine1}
                                               required={true}
                                               invalidFeedbackText={AddressLine1Regex.helpText}
                                               type={"text"} label={"Address"}
                                        />
                                    </div>
                                    <div className={"col-md-6 mb-1"}>
                                        <Input id={"addressLine2"} validation={AddressLine2Regex.expression}
                                               doValidate={formInputValidate}
                                               value={addressLine2}
                                               setValue={setAddressLine2}
                                               required={false}
                                               invalidFeedbackText={AddressLine2Regex.helpText}
                                               type={"text"} label={"Address 2"}
                                        />
                                    </div>
                                    <div className={"col-md-4 mb-1"}>
                                        <Input id={"city"} validation={CityRegex.expression}
                                               doValidate={formInputValidate}
                                               value={city}
                                               setValue={setCity}
                                               required={true}
                                               invalidFeedbackText={CityRegex.helpText}
                                               type={"text"} label={"City"}
                                        />
                                    </div>
                                    <div className="col-md-4 mb-1">
                                        <StateSelect id={"state"} validation={GenericSelectRegex.expression}
                                                     noSelectionText={"Select a state..."}
                                                     doValidate={formSelectValidate}
                                                     value={state} setValue={setState}
                                                     required={true}
                                                     invalidFeedbackText={GenericSelectRegex.helpText}
                                                     label={"State"}/>
                                    </div>
                                    <div className={"col-md-4 mb-1"}>
                                        <Input id={"zip"} validation={ZipRegex.expression}
                                               doValidate={formInputValidate}
                                               value={zip}
                                               setValue={setZip}
                                               required={true}
                                               invalidFeedbackText={ZipRegex.helpText}
                                               type={"number"} label={"Zip"}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="mt-4">
                            <Card title="Property Phone Numbers">
                                <p>Edit the Phone Numbers associated with the property.</p>
                                <Link to={"/p/m/realestate/properties/edit/" + propertyUuid + "/phone-numbers"}>
                                    <button type={"button"} className={"btn btn-primary"}>Edit Phone Numbers</button>
                                </Link>
                            </Card>
                        </div>
                        <div className="mt-4">
                            {/*<Card title="Tenant Menu Items">*/}
                            {/*    <p>Configure the Menu Options avaliable to your tenants</p>*/}
                            {/*    <table className='table table-striped'>*/}
                            {/*        <thead>*/}
                            {/*        <tr>*/}
                            {/*            <th>Menu Item</th>*/}
                            {/*            <th>Enabled</th>*/}
                            {/*        </tr>*/}
                            {/*        </thead>*/}
                            {/*        <tbody>*/}
                            {/*        <tr>*/}
                            {/*            <td>*/}
                            {/*                Make a Payment*/}
                            {/*            </td>*/}
                            {/*            <td>*/}
                            {/*                <div className="form-check form-switch">*/}
                            {/*                    <input className="form-check-input" type="checkbox" role="switch"*/}
                            {/*                           id="flexSwitchCheckDefault"*/}
                            {/*                           checked={propertyMenuItems?.makeAPayment} onChange={(e) => {*/}
                            {/*                        let value = e.target.checked;*/}
                            {/*                        console.log(value);*/}
                            {/*                        if (propertyMenuItems) {*/}
                            {/*                            propertyMenuItems.makeAPayment = value;*/}
                            {/*                            setPropertyMenuItems({...propertyMenuItems});*/}
                            {/*                            editMenuItems();*/}
                            {/*                        }*/}
                            {/*                    }}/>*/}
                            {/*                </div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td>*/}
                            {/*                Automatic Payments*/}
                            {/*            </td>*/}
                            {/*            <td>*/}
                            {/*                <div className="form-check form-switch">*/}
                            {/*                    <input className="form-check-input" type="checkbox" role="switch"*/}
                            {/*                           id="flexSwitchCheckDefault"*/}
                            {/*                           checked={propertyMenuItems?.automaticPayments} onChange={(e) => {*/}
                            {/*                        let value = e.target.checked;*/}
                            {/*                        console.log(value);*/}
                            {/*                        if (propertyMenuItems) {*/}
                            {/*                            propertyMenuItems.automaticPayments = value;*/}
                            {/*                            setPropertyMenuItems({...propertyMenuItems});*/}
                            {/*                            editMenuItems();*/}
                            {/*                        }*/}
                            {/*                    }}/>*/}
                            {/*                </div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td>*/}
                            {/*                Payment Methods*/}
                            {/*            </td>*/}
                            {/*            <td>*/}
                            {/*                <div className="form-check form-switch">*/}
                            {/*                    <input className="form-check-input" type="checkbox" role="switch"*/}
                            {/*                           id="flexSwitchCheckDefault"*/}
                            {/*                           checked={propertyMenuItems?.paymentMethods} onChange={(e) => {*/}
                            {/*                        let value = e.target.checked;*/}
                            {/*                        console.log(value);*/}
                            {/*                        if (propertyMenuItems) {*/}
                            {/*                            propertyMenuItems.paymentMethods = value;*/}
                            {/*                            setPropertyMenuItems({...propertyMenuItems});*/}
                            {/*                            editMenuItems();*/}
                            {/*                        }*/}
                            {/*                    }}/>*/}
                            {/*                </div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td>*/}
                            {/*                Payment History*/}
                            {/*            </td>*/}
                            {/*            <td>*/}
                            {/*                <div className="form-check form-switch">*/}
                            {/*                    <input className="form-check-input" type="checkbox" role="switch"*/}
                            {/*                           id="flexSwitchCheckDefault"*/}
                            {/*                           checked={propertyMenuItems?.paymentHistory} onChange={(e) => {*/}
                            {/*                        let value = e.target.checked;*/}
                            {/*                        console.log(value);*/}
                            {/*                        if (propertyMenuItems) {*/}
                            {/*                            propertyMenuItems.paymentHistory = value;*/}
                            {/*                            setPropertyMenuItems({...propertyMenuItems});*/}
                            {/*                            editMenuItems();*/}
                            {/*                        }*/}
                            {/*                    }}/>*/}
                            {/*                </div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td>*/}
                            {/*                Instructions*/}
                            {/*            </td>*/}
                            {/*            <td>*/}
                            {/*                <div className="form-check form-switch">*/}
                            {/*                    <input className="form-check-input" type="checkbox" role="switch"*/}
                            {/*                           id="flexSwitchCheckDefault"*/}
                            {/*                           checked={propertyMenuItems?.instructions} onChange={(e) => {*/}
                            {/*                        let value = e.target.checked;*/}
                            {/*                        console.log(value);*/}
                            {/*                        if (propertyMenuItems) {*/}
                            {/*                            propertyMenuItems.instructions = value;*/}
                            {/*                            setPropertyMenuItems({...propertyMenuItems});*/}
                            {/*                            editMenuItems();*/}
                            {/*                        }*/}
                            {/*                    }}/>*/}
                            {/*                </div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td>*/}
                            {/*                Guests*/}
                            {/*            </td>*/}
                            {/*            <td>*/}
                            {/*                <div className="form-check form-switch">*/}
                            {/*                    <input className="form-check-input" type="checkbox" role="switch"*/}
                            {/*                           id="flexSwitchCheckDefault"*/}
                            {/*                           checked={propertyMenuItems?.guests} onChange={(e) => {*/}
                            {/*                        let value = e.target.checked;*/}
                            {/*                        console.log(value);*/}
                            {/*                        if (propertyMenuItems) {*/}
                            {/*                            propertyMenuItems.guests = value;*/}
                            {/*                            setPropertyMenuItems({...propertyMenuItems});*/}
                            {/*                            editMenuItems();*/}
                            {/*                        }*/}
                            {/*                    }}/>*/}
                            {/*                </div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td>*/}
                            {/*                Packages*/}
                            {/*            </td>*/}
                            {/*            <td>*/}
                            {/*                <div className="form-check form-switch">*/}
                            {/*                    <input className="form-check-input" type="checkbox" role="switch"*/}
                            {/*                           id="flexSwitchCheckDefault"*/}
                            {/*                           checked={propertyMenuItems?.packages} onChange={(e) => {*/}
                            {/*                        let value = e.target.checked;*/}
                            {/*                        console.log(value);*/}
                            {/*                        if (propertyMenuItems) {*/}
                            {/*                            propertyMenuItems.packages = value;*/}
                            {/*                            setPropertyMenuItems({...propertyMenuItems});*/}
                            {/*                            editMenuItems();*/}
                            {/*                        }*/}
                            {/*                    }}/>*/}
                            {/*                </div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td>*/}
                            {/*                Maintenance Requests*/}
                            {/*            </td>*/}
                            {/*            <td>*/}
                            {/*                <div className="form-check form-switch">*/}
                            {/*                    <input className="form-check-input" type="checkbox" role="switch"*/}
                            {/*                           id="flexSwitchCheckDefault"*/}
                            {/*                           checked={propertyMenuItems?.maintenanceRequests}*/}
                            {/*                           onChange={(e) => {*/}
                            {/*                               let value = e.target.checked;*/}
                            {/*                               console.log(value);*/}
                            {/*                               if (propertyMenuItems) {*/}
                            {/*                                   propertyMenuItems.maintenanceRequests = value;*/}
                            {/*                                   setPropertyMenuItems({...propertyMenuItems});*/}
                            {/*                                   editMenuItems();*/}
                            {/*                               }*/}
                            {/*                           }}/>*/}
                            {/*                </div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td>*/}
                            {/*                Amenities*/}
                            {/*            </td>*/}
                            {/*            <td>*/}
                            {/*                <div className="form-check form-switch">*/}
                            {/*                    <input className="form-check-input" type="checkbox" role="switch"*/}
                            {/*                           id="flexSwitchCheckDefault"*/}
                            {/*                           checked={propertyMenuItems?.amenities} onChange={(e) => {*/}
                            {/*                        let value = e.target.checked;*/}
                            {/*                        console.log(value);*/}
                            {/*                        if (propertyMenuItems) {*/}
                            {/*                            propertyMenuItems.amenities = value;*/}
                            {/*                            setPropertyMenuItems({...propertyMenuItems});*/}
                            {/*                            editMenuItems();*/}
                            {/*                        }*/}
                            {/*                    }}/>*/}
                            {/*                </div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td>*/}
                            {/*                Community Events*/}
                            {/*            </td>*/}
                            {/*            <td>*/}
                            {/*                <div className="form-check form-switch">*/}
                            {/*                    <input className="form-check-input" type="checkbox" role="switch"*/}
                            {/*                           id="flexSwitchCheckDefault"*/}
                            {/*                           checked={propertyMenuItems?.communityEvents} onChange={(e) => {*/}
                            {/*                        let value = e.target.checked;*/}
                            {/*                        console.log(value);*/}
                            {/*                        if (propertyMenuItems) {*/}
                            {/*                            propertyMenuItems.communityEvents = value;*/}
                            {/*                            setPropertyMenuItems({...propertyMenuItems});*/}
                            {/*                            editMenuItems();*/}
                            {/*                        }*/}
                            {/*                    }}/>*/}
                            {/*                </div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td>*/}
                            {/*                Community Links*/}
                            {/*            </td>*/}
                            {/*            <td>*/}
                            {/*                <div className="form-check form-switch">*/}
                            {/*                    <input className="form-check-input" type="checkbox" role="switch"*/}
                            {/*                           id="flexSwitchCheckDefault"*/}
                            {/*                           checked={propertyMenuItems?.communityLinks} onChange={(e) => {*/}
                            {/*                        let value = e.target.checked;*/}
                            {/*                        console.log(value);*/}
                            {/*                        if (propertyMenuItems) {*/}
                            {/*                            propertyMenuItems.communityLinks = value;*/}
                            {/*                            setPropertyMenuItems({...propertyMenuItems});*/}
                            {/*                            editMenuItems();*/}
                            {/*                        }*/}
                            {/*                    }}/>*/}
                            {/*                </div>*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        </tbody>*/}
                            {/*    </table>*/}
                            {/*</Card>*/}
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <Card title="Quote Theme">
                            <div className="d-flex justify-content-between gap-2">
                                <p>Theming of the quoting tool for this property.</p>
                                <a href={TENANT_UI_ENDPOINT + "/p/u/quotes/" + propertyUuid} target={"_blank"}>
                                    <button type={"button"} className={"btn btn-primary"}>Open Tenant Quote App</button>
                                </a>
                            </div>
                            <FormFieldBuilder field={EditPropertyForm.navBarColor}/>
                            <div hidden={propertyLogoImageId} className="mt-2">
                                <FormFieldBuilder field={EditPropertyForm.propertyLogoImageUrl}/>
                            </div>
                            <div hidden={!propertyLogoImageId} className="mt-2">
                                <div hidden={!propertyLogoImageMetadata}>
                                    <label>{EditPropertyForm.propertyLogoImageUrl.label}</label>
                                    <p>
                                        {propertyLogoImageMetadata?.fileName}, {bytesPrettyPrint(parseInt(propertyLogoImageMetadata?.size || "0"))}
                                        <button type="button" className={"btn btn-danger ms-3"} onClick={() => {
                                            setPropertyLogoImageId("");
                                            setPropertyLogoImage(undefined);
                                            setPropertyLogoImageMetadata(undefined);
                                        }}>Remove
                                        </button>
                                    </p>

                                </div>
                                <div hidden={!propertyLogoImageLoading} className="spinner-border text-primary"
                                     role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                Use a 100px x 100px image for best experience.
                            </div>
                            <div hidden={backgroundImageId} className="mt-2">
                                <FormFieldBuilder field={EditPropertyForm.backgroundImageUrl}/>
                            </div>
                            <div hidden={!backgroundImageId} className="mt-2">
                                <div hidden={!backgroundImageMetadata}>
                                    <label>{EditPropertyForm.backgroundImageUrl.label}</label>
                                    <p>
                                        {backgroundImageMetadata?.fileName}, {bytesPrettyPrint(parseInt(backgroundImageMetadata?.size || "0"))}
                                        <button type="button" className={"btn btn-danger ms-3"} onClick={() => {
                                            setBackgroundImageId("");
                                            setBackgroundImage(undefined);
                                            setBackgroundImageMetadata(undefined);
                                        }}>Remove
                                        </button>
                                    </p>
                                </div>
                                <div hidden={!backgroundImageLoading} className="spinner-border text-primary"
                                     role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </Card>
                        <div className="mt-4">
                            <Card title="Property Owner/Manager Business Information">
                                <p>The US entity receiving collected funds from rent, fees, and services.</p>
                                <div className="row">
                                    <div className={"col-md-6 mb-3"}>
                                        <Select id={"rentTrustAccount"} validation={GenericSelectRegex.expression}
                                                doValidate={formSelectValidate} value={selectedRentTrustAccount}
                                                setValue={setSelectedRentTrustAccount} required={false}
                                                invalidFeedbackText={GenericSelectRegex.helpText}
                                                label={"Rent Trust Account"} disabled={true}>
                                            <option disabled={true} value={""}>Not set</option>
                                            {
                                                trustAccounts.map((t, index) => (
                                                    <option key={t.uuid} value={t.uuid}>{t.name}</option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                    <div className={"col-md-6 mb-3"}>
                                        <Select id={"secTrustAccount"} validation={GenericSelectRegex.expression}
                                                doValidate={formSelectValidate}
                                                value={selectedSecurityDepositTrustAccount}
                                                setValue={setSelectedSecurityDepositTrustAccount} required={false}
                                                invalidFeedbackText={GenericSelectRegex.helpText}
                                                label={"Security Deposit Trust Account"} disabled={true}>
                                            <option disabled={true} value={""}>Not set</option>
                                            {
                                                trustAccounts.map((t, index) => (
                                                    <option key={t.uuid} value={t.uuid}>{t.name}</option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                    <div className={"col-md-12 mb-3"}>
                                        {getOwnerInfo()}
                                    </div>
                                </div>
                                <div className="d-flex justify-content-between gap-2 mt-3">
                                    <div className="d-flex justify-content-start">
                                        <button hidden={disabled} type="button" className="btn btn-danger"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowDisableModal(true);
                                                }}
                                        >Disable
                                        </button>
                                    </div>
                                    <div className="d-flex justify-content-end gap-2">
                                        <button hidden={!disabled} type="button" className="btn btn-danger"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    doEnableProperty();

                                                }}
                                        >Enable
                                        </button>
                                        <Link to={"/p/m/realestate/properties"}>
                                            <button type="button" className="btn btn-secondary">Cancel</button>
                                        </Link>
                                        <button disabled={loading || disabled} type="submit"
                                                className="btn btn-primary">Save
                                        </button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col mb-3"}>
                        <Alert show={showAlert} message={alertMessage} type={alertType}/>
                    </div>
                </div>
            </form>
        </PageLayout>
        <Modal show={showDisableModal} title={"Disable confirmation"}
               cancelButtonText={"Cancel"} okButtonText={"Disable"} doCancel={doDisableModalCancel}
               doOk={doDisableModalOk}>
            <p>Are you sure you want to disable property: {name}?</p>
        </Modal>
    </>);
};

export default EditProperty;
