import * as React from "react";
import {useParams} from "react-router";
import {FormEvent, useState} from "react";
import {createTenantPackage} from "../../../../Api/PackageManangementApi";
import FormFieldBuilder, {SelectOptions} from "../../../Form/FieldBuilder/FormFieldBuilder";
import AddTenantPackageForm from "../../Forms/AddTenantPackageForm";
import moment from "moment/moment";

const AddTenantPackage: React.FunctionComponent<{selectOptions: SelectOptions[], done: Function}> = ({selectOptions, done}) => {

    const {propertyId} = useParams();

    const [tenantAccountId, setTenantAccountId] = useState<string>("");
    const [addressedToName, setAddressedToName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [dateReceived, setDateReceived] = useState<string>(moment().format("YYYY-MM-DD"));
    const [pickupInstructions, setPickupInstructions] = useState<string>("");

    AddTenantPackageForm.tenantAccountId.value = tenantAccountId;
    AddTenantPackageForm.tenantAccountId.setValue = setTenantAccountId;
    AddTenantPackageForm.addressedToName.value = addressedToName;
    AddTenantPackageForm.addressedToName.setValue = setAddressedToName;
    AddTenantPackageForm.description.value = description;
    AddTenantPackageForm.description.setValue = setDescription;
    AddTenantPackageForm.dateReceived.value = dateReceived;
    AddTenantPackageForm.dateReceived.setValue = setDateReceived;
    AddTenantPackageForm.pickupInstructions.value = pickupInstructions;
    AddTenantPackageForm.pickupInstructions.setValue = setPickupInstructions;

    const clearFields = () => {
        setTenantAccountId("");
        setAddressedToName("");
        setDescription("");
        setDateReceived(moment().format("YYYY-MM-DD"));
        setPickupInstructions("");
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        createTenantPackage(propertyId, {
            tenantAccountId: tenantAccountId,
            addressedToName: addressedToName,
            description: description,
            dateReceived: dateReceived + " 00:00:00",
            pickupInstructions: pickupInstructions
        })
            .then(() => clearFields())
            .then(() => done())
    }

    const onReset = (e: FormEvent) => {
        e.preventDefault();
    }

    return (<>
        <div className="row widget bg-light">
            <div className="col-sm-12 mb-3">
                <div className="row">
                    <h4>Add Tenant Package</h4>
                </div>
                <form onSubmit={onSubmit} onReset={onReset}>
                    <div className="row">
                        <div className="col-xxl-6 mb-3">
                            <FormFieldBuilder field={AddTenantPackageForm.tenantAccountId} selectOptions={selectOptions} />
                            <FormFieldBuilder field={AddTenantPackageForm.addressedToName}/>
                            <FormFieldBuilder field={AddTenantPackageForm.dateReceived}/>
                        </div>
                        <div className="col-xxl-6 mb-3">
                            <FormFieldBuilder field={AddTenantPackageForm.description}/>
                            <FormFieldBuilder field={AddTenantPackageForm.pickupInstructions}/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-12 mb-3"}>
                            <div className="btn-group" role="btn-group">
                                <button type="reset" className="btn btn-outline-primary">Clear</button>
                                <button type="submit" className="btn btn-primary">Add</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>)
}

export default AddTenantPackage;
