import * as React from 'react';
import {useEffect} from "react";
import {useHistory} from "react-router";
import {APP_PORTFOLIO_LANDING} from "../../types";



const NoRouteMatch: React.FunctionComponent = () => {

    let history = useHistory();

    useEffect(() => {
        history.push({
            pathname: APP_PORTFOLIO_LANDING,
            search: window.location.search
        })
    },[history])

    return (<>

    </>);
};

export default NoRouteMatch;