import * as React from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import { Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router";
import Alert, { AlertType } from "../Alert";
import { Application, ApplicationStatus } from "../../types";
import ErrorBoundary from '../ErrorBoundary';
import Modal from "../Modal";
import Stage1 from "./Stage1";
import Stage2 from "./Stage2";
import Stage3 from "./Stage3";
import Stage4 from "./Stage4";
import Stage5 from "./Stage5";
import Stage6 from "./Stage6";
import Stage7 from "./Stage7";
import Stage8 from "./Stage8";
import Stage9 from "./Stage9";
import Stage10 from "./Stage10";
import { getPortfolioId } from "../../helpers";
import { createApplication, getApplication } from "../../Api/ApplicationApi";
import ButtonNew, { ButtonColors, ButtonTypes } from "../ButtonNew";
import ProgressSteps, { ProgressPath } from "../ProgressStepper";
import AddApplicationPathSteps from "../ProgressStepper/ProgressPaths/AddApplicationPathSteps";
import {PropertyIdContext} from "../../Containers/PrivateMainContainer";

const AddApplication: React.FunctionComponent = () => {

    const cancelPath = "/p/m/customers/applications";
    const { path } = useRouteMatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const editApplication = queryParams.get("editApplication")
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(AlertType.SUCCESS);
    const APPLICATION_ID_KEY = "app.application.id";
    const [applicationReady, setApplicationReady] = useState(false);
    const [doRedirectToSearch, setDoRedirectToSearch] = useState(false);
    const history = useHistory();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showContinueModal, setShowContinueModal] = useState(false);
    const [showErrorLoadingModal, setShowErrorLoadingModal] = useState(false);
    const [showInvalidStatusModal, setShowInvalidStatusModal] = useState(false);
    const [startApplicationWhenReady, setStartApplicationWhenReady] = useState(false);
    const [doHandleInvalidApplicationStatus, setDoHandleInvalidApplicationStatus] = useState(false);
    const [step, setStep] = useState<number>(0)

    const [applicationId, setApplicationId] = useState()

    const startApplicationCallback = useCallback(() => {
        console.log("Starting new application");

        createApplication(getPortfolioId())
            .then((a) => {
                localStorage.setItem(APPLICATION_ID_KEY, a.uuid);
                setupApplication(a);
                setApplicationReady(true);
            })
            .catch(e => {
                doShowAlert("Failed to start application: " + e.message, AlertType.DANGER);
            })
    }, []);

    const matchStage2 = useRouteMatch(path + "/2");
    useEffect(() => {
        if (startApplicationWhenReady) {
            setStartApplicationWhenReady(false);
            startApplicationCallback();
        }
    }, [startApplicationWhenReady, startApplicationCallback])

    const setupApplication = (a: Application) => {
        setApplicationId(a.uuid);
    }

    useEffect(() => {
        const applicationId = localStorage.getItem(APPLICATION_ID_KEY);
        if (applicationId !== undefined && applicationId !== null) {

            //TODO: remove, duplicate
            getApplication(getPortfolioId(), applicationId)
                .then((a) => {
                    if (a.status !== ApplicationStatus.approved) {
                        if (!editApplication || editApplication !== "true") {
                            setShowContinueModal(true);
                            setupApplication(a);
                        } else {
                            setupApplication(a);
                            setApplicationReady(true);
                        }
                    } else {
                        startApplicationCallback();
                    }
                })
                .catch(e => {
                    console.log(e);
                    setShowErrorLoadingModal(true);
                    doShowAlert("Failed to start application: " + e.message, AlertType.DANGER);
                })
        } else {
            startApplicationCallback();
        }
    }, [startApplicationCallback])

    const doShowAlert = (message: string, type: AlertType) => {
        setShowAlert(true);
        setAlertMessage(message);
        setAlertType(type);
    }

    const doCancel = () => {
        localStorage.removeItem(APPLICATION_ID_KEY);
        setDoRedirectToSearch(true);
    }

    useEffect(() => {
        if (doRedirectToSearch) {
            history.push({
                pathname: cancelPath
            })
        }
    }, [doRedirectToSearch, history])

    const handleInvalidApplicationStatus = () => {
        setApplicationReady(false);
        setDoHandleInvalidApplicationStatus(true);
    }

    useEffect(() => {
        if (doHandleInvalidApplicationStatus) {
            setDoHandleInvalidApplicationStatus(false);
            setShowInvalidStatusModal(true);
        }
    }, [doHandleInvalidApplicationStatus]);

    const progressPath: ProgressPath = {
        activeStep: step,
        steps: AddApplicationPathSteps
    }

    return ((!applicationReady) ?
        <>
            <p hidden={showAlert}>Loading application...</p>
            <Alert type={alertType} message={alertMessage} show={showAlert} />
            <Modal show={showContinueModal} title={"Continue Application"} cancelButtonText={"Continue"}
                okButtonText={"New Application"}
                doCancel={() => {
                    setShowContinueModal(false);
                    setApplicationReady(true);
                }}
                doOk={() => {
                    setShowContinueModal(false);
                    setApplicationReady(false);
                    setStartApplicationWhenReady(true);
                }}>
                <p>You already have an application in progress. Do you want to continue or start a new
                    application?</p>
            </Modal>
            <Modal show={showErrorLoadingModal} title={"Error Loading Application"} cancelButtonText={"No"}
                okButtonText={"Yes"}
                doCancel={() => {
                    setShowErrorLoadingModal(false);
                    setApplicationReady(false);
                    localStorage.removeItem(APPLICATION_ID_KEY);
                    setDoRedirectToSearch(true);
                }}
                doOk={() => {
                    setShowErrorLoadingModal(false);
                    setApplicationReady(false);
                    setStartApplicationWhenReady(true);
                }}>
                <p>You already have an application in progress but there was an error trying to retrieve the
                    application. Do you want to start a new application?</p>
            </Modal>
            <Modal show={showInvalidStatusModal} title={"Editing a Submitted Application"} cancelButtonText={"No"}
                okButtonText={"Yes"}
                doCancel={() => {
                    setShowInvalidStatusModal(false);
                    setApplicationReady(false);
                    localStorage.removeItem(APPLICATION_ID_KEY);
                    setDoRedirectToSearch(true);
                }}
                doOk={() => {
                    console.log("ok modal")
                    setShowInvalidStatusModal(false);
                    startApplicationCallback();
                    }}>
                <p>The application you are trying to update is already approved and cannot be changed. Would you like to start a new application?</p>
            </Modal>
        </>
        :
        <>
            <div className={"row"}>
                <div className="d-flex justify-content-between align-items-center gap-2 mt-3">
                    <h4>Tenant Application</h4>
                    <ButtonNew
                        type={ButtonTypes.BUTTON}
                        color={ButtonColors.DANGER}
                        label="Cancel"
                        onClick={() => setShowCancelModal(true)}
                    />
                </div>
                <ProgressSteps progressPath={progressPath} />
            </div>
            <hr />
            <ErrorBoundary>
                <Switch>
                    <Route exact path={path + "/1/:journeyLeaseId?"}>
                        <Stage1
                            applicationId={applicationId}
                            handleInvalidApplicationStatus={handleInvalidApplicationStatus}
                            setStage={setStep}
                        />
                    </Route>
                    <Route exact path={path + "/2"}>
                        <Stage2
                            applicationId={applicationId}
                            handleInvalidApplicationStatus={handleInvalidApplicationStatus}
                            setStage={setStep}
                        />
                    </Route>
                    <Route exact path={path + "/3"}>
                        <Stage3
                            applicationId={applicationId}
                            handleInvalidApplicationStatus={handleInvalidApplicationStatus}
                            setStage={setStep}
                        />
                    </Route>
                    <Route exact path={path + "/4"}>
                        <Stage4
                            applicationId={applicationId}
                            handleInvalidApplicationStatus={handleInvalidApplicationStatus}
                            setStage={setStep}
                        />
                    </Route>
                    <Route exact path={path + "/5"}>
                        <Stage5
                            applicationId={applicationId}
                            handleInvalidApplicationStatus={handleInvalidApplicationStatus}
                            setStage={setStep}
                        />
                    </Route>
                    <Route exact path={path + "/6"}>
                        <Stage6
                            applicationId={applicationId}
                            handleInvalidApplicationStatus={handleInvalidApplicationStatus}
                            setStage={setStep}
                        />
                    </Route>
                    <Route exact path={path + "/7"}>
                        <Stage7
                            applicationId={applicationId}
                            handleInvalidApplicationStatus={handleInvalidApplicationStatus}
                            setStage={setStep}
                        />
                    </Route>
                    <Route exact path={path + "/8"}>
                        <Stage8
                            applicationId={applicationId}
                            handleInvalidApplicationStatus={handleInvalidApplicationStatus}
                            setStage={setStep}
                        />
                    </Route>
                    <Route exact path={path + "/9"}>
                        <Stage9
                            applicationId={applicationId}
                            handleInvalidApplicationStatus={handleInvalidApplicationStatus}
                            setStage={setStep}
                        />
                    </Route>
                    <Route exact path={path + "/10"}>
                        <Stage10
                            applicationId={applicationId}
                            handleInvalidApplicationStatus={handleInvalidApplicationStatus}
                            setStage={setStep}
                        />
                    </Route>
                </Switch>
            </ErrorBoundary>
            <Modal show={showCancelModal} title={"Confirm Cancel"} cancelButtonText={"No"} okButtonText={"Yes"}
                doCancel={() => setShowCancelModal(false)} doOk={() => doCancel()}>
                <p>Are you sure you want to leave this application?</p>
            </Modal>
        </>
    );
};

export default AddApplication;
