import * as React from 'react';
import {useEffect, useState} from 'react';
import Table from "../../Table";
import {
    getAllMaintenanceRequests,
    MaintenanceRequest,
    MaintenanceRequestStatus
} from "../../../Api/MaintenanceRequestApi";
import './MaintenanceRequestWidget.css'
import {useHistory} from "react-router";
import moment from "moment/moment";
import Card from "../../Card";
import LoadingOverlay from "../../LoadingOverlay";


const MaintenanceRequestWidget: React.FunctionComponent = () => {

    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [maintenanceRequests, setMaintenanceRequests] = useState<MaintenanceRequest[]>([]);

    useEffect(() => {
        setLoading(true);
        getAllMaintenanceRequests({status: MaintenanceRequestStatus.OPEN})
            .then(setMaintenanceRequests)
            .catch(console.error)
            .finally(() => setLoading(false));
    }, []);

    return (
        <Card title="Maintenance Requests">
            {loading ? <LoadingOverlay /> :
                <Table headers={["Ticket Id", "Property", "Unit", "Category", "Created", "Scheduled", "Status"]} defaultPaginationSize={10}>
                    {maintenanceRequests.map((maintenanceRequest, i) => (
                        <tr className="clickable" key={i + "_mr"} onClick={() => {history.push({ pathname: "/p/m/maintenance/maintenance-requests/" + maintenanceRequest.propertyId + "/manage/" + maintenanceRequest.uuid })}}>
                            <td>{maintenanceRequest.uuid.substring(0, 7)}</td>
                            <td>{maintenanceRequest.propertyName}</td>
                            <td>{maintenanceRequest.unitName}</td>
                            <td>{maintenanceRequest.category}</td>
                            <td>{moment(maintenanceRequest.createdAt, "YYYY-MM-DD").format("MMM Do")}</td>
                            <td>{maintenanceRequest.scheduledDate ? moment(maintenanceRequest.scheduledDate, "YYYY-MM-DD").format("MMM Do") : 'Pending'}</td>
                            <td><span className={maintenanceRequest.status === MaintenanceRequestStatus.OPEN ? "badge rounded-pill bg-info" : "badge rounded-pill bg-success"}>{maintenanceRequest.status}</span></td>
                        </tr>
                    ))}
                </Table>
            }
        </Card>
    )
};

export default MaintenanceRequestWidget;
