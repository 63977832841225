import {
    DateRegex,
    formInputValidate,
    formSelectValidate,
    FullNameRegex,
    GenericInputRegex,
    GenericSelectRegex,
    UuidRegex
} from "../../../types";
import {FormBuilderField, FormFieldType, InputFieldType} from "../../Form/FieldBuilder/FormFieldBuilder";
import {TenantPackageStatus} from "../../../Api/PackageManangementApi";

interface EditTenantPackageForm {
    tenantAccountId: FormBuilderField,
    addressedToName: FormBuilderField,
    description: FormBuilderField,
    datePickedUp: FormBuilderField,
    dateReceived: FormBuilderField,
    pickupPersonName: FormBuilderField,
    pickupInstructions: FormBuilderField,
    status: FormBuilderField
}

const EditTenantPackageForm: EditTenantPackageForm = {
    tenantAccountId: {
        type: FormFieldType.SELECT,
        element: {
            id: 'tenantAccountId',
            required: true,
            validate: formSelectValidate,
            regex: UuidRegex
        },
        label: 'Unit Name',
        defaultSelectOptions: [
            {
                value: "",
                label: "Please select an account...",
                disabled: true
            }
        ],
        value: undefined,
        setValue: () => {}
    },
    addressedToName: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.TEXT,
        element: {
            id: 'addressedToName',
            required: true,
            validate: formInputValidate,
            regex: FullNameRegex
        },
        label: 'Addressed To',
        value: undefined,
        setValue: () => {}
    },
    description: {
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 3,
        element: {
            id: 'description',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Description',
        value: undefined,
        setValue: () => {}
    },
    pickupPersonName: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.TEXT,
        element: {
            id: 'pickupPersonName',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Picked Up By',
        value: undefined,
        setValue: () => {}
    },
    pickupInstructions: {
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 4,
        element: {
            id: 'pickupInstructions',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Pickup Instructions',
        value: undefined,
        setValue: () => {}
    },
    datePickedUp: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.DATE,
        element: {
            id: 'datePickedUp',
            required: true,
            validate: formInputValidate,
            regex: DateRegex
        },
        label: "Date Picked Up",
        value: undefined,
        setValue: () => {}
    },
    dateReceived: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.DATE,
        element: {
            id: 'dateReceived',
            required: false,
            validate: formInputValidate,
            regex: DateRegex
        },
        label: 'Date Received',
        value: undefined,
        setValue: () => {}
    },
    status: {
        type: FormFieldType.SELECT,
        element: {
            id: 'status',
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        label: "Status",
        defaultSelectOptions: [
            {
                value: TenantPackageStatus.RECEIVED,
                label: "Received",
            },
            {
                value: TenantPackageStatus.PICKED_UP,
                label: "Picked Up"
            }
        ],
        value: undefined,
        setValue: () => {}
    }
}

export default EditTenantPackageForm;
