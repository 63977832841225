import {FormBuilderField, FormFieldType} from "../Form/FieldBuilder/FormFieldBuilder";
import {
    AddressLine1Regex,
    AddressLine2Regex,
    CityRegex,
    FirstNameRegex,
    formInputValidate,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex,
    LastNameRegex,
    PhoneNumberRegex, USAccountNameRegex, USBankAccountNumberRegex, USBankRoutingNumberRegex,
    ZipRegex
} from "../../types";

interface CreatePortfolioForm {
    portfolioName: FormBuilderField,
    businessName: FormBuilderField,
    businessType: FormBuilderField,
    businessStructure: FormBuilderField,
    phone: FormBuilderField,
    firstName: FormBuilderField,
    lastName: FormBuilderField,
    addressLine1: FormBuilderField,
    addressLine2: FormBuilderField,
    city: FormBuilderField,
    state: FormBuilderField,
    zip: FormBuilderField,
    // bankAccountType: FormBuilderField,
    // bankAccountHolderName: FormBuilderField,
    // bankRoutingNumber: FormBuilderField,
    // bankAccountNumber: FormBuilderField,
    // paymentSource: FormBuilderField,
    // offerCode: FormBuilderField

}

const CreatePortfolioForm: CreatePortfolioForm = {
    portfolioName: {
        type: FormFieldType.INPUT,
        element: {
            id: 'portfolioName',
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: 'Portfolio Name',
        value: undefined,
        setValue: () => {
        }
    },
    businessName: {
        type: FormFieldType.INPUT,
        element: {
            id: 'businessName',
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: 'Business Name',
        value: undefined,
        setValue: () => {
        }
    },
    businessType: {
        type: FormFieldType.SELECT,
        element: {
            id: 'businessType',
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        label: 'Business Type',
        defaultSelectOptions: [
            {
                value: "",
                label: "Please select...",
                disabled: true
            },
            {
                value: 'company',
                label: "Company",
            },
            {
                value: 'individual',
                label: "Individual",
            },
            {
                value: 'non_profit',
                label: "Non-profit",
            },
            {
                value: 'government_entity',
                label: "Government Entity",
            },
        ],
        value: undefined,
        setValue: () => {
        }
    },
    businessStructure: {
        type: FormFieldType.SELECT,
        element: {
            id: 'businessStructure',
            required: false,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        label: 'Business Structure',
        defaultSelectOptions: [
            {
                value: "",
                label: "Please select...",
                disabled: true
            },
            {
                value: 'multi_member_llc',
                label: "Multi-member LLC",
            },
            {
                value: 'private_corporation',
                label: "Private Corporation",
            },
            {
                value: 'private_partnership',
                label: "Private Partnership",
            },
            {
                value: 'public_corporation',
                label: "Public Corporation",
            },
            {
                value: 'public_partnership',
                label: "Public Partnership",
            },
            {
                value: 'single_member_llc',
                label: "Single-member LLC",
            },
            {
                value: 'sole_proprietorship',
                label: "Sole Proprietorship",
            },
            {
                value: 'individual',
                label: "Individual",
            },
        ],
        value: undefined,
        setValue: () => {
        }
    },
    phone: {
        type: FormFieldType.INPUT,
        element: {
            id: 'phone',
            required: true,
            validate: formInputValidate,
            regex: PhoneNumberRegex,
        },
        label: 'Phone',
        value: undefined,
        setValue: () => {
        }
    },
    addressLine1: {
        type: FormFieldType.INPUT,
        element: {
            id: 'addressLine1',
            required: true,
            validate: formInputValidate,
            regex: AddressLine1Regex,
        },
        label: 'Address Line 1',
        value: undefined,
        setValue: () => {
        }
    },
    addressLine2: {
        type: FormFieldType.INPUT,
        element: {
            id: 'addressLine2',
            required: false,
            validate: formInputValidate,
            regex: AddressLine2Regex,
        },
        label: 'Address Line 2',
        value: undefined,
        setValue: () => {
        }
    },
    city: {
        type: FormFieldType.INPUT,
        element: {
            id: 'city',
            required: true,
            validate: formInputValidate,
            regex: CityRegex,
        },
        label: 'City',
        value: undefined,
        setValue: () => {
        }
    },
    state: {
        type: FormFieldType.STATE_SELECT,
        element: {
            id: 'state',
            required: true,
            validate: formInputValidate,
            regex: GenericSelectRegex,
        },
        label: 'State',
        value: undefined,
        setValue: () => {
        }
    },
    zip: {
        type: FormFieldType.INPUT,
        element: {
            id: 'zip',
            required: true,
            validate: formInputValidate,
            regex: ZipRegex,
        },
        label: 'Zip',
        value: undefined,
        setValue: () => {
        }
    },
    firstName: {
        type: FormFieldType.INPUT,
        element: {
            id: 'firstName',
            required: true,
            validate: formInputValidate,
            regex: FirstNameRegex,
        },
        label: 'First Name',
        value: undefined,
        setValue: () => {
        }
    },
    lastName: {
        type: FormFieldType.INPUT,
        element: {
            id: 'lastName',
            required: true,
            validate: formInputValidate,
            regex: LastNameRegex,
        },
        label: 'Last Name',
        value: undefined,
        setValue: () => {
        }
    },
    // bankAccountType: {
    //     type: FormFieldType.SELECT,
    //     element: {
    //         id: 'bankAccountType',
    //         required: false,
    //         validate: formSelectValidate,
    //         regex: GenericSelectRegex
    //     },
    //     label: 'Account Type',
    //     defaultSelectOptions: [
    //         {
    //             value: "",
    //             label: "Please select...",
    //             disabled: true
    //         },
    //         {
    //             value: 'personal_checking',
    //             label: "Personal Checking",
    //         },
    //         {
    //             value: 'personal_savings',
    //             label: "Personal Savings",
    //         },
    //         {
    //             value: 'business_checking',
    //             label: "Business Checking",
    //         },
    //         {
    //             value: 'business_savings',
    //             label: "Business Savings",
    //         },
    //     ],
    //     value: undefined,
    //     setValue: () => {
    //     }
    // },
    // bankAccountHolderName: {
    //     type: FormFieldType.INPUT,
    //     element: {
    //         id: 'bankAccountHolderName',
    //         required: true,
    //         validate: formInputValidate,
    //         regex: USAccountNameRegex,
    //     },
    //     label: 'Account Holder Name',
    //     value: undefined,
    //     setValue: () => {
    //     }
    // },
    // bankRoutingNumber: {
    //     type: FormFieldType.INPUT,
    //     element: {
    //         id: 'bankRoutingNumber',
    //         required: true,
    //         validate: formInputValidate,
    //         regex: USBankRoutingNumberRegex,
    //     },
    //     label: 'Routing Number',
    //     value: undefined,
    //     setValue: () => {
    //     }
    // },
    // bankAccountNumber: {
    //     type: FormFieldType.INPUT,
    //     element: {
    //         id: 'bankAccountNumber',
    //         required: true,
    //         validate: formInputValidate,
    //         regex: USBankAccountNumberRegex,
    //     },
    //     label: 'Account Number',
    //     value: undefined,
    //     setValue: () => {
    //     }
    // },
    // paymentSource: {
    //     type: FormFieldType.SELECT,
    //     element: {
    //         id: 'paymentSource',
    //         required: false,
    //         validate: formSelectValidate,
    //         regex: GenericSelectRegex
    //     },
    //     label: 'Payment Source',
    //     defaultSelectOptions: [
    //         {
    //             value: "",
    //             label: "Please select...",
    //             disabled: true
    //         },
    //         {
    //             value: 'bank_account',
    //             label: "US Bank Account",
    //         },
    //         {
    //             value: 'offer_code',
    //             label: "Offer Code",
    //         },
    //
    //     ],
    //     value: undefined,
    //     setValue: () => {
    //     }
    // },
    // offerCode: {
    //     type: FormFieldType.INPUT,
    //     element: {
    //         id: 'offerCode',
    //         required: true,
    //         validate: formInputValidate,
    //         regex: GenericInputRegex,
    //     },
    //     label: 'Offer Code',
    //     value: undefined,
    //     setValue: () => {
    //     }
    // },
}

export default CreatePortfolioForm;