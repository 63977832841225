import {AppUser} from "../types";
import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod} from "../api";

export interface CreateTenantGuest {
    firstName: string,
    lastName: string,
    details: string,
    vehicleDescription: string,
    vehicleTagNumber: string,
    allowedKey: boolean,
    secretPhrase: string,
    permanent: boolean,
    startDate: string,
    endDate: string
}

export interface UpdateTenantGuest {
    uuid: string
    accountId: string,
    firstName: string,
    lastName: string,
    details: string,
    vehicleDescription: string,
    vehicleTagNumber: string,
    allowedKey: boolean,
    secretPhrase: string,
    permanent: boolean,
    startDate: string,
    endDate: string
}

export interface TenantGuest {
    uuid: string
    accountId: string,
    firstName: string,
    lastName: string,
    details: string,
    vehicleDescription: string,
    vehicleTagNumber: string,
    allowedKey: boolean,
    secretPhrase: string,
    permanent: boolean,
    startDate: string,
    endDate: string
    verified: boolean,
    createdByManagement: boolean,
    updatedBy: AppUser,
    checkInDates: TenantGuestCheckIn[]
}

export interface TenantGuestCheckIn {
    date: string,
    checkedInBy: AppUser
}

type GetTenantGuests = (propertyId: string, accountId: string) => Promise<TenantGuest[]>;
type GetTenantGuest = (propertyId: string, accountId: string, guestId: string) => Promise<TenantGuest>;
type PostTenantGuest = (propertyId: string, accountId: string, guest: CreateTenantGuest) => Promise<TenantGuest>;
type PutTenantGuest = (propertyId: string, guest: UpdateTenantGuest) => Promise<TenantGuest>;
type PutVerifyTenantGuest = (propertyId: string, accountId: string, guestId: string) => Promise<TenantGuest>;
type PostCheckInTenantGuest = (propertyId: string, accountId: string, guestId: string) => Promise<TenantGuest>;
type DeleteTenantGuest = (propertyId: string, accountId: string, guestId: string) => Promise<void>;

const pathBuilder = (propertyId: string, accountId: string, extension = ""): string => {
    return "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/accounts/" + accountId + "/guests/" + extension;
}

export const getTenantGuests: GetTenantGuests = (propertyId, accountId) => {
    const path = pathBuilder(propertyId, accountId);
    return fetchWithAuth(path, HttpMethod.GET);
}

export const getTenantGuest: GetTenantGuest = (propertyId, accountId, guestId) => {
    const path = pathBuilder(propertyId, accountId, guestId);
    return fetchWithAuth(path, HttpMethod.GET);
}

export const createTenantGuest: PostTenantGuest = (propertyId, accountId, guest) => {
    const path = pathBuilder(propertyId, accountId);
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(guest));
}

export const updateTenantGuest: PutTenantGuest = (propertyId, guest) => {
    const path = pathBuilder(propertyId, guest.accountId, guest.uuid);
    return fetchWithAuth(path, HttpMethod.PUT, JSON.stringify(guest));
}

export const verifyTenantGuest: PutVerifyTenantGuest = (propertyId, accountId, guestId) => {
    const path = pathBuilder(propertyId, accountId, guestId + "/verify");
    return fetchWithAuth(path, HttpMethod.PUT);
}

export const checkInTenantGuest: PostCheckInTenantGuest = (propertyId, accountId, guestId) => {
    const path = pathBuilder(propertyId, accountId, guestId + "/check-in");
    return fetchWithAuth(path, HttpMethod.POST);
}

export const deleteTenantGuest: DeleteTenantGuest = (propertyId, accountId, guestId) => {
    const path = pathBuilder(propertyId, accountId, guestId);
    return fetchWithAuth(path, HttpMethod.DELETE);
}
