import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import AutocompleteInput, {DataAttribute} from "../../Form/AutocompleteInput";
import {formInputValidate, formSelectValidate, GenericInputRegex, GenericSelectRegex} from "../../../types";
import Select from "../../Form/Select";
import Input from "../../Form/Input";
import {addOwnerReport, getOwners} from "../../../Api/PortfolioApi";
import {getPortfolioId} from "../../../helpers";


const OwnerReportPreview: React.FunctionComponent = () => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("")
    const [view, setView] = useState("select-owner");
    const [selectedOwner, setSelectedOwner] = useState("");
    const [ownerList, setOwnerList] = useState<DataAttribute[]>([]);
    const [selectedReport, setSelectedReport] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");

    useEffect(() => {
        setLoading(true);
        getOwners(getPortfolioId())
            .then(rz => {
                let oz: DataAttribute[] = [];
                rz.forEach(r => {
                    let name = r.name;
                    if (r.person) {
                        name += " - " + r.person.firstName + " " + r.person.lastName;
                    }
                    oz.push({
                        uuid: r.uuid,
                        label: name
                    })
                })
                setOwnerList([...oz])
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }, []);


    function doAddOwnerReport(e: FormEvent) {
        e.preventDefault();
        setLoading(true);
        addOwnerReport(getPortfolioId(), {
            name: "",
            type: selectedReport,
            ownerIds: new Array(selectedOwner),
            parameters: getParameters(),
            preview: true
        })
            .then(r => {
                let first = r.pop();
                if (first && window && window.location.host) {
                    let url = window.location.protocol + '//' + window.location.host + "/p/m/owners/" + first.type + "/" + first.reportId;
                    let focus = window.open(url, '_blank');
                    if (focus) {
                        focus.focus();
                    }
                }

            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }

    function getParameters() {
        if (selectedReport === "owner-statement") {
            return [{
                key: "from_date",
                value: fromDate
            }, {
                key: "to_date",
                value: toDate
            }]
        }
        if (selectedReport === "cash-flow-property-comparison") {
            return [{
                key: "from_date",
                value: fromDate
            }, {
                key: "to_date",
                value: toDate
            }]
        }
        return []
    }

    function selectOwner() {
        return <>
            <div className={"row"}>
                <div className={"col-md-4 mb-3"}>
                    <AutocompleteInput id={"tenant"} validation={GenericInputRegex.expression}
                                       data={ownerList}
                                       doValidate={formInputValidate}
                                       value={selectedOwner}
                                       setValue={setSelectedOwner}
                                       placeholder={"Search by owner name"}
                                       required={true}
                                       invalidFeedbackText={GenericInputRegex.helpText}
                                       type={"text"} label={"Owner"}
                    />
                </div>
            </div>
            <div className={"row mb-3"}>
                <div className={"col-md-4"}>
                    <Select id={"report"} validation={GenericSelectRegex.expression}
                            doValidate={formSelectValidate} value={selectedReport} setValue={setSelectedReport}
                            required={true}
                            invalidFeedbackText={GenericSelectRegex.helpText} label={"Owner Report"}>
                        <option disabled={true} value={""}>Select a report...</option>
                        <option value={"owner-statement"}>Owner Statement</option>
                        <option value={"cash-flow-property-comparison"}>Cash Flow Property Comparison</option>
                    </Select>
                </div>
            </div>
            {getReportAttributes()}
        </>
    }

    function getCashFlowPropertyComparisonOptions() {
        return <>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <Input id={"from"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={fromDate}
                           setValue={setFromDate}
                           required={true}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"date"} label={"From Date"}
                    />
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <Input id={"to"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={toDate}
                           setValue={setToDate}
                           required={true}
                           disabled={!fromDate}
                           min={fromDate}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"date"} label={"To Date"}
                    />
                </div>
            </div>
            <div className={"row mt-3"}>
                <div className={"col-md-4"}>
                    <button disabled={!isReady()} className={"btn btn-primary"}
                            onClick={doAddOwnerReport}>View Report
                    </button>
                </div>
            </div>
        </>
    }

    function getOwnerStatementOptions() {
        return <>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <Input id={"from"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={fromDate}
                           setValue={setFromDate}
                           required={true}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"date"} label={"From Date"}
                    />
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <Input id={"to"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={toDate}
                           setValue={setToDate}
                           required={true}
                           disabled={!fromDate}
                           min={fromDate}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"date"} label={"To Date"}
                    />
                </div>
            </div>
            <div className={"row mt-3"}>
                <div className={"col-md-4"}>
                    <button disabled={!isReady()} className={"btn btn-primary"}
                            onClick={doAddOwnerReport}>View Report
                    </button>
                </div>
            </div>
        </>
    }

    function isReady() {
        if (selectedOwner && selectedReport && fromDate && toDate) {
            return true;
        }
        return false;
    }

    function getReportAttributes() {
        if (selectedReport && selectedReport === "owner-statement") {
            return getOwnerStatementOptions();
        }
        if (selectedReport && selectedReport === "cash-flow-property-comparison") {
            return getCashFlowPropertyComparisonOptions();
        }
        return <></>
    }

    function getContent() {
        if (view === "select-owner") {
            return selectOwner()
        }
    }

    return <>
        {loading && <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>}
        {errorMessage && <div className="alert alert-danger" role="alert">
            {errorMessage}
        </div>}
        {successMessage && <div className="alert alert-success" role="alert">
            {successMessage}
        </div>}
        {getContent()}
    </>
}
export default OwnerReportPreview;