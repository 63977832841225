import * as React from "react";
import {TenantFrontDeskInstruction} from "../../../../Api/FrontDeskInstructionsApi";
import {ARAccount} from "../../../../types";
import FormFieldBuilder, {assignFormFieldValues, FormFieldType} from "../../../Form/FieldBuilder/FormFieldBuilder";
import FrontDeskInstructionForm from "../Forms/FrontDeskInstructionForm";
import moment from "moment/moment";
import {getTicketIdFromUuid} from "../../../../helpers";
import {CheckCircleFill, XCircleFill} from "../../../Icons/Icons";

const ViewFrontDeskInstruction: React.FunctionComponent<{ instruction: TenantFrontDeskInstruction, account: ARAccount }> = ({instruction, account}) => {

    assignFormFieldValues({field: FrontDeskInstructionForm.accountId, value: account.unitName, disabledOverride: true});
    assignFormFieldValues({field: FrontDeskInstructionForm.comments, value: instruction.comments, disabledOverride: true});
    assignFormFieldValues({field: FrontDeskInstructionForm.permanent, value: instruction.permanent ? "Yes" : "No", disabledOverride: true, typeOverride: FormFieldType.INPUT});
    assignFormFieldValues({field: FrontDeskInstructionForm.startDate, value: instruction.permanent ? "Permanent" : moment(instruction.startDate).format("MM / DD / YYYY"), disabledOverride: true, typeOverride: FormFieldType.INPUT});
    assignFormFieldValues({field: FrontDeskInstructionForm.endDate, value: instruction.permanent ? "Permanent" : moment(instruction.endDate).format("MM / DD / YYYY"), disabledOverride: true, typeOverride: FormFieldType.INPUT});

    return (<>
        <div className="row p-1">
            <div className="col-12">
                <div className="row">
                    <h4>Instruction</h4>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Id</label>
                        <h6>{getTicketIdFromUuid(instruction.uuid)}</h6>
                    </div>
                    <div className="col">
                        <label>Created By Management</label>
                        <h6>{instruction.createdByManagement ? <CheckCircleFill color="green"/> : <XCircleFill color="red"/>}</h6>
                    </div>
                    <div className="col">
                        <label>Updated By</label>
                        <h6>{instruction.updateBy.firstName + " " + instruction.updateBy.lastName}</h6>
                    </div>
                </div>
            </div>
        </div>
        <div className="row p-1 mb-3">
            <div className="col-md-4">
                <div className="row d-grid gap-2">
                    <div className="col-12">
                        <FormFieldBuilder field={FrontDeskInstructionForm.accountId} />
                    </div>
                    <div className="col-12">
                        <FormFieldBuilder field={FrontDeskInstructionForm.permanent} />
                    </div>
                    <div className="col-12">
                        <FormFieldBuilder field={FrontDeskInstructionForm.startDate} />
                    </div>
                    <div className="col-12">
                        <FormFieldBuilder field={FrontDeskInstructionForm.endDate} />
                    </div>
                </div>
            </div>
            <div className="col-md-8">
                <FormFieldBuilder field={FrontDeskInstructionForm.comments} />
            </div>
        </div>
    </>)
}

export default ViewFrontDeskInstruction;
