import * as React from "react";
import {useState} from "react";

const MessagesCustomTable: React.FunctionComponent = (props) => {
    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(5);

    const rows = React.Children.toArray(props.children);
    const numberOfPages = Math.ceil(rows.length / paginationSize);


    const paginationComponent = [];
    for (let i = 0; i < numberOfPages; i++) {
        paginationComponent.push(<li key={"page-" + i}
                                     className={((i + 1) === page) ? "page-item active" : "page-item"}>
            <button type={"button"} className="page-link" onClick={() => setPage(i + 1)}>{i + 1}</button>
        </li>)
    }


    return (<>
        <div className="d-flex w-100 justify-content-end">
            <nav>
                <ul className="pagination pe-3 d-flex align-items-center">
                    <li className={(page === 1) ? "page-item disabled" : "page-item"}>
                        <button type={"button"} className={"page-link"}
                                onClick={() => (page > 1) ? setPage(page - 1) : page}>Previous
                        </button>
                    </li>
                    {paginationComponent}
                    <li className={(page === numberOfPages) ? "page-item disabled" : "page-item"}>
                        <button type={"button"} className="page-link"
                                onClick={() => (page < numberOfPages) ? setPage(page + 1) : page}>Next
                        </button>
                    </li>
                    <li>
                        <select className={"form-select custom-select d-block w-100 ms-3"}
                                value={paginationSize}
                                onChange={e => {
                                    let value = e.target.value;
                                    setPaginationSize(parseInt(value));
                                    setPage(1);
                                }}>
                            <option value={2}>2</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={50}>50</option>
                        </select>
                    </li>
                </ul>
            </nav>
        </div>
        <div className="list-group list-group-flush mt-2 mb-2 border-top">
            {rows.filter((r, rindex) => rindex >= (page - 1) * paginationSize && rindex < page * paginationSize).slice(0).reverse()}
        </div>
    </>);
}

export default MessagesCustomTable;
