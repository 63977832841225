import * as React from "react";
import {Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import ViewAmenities from "./View/ViewAmenities";
import ManageAmenity from "./Manage/ManageAmenity";
import AddAmenity from "./Add/AddAmenity";
import AutoSelectProperty from "../Form/SelectProperty/AutoSelectProperty";

const AmenityRouter: React.FunctionComponent = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <AutoSelectProperty name={"Amenities"} />
            </Route>
            <Route exact path={path + "/:propertyId"}>
                <ViewAmenities />
            </Route>
            <Route exact path={path + "/:propertyId/add"}>
                <AddAmenity />
            </Route>
            <Route exact path={path + "/:propertyId/manage/:amenityId"}>
                <ManageAmenity />
            </Route>
        </Switch>
    )
}

export default AmenityRouter;
