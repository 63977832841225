import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import PrivateMainContainer from "./Containers/PrivateMainContainer";
import OauthCallbackHandler from "./Containers/OauthCallbackHandler";
import PortfolioSelection from "./Components/PortfolioSelection";
import {Switch} from "react-router";
import NoRouteMatch from "./Components/NoRouteMatch";
import CreatePortfolio from "./Components/CreatePortfolio";
import SignupCallbackHandler from "./Containers/SignupCallbackHandler";
import ActivateCallbackHandler from "./Components/Activate";

function App() {
    return (
        <Router>
            <Switch>
                <Route exact path={"/callback"}>
                    <OauthCallbackHandler/>
                </Route>
                <Route exact path={"/signup_callback"}>
                    <SignupCallbackHandler/>
                </Route>
                <Route exact={false} path={"/activate/:userUUID"}>
                    <ActivateCallbackHandler/>
                </Route>
                <Route exact path={"/p/s/portfolio-selection"}>
                    <PortfolioSelection/>
                </Route>
                <Route exact path={"/p/s/create-portfolio"}>
                    <CreatePortfolio/>
                </Route>
                <Route path={"/p/m"}>
                    <PrivateMainContainer/>
                </Route>
                <Route exact path={"*"}>
                    <NoRouteMatch/>
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
