import {fetchWithAuth, HttpMethod} from "../api";
import {getPortfolioId} from "../helpers";

type getMaintenanceRequestsCategories = () => Promise<string[]>;

const MAINTENANCE_REQUEST_CATEGORY_API_BASE_URL = (pathExtention: string) => { return "/portfolios/" + getPortfolioId() + "/maintenance-request-categories" + pathExtention}

export const getMaintenanceRequestCategories: getMaintenanceRequestsCategories = (async () => {
    return fetchWithAuth(MAINTENANCE_REQUEST_CATEGORY_API_BASE_URL("/"), HttpMethod.GET);
})


