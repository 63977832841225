import * as React from 'react';
import {useEffect, useState} from 'react';
import {useParams} from "react-router";
import {getTenantFrontDeskInstruction, TenantFrontDeskInstruction} from "../../../Api/FrontDeskInstructionsApi";
import LoadingOverlay from "../../LoadingOverlay";
import Tabs, {Tab} from "../../Tabs/Tabs";
import Alert, {AlertType} from "../../Alert";
import {ARAccount, Property} from "../../../types";
import {getProperty} from "../../../Api/PropertyApi";
import ViewFrontDeskInstruction from "./View/ViewFrontDeskInstruction";
import {getARAccountById} from "../../../Api/AccountApi";
import EditFrontDeskInstruction from "./Edit/EditFrontDeskInstruction";

const ManageFrontDeskInstruction: React.FunctionComponent = () => {

    enum ManageFrontDeskInstructionView {
        OVERVIEW="View",EDIT="Edit"
    }

    const tabs: Tab[] = [
        {
            targetView: ManageFrontDeskInstructionView.OVERVIEW,
            label: ManageFrontDeskInstructionView.OVERVIEW
        },
        {
            targetView: ManageFrontDeskInstructionView.EDIT,
            label: ManageFrontDeskInstructionView.EDIT,
        }
    ]

    const { propertyId, accountId, instructionId } = useParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [error, setError] = useState<Error>();
    const [view, setView] = useState<ManageFrontDeskInstructionView>(ManageFrontDeskInstructionView.OVERVIEW)

    const [property, setProperty] = useState<Property>();
    const [account, setAccount] = useState<ARAccount>();
    const [instruction, setInstruction] = useState<TenantFrontDeskInstruction>();

    useEffect(() => {
        setLoading(true);
        Promise.all([
            getProperty(propertyId).then(data => data),
            getARAccountById(propertyId, accountId).then(data => data),
            getTenantFrontDeskInstruction(propertyId, accountId, instructionId).then(data => data)
        ])
            .then(values => {
                setProperty(values[0]);
                setAccount(values[1]);
                setInstruction(values[2]);
            })
            .catch(setError)
            .finally(() => setLoading(false));
    }, [propertyId, accountId, instructionId, refresh])

    return (loading ? <LoadingOverlay show={loading} /> : <>
        <div className="row p-1">
            {error && <Alert type={AlertType.DANGER} message={error.message} />}
            <div className="col-12 mb-3">
                <h3>Front Desk Information</h3>
                <h5>{property?.name}</h5>
                {property?.address.addressLine1}<br/>
                {property?.address.addressLine2}<br hidden={(property?.address.addressLine2) !== undefined}/>
                {property?.address.city}, {property?.address.stateCode} {property?.address.zip}
            </div>
            <Tabs view={view} setView={setView} tabs={tabs} />
            <div className="col-9 widget">
                {account && instruction && <>
                    <div hidden={view !== ManageFrontDeskInstructionView.OVERVIEW}>
                        <ViewFrontDeskInstruction account={account} instruction={instruction} />
                    </div>
                    <div hidden={view !== ManageFrontDeskInstructionView.EDIT}>
                        <EditFrontDeskInstruction instruction={instruction} account={account} done={() => {
                            setRefresh(!refresh);
                            setView(ManageFrontDeskInstructionView.OVERVIEW);
                        }} />
                    </div>
                </>}
            </div>
        </div>
    </>)
}

export default ManageFrontDeskInstruction;
