import * as React from 'react';
import {useHistory, useParams} from "react-router";
import {FormEvent, useEffect, useState} from "react";
import {AppUser, ARAccount} from "../../../types";
import Input from "../../Form/Input";
import TextArea from "../../Form/TextArea";
import Select from "../../Form/Select";
import AddMaintenanceRequestForm from "../Forms/AddMaintenanceRequestForm";
import {getARAccounts} from "../../../Api/AccountApi";
import LoadingOverlay from "../../LoadingOverlay";
import {getUsersWithMaintenanceRole} from "../../../Api/UserApi";
import {getMaintenanceRequestCategories} from "../../../Api/MaintenanceRequestCategoriesApi";
import {createMaintenanceRequest, MaintenanceRequest} from "../../../Api/MaintenanceRequestApi";
import PageLayout from "../../../Layouts/PageLayout";
import {AlertProps, AlertType} from "../../Alert";
import Card from "../../Card";


const AddMaintenanceRequest: React.FunctionComponent = () => {

    enum EntryPermission {
        No="No", Yes="Yes"
    }

    const { propertyId } = useParams();
    const history = useHistory();

    const [error, setError] = useState<Error>()
    const [alert, setAlert] = useState<AlertProps>()
    const [loading, setLoading] = useState<boolean>(true);

    const [accountId, setAccountId] = useState<string>("");
    const [category, setCategory] = useState<string>("");
    const [entryPermission, setEntryPermission] = useState<EntryPermission>(EntryPermission.No);
    const [description, setDescription] = useState<string>("");
    const [accessDetails, setAccessDetails] = useState<string>("");
    const [technicalNotes, setTechnicalNotes] = useState<string>("");
    const [scheduledDate, setScheduledDate] = useState<string>("");
    const [assignedMaintenanceUserId, setAssignedMaintenanceUserId] = useState<string>("");

    const [categories, setCategories] = useState<string[]>();
    const [accounts, setAccounts] = useState<ARAccount[]>([]);
    const [maintenanceUsers, setMaintenanceUsers] = useState<AppUser[]>([]);

    useEffect(() => {
        Promise.all([
            getMaintenanceRequestCategories()
                .then(data => data),
            getARAccounts(propertyId)
                .then(data => data),
            getUsersWithMaintenanceRole(propertyId)
                .then(data => data),
            ])
            .then(values => {
                setCategories(values[0]);
                setAccounts(values[1]);
                setMaintenanceUsers(values[2]);
            })
            .catch(setError)
            .finally(() => setLoading(false));
    }, [propertyId])

    useEffect(() => {
        if (error) {
            setAlert({type: AlertType.DANGER, message: error.message})
        }
    }, [error])

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        createMaintenanceRequest({
            accountId: accountId,
            category: category,
            entryPermission: entryPermission === EntryPermission.Yes,
            description: description,
            accessDetails: accessDetails,
            technicalNotes: technicalNotes,
            scheduledDate: scheduledDate,
            assignedMaintenanceUserId: assignedMaintenanceUserId
        })
            .then((data: MaintenanceRequest) => history.push({
                pathname: "/p/m/maintenance/maintenance-requests/" + propertyId + "/manage/" + data.uuid
            }))
            .catch(setError)
    }

    function clearForm() {
        setAccountId("");
        setCategory("");
        setEntryPermission(EntryPermission.No);
        setDescription("");
        setAccessDetails("");
        setTechnicalNotes("");
        setScheduledDate("");
        setAssignedMaintenanceUserId("");
    }

    return (<>
        {loading ? <LoadingOverlay /> :
            <>
                <PageLayout
                    title="Add Maintenance Request"
                    propertyId={propertyId}
                    alert={alert}
                >
                    <div className="col-lg-8">
                        <Card title="Maintenance Request Details">
                            <form onSubmit={onSubmit}>
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <Select
                                            id={AddMaintenanceRequestForm.accountId.id}
                                            validation={AddMaintenanceRequestForm.accountId.regex.expression}
                                            doValidate={AddMaintenanceRequestForm.accountId.validate}
                                            value={accountId}
                                            setValue={setAccountId}
                                            required={AddMaintenanceRequestForm.accountId.required}
                                            invalidFeedbackText={AddMaintenanceRequestForm.accountId.regex.helpText}
                                            label={"Unit"}
                                        >
                                            <option value={""} disabled={true}>Select Unit...</option>
                                            {
                                                accounts?.map((a: ARAccount) => (
                                                    <option key={a.uuid + "_a"}
                                                            value={a.uuid}>{a.unitName}</option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <Select
                                            id={AddMaintenanceRequestForm.category.id}
                                            validation={AddMaintenanceRequestForm.category.regex.expression}
                                            doValidate={AddMaintenanceRequestForm.category.validate}
                                            value={category}
                                            setValue={setCategory}
                                            required={AddMaintenanceRequestForm.category.required}
                                            invalidFeedbackText={AddMaintenanceRequestForm.category.regex.helpText}
                                            label={"Category"}
                                        >
                                            <option value={""} disabled={true}>Select Category...</option>
                                            {
                                                categories?.map(c => (
                                                    <option key={c + "_mrc"}
                                                            value={c}>{c}</option>
                                                ))
                                            }
                                        </Select>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <TextArea
                                            id={AddMaintenanceRequestForm.description.id}
                                            validation={AddMaintenanceRequestForm.description.regex.expression}
                                            doValidate={AddMaintenanceRequestForm.description.validate}
                                            value={description}
                                            setValue={setDescription}
                                            required={AddMaintenanceRequestForm.description.required}
                                            invalidFeedbackText={AddMaintenanceRequestForm.description.regex.helpText}
                                            rows={3}
                                            label={"Description"}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <Select
                                            id={AddMaintenanceRequestForm.entryPermission.id}
                                            validation={AddMaintenanceRequestForm.entryPermission.regex.expression}
                                            doValidate={AddMaintenanceRequestForm.entryPermission.validate}
                                            value={entryPermission}
                                            setValue={setEntryPermission}
                                            required={AddMaintenanceRequestForm.entryPermission.required}
                                            invalidFeedbackText={AddMaintenanceRequestForm.entryPermission.regex.helpText}
                                            label={"Entry Permission"}
                                        >
                                            <option value={EntryPermission.No}>No</option>
                                            <option value={EntryPermission.Yes}>Yes</option>
                                        </Select>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <TextArea
                                            id={AddMaintenanceRequestForm.accessDetails.id}
                                            validation={AddMaintenanceRequestForm.accessDetails.regex.expression}
                                            doValidate={AddMaintenanceRequestForm.accessDetails.validate}
                                            value={accessDetails}
                                            setValue={setAccessDetails}
                                            required={AddMaintenanceRequestForm.accessDetails.required}
                                            invalidFeedbackText={AddMaintenanceRequestForm.accessDetails.regex.helpText}
                                            rows={3}
                                            label={"Access Details"}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <Select
                                                    id={AddMaintenanceRequestForm.assignedMaintenanceUser.id}
                                                    validation={AddMaintenanceRequestForm.assignedMaintenanceUser.regex.expression}
                                                    doValidate={AddMaintenanceRequestForm.assignedMaintenanceUser.validate}
                                                    value={assignedMaintenanceUserId}
                                                    setValue={setAssignedMaintenanceUserId}
                                                    required={AddMaintenanceRequestForm.assignedMaintenanceUser.required}
                                                    invalidFeedbackText={AddMaintenanceRequestForm.assignedMaintenanceUser.regex.helpText}
                                                    label={"Ticket Assigned"}
                                                >
                                                    <option value={""} disabled={true}>Select a Maintenance User...</option>
                                                    {
                                                        maintenanceUsers?.map((u: AppUser) => (
                                                            <option key={u.uuid + "_p"}
                                                                    value={u.uuid}>{u.firstName + " " + u.lastName}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 mb-3">
                                                <Input id={AddMaintenanceRequestForm.scheduledDate.id}
                                                       validation={AddMaintenanceRequestForm.scheduledDate.regex.expression}
                                                       doValidate={AddMaintenanceRequestForm.scheduledDate.validate}
                                                       value={scheduledDate}
                                                       setValue={setScheduledDate}
                                                       required={AddMaintenanceRequestForm.scheduledDate.required}
                                                       invalidFeedbackText={AddMaintenanceRequestForm.scheduledDate.regex.helpText}
                                                       type={"date"}
                                                       label={"Scheduled Date"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <TextArea
                                            id={AddMaintenanceRequestForm.technicalNotes.id}
                                            validation={AddMaintenanceRequestForm.technicalNotes.regex.expression}
                                            doValidate={AddMaintenanceRequestForm.technicalNotes.validate}
                                            value={technicalNotes}
                                            setValue={setTechnicalNotes}
                                            required={AddMaintenanceRequestForm.technicalNotes.required}
                                            invalidFeedbackText={AddMaintenanceRequestForm.technicalNotes.regex.helpText}
                                            rows={5}
                                            label="Notes"
                                        />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-start gap-2 mt-3">
                                    <button type="button" className="btn btn-outline-primary" onClick={clearForm}>Clear</button> 
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </Card>
                    </div>
                </PageLayout>
            </>
        }
    </>)
}

export default AddMaintenanceRequest;
