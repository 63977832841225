import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import {ARAccount} from "../../../../types";
import {deleteTenantGuest, TenantGuest, updateTenantGuest, verifyTenantGuest} from "../../../../Api/TenantGuestApi";
import {useHistory, useParams} from "react-router";
import FormFieldBuilder, {assignFormFieldValues, FormFieldType} from "../../../Form/FieldBuilder/FormFieldBuilder";
import TenantGuestForm from "../Forms/TenantGuestForm";
import {getTicketIdFromUuid} from "../../../../helpers";
import LoadingOverlay from "../../../LoadingOverlay";
import Alert, {AlertType} from "../../../Alert";
import moment from "moment/moment";
import {CheckCircleFill, XCircleFill} from "../../../Icons/Icons";

const EditTenantGuest: React.FunctionComponent<{account: ARAccount, guest: TenantGuest, done: Function}> = ({account, guest, done}) => {

    enum YES_OR_NO {
        YES = "Yes", NO = "No"
    }

    const { propertyId, accountId, guestId } = useParams();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [details, setDetails] = useState<string>("");
    const [vehicleDescription, setVehicleDescription] = useState<string>("");
    const [vehicleTagNumber, setVehicleTagNumber] = useState<string>("");
    const [allowedKey, setAllowedKey] = useState<string>("");
    const [secretPhrase, setSecretPhrase] = useState<string>("");
    const [permanent, setPermanent] = useState<string>("");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");

    assignFormFieldValues({field: TenantGuestForm.accountId, value: account.unitName});
    assignFormFieldValues({field: TenantGuestForm.firstName, value: firstName, setValue: setFirstName, disabledOverride: false});
    assignFormFieldValues({field: TenantGuestForm.lastName, value: lastName, setValue: setLastName, disabledOverride: false});
    assignFormFieldValues({field: TenantGuestForm.details, value: details, setValue: setDetails, disabledOverride: false});
    assignFormFieldValues({field: TenantGuestForm.vehicleDescription, value: vehicleDescription, setValue: setVehicleDescription, disabledOverride: false});
    assignFormFieldValues({field: TenantGuestForm.vehicleTagNumber, value: vehicleTagNumber, setValue: setVehicleTagNumber, disabledOverride: false});
    assignFormFieldValues({field: TenantGuestForm.allowedKey, value: allowedKey, setValue: setAllowedKey, disabledOverride: false, typeOverride: FormFieldType.SELECT});
    assignFormFieldValues({field: TenantGuestForm.secretPhrase, value: secretPhrase, setValue: setSecretPhrase, disabledOverride: false});
    assignFormFieldValues({field: TenantGuestForm.permanent, value: permanent, setValue: setPermanent, disabledOverride: false, typeOverride: FormFieldType.SELECT});
    assignFormFieldValues({field: TenantGuestForm.startDate, value: startDate, setValue: setStartDate, requiredOverride: permanent === YES_OR_NO.NO, disabledOverride: permanent === YES_OR_NO.YES, typeOverride: FormFieldType.DATE});
    assignFormFieldValues({field: TenantGuestForm.endDate, value: endDate, setValue: setEndDate, requiredOverride: permanent === YES_OR_NO.NO, disabledOverride: permanent === YES_OR_NO.YES, typeOverride: FormFieldType.DATE});

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (isValid) {
            setLoading(true);
            updateTenantGuest(propertyId, {
                uuid: guest.uuid,
                accountId,
                firstName,
                lastName,
                details,
                vehicleDescription,
                vehicleTagNumber,
                allowedKey: allowedKey === YES_OR_NO.YES,
                secretPhrase,
                permanent: permanent === YES_OR_NO.YES,
                startDate,
                endDate
            })
                .then(() => {
                    setLoading(false);
                    done();
                })
                .catch(e => {
                    setError(e);
                    setLoading(false);
                });
        }
    }

    const onReset = (e: FormEvent | undefined) => {
        e?.preventDefault();
        setFirstName(guest.firstName);
        setLastName(guest.lastName);
        setDetails(guest.details);
        setVehicleDescription(guest.vehicleDescription);
        setVehicleTagNumber(guest.vehicleTagNumber);
        setAllowedKey(guest.allowedKey ? YES_OR_NO.YES : YES_OR_NO.NO);
        setSecretPhrase(guest.secretPhrase);
        setPermanent(guest.permanent ? YES_OR_NO.YES : YES_OR_NO.NO);
        setStartDate(guest.startDate ? moment(guest.startDate).format("YYYY-MM-DD") : "");
        setEndDate(guest.endDate ? moment(guest.endDate).format("YYYY-MM-DD") : "");
    }

    const verifyGuest = () => {
        if (!guest.verified) {
            setLoading(true);
            verifyTenantGuest(propertyId, accountId, guestId)
                .then(() => {
                    setLoading(false);
                    done();
                })
                .catch(setError);
        }
    }

    const onDelete = () => {
        if (confirm) {
            deleteTenantGuest(propertyId, accountId, guestId)
                .then(() => history.push("/p/m/tenant-services/front-desk/" + propertyId));
        }
    }

    useEffect(() => {
        const isFirstNameValid = firstName !== "";
        const isStartDateValid = permanent === YES_OR_NO.YES || startDate !== "";
        const isEndDateValid = permanent === YES_OR_NO.YES || endDate !== "";
        setIsValid(isFirstNameValid && isStartDateValid && isEndDateValid);
    }, [firstName, permanent, startDate, endDate])

    useEffect(() => {
        onReset(undefined);
    }, [])

    return (loading ? <LoadingOverlay show={loading} /> : <>
        <div className="row p-1">
            <div className="col-12">
                {error && <Alert type={AlertType.DANGER} message={error.message} />}
                {confirm && <>
                    <div className="alert alert-warning" role="alert">
                        <p>Are you sure you would like to delete this guest?  This action cannot be undone.</p>
                        <button className="btn btn-danger me-2" type="button" onClick={() => setConfirm(false)}>No</button>
                        <button className="btn btn-success" type="button" onClick={() => onDelete()}>Yes</button>
                    </div>
                </>}
            </div>
        </div>
        <div className="row p-1">
            <div className="col-12">
                <div className="row">
                    <h4>Tenant Guest</h4>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Id</label>
                        <h6>{getTicketIdFromUuid(guest.uuid)}</h6>
                    </div>
                    <div className="col">
                        <label>Verified</label>
                        <h6>{guest.verified ? <CheckCircleFill color="green"/> : <XCircleFill color="red"/>}</h6>
                    </div>
                    <div className="col">
                        <label>Created By Management</label>
                        <h6>{guest.createdByManagement ? <CheckCircleFill color="green"/> : <XCircleFill color="red"/>}</h6>
                    </div>
                    <div className="col">
                        <label>Updated By</label>
                        <h6>{guest.updatedBy.firstName + " " + guest.updatedBy.lastName}</h6>
                    </div>
                </div>
            </div>
        </div>
        <div className="row p-1">
            <form onSubmit={onSubmit} onReset={onReset}>
                <div className="row d-grid gap-2">
                    <div className="row">
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.accountId} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.firstName} />
                        </div>
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.lastName} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.allowedKey} selectOptions={TenantGuestForm.allowedKey.defaultSelectOptions}/>
                        </div>
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.permanent} selectOptions={TenantGuestForm.permanent.defaultSelectOptions}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.startDate} />
                        </div>
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.endDate} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <FormFieldBuilder field={TenantGuestForm.details} />
                        </div>
                        <div className="col-6 d-grid gap-2">
                            <div className="row">
                                <div className="col-12">
                                    <FormFieldBuilder field={TenantGuestForm.vehicleDescription} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <FormFieldBuilder field={TenantGuestForm.vehicleTagNumber} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <FormFieldBuilder field={TenantGuestForm.secretPhrase} />
                                </div>
                            </div>
                        </div>
                        <div className="col-9 mt-3">
                            <div className="btn-group" role="btn-group">
                                <button type="reset" className="btn btn-outline-primary">Reset</button>
                                <button type="submit" className="btn btn-primary">Update</button>
                            </div>
                        </div>
                        <div className="col-3 mt-3">
                            <div className="row justify-content-end">
                                <div className="btn-group" role="btn-group">
                                    <button disabled={false} type="button" className="btn btn-outline-danger" onClick={() => setConfirm(true)}>Delete</button>
                                    <button disabled={guest.verified} type="button" className="btn btn-success" onClick={() => verifyGuest()}>{!guest.verified ? "Verify" : "Verified"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </>)
}

export default EditTenantGuest;
