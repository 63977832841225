import * as React from 'react';


const RequiredLabel: React.FunctionComponent = (p) => {
    return (<>
        <span>{p.children}</span>
        <span style={{paddingLeft: 5, color: "var(--bs-danger)"}}>*</span>
    </>);
};

export default RequiredLabel;