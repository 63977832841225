import React, {Component} from 'react';
import {ContentState, convertFromHTML, EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';


export interface Params {
    initialHtmlContent?: string,
    onEditorChange: Function,
}

function getInitialState(html: string) {
    if(html) {

        const contentBlocks = convertFromHTML(html);
        return EditorState.createWithContent(ContentState.createFromBlockArray(contentBlocks.contentBlocks));
    }
    EditorState.createEmpty();
}

class WYSIWYGEditor extends Component<Params> {


    state = {
        editorState: getInitialState(this.props.initialHtmlContent || "")
    }

    // @ts-ignore
    onEditorStateChange: Function = (editorState) => {
        this.props.onEditorChange(editorState);
        this.setState({
            editorState,
        });
    };

    render() {
        const { editorState } = this.state;

        return (

            <div>
                <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    // @ts-ignore
                    //defaultEditorState={sampleEditorContent}
                    // @ts-ignore
                    onEditorStateChange={this.onEditorStateChange}
                />

            </div>
        );
    }
}
export default WYSIWYGEditor;