import pkceChallenge from "pkce-challenge";
import {OauthState} from "./types";
import {v4 as uuidv4} from 'uuid';

let authApiEndpoint: string = 'https://auth.propertymq.com';

if (window.location.hostname === "localhost") {
    authApiEndpoint = window.location.protocol + '//' + window.location.hostname + ":4001"
} 
//else if (window.location.hostname === "localhost" && parseInt(window.location.port) >= 3000 && parseInt(window.location.port) < 4000) {
//    authApiEndpoint = window.location.protocol + '//' + window.location.hostname + ":8100"
//}

export const AUTH_CLIENT_ID = "63b017c2-3257-404b-a9ba-738b71124b5b";
export const AUTH_ACCESS_TOKEN = "stackleader.auth.access_token";
export const AUTH_REFRESH_TOKEN = "stackleader.auth.refresh_token";
export const AUTH_EXPIRES_IN = "stackleader.auth.expires_in";
export const AUTH_SCOPE = "stackleader.auth.scope";
export const AUTH_TOKEN_TYPE = "stackleader.auth.token_type";
export const AUTH_CLOCK_START = "stackleader.auth.clock_start";
export const APP_PKCE_VERIFIER = "pmq.auth.pkce.verifier";
export const APP_OAUTH_STATE = "pmq.auth.state";

export const AUTH_CODE_EXPIRED_TOKEN = "pmq.auth.code.expired_token"

enum HttpMethod {
    GET = "GET",
    HEAD = "HEAD",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE",
    OPTIONS = "OPTIONS",
    TRACE = "TRACE"
}


export type OAuthSession = {
    access_token: string,
    token_type: string,
    expires_in: string,
    refresh_token: string,
    scope: string
}

type SignInParams = {
    code: string,
    redirectUri: string,
    clientId: string,
    codeVerifier: string
}

type signIn = (p: SignInParams) => Promise<OAuthSession>;

export const signIn: signIn = (async (p) => {

    const formBody = [];

    formBody.push(encodeURIComponent("grant_type") + "=" + encodeURIComponent("authorization_code"));
    formBody.push(encodeURIComponent("client_id") + "=" + encodeURIComponent(p.clientId));
    formBody.push(encodeURIComponent("code_verifier") + "=" + encodeURIComponent(p.codeVerifier));
    formBody.push(encodeURIComponent("code") + "=" + encodeURIComponent(p.code));
    formBody.push(encodeURIComponent("redirect_uri") + "=" + encodeURIComponent(p.redirectUri));
    formBody.push(encodeURIComponent("scope") + "=" + encodeURIComponent("/api"));
    let formBodyText = formBody.join("&");

    return fetch(authApiEndpoint + "/oauth/token", {
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            Pragma: 'no-cache'
        },
        body: formBodyText,
        method: HttpMethod.POST
    })
        .then(function (a) {
            if (a.ok) {
                return a.json().then(function (b: OAuthSession) {
                    console.log(b);
                    localStorage.setItem(AUTH_ACCESS_TOKEN, b.access_token);
                    localStorage.setItem(AUTH_REFRESH_TOKEN, b.refresh_token);
                    localStorage.setItem(AUTH_EXPIRES_IN, b.expires_in);
                    localStorage.setItem(AUTH_SCOPE, b.scope);
                    localStorage.setItem(AUTH_TOKEN_TYPE, b.token_type);
                    localStorage.setItem(AUTH_CLOCK_START, (Date.now() / 1000).toString())
                    return b;
                });
            }
            return a.json().then(function (e) {
                throw e;
            });
        });
});


type currentSession = () => Promise<OAuthSession>;

export const currentSession: currentSession = (() => {
    const clockStart = parseInt(localStorage.getItem(AUTH_CLOCK_START) || "0");
    //console.log("clock start " + clockStart)
    const now = (Date.now() / 1000);
    const elapsedTime = Math.floor(now - clockStart);
    //console.log("elapsed time: " + elapsedTime)
    const expiresIn = parseInt(localStorage.getItem(AUTH_EXPIRES_IN) || "0");
    const timeRemaining = expiresIn - elapsedTime;
    //console.log("remaining time: " + timeRemaining);
    if (timeRemaining > 60) {
        return new Promise<OAuthSession>(function (resolve, reject) {
                resolve({
                    access_token: localStorage.getItem(AUTH_ACCESS_TOKEN) || "",
                    refresh_token: localStorage.getItem(AUTH_REFRESH_TOKEN) || "",
                    expires_in: localStorage.getItem(AUTH_EXPIRES_IN) || "",
                    scope: localStorage.getItem(AUTH_SCOPE) || "",
                    token_type: localStorage.getItem(AUTH_TOKEN_TYPE) || ""
                })
            }
        );
    }
    return new Promise<OAuthSession>(function (resolve, reject) {
        reject({
            message: "expired token",
            code: AUTH_CODE_EXPIRED_TOKEN
        })
    })

});


export const doLoginRedirect = (currentPath: string) => {
    const pkce = pkceChallenge(128);
    localStorage.setItem(APP_PKCE_VERIFIER, pkce.code_verifier)
    let redirect_uri = window.location.protocol + '//' + window.location.host + '/callback';

    const state: OauthState = {
        path: currentPath,
        r_value: uuidv4()
    };
    const stateEncoded = btoa(JSON.stringify(state));
    localStorage.setItem(APP_OAUTH_STATE, stateEncoded)
    window.location.href = authApiEndpoint + "/authorize?" +
        "response_type=code" +
        "&client_id=" + encodeURIComponent(AUTH_CLIENT_ID) +
        "&code_challenge=" + encodeURIComponent(pkce.code_challenge) +
        "&code_challenge_method=" + encodeURIComponent("S256") +
        "&redirect_uri=" + encodeURIComponent(redirect_uri) +
        "&scope=" + encodeURIComponent("/api") +
        "&state=" + encodeURIComponent(stateEncoded)
}


export const doRegisterRedirect = (currentPath: string) => {
    const pkce = pkceChallenge(128);
    localStorage.setItem(APP_PKCE_VERIFIER, pkce.code_verifier)
    let redirect_uri = window.location.protocol + '//' + window.location.host + '/callback';

    const state: OauthState = {
        path: currentPath,
        r_value: uuidv4()
    };

    const stateEncoded = btoa(JSON.stringify(state));
    localStorage.setItem(APP_OAUTH_STATE, stateEncoded)
    window.location.href = authApiEndpoint + "/authorize?" +
        "response_type=code" +
        "&client_id=" + encodeURIComponent(AUTH_CLIENT_ID) +
        "&code_challenge=" + encodeURIComponent(pkce.code_challenge) +
        "&code_challenge_method=" + encodeURIComponent("S256") +
        "&redirect_uri=" + encodeURIComponent(redirect_uri) +
        "&scope=" + encodeURIComponent("/api") +
        "&state=" + encodeURIComponent(stateEncoded) +
        "&intent=signup"
}

export const doActivateRedirect = (currentPath: string, userUUID: string) => {
    const pkce = pkceChallenge(128);
    localStorage.setItem(APP_PKCE_VERIFIER, pkce.code_verifier)
    let redirect_uri = window.location.protocol + '//' + window.location.host + '/callback';

    const state: OauthState = {
        path: currentPath,
        r_value: uuidv4()
    };

    const stateEncoded = btoa(JSON.stringify(state));
    localStorage.setItem(APP_OAUTH_STATE, stateEncoded)
    window.location.href = authApiEndpoint + "/authorize?" +
        "response_type=code" +
        "&client_id=" + encodeURIComponent(AUTH_CLIENT_ID) +
        "&code_challenge=" + encodeURIComponent(pkce.code_challenge) +
        "&code_challenge_method=" + encodeURIComponent("S256") +
        "&redirect_uri=" + encodeURIComponent(redirect_uri) +
        "&scope=" + encodeURIComponent("/api") +
        "&state=" + encodeURIComponent(stateEncoded) +
        "&user_id=" + encodeURIComponent(userUUID) +
        "&intent=activate"
};


export const doLogoutRedirect = () => {
    if (window.location.hostname === "localhost") {
        window.location.href = "http://localhost:4001/a/logout?redirect_uri=" + window.location.protocol + '//' + window.location.host + window.location.pathname
    } else {
        window.location.href = "https://auth.propertymq.com/a/logout?redirect_uri=" + window.location.protocol + '//' + window.location.host + window.location.pathname
    }
}

export const doChangePasswordRedirect = () => {
    if (window.location.hostname === "localhost") {
        window.location.href = "http://localhost:4001/a/reset-password?redirect_uri=" + window.location.protocol + '//' + window.location.host + window.location.pathname
    } else {
        window.location.href = "https://auth.propertymq.com/a/reset-password?redirect_uri=" + window.location.protocol + '//' + window.location.host + window.location.pathname
    }
}

export const doManageProfileRedirect = () => {
    if (window.location.hostname === "localhost") {
        window.location.href = "http://localhost:4001/m/profile?redirect_uri=" + window.location.protocol + '//' + window.location.host + window.location.pathname
    } else {
        window.location.href = "https://auth.propertymq.com/m/profile?redirect_uri=" + window.location.protocol + '//' + window.location.host + window.location.pathname
    }
}