import React, {FormEvent, useEffect, useState} from "react";
import PageLayout from "../../../../Layouts/PageLayout";
import {useParams} from "react-router";
import {
    addOwnerPortalUser, deleteOwnerPortalUser,
    getOwner,
    getOwnerPortalUsers, resendUserInvite,
    updateOwner,
    updateOwnerBankAccount
} from "../../../../Api/PortfolioApi";
import {getPortfolioId} from "../../../../helpers";
import {
    AddressLine1Regex,
    AddressLine2Regex, AppUser,
    Business,
    CityRegex, EmailRegex,
    FirstNameRegex,
    formInputValidate,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex, GetAppUser,
    LastNameRegex, OwnerReportDto,
    PhoneNumberRegex,
    USAccountNameRegex,
    USBankAccountNumberRegex,
    USBankRoutingNumberRegex,
    ZipRegex
} from "../../../../types";
import Input from "../../../../Components/Form/Input";
import Select from "../../../../Components/Form/Select";
import StateSelect from "../../../../Components/StateSelect";
import {getOwnerReports} from "../../../../Api/ReportApi";
import Table from "../../../../Components/Table";
import {Link} from "react-router-dom";

const ViewOwner: React.FunctionComponent = () => {
    const {businessId} = useParams();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [owner, setOwner] = useState<Business>();
    const [currentEditField, setCurrentEditField] = useState(0);
    const [accountType, setAccountType] = useState("");
    const [accountHolderName, setAccountHolderName] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [verifyAccountNumber, setVerifyAccountNumber] = useState("");
    const [doEditBankAccount, setDoEditBankAccount] = useState(false);
    const [ownerReports, setOwnerReports] = useState<OwnerReportDto[]>();
    const [email, setEmail] = useState("");
    const [users, setUsers] = useState<GetAppUser[]>();
    const [selectedResendInvite, setSelectedResendInvite] = useState("");

    function doAddUserToPortal(e: FormEvent) {
        e.preventDefault();
        setErrorMessage("");
        setLoading(true);
        addOwnerPortalUser(getPortfolioId(), businessId, {
            email: email
        })
            .then(r => {
                load();
                setEmail("");
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }


    function getSpinner(id: string) {
        if (id === selectedResendInvite) {
            return <>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span className="visually-hidden" role="status">Loading...</span>
            </>
        }
        return <></>;
    }

    function getResendInviteButton(id: string, activated: boolean) {
        if (!activated) {
            return <button className={"btn btn-primary me-3"} type={"button"} onClick={(e) => {
                e.preventDefault();
                setSelectedResendInvite(id);
            }}>
                {getSpinner(id)}
                Resend Invite
            </button>
        }
    }

    useEffect(() => {
        if (selectedResendInvite) {
            resendUserInvite(selectedResendInvite)
                .then((r) => {
                })
                .catch(e => setErrorMessage(e.message))
                .finally(() => setSelectedResendInvite(""));
        }
    }, [selectedResendInvite]);

    function getUsers() {
        return <>
            <div className={"row"}>
                <div className={"col-md-3"}>
                    <Input id={"email"} validation={EmailRegex.expression} doValidate={formInputValidate}
                           value={email} setValue={setEmail} required={false} invalidFeedbackText={EmailRegex.helpText}
                           type={"text"} label={"Email"}/>
                </div>
                <div className={"col-md-9"}>
                    <button style={{marginTop: 21}} className={"btn btn-primary"}
                            onClick={doAddUserToPortal}>Add
                    </button>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    {users &&
                        <Table headers={["Email", "First Name", "Last Name", ""]} defaultPaginationSize={10}>
                            {
                                users.map(u => (
                                    <tr key={u.uuid} style={{verticalAlign: 'middle'}}>
                                        <td>{u.email} {!u.activated ? <em>(pending invite)</em> : ""}</td>
                                        <td>{u.firstName}</td>
                                        <td>{u.lastName}</td>
                                        <td>
                                            {getResendInviteButton(u.uuid, u.activated)}
                                            <button className={"btn btn-danger me-3"} onClick={(e) => {
                                                e.preventDefault();
                                                doDeleteUser(u.uuid)
                                            }}>
                                                Remove
                                            </button>
                                        </td>
                                    </tr>

                                ))
                            }
                        </Table>}
                </div>
            </div>
        </>
            ;
    }

    function doDeleteUser(userId: string) {
        setErrorMessage("");
        setLoading(true);
        deleteOwnerPortalUser(getPortfolioId(), businessId, userId)
            .then(r => {
                load();
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }

    function doUpdateOwner() {
        if (owner) {
            setErrorMessage("");
            setLoading(true);
            updateOwner(getPortfolioId(), businessId, owner)
                .then((r) => {
                    setCurrentEditField(0);
                })
                .catch((e) => setErrorMessage(e.message))
                .finally(() => setLoading(false));
        }
    }

    function load() {
        if (businessId) {
            setErrorMessage("");
            setLoading(true);
            getOwner(getPortfolioId(), businessId)
                .then(b => setOwner(b))
                .catch((e) => setErrorMessage(e.message))
                .finally(() => setLoading(false));

            getOwnerReports(businessId)
                .then(rz => {
                    console.log(rz);
                    setOwnerReports(rz)
                })
                .catch(e => setErrorMessage(e.message))
                .finally(() => {
                })

            getOwnerPortalUsers(getPortfolioId(), businessId)
                .then((uz) => setUsers(uz))
                .catch(e => setErrorMessage(e.message))
                .finally(() => {
                });
        }
    }

    useEffect(() => {
        load();
    }, [businessId]);

    function doBankAccountSave() {
        setErrorMessage("");
        setLoading(true);
        updateOwnerBankAccount(getPortfolioId(), businessId, {
            accountHolderName: accountHolderName,
            accountType: accountType,
            accountNumber: accountNumber,
            routingNumber: routingNumber,
        })
            .then(() => {
                setDoEditBankAccount(false);
                load();
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }

    function getEditSaveIcon(field: number) {
        if (field === currentEditField) {
            return <svg
                onClick={() => {
                    doUpdateOwner()
                }}
                style={{position: "absolute", top: 4, right: 0}}
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy"
                viewBox="0 0 16 16">
                <path d="M11 2H9v3h2z"/>
                <path
                    d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
            </svg>
        }
        return <svg
            onClick={() => {
                setCurrentEditField(field);
            }}
            style={{position: "absolute", top: 4, right: 0}} xmlns="http://www.w3.org/2000/svg" width="16" height="16"
            fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
            <path
                d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
        </svg>
    }

    function getEditSaveIconBankAccount() {
        return <svg
            onClick={() => {
                setDoEditBankAccount(true);
            }}
            style={{position: "absolute", top: 4, right: 0}} xmlns="http://www.w3.org/2000/svg" width="16" height="16"
            fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
            <path
                d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
        </svg>
    }

    function getEditBankAccount() {
        if (!doEditBankAccount) {
            return <div className={"row"}>
                <div className={"col-md-4"}>
                    <div style={{position: 'relative'}}>
                        <Select id={"payoutMethod"}
                                label={"Payout Method"}
                                required={true}
                                invalidFeedbackText={GenericSelectRegex.helpText}
                                validation={GenericSelectRegex.expression}
                                doValidate={formSelectValidate}
                                disabled={currentEditField !== 12}
                                value={owner?.payoutMethod || ""}
                                setValue={(e: string) => {
                                    if (owner) {
                                        owner.payoutMethod = e;
                                        setOwner({...owner});
                                    }
                                }}
                        >
                            <option disabled={true} value={""}>Select a payout method...</option>
                            <option value={"manual"}>Manual</option>
                            <option value={"ach"}>ACH</option>
                        </Select>
                        {getEditSaveIcon(12)}
                    </div>
                </div>
                <div className={"col-md-4"}>
                    <div style={{position: 'relative'}}>
                        <Input id={"name"} validation={GenericInputRegex.expression}
                               doValidate={formInputValidate}
                               value={(owner?.bankAccount?.lastFour ? "**" + owner?.bankAccount?.lastFour : "") || ""}
                               setValue={(e: string) => {

                               }}
                               required={true}
                               disabled={currentEditField !== 10}
                               invalidFeedbackText={GenericInputRegex.helpText}
                               type={"text"} label={"Bank Account"}
                        />
                        {getEditSaveIconBankAccount()}
                    </div>
                </div>

            </div>
        }
        return <div className={"row"}>
            <div className={"col-md-4"}>
                <Select id={"accountType"}
                        label={"Account Type"}
                        required={true}
                        invalidFeedbackText={GenericSelectRegex.helpText}
                        validation={GenericSelectRegex.expression}
                        doValidate={formSelectValidate}
                        value={accountType}
                        setValue={setAccountType}
                >
                    <option disabled={true} value={""}>Select an account type...</option>
                    <option value={"personal_checking"}>Personal Checking</option>
                    <option value={"personal_savings"}>Personal Savings</option>
                    <option value={"business_checking"}>Business Checking</option>
                    <option value={"business_savings"}>Business Savings</option>
                </Select>
            </div>
            <div className={"col-md-4"}>
                <Input id={"accountHolderName"}
                       type={"text"}
                       label={"Account Holder Name"}
                       required={true}
                       invalidFeedbackText={USAccountNameRegex.helpText}
                       validation={USAccountNameRegex.expression}
                       doValidate={formInputValidate}
                       value={accountHolderName}
                       setValue={setAccountHolderName}
                />
            </div>
            <div className={"col-md-4"}>
                <Input id={"routingNumber"}
                       type={"text"}
                       label={"Routing Number"}
                       required={true}
                       invalidFeedbackText={USBankRoutingNumberRegex.helpText}
                       validation={USBankRoutingNumberRegex.expression}
                       doValidate={formInputValidate}
                       value={routingNumber}
                       setValue={setRoutingNumber}
                />
            </div>
            <div className={"col-md-4"}>
                <Input id={"accountNumber"}
                       type={"text"}
                       label={"Account Number"}
                       required={true}
                       invalidFeedbackText={USBankAccountNumberRegex.helpText}
                       validation={USBankAccountNumberRegex.expression}
                       doValidate={formInputValidate}
                       value={accountNumber}
                       setValue={setAccountNumber}
                />
            </div>
            <div className={"col-md-4"}>
                <Input id={"vaccountNumber"}
                       type={"text"}
                       label={"Verify Account Number"}
                       required={true}
                       invalidFeedbackText={"Account numbers do not match"}
                       validation={USBankAccountNumberRegex.expression}
                       doValidate={() => {
                           return accountNumber === verifyAccountNumber;
                       }}
                       value={verifyAccountNumber}
                       setValue={setVerifyAccountNumber}
                />
            </div>
            <div className={"col-md-12 mt-3"}>
                <button className={"btn btn-primary"}
                        disabled={!accountType || !accountHolderName || !routingNumber || !accountNumber || accountNumber !== verifyAccountNumber}
                        onClick={() => doBankAccountSave()}
                >Save Bank Account
                </button>
                <button className={"btn btn-danger"}
                        onClick={() => setDoEditBankAccount(false)}
                >Cancel
                </button>
            </div>
        </div>
    }

    function getReports() {
        return <div className={"row"}>
            <div className={"col"}>
                <Table headers={["Date", "Name", "Type", ""]} defaultPaginationSize={10}>
                    {
                        ownerReports?.map(r => (
                            <tr key={r.uuid}>
                                <td>{r.createdAt}</td>
                                <td><Link to={"/p/m/owners/" + r.type + "/" + r.uuid}>{r.name}</Link></td>
                                <td>{r.type}</td>
                            </tr>
                        ))
                    }
                </Table>

            </div>
        </div>
    }

    return <PageLayout title={"Owner Information"}>
        {loading && <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>}
        {errorMessage && <div className="alert alert-danger" role="alert">
            {errorMessage}
        </div>}
        <div className={"row mb-3"}>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Input id={"name"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={owner?.name || ""}
                           setValue={(e: string) => {
                               if (owner) {
                                   owner.name = e;
                                   setOwner({...owner});
                               }
                           }}
                           required={true}
                           disabled={currentEditField !== 1}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"text"} label={"Name"}
                    />
                    {getEditSaveIcon(1)}
                </div>
            </div>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Select id={"businessStructure"}
                            validation={GenericSelectRegex.expression}
                            disabled={currentEditField !== 2}
                            doValidate={formSelectValidate}
                            value={owner?.businessStructure || ""}
                            setValue={(e: string) => {
                                if (owner) {
                                    owner.businessStructure = e;
                                    owner.typeOfBusiness = (e === "individual") ? "individual" : "company";
                                    setOwner({...owner});
                                }
                            }}
                            required={true}
                            invalidFeedbackText={GenericSelectRegex.helpText}
                            label={"Business Structure"}>
                        <option value="" disabled={true}>Select an option...</option>
                        <option value="sole_proprietorship">Sole Proprietorship</option>
                        <option value="single_member_llc">Single-member LLC</option>
                        <option value="multi_member_llc">Multi-member LLC</option>
                        <option value="private_partnership">Private Partnership</option>
                        <option value="public_partnership">Public Partnership</option>
                        <option value="private_corporation">Private Corporation</option>
                        <option value="public_corporation">Public Corporation</option>
                        <option value="individual">Individual</option>
                    </Select>
                    {getEditSaveIcon(2)}
                </div>
            </div>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Input id={"phone"} validation={PhoneNumberRegex.expression}
                           doValidate={formInputValidate}
                           value={owner?.phone || ""}
                           setValue={(e: string) => {
                               if (owner) {
                                   owner.phone = e;
                                   setOwner({...owner});
                               }
                           }}
                           required={true}
                           disabled={currentEditField !== 11}
                           invalidFeedbackText={PhoneNumberRegex.helpText}
                           type={"text"} label={"Phone"}
                    />
                    {getEditSaveIcon(11)}
                </div>
            </div>
        </div>
        <h5>Person</h5>
        <div className={"row mb-3"}>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Input id={"firstname"} validation={FirstNameRegex.expression}
                           doValidate={formInputValidate}
                           value={owner?.person?.firstName || ""}
                           setValue={(e: string) => {
                               if (owner) {
                                   if (!owner.person) {
                                       owner.person = {firstName: e, lastName: ""}
                                   } else {
                                       owner.person.firstName = e;
                                   }
                                   setOwner({...owner});
                               }
                           }}
                           required={true}
                           disabled={currentEditField !== 3}
                           invalidFeedbackText={FirstNameRegex.helpText}
                           type={"text"} label={"First Name"}
                    />
                    {getEditSaveIcon(3)}
                </div>
            </div>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Input id={"lastname"} validation={LastNameRegex.expression}
                           doValidate={formInputValidate}
                           value={owner?.person?.lastName || ""}
                           setValue={(e: string) => {
                               if (owner) {
                                   if (!owner.person) {
                                       owner.person = {firstName: "", lastName: e}
                                   } else {
                                       owner.person.lastName = e;
                                   }
                                   setOwner({...owner});
                               }
                           }}
                           required={true}
                           disabled={currentEditField !== 4}
                           invalidFeedbackText={LastNameRegex.helpText}
                           type={"text"} label={"Last Name"}
                    />
                    {getEditSaveIcon(4)}
                </div>
            </div>
        </div>
        <h5>Address</h5>
        <div className={"row mb-3"}>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Input id={"addressLine1"} validation={AddressLine1Regex.expression}
                           doValidate={formInputValidate}
                           value={owner?.address.addressLine1 || ""}
                           setValue={(e: string) => {
                               if (owner) {
                                   owner.address.addressLine1 = e;
                                   setOwner({...owner});
                               }
                           }}
                           required={true}
                           disabled={currentEditField !== 5}
                           invalidFeedbackText={AddressLine1Regex.helpText}
                           type={"text"} label={"Address Line 1"}
                    />
                    {getEditSaveIcon(5)}
                </div>
            </div>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Input id={"addressLine2"} validation={AddressLine2Regex.expression}
                           doValidate={formInputValidate}
                           value={owner?.address.addressLine2 || ""}
                           setValue={(e: string) => {
                               if (owner) {
                                   owner.address.addressLine2 = e;
                                   setOwner({...owner});
                               }
                           }}
                           required={true}
                           disabled={currentEditField !== 6}
                           invalidFeedbackText={AddressLine2Regex.helpText}
                           type={"text"} label={"Address Line 2"}
                    />
                    {getEditSaveIcon(6)}
                </div>
            </div>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Input id={"city"} validation={CityRegex.expression}
                           doValidate={formInputValidate}
                           value={owner?.address.city || ""}
                           setValue={(e: string) => {
                               if (owner) {
                                   owner.address.city = e;
                                   setOwner({...owner});
                               }
                           }}
                           required={true}
                           disabled={currentEditField !== 7}
                           invalidFeedbackText={CityRegex.helpText}
                           type={"text"} label={"City"}
                    />
                    {getEditSaveIcon(7)}
                </div>
            </div>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <StateSelect id={"state"} validation={GenericSelectRegex.expression}
                                 noSelectionText={"Select a state..."}
                                 doValidate={formSelectValidate}
                                 disabled={currentEditField !== 8}
                                 value={owner?.address.stateCode || ""}
                                 setValue={(e: string) => {
                                     if (owner) {
                                         owner.address.stateCode = e;
                                         setOwner({...owner});
                                     }
                                 }}
                                 required={true}
                                 invalidFeedbackText={GenericSelectRegex.helpText}
                                 label={"State"}/>
                    {getEditSaveIcon(8)}
                </div>
            </div>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Input id={"zip"} validation={ZipRegex.expression}
                           doValidate={formInputValidate}
                           value={owner?.address.zip ? owner?.address.zip + "" : ""}
                           setValue={(e: string) => {
                               if (owner) {
                                   owner.address.zip = e;
                                   setOwner({...owner});
                               }
                           }}
                           required={true}
                           disabled={currentEditField !== 9}
                           invalidFeedbackText={ZipRegex.helpText}
                           type={"text"} label={"Zip"}
                    />
                    {getEditSaveIcon(9)}
                </div>
            </div>
        </div>
        <h5>Distributions</h5>
        {getEditBankAccount()}
        <h3 className={"mt-3"}>Reports</h3>
        <hr/>
        {getReports()}
        <h3 className={"mt-3"}>Owner Portal Users</h3>
        {getUsers()}
        <hr/>
    </PageLayout>
}
export default ViewOwner;