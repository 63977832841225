import * as React from 'react';
import {ApplicantType, Application, EmergencyContact, Property} from "../../types";
import Card from "../Card";

type ViewApplicationProps = {
    application: Application
}

const Section: React.FunctionComponent<{label: string}> = (p) => {
    return <>
        <div className="mb-4">
            <Card title={p.label}>
                {p.children}
            </Card>
        </div>
    </>
}

const ViewApplication: React.FunctionComponent<ViewApplicationProps> = (p) => {
    let emergencyContact: EmergencyContact = {
        email: "",
        phone: "",
        name: ""
    };
    if (p.application && p.application.emergencyContacts && p.application.emergencyContacts.length > 0) {
        emergencyContact = p.application.emergencyContacts[0];
    }
    return (
        <>
            <div className="row">
                <div className="col-md-3">
                    <Section label="Unit Info">
                        <label>Name:</label>
                        <h5>{p.application.unit.name}</h5>
                        <label>Floor Plan:</label>
                        <h5>{p.application.unit.floorPlan.name}</h5>
                    </Section>
                </div>
                <Section label="Applicants">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">First name</th>
                                <th scope="col">Middle name</th>
                                <th scope="col">Last name</th>
                                <th scope="col">Maiden name</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">SSN/SIN</th>
                                <th scope="col">Driver's license number</th>
                                <th scope="col">Driver's license state</th>
                                <th scope="col">Smoke?</th>
                                <th scope="col">Type</th>
                                <th scope="col">Primary</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                p.application.applicants?.map((a, index) => (
                                    <tr key={"app_" + index}>
                                        <td>{a.firstName}</td>
                                        <td>{a.middleInitial}</td>
                                        <td>{a.lastName}</td>
                                        <td>{a.maidenName}</td>
                                        <td>{a.gender}</td>
                                        <td>{a.email}</td>
                                        <td>{a.phone}</td>
                                        <td>{a.ssn}</td>
                                        <td>{a.dlNumber}</td>
                                        <td>{a.dlState}</td>
                                        <td>{a.smoke ? "Yes" : "No"}</td>
                                        <td>{a.applicantType}</td>
                                        <td>{a.primary ? "Yes" : "No"}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </Section>
                <Section label="Address History">
                    <div className={"table-responsive"}>
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">Applicant</th>
                                <th scope="col">Address</th>
                                <th scope="col">Address 2</th>
                                <th scope="col">City</th>
                                <th scope="col">State</th>
                                <th scope="col">Zip</th>
                                <th scope="col">From</th>
                                <th scope="col">To</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                p.application.applicants?.map(ap => {
                                    if ((ap.applicantType === ApplicantType.occupant || ap.applicantType === ApplicantType.cooccupant) && ap.applicantAddresses) {
                                        return ap.applicantAddresses.map((a, index) => (
                                            <tr key={"add_" + index}>
                                                <td>{ap.firstName + " " + ap.lastName}</td>
                                                <td>{a.addressLine1}</td>
                                                <td>{a.addressLine2}</td>
                                                <td>{a.city}</td>
                                                <td>{a.state}</td>
                                                <td>{a.zip}</td>
                                                <td>{a.moveInDate}</td>
                                                <td>{a.moveOutDate}</td>
                                            </tr>
                                        ))
                                    }
                                    return null;
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </Section>
                <Section label="Employment">
                    <div className={"table-responsive"}>
                        <table className="table table-striped">
                            <thead>
                            <tr>

                                <th scope="col">Applicant</th>
                                <th scope="col">Employer name</th>
                                <th scope="col">Employer contact person</th>
                                <th scope="col">Employer phone</th>
                                <th scope="col">Start date</th>
                                <th scope="col">End date</th>
                                <th scope="col">Monthly gross income</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                p.application.applicants?.map(ap => {
                                    if (ap.employmentIncomeSources) {
                                        return ap.employmentIncomeSources.map((emp, index) => (
                                            <tr key={"emp_" + index}>
                                                <td>{ap.firstName + " " + ap.lastName}</td>
                                                <td>{emp.employerName}</td>
                                                <td>{emp.employmentContactPerson}</td>
                                                <td>{emp.employerPhone}</td>
                                                <td>{emp.startDate}</td>
                                                <td>{emp.endDate}</td>
                                                <td>{emp.monthlyGrossIncome}</td>
                                            </tr>
                                        ))
                                    }
                                    return null;
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </Section>
                <Section label="Pets">
                    <div className={"table-responsive"}>
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Type</th>
                                <th scope="col">Information</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                p.application.pets?.map((p, index) => (
                                    <tr key={"pet_" + index}>
                                        <td>{p.name}</td>
                                        <td>{p.petType}</td>
                                        <td>
                                            {(p.otherPetTypeDescription ? p.otherPetTypeDescription + ", " : "")
                                                + p.color + ", " + p.weight + " lbs, " + p.age + " years old, "
                                                + p.gender + ", "
                                                + (p.neutered ? "neutered, " : "not neutered, ")
                                                + (p.vaccinationsUpToDate ? "vaccines are up to date, " : "vaccines not up to date, ")
                                                + (p.declawed ? "declawed, " : "not declawed, ") + p.petLocation + ", "
                                                + p.description}
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </Section>
                <Section label="Vehicles">
                    <div className={"table-responsive"}>
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">Type</th>
                                <th scope="col">Make</th>
                                <th scope="col">Model</th>
                                <th scope="col">Year</th>
                                <th scope="col">Color</th>
                                <th scope="col">License plate number</th>
                                <th scope="col">Registered State</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                p.application.vehicles?.map((v, index) => (
                                    <tr key={"veh_" + index}>
                                        <td>{v.type}</td>
                                        <td>{v.make}</td>
                                        <td>{v.model}</td>
                                        <td>{v.year}</td>
                                        <td>{v.color}</td>
                                        <td>{v.licensePlate}</td>
                                        <td>{v.registeredState}</td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </Section>
                <Section label="Emergency Contact">
                    <div className="col-lg-8 mt-2">
                        <div className="row">
                            <div className={"col-md-6 mb-2"}>
                                <label htmlFor="name">Name</label>
                                <input type="text" className="form-control" id="name"
                                       disabled={true}
                                       value={emergencyContact.name}/>
                            </div>
                            <div className={"col-md-6 mb-2"}>
                                <label htmlFor="email">Email</label>
                                <input type="email" className="form-control" id="email"
                                       disabled={true}
                                       value={emergencyContact.email}/>
                            </div>
                            <div className={"col-md-6 mb-2"}>
                                <label htmlFor="phone">Phone</label>
                                <input type="tel" className="form-control" id="phone"
                                       disabled={true}
                                       value={emergencyContact.phone}/>
                            </div>
                        </div>
                    </div>
                </Section>
            </div>
        </>
    );
};

export default ViewApplication;