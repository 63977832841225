import * as React from 'react';
import Table from "../../../Table";
import {useCallback, useEffect, useState} from "react";
import {EmailRegex, formInputValidate, LeaseUser} from "../../../../types";
import {useParams} from "react-router-dom";
import Input from "../../../Form/Input";
import {addUserToLease, deleteUserOnLease, getUsersOnLease} from "../../../../Api/LeaseApi";
import Card from "../../../Card";
import {resendUserInvite} from "../../../../Api/PortfolioApi";

const Users: React.FunctionComponent = (p) => {
    const {leaseId} = useParams();
    const [users, setUsers] = useState<LeaseUser[]>([]);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [selectedResendInvite, setSelectedResendInvite] = useState("");

    const doAdd = () => {
        setMessage("");
        if (email.trim() !== "") {
            addUserToLease(leaseId, email)
                .then((u) => {
                    setEmail("");
                    load();
                })
                .catch((e) => {
                    console.error(e);
                    setMessage(e.message);
                });
        }
    }

    useEffect(() => {
        if(selectedResendInvite) {
            resendUserInvite(selectedResendInvite)
                .then((r) => {
                })
                .catch(e => setMessage(e.message))
                .finally(() => setSelectedResendInvite(""));
        }
    }, [selectedResendInvite]);

    function getSpinner(id: string) {
        if(id === selectedResendInvite) {
            return <>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span className="visually-hidden" role="status">Loading...</span>
            </>
        }
        return <></>;
    }

    function getResendInviteButton(id?: string, activated?: boolean) {
        if(id && !activated) {
            return <button className={"btn btn-primary me-3"} type={"button"} onClick={(e) => {
                e.preventDefault();
                setSelectedResendInvite(id);
            }}>
                {getSpinner(id)}
                Resend Invite
            </button>
        }
    }

    const doDelete = (userId?: string) => {
        if(userId) {
            deleteUserOnLease(leaseId, userId)
                .then((r) => {
                    if (r.ok) {
                        load();
                    }
                })
                .catch((e) => {
                    console.error(e);
                    setMessage(e.message);
                })
        }
    }

    const load = useCallback(() => {
        getUsersOnLease(leaseId)
            .then((uz) => {
                setUsers(uz);
            })
            .catch((e) => console.error(e))
    }, [leaseId])

    useEffect(() => {
        load();
    }, [load])
    return (<>
        <Card title="Users">
            <div className="row">
                <div className="col-lg-6">
                    <Input id={"email"} validation={EmailRegex.expression} doValidate={formInputValidate}
                           value={email} setValue={setEmail} required={true}
                           invalidFeedbackText={EmailRegex.helpText} type={"email"} label={"Email"}/>
                </div>
                <div className="col-lg-6">
                    <button type={"button"} className={"btn btn-primary"} style={{marginTop: 20}}
                            onClick={(e) => {
                                doAdd();
                            }}>Add
                    </button>
                </div>
                <div hidden={!message} className="col-md-12">
                    <div className="alert alert-danger mt-1" role="alert">
                        {message}
                    </div>
                </div>
                <div className="col-md-12">
                    <Table headers={["Email", "First Name", "Last Name", ""]} defaultPaginationSize={10}>
                        {
                            users.map((u) => (
                                <tr key={u.uuid}>
                                    <td>{u.email}{!u.activated ? <em> (pending invite)</em> : <></>}</td>
                                    <td>{u.firstName}</td>
                                    <td>{u.lastName}</td>
                                    <td>
                                        {getResendInviteButton(u.uuid, u.activated)}
                                        <button className={"btn btn-danger me-3"} onClick={(e) => {
                                            e.preventDefault();
                                            doDelete(u.uuid)
                                        }}>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </Table>
                </div>
            </div>
        </Card>
    </>);
};

export default Users;
