import * as React from 'react';
import { FormEvent, useEffect, useState } from 'react';
import {
    Account,
    DayOfMonthRegex,
    DollarAmountRegex,
    formInputValidate,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex,
    RecurringTransaction,
    Transaction,
    TransactionAccount
} from "../../../../types";
import { useParams } from "react-router-dom";
import Select from "../../../Form/Select";
import Input from "../../../Form/Input";
import moment from "moment";
import Table from "../../../Table";
import Alert, { AlertType } from "../../../Alert";
import Card from "../../../Card";

type RecurringTransactionsProps = {
    back: Function,
    next: Function,
    dueDate: string,
    startDate: string,
    endDate: string,
    isMonthToMonth: boolean,
    transactions: RecurringTransaction[],
    recurringStartDate: string,
    accounts: TransactionAccount[]
}

const RecurringTransactions: React.FunctionComponent<RecurringTransactionsProps> = (p) => {
    const { applicationId } = useParams();
    const [recurringStartDate, setRecurringStartDate] = useState("");
    const [transactionDescription, setTransactionDescription] = useState("");
    const [transactionAmount, setTransactionAmount] = useState("");
    const [account, setAccount] = useState("");
    const [startDates, setStartDates] = useState<string[]>([]);
    const [transactions, setTransactions] = useState<RecurringTransaction[]>([]);
    const [startDate, setStartDate] = useState("");
    const [stopDate, setStopDate] = useState("");
    const [dayOfMonth, setDayOfMonth] = useState("");
    const [isMonthToMonth, setIsMonthToMonth] = useState<boolean>(false);
    const [endDateRequired, setEndDateRequired] = useState<boolean>(true);


    const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(AlertType.SUCCESS);

    useEffect(() => {
        setTransactions(p.transactions);
    }, [p.transactions]);

    useEffect(() => {
        if (p.startDate && p.endDate && p.dueDate !== undefined
            && !p.isMonthToMonth) {
            setStartDate(p.startDate);
            setStopDate(p.endDate);
            setDayOfMonth(getDayOfMonthForRecurringTransactions(Number(p.dueDate)) + "");
            setIsMonthToMonth(p.isMonthToMonth);
        }

        if (p.startDate && p.dueDate !== undefined && p.isMonthToMonth) {
            setStartDate(p.startDate);
            setDayOfMonth(getDayOfMonthForRecurringTransactions(Number(p.dueDate)) + "");
            setIsMonthToMonth(p.isMonthToMonth);
        }


    }, [p.startDate, p.endDate, p.dueDate, p.isMonthToMonth])

    function validateRecurringTransaction(transaction: RecurringTransaction): boolean {
        let valid = true;
        if (transaction.endDate && transaction.startDate > transaction.endDate) {
            doShowAlert("Start date must be before stop date.", AlertType.DANGER)
            valid = false;
        }
        if (parseFloat(transaction.dayOfMonth) > 28) {
            doShowAlert("Day of month cannot be greater than 29.", AlertType.DANGER);
            valid = false;
        }
        if (parseFloat(p.dueDate) > parseFloat(transaction.dayOfMonth)
            && (parseFloat(p.dueDate) - parseFloat(transaction.dayOfMonth) < 7)
            || ((parseFloat(p.dueDate) < parseFloat(transaction.dayOfMonth))
                && (parseFloat(p.dueDate) + 29 - parseFloat(transaction.dayOfMonth)) < 7)
            || parseFloat(p.dueDate) == parseFloat(transaction.dayOfMonth)) {
            doShowAlert("The day of month must be at lease 7 days before the due date.", AlertType.DANGER)
            valid = false;
        }
        return valid;
    }

    const onSubmit = (e: FormEvent) => {
        doHideAlert();
        e.preventDefault();
        let newTransaction: RecurringTransaction =
        {
            amount: Number(transactionAmount),
            description: transactionDescription,
            accountUuid: account,
            startDate: startDate,
            endDate: stopDate,
            dayOfMonth: dayOfMonth
        }

        if (validateRecurringTransaction(newTransaction)) {
            transactions.push(newTransaction);
            setTransactions([...transactions]);
            clearForm();
        }

    }



    const getAccountName = (accountId: string) => {
        let filtered = p.accounts.filter((a) => a.uuid === accountId);
        if (filtered) {
            return filtered[0].name;
        }
        return "";
    }

    const clearForm = () => {
        setAccount("");
        setTransactionDescription("");
        setTransactionAmount("");
        setStartDate(p.startDate);
        setStopDate(p.endDate);
        setDayOfMonth(getDayOfMonthForRecurringTransactions(Number(p.dueDate)) + "");
    }

    function getDayOfMonthForRecurringTransactions(dueDate: number) {
        if (dueDate - 7 < 1) {
            return 28 + (dueDate - 7);
        } else {
            return dueDate - 7;
        }
    }

    const getTotal = (): number => {
        let total = 0;
        if (!transactions) {
            return total;
        }
        transactions.forEach(t => {
            let accountName = getAccountName(t.accountUuid);
            if (accountName.toLowerCase().indexOf("credit") >= 0) {
                total -= t.amount;
            } else {
                total += t.amount;
            }
        })
        return total;
    }

    const doDelete = (obj: RecurringTransaction) => {
        let spliced = transactions.filter(i => i !== obj);
        setTransactions([...spliced]);
    }

    const doShowAlert = (message: string, type: AlertType) => {
        setShowAlert(true);
        setAlertMessage(message);
        setAlertType(type);
    }

    const doHideAlert = () => {
        setShowAlert(false);
    }

    return (<>
        <Card title="Recurring Transactions">
            <div className="d-flex justify-content-between gap-5">
                <div>
                    <h5>Recurring Amount Due: {currencyFormat.format(getTotal())}</h5>
                    <p>Recurring amount due each month</p>
                </div>
            </div>
            <hr />
            <div>
                <Alert show={showAlert} type={alertType} message={alertMessage}></Alert>
            </div>
            <div className="row">
                <div className="col-lg-3">
                    <div className="row">
                        <form onSubmit={onSubmit}>
                            <div className="col-md-12">
                                <Input id={"description"}
                                    type={"text"}
                                    label={"Description"}
                                    required={true}
                                    invalidFeedbackText={GenericInputRegex.helpText}
                                    validation={GenericInputRegex.expression}
                                    doValidate={formInputValidate}
                                    value={transactionDescription}
                                    setValue={setTransactionDescription}
                                />
                            </div>
                            <div className="col-md-12">
                                <Select id={"account"}
                                    label={"Account"}
                                    required={true}
                                    invalidFeedbackText={GenericSelectRegex.helpText}
                                    validation={GenericSelectRegex.expression}
                                    doValidate={formInputValidate}
                                    value={account}
                                    setValue={setAccount}
                                >
                                    <option value={""}>Select a transaction type...</option>
                                    {
                                        p.accounts.map((a) => (
                                            <option key={a.uuid} value={a.uuid}>{a.name}</option>
                                        ))
                                    }

                                </Select>
                            </div>
                            <div className="col-md-12">
                                <Input id={"transactionAmount"}
                                    type={"number"}
                                    label={"Amount"}
                                    required={true}
                                    invalidFeedbackText={DollarAmountRegex.helpText}
                                    validation={DollarAmountRegex.expression}
                                    doValidate={formInputValidate}
                                    value={transactionAmount}
                                    setValue={setTransactionAmount}
                                />
                            </div>
                            <div className="col-md-12">
                                <Input id={"startDate"}
                                    type={"date"}
                                    label={"Start Date"}
                                    required={true}
                                    invalidFeedbackText={GenericInputRegex.helpText}
                                    validation={GenericInputRegex.expression}
                                    doValidate={formInputValidate}
                                    value={startDate}
                                    setValue={setStartDate}
                                />
                            </div>
                            <div className={"col-md-12"}>
                                <div className="form-check" style={{ marginTop: 28 }}>
                                    <input className="form-check-input" type="checkbox"
                                        id="defaultCheck1"
                                        checked={!endDateRequired.valueOf()}
                                        disabled={false}
                                        value={String(endDateRequired)}
                                        onChange={e => {
                                            let value = e.target.checked;
                                            setEndDateRequired(!endDateRequired);
                                            if (value) {
                                                setStopDate("");
                                            }
                                        }} />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        No Stop Date
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <Input id={"stopDate"}
                                    type={"date"}
                                    label={"Stop Date"}
                                    required={false}
                                    disabled={!endDateRequired}
                                    invalidFeedbackText={GenericInputRegex.helpText}
                                    validation={GenericInputRegex.expression}
                                    doValidate={formInputValidate}
                                    value={stopDate}
                                    setValue={setStopDate}
                                />
                            </div>
                            <div className="col-md-12">
                                <Input id={"dayOfMonth"}
                                    type={"number"}
                                    label={"Day of Month"}
                                    required={true}
                                    invalidFeedbackText={DayOfMonthRegex.helpText}
                                    validation={DayOfMonthRegex.expression}
                                    doValidate={formInputValidate}
                                    value={dayOfMonth}
                                    setValue={setDayOfMonth}
                                />
                                <p>The transaction should occur at least 7 days before the lease due date.</p>

                            </div>
                            <div className="col-md-12 mt-3">
                                <button type={"submit"} className={"btn btn-primary"}>Add Transaction</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-lg-9">
                    <Table headers={["Description", "Type", "Amount", "Start Date", "Stop Date", "Day of Month", ""]} defaultPaginationSize={10}>
                        {transactions.map((t, index) => (
                            <tr key={index + "_tran"}>
                                <td>
                                    {t.description}
                                </td>
                                <td>
                                    {getAccountName(t.accountUuid)}
                                </td>
                                <td>
                                    {currencyFormat.format(t.amount)}
                                </td>
                                <td>
                                    {moment(t.startDate).format("MMM Do, YYYY")}
                                </td>
                                <td>
                                    {t.endDate ? moment(t.endDate).format("MMM Do, YYYY") : ""}
                                </td>
                                <td>
                                    {t.dayOfMonth}
                                </td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                        fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16"
                                        onClick={() => {
                                            doDelete(t);
                                        }}>
                                        <path
                                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path
                                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                    </svg>
                                </td>
                            </tr>
                        ))}
                    </Table>
                </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between gap-2 mt-3">
                <button type={"button"} className={"btn btn-secondary"}
                    onClick={() => {
                        p.back();
                    }}>
                    Back
                </button>
                <button type={"submit"} className={"btn btn-primary"}
                    onClick={() => {
                        p.next(transactions, recurringStartDate);
                    }}>
                    Next
                </button>
            </div>
        </Card>
    </>);
};

export default RecurringTransactions;