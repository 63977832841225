import * as React from "react";
import {FormEvent, useContext, useEffect, useState} from "react";
import {useParams} from "react-router";
import {
    Conversation, createConversationMessage, getConversationMessages,
    getConversations, Message,
} from "../../../Api/ConversationApi";
import {ARAccount} from "../../../types";
import {getARAccounts} from "../../../Api/AccountApi";
import Alert, {AlertType} from "../../Alert";
import moment from "moment";
import ConversationCustomTable from "../../Table/ConversationsCustomTable/ConversationCustomTable";
import AddConversation from "../Add/AddConversation";
import MessagesCustomTable from "../../Table/MessagesCustomTable/MessagesCustomTable";
import FormFieldBuilder, {assignFormFieldValues} from "../../Form/FieldBuilder/FormFieldBuilder";
import CreateConversationMessageForm from "../Forms/CreateConversationMessageForm";
import {getAppUserFullName} from "../../../helpers";
import PageLayout from "../../../Layouts/PageLayout";
import Card from "../../Card";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";

const ViewConversations: React.FunctionComponent = () => {

    const propertyId = useContext(PropertyIdContext)
    const { conversationId } = useParams();

    const [conversationsLoading, setConversationsLoading] = useState<boolean>(false);
    const [messagesLoading, setMessagesLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    const [accounts, setAccounts] = useState<ARAccount[]>([]);
    const [conversations, setConversations] = useState<Conversation[]>([]);
    const [newConversationSelected, setNewConversationSelected] = useState<boolean>(false);

    const [selectedConversation, setSelectedConversation] = useState<Conversation>();
    const [messages, setMessages] = useState<Message[]>([]);

    const [messageBody, setMessageBody] = useState<string>("");
    const [isValid, setIsValid] = useState<boolean>(false);

    assignFormFieldValues({field: CreateConversationMessageForm.message, value: messageBody, setValue: setMessageBody});

    const ifTodayGetTimeSince = (time: string): string => {
        const momentTime = moment(time);
        return moment().date() === momentTime.date() ? momentTime.fromNow() : momentTime.format("MMM Do, YYYY");
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (isValid && selectedConversation) {
            createConversationMessage(propertyId, selectedConversation.uuid, {
                body: messageBody
            })
                .then(() => {
                    setMessageBody("");
                    setRefresh(!refresh);
                })
                .catch(setError)
        }
    }

    useEffect(() => {
        const messageBodyIsValid = messageBody !== "";
        setIsValid(messageBodyIsValid);
    }, [messageBody])

     useEffect(() => {
        if (selectedConversation) {
            setMessagesLoading(true);
            getConversationMessages(propertyId, selectedConversation.uuid)
                .then(setMessages)
                .catch(setError)
                .finally(() => setMessagesLoading(false));
        }
    }, [propertyId, selectedConversation, refresh])

    useEffect(() => {
        if (conversationId && conversations) {
            setSelectedConversation(conversations.find(c => { return c.uuid === conversationId }))
        }
    }, [conversationId, conversations])

    useEffect(() => {
        if(propertyId) {
            getConversations(propertyId)
                .then(setConversations)
                .catch(setError);
        }
    }, [propertyId, refresh])

    useEffect(() => {
        if(propertyId) {
            setConversationsLoading(true);
            Promise.all([
                getARAccounts(propertyId).then(data => data),
                getConversations(propertyId).then(data => data),
            ])
                .then(values => {
                    setAccounts(values[0]);
                    setConversations(values[1]);
                })
                .catch(setError)
                .finally(() => setConversationsLoading(false));
        }
        }, [propertyId])

    return <>
        {newConversationSelected && <AddConversation accounts={accounts} doCancel={() => setNewConversationSelected(false)} doSubmit={() => { setNewConversationSelected(false); setRefresh(!refresh);}} />}
        <PageLayout title="Tenant Messages" propertyId={propertyId}>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        {error && <Alert type={AlertType.DANGER} message={error.message} />}
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-xl-3">
                            <Card title="Conversations">
                                {!conversationsLoading &&
                                    <ConversationCustomTable>
                                        {conversations.map(conversation => (
                                            <button
                                                key={conversation.uuid}
                                                className={selectedConversation?.uuid === conversation.uuid ? "list-group-item list-group-item-action position-relative active" : "list-group-item list-group-item-action position-relative"}
                                                onClick={() => setSelectedConversation(conversation)}
                                            >
                                                {!conversation.lastMessage.isRead && <span className="position-absolute top-0 start-0 translate-middle p-2 bg-danger rounded-circle"></span>}
                                                <div className="d-flex w-100 justify-content-between">
                                                    <h5 className="mb-1">{accounts.find(account => {return account.uuid === conversation.accountId})?.unitName}</h5>
                                                    <small>{moment(conversation.lastMessage.createdAt).fromNow()}</small>
                                                </div>
                                                <div className="d-flex w-100 justify-content-between">
                                                    <p className="mb-1">{conversation.subject}</p>
                                                    <small><span className="badge rounded-pill bg-secondary">{conversation.messageCount}</span></small>
                                                </div>
                                                <small className={selectedConversation?.uuid === conversation.uuid ? "" : "text-muted"}>{conversation.lastMessage.body.substring(0, 64)}{conversation.lastMessage.body.length > 64 ? "..." : ""}</small>
                                            </button>
                                        ))}
                                        {conversations.length === 0 &&
                                            <small><em>There are no current conversations...</em></small>
                                        }
                                    </ConversationCustomTable>
                                }
                                {conversationsLoading &&
                                    <div className="list-group shadow-sm">
                                        {Array.from({length: 10}, (_, i) => {
                                            return (
                                                <div key={i} className="list-group-item">
                                                    <div className="d-flex w-100 justify-content-between placeholder-glow">
                                                        <h5 className="mb-1 placeholder col-2"></h5>
                                                        <p className="placeholder placeholder-sm col-2"></p>
                                                    </div>
                                                    <div className="d-flex w-100 justify-content-between placeholder-glow pt-1">
                                                        <p className="placeholder placeholder-sm col-1"></p>
                                                        <p className="placeholder placeholder-sm col-1"></p>
                                                    </div>

                                                    <p className="placeholder-glow mt-0 mb-0"><span className="placeholder placeholder-sm col-2"></span></p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                                <button type="button" className="btn btn-primary" onClick={() => setNewConversationSelected(true)}>New Conversation</button>
                            </Card>
                        </div>
                        <div className="col-xl-9 col-md-8">
                            <Card title="Messages">
                                {selectedConversation && <>
                                    <div className="row p-2">
                                        <div className="col-12">
                                            <div className="row border-bottom">
                                                <div className="col">
                                                    <label><small><em>Unit</em></small></label>
                                                    <h6>{accounts.find(account => {return account.uuid === selectedConversation.accountId})?.unitName}</h6>
                                                </div>
                                                <div className="col">
                                                    <label><small><em>Subject</em></small></label>
                                                    <h6>{selectedConversation.subject}</h6>
                                                </div>
                                                <div className="col">
                                                    <label><small><em>Created</em></small></label>
                                                    <h6>{moment(selectedConversation.createdAt).format("MMM Do, YYYY")}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 p-3 pb-0">
                                            {!messagesLoading && <>
                                                <MessagesCustomTable>
                                                    {messages.map((message, i) => (<React.Fragment key={message.uuid}>
                                                        {message.sentByUser && <>
                                                                <div className="row justify-content-end mt-1">
                                                                    <div className="col-12 w-auto">
                                                                        <div className="d-flex w-100 justify-content-end">
                                                                            <small className="text-muted pe-1"><em>{moment(message.createdAt).format("hh:mm a")}</em></small>
                                                                        </div>
                                                                        <div className="list-group-item ms-5 p-1 rounded bg-primary bg-gradient text-white shadow-sm">
                                                                            <div className="m-1 overflow-scroll">{message.body}</div>
                                                                        </div>
                                                                        <div className="d-flex w-100 justify-content-end mt-1">
                                                                            <small className="text-muted pe-1"><em>{ifTodayGetTimeSince(message.createdAt)}</em></small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>}
                                                        {!message.sentByUser && <>
                                                                <div className="row mt-1">
                                                                    <div className="col-12 w-auto">
                                                                        <div className="d-flex w-100 justify-content-between">
                                                                            <small className="text-muted ps-1"><em>{moment(message.createdAt).format("hh:mm a")}</em></small>
                                                                        </div>
                                                                        <div className="list-group-item me-5 p-1 rounded bg-light bg-gradient shadow-sm w-auto">
                                                                            <small className="text-muted m-1"><em>{getAppUserFullName(message.sender)}</em></small>
                                                                            <div className="m-1 overflow-scroll">{message.body}</div>
                                                                        </div>
                                                                        <div className="d-flex w-100 justify-content-start mt-1">
                                                                            <small className="text-muted ps-1"><em>{ifTodayGetTimeSince(message.createdAt)}</em></small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>}
                                                    </React.Fragment>))}
                                                </MessagesCustomTable>
                                                <div className="col-12 pt-3 pb-3" style={{borderTop: "1px solid darkgrey"}}>
                                                    <div className="row p-1">
                                                        <form onSubmit={onSubmit}>
                                                            <div className="input-group shadow-sm">
                                                                <FormFieldBuilder field={CreateConversationMessageForm.message} />
                                                                <button className="btn btn-primary" type="submit" disabled={!isValid}>Send</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </>}
                                            {messagesLoading &&
                                                <div className="row p-5 pt-4">
                                                    <div className="col-12 p-5">
                                                        <div className="d-flex w-100 justify-content-center">
                                                            <div className="spinner-grow text-primary"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>}
                                {!selectedConversation &&
                                    <small><em>Please select a conversation to view messages...</em></small>
                                }
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    </>}

export default ViewConversations;
