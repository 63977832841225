import { AUTH_CODE_EXPIRED_TOKEN, currentSession, doLogoutRedirect } from "../Auth";
import { HttpMethod, fetchWithAuth, handleNetworkError, handleResponse } from "../api";
import { getPortfolioId } from "../helpers";
import { EsignTemplate, Document, Profile, Template } from "../types";

interface CreateDocumentBody {
    transactionId: string,
    templateId: string
}

var ESIGN_API_ENDPOINT: string;
export var ESIGN_UI_ENDPOINT: string;

if (window.location.hostname === "localhost" && parseInt(window.location.port) >= 3000 && parseInt(window.location.port) < 4000) {
    ESIGN_UI_ENDPOINT = (window.location.protocol + '//' + window.location.hostname + ":3000/");
    ESIGN_API_ENDPOINT = (window.location.protocol + '//' + window.location.hostname + ":8088/esign/api");

} else if (window.location.hostname === "localhost" && parseInt(window.location.port) >= 4000 && parseInt(window.location.port) < 5000) {
    ESIGN_UI_ENDPOINT = (window.location.protocol + '//' + window.location.hostname + ":4007/");
    ESIGN_API_ENDPOINT = (window.location.protocol + '//' + window.location.hostname + ":4007/esign/api");
} else {
    ESIGN_UI_ENDPOINT = "https://esign.propertymq.com/";
    ESIGN_API_ENDPOINT = "https://esign.propertymq.com/esign/api";
}

type getPropertyTemplates = (propertyId: string, filter?: string) => Promise<Template[]>;
type createDocumentFromTemplate = (propertyId: string, body: CreateDocumentBody) => Promise<Document>;
type GetDocuments = (applicationId: string, propertyId: string) => Promise<Document[]>;
type ProfileId = (propertyId: string) => Promise<Profile>;
type DownloadFile = (documentId: string, documentName: string) => Promise<void>;


const pathBuilder = (propertyId: string, extension = ""): string => {
    if (extension == "") {
        return "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/esign";
    } else {
        return "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/esign/" + extension;
    }
};

export const getProfileId: ProfileId = (async propertyId => {
    const path = pathBuilder(propertyId, "profiles");
    return fetchWithAuth(path, HttpMethod.GET);
})

export const getPropertyTemplates: getPropertyTemplates = (async (propertyId, filter) => {
    let path;
    if (filter) {
        path = pathBuilder(propertyId, "templates?filter=" + filter);
    } else {
        path = pathBuilder(propertyId, "templates");
    }
    return fetchWithAuth(path, HttpMethod.GET);
});

export const createDocumentFromTemplate: createDocumentFromTemplate = (async (propertyId, body) => {
    const path = pathBuilder(propertyId, "createDocumentOnApplication");
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const getDocuments: GetDocuments = (async (applicationId, propertyId) => {
    const path = pathBuilder(propertyId, applicationId + "/documents");
    return fetchWithAuth(path, HttpMethod.GET);
});

export const downloadDocumentByUUID: DownloadFile = (async (documentId, documentName) => {
    const path = "/render/documents/" + documentId;
    return fetchWithAuthFromEsignApi(path, HttpMethod.GET, undefined)
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "";
            if (documentName) {
                a.download = documentName;
            }
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();
        });
});

const fetchWithAuthFromEsignApi = (path: string, method: HttpMethod, payload?: string) => {
    return currentSession()
        .then((o) => {
            return fetch(ESIGN_API_ENDPOINT + path, {
                mode: 'cors',
                credentials: 'include',
                headers: {
                    Authorization: 'Bearer ' + o.access_token,
                    'Content-Type': 'application/json',
                    Pragma: 'no-cache'
                },
                method: method,
                body: payload
            })
                .then(handleResponse, handleNetworkError)

        })
        .catch((e) => {
            if (e.code === AUTH_CODE_EXPIRED_TOKEN) {
                doLogoutRedirect();
            }
            throw e;
        })
}
