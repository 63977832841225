import {FormBuilderField, FormFieldType} from "../../../../Components/Form/FieldBuilder/FormFieldBuilder";
import {AreaCodeRegex, formInputValidate, formSelectValidate} from "../../../../types";


interface PhoneNumberSearchForm {
    areaCode: FormBuilderField
}

const PhoneNumberSearchForm: PhoneNumberSearchForm = {
    areaCode: {
        type: FormFieldType.INPUT,
        element: {
            id: 'areaCode',
            required: true,
            validate: formSelectValidate,
            regex: AreaCodeRegex,
        },
        label: 'Please enter a Valid U.S. Area Code',
        value: undefined,
        setValue: () => {}
    }
}

export default PhoneNumberSearchForm;