import * as React from 'react';

export type AlertProps = {
    type: AlertType,
    message: string,
    show?: boolean
}

export interface AlertInterface {
    type: AlertType
    message: string
}

export enum AlertType {
    SUCCESS = "success",
    DANGER = "danger",
    INFO = "info",
    WARNING = "warning",
    SECONDARY = "secondary",
    PRIMARY = "primary",
}

const Alert: React.FunctionComponent<AlertProps> = ({type, message, show = true}) => {
    return (<>
        <div hidden={!show} className={"w-100 alert alert-dismissible alert-" + type.valueOf()} role="alert">
            {message}
            <button type="button" className="btn-close pt-1" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    </>);
};

export default Alert;
