import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {
    FormElementValidation,
    formInputValidate,
    formSelectValidate,
    GenericInputRegex,
    GenericNumberRegex,
    GenericSelectRegex,
    Property,
    PropertyNameRegex
} from "../../../types";
import {useHistory, useLocation, useParams} from "react-router";
import Input from "../../Form/Input";
import TextArea from "../../Form/TextArea";
import Alert, {AlertProps, AlertType} from "../../Alert";
import {Link} from "react-router-dom";
import LoadingOverlay from "../../LoadingOverlay";
import Select from "../../Form/Select";
import {getProperty} from "../../../Api/PropertyApi";
import {addFloorPlan} from "../../../Api/FloorPlanApi";
import PageLayout from "../../../Layouts/PageLayout";
import Card from "../../Card";
import ImageCard from "../../ImageCard";

const AddFloorPlan: React.FunctionComponent = () => {

    const history = useHistory()
    const { pathname } = useLocation()

    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [property, setProperty] = useState<Property>();
    const {propertyUuid, floorPlanUuid} = useParams();

    const [alert, setAlert] = useState<AlertProps>()

    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [bed, setBed] = useState("");
    const [bath, setBath] = useState("");
    const [sqft, setSqft] = useState("");
    const [deposit, setDeposit] = useState("");
    const [description, setDescription] = useState("");
    const [imageUrl, setImageUrl] = useState<string>();
    const [occupancy, setOccupancy] = useState("");

    const [isValid, setIsValid] = useState<boolean>(false)

    useEffect(() => {
        setIsValid(
            name !== "" &&
            type  !== "" &&
            ((type !== "multifamily" && type !== "residential") || bed !== "") &&
            ((type !== "multifamily" && type !== "residential") || bath !== "") &&
            sqft !== "" &&
            deposit !== "" &&
            description !== "" &&
            occupancy !== ""
        )
    }, [name, type, bed, bath, sqft, deposit, description, imageUrl, occupancy])

    type Form = {
        name: FormElementValidation,
        description: FormElementValidation,
        bed: FormElementValidation,
        bath: FormElementValidation,
        sqft: FormElementValidation,
        type: FormElementValidation,
        deposit: FormElementValidation,
        occupancy: FormElementValidation
    }

    const form: Form = {
        name: {
            id: "name",
            required: true,
            validate: formInputValidate,
            regex: PropertyNameRegex
        },
        description: {
            id: "description",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        bed: {
            id: "bed",
            required: true,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        bath: {
            id: "bath",
            required: true,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        sqft: {
            id: "sqft",
            required: true,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        occupancy: {
            id: "occupancy",
            required: true,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        deposit: {
            id: "deposit",
            required: true,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        type: {
            id: "type",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        }
    };

    useEffect(() => {

        setLoading(true);
        getProperty(propertyUuid)
            .then(data => {
                setProperty(data);
            })
            .finally(() => setLoading(false));

    }, [propertyUuid]);

    const doShowAlert = (message: string, type: AlertType) => {
        setAlert({type, message})
    }

    const validateForm = (form: Form): boolean => {
        if (!property) {
            doShowAlert("Failed to add floor plan.  Could not retrieve property details", AlertType.DANGER);
            return false;
        }
        if (!form.name.validate(name, form.name.required, form.name.regex.expression)) {
            doShowAlert("Name invalid.", AlertType.DANGER);
            return false;
        }
        if (!form.description.validate(description, form.description.required, form.description.regex.expression)) {
            doShowAlert("Description invalid.", AlertType.DANGER);
            return false;
        }
        return true;

    }

    const onSubmit = (e: FormEvent) => {
        console.log("Submitting Form")
        e.preventDefault();
        if (!validateForm(form)) {
            return;
        }
        setLoading(true);
        addFloorPlan(propertyUuid, floorPlanUuid, {
            name: name,
            description: description,
            bed: (bed === "") ? "0" : bed,
            bath: (bath === "") ? "0" : bath,
            sqft: sqft,
            type: type,
            imageUrl: imageUrl || "",
            deposit: deposit,
            occupancy: occupancy
        }).then((fp) => {
            history.push(pathname.replace("add", `edit/${fp.uuid}?success=true` || "").toString())
            // clearForm();
            // doShowAlert("Successfully added floor plan: " + name, AlertType.SUCCESS);
        }).catch(e => {
            doShowAlert("Failed to add floor plan: " + e.message, AlertType.DANGER);
        }).finally(() => setLoading(false));
    }

    const clearForm = () => {
        setName("");
        setDescription("");
    }

    const encodeImageFileAsURL = (element: EventTarget & HTMLInputElement) => {
        // @ts-ignore
        let file = element.files[0];
        let filesize = ((file.size/1024)/1024);
        if(filesize > 5) {
            setImageUrl("");
            setAlert({type: AlertType.DANGER, message: "Image size is too large. Limit 5mb"})
            return;
        }
        let reader = new FileReader();
        reader.onloadend = function () {
            setImageUrl(reader.result + "");
        }
        reader.readAsDataURL(file);
    }

    return (loading ? <div className="col-md-6"><LoadingOverlay /></div> : <>
        <PageLayout title="Add Floor Plan" property={property} alert={alert}>
            {loading && <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>}
            {errorMessage && <div className="alert alert-danger" role="alert">
                {errorMessage}
            </div>}
            {successMessage && <div className="alert alert-success" role="alert">
                {successMessage}
            </div>}
            <div className="d-flex justify-content-start gap-5">
                <div>
                    <ImageCard
                        imageUrl={imageUrl}
                        setImageUrl={setImageUrl}
                        canUpload={true}
                        onError={m => setAlert({type: AlertType.DANGER, message: m})}
                        required={false}
                    />
                </div>
                <div className="col-lg-6">
                    <Card title="General Information">
                        <div className="row">
                            <div className={"col-lg-6 mb-1"}>
                                <Input id={form.name.id} validation={form.name.regex.expression}
                                       doValidate={form.name.validate}
                                       value={name}
                                       setValue={setName}
                                       required={form.name.required}
                                       invalidFeedbackText={form.name.regex.helpText}
                                       type={"text"} label={"Name"}
                                />
                            </div>
                            <div className={"col-lg-6 mb-1"}>
                                <Select id={form.type.id} validation={form.type.regex.expression}
                                        doValidate={form.type.validate}
                                        value={type}
                                        setValue={setType}
                                        required={form.type.required}
                                        invalidFeedbackText={form.type.regex.helpText}
                                        label={"Type"}
                                >
                                    <option disabled={true} value={""}>Choose a type...</option>
                                    <option value={"retail"}>Retail</option>
                                    <option value={"office"}>Office</option>
                                    <option value={"multifamily"}>Multifamily</option>
                                    <option value={"residential"}>Residential</option>
                                </Select>
                            </div>
                            <div className={"col-lg-6 mb-1"} hidden={type !== "multifamily" && type !== "residential"}>
                                <Input id={form.bed.id} validation={form.bed.regex.expression}
                                       doValidate={form.bed.validate}
                                       value={bed}
                                       setValue={setBed}
                                       required={form.bed.required}
                                       invalidFeedbackText={form.bed.regex.helpText}
                                       type={"text"} label={"Bed"}
                                />
                            </div>
                            <div className={"col-lg-6 mb-1"} hidden={type !== "multifamily" && type !== "residential"}>
                                <Input id={form.bath.id} validation={form.bath.regex.expression}
                                       doValidate={form.bath.validate}
                                       value={bath}
                                       setValue={setBath}
                                       required={form.bath.required}
                                       invalidFeedbackText={form.bath.regex.helpText}
                                       type={"text"} label={"Bath"}
                                />
                            </div>
                            <div className={"col-lg-6 mb-1"}>
                                <Input id={form.sqft.id} validation={form.sqft.regex.expression}
                                       doValidate={form.sqft.validate}
                                       value={sqft}
                                       setValue={setSqft}
                                       required={form.sqft.required}
                                       invalidFeedbackText={form.sqft.regex.helpText}
                                       type={"text"} label={"Square Feet"}
                                />
                            </div>
                            <div className={"col-lg-6 mb-1"}>
                                <Input id={form.occupancy.id} validation={form.occupancy.regex.expression}
                                       doValidate={form.occupancy.validate}
                                       value={occupancy}
                                       setValue={setOccupancy}
                                       required={form.occupancy.required}
                                       invalidFeedbackText={form.occupancy.regex.helpText}
                                       type={"number"} label={"Occupancy"}
                                />
                            </div>
                            <div className={"col-lg-6 mb-1"}>
                                <Input id={form.deposit.id} validation={form.deposit.regex.expression}
                                       doValidate={form.deposit.validate}
                                       value={deposit}
                                       setValue={setDeposit}
                                       required={form.deposit.required}
                                       invalidFeedbackText={form.deposit.regex.helpText}
                                       type={"text"} label={"Deposit"}
                                />
                            </div>
                            <div className={"col-lg-6 mb-1"}>
                                <TextArea id={form.description.id} validation={form.description.regex.expression}
                                          doValidate={form.description.validate}
                                          value={description}
                                          setValue={setDescription}
                                          required={form.description.required}
                                          invalidFeedbackText={form.description.regex.helpText}
                                          rows={3} label={"Description"}
                                />
                            </div>
                        </div>
                    </Card>
                    <div className="d-flex justify-content-start gap-2 mt-3">
                        <Link to={"/p/m/realestate/floorplans/" + propertyUuid}>
                            <button type="button" className="btn btn-secondary"
                                    onClick={() => {
                                    }}>Back
                            </button>
                        </Link>
                        <button type="button" disabled={!isValid} onClick={onSubmit} className="btn btn-primary">Submit</button>
                    </div>
                </div>
            </div>
        </PageLayout>
    </>);
};

export default AddFloorPlan;
