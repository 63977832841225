import * as React from 'react';
import Input from "../Form/Input";
import {
    FormElementValidation,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex, Invoice,
    Property,
    UuidRegex
} from "../../types";
import {FormEvent, useEffect, useState} from "react";
import Select from "../Form/Select";
import {Link} from "react-router-dom";
import LoadingOverlay from "../LoadingOverlay";
import {getPortfolioId} from "../../helpers";
import {getProperties} from "../../Api/PropertyApi";
import {getInvoices} from "../../Api/InvoiceApi";

const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

const dateFormat = require('dateformat');

const InvoicesReport: React.FunctionComponent = () => {

    const [loading, setLoading] = useState(false);
    const [properties, setProperties] = useState<Property[]>([]);
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [runDate, setRunDate] = useState("");

    //form
    const [property, setProperty] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [status, setStatus] = useState("");

    type Form = {
        property: FormElementValidation,
        startDate: FormElementValidation,
        endDate: FormElementValidation,
        status: FormElementValidation
    }

    const form: Form = {
        property: {
            id: "property",
            required: false,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        startDate: {
            id: "startDate",
            required: false,
            validate: formSelectValidate,
            regex: GenericInputRegex
        },
        endDate: {
            id: "endDate",
            required: false,
            validate: formSelectValidate,
            regex: GenericInputRegex
        },
        status: {
            id: "status",
            required: false,
            validate: formSelectValidate,
            regex: GenericInputRegex
        }
    }

    const fetchProperties = () => {
        setLoading(true);
        getProperties({})
            .then((data) => {
                    setProperties(data);
                }
            ).finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchProperties();
    }, []);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        getInvoices(startDate, endDate, property, status)
            .then((data) => {
                setInvoices(data);
                setRunDate(dateFormat(new Date(), "mmmm dS, yyyy, h:MM:ss TT"));
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => setLoading(false));

    }

    return (<>
        <div className="row">
            <div className={"col-md-12 mb-3"}>
                <h3>Invoices Report</h3>
                <h5 hidden={runDate === undefined || runDate === ""}>As of {runDate}</h5>
            </div>
        </div>
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className={"col-md-6 mb-3"}>
                    <Input id={form.startDate.id} validation={form.startDate.regex.expression}
                           doValidate={form.startDate.validate}
                           value={startDate}
                           setValue={setStartDate}
                           required={form.startDate.required}
                           invalidFeedbackText={form.startDate.regex.helpText}
                           type={"date"} label={"Start Date"}
                    />
                </div>
                <div className={"col-md-6 mb-3"}>
                    <Input id={form.endDate.id} validation={form.endDate.regex.expression}
                           doValidate={form.endDate.validate}
                           value={endDate}
                           setValue={setEndDate}
                           required={form.endDate.required}
                           invalidFeedbackText={form.endDate.regex.helpText}
                           type={"date"} label={"End Date"}
                    />
                </div>
                <div className="col-md-6 mb-3">
                    <Select id={form.property.id} validation={form.property.regex.expression}
                            doValidate={form.property.validate}
                            value={property} setValue={setProperty}
                            required={form.property.required}
                            invalidFeedbackText={form.property.regex.helpText} label={"Property"}>
                        <option value={""} disabled={false}>All properties</option>
                        {
                            properties.map((p: Property) => (
                                <option key={p.uuid + "_p"}
                                        value={p.uuid}>{p.name + " - " + p.address.city + ", " + p.address.stateCode}</option>
                            ))
                        }
                    </Select>
                </div>
                <div className="col-md-6 mb-3">
                    <Select id={form.status.id} validation={form.status.regex.expression}
                            doValidate={form.status.validate}
                            value={status} setValue={setStatus}
                            required={form.status.required}
                            invalidFeedbackText={form.status.regex.helpText} label={"Status"}>
                        <option value={""} disabled={false}>All Statuses</option>
                        <option value={"paid"} disabled={false}>Paid</option>
                        <option value={"unpaid"} disabled={false}>Unpaid</option>
                    </Select>
                </div>
            </div>
            <div className="row">
                <div className={"col-md-12 mb-3"}>
                    <button className={"btn btn-primary"}>Search</button>
                </div>
            </div>
        </form>
        <div className={"row"}>
            <div className={"col-md-12 mb-3"}>
                <div className={"table-responsive"}>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">Invoice Date</th>
                            <th scope="col">Due Date</th>
                            <th scope="col">Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Balance</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            invoices.map((i, index) => (
                                <tr key={"leas_" + index}>
                                    <td>{dateFormat(i.invoiceDate, "mmmm dS, yyyy")}</td>
                                    <td>{dateFormat(i.paymentDueDate, "mmmm dS, yyyy")}</td>
                                    <td>{i.billToName}</td>
                                    <td>{i.status}</td>
                                    <td>{currencyFormat.format(i.total)}</td>
                                    <td>
                                        <Link to={"/p/m/customers/invoices/" + i.uuid + "/edit"} className={""}>View</Link>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                    <LoadingOverlay show={loading}/>
                </div>
            </div>
        </div>
    </>);
};

export default InvoicesReport;
