import * as React from 'react';
import {FormEvent, useContext, useEffect, useState} from "react";
import {useLocation, useParams} from "react-router";
import {
    AddressLine1Regex, AddressLine2Regex, CityRegex, FloorPlan,
    FormElementValidation,
    formInputValidate, formSelectValidate, GenericSelectRegex,
    Property,
    PropertyNameRegex,
    Unit, ZipRegex
} from "../../../types";
import LoadingOverlay from "../../LoadingOverlay";
import {Link} from "react-router-dom";
import Input from "../../Form/Input";
import StateSelect from "../../StateSelect";
import Select from "../../Form/Select";
import Table from "../../Table";
import {getProperty} from "../../../Api/PropertyApi";
import {getUnits} from "../../../Api/UnitApi";
import {getFloorPlans} from "../../../Api/FloorPlanApi";
import PageLayout from "../../../Layouts/PageLayout";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";

const ViewUnits: React.FunctionComponent = () => {

    const propertyUuid = useContext(PropertyIdContext)

    const [loading, setLoading] = useState(false);
    const [units, setUnits] = useState(new Array<Unit>());
    const [floorPlans, setFloorPlans] = useState(new Array<FloorPlan>());
    const [property, setProperty] = useState<Property>();
    let {pathname} = useLocation();

    const [unitName, setUnitName] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [floorPlan, setFloorPlan] = useState("");

    type Form = {
        unitName: FormElementValidation,
        addressLine1: FormElementValidation,
        addressLine2: FormElementValidation,
        city: FormElementValidation,
        state: FormElementValidation,
        zip: FormElementValidation,
        floorPlan: FormElementValidation,
    }

    const form: Form = {
        unitName: {
            id: "name",
            required: false,
            validate: formInputValidate,
            regex: PropertyNameRegex
        },
        addressLine1: {
            id: "addressLine1",
            required: false,
            validate: formInputValidate,
            regex: AddressLine1Regex
        },
        addressLine2: {
            id: "addressLine2",
            required: false,
            validate: formInputValidate,
            regex: AddressLine2Regex
        },
        city: {
            id: "city",
            required: false,
            validate: formInputValidate,
            regex: CityRegex
        },
        state: {
            id: "state",
            required: false,
            validate: formInputValidate,
            regex: GenericSelectRegex
        },
        zip: {
            id: "zip",
            required: false,
            validate: formInputValidate,
            regex: ZipRegex
        },
        floorPlan: {
            id: "floorPlan",
            required: false,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        }
    }


    useEffect(() => {
        if (propertyUuid) {
            setLoading(true);
            Promise.all([
                getProperty(propertyUuid),
                getFloorPlans(propertyUuid),
                getUnits(propertyUuid, {})
            ])
                .then(values => {
                    setProperty(values[0]);
                    setFloorPlans(values[1]);
                    setUnits(values[2])
                })
                .finally(() => setLoading(false));
        }
    }, [propertyUuid]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        setLoading(true);
        getUnits(propertyUuid, {
            name: unitName,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            stateCode: state,
            zip: zip,
            floorPlanUuid: floorPlan
        })
            .then(data => setUnits(data))
            .finally(() => setLoading(false));
    }

    return (<>
            {property ?
                <PageLayout title="Units" property={property} addButtonLabel="Add Unit">
                    <div className="col-lg-8 mb-3">
                        <h6>Filters</h6>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-6 mb-2">
                                    <Input id={form.unitName.id} validation={form.unitName.regex.expression}
                                           doValidate={form.unitName.validate}
                                           value={unitName}
                                           setValue={setUnitName}
                                           required={form.unitName.required}
                                           invalidFeedbackText={form.unitName.regex.helpText}
                                           type={"text"} label={"Unit name"}
                                    />
                                </div>
                                <div className={"col-md-6 mb-2"}>
                                    <Select id={form.floorPlan.id} validation={form.floorPlan.regex.expression}
                                            doValidate={form.floorPlan.validate}
                                            value={floorPlan} setValue={setFloorPlan}
                                            required={form.floorPlan.required}
                                            invalidFeedbackText={form.floorPlan.regex.helpText} label={"Floor plan"}>
                                        <option value={""}>All floor plans</option>
                                        {
                                            floorPlans.map((fp, index) => (
                                                <option key={fp.uuid + "_" + index} value={fp.uuid}>{fp.name}</option>
                                            ))
                                        }
                                    </Select>
                                </div>
                                <div className={"col-md-6 mb-2"}>
                                    <Input id={form.addressLine1.id} validation={form.addressLine1.regex.expression}
                                           doValidate={form.addressLine1.validate}
                                           value={addressLine1}
                                           setValue={setAddressLine1}
                                           required={form.addressLine1.required}
                                           invalidFeedbackText={form.addressLine1.regex.helpText}
                                           type={"text"} label={"Address"}
                                    />
                                </div>
                                <div className={"col-md-6 mb-2"}>

                                    <Input id={form.addressLine2.id} validation={form.addressLine2.regex.expression}
                                           doValidate={form.addressLine2.validate}
                                           value={addressLine2}
                                           setValue={setAddressLine2}
                                           required={form.addressLine2.required}
                                           invalidFeedbackText={form.addressLine2.regex.helpText}
                                           type={"text"} label={"Address 2"}
                                    />
                                </div>
                                <div className={"col-md-4 mb-2"}>
                                    <Input id={form.city.id} validation={form.city.regex.expression}
                                           doValidate={form.city.validate}
                                           value={city}
                                           setValue={setCity}
                                           required={form.city.required}
                                           invalidFeedbackText={form.city.regex.helpText}
                                           type={"text"} label={"City"}
                                    />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <StateSelect id={form.state.id} validation={form.state.regex.expression}
                                                 noSelectionText={"All states"}
                                                 doValidate={form.state.validate}
                                                 value={state} setValue={setState}
                                                 required={form.state.required}
                                                 invalidFeedbackText={form.state.regex.helpText}
                                                 label={"State"}/>
                                </div>
                                <div className={"col-md-4 mb-2"}>
                                    <Input id={form.zip.id} validation={form.zip.regex.expression}
                                           doValidate={form.zip.validate}
                                           value={zip}
                                           setValue={setZip}
                                           required={form.zip.required}
                                           invalidFeedbackText={form.zip.regex.helpText}
                                           type={"number"} label={"Zip"}
                                    />
                                </div>

                            </div>
                            <button disabled={loading} type="submit" className="btn btn-primary mt-3">Apply Filters
                            </button>
                        </form>
                    </div>
                    {loading ? <LoadingOverlay/> :
                        <Table headers={["Name", "Floor plan", "Address"]} defaultPaginationSize={10}>
                            {
                                units.map((u, index) => (
                                    <tr key={u.uuid + "_" + index}>
                                        <td>{u.name}</td>
                                        <td>{u.floorPlan.name}</td>
                                        <td>{u.address.addressLine1 + " " + u.address.addressLine2 + " " + u.address.city + "," + u.address.stateCode + " " + u.address.zip}</td>
                                        <td><Link to={pathname + "/edit/" + u.uuid}>
                                            <button className="btn btn-sm btn-primary">Edit</button>
                                        </Link></td>
                                    </tr>
                                ))
                            }
                        </Table>
                    }
                </PageLayout>
                :
                <LoadingOverlay/>
            }
        </>
    );
};

export default ViewUnits;
