import * as React from 'react';
import {useEffect, useState} from 'react';
import FormFieldBuilder, {assignFormFieldValues} from "../Form/FieldBuilder/FormFieldBuilder";
import CreatePortfolioForm from "./CreatePortfolioForm";
import './create-portfolio.css';
import Alert, {AlertType} from "../Alert";
import {createPortfolio} from "../../Api/PortfolioApi";
import {APP_PORTFOLIO_ID, APP_PORTFOLIO_LANDING} from "../../types";
import {useHistory} from "react-router";
import {Link, useLocation} from "react-router-dom";
import {doLogoutRedirect} from "../../Auth";
import BillingInformationCard from "./BillingInformationCard";
import PlanSelectionCard from "./PlanSelectionCard";

const CreatePortfolio: React.FunctionComponent = () => {


    let [doRedirectToLanding, setDoRedirectToLanding] = useState(false);
    const history = useHistory();
    const location = useLocation();

    const [onSubmitLoading, setOnSubmitLoading] = useState(false)

    const [portfolioName, setPortfolioName] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [typeOfBusiness, setTypeOfBusiness] = useState("");
    const [businessStructure, setBusinessStructure] = useState("");
    const [phone, setPhone] = useState("");

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");


    const [paymentSourceId, setPaymentSourceId] = useState("");
    const [planId, setPlanId] = useState("");
    const [planAmount, setPlanAmount] = useState(0);


    const [stage1Error, setStage1Error] = useState("");
    const [stage2Error, setStage2Error] = useState("");
    const [stage3Error, setStage3Error] = useState("");
    const [stage4Error, setStage4Error] = useState("");
    const [stage5Error, setStage5Error] = useState("");

    assignFormFieldValues({field: CreatePortfolioForm.portfolioName, value: portfolioName, setValue: setPortfolioName})
    assignFormFieldValues({field: CreatePortfolioForm.businessName, value: businessName, setValue: setBusinessName})
    assignFormFieldValues({field: CreatePortfolioForm.businessType, value: typeOfBusiness, setValue: setTypeOfBusiness})
    assignFormFieldValues({
        field: CreatePortfolioForm.businessStructure,
        value: businessStructure,
        setValue: setBusinessStructure
    })
    assignFormFieldValues({field: CreatePortfolioForm.phone, value: phone, setValue: setPhone})

    assignFormFieldValues({field: CreatePortfolioForm.firstName, value: firstName, setValue: setFirstName})
    assignFormFieldValues({field: CreatePortfolioForm.lastName, value: lastName, setValue: setLastName})

    assignFormFieldValues({field: CreatePortfolioForm.addressLine1, value: addressLine1, setValue: setAddressLine1})
    assignFormFieldValues({field: CreatePortfolioForm.addressLine2, value: addressLine2, setValue: setAddressLine2})
    assignFormFieldValues({field: CreatePortfolioForm.city, value: city, setValue: setCity})
    assignFormFieldValues({field: CreatePortfolioForm.state, value: state, setValue: setState})
    assignFormFieldValues({field: CreatePortfolioForm.zip, value: zip, setValue: setZip})



    const [stage, setStage] = useState(1);

    const validateStage1 = () => {
        if (!portfolioName || !typeOfBusiness || !phone) {
            return false;
        }
        if (typeOfBusiness !== "individual"
            && (!businessName || !businessStructure)) {
            return false;
        }
        return true;
    }

    const validateStage2 = () => {
        if (!firstName || !lastName) {
            return false;
        }
        return true;
    }

    const validateStage3 = () => {
        if (!addressLine1 || !city || !state || !zip) {
            return false;
        }
        return true;
    }

    const validateStage4 = () => {
        if(!planId) {
            return false;
        }
        return true;
    }

    const validateStage5 = () => {
        if(!paymentSourceId) {
            return false;
        }
        return true;
    }


    const onSubmit = () => {
        setOnSubmitLoading(true)
        createPortfolio({
            name: portfolioName,
            subscriptionPlanName: planId,
            stripePaymentMethodId: paymentSourceId,
            business: {
                name: businessName,
                typeOfBusiness: typeOfBusiness,
                businessStructure: (typeOfBusiness === "individual" ? "individual" : businessStructure),
                phone: phone,
                person: {
                    firstName: firstName,
                    lastName: lastName
                },
                address: {
                    addressLine1: addressLine1,
                    addressLine2: addressLine2,
                    city: city,
                    stateCode: state,
                    zip: zip
                },

            }
        })
            .then((p) => {
                localStorage.setItem(APP_PORTFOLIO_ID, p.uuid);
                setDoRedirectToLanding(true);
            })
            .catch((e: any) => setStage5Error(e.message))
            .finally(() => setOnSubmitLoading(false));
    }


    useEffect(() => {
        if (doRedirectToLanding) {
            let redirectPath = new URLSearchParams(location.search).get("redirect_path");
            setDoRedirectToLanding(false);
            if (redirectPath) {
                history.push({
                    pathname: redirectPath,
                })
            } else {
                history.push({
                    pathname: APP_PORTFOLIO_LANDING,
                })
            }
        }
    }, [doRedirectToLanding, history]);


    return (<>
        <nav className="navbar navbar-dark sticky-top bg-dark flex-md-nowrap shadow">
            <Link className="navbar-brand col-md-3 col-lg-2 mr-0 px-3"
                  to={APP_PORTFOLIO_LANDING}>
                <img width={200} src={"/assets/dist/img/propertymq_logo.png"}/>

            </Link>
            <button className="navbar-toggler position-absolute d-md-none collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <ul className="navbar-nav px-3">
                <li className="nav-item text-nowrap">

                    <button type="button" className="ms-3 btn btn-signup bg-theme-4 border-theme-4"
                            onClick={(e) => {
                                localStorage.clear();
                                doLogoutRedirect();
                            }}>
                        Logout
                    </button>
                </li>
            </ul>
        </nav>
        <div className={"row justify-content-md-center"}>
            <div className={"col-lg-6 mb-3"}>
                <div className={"form-container mt-3 text-center p-4"}>
                    <div hidden={stage !== 1} className={"stage1"}>
                        <div className={"col mb-3"}>
                            <h1 className="h3 mb-3 font-weight-normal">Create a New Portfolio</h1>
                            <p>A portfolio is a collection of properties managed by one entity. Reports and user
                                access
                                controls
                                are managed by the portfolio.
                            </p>

                        </div>
                        <div className={"col mb-3"}>
                            <FormFieldBuilder field={CreatePortfolioForm.portfolioName}/>
                        </div>
                        <div className={"col mb-3"}>
                            <h4>Tell us about your business</h4>
                        </div>
                        <div className={"col mb-3"}>
                            <FormFieldBuilder field={CreatePortfolioForm.businessType}
                                              selectOptions={CreatePortfolioForm.businessType.defaultSelectOptions}/>
                        </div>
                        <div hidden={typeOfBusiness === "individual" || typeOfBusiness === ""}>
                            <div className={"col mb-3"}>
                                <FormFieldBuilder field={CreatePortfolioForm.businessName}/>
                            </div>


                            <div className={"col mb-3"}>
                                <FormFieldBuilder field={CreatePortfolioForm.businessStructure}
                                                  selectOptions={CreatePortfolioForm.businessStructure.defaultSelectOptions}/>
                            </div>

                        </div>
                        <div className={"col mb-3"}>
                            <FormFieldBuilder field={CreatePortfolioForm.phone}/>
                        </div>
                        <div className={"col mb-3 control"}>
                            <button className={"btn btn-primary single"} type={"button"}
                                    onClick={() => {
                                        if (validateStage1()) {
                                            setStage1Error("");
                                            setStage(2)
                                        } else {
                                            setStage1Error("One or more fields missing values.");
                                        }
                                    }}
                            >Next
                            </button>

                            <div hidden={!stage1Error} className={"mt-3"}>
                                <Alert type={AlertType.DANGER} message={stage1Error}/>
                            </div>
                        </div>
                        <div className={"col control"}>
                            <Link to={"/p/s/portfolio-selection"}>
                                <button className={"btn btn-danger single"} type={"button"}
                                >Cancel
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div hidden={stage !== 2} className={"stage2"}>
                        <div className={"col mb-3"}>
                            <h4>Tell us about yourself</h4>
                        </div>
                        <div className={"col mb-3"}>
                            <FormFieldBuilder field={CreatePortfolioForm.firstName}/>
                        </div>
                        <div className={"col mb-3"}>
                            <FormFieldBuilder field={CreatePortfolioForm.lastName}/>
                        </div>
                        <div className={"col mb-3 control"}>
                            <button className={"btn btn-primary previous"} type={"button"}
                                    onClick={() => {
                                        setStage2Error("");
                                        setStage(1)
                                    }}
                            >Back
                            </button>
                            <button className={"btn btn-primary next"} type={"button"}
                                    onClick={() => {
                                        if (validateStage2()) {
                                            setStage2Error("");
                                            setStage(3);
                                        } else {
                                            setStage2Error("One or more fields missing values.");
                                        }
                                    }}
                            >Next
                            </button>
                            <div hidden={!stage2Error} className={"mt-3"}>
                                <Alert type={AlertType.DANGER} message={stage2Error}/>
                            </div>
                        </div>
                        <div className={"col control"} >
                            <Link to={"/p/s/portfolio-selection"}>
                                <button className={"btn btn-danger single"} type={"button"}
                                >Cancel
                                </button>
                            </Link>

                        </div>
                    </div>
                    <div hidden={stage !== 3} className={"stage3"}>
                        <div className={"col mb-3"}>
                            <h4>Where are you located?</h4>
                        </div>
                        <div className={"col mb-3"}>
                            <FormFieldBuilder field={CreatePortfolioForm.addressLine1}/>
                        </div>
                        <div className={"col mb-3"}>
                            <FormFieldBuilder field={CreatePortfolioForm.addressLine2}/>
                        </div>
                        <div className={"col mb-3"}>
                            <FormFieldBuilder field={CreatePortfolioForm.city}/>
                        </div>
                        <div className={"col mb-3"}>
                            <FormFieldBuilder field={CreatePortfolioForm.state}/>
                        </div>
                        <div className={"col mb-3"}>
                            <FormFieldBuilder field={CreatePortfolioForm.zip}/>
                        </div>
                        <div className={"col mb-3 control"}>
                            <button className={"btn btn-primary previous"} type={"button"}
                                    onClick={() => {
                                        setStage3Error("");
                                        setStage(2)
                                    }}
                            >Back
                            </button>
                            <button className={"btn btn-primary next"} type={"button"}
                                    onClick={() => {
                                        if (validateStage3()) {
                                            setStage3Error("");
                                            setStage(4);
                                        } else {
                                            setStage3Error("One or more fields missing values.");
                                        }
                                    }}
                            >Next
                            </button>
                            <div hidden={!stage3Error} className={"mt-3"}>
                                <Alert type={AlertType.DANGER} message={stage3Error}/>
                            </div>

                        </div>
                        <div className={"col control"} >
                            <Link to={"/p/s/portfolio-selection"}>
                                <button className={"btn btn-danger single"} type={"button"}
                                >Cancel
                                </button>
                            </Link>

                        </div>
                    </div>

                    <div hidden={stage !== 4} className={"stage4"}>
                        <PlanSelectionCard
                            setPlanId={(id: string) => setPlanId(id)}
                            setPlanAmount={(amount: number) => setPlanAmount(amount)}
                        />
                        <div className={"row"}>
                            <div className={"col mb-3 control"}>
                                <button className={"btn btn-primary previous"} type={"button"}
                                        onClick={() => {
                                            setStage4Error("");
                                            setStage(3)
                                        }}
                                >Back
                                </button>
                                <button disabled={!validateStage4()} className={"btn btn-primary next"} type={"button"}
                                        onClick={() => {
                                            if (validateStage4()) {
                                                setStage4Error("");
                                                setStage(5);
                                                // onSubmit();
                                            } else {
                                                setStage4Error("One or more fields missing values.");
                                            }
                                        }}
                                >Next
                                </button>

                                <div hidden={!stage4Error} className={"mt-3"}>
                                    <Alert type={AlertType.DANGER} message={stage4Error}/>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col control"} >
                                <Link to={"/p/s/portfolio-selection"}>
                                    <button className={"btn btn-danger single"} type={"button"}
                                    >Cancel
                                    </button>
                                </Link>

                            </div>
                        </div>
                    </div>
                    <div hidden={stage !== 5} className={"stage5"}>
                        <BillingInformationCard
                            amount={planAmount}
                            firstName={firstName}
                            lastName={lastName}
                            setPaymentSourceId={(id: string) => {
                            setPaymentSourceId(id);
                        }} />
                        <div className={"row"}>
                            <div className={"col mb-3 control"}>
                                <button className={"btn btn-primary previous"} type={"button"}
                                        onClick={() => {
                                            setStage4Error("");
                                            setStage(4)
                                        }}
                                >Back
                                </button>
                                <button disabled={!validateStage5() || onSubmitLoading} className={"btn btn-primary next"} type={"button"}
                                        onClick={() => {
                                            if (validateStage5()) {
                                                setStage5Error("");
                                                onSubmit();
                                            } else {
                                                setStage5Error("One or more fields missing values.");
                                            }
                                        }}
                                >
                                    <span hidden={onSubmitLoading}>Submit</span>
                                    <span hidden={!onSubmitLoading} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span hidden={!onSubmitLoading}>Processing...</span>
                                </button>
                                <div hidden={!stage5Error} className={"mt-3"}>
                                    <Alert type={AlertType.DANGER} message={stage5Error}/>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col control"} >
                                <Link to={"/p/s/portfolio-selection"}>
                                    <button className={"btn btn-danger single"} type={"button"}
                                    >Cancel
                                    </button>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default CreatePortfolio;
