import {FormBuilderField, FormFieldType} from "../../Form/FieldBuilder/FormFieldBuilder";
import {formInputValidate, GenericInputRegex} from "../../../types";

interface CreateConversationMessageForm {
    message: FormBuilderField
}

const CreateConversationMessageForm: CreateConversationMessageForm = {
    message: {
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 1,
        element: {
            id: "message",
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        placeholder: "Reply...",
        label: "",
        value: undefined,
        setValue: () => {}
    },
}

export default CreateConversationMessageForm;
