import * as React from "react";
import Tabs, {Tab} from "../../Tabs/Tabs";

export enum AmenityDayViews {
    SUNDAY="Sunday",
    MONDAY="Monday",
    TUESDAY="Tuesday",
    WEDNESDAY="Wednesday",
    THURSDAY="Thursday",
    FRIDAY="Friday",
    SATURDAY="Saturday"
}

const AmenityDayTabs: React.FunctionComponent<{view: string, setView: Function}> = ({view, setView}) => {

    const AmenityDayTabs: Tab[] = [
        {
            targetView: AmenityDayViews.SUNDAY,
            label: AmenityDayViews.SUNDAY,
        },
        {
            targetView: AmenityDayViews.MONDAY,
            label: AmenityDayViews.MONDAY,
        },
        {
            targetView: AmenityDayViews.TUESDAY,
            label: AmenityDayViews.TUESDAY,
        },
        {
            targetView: AmenityDayViews.WEDNESDAY,
            label: AmenityDayViews.WEDNESDAY,
        },
        {
            targetView: AmenityDayViews.THURSDAY,
            label: AmenityDayViews.THURSDAY,
        },
        {
            targetView: AmenityDayViews.FRIDAY,
            label: AmenityDayViews.FRIDAY,
        },
        {
            targetView: AmenityDayViews.SATURDAY,
            label: AmenityDayViews.SATURDAY,
        }
    ];

    return <Tabs view={view} setView={setView} tabs={AmenityDayTabs} />
}

export default AmenityDayTabs;
