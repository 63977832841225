import * as React from "react";
import {useCallback, useContext, useEffect, useState} from "react";
import './index.css'
import {getPortfolio} from "../../Api/PortfolioApi";
import {Portfolio, Subscription, SubscriptionPayment, SubscriptionPaymentMethod, UsageSummary} from "../../types";
import {assignFormFieldValues} from "../../Components/Form/FieldBuilder/FormFieldBuilder";
import SubscriptionForm from "./Forms/SubscriptionForm";
import {
    addSubscriptionPaymentMethod,
    deleteSubscriptionPaymentMethod,
    getSubscription,
    getSubscriptionPaymentMethods,
    getSubscriptionPayments, getSubscriptionUsageSummary, updateSubscription
} from "../../Api/SubscriptionApi";
import moment from "moment";
import Table from "../../Components/Table";
import UpdateCard from "./UpdateCard";
import {v4 as uuidv4} from 'uuid';
import Alert, {AlertType} from "../../Components/Alert";
import PageLayout from "../../Layouts/PageLayout";
import LoadingOverlay from "../../Components/LoadingOverlay";
import {PortfolioIdContext} from "../../Containers/PrivateMainContainer";


const Subscriptions: React.FunctionComponent = () => {

    const portfolioId = useContext(PortfolioIdContext)

    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

    const [portfolio, setPortfolio] = useState<Portfolio>();
    const [subscription, setSubscription] = useState<Subscription>();
    const [payments, setPayments] = useState<SubscriptionPayment[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<SubscriptionPaymentMethod[]>([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<SubscriptionPaymentMethod>();
    const [newPaymentMethod, setNewPaymentMethod] = useState("");
    const [billingFrequency, setBillingFrequency] = useState("monthly");
    const [planId, setPlanId] = useState("1");
    const [paymentHistoryDaysAgo, setPaymentHistoryDaysAgo] = useState(30);
    const [usageHistoryDaysAgo, setUsageHistoryDaysAgo] = useState(30);
    const [doShowUpdateCard, setDoShowUpdateCard] = useState(false);
    const [paymentMethodLoading, setPaymentMethodLoading] = useState(false);
    const [usageHistoryLoading, setUsageHistoryLoading] = useState(false);
    const [paymentHistoryLoading, setPaymentHistoryLoading] = useState(false);
    const [subscriptionInfoLoading, setSubscriptionInfoLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [selectedUpgradePlan, setSelectedUpgradePlan] = useState("")
    const [doUpgrade, setDoUpgrade] = useState(false);
    const [usageSummary, setUsageSummary] = useState<UsageSummary>();
    const [upgradeError, setUpgradeError] = useState("");
    const [upgradeLoading, setUpgradeLoading] = useState(false);

    assignFormFieldValues({field: SubscriptionForm.subscriptionPlan, value: planId, setValue: setPlanId});
    assignFormFieldValues({
        field: SubscriptionForm.billingFrequency,
        value: billingFrequency,
        setValue: setBillingFrequency
    });


    useEffect(() => {
        if (paymentMethods) {
            let defaultPM = paymentMethods.filter(pm => pm.default);
            if (defaultPM && defaultPM.length > 0) {
                setSelectedPaymentMethod(defaultPM[0]);
            }
        }
    }, [paymentMethods]);

    const load = useCallback(() => {
        if (portfolio) {
            setSubscriptionInfoLoading(true);
            getSubscription(portfolio.uuid, portfolio.subscriptionId)
                .then((s) => {
                    setSubscription(s);
                })
                .catch(e => console.error(e.message))
                .finally(() => setSubscriptionInfoLoading(false));

            setPaymentHistoryLoading(true);
            getSubscriptionPayments(portfolio.uuid, portfolio.subscriptionId, paymentHistoryDaysAgo)
                .then((pz) => {
                    setPayments(pz);
                })
                .catch(e => console.error(e.message))
                .finally(() => setPaymentHistoryLoading(false));

            setPaymentMethodLoading(true);
            getSubscriptionPaymentMethods(portfolio.uuid, portfolio.subscriptionId)
                .then((pmz) => {
                    setPaymentMethods(pmz);
                })
                .catch(e => console.error(e.message))
                .finally(() => setPaymentMethodLoading(false));

            setUsageHistoryLoading(true);
            getSubscriptionUsageSummary(portfolio.uuid, portfolio.subscriptionId, usageHistoryDaysAgo)
                .then((u) => {
                    setUsageSummary(u);
                })
                .catch(e => console.error(e.message))
                .finally(() => setUsageHistoryLoading(false));
        }
    }, [paymentHistoryDaysAgo, portfolio, usageHistoryDaysAgo]);

    useEffect(() => {
        if (newPaymentMethod && portfolio) {
            setPaymentMethodLoading(true)
            addSubscriptionPaymentMethod(portfolio?.uuid, portfolio?.subscriptionId, {
                paymentMethodId: newPaymentMethod
            })
                .then((r) => {
                    setDoShowUpdateCard(false);
                    setNewPaymentMethod("");
                    load();
                })
                .catch(e => console.error(e.message))
                .finally(() => setPaymentMethodLoading(false));
        }
    }, [load, newPaymentMethod, portfolio]);

    useEffect(() => {
        if (portfolio) {
            load();
        }
    }, [load, portfolio]);


    useEffect(() => {
        if (portfolioId) {
            getPortfolio(portfolioId)
                .then((p) => {
                    setPortfolio(p);
                })
                .catch(e => console.error(e.message))
        }
    }, [portfolioId]);


    const deletePaymentMethod = (id: string) => {
        if (subscription && portfolio && portfolioId) {
            setDeleteLoading(true);
            deleteSubscriptionPaymentMethod(portfolioId, portfolio?.subscriptionId, id)
                .then((r) => {
                    load();
                })
                .catch(e => console.error(e.message))
                .finally(() => setDeleteLoading(false));
        }

    }

    const getUpgradePlans = () => {
        if (subscription?.planName === "Basic Plan") {
            return <>
                <option key="" value={""} disabled={true}>Select one...</option>
                <option key="pro" value={"pro"}>Pro</option>
                <option key="enterprise" value={"enterprise"}>Enterprise</option>
            </>
        }
        if (subscription?.planName === "Pro Plan") {
            return <>
                <option key="" value={""} disabled={true}>Select one...</option>
                <option key="enterprise" value={"enterprise"}>Enterprise</option>
            </>
        }
        return;
    }

    const doUpdateSubscription = () => {
        if (portfolioId && portfolio?.subscriptionId) {
            setUpgradeError("");
            setUpgradeLoading(true);
            updateSubscription(portfolioId, portfolio?.subscriptionId, {
                planName: selectedUpgradePlan
            })
                .then((r) => {
                    setDoUpgrade(false);
                    setSelectedUpgradePlan("");
                    load();
                })
                .catch((e) => setUpgradeError(e.message))
                .finally(() => setUpgradeLoading(false));
        }
    }

    const getUpgradeComponent = () => {
        if (!subscription?.planName || subscription?.planName === "Enterprise Plan") {
            return;
        }
        if (!doUpgrade) {
            return <button type={"button"} className="btn btn-primary"
                           onClick={() => {
                               setDoUpgrade(true);
                           }}
            >Upgrade Plan</button>
        }
        return <>
            <div className="input-group">
                <select value={selectedUpgradePlan} className={"form-control"}
                        onChange={(e) => {
                            setSelectedUpgradePlan(e.target.value)
                        }}>
                    {getUpgradePlans()}
                </select>
                <button disabled={upgradeLoading} type={"button"} className={"btn btn-primary"}
                        onClick={() => {
                            doUpdateSubscription()
                        }}>
                    <span hidden={upgradeLoading}>Save</span>
                    <span hidden={!upgradeLoading}>
                    <span className="spinner-border spinner-border-sm" role="status"
                          aria-hidden="true"></span>
                    Saving...
                    </span>
                </button>
                <button disabled={upgradeLoading} type={"button"} className={"btn btn-danger"}
                        onClick={() => {
                            setDoUpgrade(false);
                        }}
                >Cancel
                </button>
            </div>
            {upgradeError && <div className={"mt-3"}><Alert type={AlertType.DANGER} message={upgradeError}/></div>}
        </>


    }

    const getCurrentBalanceComponent = () => {
        if (!subscription?.currentBalance || subscription?.currentBalance >= 0) {
            return <h4 className={"text-success"}>+{currencyFormat.format(subscription?.currentBalance || 0)}</h4>
        }
        return <h4 className={"text-danger"}>{currencyFormat.format(subscription?.currentBalance || 0)}</h4>
    }

    return (loading ? <LoadingOverlay /> :
        <PageLayout title="Subscription">
            <div className={"row"}>
                <div className={"col mb-3"}>
                    <div className="card">
                        <div className={"card-header"}>
                            <h4 className="m-0">Plan Information</h4>
                        </div>
                        <div hidden={!subscriptionInfoLoading} className="spinner-grow text-primary"
                             role="status"
                             style={{margin: 'auto', marginTop: 20, marginBottom: 20}}>
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div hidden={subscriptionInfoLoading} className="card-body">
                            <div className="card-text">
                                <div className={"row mb-3"}>
                                    <div className={"col"}>
                                        <h5>{subscription?.planName}</h5>
                                        Upgrade or cancel your plan at any time
                                    </div>
                                    <div className={"col"}>
                                        <h5>{subscription?.activeUnits} Active Units</h5>
                                        The number of units in your subscription
                                    </div>
                                    <div className={"col"}>
                                        <h5>Next Payment</h5>
                                        {moment(subscription?.nextPaymentDate).format("MMM Do, YYYY")} for {currencyFormat.format(subscription?.nextPaymentAmount || 0)}
                                    </div>
                                </div>
                                <div className={"row mb-3"}>
                                    <div className={"col"}>
                                        <h5>Current Balance</h5>
                                        {getCurrentBalanceComponent()}
                                    </div>
                                </div>
                                <div className={"row mb-3"}>
                                    <div className={"col"}>
                                        {getUpgradeComponent()}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={"col mb-3"}>
                    <div className="card">
                        <div className={"card-header"}>
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="m-0 p-0">Usage Summary</h4>
                                <div className="w-25 m-0 p-0">
                                    <select value={usageHistoryDaysAgo} className={"form-control form-control-sm m-0"}
                                            onChange={(e) => {
                                                setUsageHistoryDaysAgo(parseInt(e.target.value))
                                            }}>

                                        <option key={30} value={30}>30 Days</option>
                                        <option key={60} value={60}>60 Days</option>
                                        <option key={90} value={90}>90 Days</option>
                                        <option key={365} value={365}>1 Years</option>
                                        <option key={365 * 2} value={365 * 2}>2 Years</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div hidden={!usageHistoryLoading} className="spinner-grow text-primary"
                             role="status"
                             style={{margin: 'auto', marginTop: 20, marginBottom: 20}}>
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div hidden={usageHistoryLoading} className="card-body">
                            <div className={"row"}>
                                <div className={"col-md-8"}>
                                    As of {moment().format("MMM Do, YYYY @ h:mm:ss a")}
                                </div>

                            </div>
                            <table className={"table"}>
                                <thead>
                                <tr>
                                    <td>Product</td>
                                    <td className={"text-end"}>Cost</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>E-sign Requests</td>

                                    <td className={"text-end"}>{currencyFormat.format(usageSummary?.esignRequests || 0)}</td>
                                </tr>
                                <tr>
                                    <td>Communication</td>
                                    <td className={"text-end"}>{currencyFormat.format(usageSummary?.communications || 0)}</td>
                                </tr>
                                <tr>
                                    <td>Tenant Applications</td>
                                    <td className={"text-end"}>{currencyFormat.format(usageSummary?.applications || 0)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col mb-3"}>
                    <div className="card">
                        <div className="card-header">
                            <div className="d-flex justify-content-between gap-2">
                                <h4 className="m-0 p-0">Payment History</h4>
                                <div className={"w-25"}>
                                    <select value={paymentHistoryDaysAgo} className={"form-control form-control-sm"}
                                            onChange={(e) => {
                                                setPaymentHistoryDaysAgo(parseInt(e.target.value))
                                            }}>

                                        <option key={30} value={30}>30 Days</option>
                                        <option key={60} value={60}>60 Days</option>
                                        <option key={90} value={90}>90 Days</option>
                                        <option key={365} value={365}>1 Years</option>
                                        <option key={365 * 2} value={365 * 2}>2 Years</option>
                                    </select>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col-md-8"}>
                                </div>

                            </div>
                        </div>
                        <div hidden={!paymentHistoryLoading} className="spinner-grow text-primary"
                             role="status"
                             style={{margin: 'auto', marginTop: 20, marginBottom: 20}}>
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div hidden={paymentHistoryLoading} className="card-body">
                            <div className="card-text">
                                <Table headers={["Date", "Payment Method", "Description", "Status", "Amount"]}
                                       defaultPaginationSize={10}>
                                    {
                                        payments?.map((p) => (
                                            <tr key={uuidv4()}>
                                                <td>{moment(p.date).format("MMM Do, YYYY")}</td>
                                                <td>{p.paymentMethod}</td>
                                                <td>{p.description}</td>
                                                <td>{p.status}</td>
                                                <td className={"text-end"}>{currencyFormat.format(p.amount)}</td>
                                            </tr>
                                        ))
                                    }
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"col mb-3"}>
                    <div className="card">
                        <div className={"card-header"}>
                            <h4 className="card-title">Payment Method</h4>
                        </div>
                        <div hidden={!paymentMethodLoading} className="spinner-grow text-primary"
                             role="status"
                             style={{margin: 'auto', marginTop: 20, marginBottom: 20}}>
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <div hidden={doShowUpdateCard || paymentMethodLoading} className="card-body">


                            <div hidden={!selectedPaymentMethod} className="card-text text-center mb-3">
                                <h4>{selectedPaymentMethod?.brand}</h4>
                                <h5>**{selectedPaymentMethod?.lastFour}</h5>
                                Expires
                                on: {selectedPaymentMethod?.expireOnMonth}/{selectedPaymentMethod?.expireOnYear}

                            </div>
                            <div className="card-text">
                                <button type={"button"} className="btn btn-primary"
                                        onClick={() => setDoShowUpdateCard(true)}>Update Payment Method
                                </button>
                            </div>
                        </div>
                        <div hidden={!doShowUpdateCard || paymentMethodLoading} className="card-body">
                            <UpdateCard
                                savedCards={paymentMethods}
                                doDelete={(id: string) => deletePaymentMethod(id)}
                                setPaymentSourceId={(p: string) => {
                                    setNewPaymentMethod(p);
                                }}/>
                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>
    );
}

export default Subscriptions;