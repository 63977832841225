import * as React from "react";
import {Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import ViewCommunityLinks from "./ViewCommunityLinks";
import ManageCommunityLinks from "./ManageCommunityLinks/ManageCommunityLinks";
import AutoSelectProperty from "../Form/SelectProperty/AutoSelectProperty";

const CommunityLinkRouter: React.FunctionComponent = () => {
    let {path} = useRouteMatch();
    return (
        <>
           <Switch>
            <Route exact path={path}>
                <AutoSelectProperty name={"Community Links"} />
            </Route>
            <Route exact path={path + "/:propertyUuid"}>
              <ViewCommunityLinks/>
            </Route>
            <Route exact path={path + "/:propertyUuid/manage/:communityLinkUuid"}>
               <ManageCommunityLinks/>
            </Route>
            <Route exact path={path + "/:propertyUuid/add"}>
                
            </Route>
        </Switch>
        </>
    );
}

export default CommunityLinkRouter;
