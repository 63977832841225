import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import PageLayout from "../../../../Layouts/PageLayout";
import {DateRegex, formInputValidate, GenericInputRegex, GenericNumberRegex, GetOwnerPayout} from "../../../../types";
import {createOwnerPayoutCheck, getOwnerPayouts} from "../../../../Api/PortfolioApi";
import {getPortfolioId} from "../../../../helpers";
import {Link} from "react-router-dom";
import Input from "../../../../Components/Form/Input";
import {downloadFile, openFile} from "../../../../Api/FileApi";

const ViewOwnerPayouts: React.FunctionComponent = () => {
    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [ownerPayouts, setOwnerPayouts] = useState<GetOwnerPayout[]>([]);
    const [view, setView] = useState("create-payout");
    const [selectedPayoutId, setSelectedPayoutId] = useState("");
    const [selectedOwnerId, setSelectedOwnerId] = useState("");
    const [checkDate, setCheckDate] = useState("");
    const [checkNumber, setCheckNumber] = useState("");
    const [selectedOwnerName, setSelectedOwnerName] = useState("");
    const [selectedPayoutAmount, setSelectedPayoutAmount] = useState("")

    useEffect(() => {
        getOwnerPayouts(getPortfolioId())
            .then(r => {
                setOwnerPayouts(r)
            })
            .catch((e) => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }, []);

    function doDownloadCheck() {
        createOwnerPayoutCheck(getPortfolioId(), selectedPayoutId, selectedOwnerId, {
            checkNumber: parseInt(checkNumber),
            date: checkDate
        })
            .then(r => {
                downloadFile(r.uuid, r.uuid)
                    .then((b) => {
                    })
                    .catch(() => {
                    });
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => {
            });
    }

    function doOpenCheck() {
        createOwnerPayoutCheck(getPortfolioId(), selectedPayoutId, selectedOwnerId, {
            checkNumber: parseInt(checkNumber),
            date: checkDate
        })
            .then(r => {
                openFile(r.uuid, r.uuid)
                    .then((b) => {
                    })
                    .catch(() => {
                    });
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => {
            });
    }

    function getMethodButtons(method: string, payoutId: string, ownerId: string, ownerName: string, payoutAmount: number) {
        if (method === "manual") {
            return <button type={"button"} className={"btn btn-small btn-primary ms-1"}
                           onClick={() => {
                               setView("create-check");
                               setSelectedPayoutId(payoutId);
                               setSelectedOwnerId(ownerId);
                               setSelectedOwnerName(ownerName);
                               setSelectedPayoutAmount(payoutAmount.toString());
                           }}>Print Check</button>
        }
    }

    function getView() {
        if (view === "create-payout") {
            return getCreatePayoutView();
        }
        if (view === "create-check") {
            return getPrintCheckView();
        }
    }

    function getCreatePayoutView() {
        return <>
            <div className={"row"}>
                <div className={"col-md-12 mb-3"}>
                    <Link to={"payout-calculator"}>
                        <button type={"button"} className={"btn btn-primary"}>Create Owner Payout</button>
                    </Link>
                </div>
            </div>
            <div className="accordion" id="accordionExample">

                {
                    ownerPayouts.map((payout, index) => (
                        <div key={payout.uuid} className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                        data-bs-target={"#payout_" + index} aria-expanded="false"
                                        aria-controls="collapseTwo">
                                    {payout.date} Payout {payout.identificationId} {currencyFormat.format(payout.amount)}
                                </button>
                            </h2>
                            <div id={"payout_" + index} className="accordion-collapse collapse"
                                 data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className={"row"}>

                                    </div>
                                    <div className={"row"}>
                                        <div className={"col-md-12"}>
                                            <table className="table table-sm">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Owner Name</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Method</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    payout?.owners.map(p => (
                                                        <tr key={p.uuid} style={{verticalAlign: "middle"}}>
                                                            <td>{p.name}</td>
                                                            <td>{currencyFormat.format(p.amount)}</td>
                                                            <td>{p.method}</td>
                                                            <td className={"text-end"}>{getMethodButtons(p.method, payout.uuid, p.uuid, p.name, p.amount)}</td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>;
    }

    function getPrintCheckView() {
        return <div className={"row"}>

            <div className={"col-md-12 mb-3"}>
                <h5>Print Check</h5>
            </div>
            <div className={"col-md-12 mb-3"}>
                <button className={"btn btn-danger me-3"}
                        onClick={(e) => {
                            e.preventDefault();
                            setView("create-payout");
                        }} type={"button"}>Back
                </button>
                <button className={"btn btn-primary me-3"}
                        onClick={() => {
                            doDownloadCheck()
                        }}
                        type={"button"}>Download
                </button>
                <button className={"btn btn-primary"}
                        onClick={() => {
                            doOpenCheck()
                        }}
                        type={"button"}>Print
                </button>
            </div>


            <div className={"col-md-3 mb-3"}>
                <Input id={"checkDate"} validation={DateRegex.expression}
                       doValidate={formInputValidate}
                       value={checkDate}
                       setValue={setCheckDate}
                       required={true}
                       invalidFeedbackText={DateRegex.helpText}
                       type={"date"} label={"Check Date"}
                />
            </div>
            <div className={"col-md-3 mb-3"}>
                <Input id={"checkNumber"} validation={GenericNumberRegex.expression}
                       doValidate={formInputValidate}
                       value={checkNumber}
                       setValue={setCheckNumber}
                       required={true}
                       invalidFeedbackText={GenericNumberRegex.helpText}
                       type={"number"} label={"Check Number"}
                />
            </div>
            <div className={"col-md-3 mb-3"}>
                <Input id={"Owner Name"} validation={GenericInputRegex.expression}
                       doValidate={formInputValidate}
                       value={selectedOwnerName}
                       setValue={setSelectedOwnerName}
                       disabled={true}
                       required={true}
                       invalidFeedbackText={GenericNumberRegex.helpText}
                       type={"text"} label={"Owner Name"}
                />
            </div>
            <div className={"col-md-3 mb-3"}>
                <Input id={"checkNumber"} validation={GenericInputRegex.expression}
                       doValidate={formInputValidate}
                       value={selectedPayoutAmount}
                       step={.01}
                       setValue={setSelectedPayoutAmount}
                       disabled={true}
                       required={true}
                       invalidFeedbackText={GenericInputRegex.helpText}
                       type={"number"} label={"Amount"}
                />
            </div>
            <div className={"col-md-6 mb-3"}>
            </div>
        </div>
    }

    return <PageLayout title={"Owner Payouts"}>
        {loading && <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>}
        {errorMessage && <div className="alert alert-danger" role="alert">
            {errorMessage}</div>}
        {getView()}

    </PageLayout>
}
export default ViewOwnerPayouts;