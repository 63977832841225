import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import {useParams} from "react-router";
import {Amenity, AmenityDay, createAmenityReservation} from "../../../../Api/AmenityApi";
import FormFieldBuilder, {SelectOptions} from "../../../Form/FieldBuilder/FormFieldBuilder";
import AddAmenityReservationForm from "../../Forms/AddAmenityReservationForm";
import AmenityDayTabs, {AmenityDayViews} from "../../Tabs/AmenityDayTabs";
import {parseStringToSimpleTime, simpleTimeToString} from "../../Service/AmenityService";
import Alert, {AlertType} from "../../../Alert";
import AmenityDayOverview from "../../Overview/AmenityDayOverview";
import AmenityForm from "../../Forms/AmenityForm";

const AddAmenityReservation: React.FunctionComponent<{amenity: Amenity, dayView: AmenityDayViews, setDayView: Function, selectOptions: SelectOptions[], done: Function}> = ({amenity, dayView, setDayView, selectOptions, done}) => {

    const { propertyId, amenityId } = useParams();

    const [alert, setAlert] = useState<Error>();
    const [accountId, setAccountId] = useState<string>("");
    const [date, setDate] = useState<string>("");
    const [startTime, setStartTime] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");
    const [accountHolderName, setAccountHolderName] = useState<string>("");
    const [accountType, setAccountType] = useState<string>("");
    const [accountNumber, setAccountNumber] = useState<string>("");
    const [routingNumber, setRoutingNumber] = useState<string>("");

    const [startTimeSelectOptions, setStartTimeSelectOptions] = useState<SelectOptions[]>([]);
    const [endTimeSelectOptions, setEndTimeSelectOptions] = useState<SelectOptions[]>([]);

    AddAmenityReservationForm.accountId.value = accountId;
    AddAmenityReservationForm.accountId.setValue = setAccountId;
    AddAmenityReservationForm.date.value = date;
    AddAmenityReservationForm.date.setValue = setDate;
    AddAmenityReservationForm.startTime.value = startTime;
    AddAmenityReservationForm.startTime.setValue = setStartTime;
    AddAmenityReservationForm.endTime.value = endTime;
    AddAmenityReservationForm.endTime.setValue = setEndTime;
    AddAmenityReservationForm.accountHolderName.value = accountHolderName;
    AddAmenityReservationForm.accountHolderName.setValue = setAccountHolderName;
    AddAmenityReservationForm.accountType.value = accountType;
    AddAmenityReservationForm.accountType.setValue = setAccountType;
    AddAmenityReservationForm.accountNumber.value = accountNumber;
    AddAmenityReservationForm.accountNumber.setValue = setAccountNumber;
    AddAmenityReservationForm.routingNumber.value = routingNumber;
    AddAmenityReservationForm.routingNumber.setValue = setRoutingNumber;

    const updateStartAndEndTimeSelectOptions = (day: AmenityDay) => {
        const startTimes: SelectOptions[] = [{value: "", label: "Please select...", disabled: true}];
        const endTimes: SelectOptions[] = [{value: "", label: "Please select...", disabled: true}];
        const parsedStartTime = startTime ? parseStringToSimpleTime(startTime) : { hour: day.openTime.hour, minute: day.openTime.minute }
        let firstEndHour = parsedStartTime.hour
        let firstEndMinute = parsedStartTime.minute + day.minReservationMinutes;
        if (firstEndMinute > 60) {
            firstEndHour++;
            firstEndMinute -= 60;
        }
        let lastStartTimeHour = day.closeTime.hour > 0 ? day.closeTime.hour : 24;
        let lastStartTimeMinute = day.closeTime.minute - day.minReservationMinutes;
        if (lastStartTimeMinute < 0) {
            lastStartTimeHour--;
            lastStartTimeMinute += 60;
        }
        let endTimeHour = day.closeTime.hour > 0 ? day.closeTime.hour : 24;
        console.log("lastStartTime: " + lastStartTimeHour + ":" + lastStartTimeMinute);
        console.log("firstEndTime: " + firstEndHour + ":" + firstEndMinute);
        for (let hour = day.openTime.hour; hour <= endTimeHour; hour++) {
            for (let minute = 0; minute < 60; minute += day.timeslotBlockMinutes) {
                if (hour === endTimeHour && minute > day.closeTime.minute) {
                    console.log("hour: " + hour + ", " + day.closeTime.hour);
                    console.log("minute: " + minute + ", " + day.closeTime.minute);
                    break;
                }
                const time = simpleTimeToString({hour, minute});
                if (hour < lastStartTimeHour || (hour === lastStartTimeHour && minute <= lastStartTimeMinute)) {
                    console.log(hour + ":" + minute);
                    startTimes.push({
                        value: time,
                        label: time
                    });
                }
                if (hour > firstEndHour || (hour === firstEndHour && minute >= firstEndMinute)) {
                    endTimes.push({
                        value: time,
                        label: time
                    })
                }
            }
        }
        setStartTimeSelectOptions(startTimes);
        setEndTimeSelectOptions(endTimes);
    }

    useEffect(() => {
        if (date === "") {
            switch (dayView) {
                case AmenityDayViews.SUNDAY: updateStartAndEndTimeSelectOptions(amenity.sunday); break;
                case AmenityDayViews.MONDAY: updateStartAndEndTimeSelectOptions(amenity.monday); break;
                case AmenityDayViews.TUESDAY: updateStartAndEndTimeSelectOptions(amenity.tuesday); break;
                case AmenityDayViews.WEDNESDAY: updateStartAndEndTimeSelectOptions(amenity.wednesday); break;
                case AmenityDayViews.THURSDAY: updateStartAndEndTimeSelectOptions(amenity.thursday); break;
                case AmenityDayViews.FRIDAY: updateStartAndEndTimeSelectOptions(amenity.friday); break;
                case AmenityDayViews.SATURDAY: updateStartAndEndTimeSelectOptions(amenity.saturday); break;
            }
        }
        else {
            switch (new Date(Date.parse(date)).getDay()) {
                case 6: updateStartAndEndTimeSelectOptions(amenity.sunday); break;
                case 0: updateStartAndEndTimeSelectOptions(amenity.monday); break;
                case 1: updateStartAndEndTimeSelectOptions(amenity.tuesday); break;
                case 2: updateStartAndEndTimeSelectOptions(amenity.wednesday); break;
                case 3: updateStartAndEndTimeSelectOptions(amenity.thursday); break;
                case 4: updateStartAndEndTimeSelectOptions(amenity.friday); break;
                case 5: updateStartAndEndTimeSelectOptions(amenity.saturday); break;
            }
        }
    }, [date, startTime, amenity, dayView])

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (startTime && endTime) {
            createAmenityReservation(propertyId, {
                accountId: accountId,
                amenityId: amenityId,
                bankAccount: {
                    accountHolderName: accountHolderName,
                    accountType: accountType,
                    accountNumber: accountNumber,
                    routingNumber: routingNumber,
                },
                date: date,
                startTime: parseStringToSimpleTime(startTime),
                endTime: parseStringToSimpleTime(endTime)
            })
                .then(reservation => {
                    onReset(undefined);
                    setAlert(undefined);
                    done(reservation)
                })
                .catch(setAlert);
        }
    }

    const onReset = (e: FormEvent | undefined) => {
        e?.preventDefault();
        setAccountId("");
        setDate("");
        setStartTime("");
        setEndTime("");
        setAccountHolderName("");
        setAccountType("");
        setAccountNumber("");
        setRoutingNumber("");
    }

    return (<>
        <div className="row">
            <div className="col-md-4 mb-3 bg-light">
                <form onSubmit={onSubmit} onReset={onReset}>
                    <div className="row">
                        <div className="col-xxl-6">
                            <div className="row">
                                <div className="col-md-12 mt-2 mb-2">
                                    <h5>Details</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <FormFieldBuilder field={AddAmenityReservationForm.accountId} selectOptions={selectOptions} />
                                </div>
                                <div className="col-md-12 mb-2">
                                    <FormFieldBuilder field={AddAmenityReservationForm.date} />
                                </div>
                                <div className="col-md-12 mb-2">
                                    <FormFieldBuilder field={AddAmenityReservationForm.startTime} selectOptions={startTimeSelectOptions} />
                                </div>
                                <div className="col-md-12 mb-2">
                                    <FormFieldBuilder field={AddAmenityReservationForm.endTime} selectOptions={endTimeSelectOptions} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-6">
                            <div className="row">
                                <div className="col-md-12 mt-2 mb-2">
                                    <h5>Payment</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-2">
                                    <FormFieldBuilder field={AddAmenityReservationForm.accountType} selectOptions={AddAmenityReservationForm.accountType.defaultSelectOptions}/>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <FormFieldBuilder field={AddAmenityReservationForm.accountHolderName}/>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <FormFieldBuilder field={AddAmenityReservationForm.accountNumber}/>
                                </div>
                                <div className="col-md-12 mb-2">
                                    <FormFieldBuilder field={AddAmenityReservationForm.routingNumber}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="btn-group mt-3 mb-3" role="btn-group">
                                <button type="reset" className="btn btn-outline-primary">Reset</button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                        <div className="col-md-12">
                            {alert &&
                                <Alert type={AlertType.DANGER} message={alert.message} show={true} />
                            }
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-md-8">
                <div className="row">
                    <div className="col-md-12 bg-light mb-3">
                        <AmenityDayTabs view={dayView} setView={setDayView} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <div hidden={dayView !== AmenityDayViews.SUNDAY}>
                            <AmenityDayOverview form={AmenityForm.sunday} day={amenity.sunday} />
                        </div>
                        <div hidden={dayView !== AmenityDayViews.MONDAY}>
                            <AmenityDayOverview form={AmenityForm.monday} day={amenity.monday} />
                        </div>
                        <div hidden={dayView !== AmenityDayViews.TUESDAY}>
                            <AmenityDayOverview form={AmenityForm.tuesday} day={amenity.tuesday} />
                        </div>
                        <div hidden={dayView !== AmenityDayViews.WEDNESDAY}>
                            <AmenityDayOverview form={AmenityForm.wednesday} day={amenity.wednesday} />
                        </div>
                        <div hidden={dayView !== AmenityDayViews.THURSDAY}>
                            <AmenityDayOverview form={AmenityForm.thursday} day={amenity.thursday} />
                        </div>
                        <div hidden={dayView !== AmenityDayViews.FRIDAY}>
                            <AmenityDayOverview form={AmenityForm.friday} day={amenity.friday} />
                        </div>
                        <div hidden={dayView !== AmenityDayViews.SATURDAY}>
                            <AmenityDayOverview form={AmenityForm.saturday} day={amenity.saturday} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default AddAmenityReservation;
