import {Term, Unit, UnitService} from "../types";
import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod, serializeFilter} from "../api";

interface getUnitsFilter {
    name?: string,
    addressLine1?: string,
    addressLine2?: string,
    city?: string,
    stateCode?: string,
    zip?: string
    floorPlanUuid?: string
}

interface AddUpdateUnitBody {
    name: string,
    floorLevel: string,
    deposit: string,
    address: {
        addressLine1: string,
        addressLine2: string,
        city: string,
        stateCode: string,
        zip: string
    },
    floorPlan: {
        uuid: string
    },
    disabled: boolean,
    imageUrl: string
}

interface AddUnitServiceBody {
    units: string,
    price: string,
    name: string
    description: string,
    optional: boolean
}

interface AddUnitTermsBody {
    length: string,
    units: string,
    price: string
}

type getUnits = (propertyUuid: string, filter: getUnitsFilter) => Promise<Unit[]>;
type getUnit = (propertyUuid: string, unitUuid: string) => Promise<Unit>;
type addUnit = (propertyUuid: string, body: AddUpdateUnitBody) => Promise<Unit>;
type updateUnit = (propertyUuid: string, unitUuid: string, body: AddUpdateUnitBody) => Promise<Unit>;
type addUnitTerms = (propertyUuid: string, unitUuid: string, body: AddUnitTermsBody) => Promise<Term>;
type getUnitTerms = (propertyUuid: string, unitUuid: string) => Promise<Term[]>;
type deleteUnitTerms = (propertyUuid: string, unitUuid: string, termUuid: string) => Promise<Response>;
type addUnitService = (propertyUuid: string, unitId: string, body: AddUnitServiceBody) => Promise<UnitService>;
type getUnitServices = (propertyUuid: string, unitUuid: string) => Promise<UnitService[]>;
type deleteUnitService = (propertyUuid: string, unitUuid: string, serviceUuid: string) => Promise<Response>;
type GetUnitImages = (propertyId: string, unitId: string) => Promise<String[]>
type PostUnitImage = (propertyId: string, unitId: string, imageUrl: string) => Promise<String>

export const getUnits: getUnits = async (propertyUuid, filter) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/units" + "?" + serializeFilter(filter), HttpMethod.GET);
}

export const getUnit: getUnit = async (propertyUuid, unitUuid) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/units/" + unitUuid, HttpMethod.GET);
}

export const updateUnit: updateUnit = async (propertyUuid, unitUuid, body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/units/" + unitUuid, HttpMethod.PUT, JSON.stringify(body));
}

export const addUnit: addUnit = async (propertyUuid, body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/units", HttpMethod.POST, JSON.stringify(body));
}

export const addUnitTerms: addUnitTerms = async (propertyUuid, unitUuid, body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/units/" + unitUuid + "/terms", HttpMethod.POST, JSON.stringify(body));
};

export const getUnitTerms: getUnitTerms = async (propertyUuid, unitUuid) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/units/" + unitUuid + "/terms", HttpMethod.GET);
};

export const deleteUnitTerms: deleteUnitTerms = async (propertyUuid, unitUuid, termUuid) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/units/" + unitUuid + "/terms/" + termUuid, HttpMethod.DELETE);
};

export const addUnitService: addUnitService = async (propertyUuid, unitId, body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/units/" + unitId + "/additional-services", HttpMethod.POST, JSON.stringify(body));
};

export const getUnitServices: getUnitServices = async (propertyUuid, unitUuid) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/units/" + unitUuid + "/additional-services", HttpMethod.GET);
};

export const deleteUnitService: deleteUnitService = async (propertyUuid, unitUuid, serviceUuid) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/units/" + unitUuid + "/additional-services/" + serviceUuid, HttpMethod.DELETE);
};

export const getUnitImages: GetUnitImages = async (propertyId, unitId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/units/" + unitId + "/images", HttpMethod.GET)
}

export const uploadUnitImage: PostUnitImage = async (propertyId, unitId, imageUrl) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/units/" + unitId + "/images", HttpMethod.POST, imageUrl)
}
