import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import './index.css';
import {AUTH_ACCESS_TOKEN} from "../../../Auth";
import {SubscriptionPaymentMethod} from "../../../types";

type Params = {
    setCardId: Function,
    savedCards: SubscriptionPaymentMethod[]
}
const AddPaymentMethod: React.FunctionComponent<Params> = (p) => {

    const stripe = useStripe();
    const elements = useElements();

    const [cardId, setCardId] = useState("");
    const [brand, setBrand] = useState("");
    const [last4, setLast4] = useState("");
    const [expireYear, setExpireYear] = useState("");
    const [expireMonth, setExpireMonth] = useState("");
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [nameOnCard, setNameOnCard] = useState("")
    const jwtDecode = require('jwt-decode');


    useEffect(() => {
        const accessToken = localStorage.getItem(AUTH_ACCESS_TOKEN);
        const decodedJwt: any = jwtDecode(accessToken);
        setEmail(decodedJwt.email);
    }, [jwtDecode]);

    useEffect(() => {
        if (cardId) {
            p.setCardId(cardId);
        }
    }, [cardId, p]);

    let options = {
        style: {
            base: {
                iconColor: '#000',
                color: '#000',
                fontWeight: '400',
                fontFamily: 'Roboto, sans-serif',
                fontSize: '16px',
                lineHeight: '40px'

            },

            invalid: {
                iconColor: '#FFC7EE',
                color: '#FFC7EE',
            },
        },
    }
    const handleSubmit = async (event: FormEvent) => {

        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }
        let card = elements.getElement(CardElement);
        if (card) {
            setLoading(true);
            stripe.createPaymentMethod({
                    element: card,
                    params: {
                        billing_details: {
                            name: nameOnCard,
                            email: email,
                        }
                    }
                }
            ).then((result) => {
                setCardId(result.paymentMethod?.id || "");
                setBrand(result.paymentMethod?.card?.brand || "");
                setLast4(result.paymentMethod?.card?.last4 || "")
                setExpireMonth(result.paymentMethod?.card?.exp_month + "");
                setExpireYear(result.paymentMethod?.card?.exp_year + "");
            })
                .catch((e) => console.error(e.message))
                .finally(() => setLoading(false));

        }


    }

    const getRenderedComponent = () => {
        if (cardId) {
            return getSavedCardComponent();
        } else {
            return getStripeComponent();
        }
    }

    const getSavedCardComponent = () => {
        return <div className={"row"}>
            <div className={"col"}>
                <div className="card" style={{width: '100%'}}>
                    <div className="card-body">
                        <h5 className="card-title">{brand.toUpperCase()}</h5>
                        <h6 className="card-subtitle mb-2 text-muted">**{last4}</h6>
                        <p className="card-text">Expires on: {expireMonth + "/" + expireYear}</p>

                    </div>
                    <div className="card-footer">
                        <button type={"button"} className={"btn btn-link"} onClick={() => {
                            setCardId("");
                            setLast4("");
                            setBrand("");
                            setExpireYear("");
                            setExpireMonth("");
                        }}>Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    }

    const getSavedCards = () => {
        if (!p.savedCards || p.savedCards.length === 0) {
            return <></>
        }
        return <>
            <h5 className={"mb-3 mt-3"}>Choose a Saved Card</h5>
            <div hidden={loading} className={"row"}>
                <div className={"col"}>
                    {
                        p.savedCards.map(c => (
                            <div key={c.createdAt + "-" + c.lastFour} className="card">
                                <div className="card-body">
                                    <div className={"row"}>
                                        <div className={"col"} style={{fontSize: 16}}>
                                            {c.brand + " **" + c.lastFour + " expires on: " + c.expireOnMonth + "/" + c.expireOnYear}
                                        </div>
                                        <div className={"col text-end"}>

                                            {c.default ?
                                                <span className="badge rounded-pill bg-primary me-3">Default</span> : <>
                                                    <a href={"/"}>Set as default</a>
                                                </>}
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                 fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                <path
                                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    }

    const getStripeComponent = () => {
        return <>
            <h5 className={"mb-3"}>Add a Credit Card</h5>
            <div hidden={!loading} className="spinner-grow text-primary"
                 role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
            <form onSubmit={handleSubmit}>
                <div hidden={loading} className={"row"}>
                    <div className={"col-md-9 card-meta mb-3"}>
                        <div className={"row"}>
                            <div className={"col"}>
                                <input type={"input"} min={1} className={""} placeholder={"Name on card"}
                                       value={nameOnCard} onChange={(e) => {
                                    setNameOnCard(e.target.value)
                                }}/>
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-3"}>
                    </div>
                </div>
                <div hidden={loading} className={"row"}>
                    <div className={"col-md-9"}>
                        <CardElement className={"card-info"} options={options}/>
                    </div>
                    <div className={"col-md-3"}>
                        <button style={{width: '100%', marginTop: 3}} type={"submit"} className={"btn btn-primary"}
                                disabled={!stripe}>Save Card
                        </button>


                    </div>
                </div>
            </form>
            {getSavedCards()}
        </>
    }

    return <>

        {getRenderedComponent()}
        <p className={"mt-3"}>
            Accounts are billed at the start of services and beginning of each cycle thereafter. Your credit card is
            charged on the first day of each cycle for the total balance due. Your balance will include monthly service
            and
            usage based fees. See our <a href={"/"}>pricing page for more details <svg
            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
            className="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
            <path fill-rule="evenodd"
                  d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
        </svg>
        </a>
        </p>
    </>
}

export default AddPaymentMethod;