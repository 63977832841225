import {FormBuilderField, FormFieldType} from "../../Form/FieldBuilder/FormFieldBuilder";
import {formSelectValidate, GenericSelectRegex} from "../../../types";

interface CategoryFilterForm {
    category: FormBuilderField
}

const CategoryFilterForm: CategoryFilterForm = {
    category: {
        type: FormFieldType.SELECT,
        element: {
            id: "category",
            required: false,
            validate: formSelectValidate,
            regex: GenericSelectRegex,
        },
        label: "",
        value: undefined,
        setValue: () => {},
        defaultSelectOptions: [{
            value: "",
            label: "Filter by category...",
        }]
    },
}

export default CategoryFilterForm;
