import * as React from 'react';
import {FormEvent, useCallback, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import FormFieldBuilder, {
    assignFormFieldValues,
    SelectOptions
} from "../../../Components/Form/FieldBuilder/FormFieldBuilder";
import AddUserForm from "../Forms/AddUserForm";
import {addUserWithRoles, getRoles} from "../../../Api/UserApi";
import {useHistory} from "react-router";
import Alert, {AlertType} from "../../../Components/Alert";
import PageLayout from "../../../Layouts/PageLayout";
import Card from "../../../Components/Card";


const AddUser: React.FunctionComponent = () => {

    const [email, setEmail] = useState("");
    const [selectedRole, setSelectedRole] = useState<string>("");
    const [roleOptions, setRoleOptions] = useState<SelectOptions[]>([]);
    const [error, setError] = useState("");

    const history = useHistory();

    assignFormFieldValues({field: AddUserForm.email, value: email, setValue: setEmail});
    assignFormFieldValues({field: AddUserForm.roles, value: selectedRole, setValue: setSelectedRole});

    const load = useCallback(() => {
        getRoles()
            .then((rz) => {
                let options: SelectOptions[] = []
                options.push({
                    value: "",
                    label: "Please select...",
                    disabled: true
                })
                rz.forEach((r) => {
                        options.push({
                            value: r.uuid,
                            disabled: false,
                            label: r.name
                        })
                })
                setRoleOptions(options);
            })
            .catch((e) => console.error(e))
    }, [])

    useEffect(() => {
        load();
    }, []);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setError("");
        let roles:{uuid: string}[] = [];
        roles.push({
            uuid: selectedRole
        })
        addUserWithRoles({
            email: email,
            roles: roles
        }).then((r) => {
            history.push({
                pathname: "/p/m/settings/users"
            })
        }).catch((e) => setError(e.message))
    }

    useEffect(() => {
        console.log(`Roles: ${selectedRole}`)
    }, [selectedRole])

    return (<>
        <PageLayout title="Add User">
            <div className="col-lg-6">
                {error && <Alert type={AlertType.DANGER} message={error}/>}
                <Card title="Details">
                    <form onSubmit={onSubmit}>
                        <div className={"row"}>
                            <div className={"col mb-3"}>
                                <FormFieldBuilder field={AddUserForm.email}/>
                            </div>
                            <div className={"col mb-3"}>
                                <FormFieldBuilder field={AddUserForm.roles}
                                                  selectOptions={roleOptions}/>
                            </div>
                            <div className="d-flex justify-content-between gap-2 mt-3">
                                <Link to={"/p/m/settings/users"}>
                                    <button type="button" className="btn btn-danger">Cancel</button>
                                </Link>
                                <button type="submit" className="btn btn-primary">Add user</button>
                            </div>
                        </div>
                    </form>
                </Card>
            </div>
        </PageLayout>
    </>);
};

export default AddUser;