import * as React from "react";
import {useContext, useEffect, useState} from "react";
import AddTenantPackage from "./Add/AddTenantPackage";
import SearchTenantPackages from "./SearchTenantPackages/SearchTenantPackages";
import {ARAccount, Property} from "../../../types";
import {getARAccounts} from "../../../Api/AccountApi";
import LoadingOverlay from "../../LoadingOverlay";
import {getProperty} from "../../../Api/PropertyApi";
import {SelectOptions} from "../../Form/FieldBuilder/FormFieldBuilder";
import AddTenantPackageForm from "../Forms/AddTenantPackageForm";
import SearchTenantPackagesForm from "../Forms/SearchTenantPackagesForm";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";

const ViewTenantPackages: React.FunctionComponent = () => {

    const propertyId = useContext(PropertyIdContext)

    const [loading, setLoading] = useState<boolean>(true);
    const [refresh, setRefresh] = useState<boolean>(true);
    const [property, setProperty] = useState<Property>();
    const [tenantAccounts, setTenantAccounts] = useState<ARAccount[]>([]);
    const [addSelectOptions, setAddSelectOptions] = useState<SelectOptions[]>([]);
    const [searchSelectOptions, setSearchSelectOptions] = useState<SelectOptions[]>([]);

    const updateAccountSelectOptions = (accounts: ARAccount[]) => {
        setTenantAccounts(accounts);
        const addOptions: SelectOptions[] = JSON.parse(JSON.stringify(AddTenantPackageForm.tenantAccountId.defaultSelectOptions));
        const searchOptions: SelectOptions[] = JSON.parse(JSON.stringify(SearchTenantPackagesForm.accountId.defaultSelectOptions));
        accounts.forEach(account => {
            addOptions.push({ value: account.uuid, label: account.unitName })
            searchOptions.push({ value: account.uuid, label: account.unitName })
        });
        setAddSelectOptions(addOptions);
        setSearchSelectOptions(searchOptions);
    }

    useEffect(() => {
        if(propertyId) {
            setLoading(true);
            Promise.all([
                getProperty(propertyId)
                    .then(data => data),
                getARAccounts(propertyId)
                    .then(data => data)
            ])
                .then(values => {
                    setProperty(values[0]);
                    updateAccountSelectOptions(values[1]);
                })
                .catch(console.error)
                .finally(() => setLoading(false));
        }
    }, [propertyId])

    return (loading ? <LoadingOverlay show={loading} /> : <>
        <div className="row widget">
            <div className="col-md-5 mb-3">
                <div className="row">
                    <div className={"col-md-12 mb-3 "}>
                        <h4>{property?.name} </h4>
                        <h6>{property?.address.addressLine1}</h6>
                        <h6>{property?.address.addressLine2}</h6>
                        <br hidden={(property?.address.addressLine2) !== undefined}/>
                        {property?.address.city}, {property?.address.stateCode} {property?.address.zip}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        {tenantAccounts &&
                            <AddTenantPackage selectOptions={addSelectOptions} done={() => setRefresh(!refresh)}/>
                        }
                    </div>
                </div>
            </div>
            <div className="col-md-7">
                {tenantAccounts &&
                    <SearchTenantPackages tenantAccounts={tenantAccounts} selectOptions={searchSelectOptions} refresh={refresh}/>
                }
            </div>
        </div>
    </>)
}

export default ViewTenantPackages;
