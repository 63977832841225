import * as React from "react";
import {useParams} from "react-router";
import {useEffect, useState} from "react";
import {Property} from "../../../types";
import {Amenity, AmenityDay, getAmenity} from "../../../Api/AmenityApi";
import Tabs, {Tab} from "../../Tabs/Tabs";
import {getProperty} from "../../../Api/PropertyApi";
import LoadingOverlay from "../../LoadingOverlay";
import {getTicketIdFromUuid} from "../../../helpers";
import AmenityOverview from "../Overview/AmenityOverview";
import moment from "moment";
import AmenityReservations from "../Reservations/AmenityReservations";
import {AmenityDayViews} from "../Tabs/AmenityDayTabs";
import EditAmenity from "../Edit/EditAmenity";
import {simpleTimeToString} from "../Service/AmenityService";

export enum AmenityView {
    OVERVIEW="OVERVIEW",EDIT="EDIT",RESERVATIONS="RESERVATIONS"
}

export const AmenityTabs: Tab[] = [
    {
        targetView: AmenityView.OVERVIEW,
        label: "Overview"
    },
    {
        targetView: AmenityView.EDIT,
        label: "Edit"
    },
    {
        targetView: AmenityView.RESERVATIONS,
        label: "Reservations"
    }
];

const ManageAmenity: React.FunctionComponent = () => {

    const { propertyId, amenityId } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [view, setView] = useState<AmenityView>(AmenityView.OVERVIEW);
    const [dayView, setDayView] = useState<AmenityDayViews>(AmenityDayViews.SUNDAY);
    const [property, setProperty] = useState<Property>();
    const [amenity, setAmenity] = useState<Amenity>();
    const [reservationCount, setReservationCount] = useState<number>();

    const isAmenityOpenToday = (): boolean => {
        if (amenity) {
            switch (moment().day()) {
                case 0: return isAmenityOpen(amenity?.sunday);
                case 1: return isAmenityOpen(amenity?.monday);
                case 2: return isAmenityOpen(amenity?.tuesday);
                case 3: return isAmenityOpen(amenity?.wednesday);
                case 4: return isAmenityOpen(amenity?.thursday);
                case 5: return isAmenityOpen(amenity?.friday);
                case 6: return isAmenityOpen(amenity?.saturday);
            }
        }
        return false;
    }

    const isAmenityOpen = (day: AmenityDay): boolean => {
        const now = moment()
        const openTime = moment(simpleTimeToString(day.openTime));
        const closeTime = moment(simpleTimeToString(day.closeTime));
        return day.open && now.isAfter(openTime) && now.isBefore(closeTime);
    }

    useEffect(() => {
        setLoading(true);
        switch (moment().day()) {
            case 0: setDayView(AmenityDayViews.SUNDAY); break;
            case 1: setDayView(AmenityDayViews.MONDAY); break;
            case 2: setDayView(AmenityDayViews.TUESDAY); break;
            case 3: setDayView(AmenityDayViews.WEDNESDAY); break;
            case 4: setDayView(AmenityDayViews.THURSDAY); break;
            case 5: setDayView(AmenityDayViews.FRIDAY); break;
            case 6: setDayView(AmenityDayViews.SATURDAY); break;
        }
        Promise.all([
            getProperty(propertyId)
                .then(data => data),
            getAmenity(propertyId, amenityId)
                .then(data => data)
        ]).then(values => {
            setProperty(values[0]);
            setAmenity(values[1]);
            setReservationCount(values[1].reservations?.length)
        }).catch(console.error)
            .finally(() => setLoading(false));
    }, [propertyId, amenityId]);

    return (loading ? <LoadingOverlay show={loading} /> : <>
        <div className="container-xxl m-0">
            <Tabs view={view} setView={setView} tabs={AmenityTabs} />
            <div className="row widget">
                <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <h3>Manage Amenity</h3>
                            <label htmlFor="am_uuid">Id</label>
                            <h5 id="am_uuid">{getTicketIdFromUuid(amenityId)}</h5>
                            <label htmlFor="property_name">Property</label>
                            <h5 id="property_name">{property?.name}</h5>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="am_status">Status</label>
                            <h4 id="am_status"><span className={isAmenityOpenToday ? "badge rounded-pill bg-success" : "badge rounded-pill bg-danger"}>{isAmenityOpenToday ? <>Open</> : <>Closed</>}</span></h4>
                            <label htmlFor="am_reservation_count">Reservation(s)</label>
                            <h5 id="am_reservation_count">{reservationCount}</h5>
                        </div>
                    </div>
                    <div className="row">
                        {amenity && <>
                            <div hidden={view !== AmenityView.OVERVIEW}>
                                <AmenityOverview amenity={amenity} dayView={dayView} setDayView={setDayView} />
                            </div>
                            <div hidden={view !== AmenityView.EDIT}>
                                <EditAmenity amenity={amenity} dayView={dayView} setDayView={setDayView} done={(amenity: Amenity) => {
                                    setAmenity(amenity)
                                    setView(AmenityView.OVERVIEW);
                                }}/>
                            </div>
                            <div hidden={view !== AmenityView.RESERVATIONS}>
                                <AmenityReservations amenity={amenity} dayView={dayView} setDayView={setDayView} setReservationCount={setReservationCount} />
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default ManageAmenity;
