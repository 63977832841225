import * as React from 'react';
import {useEffect, useState} from 'react';
import {doRegisterRedirect} from "../../Auth";
import {APP_PORTFOLIO_LANDING} from "../../types";


const SignupCallbackHandler: React.FunctionComponent = () => {

    const [doError, setDoError] = useState(false);

    useEffect(() => {
       doRegisterRedirect(APP_PORTFOLIO_LANDING);
    }, []);

    return (<>
        <h3 hidden={!doError} className={"text-center pt-3"}>There was an error processing your request.</h3>
    </>);
};

export default SignupCallbackHandler;