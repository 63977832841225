import * as React from "react";
import {Redirect, Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import AutoSelectProperty from "../../Components/Form/SelectProperty/AutoSelectProperty";
import ManageMaintenanceRequest from "../../Components/MaintenanceRequest/Manage/ManageMaintenanceRequest";
import ViewMaintenanceRequests from "../../Components/MaintenanceRequest/View/ViewMaintenanceRequests";
import AddMaintenanceRequest from "../../Components/MaintenanceRequest/Add/AddMaintenanceRequest";

const MaintenanceContainer: React.FunctionComponent = () => {
    let {path} = useRouteMatch();
    return (
        <>
            <Switch>
                <Route exact path={path}>
                    <Redirect to={path + "/maintenance-requests"} />
                </Route>
                <Route exact path={path + "/maintenance-requests"}>
                    <AutoSelectProperty name={"Maintenance Requests"} />
                </Route>
                <Route exact path={path + "/maintenance-requests/:propertyId/manage/:maintenanceRequestId"}>
                    <ManageMaintenanceRequest />
                </Route>
                <Route exact path={path + "/maintenance-requests/:propertyId"}>
                    <ViewMaintenanceRequests />
                </Route>
                <Route exact path={path + "/maintenance-requests/:propertyId/add"}>
                    <AddMaintenanceRequest />
                </Route>
            </Switch>
        </>
    )
}

export default MaintenanceContainer;
