import React, {FormEvent, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import Alert, {AlertProps, AlertType} from "../../Alert";
import FormFieldBuilder, {assignFormFieldValues} from "../../Form/FieldBuilder/FormFieldBuilder";
import CommunityEventForm from "../Forms/CommunityEventForm";
import {createCommunityEvent} from "../../../Api/CommunityEventsApi";
import moment from "moment";
import Modal from "../../Modal";

const AddCommunityEvent: React.FunctionComponent<{doCancel: Function, doSubmit: Function}> = ({doCancel, doSubmit}) => {

    const { propertyId } = useParams();
    const history = useHistory();

    const [alert, setAlert] = useState<AlertProps>();
    const [error, setError] = useState<Error>();

    const [name, setName] = useState<string>("");
    const [category, setCategory] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [startDate, setStartDate] = useState<string>("");
    const [startTime, setStartTime] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [endTime, setEndTime] = useState<string>("");

    const [isValid, setIsValid] = useState<boolean>(false);

    assignFormFieldValues({field: CommunityEventForm.name, value: name, setValue: setName});
    assignFormFieldValues({field: CommunityEventForm.category, value: category, setValue: setCategory});
    assignFormFieldValues({field: CommunityEventForm.description, value: description, setValue: setDescription});
    assignFormFieldValues({field: CommunityEventForm.startDate, value: startDate, setValue: setStartDate});
    assignFormFieldValues({field: CommunityEventForm.startTime, value: startTime, setValue: setStartTime});
    assignFormFieldValues({field: CommunityEventForm.endDate, value: endDate, setValue: setEndDate});
    assignFormFieldValues({field: CommunityEventForm.endTime, value: endTime, setValue: setEndTime});

    const onSubmit = () => {
        if (isValid) {
            createCommunityEvent(propertyId, {
                name,
                category,
                description,
                startTime: startDate + "T" + startTime + ":00",
                endTime: endDate + "T" + endTime + ":00",
            })
                .then(() => doSubmit())
                .catch(setError)
        }
    }

    const onReset = (e: FormEvent) => {
        setName("");
        setCategory("");
        setDescription("");
        setStartDate("");
        setStartTime("");
        setEndDate("");
        setEndTime("");
    }

    useEffect(() => {
        const isNameValid = name !== "";
        const isCategoryValid = category !== "";
        const isDescriptionValid = description !== "";
        const isStartDateValid = startDate !== "";
        const isStartTimeValid = startTime !== "";
        const isEndDateValid = endDate !== "";
        const isEndTimeValid = endTime !== "";
        setIsValid(isNameValid && isCategoryValid && isDescriptionValid && isStartDateValid && isStartTimeValid && isEndDateValid && isEndTimeValid);
    }, [name, category, description, startDate, startTime, endDate, endTime])

    return <>
        <Modal show={true} title={"Add Community Event"} cancelButtonText={"Cancel"} okButtonText={"Add"} doCancel={doCancel} doOk={onSubmit} disableOk={!isValid}>
             <div className="row p-1">
                 {error && <Alert type={AlertType.DANGER} message={error.message} />}
                    <div className="col-12 mb-2">
                        <FormFieldBuilder field={CommunityEventForm.name} />
                    </div>
                    <div className="col-12 mb-2">
                        <FormFieldBuilder field={CommunityEventForm.category} />
                    </div>
                    <div className="col-12 mb-2">
                        <FormFieldBuilder field={CommunityEventForm.description} />
                    </div>
                    <div className="col-6 mb-2">
                        <FormFieldBuilder field={CommunityEventForm.startDate} />
                    </div>
                    <div className="col-6 mb-2">
                        <FormFieldBuilder field={CommunityEventForm.startTime} />
                    </div>
                    <div className="col-6 mb-2">
                        <FormFieldBuilder field={CommunityEventForm.endDate} />
                    </div>
                    <div className="col-6 mb-2">
                        <FormFieldBuilder field={CommunityEventForm.endTime} />
                    </div>
                </div>
        </Modal>
    </>
}

export default AddCommunityEvent;
