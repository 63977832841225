import {FormBuilderField, FormFieldType} from "../../../Components/Form/FieldBuilder/FormFieldBuilder";
import {formSelectValidate, GenericSelectRegex} from "../../../types";


interface SubscriptionForm {
    subscriptionPlan: FormBuilderField,
    billingFrequency: FormBuilderField
}

const SubscriptionForm: SubscriptionForm = {
     subscriptionPlan: {
         type: FormFieldType.SELECT,
         element: {
             id: 'subscriptionPlan',
             required: true,
             validate: formSelectValidate,
             regex: GenericSelectRegex
         },
         label: 'Subscription Plan',
         defaultSelectOptions: [
             {
                 value: "",
                 label: "Please select one...",
                 disabled: true
             },

         ],
         value: undefined,
         setValue: () => {
         }
     },
    billingFrequency: {
        type: FormFieldType.SELECT,
        element: {
            id: 'billingFrequency',
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        label: 'Billing Frequency',
        defaultSelectOptions: [
            {
                value: "monthly",
                label: "Monthly",
                disabled: false
            },
            {
                value: "yearly",
                label: "Yearly",
                disabled: false
            },

        ],
        value: undefined,
        setValue: () => {
        }
    },
}

export default SubscriptionForm;