import {
    DateRegex,
    formInputValidate,
    formSelectValidate,
    FullNameRegex,
    GenericInputRegex,
    UuidRegex
} from "../../../types";
import {FormBuilderField, FormFieldType, InputFieldType} from "../../Form/FieldBuilder/FormFieldBuilder";

interface AddTenantPackageForm {
    tenantAccountId: FormBuilderField
    addressedToName: FormBuilderField,
    description: FormBuilderField,
    dateReceived: FormBuilderField,
    pickupInstructions: FormBuilderField
}

const AddTenantPackageForm: AddTenantPackageForm = {
    tenantAccountId: {
        type: FormFieldType.SELECT,
        element: {
            id: 'tenantAccountId',
            required: true,
            validate: formSelectValidate,
            regex: UuidRegex
        },
        label: 'Unit',
        defaultSelectOptions: [{
            value: '',
            label: 'Please select a unit...',
            disabled: true
        }],
        value: undefined,
        setValue: () => {}
    },
    addressedToName: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.TEXT,
        element: {
            id: 'addressedToName',
            required: true,
            validate: formInputValidate,
            regex: FullNameRegex
        },
        label: 'Addressed To',
        value: undefined,
        setValue: () => {}
    },
    description: {
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 2,
        element: {
            id: 'description',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Description',
        value: undefined,
        setValue: () => {}
    },
    dateReceived: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.DATE,
        element: {
            id: 'dateReceived',
            required: false,
            validate: formInputValidate,
            regex: DateRegex
        },
        label: 'Date Received',
        value: undefined,
        setValue: () => {}
    },
    pickupInstructions: {
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 3,
        element: {
            id: 'pickupInstructions',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Pickup Instructions',
        value: undefined,
        setValue: () => {}
    }
}

export default AddTenantPackageForm;
