import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import StateSelect from "../../StateSelect";
import Alert, {AlertType} from "../../Alert";
import {
    Address,
    AddressLine1Regex,
    AddressLine2Regex,
    APP_PROPERTY_ID,
    BankStatementNameRegex,
    Business,
    CityRegex, DollarAmountRegex,
    EINRegex,
    EmailRegex,
    FirstNameRegex,
    formInputValidate,
    formSelectValidate,
    GenericSelectRegex,
    GetBankAccount,
    LastNameRegex, NumberGreaterThanZeroRegex, PercentRegex,
    PhoneNumberRegex,
    PropertyNameRegex,
    SsnRegex,
    USAccountNameRegex,
    USBankAccountNumberRegex,
    USBankRoutingNumberRegex,
    ZipRegex
} from "../../../types";
import Input from "../../Form/Input";
import Select from "../../Form/Select";
import {addProperty} from "../../../Api/PropertyApi";
import PageLayout from "../../../Layouts/PageLayout";
import Card from "../../Card";
import {addOwner, addTrustAccount, getOwners, getTrustAccounts} from "../../../Api/PortfolioApi";
import {getPortfolioId} from "../../../helpers";


const AddProperty: React.FunctionComponent = () => {

    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(AlertType.SUCCESS);

    const [name, setName] = useState("");
    const [bankStatementName, setBankStatementName] = useState("");
    const [emailReplyTo, setEmailReplyTo] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");

    const [businessStructure, setBusinessStructure] = useState("");
    const [ein, setEin] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [phone, setPhone] = useState("");
    const [ssn, setSsn] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [businessAddressLine1, setBusinessAddressLine1] = useState("");
    const [businessAddressLine2, setBusinessAddressLine2] = useState("");
    const [businessCity, setBusinessCity] = useState("");
    const [businessState, setBusinessState] = useState("");
    const [businessZip, setBusinessZip] = useState("");

    const [accountHolderName, setAccountHolderName] = useState("");
    const [accountType, setAccountType] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");

    const [rentTrustAccountHolderName, setRentTrustAccountHolderName] = useState("");
    const [rentTrustAccountType, setRentTrustAccountType] = useState("");
    const [rentTrustAccountNumber, setRentTrustAccountNumber] = useState("");
    const [rentTrustRoutingNumber, setRentTrustRoutingNumber] = useState("");

    const [securityTrustAccountHolderName, setSecurityTrustAccountHolderName] = useState("");
    const [securityTrustAccountType, setSecurityTrustAccountType] = useState("");
    const [securityTrustAccountNumber, setSecurityTrustAccountNumber] = useState("");
    const [securityTrustRoutingNumber, setSecurityTrustRoutingNumber] = useState("");

    const [useTrustAccounts, setUseTrustAccounts] = useState(false);
    const [doAddNewRentTrustAccount, setDoAddNewRentTrustAccount] = useState(false);
    const [doAddNewSecurityDepositTrustAccount, setDoAddNewSecurityDepositTrustAccount] = useState(false);
    const [selectedRentTrustAccount, setSelectedRentTrustAccount] = useState("");
    const [selectedSecurityDepositTrustAccount, setSelectedSecurityDepositTrustAccount] = useState("");
    const [trustAccounts, setTrustAccounts] = useState<GetBankAccount[]>([]);
    const [loadingTrustAccounts, setLoadingTrustAccounts] = useState(false);
    const [loadingAddRentTrustAccount, setLoadingAddRentTrustAccount] = useState(false);
    const [loadingAddSecurityDepositAccount, setLoadingAddSecurityDepositAccount] = useState(false);
    const [addRentTrustError, setAddRentTrustError] = useState("");
    const [addSecurityTrustError, setAddSecurityTrustError] = useState("");
    const [loadingOwners, setLoadingOwners] = useState(false);
    const [owners, setOwners] = useState<Business[]>([]);
    const [doAddOwner, setDoAddOwner] = useState(false);
    const [selectedOwner, setSelectedOwner] = useState("");
    const [loadingAddOwner, setLoadingAddOwner] = useState(false);
    const [addOwnerError, setAddOwnerError] = useState("");
    const [managementFeePercent, setManagementFeePercent] = useState("");

    const [reserveAmount, setReserveAmount] = useState("")


    function load() {
        setLoadingTrustAccounts(true);
        getTrustAccounts(getPortfolioId())
            .then(tz => {
                setTrustAccounts(tz);
            })
            .catch((e) => {
                console.error(e.message);
            })
            .finally(() => setLoadingTrustAccounts(false));

        setLoadingOwners(true);
        getOwners(getPortfolioId())
            .then((oz) => {
                setOwners(oz);
            })
            .catch((e) => console.error(e.message))
            .finally(() => setLoadingOwners(false));
    }

    useEffect(() => {
        load();
    }, []);




    function handleOwner() {
        setLoadingAddOwner(true);
        addOwner(getPortfolioId(), {
            businessStructure: businessStructure,
            ein: ein,
            person: {
                firstName: firstName,
                lastName: lastName,
                ssn: ssn
            },
            name: businessName,
            phone: phone,
            typeOfBusiness: (businessStructure === "individual") ? "individual" : "company",
            address: {
                addressLine1: businessAddressLine1,
                addressLine2: businessAddressLine2,
                city: businessCity,
                stateCode: businessState,
                zip: businessZip
            }
        })
            .then((b) => {
                load();
                setSelectedOwner(b.uuid || "");
                setDoAddOwner(false);
            })
            .catch((e) => setAddOwnerError(e.message))
            .finally(() => setLoadingAddOwner(false));
    }


    function handleRentTrustAccount() {
        setLoadingAddRentTrustAccount(true);
        addTrustAccount(getPortfolioId(), {
            accountHolderName: rentTrustAccountHolderName,
            accountNumber: rentTrustAccountNumber,
            accountType: rentTrustAccountType,
            routingNumber: rentTrustRoutingNumber
        })
            .then((t) => {
                load();
                setSelectedRentTrustAccount(t.uuid);
                setDoAddNewRentTrustAccount(false);
            })
            .catch((e) => setAddRentTrustError(e.message))
            .finally(() => setLoadingAddRentTrustAccount(false))
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        doHideAlert();

        setLoading(true);

        addProperty({
            name: name,
            managementFeePercent: parseFloat(managementFeePercent),
            bankStatementName: bankStatementName,
            emailReplyTo: emailReplyTo,
            address: {
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                stateCode: state,
                zip: zip
            },
            reserveAmount: parseFloat(reserveAmount),
            ownerId: selectedOwner,
            rentTrustAccountId: selectedRentTrustAccount ? selectedRentTrustAccount : undefined,
            securityDepositTrustAccountId: selectedSecurityDepositTrustAccount ? selectedSecurityDepositTrustAccount : undefined
        })
            .then((property) => {
                localStorage.setItem(APP_PROPERTY_ID, property.uuid)
                window.location.replace("/p/m/realestate/properties")
                //doShowAlert("Successfully added property: " + name, AlertType.SUCCESS);
                //clearForm();
            })
            .catch(e => {
                doShowAlert("Failed adding property: " + e.message, AlertType.DANGER);
            }).finally(() => setLoading(false))
    }


    const doShowAlert = (message: string, type: AlertType) => {
        setShowAlert(true);
        setAlertMessage(message);
        setAlertType(type);
    }

    const doHideAlert = () => {
        setShowAlert(false);
    }

    const clearForm = () => {
        setName("");
        setBankStatementName("");
        setEmailReplyTo("");
        setAddressLine1("");
        setAddressLine2("");
        setCity("");
        setState("");
        setZip("");
        setBusinessName("");
        setEin("");
        setPhone("");
        setBusinessStructure("");
        setBusinessAddressLine1("");
        setBusinessAddressLine2("");
        setBusinessCity("");
        setBusinessState("");
        setBusinessZip("");
        setAccountHolderName("");
        setAccountType("");
        setRoutingNumber("");
        setAccountNumber("");
        setReserveAmount("");
        setManagementFeePercent("");
    }

    function getAddNewRentTrustAccountContent() {
        if (!useTrustAccounts) {
            return;
        }
        if (doAddNewRentTrustAccount) {
            return <div className="row form-container">
                {getRentTrustAccountHeader()}

                <div className={"col-md-6 mb-3"}>
                    <Input id={"accountHolderName"} validation={USAccountNameRegex.expression}
                           doValidate={formInputValidate}
                           value={rentTrustAccountHolderName}
                           setValue={setRentTrustAccountHolderName}
                           required={true}
                           invalidFeedbackText={USAccountNameRegex.helpText}
                           type={"text"} label={"Account Holder Name"}
                    />
                    <p>The name as it appears on your bank statement.</p>
                </div>
                <div className={"col-md-6 mb-3"}>

                    <Select id={"accountHolderType"} validation={GenericSelectRegex.expression}
                            doValidate={formSelectValidate} value={rentTrustAccountType}
                            setValue={setRentTrustAccountType}
                            required={true}
                            invalidFeedbackText={GenericSelectRegex.helpText} label={"Account Type"}>
                        <option disabled={true} value={""}>Select a type...</option>
                        <option value={"personal_checking"}>Personal Checking</option>
                        <option value={"personal_savings"}>Personal Savings</option>
                        <option value={"business_checking"}>Business Checking</option>
                        <option value={"business_savings"}>Business Savings</option>
                    </Select>
                </div>
                <div className={"col-md-6 mb-3"}>

                    <Input id={"routingNumber"} validation={USBankRoutingNumberRegex.expression}
                           doValidate={formInputValidate}
                           value={rentTrustRoutingNumber}
                           setValue={setRentTrustRoutingNumber}
                           required={true}
                           invalidFeedbackText={USBankRoutingNumberRegex.helpText}
                           type={"text"} label={"Routing Number"}
                    />
                </div>
                <div className={"col-md-6 mb-3"}>

                    <Input id={"accountNumber"} validation={USBankAccountNumberRegex.expression}
                           doValidate={formInputValidate}
                           value={rentTrustAccountNumber}
                           setValue={setRentTrustAccountNumber}
                           required={true}
                           invalidFeedbackText={USBankAccountNumberRegex.helpText}
                           type={"text"} label={"Account Number"}
                    />
                </div>
                <div className={"col-md-12 mb-3 mr-3"}>
                    <button disabled={loadingAddRentTrustAccount} type={"button"} className={"btn btn-primary"}
                            onClick={(e) => {
                                e.preventDefault();
                                handleRentTrustAccount();

                            }}>
                        Save
                        <span hidden={!loadingAddRentTrustAccount}>
                           <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                           <span className="visually-hidden" role="status">Saving...</span>
                       </span>
                    </button>
                    <button type={"button"} className={"btn btn-danger"} onClick={(e) => {
                        e.preventDefault();
                        setDoAddNewRentTrustAccount(false);
                    }}>Cancel
                    </button>
                </div>
                <div className={"col-md-12 mt-3 mr-3"}>
                    {addRentTrustError && <Alert type={AlertType.DANGER} message={addRentTrustError}/>}
                </div>
            </div>
        } else {
            return <div className="row form-container">
                {getRentTrustAccountHeader()}
                <div className={"col-md-5 mb-3"}>
                    <Select id={"rentTrustAccount"} validation={GenericSelectRegex.expression}
                            doValidate={formSelectValidate} value={selectedRentTrustAccount}
                            setValue={setSelectedRentTrustAccount} required={false}
                            invalidFeedbackText={GenericSelectRegex.helpText} label={"Choose a Trust Account"}>
                        <option disabled={true} value={""}>Select one...</option>
                        {
                            trustAccounts.map((t, index) => (
                                <option key={t.uuid} value={t.uuid}>{t.name}</option>
                            ))
                        }
                    </Select>
                </div>
                <div className={"col-md-1 mb-3"} style={{textAlign: 'center', paddingTop: 28}}>
                    -or-
                </div>
                <div className={"col-md-6 mb-3"} style={{paddingTop: 20}}>
                    <button type={"button"} className={"btn btn-primary"} onClick={(e) => {
                        e.preventDefault();
                        setDoAddNewRentTrustAccount(true);
                    }}>Create Rent Trust Account
                    </button>
                </div>
            </div>
        }
    }

    function getRentTrustAccountHeader() {
        return <div className={"col-md-12 mb-3"}>
            <h5>Rent Trust Account</h5>
        </div>
    }

    function getSecurityDepositTrustAccountHeader() {
        return <div className={"col-md-12 mb-3"}>
            <h5>Security Deposit Trust Account</h5>
        </div>
    }

    function handleSecurityTrustAccount() {
        setLoadingAddSecurityDepositAccount(true);
        addTrustAccount(getPortfolioId(), {
            accountHolderName: securityTrustAccountHolderName,
            accountNumber: securityTrustAccountNumber,
            accountType: securityTrustAccountType,
            routingNumber: securityTrustRoutingNumber
        })
            .then((t) => {
                load();
                setSelectedSecurityDepositTrustAccount(t.uuid);
                setDoAddNewSecurityDepositTrustAccount(false);
            })
            .catch((e) => setAddSecurityTrustError(e.message))
            .finally(() => setLoadingAddSecurityDepositAccount(false))
    }

    function getAddNewSecurityDepositTrustAccountContent() {
        if (!useTrustAccounts) {
            return;
        }
        if (doAddNewSecurityDepositTrustAccount) {
            return <div className="row form-container">
                {getSecurityDepositTrustAccountHeader()}

                <div className={"col-md-6 mb-3"}>
                    <Input id={"accountHolderName"} validation={USAccountNameRegex.expression}
                           doValidate={formInputValidate}
                           value={securityTrustAccountHolderName}
                           setValue={setSecurityTrustAccountHolderName}
                           required={true}
                           invalidFeedbackText={USAccountNameRegex.helpText}
                           type={"text"} label={"Account Holder Name"}
                    />
                    <p>The name as it appears on your bank statement.</p>
                </div>
                <div className={"col-md-6 mb-3"}>

                    <Select id={"accountHolderType"} validation={GenericSelectRegex.expression}
                            doValidate={formSelectValidate} value={securityTrustAccountType}
                            setValue={setSecurityTrustAccountType}
                            required={true}
                            invalidFeedbackText={GenericSelectRegex.helpText} label={"Account Type"}>
                        <option disabled={true} value={""}>Select a type...</option>
                        <option value={"personal_checking"}>Personal Checking</option>
                        <option value={"personal_savings"}>Personal Savings</option>
                        <option value={"business_checking"}>Business Checking</option>
                        <option value={"business_savings"}>Business Savings</option>
                    </Select>
                </div>
                <div className={"col-md-6 mb-3"}>

                    <Input id={"routingNumber"} validation={USBankRoutingNumberRegex.expression}
                           doValidate={formInputValidate}
                           value={securityTrustRoutingNumber}
                           setValue={setSecurityTrustRoutingNumber}
                           required={true}
                           invalidFeedbackText={USBankRoutingNumberRegex.helpText}
                           type={"text"} label={"Routing Number"}
                    />
                </div>
                <div className={"col-md-6 mb-3"}>

                    <Input id={"accountNumber"} validation={USBankAccountNumberRegex.expression}
                           doValidate={formInputValidate}
                           value={securityTrustAccountNumber}
                           setValue={setSecurityTrustAccountNumber}
                           required={true}
                           invalidFeedbackText={USBankAccountNumberRegex.helpText}
                           type={"text"} label={"Account Number"}
                    />
                </div>
                <div className={"col-md-12 mb-3"}>
                    <button disabled={loadingAddSecurityDepositAccount} type={"button"} className={"btn btn-primary"}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSecurityTrustAccount();

                            }}>
                        Save
                        <span hidden={!loadingAddSecurityDepositAccount}>
                           <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                           <span className="visually-hidden" role="status">Saving...</span>
                       </span>
                    </button>
                    <button type={"button"} className={"btn btn-danger"} onClick={(e) => {
                        e.preventDefault();
                        setDoAddNewSecurityDepositTrustAccount(false);
                    }}>Cancel
                    </button>
                </div>
                <div className={"col-md-12 mt-3 mr-3"}>
                    {addSecurityTrustError && <Alert type={AlertType.DANGER} message={addSecurityTrustError}/>}
                </div>
            </div>
        } else {
            return <div className="row form-container">
                {getSecurityDepositTrustAccountHeader()}
                <div className={"col-md-5 mb-3"}>
                    <Select id={"secTrustAccount"} validation={GenericSelectRegex.expression}
                            doValidate={formSelectValidate} value={selectedSecurityDepositTrustAccount}
                            setValue={setSelectedSecurityDepositTrustAccount} required={false}
                            invalidFeedbackText={GenericSelectRegex.helpText} label={"Choose a Trust Account"}>
                        <option disabled={true} value={""}>Select one...</option>
                        {
                            trustAccounts.map((t, index) => (
                                <option key={t.uuid} value={t.uuid}>{t.name}</option>
                            ))
                        }
                    </Select>
                </div>
                <div className={"col-md-1 mb-3"} style={{textAlign: 'center', paddingTop: 28}}>
                    -or-
                </div>
                <div className={"col-md-6 mb-3"} style={{paddingTop: 20}}>
                    <button type={"button"} className={"btn btn-primary"} onClick={(e) => {
                        e.preventDefault();
                        setDoAddNewSecurityDepositTrustAccount(true);
                    }}>Create Security Deposit Trust Account
                    </button>
                </div>
            </div>
        }
    }

    function clearTrustAccounts() {
        setSelectedRentTrustAccount("");
        setSelectedSecurityDepositTrustAccount("");
    }

    function getSSNorEIN() {
        if(businessStructure && businessStructure !== "individual" && businessStructure !== "single_member_llc") {
            return <div className={"col-md-6 mb-2"}>
                <Input id={"ein"} validation={EINRegex.expression}
                       doValidate={formInputValidate}
                       value={ein}
                       setValue={setEin}
                       required={false}
                       invalidFeedbackText={EINRegex.helpText}
                       type={"text"} label={"EIN"}
                />
            </div>
        } else if(businessStructure === "individual" || businessStructure === "single_member_llc") {
            return <div className={"col-md-6 mb-2"}>
                <Input id={"ssn"} validation={SsnRegex.expression}
                       doValidate={formInputValidate}
                       value={ssn}
                       setValue={setSsn}
                       required={false}
                       invalidFeedbackText={SsnRegex.helpText}
                       type={"text"} label={"SSN"}
                />
            </div>
        }
    }

    function getName() {
        if(businessStructure && businessStructure !== "individual" && businessStructure !== "single_member_llc") {
            return <><div className={"col-md-6"}>
                <Input id={"businessName"} validation={PropertyNameRegex.expression}
                       doValidate={formInputValidate}
                       value={businessName}
                       setValue={setBusinessName}
                       required={true}
                       invalidFeedbackText={PropertyNameRegex.helpText}
                       type={"text"} label={"Legal Name"}
                />
                <p>Entities legal name e.g. The View LLC.</p>
            </div>
                <div className={"col-md-6"}>
                    <Input id={"firstName"} validation={FirstNameRegex.expression}
                           doValidate={formInputValidate}
                           value={firstName}
                           setValue={setFirstName}
                           required={true}
                           invalidFeedbackText={FirstNameRegex.helpText}
                           type={"text"} label={"Manager First Name"}
                    />
                </div>
                <div className={"col-md-6"}>
                    <Input id={"lastName"} validation={LastNameRegex.expression}
                           doValidate={formInputValidate}
                           value={lastName}
                           setValue={setLastName}
                           required={true}
                           invalidFeedbackText={LastNameRegex.helpText}
                           type={"text"} label={"Manager Last Name"}
                    />
                </div>
            </>
        } else if(businessStructure === "single_member_llc") {
            return <>
                <div className={"col-md-6"}>
                    <Input id={"businessName"} validation={PropertyNameRegex.expression}
                           doValidate={formInputValidate}
                           value={businessName}
                           setValue={setBusinessName}
                           required={true}
                           invalidFeedbackText={PropertyNameRegex.helpText}
                           type={"text"} label={"Legal Name"}
                    />
                    <p>Entities legal name e.g. The View LLC.</p>
                </div>
                <div className={"col-md-6"}>
                    <Input id={"firstName"} validation={FirstNameRegex.expression}
                           doValidate={formInputValidate}
                           value={firstName}
                           setValue={setFirstName}
                           required={true}
                           invalidFeedbackText={FirstNameRegex.helpText}
                           type={"text"} label={"First Name"}
                    />
                </div>
                <div className={"col-md-6"}>
                    <Input id={"lastName"} validation={LastNameRegex.expression}
                           doValidate={formInputValidate}
                           value={lastName}
                           setValue={setLastName}
                           required={true}
                           invalidFeedbackText={LastNameRegex.helpText}
                           type={"text"} label={"Last Name"}
                    />
                </div>
            </>
        } else if(businessStructure === "individual") {
            return <>
                <div className={"col-md-6"}>
                    <Input id={"firstName"} validation={FirstNameRegex.expression}
                           doValidate={formInputValidate}
                           value={firstName}
                           setValue={setFirstName}
                           required={true}
                           invalidFeedbackText={FirstNameRegex.helpText}
                           type={"text"} label={"First Name"}
                    />
                </div>
                <div className={"col-md-6"}>
                    <Input id={"lastName"} validation={LastNameRegex.expression}
                           doValidate={formInputValidate}
                           value={lastName}
                           setValue={setLastName}
                           required={true}
                           invalidFeedbackText={LastNameRegex.helpText}
                           type={"text"} label={"Last Name"}
                    />
                </div>
            </>
        }
    }

    function getBusinessInfo() {
        if (doAddOwner) {
            return <div className="row">
                <div className={"col-md-6 mb-2"}>
                    <Select id={"businessStructure"}
                            validation={GenericSelectRegex.expression}
                            disabled={false}
                            doValidate={formSelectValidate}
                            value={businessStructure} setValue={setBusinessStructure}
                            required={true}
                            invalidFeedbackText={GenericSelectRegex.helpText}
                            label={"Business Structure"}>
                        <option value="" disabled={true}>Select an option...</option>
                        <option value="sole_proprietorship">Sole Proprietorship</option>
                        <option value="single_member_llc">Single-member LLC</option>
                        <option value="multi_member_llc">Multi-member LLC</option>
                        <option value="private_partnership">Private Partnership</option>
                        <option value="public_partnership">Public Partnership</option>
                        <option value="private_corporation">Private Corporation</option>
                        <option value="public_corporation">Public Corporation</option>
                        <option value="individual">Individual</option>
                    </Select>
                </div>
                {getName()}
                {getSSNorEIN()}
                <div className={"col-md-6 mb-2"}>
                    <Input id={"phone"} validation={PhoneNumberRegex.expression}
                           doValidate={formInputValidate}
                           value={phone}
                           setValue={setPhone}
                           required={true}
                           invalidFeedbackText={PhoneNumberRegex.helpText}
                           type={"text"} label={"Phone"}
                    />
                </div>

                <div className={"col-md-6 mb-2"}>
                    <Input id={"businessaddressLine1"} validation={AddressLine1Regex.expression}
                           doValidate={formInputValidate}
                           value={businessAddressLine1}
                           setValue={setBusinessAddressLine1}
                           required={true}
                           invalidFeedbackText={AddressLine1Regex.helpText}
                           type={"text"} label={"Address"}
                    />
                </div>
                <div className={"col-md-6 mb-2"}>
                    <Input id={"businessaddressLine2"} validation={AddressLine2Regex.expression}
                           doValidate={formInputValidate}
                           value={businessAddressLine2}
                           setValue={setBusinessAddressLine2}
                           required={false}
                           invalidFeedbackText={AddressLine2Regex.helpText}
                           type={"text"} label={"Address 2"}
                    />
                </div>
                <div className={"col-md-4 mb-2"}>
                    <Input id={"businesscity"} validation={CityRegex.expression}
                           doValidate={formInputValidate}
                           value={businessCity}
                           setValue={setBusinessCity}
                           required={true}
                           invalidFeedbackText={CityRegex.helpText}
                           type={"text"} label={"City"}
                    />
                </div>
                <div className="col-md-4 mb-2">
                    <StateSelect id={"businessstate"} validation={GenericSelectRegex.expression}
                                 noSelectionText={"Select a state..."}
                                 doValidate={formSelectValidate}
                                 value={businessState} setValue={setBusinessState}
                                 required={true}
                                 invalidFeedbackText={GenericSelectRegex.helpText}
                                 label={"State"}/>
                </div>
                <div className={"col-md-4 mb-2"}>
                    <Input id={"businesszip"} validation={ZipRegex.expression}
                           doValidate={formInputValidate}
                           value={businessZip}
                           setValue={setBusinessZip}
                           required={true}
                           invalidFeedbackText={ZipRegex.helpText}
                           type={"number"} label={"Zip"}
                    />
                </div>
                <div className={"col-md-12"}>
                    <button disabled={loadingAddRentTrustAccount} type={"button"} className={"btn btn-primary"}
                            onClick={(e) => {
                                e.preventDefault();
                                handleOwner();

                            }}>
                        Save
                        <span hidden={!loadingAddRentTrustAccount}>
                           <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                           <span className="visually-hidden" role="status">Saving...</span>
                       </span>
                    </button>
                    <button type={"button"} className={"btn btn-danger"} onClick={(e) => {
                        e.preventDefault();
                        setDoAddOwner(false);
                    }}>Cancel
                    </button>
                </div>
                <div className={"col-md-12 mt-3"}>
                {addOwnerError && <Alert type={AlertType.DANGER} message={addOwnerError} />}
                </div>
            </div>
        } else {
            return <div className={"row"}>
                <div className={"col-md-5 mb-3"}>
                    <Select id={"owner"} validation={GenericSelectRegex.expression}
                            doValidate={formSelectValidate} value={selectedOwner}
                            setValue={setSelectedOwner} required={false}
                            invalidFeedbackText={GenericSelectRegex.helpText} label={"Choose an Owner"}>
                        <option disabled={true} value={""}>Select one...</option>
                        {
                            owners.map((t, index) => (
                                <option key={t.uuid} value={t.uuid}>{t.name}</option>
                            ))
                        }
                    </Select>
                </div>
                <div className={"col-md-1 mb-3"} style={{textAlign: 'center', paddingTop: 28}}>
                    -or-
                </div>
                <div className={"col-md-6 mb-3"} style={{paddingTop: 20}}>
                    <button type={"button"} className={"btn btn-primary"} onClick={(e) => {
                        e.preventDefault();
                        setDoAddOwner(true);
                    }}>Create Owner
                    </button>
                </div>
            </div>
        }
    }

    return (<>
        <PageLayout title="Add Property">
            <form onSubmit={handleSubmit}>
                <div className={"row"}>
                    <div className="col-lg-8 mb-3">
                        <Card title="1. Property Details">
                            <div className={"row"}>
                                <div className={"col-md-6"}>
                                    <Input id={"name"} validation={PropertyNameRegex.expression}
                                           doValidate={formInputValidate}
                                           value={name}
                                           setValue={setName}
                                           required={true}
                                           invalidFeedbackText={PropertyNameRegex.helpText}
                                           type={"text"} label={"Name"}
                                    />
                                    <p>The publicly displayed name of the property e.g. The View Apartments</p>
                                </div>
                                <div className={"col-md-6"}>
                                    <Input id={"bankStatement"} validation={BankStatementNameRegex.expression}
                                           doValidate={formInputValidate}
                                           value={bankStatementName}
                                           setValue={setBankStatementName}
                                           required={true}
                                           invalidFeedbackText={BankStatementNameRegex.helpText}
                                           type={"text"} label={"Bank Statement Name"}
                                    />
                                    <p>Name that will appear on a tenant's bank statement e.g. THE VIEW</p>
                                </div>
                                <div className={"col-md-6"}>
                                    <Input id={"emailReplyTo"} validation={EmailRegex.expression}
                                           doValidate={formInputValidate}
                                           value={emailReplyTo}
                                           setValue={setEmailReplyTo}
                                           required={true}
                                           invalidFeedbackText={EmailRegex.helpText}
                                           type={"text"} label={"Email Reply-to"}
                                    />
                                    <p>Automated emails sent to tenants will use this email reply-to.</p>
                                </div>
                                <div className={"col-md-6"}>
                                    <Input id={"reserveAmount"} validation={DollarAmountRegex.expression}
                                           doValidate={formInputValidate}
                                           value={reserveAmount}
                                           setValue={setReserveAmount}
                                           required={true}
                                           invalidFeedbackText={DollarAmountRegex.helpText}
                                           type={"number"} label={"Reserve Amount"}
                                           step={.01} min={0}
                                    />
                                    <p>Cash reserve amount for the property.</p>
                                </div>
                                <div className={"col-md-6"}>
                                    <Input id={"mgmtFee"} validation={PercentRegex.expression}
                                           doValidate={formInputValidate}
                                           value={managementFeePercent}
                                           setValue={setManagementFeePercent}
                                           required={true}
                                           invalidFeedbackText={PercentRegex.helpText}
                                           type={"number"} label={"Management Fee Percent"}
                                           step={.01} min={0} max={100}
                                    />
                                    <p>Management fee percent charged to owners.</p>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col-lg-8 mb-2">
                        <Card title="2. Property Address">
                            <p>Physical location of the property advertised publicly.</p>
                            <div className="row form-container">

                                <div className={"col-md-6 mb-2"}>
                                    <Input id={"addressLine1"} validation={AddressLine1Regex.expression}
                                           doValidate={formInputValidate}
                                           value={addressLine1}
                                           setValue={setAddressLine1}
                                           required={true}
                                           invalidFeedbackText={AddressLine1Regex.helpText}
                                           type={"text"} label={"Address"}
                                    />
                                </div>
                                <div className={"col-md-6 mb-2"}>
                                    <Input id={"addressLine2"} validation={AddressLine2Regex.expression}
                                           doValidate={formInputValidate}
                                           value={addressLine2}
                                           setValue={setAddressLine2}
                                           required={false}
                                           invalidFeedbackText={AddressLine2Regex.helpText}
                                           type={"text"} label={"Address 2"}
                                    />
                                </div>
                                <div className={"col-md-4 mb-2"}>
                                    <Input id={"city"} validation={CityRegex.expression}
                                           doValidate={formInputValidate}
                                           value={city}
                                           setValue={setCity}
                                           required={true}
                                           invalidFeedbackText={CityRegex.helpText}
                                           type={"text"} label={"City"}
                                    />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <StateSelect id={"state"} validation={GenericSelectRegex.expression}
                                                 noSelectionText={"Select a state..."}
                                                 doValidate={formSelectValidate}
                                                 value={state} setValue={setState}
                                                 required={true}
                                                 invalidFeedbackText={GenericSelectRegex.helpText}
                                                 label={"State"}/>
                                </div>
                                <div className={"col-md-4 mb-2"}>
                                    <Input id={"zip"} validation={ZipRegex.expression}
                                           doValidate={formInputValidate}
                                           value={zip}
                                           setValue={setZip}
                                           required={true}
                                           invalidFeedbackText={ZipRegex.helpText}
                                           type={"number"} label={"Zip"}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col-lg-8 mb-3">
                        <Card title="3. Property Owner Information">
                            <p>The US entity receiving collected funds from rent, fees, and services.</p>
                            {getBusinessInfo()}
                        </Card>
                    </div>
                    {/*<div className="col-lg-8 mb-3">*/}
                    {/*    <Card title="4. Property Owner Bank Account">*/}
                    {/*        <p>The US bank account to receive funds.</p>*/}
                    {/*        <div className="row form-container">*/}
                    {/*            <div className={"col-md-6 mb-3"}>*/}
                    {/*                <Input id={"accountHolderName"} validation={USAccountNameRegex.expression}*/}
                    {/*                       doValidate={formInputValidate}*/}
                    {/*                       value={accountHolderName}*/}
                    {/*                       setValue={setAccountHolderName}*/}
                    {/*                       required={true}*/}
                    {/*                       invalidFeedbackText={USAccountNameRegex.helpText}*/}
                    {/*                       type={"text"} label={"Account Holder Name"}*/}
                    {/*                />*/}
                    {/*                <p>The name as it appears on your bank statement.</p>*/}
                    {/*            </div>*/}
                    {/*            <div className={"col-md-6 mb-3"}>*/}

                    {/*                <Select id={"accountHolderType"} validation={GenericSelectRegex.expression}*/}
                    {/*                        doValidate={formSelectValidate} value={accountType}*/}
                    {/*                        setValue={setAccountType}*/}
                    {/*                        required={true}*/}
                    {/*                        invalidFeedbackText={GenericSelectRegex.helpText} label={"Account Type"}>*/}
                    {/*                    <option disabled={true} value={""}>Select a type...</option>*/}
                    {/*                    <option value={"personal_checking"}>Personal Checking</option>*/}
                    {/*                    <option value={"personal_savings"}>Personal Savings</option>*/}
                    {/*                    <option value={"business_checking"}>Business Checking</option>*/}
                    {/*                    <option value={"business_savings"}>Business Savings</option>*/}
                    {/*                </Select>*/}
                    {/*            </div>*/}
                    {/*            <div className={"col-md-6 mb-3"}>*/}

                    {/*                <Input id={"routingNumber"} validation={USBankRoutingNumberRegex.expression}*/}
                    {/*                       doValidate={formInputValidate}*/}
                    {/*                       value={routingNumber}*/}
                    {/*                       setValue={setRoutingNumber}*/}
                    {/*                       required={true}*/}
                    {/*                       invalidFeedbackText={USBankRoutingNumberRegex.helpText}*/}
                    {/*                       type={"text"} label={"Routing Number"}*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            <div className={"col-md-6 mb-3"}>*/}

                    {/*                <Input id={"accountNumber"} validation={USBankAccountNumberRegex.expression}*/}
                    {/*                       doValidate={formInputValidate}*/}
                    {/*                       value={accountNumber}*/}
                    {/*                       setValue={setAccountNumber}*/}
                    {/*                       required={true}*/}
                    {/*                       invalidFeedbackText={USBankAccountNumberRegex.helpText}*/}
                    {/*                       type={"text"} label={"Account Number"}*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </Card>*/}
                    {/*</div>*/}
                    <div className="col-lg-8 mb-3">
                        <Card title="4. Property Manager Trust Bank Accounts">
                            <p>Will you use a trust account to deposit funds before paying owners?</p>
                            <div className={"row"}>
                                <div className={"col-md-6 mb-3"}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="exampleRadios"
                                               id="exampleRadios1" value="option1" checked={!useTrustAccounts}
                                               onChange={(e) => {
                                                   setUseTrustAccounts(!e.target.checked);
                                                   clearTrustAccounts();
                                               }}/>
                                        <label className="form-check-label" htmlFor="exampleRadios1">
                                            No trust accounts used
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="exampleRadios"
                                               id="exampleRadios2" value="option2" checked={useTrustAccounts}
                                               onChange={(e) => {
                                                   setUseTrustAccounts(e.target.checked)
                                               }}/>
                                        <label className="form-check-label" htmlFor="exampleRadios2">
                                            Use trust accounts
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {getAddNewRentTrustAccountContent()}
                            {getAddNewSecurityDepositTrustAccountContent()}
                        </Card>
                    </div>
                </div>
                <div className={"row form-container"}>
                    <div className={"col-md-12 mb-3"}>
                        <Link to={"/p/m/realestate/properties"}>
                            <button type="button" className="btn btn-danger me-3">Cancel</button>
                        </Link>
                        <button type="submit" disabled={loading} className="btn btn-primary">Submit</button>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-lg-6 mb-3"}>
                        <Alert show={showAlert} message={alertMessage} type={alertType}/>
                    </div>
                </div>
            </form>
        </PageLayout>
    </>);
};

export default AddProperty;
