import React, {useContext, useEffect, useState} from "react"
import { getProperty } from "../../Api/PropertyApi";
import { Property } from "../../types";
import LoadingOverlay from "../LoadingOverlay";
import {PropertyIdContext} from "../../Containers/PrivateMainContainer";

interface Props {
    property?: Property
}

const ViewPropertyInfo: React.FunctionComponent<Props> = (p) => {

    const propertyId = useContext(PropertyIdContext)
    const [property, setProperty] = useState<Property>();

    useEffect(() => {
        if (!p.property && propertyId) {
            getProperty(propertyId)
                .then(data => data)
                .then(property => {
                    setProperty(property);
                })
                .catch(console.error)
        }
    }, [p.property, propertyId])

    useEffect(() => {
        if (p.property) {
            setProperty(p.property)
        }
    }, [p.property])

    return <>
    {property ?
        <div className="pt-2">
            <h5 className="mb-1">{property.name}</h5>
            <p className="mb-0">{property.address.addressLine1}</p>
            {property.address.addressLine2 && <p className="mb-0">{property.address.addressLine2}</p>}
            <p className="mb-0 small">{property.address.city}, {property.address.stateCode} {property.address.zip}</p>
        </div>
        :
        <LoadingOverlay />
    }
    </>
}


export default ViewPropertyInfo