import { HttpMethod, fetchWithAuth } from "../api";
import { getPortfolioId } from "../helpers";
import { DashboardItemsDto } from "../types";

type getDashboardItems = () => Promise<DashboardItemsDto>;

export const getDashboardItems: getDashboardItems = (async () => {
    const path = "/portfolios/" + getPortfolioId() + "/dashboard/default";
    return fetchWithAuth(path, HttpMethod.GET);
});
