import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {useLocation, useParams} from "react-router";
import {FloorPlan, Property} from "../../../types";
import LoadingOverlay from "../../LoadingOverlay";
import {Link} from "react-router-dom";
import Table from "../../Table";
import {getProperty} from "../../../Api/PropertyApi";
import {getFloorPlans} from "../../../Api/FloorPlanApi";
import PageLayout from "../../../Layouts/PageLayout";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";

const ViewFloorPlans: React.FunctionComponent = () => {

    const propertyUuid = useContext(PropertyIdContext)

    const [loading, setLoading] = useState(false);
    const [floorPlans, setFloorPlans] = useState(new Array<FloorPlan>());
    const [property, setProperty] = useState<Property>();
    let {pathname} = useLocation();

    useEffect(() => {
        if(propertyUuid) {
            setLoading(true);
            Promise.all([
                getProperty(propertyUuid)
                    .then(data => data),
                getFloorPlans(propertyUuid)
                    .then(data => data)
            ])
                .then(values => {
                    setProperty(values[0]);
                    setFloorPlans(values[1]);
                })
                .finally(() => setLoading(false));
        }
    }, [propertyUuid]);


    return (<>
            {property ?
                <PageLayout title="Floor Plans" property={property} addButtonLabel="Add Floor Plan">
                    <div className={"row"}>
                        <div className={"col-lg-8"}>
                            <Table headers={["Name", "Description"]} defaultPaginationSize={10}>
                                {
                                    floorPlans.map((fp, index) => (
                                        <tr key={fp.uuid + "_" + index}>
                                            <td>{fp.name}</td>
                                            <td>{fp.description}</td>
                                            <td><Link to={pathname + "/edit/" + fp.uuid}><button className="btn btn-sm btn-primary">Edit</button></Link></td>
                                        </tr>
                                    ))
                                }
                            </Table>
                        </div>
                    </div>
                </PageLayout>
                :
                <LoadingOverlay />
            }
        </>
    );
};

export default ViewFloorPlans;
