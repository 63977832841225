import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {
    AddressLine1Regex,
    AddressLine2Regex,
    CityRegex,
    DollarAmountRegex,
    FloorPlan,
    FormElementValidation,
    formInputValidate,
    formSelectValidate,
    GenericNumberRegex,
    GenericSelectRegex,
    Property,
    PropertyNameRegex,
    ZipRegex
} from "../../../types";
import {useHistory, useLocation, useParams} from "react-router";
import Input from "../../Form/Input";
import {AlertProps, AlertType} from "../../Alert";
import {Link} from "react-router-dom";
import LoadingOverlay from "../../LoadingOverlay";
import Select from "../../Form/Select";
import StateSelect from "../../StateSelect";
import {getProperty} from "../../../Api/PropertyApi";
import {getFloorPlans} from "../../../Api/FloorPlanApi";
import {addUnit} from "../../../Api/UnitApi";
import PageLayout from "../../../Layouts/PageLayout";
import ButtonNew, {ButtonColors, ButtonTypes} from "../../ButtonNew";
import ImageCard from "../../ImageCard";
import Card from "../../Card";

const AddUnit: React.FunctionComponent = () => {

    const [alert, setAlert] = useState<AlertProps>()
    const [loading, setLoading] = useState(false);
    const [property, setProperty] = useState<Property>();
    const [floorPlans, setFloorPlans] = useState<FloorPlan[]>([])

    const history = useHistory()
    const { pathname } = useLocation()
    const {propertyUuid} = useParams();

    const [unitName, setUnitName] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [floorPlan, setFloorPlan] = useState("");
    const [floorLevel, setFloorLevel] = useState("");
    const [deposit, setDeposit] = useState("");
    const [imageUrl, setImageUrl] = useState<string>()

    const [isValid, setIsValid] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    useEffect(() => {
        setIsValid(
            unitName !== "" &&
            addressLine1 !== "" &&
            city !== "" &&
            state !== "" &&
            zip !== "" &&
            floorPlan !== "" &&
            floorLevel !== ""
        )
    }, [unitName, addressLine1, city, state, zip, floorPlan])

    type Form = {
        unitName: FormElementValidation,
        addressLine1: FormElementValidation,
        addressLine2: FormElementValidation,
        city: FormElementValidation,
        state: FormElementValidation,
        zip: FormElementValidation,
        floorPlan: FormElementValidation,
        floorLevel: FormElementValidation,
        deposit: FormElementValidation,
    }

    const form: Form = {
        unitName: {
            id: "name",
            required: true,
            validate: formInputValidate,
            regex: PropertyNameRegex
        },
        floorLevel: {
            id: "floorLevel",
            required: true,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        deposit: {
            id: "deposit",
            required: false,
            validate: formInputValidate,
            regex: DollarAmountRegex
        },
        addressLine1: {
            id: "addressLine1",
            required: true,
            validate: formInputValidate,
            regex: AddressLine1Regex
        },
        addressLine2: {
            id: "addressLine2",
            required: false,
            validate: formInputValidate,
            regex: AddressLine2Regex
        },
        city: {
            id: "city",
            required: true,
            validate: formInputValidate,
            regex: CityRegex
        },
        state: {
            id: "state",
            required: true,
            validate: formInputValidate,
            regex: GenericSelectRegex
        },
        zip: {
            id: "zip",
            required: true,
            validate: formInputValidate,
            regex: ZipRegex
        },
        floorPlan: {
            id: "floorPlan",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        }
    }

    useEffect(() => {

        setLoading(true);
        Promise.all([
            getProperty(propertyUuid)
                .then(data => data),
            getFloorPlans(propertyUuid)
                .then(data => data)
        ])
            .then(values => {
                setProperty(values[0]);
                setFloorPlans(values[1]);

            })
            .catch((e) => console.error(e))
            .finally(() => setLoading(false));

    }, [propertyUuid]);

    const doShowAlert = (message: string, type: AlertType) => {
        setAlert({type, message})
    }

    const validateForm = (form: Form): boolean => {
        if (!property) {
            doShowAlert("Failed to add unit.  Could not retrieve property details", AlertType.DANGER);
            return false;
        }
        if (!form.unitName.validate(unitName, form.unitName.required, form.unitName.regex.expression)) {
            doShowAlert("Unit name invalid.", AlertType.DANGER);
            return false;
        }
        if (!form.addressLine1.validate(addressLine1, form.addressLine1.required, form.addressLine1.regex.expression)) {
            doShowAlert("Address invalid.", AlertType.DANGER);
            return false;
        }
        if (!form.addressLine2.validate(addressLine2, form.addressLine2.required, form.addressLine2.regex.expression)) {
            doShowAlert("Address 2 invalid.", AlertType.DANGER);
            return false;
        }
        if (!form.city.validate(city, form.city.required, form.city.regex.expression)) {
            doShowAlert("City invalid.", AlertType.DANGER);
            return false;
        }
        if (!form.state.validate(state, form.state.required, form.state.regex.expression)) {
            doShowAlert("Select a state.", AlertType.DANGER);
            return false;
        }
        if (!form.zip.validate(zip, form.zip.required, form.zip.regex.expression)) {
            doShowAlert("Zip invalid.", AlertType.DANGER);
            return false;
        }
        if (!form.floorPlan.validate(floorPlan, form.floorPlan.required, form.floorPlan.regex.expression)) {
            doShowAlert("Select a floor plan.", AlertType.DANGER);
            return false;
        }
        return true;

    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!validateForm(form)) {
            return;
        }
        setLoading(true);
        addUnit(propertyUuid, {
            name: unitName,
            floorLevel: floorLevel,
            deposit: deposit,
            address: {
                addressLine1: addressLine1,
                addressLine2: addressLine2,
                city: city,
                stateCode: state,
                zip: zip,
            },
            floorPlan: {
                uuid: floorPlan
            },
            disabled: false,
            imageUrl: imageUrl || ""
        })
            .then(data => {
                history.push(pathname.replace("add", "edit/" + data.uuid + "?success=true"))
            })
            .catch(e => {
                doShowAlert("Failed to update unit: " + e.message, AlertType.DANGER);
            })
            .finally(() => setLoading(false))
    }

    return (loading ? <LoadingOverlay /> : <>
        <PageLayout
            title="Add Unit"
            property={property}
            alert={alert}
        >
            <div className="d-flex justify-content-start gap-5">
                <div>
                    <ImageCard
                        imageUrl={imageUrl}
                        setImageUrl={setImageUrl}
                        canUpload={true}
                        onError={m => setAlert({type: AlertType.DANGER, message: m})}
                        required={true}
                    />
                </div>
                <div className="col-lg-6">
                    <Card title="General Information">
                        <div className={"row"}>
                            <div className={"col-md-6 mb-1"}>

                                <Input id={form.unitName.id} validation={form.unitName.regex.expression}
                                       doValidate={form.unitName.validate}
                                       value={unitName}
                                       setValue={setUnitName}
                                       required={form.unitName.required}
                                       invalidFeedbackText={form.unitName.regex.helpText}
                                       type={"text"} label={"Unit name"}
                                />
                            </div>
                            <div className={"col-md-6 mb-1"}>

                                <Select id={form.floorPlan.id} validation={form.floorPlan.regex.expression}
                                        doValidate={form.floorPlan.validate}
                                        value={floorPlan} setValue={setFloorPlan}
                                        required={form.floorPlan.required}
                                        invalidFeedbackText={form.floorPlan.regex.helpText} label={"Floor plan"}>
                                    <option value={""} disabled={true}>Select a floor plan</option>
                                    {
                                        floorPlans.map((fp, index) => (
                                            <option key={fp.uuid + "_" + index} value={fp.uuid}>{fp.name}</option>
                                        ))
                                    }
                                </Select>
                            </div>
                            <div className={"col-md-6 mb-1"}>

                                <Input id={form.floorLevel.id} validation={form.floorLevel.regex.expression}
                                       doValidate={form.floorLevel.validate}
                                       value={floorLevel}
                                       setValue={setFloorLevel}
                                       required={form.floorLevel.required}
                                       invalidFeedbackText={form.floorLevel.regex.helpText}
                                       type={"number"} label={"Floor level"}
                                />
                            </div>
                            <div className={"col-md-6 mb-1"}>

                                <Input id={form.deposit.id} validation={form.deposit.regex.expression}
                                       doValidate={form.deposit.validate}
                                       value={deposit}
                                       setValue={setDeposit}
                                       required={form.deposit.required}
                                       invalidFeedbackText={form.deposit.regex.helpText}
                                       type={"number"} label={"Deposit"}
                                />
                                <p>This unit will inherit the deposit amount set on the floor plan unless overridden here.</p>
                            </div>
                            <div className={"col-md-6 mb-1"}>

                                <Input id={form.addressLine1.id} validation={form.addressLine1.regex.expression}
                                       doValidate={form.addressLine1.validate}
                                       value={addressLine1}
                                       setValue={setAddressLine1}
                                       required={form.addressLine1.required}
                                       invalidFeedbackText={form.addressLine1.regex.helpText}
                                       type={"text"} label={"Address"}
                                />
                            </div>
                            <div className={"col-md-6 mb-1"}>

                                <Input id={form.addressLine2.id} validation={form.addressLine2.regex.expression}
                                       doValidate={form.addressLine2.validate}
                                       value={addressLine2}
                                       setValue={setAddressLine2}
                                       required={form.addressLine2.required}
                                       invalidFeedbackText={form.addressLine2.regex.helpText}
                                       type={"text"} label={"Address 2"}
                                />
                            </div>
                            <div className={"col-md-4 mb-1"}>
                                <Input id={form.city.id} validation={form.city.regex.expression}
                                       doValidate={form.city.validate}
                                       value={city}
                                       setValue={setCity}
                                       required={form.city.required}
                                       invalidFeedbackText={form.city.regex.helpText}
                                       type={"text"} label={"City"}
                                />
                            </div>
                            <div className="col-md-4 mb-1">
                                <StateSelect id={form.state.id} validation={form.state.regex.expression}
                                             noSelectionText={"Select a state"}
                                             doValidate={form.state.validate}
                                             value={state} setValue={setState}
                                             required={form.state.required}
                                             invalidFeedbackText={form.state.regex.helpText}
                                             label={"State"}/>
                            </div>
                            <div className={"col-md-4 mb-1"}>
                                <Input id={form.zip.id} validation={form.zip.regex.expression}
                                       doValidate={form.zip.validate}
                                       value={zip}
                                       setValue={setZip}
                                       required={form.zip.required}
                                       invalidFeedbackText={form.zip.regex.helpText}
                                       type={"number"} label={"Zip"}
                                />
                            </div>
                        </div>
                    </Card>
                    <div className="d-flex justify-content-start gap-2 mt-3">
                        <Link to={"/p/m/realestate/units/" + propertyUuid}>
                            <ButtonNew
                                type={ButtonTypes.BUTTON}
                                color={ButtonColors.SECONDARY}
                                label="Back"
                            />
                        </Link>
                        <ButtonNew
                            type={ButtonTypes.BUTTON}
                            color={ButtonColors.PRIMARY}
                            label="Submit"
                            disabled={!isValid}
                            loading={isSubmitting}
                            onClick={onSubmit}
                        />
                    </div>
                </div>
            </div>
        </PageLayout>
    </>);
};

export default AddUnit;
