import * as React from "react";
import {FormEvent, useEffect, useRef, useState} from "react";
import PageLayout from "../../../Layouts/PageLayout";
import {getCashFlowComparison, getOwners} from "../../../Api/PortfolioApi";
import {getPortfolioId} from "../../../helpers";
import {AccountBalance, CashFlowComparisonReport, PropertyReport} from "../../../types";
import moment from "moment";
import {useParams} from "react-router";
import './index.css';
import {useDownloadExcel} from "react-export-table-to-excel";
var tableToCsv = require('node-table-to-csv');


const CashFlowComparison: React.FunctionComponent = () => {
    const {reportId} = useParams();
    const tableRef = useRef(null);
    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [report, setReport] = useState<CashFlowComparisonReport>()


    useEffect(() => {
        setLoading(true);
        getCashFlowComparison(getPortfolioId(), reportId)
            .then(r => {
                setReport(r);
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }, [reportId]);

    function generateCsvFile(e: FormEvent) {
        e.preventDefault();
        var tableHtml = document.getElementById("cash-flow-comparison");
        if(tableHtml) {
            var csvText = tableToCsv(tableHtml.outerHTML);
            const dataUrl = "data:text/plain;base64," + btoa(csvText);
            var a = document.createElement('a');
            a.href = dataUrl;
            a.download = "";
            a.download = report?.ownerName + "_" + report?.portfolioName + "_cash-flow-property-comparison.csv";
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();
        }




    }

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: report?.ownerName + "_" + report?.portfolioName + "_cash-flow-property-comparison",
        sheet: 'Cash Flow Property Comparison'
    })

    function getPropertyCount() {
        if (report) {
            return report.propertyReports.length;
        }
        return 0;
    }

    function getAccountBalanceByProperty(accounts: AccountBalance[], accountId: string) {
        if (accounts) {
            let filter = accounts.filter(a => a.code === accountId);
            if (filter && filter.length > 0) {
                return filter[0].balance;
            }
        }
        return 0;
    }

    function sortPropertyList(list: PropertyReport[]) {
        return list.sort((a, b) => a.orderId - b.orderId);
    }

    function getReport() {
        if (report) {
            return <div className={"row"}>
                <div className={"col-md-12 mb-3"}>
                    <button type={"button"} className={"btn btn-primary"} onClick={e => generateCsvFile(e)}>Download CSV</button>
                    <button type={"button"} className={"btn btn-primary ms-3"} onClick={onDownload}>Download Excel</button>
                </div>
                <div className={"col-md-12 mb-3"}>
                    <table id={"cash-flow-comparison"} className={"table table-striped table-responsive"} ref={tableRef}>
                        <thead>
                        <tr>
                            <td colSpan={2+getPropertyCount()} className={"header"}>Cash Flow Property Comparison Report</td>
                        </tr>
                        <tr>
                            <td colSpan={2+getPropertyCount()} className={"header"}>{report.portfolioName}</td>
                        </tr>
                        <tr>
                            <td colSpan={2+getPropertyCount()} className={"header"}>Properties owned by: {report.ownerName}</td>
                        </tr>
                        <tr>
                            <td colSpan={2+getPropertyCount()} className={"header"}>
                                Date range: {moment(report.fromDate, "YYYY-MM-DD").format("MMM, Do, YYYY")} to {moment(report.toDate, "YYYY-MM-DD").format("MMM, Do, YYYY")}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2+getPropertyCount()} className={"header"}>Report generated on: {moment(Date.now()).format("MMM, Do, YYYY")}</td>
                        </tr>
                        <tr>
                            <td className={"header"}>Account Name</td>
                            {sortPropertyList(report.propertyReports).map((p) => (
                                <td key={p.uuid} className={"data-cell data-cell-header header"}>{p.name}</td>
                            ))}
                            <td className={"data-cell data-cell-header header"}>Total</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td colSpan={2 + getPropertyCount()} className={"header"}>
                                Operating Income and Expenses
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2 + getPropertyCount()} className={"header"}>
                                <span className={"ms-3"}>Income</span>
                            </td>
                        </tr>

                        {
                            report.incomeAccounts.filter(a => a.balance !== 0).map((a) => (
                                <tr>

                                    <td><span className={"ms-5"}>{a.code + " - " + a.name}</span></td>
                                    {sortPropertyList(report.propertyReports).map(p => (
                                        <td key={"income_" + p.uuid} className={"data-cell"}>
                                            {currencyFormat.format(getAccountBalanceByProperty(p.incomeAccounts, a.code))}
                                        </td>
                                    ))}
                                    <td className={"data-cell"}>{currencyFormat.format(a.balance)}</td>
                                </tr>
                            ))
                        }
                        <tr>

                            <td className={"header"}><span className={"ms-3"}>Total Operating Income</span></td>
                            {sortPropertyList(report.propertyReports).map(p => (
                                <td key={"totalincome_" + p.uuid} className={"data-cell total-cell header"}>
                                    <span>
                                    {currencyFormat.format(p.operatingIncome)}
                                        </span>
                                </td>
                            ))}
                            <td className={"data-cell total-cell header"}>
                                <span>
                                {currencyFormat.format(report.operatingIncome)}
                                </span>
                            </td>
                        </tr>


                        <tr>
                            <td colSpan={2 + getPropertyCount()} className={"header"}>
                                <span className={"ms-3"}>Expenses</span>
                            </td>
                        </tr>

                        {
                            report.expenseAccounts.filter(a => a.balance !== 0).map((a) => (
                                <tr>

                                    <td><span className={"ms-5"}>{a.code + " - " + a.name}</span></td>
                                    {sortPropertyList(report.propertyReports).map(p => (
                                        <td key={"expense_" + p.uuid} className={"data-cell"}>
                                            {currencyFormat.format(getAccountBalanceByProperty(p.expenseAccounts, a.code))}
                                        </td>
                                    ))}
                                    <td className={"data-cell"}>{currencyFormat.format(a.balance)}</td>
                                </tr>
                            ))
                        }
                        <tr>

                            <td className={"header"}><span className={"ms-3"} >Total Operating Expenses</span></td>
                            {sortPropertyList(report.propertyReports).map(p => (
                                <td key={"totalexpense_" + p.uuid} className={"data-cell total-cell header"}>
                                    <span>
                                    {currencyFormat.format(p.operatingExpenses)}
                                    </span>
                                </td>
                            ))}
                            <td className={"data-cell total-cell header"}>
                                <span>
                                {currencyFormat.format(report.operatingExpenses)}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2 + getPropertyCount()}></td>
                        </tr>
                        <tr>

                            <td className={"header"}><span className={"ms-3"}>NOI - Net Operating Income</span></td>
                            {sortPropertyList(report.propertyReports).map(p => (
                                <td key={"noi_" + p.uuid} className={"data-cell header"}>
                                    {currencyFormat.format(p.netOperatingIncome)}
                                </td>
                            ))}
                            <td className={"data-cell header"}>{currencyFormat.format(report.netOperatingIncome)}</td>
                        </tr>
                        <tr>
                            <td colSpan={2 + getPropertyCount()}></td>
                        </tr>
                        <tr>
                            <td colSpan={2 + getPropertyCount()} className={"header"}>
                                <span className={"ms-3 header"}>Other Items</span>
                            </td>
                        </tr>

                        {
                            report.otherAccounts.filter(a => a.balance !== 0).map((a) => (
                                <tr>

                                    <td><span className={"ms-5"}>{a.code + " - " + a.name}</span></td>
                                    {sortPropertyList(report.propertyReports).map(p => (
                                        <td key={"other_" + p.uuid} className={"data-cell"}>
                                            {currencyFormat.format(getAccountBalanceByProperty(p.otherAccounts, a.code))}
                                        </td>
                                    ))}
                                    <td className={"data-cell"}>{currencyFormat.format(a.balance)}</td>
                                </tr>
                            ))
                        }
                        <tr>

                            <td className={"header"}><span className={"ms-3"}>Total Other Items</span></td>
                            {sortPropertyList(report.propertyReports).map(p => (
                                <td key={"totalother_" + p.uuid} className={"data-cell total-cell header"}>
                                    <span>
                                    {currencyFormat.format(p.otherItems)}
                                    </span>
                                </td>
                            ))}
                            <td className={"data-cell total-cell header"}>
                                <span>
                                {currencyFormat.format(report.otherItems)}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2 + getPropertyCount()}></td>
                        </tr>
                        <tr>

                            <td className={"header"}><span className={""}>Cash Flow</span></td>
                            {sortPropertyList(report.propertyReports).map(p => (
                                <td key={"cashflow_" + p.uuid} className={"data-cell total-cell header"}>
                                    <span>
                                    {currencyFormat.format(p.cashFlow)}
                                    </span>
                                </td>
                            ))}
                            <td className={"data-cell total-cell header"}>
                                <span>
                                {currencyFormat.format(report.cashFlow)}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2 + getPropertyCount()}></td>
                        </tr>
                        <tr>

                            <td className={"header"}><span className={""}>Beginning Cash</span></td>
                            {sortPropertyList(report.propertyReports).map(p => (
                                <td key={"begincash_" + p.uuid} className={"data-cell header"}>
                                    {currencyFormat.format(p.beginningCash)}
                                </td>
                            ))}
                            <td className={"data-cell header"}>{currencyFormat.format(report.beginningCash)}</td>
                        </tr>
                        <tr>

                            <td className={"header"}><span className={""}>Beginning Cash + Cash Flow</span></td>
                            {sortPropertyList(report.propertyReports).map(p => (
                                <td key={"begincashcashflow_" + p.uuid} className={"data-cell header"}>
                                    {currencyFormat.format(p.beginningCashPlusCashFlow)}
                                </td>
                            ))}
                            <td className={"data-cell header"}>{currencyFormat.format(report.beginningCashPlusCashFlow)}</td>
                        </tr>
                        <tr>

                            <td className={"header"}><span className={""}>Actual Ending Cash</span></td>
                            {sortPropertyList(report.propertyReports).map(p => (
                                <td key={"actualendcash_" + p.uuid} className={"data-cell header"}>
                                    {currencyFormat.format(p.actualEndingCash)}
                                </td>
                            ))}
                            <td className={"data-cell header"}>{currencyFormat.format(report.actualEndingCash)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        }
        return <></>
    }

    return <PageLayout title={""} >
        {loading && <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>}
        {errorMessage && <div className="alert alert-danger" role="alert">
            {errorMessage}
        </div>}
        {getReport()}
    </PageLayout>
}

export default CashFlowComparison;