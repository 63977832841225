import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {Property} from "../../types";
import {getProperty} from "../../Api/PropertyApi";
import Alert, {AlertType} from "../Alert";
import {PropertyIdContext} from "../../Containers/PrivateMainContainer";

const PropertyHeader: React.FunctionComponent<{title: string}> = ({ title}) => {

    const propertyId = useContext(PropertyIdContext)

    const [error, setError] = useState<Error>();
    const [property, setProperty] = useState<Property>();

    useEffect(() => {
        if(propertyId) {
            getProperty(propertyId)
                .then(setProperty)
                .catch(setError)
        }
    }, [propertyId])

    return <>
        <div className="col-12 mb-3 placeholder-glow">
            {error && <Alert type={AlertType.DANGER} message={error.message} />}
            <h3>{title}</h3>
            <h5>{property ? property.name : <span className="placeholder col-3"></span>}</h5>
            {property ? property.address.addressLine1 : <span className="placeholder col-1"></span>}<br/>
            {property ? property.address.addressLine2 : <span className="placeholder col-2"></span>}<br hidden={(property?.address.addressLine2) !== undefined}/>
            {property ? property.address.city + ", " + property.address.stateCode + " " + property.address.zip : <span className="placeholder col-3"></span>}
        </div>
    </>
}

export default PropertyHeader;
