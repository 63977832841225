import * as React from 'react';
import {useEffect, useState} from 'react';
import OverviewMaintenanceRequest from "./Overview/OverviewMaintenanceRequest";
import EditMaintenanceRequest from "./Edit/EditMaintenanceRequest";
import {useParams} from "react-router";
import {
    getMaintenanceRequestById,
    MaintenanceRequest,
    MaintenanceRequestStatus,
} from "../../../Api/MaintenanceRequestApi";
import LoadingOverlay from "../../LoadingOverlay";
import moment from "moment/moment";
import PageLayout from "../../../Layouts/PageLayout";
import Card from "../../Card";

const ManageMaintenanceRequest: React.FunctionComponent = () => {

    const { maintenanceRequestId } = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [view, setView] = useState("overview");
    const [maintenanceRequest, setMaintenanceRequest] = useState<MaintenanceRequest>();

    const fetchMaintenanceRequest = () => {
        setLoading(true);
        getMaintenanceRequestById(maintenanceRequestId)
            .then(setMaintenanceRequest)
            .catch(console.error)
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchMaintenanceRequest();
    }, [maintenanceRequestId]);

    return (loading ? <LoadingOverlay /> : <>
        <PageLayout title="Maintenance Request" propertyId={maintenanceRequest?.propertyId}>
            <ul className="nav mb-2">
                <li className="nav-item">
                    <button className={view === "overview" ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                            aria-current="page"
                            onClick={(e) => {
                                e.preventDefault();
                                setView("overview");
                            }}>Overview
                    </button>
                    <div className={view === "overview" ? "triangle-down" : ""}></div>
                </li>
                <li className="nav-item">
                    <button
                        className={view === "edit" ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                        onClick={(e) => {
                            e.preventDefault();
                            setView("edit");
                        }}>Edit
                    </button>
                    <div className={view === "edit" ? "triangle-down" : ""}></div>
                </li>
            </ul>
            <Card title="Maintenance Request">
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="mr_uuid">Ticket Id</label>
                        <h5 id="mr_uuid">{maintenanceRequest?.uuid.substring(0,7)}</h5>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="mr_status">Status</label>
                        <h3 id="mr_status">
                        <span className={maintenanceRequest?.status === MaintenanceRequestStatus.OPEN ? "badge rounded-pill bg-info" : "badge rounded-pill bg-success"}>
                            {maintenanceRequest?.status.substring(0,1).toUpperCase() + "" + maintenanceRequest?.status.substring(1).toLowerCase()}
                        </span>
                        </h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="mr_pn">Property</label>
                        <h5 id="mr_pn">{maintenanceRequest?.propertyName}</h5>
                        <label htmlFor="mr_un">Unit</label>
                        <h6 id="mr_un">{maintenanceRequest?.unitName}</h6>
                        <label htmlFor="mr_aid">Account Id</label>
                        <h6 id="mr_aid">{maintenanceRequest?.accountId}</h6>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="mr_cb">Created</label>
                        <h6 id="mr_cb">
                            {
                                moment(maintenanceRequest?.createdAt, "YYYY-MM-DD").format("MMMM Do, YYYY") +
                                " by " +
                                maintenanceRequest?.createdByUser.firstName + " " +
                                maintenanceRequest?.createdByUser.lastName
                            }
                        </h6>
                        <label htmlFor="mr_ub">Updated</label>
                        <h6 id="mr_ub">
                            {
                                moment(maintenanceRequest?.scheduledDate, "YYYY-MM-DD").format("MMMM Do, YYYY") +
                                " by " +
                                maintenanceRequest?.updatedByUser.firstName + " " +
                                maintenanceRequest?.updatedByUser.lastName
                            }
                        </h6>
                    </div>
                </div>
                <div className="row">
                    <div hidden={view !== "overview"}>
                        <OverviewMaintenanceRequest maintenanceRequest={maintenanceRequest}/>
                    </div>
                    <div hidden={view !== "edit"}>
                        <EditMaintenanceRequest maintenanceRequest={maintenanceRequest} done={() => {
                            setView("overview")
                            fetchMaintenanceRequest();
                        }}/>
                    </div>
                </div>
            </Card>
        </PageLayout>
    </>);
}

export default ManageMaintenanceRequest;
