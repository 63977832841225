import React from "react";
import {Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import ViewCommunityEvents from "./View/ViewCommunityEvents";
import ManageCommunityEvent from "./Manage/ManageCommunityEvent";
import AutoSelectProperty from "../Form/SelectProperty/AutoSelectProperty";

const CommunityEventsRouter: React.FunctionComponent = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <AutoSelectProperty name={"Community Events"} />
            </Route>
            <Route exact path={path + "/:propertyId"}>
                <ViewCommunityEvents />
            </Route>
            <Route exact path={path + "/:propertyId/event/:communityEventId"}>
                <ManageCommunityEvent />
            </Route>
        </Switch>
    )
}

export default CommunityEventsRouter;
