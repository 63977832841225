import {FormBuilderField, FormFieldType} from "../../../Form/FieldBuilder/FormFieldBuilder";
import {formInputValidate, GenericInputRegex} from "../../../../types";


interface EditPropertyForm {
    propertyLogoImageUrl: FormBuilderField,
    backgroundImageUrl: FormBuilderField,
    navBarColor: FormBuilderField,
}

const EditPropertyForm: EditPropertyForm = {
    propertyLogoImageUrl: {
        type: FormFieldType.FILE,
        element: {
            id: 'propertyLogoImageUrl',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: 'Property Logo',
        value: undefined,
        accept: "image/png, image/jpeg",
        maxSize: 1048576*5,
        setValue: () => {}
    },
    backgroundImageUrl: {
        type: FormFieldType.FILE,
        element: {
            id: 'backgroundImageUrl',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Background Image',
        value: undefined,
        accept: "image/png, image/jpeg",
        maxSize: 1048576*5,
        setValue: () => {}
    },
    navBarColor: {
        type: FormFieldType.COLOR,
        element: {
            id: 'navBarColor',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Navigation Bar Color',
        value: undefined,
        setValue: () => {}
    },
}

export default EditPropertyForm;
