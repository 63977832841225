import * as React from 'react';
import {useEffect, useState} from 'react';
import {BootstrapValidateClass, ValidateElementFunction} from "../../../types";
import RequiredLabel from "../../RequiredLabel";

type SelectProps = {
    id: string,
    validation: RegExp,
    doValidate: ValidateElementFunction,
    value: string,
    setValue: Function,
    required: boolean,
    maxLength?: number,
    invalidFeedbackText: string,
    label: string,
    disabled?: boolean
}


const Select: React.FunctionComponent<SelectProps> = (p) => {

    const [validateClass, setValidateClass] = useState(BootstrapValidateClass.unvalidated);
    const {value} = p;

    useEffect(() => {
        if (!value || (value && value.length === 0 && p.required) || !p.required || p.disabled) {
            setValidateClass(BootstrapValidateClass.unvalidated);
            return;
        }
        if (value) {
            setValidateClass(BootstrapValidateClass.isvalid);
        } else {
            setValidateClass(BootstrapValidateClass.isinvalid);
        }
    }, [value, p])

    return (<>
        {p.label !== "" && <label htmlFor={p.id}>{!p.disabled && p.required ? <RequiredLabel>{p.label}</RequiredLabel> : p.label}</label>}
        <select className={"form-select custom-select d-block w-100 " + validateClass}
                id={p.id}
                disabled={p.disabled}
                required={p.required}
                value={p.value}
                onChange={e => {
                    let value = e.target.value;
                    p.setValue(value);
                }}
        >
            {p.children}
        </select>
        <div className="invalid-feedback">
            {p.invalidFeedbackText}
        </div>
    </>);
};

export default Select;
