import * as React from 'react';
import Input from "../Form/Input";
import {
    FormElementValidation,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex, Invoice, Payout,
    Property,
    UuidRegex
} from "../../types";
import {FormEvent, useEffect, useState} from "react";
import Select from "../Form/Select";
import {Link} from "react-router-dom";
import LoadingOverlay from "../LoadingOverlay";
import {getPortfolioId} from "../../helpers";
import {getProperties} from "../../Api/PropertyApi";
import {getPayouts} from "../../Api/PayoutApi";
import PageLayout from "../../Layouts/PageLayout";

const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});


const PayoutsReport: React.FunctionComponent = () => {

    const [loading, setLoading] = useState(false);
    const [properties, setProperties] = useState<Property[]>([]);
    const [payouts, setPayouts] = useState<Payout[]>([]);

    //form
    const [property, setProperty] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [status, setStatus] = useState("");

    type Form = {
        property: FormElementValidation,
        startDate: FormElementValidation,
        endDate: FormElementValidation,
        status: FormElementValidation
    }

    const form: Form = {
        property: {
            id: "property",
            required: false,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        startDate: {
            id: "startDate",
            required: false,
            validate: formSelectValidate,
            regex: GenericInputRegex
        },
        endDate: {
            id: "endDate",
            required: false,
            validate: formSelectValidate,
            regex: GenericInputRegex
        },
        status: {
            id: "status",
            required: false,
            validate: formSelectValidate,
            regex: GenericInputRegex
        }
    }

    const fetchProperties = () => {
        setLoading(true);
        getProperties({})
            .then((data) => {
                    setProperties(data);
                }
            ).finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchProperties();
    }, []);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        getPayouts(property, startDate, endDate, status)
            .then((data) => {
                setPayouts(data);
            })
            .catch((e) => {
                console.error(e);
            })
            .finally(() => setLoading(false));

    }

    return (<>
        <PageLayout title="Payouts Report">
            <div className="col-lg-8 mb-3">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className={"col-md-6 mb-2"}>
                            <Input id={form.startDate.id} validation={form.startDate.regex.expression}
                                   doValidate={form.startDate.validate}
                                   value={startDate}
                                   setValue={setStartDate}
                                   required={form.startDate.required}
                                   invalidFeedbackText={form.startDate.regex.helpText}
                                   type={"date"} label={"Start Date"}
                            />
                        </div>
                        <div className={"col-md-6 mb-2"}>
                            <Input id={form.endDate.id} validation={form.endDate.regex.expression}
                                   doValidate={form.endDate.validate}
                                   value={endDate}
                                   setValue={setEndDate}
                                   required={form.endDate.required}
                                   invalidFeedbackText={form.endDate.regex.helpText}
                                   type={"date"} label={"End Date"}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <Select id={form.property.id} validation={form.property.regex.expression}
                                    doValidate={form.property.validate}
                                    value={property} setValue={setProperty}
                                    required={form.property.required}
                                    invalidFeedbackText={form.property.regex.helpText} label={"Property"}>
                                <option value={""} disabled={false}>All properties</option>
                                {
                                    properties.map((p: Property) => (
                                        <option key={p.uuid + "_p"}
                                                value={p.uuid}>{p.name + " - " + p.address.city + ", " + p.address.stateCode}</option>
                                    ))
                                }
                            </Select>
                        </div>
                        <div className="col-md-6 mb-2">
                            <Select id={form.status.id} validation={form.status.regex.expression}
                                    doValidate={form.status.validate}
                                    value={status} setValue={setStatus}
                                    required={form.status.required}
                                    invalidFeedbackText={form.status.regex.helpText} label={"Status"}>
                                <option value={""} disabled={false}>All Statuses</option>
                                <option value={"batched"} disabled={false}>Batched</option>
                                <option value={"submitted to bank"} disabled={false}>Submitted to bank</option>
                                <option value={"settled"} disabled={false}>Settled</option>
                            </Select>
                        </div>
                    </div>
                    <button className={"btn btn-primary mt-3"}>Search</button>
                </form>
            </div>
            <div className={"table-responsive"}>
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Business</th>
                        <th scope="col">Property</th>
                        <th scope="col">Status</th>
                        <th scope="col">Amount</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        payouts.map((p, index) => (
                            <tr key={"payo_" + index}>
                                <td>{p.createdAt}</td>
                                <td>{p.property?.owner?.name}</td>
                                <td>{p.property?.name}</td>
                                <td>{p.status}</td>
                                <td>{currencyFormat.format(p.amount)}</td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                {loading && <LoadingOverlay />}
            </div>
        </PageLayout>
    </>);
};

export default PayoutsReport;
