import * as React from 'react';
import {useEffect, useState} from "react";
import {APP_OAUTH_STATE, APP_PKCE_VERIFIER, AUTH_CLIENT_ID, signIn} from "../../Auth";
import {useHistory} from "react-router";
import {OauthState} from "../../types";


const OauthCallbackHandler: React.FunctionComponent = () => {

    const [doError, setDoError] = useState(false);
    const history = useHistory();

    useEffect(() => {

        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get("code");
        const state = queryParams.get("state");
        const pkceVerifier = localStorage.getItem(APP_PKCE_VERIFIER);
        const localState = localStorage.getItem(APP_OAUTH_STATE);
        if (code && code.length > 0
            && state && state.length > 0
            && state === localState
            && pkceVerifier && pkceVerifier.length > 0) {


            signIn({
                code: code,
                codeVerifier: pkceVerifier,
                clientId: AUTH_CLIENT_ID,
                redirectUri: window.location.protocol + '//' + window.location.host + "/callback"
            }).then((o) => {
                localStorage.removeItem(APP_PKCE_VERIFIER);
                const oState:OauthState = JSON.parse(atob(state));
                const parts = oState.path.split("?");
                if(parts.length > 1) {
                    history.push({
                        pathname: parts[0],
                        search: parts[1]
                    });
                } else {
                    history.push({
                        pathname: oState.path
                    });
                }
            }).catch((e) => {
                setDoError(true);
            })

        } else {
            setDoError(true);
        }

    }, []);

    return (<>
        <h3 hidden={!doError} className={"text-center pt-3"}>There was an error processing your request.</h3>
    </>);
};

export default OauthCallbackHandler;