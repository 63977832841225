import * as React from "react";

export interface CheckboxProps {
    id: string,
    validateClass: string,
    value: boolean,
    setValue: Function,
    required: boolean,
    invalidFeedbackText: string,
    label: string,
    disabled?: boolean,
}

const Checkbox: React.FunctionComponent<CheckboxProps> = (p) => {

    return (<>
        <div className="form-check">
            {p.label !== "" && <label className="form-check-label" htmlFor={p.id}>{p.label}</label>}
            <input
                type="checkbox"
                className={"form-check-input"}
                id={p.id}
                disabled={p.disabled}
                required={p.required}
                checked={p.value}
                value=""
                onChange={e => {
                    let checked = e.target.checked
                    p.setValue(checked)
                }}
            />
        </div>
        <div className="invalid-feedback">
            {p.invalidFeedbackText}
        </div>
    </>);
}

export default Checkbox;
