import {
    FormBuilderField,
    FormFieldType,
    InputFieldType
} from "../../Form/FieldBuilder/FormFieldBuilder";
import {
    DollarAmountRegex,
    formInputValidate,
    GenericInputRegex,
    GenericSelectRegex,
    NumberGreaterThanZeroRegex
} from "../../../types";

export interface AmenityDayFormInterface {
    isOpen: FormBuilderField,
    openTime: FormBuilderField,
    closeTime: FormBuilderField,
    minReservationMinutes: FormBuilderField,
    maxReservationMinutes: FormBuilderField,
    timeslotBlockMinutes: FormBuilderField,
    cancelBeforeMinute: FormBuilderField,
    basePrice: FormBuilderField,
    pricePerMinute: FormBuilderField
}

export enum AmenityDayLabels {
    Open="Open",
    OpenTime="Open Time",
    CloseTime="Close Time",
    MinReservationLength="Min Reservation Time",
    MaxReservationLength="Max Reservation Time",
    TimeslotBlock="Timeslot Block Length",
    CancelBefore="Cancellation Window",
    BasePrice="Base Price ($)",
    PricePerMinute="Price/Minute ($)"
}

const AmenityDayForm = (label: string): AmenityDayFormInterface => {
    return {
        isOpen: {
            type: FormFieldType.SELECT,
            element: {
                id: label + 'IsOpen',
                required: true,
                validate: formInputValidate,
                regex: GenericSelectRegex
            },
            label: AmenityDayLabels.Open,
            defaultSelectOptions: [
                {
                    value: "No",
                    label: "No"
                },
                {
                    value: "Yes",
                    label: "Yes"
                }
            ],
            value: undefined,
            setValue: () => {}
        },
        openTime: {
            type: FormFieldType.INPUT,
            inputFieldType: InputFieldType.TIME,
            element: {
                id: label + 'OpenTime',
                required: true,
                validate: formInputValidate,
                regex: GenericInputRegex
            },
            label: AmenityDayLabels.OpenTime,
            value: undefined,
            setValue: () => {}
        },
        closeTime: {
            type: FormFieldType.INPUT,
            inputFieldType: InputFieldType.TIME,
            element: {
                id: label + 'CloseTime',
                required: true,
                validate: formInputValidate,
                regex: GenericInputRegex
            },
            label: AmenityDayLabels.CloseTime,
            value: undefined,
            setValue: () => {}
        },
        minReservationMinutes: {
            type: FormFieldType.TIME_LENGTH,
            min: 0,
            step: 1,
            element: {
                id: label + 'MinReservationMinutes',
                required: true,
                validate: formInputValidate,
                regex: NumberGreaterThanZeroRegex
            },
            label: AmenityDayLabels.MinReservationLength,
            value: undefined,
            setValue: () => {}
        },
        maxReservationMinutes: {
            type: FormFieldType.TIME_LENGTH,
            min: 0,
            step: 1,
            element: {
                id: label + 'MaxReservationMinutes',
                required: true,
                validate: formInputValidate,
                regex: NumberGreaterThanZeroRegex
            },
            label: AmenityDayLabels.MaxReservationLength,
            value: undefined,
            setValue: () => {}
        },
        timeslotBlockMinutes: {
            type: FormFieldType.TIME_LENGTH,
            min: 0,
            step: 1,
            element: {
                id: label + 'TimeslotBlockMinutes',
                required: true,
                validate: formInputValidate,
                regex: NumberGreaterThanZeroRegex
            },
            label: AmenityDayLabels.TimeslotBlock,
            value: undefined,
            setValue: () => {}
        },
        cancelBeforeMinute: {
            type: FormFieldType.TIME_LENGTH,
            min: 0,
            step: 1,
            element: {
                id: label + 'CancelBeforeMinute',
                required: true,
                validate: formInputValidate,
                regex: NumberGreaterThanZeroRegex
            },
            label: AmenityDayLabels.CancelBefore,
            value: undefined,
            setValue: () => {}
        },
        basePrice: {
            type: FormFieldType.INPUT,
            inputFieldType: InputFieldType.NUMBER,
            min: 0,
            step: 0.01,
            element: {
                id: label + 'BasePrice',
                required: false,
                validate: formInputValidate,
                regex: DollarAmountRegex
            },
            label: AmenityDayLabels.BasePrice,
            value: undefined,
            setValue: () => {}
        },
        pricePerMinute: {
            type: FormFieldType.INPUT,
            inputFieldType: InputFieldType.NUMBER,
            min: 0,
            step: 0.01,
            element: {
                id: label + 'PricePerMinute',
                required: false,
                validate: formInputValidate,
                regex: DollarAmountRegex
            },
            label: AmenityDayLabels.PricePerMinute,
            value: undefined,
            setValue: () => {}
        }
    }
}

export default AmenityDayForm;
