import * as React from 'react';
import {MouseEventHandler} from "react";

type ButtonProps = {
    disabled: boolean,
    loading: boolean,
    onClick: MouseEventHandler,
    type: 'submit' | 'reset' | 'button';
}

export enum ButtonTypes {
    SUBMIT="submit",
    RESET="reset",
    BUTTON="button"
}

export enum ButtonColors {
    LINK="btn-link",
    LIGHT="btn-light",
    DARK="btn-dark",
    PRIMARY="btn-primary",
    SECONDARY="btn-secondary",
    INFO="btn-info",
    WARNING="btn-warning",
    DANGER="btn-danger",
    SUCCESS="btn-success"
}

const Button: React.FunctionComponent<ButtonProps> = (p) => {
    return (<>
        <button disabled={p.disabled} type={p.type} className="btn btn-primary btn-block"
                onClick={p.onClick}
        >
            <span hidden={p.loading}>{p.children}</span>
            <div hidden={!p.loading} className="snippet" data-title=".dot-typing">
                <div className="stage">
                    <div className="dot-typing"></div>
                </div>
            </div>
        </button>
    </>);
};

export default Button;