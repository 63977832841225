import {DateRegex, formInputValidate, formSelectValidate, GenericSelectRegex, UuidRegex} from "../../../types";
import {FormBuilderField, FormFieldType, InputFieldType} from "../../Form/FieldBuilder/FormFieldBuilder";
import {TenantPackageStatus} from "../../../Api/PackageManangementApi";

interface SearchTenantPackagesForm {
    accountId: FormBuilderField,
    status: FormBuilderField,
    dateReceived: FormBuilderField
}

const ViewTenantPackagesForm: SearchTenantPackagesForm = {
    accountId: {
        type: FormFieldType.SELECT,
        element: {
            id: 'accountId',
            required: false,
            validate: formInputValidate,
            regex: UuidRegex
        },
        label: 'Unit',
        defaultSelectOptions: [{
            value: '',
            label: 'All'
        }],
        value: undefined,
        setValue: () => {}
    },
    status: {
        type: FormFieldType.SELECT,
        element: {
            id: 'status',
            required: false,
            validate: formSelectValidate,
            regex: GenericSelectRegex,
        },
        label: 'Status',
        defaultSelectOptions: [
            {
                value: TenantPackageStatus.UNKNOWN,
                label: 'Any'
            },
            {
                value: TenantPackageStatus.RECEIVED,
                label: "Received",
            },
            {
                value: TenantPackageStatus.PICKED_UP,
                label: "Picked Up"
            }
        ],
        value: undefined,
        setValue: () => {}
    },
    dateReceived: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.DATE,
        element: {
            id: 'dateReceived',
            required: false,
            validate: formInputValidate,
            regex: DateRegex
        },
        label: 'Date Received',
        value: undefined,
        setValue: () => {}
    }
}

export default ViewTenantPackagesForm;
