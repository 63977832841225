import {ARAccount} from "../types";
import {fetchWithAuth, HttpMethod} from "../api";
import {getPortfolioId} from "../helpers";

type getARAccounts = (propertyId: string) => Promise<ARAccount[]>;
type getARAccountById = (propertyId: string, accountId: string) => Promise<ARAccount>;

const pathBuilder = (propertyId: string, extension = ""): string => {
    return "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/accounts/" + extension;
}

export const getARAccounts: getARAccounts = (async propertyId => {
    const path = pathBuilder(propertyId);
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getARAccountById: getARAccountById = (propertyId, accountId) => {
    const path = pathBuilder(propertyId, accountId);
    return fetchWithAuth(path, HttpMethod.GET);
}
