import React, {FormEvent, useEffect, useState} from "react";
import './index.css';
import PageLayout from "../../Layouts/PageLayout";
import {EditorState} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {formInputValidate, GenericInputRegex, GetBusiness, LetterTemplate, Tenant} from "../../types";
import AutocompleteInput, {DataAttribute} from "../Form/AutocompleteInput";
import {
    addLetter,
    addLetterTemplate,
    getLetterTemplate,
    getLetterTemplates,
    getOwners,
    getTenants
} from "../../Api/PortfolioApi";
import {getPortfolioId} from "../../helpers";
import Input from "../Form/Input";
import {convertToRaw} from "draft-js";
import draftToHtml from 'draftjs-to-html';
import WYSIWYGEditor from "../WYSIWYGEditor";


const CommunicationLetters: React.FunctionComponent = () => {


    const [editorState, setEditorState] = useState<EditorState>()
    const [userList, setUserList] = useState<DataAttribute[]>([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("")
    const [recipients, setRecipients] = useState<DataAttribute[]>([]);
    const [tenants, setTenants] = useState<Tenant[]>([]);
    const [owners, setOwners] = useState<GetBusiness[]>([]);
    const [subject, setSubject] = useState("");
    const [initialHtmlContent, setInitialHtmlContent] = useState("");
    const [editorKey, setEditorKey] = useState("1");
    const [templates, setTemplates] = useState<LetterTemplate[]>([]);
    const [loadingTemplates, setLoadingTemplates] = useState(false);
    const [templateName, setTemplateName] = useState("");


    useEffect(() => {
        setLoading(true);
        getOwners(getPortfolioId())
            .then(rz => {

                setOwners(rz)
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));

        getTenants(getPortfolioId())
            .then((r) => {

                setTenants(r);
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));

        loadTemplates();

    }, []);

    function loadTemplates() {
        setLoadingTemplates(true);
        getLetterTemplates(getPortfolioId())
            .then((r) => {
                setTemplates(r);
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoadingTemplates(false));
    }

    useEffect(() => {
        if (tenants && owners) {
            let da: DataAttribute[] = [];
            owners.forEach(r => {
                let name = r.name;
                if (!name && r.person) {
                    name += " - " + r.person.firstName + " " + r.person.lastName;
                }
                name += " (Owner)"
                da.push({
                    uuid: "owner_" + r.uuid,
                    label: name
                })
            })
            tenants.forEach(t => {
                let label = t.lastName + ", " + t.firstName + " | " + t.property.unitName + " | " + t.property.name + " (Tenant)";
                da.push({
                    uuid: "tenant_" + t.uuid,
                    label: label
                })
            })
            setUserList([...da]);
        }
    }, [tenants, owners]);

    useEffect(() => {
        if (selectedUser) {
            let filter = userList.filter(m => m.uuid === selectedUser);
            if (filter && filter.length > 0) {
                recipients.push(filter[0]);
            }
            setSelectedUser("");
        }
    }, [recipients, selectedUser, userList]);

    function doRemoveRecipient(id: string) {
        let filter = recipients.filter(r => r.uuid !== id);
        setRecipients([...filter])
    }

    function clear() {
        setSubject("");
        setInitialHtmlContent("");
        setRecipients([]);
    }

    function doLoadTemplate(id: string) {
        setLoadingTemplates(true);
        getLetterTemplate(getPortfolioId(), id)
            .then(r => {
                setInitialHtmlContent(decodeURIComponent(atob(r.content)));
                setEditorKey(r.uuid);
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoadingTemplates(false));
    }

    function doReview(e: FormEvent) {
        e.preventDefault()
        setErrorMessage("");
        setSuccessMessage("");
        let o: string[] = [];
        let t: string[] = [];
        recipients.forEach(r => {
            if (r.uuid.startsWith("tenant_")) {
                t.push(r.uuid.replace("tenant_", ""));
            }
            if (r.uuid.startsWith("owner_")) {
                o.push(r.uuid.replace("owner_", ""));
            }
        })

        if (editorState) {
            setLoading(true);
            addLetter(getPortfolioId(), {
                subject: subject,
                body: btoa(encodeURIComponent(draftToHtml(convertToRaw(editorState.getCurrentContent())))),
                owners: o,
                tenants: t,
            })
                .then(() => {
                    setSuccessMessage("Message successfully sent!");
                    clear();
                })
                .catch(e => setErrorMessage(e.message))
                .finally(() => setLoading(false));
        }
    }

    function saveTemplate(e: FormEvent) {
        e.preventDefault();
        if (editorState) {
            addLetterTemplate(getPortfolioId(), {
                name: templateName,
                content: btoa(encodeURIComponent(draftToHtml(convertToRaw(editorState.getCurrentContent())))),
            })
                .then(r => {
                    setTemplateName("");
                    setSuccessMessage("Successfully saved template!");
                    loadTemplates();
                })
                .catch(e => setErrorMessage(e.message))
        }
    }

    return <PageLayout title={"Letters"}>
        {loading && <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>}
        {errorMessage && <div className="alert alert-danger" role="alert">
            {errorMessage}
        </div>}
        {successMessage && <div className="alert alert-success" role="alert">
            {successMessage}
        </div>}
        <div className={"row"}>
            <div className={"col-md-6"}>
                <div className={"row"}>
                    <div className={"col"}>
                        <h5>Recipients</h5>
                    </div>
                </div>
                <div className={"row mb-3"}>
                    <div className={"col-md-8"}>
                        <AutocompleteInput id={"tenant"} validation={GenericInputRegex.expression}
                                           data={userList}
                                           doValidate={formInputValidate}
                                           value={selectedUser}
                                           setValue={setSelectedUser}
                                           placeholder={"Search by owner or tenant name"}
                                           required={true}
                                           invalidFeedbackText={GenericInputRegex.helpText}
                                           type={"text"} label={"User Search"}
                        />
                    </div>
                </div>
                <div className={"row mb-3"}>
                    <div className={"col"}>
                        <button className={"btn btn-primary me-3"} type={"button"}
                                onClick={() => {
                                    let da: DataAttribute[] = [];
                                    tenants.forEach(t => {
                                        let label = t.lastName + ", " + t.firstName + " | " + t.property.unitName + " | " + t.property.name + " (Tenant)";
                                        da.push({
                                            uuid: "tenant_" + t.uuid,
                                            label: label
                                        })
                                    })
                                    setRecipients(da);
                                }}>All Tenants
                        </button>
                        <button className={"btn btn-primary me-3"} type={"button"}
                                onClick={() => {
                                    let da: DataAttribute[] = [];
                                    owners.forEach(r => {
                                        let name = r.name;
                                        if (!name && r.person) {
                                            name += " - " + r.person.firstName + " " + r.person.lastName;
                                        }
                                        name += " (Owner)"
                                        da.push({
                                            uuid: "owner_" + r.uuid,
                                            label: name
                                        })
                                    })
                                    setRecipients(da)
                                }}>All Owners
                        </button>
                        <button className={"btn btn-danger"} type={"button"}
                                onClick={() => setRecipients([])}>Clear
                        </button>
                    </div>
                </div>
                <div className={"row mb-3"} style={{maxHeight: 200, overflowY: "auto"}}>
                    <div className={"col"}>
                        {recipients.map(r => (
                            <span key={r.uuid} className="badge text-bg-light bg-secondary me-1 mb-1">{r.label}
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-x-lg ms-1" viewBox="0 0 16 16"
                                     onClick={() => doRemoveRecipient(r.uuid)}>
                      <path
                          d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                    </svg>
                </span>))}
                    </div>
                </div>

                <div className={"row"}>
                    <div className={"col"}>
                        <h5>Subject</h5>
                    </div>
                </div>
                <div className={"row mb-3"}>
                    <div className={"col-md-8"}>
                        <Input id={"subject"} validation={GenericInputRegex.expression} doValidate={formInputValidate}
                               value={subject} setValue={setSubject} required={true}
                               invalidFeedbackText={GenericInputRegex.helpText}
                               type={"text"} label={""}/>
                    </div>
                </div>
            </div>
            <div className={"col-md-6"}>
                {/*<div className={"row"}>*/}
                {/*    <div className={"col-md-12"}>*/}
                <h5>Templates</h5>
                {/*</div>*/}
                {/*</div>*/}
                <div style={{height: '100%', overflowY: "auto", maxHeight: 230}}>
                    <table className={"table letter-templates"}>
                        <tbody>
                        {templates.map(t => (
                            <tr key={t.uuid} className={"letter-templates"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    doLoadTemplate(t.uuid);
                                }}>
                                <td>{t.name}</td>
                                <td>{t.content}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className={"row"}>
            <div className={"col"}>
                <h5>Message Body</h5>
            </div>
        </div>
        <div className={"row mb-3"}>
            <div className={"col"}>
                <div style={{
                    backgroundColor: 'white', boxShadow: "0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)",
                    border: "1px solid rgb(206, 212, 218)", borderRadius: "0 !important", height: 400
                }}>
                    <WYSIWYGEditor
                        key={editorKey}
                        initialHtmlContent={initialHtmlContent}
                        onEditorChange={(e: EditorState) => {
                            setEditorState(e);
                        }}
                    />

                </div>
            </div>
        </div>
        <form onSubmit={saveTemplate}>
            <div className={"row"}>

                <div className={"col-auto"}>
                    <button className={"btn btn-primary"} type={"button"}
                            onClick={doReview}>Send
                    </button>
                </div>

                <div className={"col-auto"}>
                    <button className={"btn btn-primary"} type={"submit"}
                    >Save as Template
                    </button>
                </div>
                <div className={"col-auto"}>
                    <Input id={"subject"} validation={GenericInputRegex.expression} doValidate={formInputValidate}
                           value={templateName} setValue={setTemplateName} required={true}
                           placeholder={"Template name"}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"text"} label={""}/>
                </div>

            </div>
        </form>
    </PageLayout>
}
export default CommunicationLetters;