import {Autopay} from "../types";
import {fetchWithAuth, HttpMethod} from "../api";
import {getPortfolioId} from "../helpers";

interface AddEditAutopayBody {
    amount: number,
    dayOfMonth: number,
    startDate: string,
    stopDate: string,
    tenantBankAccount?: {
        accountHolderName: string,
        accountType: string,
        routingNumber: string,
        accountNumber: string
    }
}

type getAutopay = (leaseId: string) => Promise<Autopay[]>;
type addAutopay = (leaseId: string, body: AddEditAutopayBody) => Promise<Autopay>;
type editAutopay = (leaseId: string, autopayId: string, body: AddEditAutopayBody) => Promise<Autopay>;
type deleteAutopay = (leaseId: string, autopayId: string) => Promise<Response>;
type applyAutopayTransactions = (leaseId: string) => Promise<Response>;

export const getAutopay: getAutopay = async (leaseId) => {
    const path = "/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/autopays";
    return fetchWithAuth(path, HttpMethod.GET);
};

export const addAutopay: addAutopay = async (leaseId, body) => {
    const path = "/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/autopays";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body))
};

export const applyAutopayTransactions: applyAutopayTransactions = async (leaseId) => {
    const path = "/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/apply-autopay-transactions";
    return fetchWithAuth(path, HttpMethod.POST)
};

export const editAutopay: editAutopay = async (leaseId, autopayId, body) => {
    const path = "/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/autopays/" + autopayId;
    return fetchWithAuth(path, HttpMethod.PUT, JSON.stringify(body))
};

export const deleteAutopay: deleteAutopay = async (leaseId, autopayId) => {
    const path = "/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/autopays/" + autopayId;
    return fetchWithAuth(path, HttpMethod.DELETE);
};
