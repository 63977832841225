import React from "react";
import {ImageIcon} from "../Icons/Icons";
import RequiredLabel from "../RequiredLabel";

interface Props {
    imageUrl: string | undefined,
    setImageUrl: Function
    canUpload: boolean
    onError: (errorMessage: string) => void
    required?: boolean
}

const ImageCard: React.FunctionComponent<Props> = (p) => {

    const encodeImageFileAsURL = (element: EventTarget & HTMLInputElement) => {
        if (element.files) {
            let file = element.files[0];
            let filesize = ((file.size / 1024) / 1024);
            if (filesize > 5) {
                p.setImageUrl(undefined);
                p.onError("Image size too large. Please choose an image under 5mb.")
                return;
            }
            let reader = new FileReader();
            reader.onloadend = function () {
                p.setImageUrl(reader.result + "");
            }
            reader.readAsDataURL(file);
        }
    }

    return <>
        <div className="card border-0" style={{height: "350px", width: "350px"}}>
            <div className="card-header bg-white">
                Image{p.required && <RequiredLabel />}
            </div>
            <div className="bg-white w-100 h-100">
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                    {p.imageUrl ?
                        <img src={p.imageUrl} className="card-img" alt={p.imageUrl} />
                        :
                        <ImageIcon size="64" color="grey"></ImageIcon>
                    }
                </div>
            </div>
            {p.canUpload &&
                <div className="card-footer bg-white py-2 border-0">
                    <input
                        required={p.required}
                        type="file"
                        accept="image/png, image/jpeg"
                        className="form-control-sm"
                        onChange={(e) => {
                            encodeImageFileAsURL(e.target)
                        }}
                    />
                </div>
            }
        </div>
    </>
}

export default ImageCard