import * as React from 'react';
import {Route, Switch, useRouteMatch} from "react-router";
import ViewUnits from "./ViewUnits";
import EditUnit from "./EditUnit";
import AddUnit from "./AddUnit";
import AutoSelectProperty from "../Form/SelectProperty/AutoSelectProperty";

const ManageUnits: React.FunctionComponent = () => {

    let {path} = useRouteMatch();

    return (<>
        <Switch>
            <Route exact path={path}>
                <AutoSelectProperty name={"Units"}/>
            </Route>
            <Route exact path={path + "/:propertyUuid"}>
                <ViewUnits />
            </Route>
            <Route exact path={path + "/:propertyUuid/edit/:unitUuid"}>
                <EditUnit />
            </Route>
            <Route exact path={path + "/:propertyUuid/add"}>
                <AddUnit />
            </Route>
        </Switch>
    </>);
};

export default ManageUnits;