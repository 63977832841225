import {AppUser, IavToken, Role} from "../types";
import {fetchWithAuth, HttpMethod} from "../api";
import {getPortfolioId} from "../helpers";

type getIavToken = () => Promise<IavToken>;
type getUserProfile = () => Promise<AppUser>;
type getUsersWithMaintenanceRole = (propertyId: string) => Promise<AppUser[]>
type getUsersInPortfolio = (firstName: string, lastName: string, email: string) => Promise<AppUser[]>
type getUserInPortfolio = (userId: string) => Promise<AppUser>
type deleteUserInPortfolio = (userId: string) => Promise<Response>
type getRoles = () => Promise<Role[]>
type AddUserWithRoleBody = {
    email: string,
    roles: {
        uuid: string
    }[]
}
type addUserWithRoles = (body: AddUserWithRoleBody) => Promise<Response>

export const getIavToken: getIavToken = (async () => {
    return fetchWithAuth("/users/iavToken", HttpMethod.GET);
});

export const getUserProfile: getUserProfile = (async () => {
    return fetchWithAuth("/users/me", HttpMethod.GET);
});

export const getUsersWithMaintenanceRole: getUsersWithMaintenanceRole = (async propertyId => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/users/maintenance?propertyId=" + propertyId, HttpMethod.GET);
});

export const getUsersInPortfolio: getUsersInPortfolio = (async (firstName, lastName, email) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/users?firstName=" + firstName + "&lastName=" + lastName + "&email=" + email, HttpMethod.GET);
});

export const getUserInPortfolio: getUserInPortfolio = (async (userId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/users/" + userId, HttpMethod.GET);
});

export const deleteUserInPortfolio: deleteUserInPortfolio = (async (userId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/users/" + userId, HttpMethod.DELETE);
});

export const getRoles: getRoles = (async () => {
    return fetchWithAuth("/roles", HttpMethod.GET);
});

export const addUserWithRoles: addUserWithRoles = (async (body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/users", HttpMethod.POST, JSON.stringify(body));
});
