import {
    DateRegex,
    formInputValidate,
    formSelectValidate,
    FullNameRegex,
    GenericInputRegex
} from "../../../types";
import {FormBuilderField, FormFieldType, InputFieldType} from "../../Form/FieldBuilder/FormFieldBuilder";

interface OverviewTenantPackageForm {
    unitName: FormBuilderField,
    addressedToName: FormBuilderField,
    description: FormBuilderField,
    dateReceived: FormBuilderField,
    datePickedUp: FormBuilderField,
    pickupPersonName: FormBuilderField,
    pickupInstructions: FormBuilderField
}

const OverviewTenantPackageForm: OverviewTenantPackageForm = {
    unitName: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.TEXT,
        element: {
            id: 'unitName',
            required: false,
            validate: formSelectValidate,
            regex: GenericInputRegex
        },
        label: 'Unit Name',
        disabled: true,
        value: undefined,
        setValue: () => {}
    },
    addressedToName: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.TEXT,
        element: {
            id: 'addressedToName',
            required: false,
            validate: formInputValidate,
            regex: FullNameRegex
        },
        label: 'Addressed To',
        disabled: true,
        value: undefined,
        setValue: () => {}
    },
    description: {
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 3,
        element: {
            id: 'description',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Description',
        disabled: true,
        value: undefined,
        setValue: () => {}
    },
    dateReceived: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.TEXT,
        element: {
            id: 'dateReceived',
            required: false,
            validate: formInputValidate,
            regex: DateRegex
        },
        label: 'Date Received',
        disabled: true,
        value: undefined,
        setValue: () => {}
    },
    datePickedUp: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.TEXT,
        element: {
            id: 'datePickedUp',
            required: false,
            validate: formInputValidate,
            regex: DateRegex
        },
        label: "Date Picked Up",
        disabled: true,
        value: undefined,
        setValue: () => {}
    },
    pickupPersonName: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.TEXT,
        element: {
            id: 'pickupPersonName',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Picked Up By',
        disabled: true,
        value: undefined,
        setValue: () => {}
    },
    pickupInstructions: {
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 4,
        element: {
            id: 'pickupInstructions',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Pickup Instructions',
        disabled: true,
        value: undefined,
        setValue: () => {}
    }
}

export default OverviewTenantPackageForm;
