import * as React from 'react';
import './index.css';
import Table from "../Table";
import {useEffect, useState} from "react";
import {PastDueLease} from "../../types";
import {getPastDueLeaseReport} from "../../Api/ReportApi";
import Card from "../Card";
import LoadingOverlay from "../LoadingOverlay";


const PastDueLeases: React.FunctionComponent = () => {

    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    const [pastDueLeases, setPastDueLeases] = useState<PastDueLease[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getPastDueLeaseReport()
            .then((lz) => {
                setPastDueLeases(lz);
            })
            .catch(e => console.error(e))
            .finally(() => setLoading(false));
    }, [])

    return (
        <Card title="Past Due Leases">
            {loading ? <LoadingOverlay /> :
                <Table headers={["Lease ID","Property", "Unit", "Days Past Due", "Amount"]} defaultPaginationSize={10}>
                    {
                        pastDueLeases.map((l) => (
                            <tr key={l.id}>
                                <td>{l.easyId}</td>
                                <td>{l.propertyName}</td>
                                <td>{l.unitName}</td>
                                <td>{l.daysPastDue}</td>
                                <td>{l.amount >= 0 ? currencyFormat.format(l.amount) : "(" + currencyFormat.format(Math.abs(l.amount)) + ")"}</td>
                            </tr>
                        ))
                    }
                </Table>
            }
        </Card>
    )
};

export default PastDueLeases;
