import * as React from 'react';

export interface Tab {
    targetView: string,
    label: string
}

interface TabsParams {
    view: string,
    setView: React.SetStateAction<any>
    tabs: Tab[]
}

const Tabs: React.FunctionComponent<TabsParams> = ({view, setView, tabs}) => {
    return (<>
        <div className="row">
            <ul className="nav nav-tabs">
                {tabs.map(tab => (
                    <li key={tab.targetView} className="nav-item">
                        <button className={view === tab.targetView ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                                aria-current="page"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setView(tab.targetView);
                                }}>{tab.label}
                        </button>
                        <div className={view === tab.targetView ? "triangle-down" : ""}></div>
                    </li>
                ))}
            </ul>
        </div>
    </>)
}

export default Tabs;
