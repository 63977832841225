import * as React from "react";
import {FormEvent, useContext, useEffect, useState} from "react";
import {ARAccount, Property} from "../../../types";
import {useHistory, useParams} from "react-router";
import {getARAccounts} from "../../../Api/AccountApi";
import FormFieldBuilder, {SelectOptions} from "../../Form/FieldBuilder/FormFieldBuilder";
import LoadingOverlay from "../../LoadingOverlay";
import SearchFrontDeskInstructionsAndGuestsForm from "../Forms/SearchFrontDeskInstructionsAndGuestsForm";
import {getTenantFrontDeskInstructions, TenantFrontDeskInstruction} from "../../../Api/FrontDeskInstructionsApi";
import {getTenantGuests, TenantGuest} from "../../../Api/TenantGuestApi";
import Table from "../../Table";
import {getTicketIdFromUuid} from "../../../helpers";
import moment from "moment/moment";
import {useLocation} from "react-router-dom";
import Alert, {AlertType} from "../../Alert";
import {getProperty} from "../../../Api/PropertyApi";
import PageLayout from "../../../Layouts/PageLayout";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";
import PropertyHeader from "../../PropertyHeader/PropertyHeader";

const SearchUnits: React.FunctionComponent = () => {

    const propertyId = useContext(PropertyIdContext)
    const { pathname } = useLocation();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();
    const [property, setProperty] = useState<Property>();
    const [isValidSearch, setIsValidSearch] = useState<boolean>(false);
    const [searchAccountId, setSearchAccountId] = useState<string>("");
    const [accountSelectOptions, setAccountSelectOptions] = useState<SelectOptions[]>([]);
    const [frontDeskInstructions, setFrontDeskInstructions] = useState<TenantFrontDeskInstruction[]>([]);
    const [guests, setGuests] = useState<TenantGuest[]>([]);

    SearchFrontDeskInstructionsAndGuestsForm.accountId.value = searchAccountId;
    SearchFrontDeskInstructionsAndGuestsForm.accountId.setValue = setSearchAccountId;

    const updateAccountSelectOptions = (accounts: ARAccount[]) => {
        const options: SelectOptions[] = [];
        SearchFrontDeskInstructionsAndGuestsForm.accountId.defaultSelectOptions?.forEach(option => {
            options.push({value: option.value, label: option.label})
        });
        accounts.forEach(account => {
            options.push({value: account.uuid, label: account.unitName})
        });
        setAccountSelectOptions(options);
    }

    useEffect(() => {
        if (searchAccountId !== "") {
            setLoading(true);
            Promise.all([
                getTenantFrontDeskInstructions(propertyId, searchAccountId).then(data => data),
                getTenantGuests(propertyId, searchAccountId).then(data => data)
            ]).then(values => {
                setFrontDeskInstructions(values[0]);
                setGuests(values[1]);
            })
                .catch(setError)
                .finally(() => setLoading(false));
        }
    }, [searchAccountId]);

    useEffect(() => {
        if(propertyId) {
            setLoading(true);
            Promise.all([
                getProperty(propertyId).then(data => data),
                getARAccounts(propertyId).then(data => data),
            ]).then(values => {
                setProperty(values[0]);
                updateAccountSelectOptions(values[1]);
            })
                .catch(setError)
                .finally(() => setLoading(false));
        }
    }, [propertyId])

    return (<>
        {error && <Alert type={AlertType.DANGER} message={error.message} />}
        {property ?
            <PageLayout title="Front Desk" propertyId={propertyId}>
                <div className="col-4 mb-2">
                    <FormFieldBuilder field={SearchFrontDeskInstructionsAndGuestsForm.accountId} selectOptions={accountSelectOptions} />
                </div>
                {loading ? <LoadingOverlay show={loading} /> : <>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col widget">
                                <div className="p-1">
                                    <h4>Instructions</h4>
                                    {frontDeskInstructions.length === 0 && <p><em>No instructions have been found...</em></p>}
                                    <Table
                                        headers={[
                                            "Id",
                                            "Comments",
                                            "Start",
                                            "End"
                                        ]}
                                        defaultPaginationSize={10}>
                                        {frontDeskInstructions.map(instruction => (
                                            <tr key={instruction.uuid} className="clickable" onClick={() => history.push({pathname: pathname + "/accounts/" + instruction.accountId + "/instructions/" + instruction.uuid})}>
                                                <td>{getTicketIdFromUuid(instruction.uuid)}</td>
                                                <td>{instruction.comments}</td>
                                                <td>{instruction.permanent ? "Permanent" : moment(instruction.startDate).format("MMM Do, YYYY")}</td>
                                                <td>{instruction.permanent ? "Permanent" : moment(instruction.endDate).format("MMM Do, YYYY")}</td>
                                            </tr>
                                        ))}
                                    </Table>
                                    <button
                                        className="btn btn-primary mt-2 mb-2"
                                        disabled={searchAccountId === ""}
                                        onClick={() => history.push({pathname: pathname + "/accounts/" + searchAccountId + "/instructions/add"})}
                                    >Add Instruction</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col widget">
                                <div className="p-1">
                                    <h4>Guest List</h4>
                                    {guests.length === 0 && <p><em>No guests have been found...</em></p>}
                                    <Table
                                        headers={[
                                            "Id",
                                            "Name",
                                            "Verified",
                                            "Key",
                                            "Start",
                                            "End"
                                        ]}
                                        defaultPaginationSize={10}>
                                        {guests.map(guest => (
                                            <tr key={guest.uuid} className="clickable" onClick={() => history.push({pathname: pathname + "/accounts/" + guest.accountId + "/guests/" + guest.uuid})}>
                                                <td>{getTicketIdFromUuid(guest.uuid)}</td>
                                                <td>{guest.firstName + " " + guest.lastName}</td>
                                                <td>{guest.verified ? "Yes" : "No"}</td>
                                                <td>{guest.allowedKey ? "Yes" : "No"}</td>
                                                <td>{guest.permanent ? "Permanent" : moment(guest.startDate).format("MMM, Do, YYYY")}</td>
                                                <td>{guest.permanent ? "Permanent" : moment(guest.endDate).format("MMM, Do, YYYY")}</td>
                                            </tr>
                                        ))}
                                    </Table>
                                    <button
                                        className="btn btn-primary mt-2 mb-2"
                                        disabled={searchAccountId === ""}
                                        onClick={() => history.push({pathname: pathname + "/accounts/" + searchAccountId + "/guests/add"})}
                                    >Add Guest</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </PageLayout>
            :
            <LoadingOverlay />
        }
    </>)
}

export default  SearchUnits;
