import * as React from 'react';
import {FormEvent, useCallback, useEffect, useState} from 'react';
import {
    DayOfMonthRegex,
    formInputValidate,
    GenericInputRegex,
    GenericSelectRegex,
    LateFeeDto,
    TransactionAccount
} from '../../types';
import {LateFeeFrequency, LateFeeType} from './Forms/LateFeeForm';
import Input from '../../Components/Form/Input';
import Select from '../../Components/Form/Select';
import Alert, {AlertType} from '../../Components/Alert';
import {addLateFeeToLease, deleteLateFeeFromLease} from '../../Api/LeaseApi';
import Table from '../../Components/Table';
import {getLateFeeAccountName, renderPercentOrDollarAmount} from '../../helpers';


interface LateFeeConfig {
    leaseId: string,
    lateFeeDtoList: LateFeeDto[],
    accountsList: TransactionAccount[],
    load: Function
}

const LateFeeConfig: React.FunctionComponent<LateFeeConfig> = (p) => {

    const [lateFeeExists, setLateFeeExists] = useState<boolean>(false);

    const [lateFeeDescription, setLateFeeDescription] = useState("");
    const [lateFeeType, setLateFeeType] = useState("");
    const [gracePeriod, setGracePeriod] = useState("");
    const [feeAmount, setFeeAmount] = useState("");
    const [frequency, setFrequency] = useState("");
    const [recievableAccountId, setRecievableAccountId] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(AlertType.SUCCESS);

    const [addLateFee, setAddLateFee] = useState<boolean>(false);

    const doShowAlert = (message: string, type: AlertType) => {
        setShowAlert(true);
        setAlertMessage(message);
        setAlertType(type);
    }

    const doHideAlert = () => {
        setShowAlert(false);
    }

    const handleSubmit = (e: FormEvent) => {
        let tenantFeeAccountId = p.accountsList.filter(p => p.name.toLowerCase() === "tenant fee")[0].uuid; //Forces value, disabled field doesn't change state.
        e.preventDefault();
        let lateFeeDto: LateFeeDto = {
            gracePeriod: Number(gracePeriod),
            frequency: frequency,
            feeType: lateFeeType,
            amount: Number(feeAmount),
            revenueAccountId: tenantFeeAccountId,
            description: lateFeeDescription,
            leaseId: p.leaseId
        }
        addLateFeeToLease(p.leaseId, lateFeeDto).then((l) => {
            p.load()
        }).catch(() => {
            doShowAlert("Issue Adding Late Fee. If problem persists for over 24hrs please contact support: contact@propertymq.com", AlertType.DANGER);
        }
        );
    }


    const handleDelete = (lateFeeId?: string) => {
        if (lateFeeId) {
            deleteLateFeeFromLease(p.leaseId, lateFeeId).then((l) => {
                if (p.load) {
                    p.load()
                }
            }).catch(() => {
                doShowAlert("Issue Removing Late Fee. If problem persists for over 24hrs please contact support: contact@propertymq.com", AlertType.DANGER);
            }
            );
        } else {
            doShowAlert("Issue Removing Late Fee. If problem persists for over 24hrs please contact support: contact@propertymq.com", AlertType.DANGER);
        }
    }

    const load = useCallback(() => {
        if (p.lateFeeDtoList.length !== 0) {
            setLateFeeExists(true)
        }
    }, [p.lateFeeDtoList])

    useEffect(() => {
        load();
    }, [load])

    function onCancel(e: FormEvent) {
        e.stopPropagation();
        clearForm();
        setAddLateFee(false);
    }


    function clearForm() {
        setGracePeriod("");
        setFrequency("");
        setLateFeeType("");
        setFeeAmount("");
        setRecievableAccountId("");
        setLateFeeDescription("");
    }

    function getContent() {
        return <>
            <h5>Late Fee Configuration</h5>
            <div className={"row"}>
                <div className={"col mb-3"}>
                    <Alert show={showAlert} message={alertMessage} type={alertType} />
                </div>
            </div>
            <div className="col-lg-12">
                <div hidden={addLateFee} className='text-start'>
                    <button className={"btn btn-sm btn-primary"} type={"submit"} onClick={() => setAddLateFee(true)}>{lateFeeExists ? "Add Additional Late Fee" : "Add Late Fee"}</button>
                </div>
                <div hidden={!addLateFee}>
                    <form onSubmit={handleSubmit}>
                        <div className={"row"}>
                            <div className="col-lg-6 mb-1">
                                <p className="small mb-2">When should we charge this late fee?</p>
                                <Input id={"gracePeriod"}
                                    value={gracePeriod}
                                    setValue={setGracePeriod}
                                    required={true}
                                    doValidate={formInputValidate}
                                    validation={DayOfMonthRegex.expression}
                                    invalidFeedbackText={DayOfMonthRegex.helpText}
                                    type={"number"}
                                    label={"Days after due date"}
                                />
                            </div>

                            <div className="col-lg-6 mb-3">
                                <p className="small mb-2">How often should this fee be charged ?</p>
                                <Select id={"frequency"}
                                    value={frequency}
                                    setValue={setFrequency}
                                    required={true}
                                    doValidate={formInputValidate}
                                    validation={GenericSelectRegex.expression}
                                    invalidFeedbackText={GenericSelectRegex.helpText}
                                    label={"Frequency"}
                                >
                                    <option value={""} disabled={true} hidden>Select an option...
                                    </option>
                                    <option value={LateFeeFrequency.ONCE}>Once
                                    </option>
                                    <option value={LateFeeFrequency.DAILY}>Daily</option>
                                    <option value={LateFeeFrequency.WEEKLY}>Weekly</option>
                                    <option value={LateFeeFrequency.MONTHLY}>Monthly</option>
                                </Select>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-lg-6 mb-3">
                                <p className="small mb-2">How should we calculate this fee?</p>

                                <Select id={"type"}
                                    value={lateFeeType}
                                    setValue={setLateFeeType}
                                    required={true}
                                    doValidate={formInputValidate}
                                    validation={GenericSelectRegex.expression}
                                    invalidFeedbackText={GenericSelectRegex.helpText}
                                    label={"Late Fee Type"}
                                >
                                    <option value={""} disabled={true} hidden>Select an option...
                                    </option>
                                    <option value={LateFeeType.FIXED_AMOUNT}> Fixed Amount
                                    </option>
                                    <option value={LateFeeType.PERCENT}>Percent</option>
                                </Select>
                            </div>
                            <div className={"col-lg-6 mb-1"}>
                                <p className="small mb-2">How much should we charge?</p>
                                {lateFeeType === LateFeeType.FIXED_AMOUNT ?
                                    <Input id={"fixedAmount"}
                                        value={feeAmount}
                                        setValue={setFeeAmount}
                                        required={true}
                                        min={0}
                                        doValidate={formInputValidate}
                                        validation={GenericInputRegex.expression}
                                        invalidFeedbackText={GenericInputRegex.helpText}
                                        type={"number"}
                                        label={"Late Fee Amount ($)"}
                                    /> :
                                    <Input id={"description"}
                                        value={feeAmount}
                                        setValue={setFeeAmount}
                                        placeholder='%'
                                        min={0}
                                        max={100}
                                        required={true}
                                        doValidate={formInputValidate}
                                        validation={GenericInputRegex.expression}
                                        invalidFeedbackText={GenericInputRegex.helpText}
                                        type={"number"}
                                        label={"Late Fee Percent (%)"}
                                    />
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-3">
                                <p className="small mb-2">Which Account should the late fees be added to?</p>
                                <Select id={"recievableAccount"}
                                    value={recievableAccountId}
                                    setValue={setRecievableAccountId}
                                    required={true}
                                    doValidate={formInputValidate}
                                    validation={GenericSelectRegex.expression}
                                    invalidFeedbackText={GenericSelectRegex.helpText}
                                    label={"Account"}
                                    disabled={true}>
                                    {p.accountsList.filter(p => p.name.toLowerCase() === "tenant fee").map((p) => {
                                        return <React.Fragment key={p.uuid}>
                                            <option key={p.uuid} value={p.uuid}>{p.name}</option>
                                        </React.Fragment>
                                    })}
                                </Select>
                            </div>
                            <div className="col-lg-6 mb-3">
                                <p className="small mb-2">How do you want to remember this late fee configuration?</p>
                                <Input id={"description"}
                                    value={lateFeeDescription}
                                    setValue={setLateFeeDescription}
                                    required={true}
                                    doValidate={formInputValidate}
                                    validation={GenericInputRegex.expression}
                                    invalidFeedbackText={GenericInputRegex.helpText}
                                    type={"text"}
                                    label={"Description"}
                                />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <button className={"btn btn-danger"} type={"button"} onClick={onCancel} >Cancel</button>
                                <button className={"btn btn-primary"} type={"submit"}>Add Late Fee</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="row" hidden={!lateFeeExists}>
                    <Table headers={["Description", "Days after due date", "Type", "Frequency", "Amount / Percent", "Account", ""]} defaultPaginationSize={5}>
                        {p.lateFeeDtoList.sort((a, b) => Number(a.gracePeriod) - Number(b.gracePeriod)).map((f) => (
                            <tr key={f.uuid}>
                                <td>{f.description}</td>
                                <td>{f.gracePeriod}</td>
                                <td>{f.feeType}</td>
                                <td>{f.frequency}</td>
                                <td>{renderPercentOrDollarAmount(f)}</td>
                                <td>{getLateFeeAccountName(p.accountsList, f.revenueAccountId)}</td>
                                <td><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    className="bi bi-trash3" viewBox="0 0 16 16" onClick={() => { handleDelete(f.uuid) }}>
                                    <path
                                        d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                                </svg></td>
                            </tr>
                        ))}
                    </Table>
                </div>
            </div>
        </>
    }


    function ready():boolean {
        return !!(p.accountsList && p.leaseId && p.lateFeeDtoList);

    }

    return <>
        { ready() && getContent()}
    </>
}

export default LateFeeConfig