import React, {FormEvent, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {
    getJourneyLease,
    JourneyLease,
    JourneyLeaseStep,
    JourneyStep, journeySteps,
    updateJourneyLease
} from "../../../Api/JourneyApi";
import {getTicketIdFromUuid} from "../../../helpers";
import Button from "../../../Components/Button";
import Alert, {AlertProps, AlertType} from "../../../Components/Alert";
import {Item} from "../JourneyLeaseItem/JourneyLeaseItem";
import {useLocation} from "react-router-dom";
import FormFieldBuilder, {assignFormFieldValues} from "../../../Components/Form/FieldBuilder/FormFieldBuilder";
import JourneyLeaseForm from "../Forms/JourneyLeaseForm";
import PageLayout from "../../../Layouts/PageLayout";
import Card from "../../../Components/Card";

const ViewJourneyLease: React.FunctionComponent = () => {

    const { propertyId, journeyId } = useParams()
    const { pathname } = useLocation()
    const history = useHistory()

    const [alert, setAlert] = useState<AlertProps>()
    const [error, setError] = useState<Error>()
    const [refresh, setRefresh] = useState<boolean>(false)

    const [journeyLease, setJourneyLease] = useState<JourneyLease>()
    const [step, setStep] = useState<JourneyStep>()
    const [actionItemPath, setActionItemPath] = useState<string>("")
    const [edit, setEdit] = useState<boolean>(false)

    const [isValid, setIsValid] = useState<boolean>(false)
    const [firstName, setFirstName] = useState<string>("")
    const [lastName, setLastName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [phone, setPhone] = useState<string>("")
    const [notes, setNotes] = useState<string>("")
    const [followUpDate, setFollowUpDate] = useState<string>("")
    const [closed, setClosed] = useState<string>("")

    assignFormFieldValues({field: JourneyLeaseForm.firstName, value: firstName, setValue: setFirstName, disabledOverride: !edit})
    assignFormFieldValues({field: JourneyLeaseForm.lastName, value: lastName, setValue: setLastName, disabledOverride: !edit})
    assignFormFieldValues({field: JourneyLeaseForm.email, value: email, setValue: setEmail, disabledOverride: !edit})
    assignFormFieldValues({field: JourneyLeaseForm.phone, value: phone, setValue: setPhone, disabledOverride: !edit})
    assignFormFieldValues({field: JourneyLeaseForm.notes, value: notes, setValue: setNotes, disabledOverride: !edit})
    assignFormFieldValues({field: JourneyLeaseForm.followUpDate, value: followUpDate, setValue: setFollowUpDate, disabledOverride: !edit})
    assignFormFieldValues({field: JourneyLeaseForm.closed, value: closed, setValue: setClosed, disabledOverride: !edit})

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (isValid) {
            updateJourneyLease({
                uuid: journeyId,
                propertyId: propertyId,
                journeyUser: {
                    firstName,
                    lastName,
                    email,
                    phone
                },
                notes,
                followUpDate,
                closed: closed === "true"
            })
                .then(() => {
                    setAlert({type: AlertType.SUCCESS, message: "Prospective Tenant successfully updated."})
                    setRefresh(!refresh)
                    setEdit(false)
                })
                .catch(setError)

        }
    }

    const onReset = (e: FormEvent) => {
        e.preventDefault()
        if (journeyLease) {
            setFirstName(journeyLease.journeyUser.firstName)
            setLastName(journeyLease.journeyUser.lastName)
            setEmail(journeyLease.journeyUser.email)
            setPhone(journeyLease.journeyUser.phone)
            setNotes(journeyLease.notes)
            setFollowUpDate(journeyLease.followUpDate || '')
            setClosed(journeyLease.closed?.toString() || '')
        }
    }

    useEffect(() => {
        const isFirstNameValid = firstName !== "";
        const isLastNameValid = lastName !== "";
        const isEmailValid = email !== "";
        const isPhoneValid = phone !== "";
        setIsValid(isFirstNameValid && isLastNameValid && (isEmailValid || isPhoneValid))
    }, [firstName, lastName, email, phone])

    useEffect(() => {
        if (step && journeyLease) {
            switch (journeyLease.step) {
                case JourneyLeaseStep.ONE:
                    setActionItemPath(step.actionItem?.path(propertyId, journeyLease.uuid))
                    break;
                case JourneyLeaseStep.TWO:
                    setActionItemPath(step.actionItem?.path(propertyId, journeyLease.uuid))
                    break;
                case JourneyLeaseStep.THREE:
                    setActionItemPath(step.actionItem?.path(propertyId, journeyLease.tenantApplicationId))
                    break;
                case JourneyLeaseStep.FOUR:
                    setActionItemPath(step.actionItem?.path(propertyId, journeyLease.tenantApplicationId))
                    break;
                case JourneyLeaseStep.FIVE:
                    setActionItemPath(step.actionItem?.path(propertyId, journeyLease.leaseId))
                    break;
                    /*
                case JourneyLeaseStep.SIX:
                    setActionItemPath(step.actionItem?.path(journeyLease.leaseId))
                    break;
                     */
            }
        }
    }, [step, journeyLease])

    useEffect(() => {
        if (journeyLease) {
            setStep(journeySteps.find(s => s.id === journeyLease?.step))
            setFirstName(journeyLease.journeyUser.firstName)
            setLastName(journeyLease.journeyUser.lastName)
            setEmail(journeyLease.journeyUser.email)
            setPhone(journeyLease.journeyUser.phone)
            setNotes(journeyLease.notes)
            setFollowUpDate(journeyLease.followUpDate || '')
            setClosed(journeyLease.closed?.toString() || '')
        }
    }, [journeyLease])

    useEffect(() => {
        getJourneyLease(propertyId, journeyId)
            .then(setJourneyLease)
            .catch(setError)
    }, [refresh])

    return <>
        <PageLayout title="Prospective Tenants" propertyId={propertyId}>
            <Card title="Details">
                <div className="row">
                    <div className="col">
                        {error && <Alert type={AlertType.DANGER} message={error.message} />}
                        {alert && <Alert type={alert.type} message={alert.message} />}
                        <div className="progress" style={{height: 30}}>
                            {journeySteps.map(step => {
                                const width = Math.round(100 / journeySteps.length)
                                let bgColorClass = "bg-" + step.bgColor
                                if (journeyLease?.step !== step.id) {
                                    bgColorClass += " bg-opacity-25"
                                }
                                return (
                                    <div
                                        className={"progress-bar " + bgColorClass}
                                        role="progressbar"
                                        style={{width: width + "%"}} aria-valuenow={width}
                                        aria-valuemin={0} aria-valuemax={100}
                                    >
                                        <h6 className="my-auto">{step.label}</h6>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-6">
                                <Item label="Id" content={getTicketIdFromUuid(journeyLease?.uuid)} />
                                <Item label="Current Step" content={step?.label || ''} />
                            </div>
                            <div className="col-md-6">
                                <Item label="Quote Id" content={journeyLease?.quoteId? getTicketIdFromUuid(journeyLease.quoteId) : '-'} />
                                <Item label="Tenant Application Id" content={journeyLease?.tenantApplicationId ? getTicketIdFromUuid(journeyLease.tenantApplicationId) : '-'} />
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="d-grid gap-2">
                                    <div>
                                        <FormFieldBuilder field={JourneyLeaseForm.firstName} />
                                    </div>
                                    <div>
                                        <FormFieldBuilder field={JourneyLeaseForm.lastName} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="d-grid gap-2">
                                    <div>
                                        <FormFieldBuilder field={JourneyLeaseForm.email} />
                                    </div>
                                    <div>
                                        <FormFieldBuilder field={JourneyLeaseForm.phone} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="h-100">
                            <div className="d-grid gap-2">
                                <div className="d-flex gap-4 justify-content-between">
                                    <div className="w-100">
                                        <FormFieldBuilder field={JourneyLeaseForm.followUpDate} />
                                    </div>
                                    <div className="w-100">
                                        <FormFieldBuilder field={JourneyLeaseForm.closed} selectOptions={JourneyLeaseForm.closed.defaultSelectOptions} />
                                    </div>
                                </div>
                                <div>
                                    <FormFieldBuilder field={JourneyLeaseForm.notes} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="row mt-3">
                    <div className="d-flex justify-content-between">
                        {!edit && step &&
                            <>
                                <Button onClick={() => {history.push(actionItemPath)}} disabled={false} loading={false} type="button">{step.actionItem?.label}</Button>
                                <Button disabled={false} onClick={() => {setEdit(true)}} loading={false} type="button">Edit</Button>
                            </>
                        }
                        {edit &&
                            <>
                                <button className="btn btn-secondary" onClick={onReset}>Reset</button>
                                <div className="btn-group">
                                    <button className="btn btn-outline-secondary" onClick={() => setEdit(false)}>Cancel</button>
                                    <button className="btn btn-success" onClick={onSubmit} disabled={!isValid}>Update</button>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </Card>
        </PageLayout>
    </>
}

export default ViewJourneyLease;