import React, {useEffect, useState} from "react";
import CompanyLogo from "../../Components/Navigation/CompanyLogo";
import MenuToggleButton from "../../Components/Navigation/MenuToggleButton";
import {doLogoutRedirect} from "../../Auth";
import Navigation from "../../Components/Navigation";
import Breadcrumbs, {Breadcrumb} from "../../Components/Navigation/Breadcrumbs";
import {useLocation} from "react-router";
import GettingStartedChecklist from "../../Components/GettingStartedChecklist";

interface Props {
    setPortfolioId: Function
    setPropertyId: Function
    showGettingStartedChecklist: boolean
}

const ContainerLayout: React.FunctionComponent<Props> = (p) => {

    const location = useLocation()
    const fullPath = location.pathname
    const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>()

    useEffect(() => {
        let currentPath: string = "/p/m"
        const bc: Breadcrumb[] = []
        fullPath.replace("/p/m/", "").split("/")
            .forEach(s => {
                currentPath += "/" + s
                bc.push({label: s, path: currentPath})
            })
        setBreadcrumbs(bc)
    }, [fullPath])

    return <>
        <nav className="d-flex justify-content-between navbar navbar-dark sticky-top bg-dark shadow d-print-none" style={{minHeight: "5vh"}}>
            <div className="d-flex justify-content-start">
                <CompanyLogo logoUrl={"/assets/dist/img/propertymq_logo.png"} />
            </div>
            <div className="d-flex justify-content-end me-3">
                <div className="d-flex d-md-none">
                    <MenuToggleButton />
                </div>
                <div className="d-none d-md-flex">
                    <button type="button" className="btn btn-signup bg-theme-4 border-theme-4"
                            onClick={(e) => {
                                localStorage.clear();
                                doLogoutRedirect();
                            }}>
                        Logout
                    </button>
                </div>
            </div>
        </nav>
        <div className="container-fluid">
            <div className="row">
                <Navigation
                    setPortfolioId={p.setPortfolioId}
                    setPropertyId={p.setPropertyId}
                />
                <main role="main" className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                    <hr />
                    {p.showGettingStartedChecklist && fullPath.endsWith("/dashboard") &&
                        <>
                            {/*<GettingStartedChecklist />*/}
                            <hr />
                        </>
                    }
                    {p.children}
                </main>
            </div>
        </div>
    </>
}

export default ContainerLayout