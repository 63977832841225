import {
    FormElementValidation,
    formInputValidate,
    formSelectValidate,
    GenericInputRegex, GenericSelectRegex,
    TicketIdRegex
} from "../../../types";

interface ViewMaintenanceRequestsForm {
    ticketId: FormElementValidation,
    unitName: FormElementValidation,
    category: FormElementValidation,
    createdAfter: FormElementValidation
    scheduledDate: FormElementValidation,
    assignedMaintenanceUserId: FormElementValidation,
    status: FormElementValidation,
}

const ViewMaintenanceRequestsForm: ViewMaintenanceRequestsForm = {
    ticketId: {
        id: "ticketId",
        required: false,
        validate: formInputValidate,
        regex: TicketIdRegex
    },
    unitName: {
        id: "unitName",
        required: false,
        validate: formInputValidate,
        regex: GenericInputRegex
    },
    category: {
        id: "category",
        required: false,
        validate: formSelectValidate,
        regex: GenericSelectRegex
    },
    createdAfter: {
        id: "createdAfter",
        required: false,
        validate: formInputValidate,
        regex: GenericInputRegex
    },
    scheduledDate: {
        id: "scheduledDate",
        required: false,
        validate: formInputValidate,
        regex: GenericInputRegex
    },
    assignedMaintenanceUserId: {
        id: "assignedMaintenanceUserId",
        required: false,
        validate: formSelectValidate,
        regex: GenericSelectRegex
    },
    status: {
        id: "status",
        required: false,
        validate: formSelectValidate,
        regex: GenericInputRegex
    },
}

export default ViewMaintenanceRequestsForm;
