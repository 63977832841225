import {fetchWithAuth, HttpMethod} from "../api";
import {Subscription, SubscriptionPayment, SubscriptionPaymentMethod, UpdateSubscription, UsageSummary} from "../types";

type AddPaymentMethodBody = {
    paymentMethodId: string
}

type UpdateSubscriptionBody = {
    planName: string
}

type getSubscription = (portfolioId: string, subscriptionId: string) => Promise<Subscription>;
type updateSubscription = (portfolioId: string, subscriptionId: string, body: UpdateSubscriptionBody) => Promise<UpdateSubscription>;
type getSubscriptionPayments = (portfolioId: string, subscriptionId: string, daysAgo: number) => Promise<SubscriptionPayment[]>;
type getSubscriptionPaymentMethods = (portfolioId: string, subscriptionId: string) => Promise<SubscriptionPaymentMethod[]>;
type addSubscriptionPaymentMethod = (portfolioId: string, subscriptionId: string, body: AddPaymentMethodBody) => Promise<Response>;
type deleteSubscriptionPaymentMethod = (portfolioId: string, subscriptionId: string, paymentMethodId: string) => Promise<Response>;
type getSubscriptionUsageSummary = (portfolioId: string, subscriptionId: string, daysAgo: number) => Promise<UsageSummary>;

export const getSubscription: getSubscription = (async (portfolioId, subscriptionId) => {
    const path = "/portfolios/" + portfolioId + "/subscriptions/" + subscriptionId;
    return fetchWithAuth(path, HttpMethod.GET);
});

export const updateSubscription: updateSubscription = (async (portfolioId, subscriptionId, body) => {
    const path = "/portfolios/" + portfolioId + "/subscriptions/" + subscriptionId;
    return fetchWithAuth(path, HttpMethod.PUT, JSON.stringify(body));
});

export const getSubscriptionPayments: getSubscriptionPayments = (async (portfolioId, subscriptionId, daysAgo) => {
    const path = "/portfolios/" + portfolioId + "/subscriptions/" + subscriptionId + "/payments?daysAgo=" + daysAgo;
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getSubscriptionPaymentMethods: getSubscriptionPaymentMethods = (async (portfolioId, subscriptionId) => {
    const path = "/portfolios/" + portfolioId + "/subscriptions/" + subscriptionId + "/payment-methods";
    return fetchWithAuth(path, HttpMethod.GET);
});

export const addSubscriptionPaymentMethod: addSubscriptionPaymentMethod = (async (portfolioId, subscriptionId, body) => {
    const path = "/portfolios/" + portfolioId + "/subscriptions/" + subscriptionId + "/payment-methods";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const deleteSubscriptionPaymentMethod: deleteSubscriptionPaymentMethod = (async (portfolioId, subscriptionId, paymentMethodId) => {
    const path = "/portfolios/" + portfolioId + "/subscriptions/" + subscriptionId + "/payment-methods/" + paymentMethodId;
    return fetchWithAuth(path, HttpMethod.DELETE);
});

export const getSubscriptionUsageSummary: getSubscriptionUsageSummary = (async (portfolioId, subscriptionId, daysAgo) => {
    const path = "/portfolios/" + portfolioId + "/subscriptions/" + subscriptionId + "/usage-summaries?daysAgo=" + daysAgo;
    return fetchWithAuth(path, HttpMethod.GET);
});