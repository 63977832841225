import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import CreateConversationForm from "../Forms/CreateConversationForm";
import FormFieldBuilder, {assignFormFieldValues, SelectOptions} from "../../Form/FieldBuilder/FormFieldBuilder";
import {createConversation} from "../../../Api/ConversationApi";
import {useParams} from "react-router";
import {ARAccount} from "../../../types";
import Alert, {AlertType} from "../../Alert";

const AddConversation: React.FunctionComponent<{accounts: ARAccount[], doCancel: Function, doSubmit: Function}> = ({accounts, doCancel, doSubmit}) => {

    const { propertyId } = useParams();

    const [error, setError] = useState<Error>();
    const [accountSelectOptions, setAccountSelectOptions] = useState<SelectOptions[]>([]);

    const [accountId, setAccountId] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [messageBody, setMessageBody] = useState<string>("");
    const [isValid, setIsValid] = useState<boolean>(false);

    assignFormFieldValues({field: CreateConversationForm.accountId, value: accountId, setValue: setAccountId});
    assignFormFieldValues({field: CreateConversationForm.subject, value: subject, setValue: setSubject});
    assignFormFieldValues({field: CreateConversationForm.message, value: messageBody, setValue: setMessageBody});

    const onSubmit = (e: FormEvent) => {
        e.preventDefault()
        if (isValid) {
            createConversation(propertyId, {
                accountId,
                subject,
                message: {
                    body: messageBody
                }
            })
                .then(() => doSubmit())
                .catch(setError)
        }
    }

    useEffect(() => {
        const options: SelectOptions[] = [];
        CreateConversationForm.accountId.defaultSelectOptions?.forEach(option => {
            options.push({value: option.value, label: option.label})
        });
        if (accounts) {
            accounts.forEach(account => {
                options.push({value: account.uuid, label: account.unitName})
            });
        }
        setAccountSelectOptions(options);
    }, [accounts])

    useEffect(() => {
        const accountIdIsValid = accountId !== "";
        const subjectIsValid = subject !== "";
        const messageBodyIsValid = messageBody !== "";
        setIsValid(accountIdIsValid && subjectIsValid && messageBodyIsValid);
    }, [accountId, subject, messageBody])

    return <>
        <div
            style={{display: "block"}}
            className="modal fade show"
            id="staticBackdrop"
            data-backdrop="static"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    {error && <Alert type={AlertType.DANGER} message={error.message} />}
                    <form onSubmit={onSubmit}>
                        <div className="modal-header bg-light shadow-sm">
                            <h5 className="modal-title" id="staticBackdropLabel">New Tenant Message</h5>
                            <button type="button" className="btn btn-close" data-dismiss="modal" aria-label="Close"
                                    onClick={e => {
                                        e.preventDefault();
                                        doCancel();
                                    }}
                            />
                        </div>
                        <div className="modal-body">
                            <div className="row p-1">
                                <div className="col-12 mb-2">
                                    <FormFieldBuilder field={CreateConversationForm.accountId} selectOptions={accountSelectOptions} />
                                </div>
                                <div className="col-12 mb-2">
                                    <FormFieldBuilder field={CreateConversationForm.subject} />
                                </div>
                                <div className="col-12 mb-2">
                                    <FormFieldBuilder field={CreateConversationForm.message} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer bg-light">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                    onClick={e => {
                                        e.preventDefault();
                                        doCancel();
                                    }}
                            >Cancel</button>
                            <button type="submit" className="btn btn-primary" disabled={!isValid}>Send</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className="modal-backdrop show"></div>
    </>
}

export default AddConversation;
