import * as React from "react";
import PageLayout from "../../../Layouts/PageLayout";
import Input from "../../../Components/Form/Input";
import {
    AddressLine1Regex, AddressLine2Regex, CityRegex,
    formInputValidate, formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex,
    PhoneNumberRegex, USAccountNameRegex, USBankAccountNumberRegex, USBankRoutingNumberRegex, ZipRegex
} from "../../../types";
import StateSelect from "../../../Components/StateSelect";
import {useEffect, useState} from "react";
import Select from "../../../Components/Form/Select";
import {getPortfolioVendor, updatePortfolioVendor, updatePortfolioVendorBank} from "../../../Api/PortfolioApi";
import {getPortfolioId} from "../../../helpers";

const PortfolioSettings: React.FunctionComponent = () => {

    const [loading, setLoading] = useState(false);

    const [vendorName, setVendorName] = useState("");
    const [vendorPhone, setVendorPhone] = useState("");
    const [lastFour, setLastFour] = useState("");
    const [accountType, setAccountType] = useState("")
    const [accountHolderName, setAccountHolderName] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [verifyAccountNumber, setVerifyAccountNumber] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [stateCode, setStateCode] = useState("");
    const [zip, setZip] = useState("")

    const [currentEditField, setCurrentEditField] = useState(0);
    const [doEditBankAccount, setDoEditBankAccount] = useState(false);
    const [error, setError] = useState("");


    function doVendorSave() {
        setLoading(true);
        setError("");
        updatePortfolioVendor(getPortfolioId(), {
            name: vendorName,
            phone: vendorPhone,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            stateCode: stateCode,
            zip: zip
        })
            .then(r => {
                load();
                setCurrentEditField(0);
            })
            .catch(e => setError(e.message))
            .finally(() => setLoading(false));
    }

    function doBankAccountSave() {
        setLoading(true);
        setError("");
        updatePortfolioVendorBank(getPortfolioId(), {
            accountHolderName: accountHolderName,
            accountType: accountType,
            accountNumber: accountNumber,
            routingNumber: routingNumber,
        })
            .then(r => {
                load();
                setCurrentEditField(0);
                setDoEditBankAccount(false);
            })
            .catch(e => setError(e.message))
            .finally(() => setLoading(false));
    }

    function load() {
        setLoading(true);
        getPortfolioVendor(getPortfolioId())
            .then(v => {
                setVendorName(v.name);
                setVendorPhone(v.phone);
                setAddressLine1(v.addressLine1);
                setAddressLine2(v.addressLine2);
                setCity(v.city);
                setStateCode(v.stateCode);
                setZip(v.zip);
                setLastFour(v.bankLastFour);
            })
            .catch(e => setError(e.message))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        load();
    }, []);

    function getEditSaveIcon(field: number) {
        if (field === currentEditField) {
            return <svg
                onClick={() => {
                    doVendorSave()
                }}
                style={{position: "absolute", top: 4, right: 0}}
                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy"
                viewBox="0 0 16 16">
                <path d="M11 2H9v3h2z"/>
                <path
                    d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
            </svg>
        }
        return <svg
            onClick={() => {
                setCurrentEditField(field);
            }}
            style={{position: "absolute", top: 4, right: 0}} xmlns="http://www.w3.org/2000/svg" width="16" height="16"
            fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
            <path
                d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
        </svg>
    }

    function getEditSaveIconBankAccount() {
        return <svg
            onClick={() => {
                setDoEditBankAccount(true);
            }}
            style={{position: "absolute", top: 4, right: 0}} xmlns="http://www.w3.org/2000/svg" width="16" height="16"
            fill="currentColor" className="bi bi-pencil" viewBox="0 0 16 16">
            <path
                d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
        </svg>
    }

    function getEditBankAccount() {
        if (!doEditBankAccount) {
            return <div className={"row"}>
                <div className={"col-md-4"}>
                    <div style={{position: 'relative'}}>
                        <Input id={"name"} validation={GenericInputRegex.expression}
                               doValidate={formInputValidate}
                               value={(lastFour ? "**" + lastFour : "") || ""}
                               setValue={(e: string) => {

                               }}
                               required={true}
                               disabled={currentEditField !== 10}
                               invalidFeedbackText={GenericInputRegex.helpText}
                               type={"text"} label={"Bank Account"}
                        />
                        {getEditSaveIconBankAccount()}
                    </div>
                </div>

            </div>
        }
        return <div className={"row"}>
            <div className={"col-md-4"}>
                <Select id={"accountType"}
                        label={"Account Type"}
                        required={true}
                        invalidFeedbackText={GenericSelectRegex.helpText}
                        validation={GenericSelectRegex.expression}
                        doValidate={formSelectValidate}
                        value={accountType}
                        setValue={setAccountType}
                >
                    <option disabled={true} value={""}>Select an account type...</option>
                    <option value={"personal_checking"}>Personal Checking</option>
                    <option value={"personal_savings"}>Personal Savings</option>
                    <option value={"business_checking"}>Business Checking</option>
                    <option value={"business_savings"}>Business Savings</option>
                </Select>
            </div>
            <div className={"col-md-4"}>
                <Input id={"accountHolderName"}
                       type={"text"}
                       label={"Account Holder Name"}
                       required={true}
                       invalidFeedbackText={USAccountNameRegex.helpText}
                       validation={USAccountNameRegex.expression}
                       doValidate={formInputValidate}
                       value={accountHolderName}
                       setValue={setAccountHolderName}
                />
            </div>
            <div className={"col-md-4"}>
                <Input id={"routingNumber"}
                       type={"text"}
                       label={"Routing Number"}
                       required={true}
                       invalidFeedbackText={USBankRoutingNumberRegex.helpText}
                       validation={USBankRoutingNumberRegex.expression}
                       doValidate={formInputValidate}
                       value={routingNumber}
                       setValue={setRoutingNumber}
                />
            </div>
            <div className={"col-md-4"}>
                <Input id={"accountNumber"}
                       type={"text"}
                       label={"Account Number"}
                       required={true}
                       invalidFeedbackText={USBankAccountNumberRegex.helpText}
                       validation={USBankAccountNumberRegex.expression}
                       doValidate={formInputValidate}
                       value={accountNumber}
                       setValue={setAccountNumber}
                />
            </div>
            <div className={"col-md-4"}>
                <Input id={"vaccountNumber"}
                       type={"text"}
                       label={"Verify Account Number"}
                       required={true}
                       invalidFeedbackText={"Account numbers do not match"}
                       validation={USBankAccountNumberRegex.expression}
                       doValidate={() => {
                           return accountNumber === verifyAccountNumber;
                       }}
                       value={verifyAccountNumber}
                       setValue={setVerifyAccountNumber}
                />
            </div>
            <div className={"col-md-12 mt-3"}>
                <button className={"btn btn-primary"}
                        disabled={!accountType || !accountHolderName || !routingNumber || !accountNumber || accountNumber !== verifyAccountNumber}
                        onClick={() => doBankAccountSave()}
                >Save Bank Account
                </button>
                <button className={"btn btn-danger"}
                        onClick={() => setDoEditBankAccount(false)}
                >Cancel
                </button>
            </div>
        </div>
    }

    return <PageLayout title={"Portfolio Settings"}>
        {loading && <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>}
        {error && <div className="alert alert-danger" role="alert">
            {error}
        </div>}
        <div className={"row"}>

            <div className={"col"}>
                <h3>Vendor</h3>
            </div>
        </div>

        <div className={"row"}>

            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Input id={"name"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={vendorName || ""}
                           setValue={(e: string) => {
                               setVendorName(e)
                           }}
                           required={true}
                           disabled={currentEditField !== 1}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"text"} label={"Name"}
                    />
                    {getEditSaveIcon(1)}
                </div>
            </div>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Input id={"phone"} validation={PhoneNumberRegex.expression}
                           doValidate={formInputValidate}
                           value={vendorPhone || ""}
                           setValue={(e: string) => {
                               setVendorPhone(e);
                           }}
                           required={true}
                           disabled={currentEditField !== 11}
                           invalidFeedbackText={PhoneNumberRegex.helpText}
                           type={"text"} label={"Phone"}
                    />
                    {getEditSaveIcon(11)}
                </div>
            </div>

        </div>
        <div className={"row"}>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Input id={"addressLine1"} validation={AddressLine1Regex.expression}
                           doValidate={formInputValidate}
                           value={addressLine1 || ""}
                           setValue={(e: string) => {
                               setAddressLine1(e);
                           }}
                           required={true}
                           disabled={currentEditField !== 5}
                           invalidFeedbackText={AddressLine1Regex.helpText}
                           type={"text"} label={"Address Line 1"}
                    />
                    {getEditSaveIcon(5)}
                </div>
            </div>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Input id={"addressLine2"} validation={AddressLine2Regex.expression}
                           doValidate={formInputValidate}
                           value={addressLine2 || ""}
                           setValue={(e: string) => {
                               setAddressLine2(e);
                           }}
                           required={true}
                           disabled={currentEditField !== 6}
                           invalidFeedbackText={AddressLine2Regex.helpText}
                           type={"text"} label={"Address Line 2"}
                    />
                    {getEditSaveIcon(6)}
                </div>
            </div>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Input id={"city"} validation={CityRegex.expression}
                           doValidate={formInputValidate}
                           value={city || ""}
                           setValue={(e: string) => {
                               setCity(e);
                           }}
                           required={true}
                           disabled={currentEditField !== 7}
                           invalidFeedbackText={CityRegex.helpText}
                           type={"text"} label={"City"}
                    />
                    {getEditSaveIcon(7)}
                </div>
            </div>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <StateSelect id={"state"} validation={GenericSelectRegex.expression}
                                 noSelectionText={"Select a state..."}
                                 doValidate={formSelectValidate}
                                 disabled={currentEditField !== 8}
                                 value={stateCode || ""}
                                 setValue={(e: string) => {
                                     setStateCode(e);
                                 }}
                                 required={true}
                                 invalidFeedbackText={GenericSelectRegex.helpText}
                                 label={"State"}/>
                    {getEditSaveIcon(8)}
                </div>
            </div>
            <div className={"col-md-4"}>
                <div style={{position: 'relative'}}>
                    <Input id={"zip"} validation={ZipRegex.expression}
                           doValidate={formInputValidate}
                           value={zip ? zip + "" : ""}
                           setValue={(e: string) => {
                               setZip(e);
                           }}
                           required={true}
                           disabled={currentEditField !== 9}
                           invalidFeedbackText={ZipRegex.helpText}
                           type={"text"} label={"Zip"}
                    />
                    {getEditSaveIcon(9)}
                </div>
            </div>
        </div>
        {getEditBankAccount()}

    </PageLayout>
}
export default PortfolioSettings;