import * as React from 'react';
import {useEffect, useState} from 'react';
import {BootstrapValidateClass, ValidateElementFunction} from "../../../types";

type InputProps = {
    id: string,
    validation: RegExp,
    doValidate: ValidateElementFunction,
    value: string,
    setValue: Function,
    required: boolean,
    label: string,
    disabled?: boolean,
    setValidFlag?: Function,
}

const Color: React.FunctionComponent<InputProps> = (p) => {

    const [validateClass, setValidateClass] = useState(BootstrapValidateClass.unvalidated);
    const {value} = p;

    useEffect(() => {
        if (value.length === 0 || p.disabled) {
            setValidateClass(BootstrapValidateClass.unvalidated);
            if (p.setValidFlag) {
                p.setValidFlag(false);
            }
            return;
        }
        if (p.doValidate(value, p.required, p.validation)) {
            setValidateClass(BootstrapValidateClass.isvalid);
            if (p.setValidFlag) {
                p.setValidFlag(true);
            }
        } else {
            setValidateClass(BootstrapValidateClass.isinvalid);
            if (p.setValidFlag) {
                p.setValidFlag(false);
            }
        }

    }, [value, p])

    return (<>
        <label htmlFor={p.id}>{p.label}</label>
        <input type="color"
               value={p.value}
               className={"form-control " + validateClass}
               id={p.id} disabled={p.disabled}
               required={p.required}
               onChange={e => {
                   let value = e.target.value;
                   p.setValue(value);
               }}/>
    </>);
};

export default Color;
