import { fetchWithAuth, HttpMethod } from "../api";
import {
    Invoice,
    LateFeeDto,
    Lease,
    LeaseUser,
    PaymentonLease,
    RecurringTransaction,
    Transaction,
    TransactionAccount,
    UpdateLeaseTermsDto
} from "../types";
import { getPortfolioId } from "../helpers";

interface CreateLeaseBody {
    startDate: string,
    dueDate: string,
    endDate: string,
    isMonthToMonth: boolean,
    recurringStartDate: string,
    initialTransactions: Transaction[],
    recurringTransactions: RecurringTransaction[],
    applicationId: string,
    lateFeeDtoList: LateFeeDto[],
    users: LeaseUser[]
}

interface AddTransactionToLeaseBody {
    date: string,
    description: string,
    accountId?: string,
    amount: number,
    type?: string,
    payment?: {
        idempotentKey: string, method: string, amount: number, signature: string, bankAccount: {
            accountHolderName: string,
            accountType: string,
            accountNumber: string,
            routingNumber: string
        }, checkSerialNumber: string;
    }
}

interface EditTransactionToLeaseBody {
    date: string,
    description: string,
    amount: number,
}

interface AddRecurringTransactionToLeaseBody {
    startDate: string,
    endDate: string,
    dayOfMonth: string,
    description: string,
    accountUuid: string,
    amount: number,
}



type getLeases = (propertyId: string, unitId?: string, startDate?: string, leaseId?: string) => Promise<Lease[]>;
type getLease = (leaseId: string) => Promise<Lease>;
type createLease = (body: CreateLeaseBody) => Promise<Lease>;
type addTransactionToLease = (leaseId: string, body: AddTransactionToLeaseBody) => Promise<Response>;
type getPaymentsOnLease = (leaseId: string) => Promise<PaymentonLease[]>;
type getTransactionAccountsByLease = (leaseId: string) => Promise<TransactionAccount[]>;
type getUsersOnLease = (leaseId: string) => Promise<LeaseUser[]>;
type addUserToLease = (leaseId: string, email: string) => Promise<LeaseUser[]>;
type deleteUserOnLease = (leaseId: string, userId: string) => Promise<Response>;
type getInvoicesOnLease = (leaseId: string) => Promise<Invoice[]>;
type generateInvoiceOnLease = (leaseId: string) => Promise<Response>;
type addRecurringTransactionToLease = (leaseId: string, body: AddRecurringTransactionToLeaseBody) => Promise<RecurringTransaction>;
type getRecurringTransactionsForLease = (leaseId: string) => Promise<RecurringTransaction[]>;
type deleteRecurringTransactionsForLease = (leaseId: string, recurringTransactionId: string) => Promise<Response>;
type deleteLateFeeFromLease = (leaseId: string, lateFeeId: string) => Promise<Response>;
type addLateFeeToLease = (leaseId: string, lateFeeDto: LateFeeDto) => Promise<LateFeeDto>;
type updateTermsOnLease = (leaseId: string, body: UpdateLeaseTermsDto) => Promise<UpdateLeaseTermsDto>;
type editTransactionOnLease = (leaseId: string, transactionId: string, body: EditTransactionToLeaseBody) => Promise<Response>;
type voidTransactionOnLease = (leaseId: string, transactionId: string) => Promise<Response>;

export const getLeases: getLeases = (async (propertyId, unitId = "", startDate = "", leaseId = "") => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases?propertyId=" + propertyId + "&unitId=" + unitId + "&startDate=" + startDate + "&leaseId=" + leaseId, HttpMethod.GET);
});

export const getPaymentsOnLease: getPaymentsOnLease = (async (leaseId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/payments", HttpMethod.GET);
});

export const addTransactionToLease: addTransactionToLease = async (leaseId, body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/transactions", HttpMethod.POST, JSON.stringify(body));
};

export const editTransactionOnLease: editTransactionOnLease = async (leaseId, transactionId, body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/transactions/" + transactionId, HttpMethod.PUT, JSON.stringify(body));
};

export const voidTransactionOnLease: voidTransactionOnLease = async (leaseId, transactionId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/transactions/" + transactionId, HttpMethod.DELETE);
};

export const getTransactionAccountsByLease: getTransactionAccountsByLease = (async (leaseId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/transaction-accounts", HttpMethod.GET);
});

export const getLease: getLease = (async (leaseId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId, HttpMethod.GET);
});

export const createLease: createLease = (async (body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases", HttpMethod.POST, JSON.stringify(body));
});

export const getUsersOnLease: getUsersOnLease = (async (leaseId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/users", HttpMethod.GET);
});

export const addUserToLease: addUserToLease = (async (leaseId, email) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/users", HttpMethod.POST, JSON.stringify({ email: email }));
});

export const deleteUserOnLease: deleteUserOnLease = (async (leaseId, userId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/users/" + userId, HttpMethod.DELETE);
});

export const addRecurringTransactionToLease: addRecurringTransactionToLease = async (leaseId, body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/recurring-transactions", HttpMethod.POST, JSON.stringify(body));
};

export const getRecurringTransactionsForLease: getRecurringTransactionsForLease = async (leaseId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/recurring-transactions", HttpMethod.GET);
};

export const deleteRecurringTransactionsForLease: deleteRecurringTransactionsForLease = async (leaseId, recurringTransactionId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/recurring-transactions/" + recurringTransactionId, HttpMethod.DELETE);
};

export const addLateFeeToLease: addLateFeeToLease = async (leaseId, lateFeeDto) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/lateFee", HttpMethod.POST, JSON.stringify(lateFeeDto));
}

export const deleteLateFeeFromLease: deleteLateFeeFromLease = async (leaseId, lateFeeId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/lateFee/" + lateFeeId, HttpMethod.DELETE);
}

export const updateTermsOnLease: updateTermsOnLease = async (leaseId, updateTermsDto) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/leases/" + leaseId + "/leaseTerms", HttpMethod.PUT, JSON.stringify(updateTermsDto));
}

