export const AUTH_USER_EMAIL_KEY = 'app.email';
export const AUTH_USER_ID_KEY = 'app.user.id';
export const AUTH_USER_EMAIL_CONFIRMED_KEY = 'app.email_confirmed';
export const APP_PORTFOLIO_ID = 'app.portfolio.id';
export const APP_PROPERTY_ID = 'app.property.id';
export const APP_PORTFOLIO_LANDING = '/p/m/dashboard';
export const APP_ACTION_ITEMS = '/a/action-items';
export const APP_LOGIN_PATH = '/a/login';
export const APP_LOGOUT_PATH = '/a/logout';
export const APP_CONFIRM_EMAIL_PATH = '/a/confirm-email';
export const APP_RESET_PASSWORD_PATH = '/a/reset-password';
export const APP_PORTFOLIO_SELECT_PATH = '/p/s/portfolio-selection';
export const APP_CREATE_PORTFOLIO = '/p/s/create-portfolio';

export const APP_ACTIVATE_PATH = '/a/invite/'

export type FormRegex = {
    expression: RegExp,
    helpText: string
}

export const GenericSelectRegex: FormRegex = {
    expression: /.+/,
    helpText: "Please select one."
};
export const GenericInputRegex: FormRegex = {
    expression: /.+/,
    helpText: "Select an option."
}
export const YearRegex: FormRegex = {
    expression: /^[0-9]{4}$/,
    helpText: "Must be four digits in the format yyyy."
}
export const ApplicationIdRegex: FormRegex = {
    expression: /^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$/,
    helpText: "Must be in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx."
}
export const UuidRegex: FormRegex = {
    expression: /^[A-Za-z0-9]{8}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{4}-[A-Za-z0-9]{12}$/,
    helpText: "Must be in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx."
}

export const IdentificationCodeRegex: FormRegex = {
    expression: /^[A-Z][0-9]{7}$/,
    helpText: "Must be a capital letter followed by 7 numbers, ex: A1234567"
}

export const TicketIdRegex: FormRegex = {
    expression: /^[A-Za-z0-9]{7}$/,
    helpText: "Must contain 7 characters."
}

export const AreaCodeRegex: FormRegex = {
    expression: /^[0-9]{3}$/,
    helpText: "Must contain 3 numbers."
}

export const AddressLine1Regex: FormRegex = {
    expression: /^[A-Za-z0-9_.'\-#@%&/\s]+$/,
    helpText: "Must be alphanumeric and can only contain special characters .'-#@%&"
}
export const AddressLine2Regex: FormRegex = {
    expression: /^[A-Za-z0-9.'\-#@%&/\s]+$/,
    helpText: "Must be alphanumeric and can only contain special characters .'-#@%&"
}
export const CityRegex: FormRegex = {
    expression: /^[A-Za-z-.\s]{1,}$/,
    helpText: "Must contain only letters or special characters .-."
}
export const ZipRegex: FormRegex = {
    expression: /^(^\d{5}$)|(^\d{9}$)|(^\d{5}-\d{4}$)$/,
    helpText: "Must be 5 to 9 digits in the format xxxxx-xxxx."
}
export const FullNameRegex: FormRegex = {
    expression: /^[A-Za-z.\-\s]{2,}$/,
    helpText: "Must be at least 2 characters and contain only letters, ., and -."
}
export const PhoneNumberRegex: FormRegex = {
    expression: /^\b\d{3}[-.]?\d{3}[-.]?\d{4}\b$/,
    helpText: "Must contain 10 digits in the format xxx-xxx-xxxx."
}
export const DollarAmountRegex: FormRegex = {
    expression: /^-?[0-9]+.?[0-9]{0,2}$/,
    helpText: "Must contain only numbers."
}
export const DayOfMonthRegex: FormRegex = {
    expression: /^[1-9]{1}$|^1[0-9]$|^2[0-8]$$/,
    helpText: "Must be a day of month between 1 and 28."
}
export const GenericNumberRegex: FormRegex = {
    expression: /^[0-9]+$/,
    helpText: "Must contain only numbers."
}
export const NumberGreaterThanZeroRegex: FormRegex = {
    expression: /^[1-9][0-9]*$/,
    helpText: "Must be greater than zero."
}

export const PercentRegex: FormRegex = {
    expression: /^(100(?:\.0{1,2})?|[0-9]?[0-9](?:\.[0-9]{1,2})?)$/,
    helpText: "Must be a percentage between 1 and 100."
}
export const DateRegex: FormRegex = {
    expression: /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
    helpText: "Must be a valid date in the format mm/dd/yyyy."
}
export const FirstNameRegex: FormRegex = {
    expression: /^[A-Za-z\s]{2,}$/,
    helpText: "Must be at least two characters and contain only letters."
}
export const MiddleInitialRegex: FormRegex = {
    expression: /^[A-Za-z]+$/,
    helpText: "Must contain only letters."
}
export const LastNameRegex: FormRegex = {
    expression: /^[A-Za-z\s]{2,}$/,
    helpText: "Must be at least two characters and contain only letters."
}
export const EmailRegex: FormRegex = {
    expression: /^([a-zA-Z0-9_.-]+)@([\da-zA-Z.-]+)\.([a-zA-Z.]{2,10})$/,
    helpText: "Must be a valid email address."
}
export const SsnRegex: FormRegex = {
    expression: /^[0-9]{3}-[0-9]{2}-[0-9]{4}$/,
    helpText: "Must contain 9 digits in the format xxx-xx-xxxx."
}
export const DriversLicenseRegex: FormRegex = {
    expression: /.{4,}/,
    helpText: "Must contain at least four characters."
}
export const PropertyNameRegex: FormRegex = {
    expression: /^[A-Za-z0-9\s]{2,}$/,
    helpText: "Must be at least 2 characters and contain only letters."
}
export const BankStatementNameRegex: FormRegex = {
    expression: /^[A-Za-z0-9\s]{4,15}$/,
    helpText: "Must be between 4 and 15 characters and contain only letters and numbers."
}
export const USBankRoutingNumberRegex: FormRegex = {
    expression: /^[0-9]{9}$/,
    helpText: "Must be 9 digits."
}
export const USBankAccountNumberRegex: FormRegex = {
    expression: /^[0-9a-zA-Z]{4,17}$/,
    helpText: "Must be between 4 and 17 alphanumeric characters."
}
export const USAccountNameRegex: FormRegex = {
    expression: /^[A-Za-z\s]{2,}$/,
    helpText: "Must be at least 2 characters and contain only letters."
}
export const URLRegex: FormRegex = {
    expression: /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
    helpText: "Must be in the format of https://example.net"
}
export const EINRegex: FormRegex = {
    expression: /^[0-9]{2}-?[0-9]{7}$/,
    helpText: "Must be 9 digits in the format xx-xxxxxxx."
}

export enum BootstrapValidateClass {
    isvalid = "is-valid", isinvalid = "is-invalid", unvalidated = ""
}


export type FormElementValidation = {
    id: string,
    validate: ValidateElementFunction,
    regex: FormRegex,
    required: boolean
}

export type ValidateElementFunction = (value: string, required: boolean, validation: RegExp) => boolean;

export const formInputValidate: ValidateElementFunction = (value: string, required: boolean, validation: RegExp): boolean => {
    if (required) {
        if (value && value.match(validation)) {
            return true;
        }
        return false;
    } else {
        if ((value && value.length === 0) || !value) {
            return true;
        }
        if (value && value.match(validation)) {
            return true;
        }
        return false;
    }
}

export const formSelectValidate: ValidateElementFunction = (value: string, required: boolean, validation: RegExp): boolean => {
    if (value && value.match(validation)) {
        return true;
    }
    return false;
}

export type OauthState = {
    path: string,
    r_value: string
};


export type Autopay = {
    address: Address,
    amount: number,
    createdAt: string,
    dayOfMonth: number,
    remittanceEmail: string,
    startDate: string,
    stopDate: string,
    tenantBankAccount: AutopayBankAccount,
    uuid: string
}

export type CheckScan = {
    uuid: string,
    amount: number,
    code: string,
    createdAt: string,
    expireAt: string,
    invoiceId: string,
    phone: string,
    status: string,
    checkImages: CheckImage[]
}

export type CheckImage = {
    imageContent: string,
    accountNumber: string,
    routingNumber: string,
    serialNumber: string,
}

export type Unit = {
    uuid: string,
    name: string,
    address: Address,
    floorPlan: FloorPlan,
    floorLevel: string,
    deposit: string,
    disabled: boolean,
    imageUrl: string
}

export type Address = {
    addressLine1: string,
    addressLine2: string,
    city: string,
    stateCode: string,
    zip: string
}

export type PhoneNumber = {
    uuid: string,
    phoneNumber: string,
    isSms: boolean,
    isVoice: boolean,
    tag: string,
    createdAt: string,
    isActive: boolean

}

export type Property = {
    uuid: string,
    lat: number,
    lng: number,
    managementFeePercent: number,
    name: string,
    disabled?: boolean,
    address: Address,
    floorPlans: FloorPlan[],
    levels: Level[],
    owner?: Business
    bankStatementName: string,
    emailReplyTo: string,
    reserveAmount: number,
    quoteTheme?: {
        navBarColor: string,
        propertyLogoImageId: string,
        backgroundImageId: string
    },
    achAccountVerified: boolean,
    ccAccountVerified: boolean,
    securityDepositTrustAccount: {
        uuid: string,
        name: string
    },
    rentTrustAccount: {
        uuid: string,
        name: string
    }
}

export type Term = {
    uuid: string,
    price: string,
    length: string,
    units: string
}

export type FloorPlanService = {
    uuid: string,
    price: string,
    name: string,
    description: string,
    units: string,
    optional: boolean
}

export type UnitService = {
    uuid: string,
    price: string,
    name: string,
    description: string,
    units: string,
    optional: boolean
}

export type FloorPlan = {
    uuid?: string,
    name: string,
    description: string,
    bed: string,
    bath: string,
    sqft: string,
    type: string,
    imageUrl: string,
    property: Property,
    deposit: string,
    occupancy: string
}

export type TenantReceipt = {
    uuid: string,
    date: string,
    payer: string,
    amount: number,
    description: string,
    accountId: string,
    reference: string,
    type: string,
    propertyId: string,
    leaseId: string
}

export type Lease = {
    uuid: string,
    status: string,
    isPastDue: boolean,
    pastDueDate: string,
    pastDueAmount: number,
    monthlyAmount: number,
    startDate: string,
    depositAmount: number,
    dueDate: number,
    endDate: string,
    isMonthToMonth: boolean,
    identificationId: string,
    balance: number,
    lateFeeDtoList: LateFeeDto[],
    transactions: LeaseTransaction[],
    property: {
        uuid: string,
        name: string
    },
    unit: {
        uuid: string,
        name: string,
        address: {
            addressLine1: string,
            addressLine2: string,
            city: string,
            state: string,
            zip: string
        }
    },
    tenants: string[]
}

export type LeaseTransaction = {
    uuid: string,
    parentId: string,
    description: string,
    debit: number,
    credit: number,
    balance: number,
    date: string,
    paymentDetails: PaymentonLease,
    pending: boolean
}

export type Level = {
    id: number,
    name: string
}

export type PropertyGroup = {
    uuid?: number,
    name: string,
}

export type PropertyGroupExt = {
    uuid?: number,
    name: string,
    properties: Property[]
}

export type Prospect = {
    id: number,
    property: string,
    firstName: string,
    middleInitial: string,
    lastName: string,
    email: string,
    phone: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zip: string,
    desiredMoveInDate: string,
    gender: string,
    desiredFloorPlan: string,
    desiredLevel: string,
    numberOfPeople: string,
    numberOfPets: string,
    priceRangeFrom: string,
    priceRangeTo: string,
    appointmentDate: string,
    expirationDate: string,
    notes: string
}

export type AvailablePhoneNumber = {
    phoneNumber: string
}

export type PhoneNumberTag = {
    tag: string
}

export type AddPhoneNumberBody = {
    phoneNumber: string,
    tag: string
}

export type MenuItem = {
    label: string,
    path: string,
    menuItems: MenuItem[],
    icon: string,
    weight: number
}

export type Portfolio = {
    uuid: string,
    name: string,
    subscriptionId: string,
    properties: Property[],
    onBoardingStatus: string,
    business?: Business
}

export type CreatePortfolio = {
    uuid?: string,
    name: string,
    business: Business,
    stripePaymentMethodId: string;
    subscriptionPlanName: string;
}

export type Account = {
    name: string,
    total: number
}

export type IncomeStatementReport = {
    revenues: Account[];
    totalRevenue: number;
    expenses: Account[];
    totalExpenses: number;
    netIncome: number;
}

export type Business = {
    uuid?: string,
    name: string,
    payoutMethod?: string,
    phone: string,
    person?: Person
    typeOfBusiness: string,
    businessStructure: string,
    address: Address,
    properties?: Property[]
    ein?: string,

    bankAccount?: {
        name: string,
        lastFour: string
    }
}

export type GetBusiness = {
    uuid: string,
    name: string,
    phone: string,
    person?: Person
    typeOfBusiness: string,
    businessStructure: string,
    address: Address,
    properties?: Property[]
    ein?: string,

    bankAccount?: {
        name: string,
        lastFour: string
    }
}

export type Person = {
    firstName: string,
    lastName: string,
    maidenName?: string,
    ssn?: string
}

export type PortfolioExt = {
    portfolio: Portfolio,
    setupNeeded: string[],
}

export type IavToken = {
    token: string
}

export type Payout = {
    uuid: string,
    amount: number,
    createdAt: string,
    description: string,
    status: string,
    property: Property,
}

export type Payment = {
    uuid: string,
    amount: number,
    createdAt: string,
    manualOverride: boolean,
    status: string,
    method: string,
    facilitatorFee: number,
    invoice?: Invoice,
    property?: Property,
    tenantBankAccount?: BankAccount,
    transactionDate: string
}

export type PaymentReportPayment = {
    uuid: string,
    amount: number,
    createdAt: string,
    status: string,
    method: string,
    transactionDate: string,
    payee: string,
    unitName: string,
    description: string
}

export type PaymentonLease = {
    uuid: string,
    amount: number,
    createdAt: string,
    status: string,
    method: string,
    transactionDate: string,
    facilitatorFee: number,
    failureDescription: string

}

export type TransactionAccount = {
    uuid: string,
    name: string
}

export type Transaction = {
    description: string,
    accountId: string,
    amount: string
}

export type PaymentSource = {
    id: string,
    name: string,
    bankName: string,
    type: string,
    bankAccountType: string
}


export type Invoice = {
    uuid: string,
    billToAddress: Address,
    billToName: string,
    remitToAddress: Address,
    remitToName: string,
    invoiceDate: string,
    invoiceNumber: string,
    paymentDueDate: string,
    status: string,
    lineItems: LineItem[],
    total: number,
    totalTax: number,
    paidDate: string,
    payments: Payment[]
}

export type IdempotentKey = {
    key: string
}

export type LineItem = {
    uuid: string,
    price: number,
    quantity: number,
    description: string,
    taxAmount: number
}

export type GetBankAccount = {
    uuid: string,
    name: string,
    lastFour: string,
    accountCode: string
}

export type BankAccount = {
    name?: string
    lastFour?: string,
    token?: string,
    accountHolderName?: string,
    accountHolderType?: string,
    accountType?: string,
    routingNumber?: string,
    accountNumber?: string
}

export type AutopayBankAccount = {
    name: string
    lastFour: string,
    accountHolderName: string,
    accountType: string,
    routingNumber: string,
    accountNumber: string
}

export type Application = {
    uuid: string,
    createdAt: string,
    status: ApplicationStatus
    unit: Unit,
    applicants: Applicant[],
    pets: Pet[],
    vehicles: Vehicle[],
    emergencyContacts: EmergencyContact[],
    trackingId: string,
    quote: Quote
}

export type Quote = {
    createdAt: string
    quoteLineItems: QuoteLineItem[]
    quoteNumber: string
    startDate: string
    termLength: number
    termUnits: string
    uuid: string
    validForDays: number
}

export type QuoteLineItem = {
    createdAt: string
    description: string
    name: string
    price: number
    units: string
    uuid: string
}

export type EmergencyContact = {
    name: string,
    email: string,
    phone: string
}

export type ApplicantAddress = {
    uuid: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    zip: string,
    landLordName: string,
    landLordPhone: string,
    rentAmount: number,
    moveInDate: string,
    isCurrentAddress: boolean,
    moveOutDate?: string
}

export type Applicant = {
    uuid: string,
    applicationId?: string,
    applicantType: ApplicantType,
    firstName: string,
    middleInitial: string,
    lastName: string,
    maidenName: string,
    email: string,
    phone: string,
    ssn: string,
    dlNumber: string,
    dlState: string,
    smoke: boolean,
    gender: string,
    primary: boolean,
    applicantAddresses: ApplicantAddress[],
    employmentIncomeSources: Employment[]
}

export type Employment = {
    uuid: string,
    employerName: string,
    employmentContactPerson: string,
    employerPhone: string,
    occupation: string,
    monthlyGrossIncome: number,
    startDate: string,
    noEndDate: boolean,
    endDate?: string
}

export enum ApplicationStatus {
    in_progress = "in_progress", submitted = "submitted", approved = "approved", cancelled = "cancelled"
}

export enum ApplicantType {
    guarantor = "guarantor", occupant = "occupant", cooccupant = "cooccupant"
}

export enum Gender {
    male = "male", female = "female", prefer_not_to_say = "prefer_not_to_say",
}

export enum PetType {
    dog = "dog", cat = "cat", other = "other"
}

export type Pet = {
    uuid: string,
    petType: PetType,
    name: string,
    breed: string,
    weight: number,
    color: string,
    age: number,
    petLocation: string,
    declawed: boolean,
    gender: Gender,
    neutered: boolean,
    vaccinationsUpToDate: boolean,
    otherPetTypeDescription: string,
    description: string
}

export enum VehicleType {
    SUV = "SUV", Truck = "Truck", Car = "Car", Van = "Van", Compact = "Compact"
}

export type Vehicle = {
    uuid: string,
    type: VehicleType,
    make: string,
    model: string,
    year: number,
    color: string,
    licensePlate: string,
    registeredState: string
}

export type Accounting_Account = {
    id: string,
    description: string
}

export type AppUser = {
    email: string,
    password: string,
    phone?: string,
    firstName: string,
    lastName: string,
    uuid?: string,
    actions?: Action[]
    roles?: Role[],
    activated?: boolean
}

export type GetAppUser = {
    uuid: string
    email: string,
    firstName: string,
    lastName: string,
    activated: boolean
}

export type Role = {
    uuid: string,
    name: string
}

export type Action = {
    code: string,
    description: string
}

export type CashAccount = {
    propertyName: string,
    balance: number,
    accountId: string,
    accountName: string
}

export type PastDueLease = {
    id: string,
    easyId: string,
    amount: number,
    propertyName: string
    unitName: string
    daysPastDue: number
}

export type LeaseUser = {
    uuid?: string
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    activated?: boolean
}

export type ARAgingSummary = {
    total: number,
    totalCurrent: number,
    total1to30DaysPastDue: number,
    total31to60DaysPastDue: number,
    total61to90DaysPastDue: number,
    totalOver90DaysPastDue: number,
    properties: {
        uuid: string,
        name: string,
        accounts: ARAccount[]
    }[]
}

export type ARAccount = {
    uuid: string,
    propertyId: string,
    propertyName: string,
    accountId: string,
    accountNumber: string,
    type: string,
    address: string,
    applicants: {
        uuid: string,
        name: string,
        phone: string,
        type: string
    }[],
    unitName: string,
    balance: number,
    balanceCurrent: number,
    balance1to30DaysPastDue: number,
    balance31to60DaysPastDue: number,
    balance61to90DaysPastDue: number,
    balanceOver90DaysPastDue: number
}

export type RecurringTransaction = {
    uuid?: string,
    description: string,
    amount: number,
    startDate: string,
    endDate: string,
    dayOfMonth: string,
    accountUuid: string
}

export type FileUploadResponse = {
    uuid: string
}

export type FileMetadata = {
    fileName: string,
    createdAt: string,
    mimeType: string,
    size: string
    uuid: string
    creator: string
}

export type SubscriptionPlan = {
    uuid: string,
    name: string,
    additionalFeatures: Feature[],
    description: string
    monthlyPrice: number,
    yearlyPrice: number,
    units: string,
    minPrice: number
}

export type Feature = {
    uuid: string,
    name: string,
    description: string
    units: string,
    monthlyPrice: number,
    yearlyPrice: number,
}

export type Subscription = {
    uuid: string,
    planName: string,
    activeUnits: number,
    nextPaymentDate: string,
    nextPaymentAmount: number,
    currentBalance: number,
    paymentMethodType: string,
    paymentMethodLastFour: string,
    paymentMethodExpiresOn: string
}

export type SubscriptionPayment = {
    date: string,
    paymentMethod: string,
    description: string,
    amount: number,
    status: string
}

export type SubscriptionPaymentMethod = {
    id: string,
    brand: string,
    lastFour: string,
    expireOnMonth: string,
    expireOnYear: string,
    default: string,
    createdAt: string,
}

export type UsageSummary = {
    esignRequests: number,
    communications: number
    applications: number
}

export type UpdateSubscription = {
    planName: string
}

export type EsignTemplate = {
    name: string,
    uuid: string
}

export type Document = {
    uuid: string,
    templateId: string,
    creator: string,
    fileId: string,
    name: string,
    description: string,
    notes: string,
    signInOrder: boolean,
    renderedPdfKey: string,
    createdAt: string,
    deadline: string,
    status: DocumentStatus,
    tags: Tag[],
    fields: Field[],
    signingUser: SigningUser
}

export enum DocumentStatus {
    PENDING = "PENDING",
    SIGNATURES_REQUESTED = "SIGNATURES_REQUESTED",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETE = "COMPLETE"
}

export type Field = {
    id: string,
    signingUserId: string,
    label: string,
    type: string,
    x: number,
    y: number,
    width: number,
    height: number,
    page: number,
    value: string,
    disabled: boolean,
    required: boolean,
    beforeSignatures: boolean,
    _uuid?: string,
    uuid: string,
    isDeleted?: boolean,
    fontSize: number,
    options: string,
    tabIndex: number,
    locked: boolean
}

export enum InputFieldType {
    NAME = "signing_user_name",
    EMAIL = "signing_user_email",
    INPUT = "input",
    DATE = "date",
    SIGNATURE_DATE = "signing_date",
    CHECKBOX = "checkbox",
    SIGNATURE = "signature",
    INITIAL = "initial",
    DROPDOWN = "dropdown"
}

export interface SigningUser {
    uuid?: string
    sequence: number
    label: string
    color: string
    status: SigningUserStatus
    notes?: string
    name?: string
    email?: string
}

export type Profile = {
    url: string
    uuid: string
    name: string
}

export enum SigningUserStatus {
    TEMPLATE = "TEMPLATE",
    NOT_READY = "NOT_READY",
    READY = "READY",
    SIGNATURE_REQUESTED = "SIGNATURE_REQUESTED",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETE = "COMPLETE"
}

export type Tag = {
    uuid?: string
    label: string,
}
export type DocumentMetadata = {
    key: string,
    value: string
}

export type Template = {
    uuid: string,
    fileId: string,
    name: string,
    description: string,
    notes: string,
    daysToComplete: number,
    signInOrder: boolean,
    status: TemplateStatus,
    createdAt: string,
    lastModified: string,
    fields: Field[],
    tags: Tag[]
}

export enum TemplateStatus {
    PENDING = "PENDING", PUBLISHED = "PUBLISHED"
}

export type LateFeeDto = {
    id?: number,
    uuid?: string,
    gracePeriod: number,
    frequency: string,
    feeType: string,
    amount: number,
    revenueAccountId: string,
    description: string,
    leaseId?: string

}

export type UpdateLeaseTermsDto = {
    startDate: string,
    endDate: string,
    isMonthToMonth: boolean,
    dueDate: number,
    status: string
}

export type DashboardItemsDto = {
    accountBalances: boolean,
    pastDueLeases: boolean,
    tenantMessages: boolean,
    maintenanceRequest: boolean
}

export type StripePublicKey = {
    key: string
}

export type PropertyGeneralLedger = {
    revenue: GeneralLedgerAccount[],
    expenses: GeneralLedgerAccount[],
    equity: GeneralLedgerAccount[],
    liabilities: GeneralLedgerAccount[],
    assets: GeneralLedgerAccount[],
    totalBalance: number,
    totalDebits: number,
    totalCredits: number
}

export type GeneralLedgerAccount = {
    code: string,
    description: string,
    type: string,
    startingBalance: number,
    endingBalance: number,
    netChange: number,
    totalDebits: number,
    totalCredits: number,
    journalEntries: JournalEntry[]
}

export type Tenant = {
    uuid: string,
    firstName: string,
    lastName: string,
    phone: string,
    email: string
    lease: {
        uuid: string,
        startDate: string,
        endDate: string,
        externalId: string,
        isMonthToMonth: boolean
        isPastDue: boolean
    },
    property: {
        uuid: string,
        name: string,
        unitName: string
    }
}

export type ReconcileDto = {
    total: number,
    totalPendingAchPayments: number,
    totalPendingCCMPayments: number,
    pendingAchPayments: ReconcilePaymentDto[],
    pendingCCMPayments: ReconcilePaymentDto[],
    payouts: {
        uuid: string,
        identificationId: string,
        amount: number,
        payments: ReconcilePaymentDto[]
    }[]
}

export type ReconcilePaymentDto = {
    uuid: string,
    method: string,
    amount: number,
    transactionDate: string
    lease: {
        uuid: string,
        externalId: string
    }
    property: {
        uuid: string,
        name: string,
        unitName: string
    }
}


export type PropertyVendor = {
    uuid: string,
    name: string,
    address: Address
    bankAccount?: BankAccount
}

export type JournalEntry = {
    uuid: string,
    date: string,
    payeePayer: string,
    type: string,
    reference: string,
    debit: number,
    credit: number,
    balance: number,
    description: string
}

export type PropertyAccount = {
    uuid: string,
    code: string,
    description: string,
    type: string
}

export type PropertyExpense = {
    uuid: string,
    account: {
        code: string,
        description: string
    },
    vendor: {
        name: string
    }
    description: string,
    amount: number,
    transactionDate: string
}

export type OwnerBalancesPayoutSummary = {
    owners: OwnerPayoutBalance[]
}

export type GetOwnerPayout = {
    uuid: string,
    identificationId: string,
    date: string,
    amount: number,
    owners: {
        uuid: string,
        name: string,
        amount: number,
        method: string,
        properties: {
            uuid: string,
            amount: string,
            name: string
        }[]
    }[]
}

export type PropertyOwnerPayoutBalance = {
    uuid: string,
    name: string,
    operating: number,
    securityDeposits: number,
    mgmtHeldSecurityDeposits: number,
    unpaidBillsAmount: number,
    unusedPrepaymentAmount: number,
    reserveAmount: number,
    workOrderEstimates: number,
    pendingPayments: number,
    paymentAmount: number,
    suggestedPayout: number
}

export type OwnerPayoutBalance = {
    uuid: string,
    name: string,
    payoutMethod: string,
    lineItems: PropertyOwnerPayoutBalance[],
    totalOperating: number,
    totalSecurityDeposits: number,
    totalMgmtHeldSecurityDeposits: number,
    totalUnpaidBillsAmount: number,
    totalUnusedPrepaymentAmount: number,
    totalReserveAmount: number,
    totalWorkOrderEstimates: number,
    totalPendingPayments: number,
    include: boolean,

}

export type TrustAccountBalances = {
    owners: OwnerTrustAccountBalance[],
    totalOperating: number,
    totalSecurityDeposits: number,
    totalMgmtHeldSecurityDeposits: number,
    totalUnpaidBillsAmount: number,
    totalUnusedPrepaymentAmount: number,
    totalReserveAmount: number,
    totalWorkOrderEstimates: number,
    totalPendingPayments: number
}

export type OwnerTrustAccountBalance = {
    uuid: string,
    name: string,
    lineItems: PropertyTrustAccountBalances[],
    totalOperating: number,
    totalSecurityDeposits: number,
    totalMgmtHeldSecurityDeposits: number,
    totalUnpaidBillsAmount: number,
    totalUnusedPrepaymentAmount: number,
    totalReserveAmount: number,
    totalWorkOrderEstimates: number,
    totalPendingPayments: number
}

export type PropertyTrustAccountBalances = {
    operating: number,
    securityDeposits: number,
    mgmtHeldSecurityDeposits: number,
    unpaidBillsAmount: number,
    unusedPrepaymentAmount: number,
    reserveAmount: number,
    workOrderEstimates: number,
    pendingPayments: number

    uuid: string,
    name: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    stateCode: string,
    zip: string
}

export type PropertySummary = {
    uuid: string,
    name: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    stateCode: string,
    zip: string,
    beginningBalance: number,
    endingCashBalance: number,
    cashIn: number,
    cashOut: number,
    ownerDisbursements: number,
    prepayments: number,
    propertyReserve: number,
    netOwnerFunds: number,
    transactions: {
        date: string,
        description: string,
        cashIn: number,
        cashOut: number,
        balance: number
    }[]
}

export type OwnerStatementDto = {
    fromDate: string,
    toDate: string,
    beginningBalance: number,
    endingCashBalance: number,
    cashIn: number,
    cashOut: number,
    ownerDisbursements: number,
    prepayments: number,
    propertyReserve: number,
    netOwnerFunds: number,
    propertySummaries: PropertySummary[],
    owner: {
        uuid: string,
        name: string,
        addressLine1: string,
        addressLine2: string,
        city: string,
        stateCode: string,
        zip: string
    }
    portfolio: {
        uuid: string,
        name: string,
        addressLine1: string,
        addressLine2: string,
        city: string,
        stateCode: string,
        zip: string,
        phone: string,
        url: string,
        logoUrl: string
    }
}

export type OwnerReportDto = {
    uuid: string,
    name: string,
    createdAt: string,
    type: string
}

export type AddOwnerReportResponse = {
    ownerId: string,
    reportId: string,
    type: string
}

export type DepositRegister = {
    bankAccounts: {
        uuid: string,
        name: string,
        deposits: {
            amount: number,
            cleared: boolean,
            date: string,
            id: string,
            transactions: {
                date: string,
                payer: string,
                property: string,
                type: string,
                reference: string,
                checkNumber: string,
                description: string,
                amount: number,
                receiptDescription: string,
                unit: string,
                leaseId: string
            }[]
        }[]
    }[],

}

export type ExpenseRegister = {
    bankAccounts: {
        uuid: string,
        name: string,
        charges: {
            uuid: string,
            amount: number,
            cleared: boolean,
            date: string,
            id: string,
            payTo: string,
            checkNumber: string,
            type: string,
            transactions: {
                date: string,
                property: string,
                reference: string,
                description: string,
                amount: number,
            }[]
        }[]
    }[],

}

export type ManagementFee = {
    uuid: string,
    amount: number,
    feeAmount: number,
    description: string,
    date: string,
    paidDate: string,
    propertyId: string,
    propertyName: string,
    unitName: string,
    rate: number,
    include: boolean,
    leaseId: string,
    contexts: {
        date: string
        amount: number,
        description: string,
        paymentStatus: string,
        leaseId: string,
        balance: number,
        source: boolean
    }[]
}

export type PortfolioVendor = {
    bankLastFour: string,
    bankName: string,
    uuid: string,
    name: string,
    phone: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    stateCode: string,
    zip: string
}

export type Bill = {
    amount: number,
    cashAccountId: string,
    createdAt: string,
    description: string,
    payTo: string,
    reference: string,
    transactionDate: string,
    uuid: string,
    accountName: string,
    propertyName: string,
    include: boolean
}

export type Vendor = {
    uuid: string,
    name: string
}

export type LetterTemplate = {
    uuid: string,
    name: string,
    content: string
}

export type CheckRequest = {
    transactions: {
        id: string,
        date: string,
        description: string
        amount: number
    }[],
    date: string,
    remitTo: string,
    memoLine: string,
    checkNumber: string
}

export type CheckResponse = {
    uuid: string
}

export type CashFlowComparisonReport = {
    ownerId: string,
    ownerName: string,
    fromDate: string,
    toDate: string,
    portfolioName: string,
    propertyReports: PropertyReport[],

    incomeAccounts: AccountBalance[],
    operatingIncome: number,
    expenseAccounts: AccountBalance[]
    operatingExpenses: number,
    netOperatingIncome: number,
    otherAccounts: AccountBalance[]
    otherItems: number,
    beginningCash: number,
    cashFlow: number,
    beginningCashPlusCashFlow: number,
    actualEndingCash: number
}

export type AccountBalance = {
    code: string,
    name: string,
    balance: number
}

export type PropertyReport = {
    uuid: string,
    orderId: number,
    name: string,
    incomeAccounts: AccountBalance[],
    operatingIncome: number,
    expenseAccounts: AccountBalance[],
    operatingExpenses: number,
    netOperatingIncome: number,
    otherAccounts: AccountBalance[],
    otherItems: number,
    beginningCash: number,
    cashFlow: number,
    beginningCashPlusCashFlow: number,
    actualEndingCash: number
}