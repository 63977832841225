import * as React from 'react';
import {useEffect, useState} from 'react';
import {BootstrapValidateClass, ValidateElementFunction} from "../../../types";
import RequiredLabel from "../../RequiredLabel";
import './index.css';

type InputProps = {
    id: string,
    validation: RegExp,
    doValidate: ValidateElementFunction,
    value: string,
    setValue: Function,
    required: boolean,
    maxLength?: number,
    minLength?: number,
    invalidFeedbackText: string,
    type: string,
    label: string,
    placeholder?: string,
    disabled?: boolean,
    setValidFlag?: Function,
    step?: number | string,
    min?: number | string,
    max?: number | string,
    data: DataAttribute[]
}

export type DataAttribute = {
    uuid: string,
    label: string
}

export enum InputType {
    TEXT = "text",
    NUMBER = "number",
    EMAIL = "email",
    PHONE = "tel",
    DATE = "date",
    TIME = "time",
    COLOR = "color"
}

const AutocompleteInput: React.FunctionComponent<InputProps> = (p) => {

    const [validateClass, setValidateClass] = useState(BootstrapValidateClass.unvalidated);
    const [filteredData, setFilteredData] = useState<DataAttribute[]>([]);
    const [search, setSearch] = useState("");
    const [contextMenuFocus, setContextMenuFocus] = useState(false);

    useEffect(() => {
        if (p.value) {
            let filter = p.data.filter(d => d.uuid === p.value);
            if(filter && filter.length > 0) {
                setSearch(filter[0].label)
            }
            setValidateClass(BootstrapValidateClass.isvalid);
        } else {
            setValidateClass(BootstrapValidateClass.unvalidated);
            setSearch("");

        }

    }, [p.data, p.value])

    const getLabelComponent = () => {
        if (p.label) {
            return <label htmlFor={p.id}>{!p.disabled && p.required ?
                <RequiredLabel>{p.label}</RequiredLabel> : p.label}</label>
        }
    }

    function selectItem(uuid: string, label: string) {
        p.setValue(uuid);
        setSearch(label);
        setFilteredData([]);
        setContextMenuFocus(false);
    }

    return (<>
        {getLabelComponent()}
        <div className="autocomplete">

            <input type={p.type} className={"form-control " + validateClass}
                   id={p.id} disabled={p.disabled}
                   maxLength={p.maxLength} minLength={p.minLength} placeholder={p.placeholder}
                   required={p.required} value={search} step={p.step} min={p.type === InputType.NUMBER ? 0 : p.min}
                   max={p.max}
                   onFocus={e => {
                       e.preventDefault();
                       p.setValue("");
                       setSearch("");
                       setFilteredData([...p.data]);
                   }}
                   onBlur={e => {
                       e.preventDefault();
                       if(!contextMenuFocus) {
                           setFilteredData([]);
                       }
                       if(!p.value) {
                           setValidateClass(BootstrapValidateClass.unvalidated);
                       }
                   }}

                   onChange={e => {
                       e.preventDefault();
                       let value = e.target.value;
                       setSearch(value);
                       if(value) {
                           let result = p.data.filter(i => i.label.toLowerCase().indexOf(value.toLowerCase()) >= 0);
                           setFilteredData([...result]);
                       } else {
                           setFilteredData([]);
                       }
                   }}
            />
            {filteredData && filteredData.length > 0 && !p.value
                && <ul onMouseOver={() => {
                    setContextMenuFocus(true);
                }}
                onMouseOut={() => {
                    setContextMenuFocus(false);
                }}>
                {
                    filteredData.map(l => (
                        <li key={l.uuid}

                            onClick={(e) => {
                            selectItem(l.uuid, l.label)}}>{l.label}</li>
                    ))
                }
            </ul>}
        </div>
        <div className="invalid-feedback">
            {p.invalidFeedbackText}
        </div>
    </>);
};

export default AutocompleteInput;
