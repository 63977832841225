import * as React from 'react';
import {FormEvent, useContext, useEffect, useState} from "react";
import {AppUser, Property} from "../../../types";
import {useHistory, useParams} from "react-router";
import {getProperty} from "../../../Api/PropertyApi";
import LoadingOverlay from "../../LoadingOverlay";
import {Link, useLocation} from "react-router-dom";
import Table from "../../Table";
import {
    getAllMaintenanceRequests,
    MaintenanceRequest,
    MaintenanceRequestStatus
} from "../../../Api/MaintenanceRequestApi";
import Input from "../../Form/Input";
import Select from "../../Form/Select";
import "./ViewMaintenanceRequests.css";
import ViewMaintenanceRequestsForm from "../Forms/ViewMaintenanceRequestsForm";
import {getMaintenanceRequestCategories} from "../../../Api/MaintenanceRequestCategoriesApi";
import {getUsersWithMaintenanceRole} from "../../../Api/UserApi";
import moment from "moment";
import {getTicketIdFromUuid} from "../../../helpers";
import PageLayout from "../../../Layouts/PageLayout";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";

const ViewMaintenanceRequests: React.FunctionComponent = () => {

    const propertyId = useContext(PropertyIdContext)
    const {pathname} = useLocation();

    const [loading, setLoading] = useState(false);

    const [ticketId, setTicketId] = useState<string>("");
    const [unitName, setUnitName] = useState<string>("");
    const [category, setCategory] = useState<string>("");
    const [createdAfter, setCreatedAfter] = useState<string>("")
    const [scheduledDate, setScheduledDate] = useState<string>("")
    const [assignedMaintenanceUserId, setAssignedMaintenanceUserId] = useState<string>("");
    const [status, setStatus] = useState<MaintenanceRequestStatus>();

    const [property, setProperty] = useState<Property>();
    const [categories, setCategories] = useState<string[]>([]);
    const [maintenanceUsers, setMaintenanceUsers] = useState<AppUser[]>([]);
    const [maintenanceRequests, setMaintenanceRequests] = useState<MaintenanceRequest[]>([])

    useEffect(() => {
        if(propertyId) {
            setLoading(true);
            Promise.all([
                getProperty(propertyId)
                    .then(data => data),
                getMaintenanceRequestCategories()
                    .then(data => data),
                getUsersWithMaintenanceRole(propertyId)
                    .then(data => data),
                getAllMaintenanceRequests({propertyId: propertyId})
                    .then(data => data)
            ])
                .then(values => {
                    setProperty(values[0]);
                    setCategories(values[1]);
                    setMaintenanceUsers(values[2]);
                    setMaintenanceRequests(values[3]);
                })
                .catch(console.error)
                .finally(() => setLoading(false));
        }
    }, [propertyId]);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        getAllMaintenanceRequests({
            propertyId: propertyId,
            ticketId: ticketId,
            unitName: unitName,
            category: category,
            createdAfter: createdAfter,
            scheduledDate: scheduledDate,
            assignedMaintenanceUserId: assignedMaintenanceUserId,
            status: status,
        })
            .then(setMaintenanceRequests)
            .catch(console.error)
            .finally(() => setLoading(false));
    }

    return <>
        {property ?
            <PageLayout title="Maintenance Requests" property={property} addButtonLabel="Add Maintenance Request">
                <div className="col-lg-8 mb-3">
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className={"col-md-6 mb-2"}>
                                <Input id={ViewMaintenanceRequestsForm.ticketId.id} validation={ViewMaintenanceRequestsForm.ticketId.regex.expression}
                                       doValidate={ViewMaintenanceRequestsForm.ticketId.validate}
                                       value={ticketId}
                                       setValue={setTicketId}
                                       required={ViewMaintenanceRequestsForm.ticketId.required}
                                       invalidFeedbackText={ViewMaintenanceRequestsForm.ticketId.regex.helpText}
                                       type={"text"} label={"Ticket Id"}
                                />
                            </div>
                            <div className={"col-md-6 mb-2"}>
                                <Input id={ViewMaintenanceRequestsForm.unitName.id} validation={ViewMaintenanceRequestsForm.unitName.regex.expression}
                                       doValidate={ViewMaintenanceRequestsForm.unitName.validate}
                                       value={unitName}
                                       setValue={setUnitName}
                                       required={ViewMaintenanceRequestsForm.unitName.required}
                                       invalidFeedbackText={ViewMaintenanceRequestsForm.unitName.regex.helpText}
                                       type={"text"} label={"Unit name"}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <Select
                                    id={ViewMaintenanceRequestsForm.category.id}
                                    validation={ViewMaintenanceRequestsForm.category.regex.expression}
                                    doValidate={ViewMaintenanceRequestsForm.category.validate}
                                    value={category}
                                    setValue={setCategory}
                                    required={ViewMaintenanceRequestsForm.category.required}
                                    invalidFeedbackText={ViewMaintenanceRequestsForm.category.regex.helpText}
                                    label={"Category"}
                                >
                                    <option value={""}>Any</option>
                                    {
                                        categories?.map(c => (
                                            <option key={c + "_mrc"}
                                                    value={c}>{c}</option>
                                        ))
                                    }
                                </Select>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Select
                                    id={ViewMaintenanceRequestsForm.assignedMaintenanceUserId.id}
                                    validation={ViewMaintenanceRequestsForm.assignedMaintenanceUserId.regex.expression}
                                    doValidate={ViewMaintenanceRequestsForm.assignedMaintenanceUserId.validate}
                                    value={assignedMaintenanceUserId}
                                    setValue={setAssignedMaintenanceUserId}
                                    required={ViewMaintenanceRequestsForm.assignedMaintenanceUserId.required}
                                    invalidFeedbackText={ViewMaintenanceRequestsForm.assignedMaintenanceUserId.regex.helpText}
                                    label={"Ticket Assigned"}
                                >
                                    <option value={""}>Any</option>
                                    {
                                        maintenanceUsers?.map((u: AppUser) => (
                                            <option key={u.uuid + "_p"}
                                                    value={u.uuid}>{u.firstName + " " + u.lastName}</option>
                                        ))
                                    }
                                </Select>
                            </div>
                            <div className={"col-md-6 mb-2"}>
                                <Input id={ViewMaintenanceRequestsForm.createdAfter.id} validation={ViewMaintenanceRequestsForm.createdAfter.regex.expression}
                                       doValidate={ViewMaintenanceRequestsForm.createdAfter.validate}
                                       value={createdAfter}
                                       setValue={setCreatedAfter}
                                       required={ViewMaintenanceRequestsForm.createdAfter.required}
                                       invalidFeedbackText={ViewMaintenanceRequestsForm.createdAfter.regex.helpText}
                                       type={"date"} label={"Created After"}
                                />
                            </div>
                            <div className={"col-md-6 mb-2"}>
                                <Input id={ViewMaintenanceRequestsForm.scheduledDate.id} validation={ViewMaintenanceRequestsForm.scheduledDate.regex.expression}
                                       doValidate={ViewMaintenanceRequestsForm.scheduledDate.validate}
                                       value={scheduledDate}
                                       setValue={setScheduledDate}
                                       required={ViewMaintenanceRequestsForm.scheduledDate.required}
                                       invalidFeedbackText={ViewMaintenanceRequestsForm.scheduledDate.regex.helpText}
                                       type={"date"} label={"Scheduled Date"}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <Select id={ViewMaintenanceRequestsForm.status.id} validation={ViewMaintenanceRequestsForm.status.regex.expression}
                                        doValidate={ViewMaintenanceRequestsForm.status.validate}
                                        value={status != null ? status.toUpperCase() : ""} setValue={setStatus}
                                        required={ViewMaintenanceRequestsForm.status.required}
                                        invalidFeedbackText={ViewMaintenanceRequestsForm.status.regex.helpText} label={"Status"}>
                                    <option value={""} disabled={false}>Any</option>
                                    <option value={"OPEN"} disabled={false}>Open</option>
                                    <option value={"COMPLETE"} disabled={false}>Complete</option>
                                </Select>
                            </div>
                        </div>
                        <button disabled={loading} type="submit" className="btn btn-primary mt-3">Search</button>
                    </form>
                </div>

                <Table headers={[
                    "Ticket Id",
                    "Unit",
                    "Category",
                    "Description",
                    "Entry Permission",
                    "Created",
                    "Scheduled",
                    "Assigned To",
                    "Status"
                ]} defaultPaginationSize={10}>
                    {maintenanceRequests.map((maintenanceRequest, i) => (
                        <tr key={i + "_mr"}>
                            <td>{getTicketIdFromUuid(maintenanceRequest.uuid || '')}</td>
                            <td>{maintenanceRequest.unitName}</td>
                            <td>{maintenanceRequest.category}</td>
                            <td>{maintenanceRequest.description}</td>
                            <td>{maintenanceRequest.entryPermission ? "Yes" : "No"}</td>
                            <td>{moment(maintenanceRequest.createdAt, "YYYY-MM-DD").format("MMMM Do, YYYY")}</td>
                            <td>{maintenanceRequest.scheduledDate ? moment(maintenanceRequest.scheduledDate, "YYYY-MM-DD").format("MMMM Do, YYYY") : 'Pending'}</td>
                            <td>{maintenanceRequest.assignedMaintenanceUser != null
                                ? maintenanceRequest.assignedMaintenanceUser.firstName.substring(0,1) + ". " + maintenanceRequest.assignedMaintenanceUser?.lastName
                                : ""
                            }</td>
                            <td><span className={maintenanceRequest.status === MaintenanceRequestStatus.OPEN ? "badge rounded-pill bg-info" : "badge rounded-pill bg-success"}>{maintenanceRequest.status}</span></td>
                            <td>
                                <Link to={pathname + "/manage/" + maintenanceRequest.uuid}>
                                    <button className="btn btn-sm btn-primary">View</button>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </Table>
            </PageLayout>
            :
            <LoadingOverlay />
        }
        </>
}

export default ViewMaintenanceRequests;
