import {FormBuilderField, FormFieldType} from "../../../Components/Form/FieldBuilder/FormFieldBuilder";
import {AreaCodeRegex, formInputValidate, formSelectValidate} from "../../../types";


export enum LateFeeFrequency {
    ONCE = "Once",
    DAILY = "Daily",
    WEEKLY = "Weekly",
    MONTHLY = "Monthly"
}

export enum LateFeeType {
    FIXED_AMOUNT = "Fixed Amount",
    PERCENT = "Percent"
}

interface LateFeeForm {
    pastDueDays: FormBuilderField,
    frequency: FormBuilderField,
    feeAmount: FormBuilderField,
    recievableAccount: FormBuilderField,
    description: FormBuilderField,
    notificationOptions: FormBuilderField
}

const LateFeeForm: LateFeeForm = {
    pastDueDays: {
        type: FormFieldType.NUMBER,
        element: {
            id: 'pastDueDays',
            required: true,
            validate: formSelectValidate,
            regex: AreaCodeRegex,
        },
        label: 'Days after rent is due',
        value: undefined,
        setValue: () => {},
    },
    frequency: {
        type: FormFieldType.SELECT,
        element: {
            id: 'frequency',
            required: true,
            validate: formSelectValidate,
            regex: AreaCodeRegex,
        },
        label: 'Frequency',
        value: undefined,
        setValue: () => {},
        defaultSelectOptions: [{
            value: '',
            label: 'Please select...',
            disabled: true
        }]
    },
    feeAmount: {
        type: FormFieldType.NUMBER,
        element: {
            id: 'feeAmount',
            required: false,
            validate: formSelectValidate,
            regex: AreaCodeRegex,
        },
        label: 'Late Fee Amount',
        value: undefined,
        setValue: () => {}
    },
    recievableAccount: {

        type: FormFieldType.SELECT,
        element: {
            id: 'recievableAccount',
            required: true,
            validate: formSelectValidate,
            regex: AreaCodeRegex,
        },
        label: 'Recievable Account',
        value: undefined,
        setValue: () => {}
    },
    description: {
        type: FormFieldType.SELECT,
        element: {
            id: 'description',
            required: true,
            validate: formSelectValidate,
            regex: AreaCodeRegex,
        },
        label: 'Description',
        value: undefined,
        setValue: () => {}
    },
    notificationOptions: {
        type: FormFieldType.SELECT,
        element: {
            id: 'notificationOptions',
            required: true,
            validate: formSelectValidate,
            regex: AreaCodeRegex,
        },
        label: 'Notification Options',
        value: undefined,
        setValue: () => {}
    }
}

export default LateFeeForm;