import * as React from 'react';
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import Alert, { AlertType } from "../Alert";
import { useParams } from "react-router";
import ViewApplication from "../ViewApplication";
import { getPortfolioId } from "../../helpers";
import { addApplicationFile, deleteApplicationFile, getApplication, getApplicationFiles, getDocumentTransactionId } from "../../Api/ApplicationApi";
import { Link, useLocation } from "react-router-dom";
import PageLayout from "../../Layouts/PageLayout";
import { Application, FileMetadata, EsignTemplate, Document, Profile, DocumentStatus, Template, TemplateStatus } from "../../types";
import Card from "../Card";
import Table from "../Table";
import { downloadFile, uploadFile } from "../../Api/FileApi";
import { createDocumentFromTemplate, downloadDocumentByUUID, getDocuments, getProfileId, getPropertyTemplates } from '../../Api/EsignApi';
import moment from 'moment';
import { ESIGN_UI_ENDPOINT } from '../../Api/EsignApi';
import { AUTH_ACCESS_TOKEN, AUTH_CLOCK_START, AUTH_EXPIRES_IN, AUTH_REFRESH_TOKEN, AUTH_SCOPE, AUTH_TOKEN_TYPE } from '../../Auth';

const Section: React.FunctionComponent<{
    label: string
}> = (p) => {
    return <>
        <div className="mb-4">
            <Card title={p.label}>
                {p.children}
            </Card>
        </div>
    </>
}

const ReviewApplication: React.FunctionComponent = (p) => {
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(AlertType.SUCCESS);
    const [uploadDocumentLoading, setUploadDocumentLoading] = useState(false);
    const [uploadError, setUploadError] = useState("");
    const [uploadDocument, setUploadDocument] = useState();
    const [documentError, setDocumentError] = useState("");
    const [showCreateDocument, setShowCreateDocument] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState("");
    const [files, setFiles] = useState<FileMetadata[]>([]);
    const [createdDocumentId, setCreatedDocumentId] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [documents, setDocuments] = useState<Document[]>([]);
    const [profile, setProfile] = useState<Profile>();
    const [profileId, setProfileId] = useState("");
    const [selectedDocumentId, setSelectedDocumentId] = useState("");
    const [sortBy, setSortBy] = useState("latest");
    const [downloadingDocument, setDownloadingDocument] = useState<boolean>(false);
    const [clickedRow, setClickedRow] = useState("");

    //form
    const [application, setApplication] = useState<Application>();
    const [propertyTemplates, setTemplates] = useState<Template[]>([]);

    const { pathname } = useLocation();
    const { applicationId } = useParams();
    const { propertyId } = useParams();

    useEffect(() => {
        setLoading(true);
        getApplication(getPortfolioId(), applicationId)
            .then((a) => {
                setApplication(a)
                getPropertyTemplates(propertyId, TemplateStatus.PUBLISHED).then((t) => {
                    setTemplates(t);
                })
                getProfileId(propertyId).then((p) => {
                    setProfile(p);
                    setProfileId(p?.uuid);
                })
            })
            .catch(e => {
                doShowAlert("Failed to load application: " + e.message, AlertType.DANGER);
            }).finally(() => setLoading(false))


    }, [applicationId, showCreateDocument])

    const doShowAlert = (message: string, type: AlertType) => {
        setShowAlert(true);
        setAlertMessage(message);
        setAlertType(type);
    }

    const load = useCallback(() => {
        getApplicationFiles(applicationId)
            .then(fz => {
                setFiles(fz)
            })
            .catch(e => setUploadError(e.message))

        getDocuments(applicationId, propertyId).then(dz => {
            setDocuments(dz);
        }).catch(e => setDocumentError(e));
    }, [applicationId, propertyId]);

    useEffect(() => {
        load();
    }, [load]);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setUploadDocumentLoading(true);
        setUploadError("")
        uploadFile(uploadDocument)
            .then((f) => {
                setUploadDocument("");
                addApplicationFile(applicationId, {
                    uuid: f.uuid
                })
                    .then((r) => {
                        load();
                        fileInputRef.current = null;
                    })
                    .catch(e => setUploadError(e.message))
                    .finally(() => setUploadDocumentLoading(false));


            })
            .catch(e => setUploadError(e.message));

    }


    const doDelete = (id: string) => {
        setLoading(true);
        deleteApplicationFile(applicationId, id)
            .then((r) => {
                load();
            })
            .catch((e) => setUploadError(e.message))
            .finally(() => setLoading(false));
    }

    const getSizeInMB = (size: number) => {
        return (size / (1024 * 1024)).toFixed(2) + " MB";
    }


    function loadingApp() {
        return <>
            <div className={"row"}>
                <div className="col-md-6">
                    <p hidden={showAlert}>Loading...</p>
                    <Alert type={alertType} message={alertMessage} show={showAlert} />
                </div>
            </div>
        </>
    }

    const onAdd = () => {
        if (selectedTemplate) {
            getDocumentTransactionId(applicationId).then((e) => {
                setTransactionId(e.transactionId);
            });
        }
    }

    const onReview = (documentId: string) => {
        if (documentId) {
            setSelectedDocumentId(documentId);
            setShowCreateDocument(true);
        }
    }

    const onEsignDownload = (uuid: string, name: string) => {
        setClickedRow(uuid);
        setDownloadingDocument(true);
        downloadDocumentByUUID(uuid, name)
            .then()
            .catch((e) => console.log(e))
            .finally(() => {
                setDownloadingDocument(false);
                setClickedRow("");
            })
    }

    useEffect(() => {
        if (transactionId) {
            createDocumentFromTemplate(propertyId, {
                templateId: selectedTemplate,
                transactionId: transactionId
            }).then((d) => {
                setCreatedDocumentId(d.uuid);
            }).catch((e) => {
                setDocumentError("Issue creating document with Template ID: " + selectedTemplate)
            })
        }
    }, [transactionId]);

    useEffect(() => {
        if (createdDocumentId) {
            setShowCreateDocument(true);
        }
    }, [createdDocumentId]);



    function renderReviewApp(application: Application) {
        return <>
            <PageLayout
                title="Application Review"
                property={application.unit.floorPlan.property}
            >
                <ViewApplication application={application} />
                <Section label="E-sign Documents">
                    <div className={"row"}>
                        <p>Create a document from an existing Esign Template</p>
                        <div className={"col drop-down"}>
                            <select id={"templates"} className={"form-select custom-select d-block w-100"}
                                onChange={(e) => setSelectedTemplate(e.target.value)}>
                                <option value="" selected disabled hidden>Select one...</option>
                                {propertyTemplates.map((t) => (
                                    <option key={t.uuid} value={t.uuid}>{t.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className={"col"}>
                            <button type={"button"} className={"btn btn-primary"} onClick={onAdd}>Add</button>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col"}>
                            {documentError && <div className={"mt-3"}><Alert type={AlertType.DANGER} message={documentError} /></div>}
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col"}>
                            <div className="d-flex float-end">
                                <div className="dropdown mt-1">
                                    <button className="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuOffset" data-bs-toggle="dropdown" aria-expanded="false">
                                        Sort By
                                    </button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuOffset">
                                        <li><a className="dropdown-item" onClick={() => { setSortBy("latest") }}>Created Date - Latest</a></li>
                                        <li><a className="dropdown-item" onClick={() => { setSortBy("oldest") }}>Created Date - Oldest</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col"}>
                            <Table headers={["Created Date", "Name", "Creator", "Notes", "Status", ""]}
                                defaultPaginationSize={10}>
                                {documents.sort((d1, d2) => (sortBy === "latest" ? (d1.createdAt > d2.createdAt ? -1 : 1)
                                    : (d1.createdAt < d2.createdAt ? -1 : 1)))
                                    .map(d => d.status == DocumentStatus.COMPLETE ?
                                        <tr key={d.uuid}>
                                            <td>{moment(d.createdAt).calendar()}</td>
                                            <td>{d.name}</td>
                                            <td>{d.creator}</td>
                                            <td>{d.notes}</td>
                                            <td>{d.status}</td>
                                            <td style={{ textAlign: 'center' }}>

                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-square" viewBox="0 0 16 16"
                                                    onClick={() => onReview(d.uuid)}>
                                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                </svg>
                                                <span className="ms-1"></span>
                                                {downloadingDocument !== false && clickedRow === d.uuid ?
                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                    </div>
                                                    :
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="ms-3 bi bi-download" viewBox="0 0 16 16"
                                                        onClick={() => onEsignDownload(d.uuid, d.name)}>
                                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                    </svg>
                                                }
                                            </td>
                                        </tr>
                                        :
                                        <tr key={d.uuid}>
                                            <td>{moment(d.createdAt).calendar()}</td>
                                            <td>{d.name}</td>
                                            <td>{d.creator}</td>
                                            <td>{d.notes}</td>
                                            <td>{d.status}</td>
                                            <td style={{ textAlign: 'center' }}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-square" viewBox="0 0 16 16"
                                                onClick={() => onReview(d.uuid)}>
                                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                            </svg></td>
                                        </tr>
                                    )}
                            </Table>
                        </div>
                    </div>
                </Section>
                <Section label="Files">

                    <form onSubmit={onSubmit}>
                        <div className={"row"}>
                            <div className={"col"}>
                                <input type="file" accept={"image/*,.pdf"}
                                    className={"form-control"}
                                    id={"supportingDocument"}
                                    disabled={uploadDocumentLoading}
                                    required={true}
                                    ref={fileInputRef}
                                    //value={supportingDocumentName}
                                    onChange={e => {
                                        setUploadDocument(e.target?.files?.[0])
                                        let value = e.target?.files?.[0];
                                        if (value?.size && value.size > 1048576 * 25) {
                                            setUploadError("File must be less than 25 MB.")
                                        }
                                    }} />
                            </div>
                            <div className={"col"}>
                                <button type={"submit"} className={"btn btn-primary"}>
                                    <span hidden={uploadDocumentLoading}>
                                        Upload
                                    </span>
                                    <span hidden={!uploadDocumentLoading}>
                                        <span className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"></span>
                                        Uploading...
                                    </span>
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className={"row"}>
                        <div className={"col"}>
                            {uploadError && <div className={"mt-3"}><Alert type={AlertType.DANGER} message={uploadError} /></div>}
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"col"}>
                            <Table headers={["Created Date", "Name", "Creator", "Type", "Size"]} defaultPaginationSize={10}>
                                {
                                    files.map(f => (
                                        <tr key={f.uuid}>
                                            <td>{moment(f.createdAt).calendar()}</td>
                                            <td>{f.fileName}</td>
                                            <td>{f.creator}</td>
                                            <td>{f.mimeType}</td>
                                            <td>{getSizeInMB(parseInt(f.size))}</td>
                                            <td>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22"
                                                    fill="currentColor"
                                                    className="bi bi-x-circle" viewBox="0 0 16 16" onClick={() => {
                                                        doDelete(f.uuid)
                                                    }}>
                                                    <path
                                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                    <path
                                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                </svg>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="ms-3 bi bi-download" viewBox="0 0 16 16"
                                                    onClick={() => {
                                                        downloadFile(f.uuid, f.fileName);
                                                    }}>
                                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                </svg>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </Table>
                        </div>
                    </div>
                </Section>
                {application.status.toLowerCase().includes("submitted") &&
                    <div className={"row mt-3"}>
                        <div className="col-md-6">
                            <Link to={pathname + "/create-lease"}>
                                <button type={"button"} className={"btn btn-primary"}>
                                    Approve & Create Lease
                                </button>
                            </Link>
                        </div>
                    </div>
                }
            </PageLayout>
        </>
    }

    function iframePathBuilder(profileId: string, ext: string) {
        const access_token = localStorage.getItem(AUTH_ACCESS_TOKEN);
        const refresh_token = localStorage.getItem(AUTH_REFRESH_TOKEN);
        const expires_in = localStorage.getItem(AUTH_EXPIRES_IN);
        const scope = localStorage.getItem(AUTH_SCOPE);
        const token_type = localStorage.getItem(AUTH_TOKEN_TYPE);
        const clock_start = localStorage.getItem(AUTH_CLOCK_START);
        const redirect_uri = "/p/m/" + profileId + "/documents/" + ext + "?inFrame=true";

        if (access_token && refresh_token && expires_in && scope && token_type && clock_start && redirect_uri) {
            let path = ESIGN_UI_ENDPOINT + "iframe_callback" + "?access_token=" + access_token + "&refresh_token=" + refresh_token + "&expires_in=" + expires_in + "&scope="
                + scope + "&token_type=" + token_type + "&clock_start=" + clock_start + "&redirect_uri=" + encodeURIComponent(redirect_uri);
            return path;
        } else {
            return ""
        }
    }

    const onBack = function () {
        setShowCreateDocument(false);
        setSelectedTemplate("");
        load();
    }

    function renderEsignIframe(src: string) {
        if (src && src !== "") {
            return <>
                <div style={{ height: '120vh' }}>
                    <button className='btn btn-secondary' onClick={onBack}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-90deg-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z" />
                        </svg>
                        <span className='ms-1'> Return to list</span>
                    </button>
                    <iframe src={src} style={{ margin: 'auto', width: '100%', height: '100%' }}></iframe>
                </div>
            </>
        } else {
            return <>
                <Alert show={true} type={AlertType.DANGER} message={"Issue accessing Esign, if issue persists for more than 24 hours, please contact support: contact@propertymq.com"} />
            </>
        }
    }

    function getBodyContent() {
        if (!showCreateDocument && application) {
            return renderReviewApp(application);
        } else {
            if (selectedTemplate && createdDocumentId) {
                return renderEsignIframe(iframePathBuilder(profileId, createdDocumentId));
            } else if (selectedDocumentId) {
                return renderEsignIframe(iframePathBuilder(profileId, selectedDocumentId));
            } else {
                setShowCreateDocument(false);
            }
        }
    }


    function getContent() {
        if (!application) {
            return loadingApp();
        } else {
            return getBodyContent();
        }
    }


    return <>
        {getContent()}
    </>
};

export default ReviewApplication;
