import React, {FormEvent, useContext, useState} from "react";
import Input from "../../../Components/Form/Input";
import {DateRegex, DollarAmountRegex, formInputValidate, GenericInputRegex} from "../../../types";
import PageLayout from "../../../Layouts/PageLayout";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";
import {deposit} from "../../../Api/PropertyApi";

const Deposits: React.FunctionComponent = () => {

    const propertyId = useContext(PropertyIdContext);

    const [transactionDate, setTransactionDate] = useState("");
    const [amount, setAmount] = useState("");
    const [description, setDescription] = useState("");
    const [errorMessage, setErrorMessage] = useState("")
    const [successMessage, setSuccessMessage] = useState("");
    const [loading, setLoading] = useState(false);

    function clear() {
        setTransactionDate("");
        setAmount("");
        setDescription("");
    }
    function doSubmit(e: FormEvent) {
        e.preventDefault();
        setLoading(true);
        deposit(propertyId, {
            amount: parseFloat(amount),
            date: transactionDate,
            description: description
        })
            .then((r) => {
                clear();
                setSuccessMessage("Deposit created.")
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }

    return <PageLayout title={"Deposit"} propertyId={propertyId}>
        <form onSubmit={doSubmit}>
            <div className={"row"}>

                <div className={"col-md-3 mb-3"}>
                    <Input id={"date"} validation={DateRegex.expression}
                           doValidate={formInputValidate}
                           value={transactionDate}
                           setValue={setTransactionDate}
                           required={true}
                           invalidFeedbackText={DateRegex.helpText}
                           type={"date"} label={"Date"}
                    />
                </div>
                <div className={"col-md-3 mb-3"}>
                    <Input id={"description"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={description}
                           setValue={setDescription}
                           required={true}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"text"} label={"Description"}
                    />
                </div>
                <div className={"col-md-3 mb-3"}>
                    <Input id={"amount"} validation={DollarAmountRegex.expression}
                           doValidate={formInputValidate}
                           value={amount}
                           setValue={setAmount}
                           required={true}
                           invalidFeedbackText={DollarAmountRegex.helpText}
                           type={"number"} label={"Amount"}
                    />
                </div>
            </div>
            <div className={"row"}>

                <div className={"col-md-12 mb-3"}>
                    <button className={"btn btn-primary"}>Make Deposit</button>
                </div>
                <div className={"col-md-12 mb-3"}>
                    <div hidden={!successMessage} className="alert alert-success" role="alert">
                        {successMessage}
                    </div>

                    <div hidden={!errorMessage} className="alert alert-success" role="alert">
                        {errorMessage}
                    </div>
                </div>

            </div>


        </form>
    </PageLayout>
};

export default Deposits;