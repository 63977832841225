import * as React from 'react';
import Tabs, {Tab} from "../../Tabs/Tabs";
import {useParams} from "react-router";
import {useEffect, useState} from "react";
import LoadingOverlay from "../../LoadingOverlay";
import Alert, {AlertType} from "../../Alert";
import {getTenantGuest, TenantGuest} from "../../../Api/TenantGuestApi";
import {ARAccount, Property} from "../../../types";
import {getProperty} from "../../../Api/PropertyApi";
import {getARAccountById} from "../../../Api/AccountApi";
import ViewTenantGuest from "./View/ViewTenantGuest";
import EditTenantGuest from "./Edit/EditTenantGuest";
import CheckInTenantGuest from "./CheckIn/CheckInTenantGuest";

const ManageTenantGuest: React.FunctionComponent = () => {
    enum ManageTenantGuestView {
        OVERVIEW="View",EDIT="Edit",CHECK_IN="Check In"
    }

    const tabs: Tab[] = [
        {
            targetView: ManageTenantGuestView.OVERVIEW,
            label: ManageTenantGuestView.OVERVIEW
        },
        {
            targetView: ManageTenantGuestView.EDIT,
            label: ManageTenantGuestView.EDIT,
        },
        {
            targetView: ManageTenantGuestView.CHECK_IN,
            label: ManageTenantGuestView.CHECK_IN
        }
    ]

    const { propertyId, accountId, guestId } = useParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [error, setError] = useState<Error>();
    const [view, setView] = useState<ManageTenantGuestView>(ManageTenantGuestView.OVERVIEW)

    const [property, setProperty] = useState<Property>();
    const [account, setAccount] = useState<ARAccount>();
    const [guest, setGuest] = useState<TenantGuest>();

    useEffect(() => {
        setLoading(true);
        Promise.all([
            getProperty(propertyId).then(data => data),
            getARAccountById(propertyId, accountId).then(data => data),
            getTenantGuest(propertyId, accountId, guestId).then(data => data)
        ])
            .then(values => {
                setProperty(values[0]);
                setAccount(values[1]);
                setGuest(values[2]);
            })
            .catch(setError)
            .finally(() => setLoading(false));
    }, [propertyId, accountId, guestId, refresh])

    return (loading ? <LoadingOverlay show={loading} /> : <>
        <div className="row p-1">
            {error && <Alert type={AlertType.DANGER} message={error.message} />}
            <div className="col-12 mb-3">
                <h3>Front Desk Information</h3>
                <h5>{property?.name}</h5>
                {property?.address.addressLine1}<br/>
                {property?.address.addressLine2}<br hidden={(property?.address.addressLine2) !== undefined}/>
                {property?.address.city}, {property?.address.stateCode} {property?.address.zip}
            </div>
            <Tabs view={view} setView={setView} tabs={tabs} />
            <div className="col-9 widget">
                {account && guest && <>
                    <div hidden={view !== ManageTenantGuestView.OVERVIEW}>
                        <ViewTenantGuest account={account} guest={guest} />
                    </div>
                    <div hidden={view !== ManageTenantGuestView.EDIT}>
                        <EditTenantGuest account={account} guest={guest} done={() => {
                            setRefresh(!refresh);
                            setView(ManageTenantGuestView.OVERVIEW);
                        }} />
                    </div>
                    <div hidden={view !== ManageTenantGuestView.CHECK_IN}>
                        <CheckInTenantGuest account={account} guest={guest} done={() => {
                            setRefresh(!refresh)
                        }} />
                    </div>
                </>}
            </div>
        </div>
    </>)
}

export default ManageTenantGuest;
