import * as React from 'react';
import './index.css'
import Table from "../../Table";
import {useContext, useEffect, useState} from "react";
import {getAllInboundCommunications, InboundCommunication} from "../../../Api/InboundCommunicationApi";
import phoneFormatter from "phone-formatter";
import {getProperty} from "../../../Api/PropertyApi";
import {Property} from "../../../types";
import moment from "moment";
import PageLayout from "../../../Layouts/PageLayout";
import LoadingOverlay from "../../LoadingOverlay";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";

const ViewLeads: React.FunctionComponent = () => {

    const propertyId = useContext(PropertyIdContext)

    const [error, setError] = useState<Error>()
    const [loading, setLoading] = useState(false);
    const [property, setProperty] = useState<Property>();
    const [inboundCommunications, setInboundCommunications] = useState<InboundCommunication[]>();

    useEffect(() => {
        if (propertyId) {
            setLoading(true);
            Promise.all([
                getProperty(propertyId),
                getAllInboundCommunications(propertyId, {})
            ])
                .then(values => {
                    setProperty(values[0])
                    setInboundCommunications(values[1])
                })
                .catch(setError)
                .finally(() => setLoading(false));
        }
    }, [propertyId])
    return <>
        {property ?
            <PageLayout title="Inbound Leads" property={property}>
                {loading ?
                    <LoadingOverlay />
                    :
                    <Table headers={["Timestamp","Type", "Number", "Contact", "Message", ""]} defaultPaginationSize={10}>
                        {inboundCommunications && inboundCommunications.map((i) => (
                            <tr key={i.id}>
                                <td className={"fit"}>{moment(i.date).format("MMM Do, YYYY hh:mm a")}</td>
                                <td className={"fit"}>{i.type}</td>
                                <td className={"fit"}>{phoneFormatter.format(i.toNumber, "(NNN) NNN-NNNN")}</td>
                                <td className={"fit"}>{phoneFormatter.format(i.fromNumber, "(NNN) NNN-NNNN")}</td>
                                <td className={"wrap"}> {i.message}</td>
                                <td className={"fit"}>{(i.audioUrl ?
                                    <audio controls src={i.audioUrl}></audio>
                                    : <></>)
                                }
                                </td>
                            </tr>
                        ))}
                    </Table>
                }
            </PageLayout>
            :
            <LoadingOverlay />
        }
    </>
};

export default ViewLeads;