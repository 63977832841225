import {FormBuilderField, FormFieldType} from "../../../Components/Form/FieldBuilder/FormFieldBuilder";
import {
    DateRegex,
    EmailRegex,
    FirstNameRegex,
    formInputValidate, formSelectValidate, GenericSelectRegex,
    LastNameRegex,
    PhoneNumberRegex,
    TicketIdRegex
} from "../../../types";

interface SearchJourneyLeasesForm {
    ticketId: FormBuilderField
    firstName: FormBuilderField
    lastName: FormBuilderField
    email: FormBuilderField
    phone: FormBuilderField
    followUpDate: FormBuilderField
    closed: FormBuilderField
}

const SearchJourneyLeaseForm: SearchJourneyLeasesForm = {
    ticketId: {
        type: FormFieldType.INPUT,
        element: {
            id: 'ticketId',
            required: false,
            validate: formInputValidate,
            regex: TicketIdRegex,
        },
        label: 'Ticket Id',
        value: undefined,
        setValue: () => {}
    },
    firstName: {
        type: FormFieldType.INPUT,
        element: {
            id: 'firstName',
            required: false,
            validate: formInputValidate,
            regex: FirstNameRegex
        },
        label: 'First Name',
        value: undefined,
        setValue: () => {}
    },
    lastName: {
        type: FormFieldType.INPUT,
        element: {
            id: 'lastName',
            required: false,
            validate: formInputValidate,
            regex: LastNameRegex
        },
        label: 'Last Name',
        value: undefined,
        setValue: () => {}
    },
    email: {
        type: FormFieldType.INPUT,
        element: {
            id: 'email',
            required: false,
            validate: formInputValidate,
            regex: EmailRegex
        },
        label: 'Email',
        value: undefined,
        setValue: () => {}
    },
    phone: {
        type: FormFieldType.INPUT,
        element: {
            id: 'phone',
            required: false,
            validate: formInputValidate,
            regex: PhoneNumberRegex
        },
        label: 'Phone',
        value: undefined,
        setValue: () => {}
    },
    followUpDate: {
        type: FormFieldType.DATE,
        element: {
            id: 'followUpDate',
            required: false,
            validate: formInputValidate,
            regex: DateRegex
        },
        label: 'Follow Up Date',
        value: undefined,
        setValue: () => {}
    },
     closed: {
        type: FormFieldType.SELECT,
        element: {
            id: 'closedOut',
            required: false,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        label: 'Status',
        defaultSelectOptions: [
            {
                value: false,
                label: "Open",
            },
            {
                value: true,
                label: "Closed",
            },
        ],
        value: undefined,
        setValue: () => {}
    },
}

export default SearchJourneyLeaseForm;