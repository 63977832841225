import React from "react";
import {Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import AutoSelectProperty from "../../Components/Form/SelectProperty/AutoSelectProperty";
import SearchApplications from "../../Components/SearchApplications";
import AddApplication from "../../Components/AddApplication";
import ReviewApplication from "../../Components/ReviewApplication";
import CreateLease from "../../Components/ManageLeases/CreateLease";
import InviteToApply from "../../Components/InviteToApply";

const ApplicationsContainer: React.FunctionComponent = () => {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <AutoSelectProperty name={"Applications"} />
            </Route>
            <Route exact path={path + "/:propertyId"}>
                <SearchApplications />
            </Route>
            <Route path={path + "/:propertyId/add"}>
                <AddApplication />
            </Route>
            <Route path={path + "/:propertyId/add/:stage"}>
                <AddApplication />
            </Route>
            <Route exact path={path + "/:propertyId/view/:applicationId"}>
                <ReviewApplication/>
            </Route>
            <Route exact path={path + "/:propertyId/view/:applicationId/create-lease"}>
                <CreateLease/>
            </Route>
            <Route path={path + "/:propertyId/invite"}>
                <InviteToApply/>
            </Route>

        </Switch>
    )
}

export default ApplicationsContainer;
