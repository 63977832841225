import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod, serializeFilter} from "../api";
import {AppUser} from "../types";

export interface MaintenanceRequest {
    uuid: string,
    accountId: string,
    propertyId: string,
    propertyName: string,
    unitName: string,
    category: string,
    entryPermission: boolean,
    description: string,
    accessDetails: string,
    technicalNotes: string,
    scheduledDate: string,
    createdAt: string,
    updatedAt: string,
    createdByUser: AppUser,
    updatedByUser: AppUser,
    assignedMaintenanceUser: AppUser,
    status: MaintenanceRequestStatus,
}

export interface CreateMaintenanceRequest {
    accountId: string,
    category: string,
    entryPermission: boolean,
    description: string,
    accessDetails: string,
    technicalNotes: string,
    scheduledDate: string,
    assignedMaintenanceUserId: string
}

export interface UpdateMaintenanceRequest {
    category: string,
    technicalNotes: string,
    scheduledDate: string,
    assignedMaintenanceUserId: string,
    status: MaintenanceRequestStatus
}

export enum MaintenanceRequestStatus {
    OPEN="OPEN", COMPLETE="COMPLETE", UNKNOWN="UNKNOWN"
}

export interface MaintenanceRequestFilter {
    propertyId?: string,
    ticketId?: string,
    unitName?: string,
    category?: string,
    createdAfter?: string,
    scheduledDate?: string,
    assignedMaintenanceUserId?: string,
    status?: MaintenanceRequestStatus
}

type getAllMaintenanceRequests = (filter: MaintenanceRequestFilter) => Promise<MaintenanceRequest[]>;
type createMaintenanceRequest = (maintenanceRequest: CreateMaintenanceRequest) => Promise<MaintenanceRequest>;
type getMaintenanceRequestById = (id: string) => Promise<MaintenanceRequest>;
type updateMaintenanceRequest = (id: string, maintenanceRequest: UpdateMaintenanceRequest) => Promise<MaintenanceRequest>;

const MAINTENANCE_REQUEST_API_BASE_URL = (pathExtention: string) => { return "/portfolios/" + getPortfolioId() + "/maintenance-requests" + pathExtention}

export const getAllMaintenanceRequests: getAllMaintenanceRequests = (async filter => {
    return fetchWithAuth(MAINTENANCE_REQUEST_API_BASE_URL("?" + serializeFilter(filter)), HttpMethod.GET);
});

export const getMaintenanceRequestById: getMaintenanceRequestById = (async id => {
    return fetchWithAuth(MAINTENANCE_REQUEST_API_BASE_URL("/" + id), HttpMethod.GET);
})

export const createMaintenanceRequest: createMaintenanceRequest = (async maintenanceRequest => {
    return fetchWithAuth(MAINTENANCE_REQUEST_API_BASE_URL("/"), HttpMethod.POST, JSON.stringify(maintenanceRequest));
});

export const updateMaintenanceRequest: updateMaintenanceRequest = (async (id, maintenanceRequest) => {
    return fetchWithAuth(MAINTENANCE_REQUEST_API_BASE_URL("/" + id), HttpMethod.PUT, JSON.stringify(maintenanceRequest));
});
