import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {
    Account,
    DollarAmountRegex,
    formInputValidate,
    GenericInputRegex,
    GenericSelectRegex,
    Transaction,
    TransactionAccount
} from "../../../../types";
import Input from "../../../Form/Input";
import Select from "../../../Form/Select";
import Table from "../../../Table";
import Card from "../../../Card";

type InitialTransactionsProps = {
    back: Function,
    next: Function,
    transactions: Transaction[],
    accounts: TransactionAccount[]
}

const InitialTransactions: React.FunctionComponent<InitialTransactionsProps> = (p) => {
    const [transactionDescription, setTransactionDescription] = useState("");
    const [transactionAmount, setTransactionAmount] = useState("");
    const [transactions, setTransactions] = useState<Transaction[]>([])
    const [account, setAccount] = useState("");

    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

    useEffect(() => {
        setTransactions(p.transactions);
    }, [p.transactions]);



    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        transactions.push({
            amount: transactionAmount,
            description: transactionDescription,
            accountId: account
        });
        setTransactions([...transactions]);
        clearForm();
    }

    const getAccountName = (accountId: string) => {
        let filtered = p.accounts.filter((a) => a.uuid === accountId);
        if(filtered) {
            return filtered[0].name;
        }
        return "";
    }

    const clearForm = () => {
        setAccount("");
        setTransactionDescription("");
        setTransactionAmount("");
    }

    const getTotal = ():number => {
        let total = 0;
        if(!transactions)  {
            return total;
        }
        transactions.forEach(t => {
            let accountName = getAccountName(t.accountId);
            if(accountName.toLowerCase().indexOf("credit") >= 0) {
                total -= parseFloat(t.amount);
            } else {
                total += parseFloat(t.amount);
            }
        })
        return total;
    }

    const doDelete = (obj: Transaction) => {
        let spliced = transactions.filter(i => i !== obj);
        setTransactions([...spliced]);
    }

    return (<>
        <Card title="Initial Transactions">
            <h5>Amount Due: {currencyFormat.format(getTotal())}</h5>
            <p>Due at start of lease</p>
            <div className="row">
                <div className="col-lg-6">
                    <form className={""} onSubmit={onSubmit}>
                        <div className={"row"}>

                            <div className="col-md-12 mb-1">
                                <Input id={"description"}
                                       type={"text"}
                                       label={"Description"}
                                       required={true}
                                       invalidFeedbackText={GenericInputRegex.helpText}
                                       validation={GenericInputRegex.expression}
                                       doValidate={formInputValidate}
                                       value={transactionDescription}
                                       setValue={setTransactionDescription}
                                />
                            </div>
                            <div className="col-lg-6 mb-1">
                                <Select id={"account"}
                                        label={"Account"}
                                        required={true}
                                        invalidFeedbackText={GenericSelectRegex.helpText}
                                        validation={GenericSelectRegex.expression}
                                        doValidate={formInputValidate}
                                        value={account}
                                        setValue={setAccount}
                                >
                                    <option value={""}>Select a transaction type...</option>
                                    {
                                        p.accounts.map((a) => (
                                            <option key={a.uuid} value={a.uuid}>{a.name}</option>
                                        ))
                                    }
                                </Select>
                            </div>
                            <div className="col-lg-6 mb-1">
                                <Input id={"transactionAmount"}
                                       type={"number"}
                                       label={"Amount"}
                                       required={true}
                                       invalidFeedbackText={DollarAmountRegex.helpText}
                                       validation={DollarAmountRegex.expression}
                                       doValidate={formInputValidate}
                                       value={transactionAmount}
                                       setValue={setTransactionAmount}
                                />
                            </div>
                            <div className="col-md-12">
                                <button type={"submit"} className={"btn btn-primary"} style={{marginTop: 20}}

                                >Add Transaction
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-lg-6">
                    <Table headers={["Description", "Type", "Amount", ""]} defaultPaginationSize={10}>
                        {transactions.map((t, index) => (
                            <tr key={index + "_tran"}>
                                <td>
                                    {t.description}
                                </td>
                                <td>
                                    {getAccountName(t.accountId)}
                                </td>
                                <td>
                                    {currencyFormat.format(parseFloat(t.amount))}
                                </td>
                                <td>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                         fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16"
                                         onClick={() => {
                                             doDelete(t);
                                         }}>
                                        <path
                                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                        <path
                                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </td>
                            </tr>
                        ))}
                    </Table>
                </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between gap-2 mt-3">
                <button type={"button"} className={"btn btn-secondary"}
                        onClick={() => {
                            p.back();
                        }}>
                    Back
                </button>
                <button type={"button"} className={"btn btn-primary"}
                        onClick={() => {
                            p.next(transactions);
                        }}>
                    Next
                </button>
            </div>
        </Card>
    </>);
};

export default InitialTransactions;