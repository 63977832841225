import * as React from 'react';
import {FormEvent, useContext, useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import { useLocation, useParams } from "react-router";
import Alert, { AlertType } from "../Alert";
import {
    Application,
    ApplicationIdRegex,
    ApplicationStatus,
    DateRegex,
    FormElementValidation,
    formInputValidate,
    formSelectValidate,
    GenericSelectRegex,
    Property
} from "../../types";
import LoadingOverlay from "../LoadingOverlay";
import Select from "../Form/Select";
import Input from "../Form/Input";
import moment from "moment";
import { getProperty } from "../../Api/PropertyApi";
import { getApplications } from "../../Api/ApplicationApi";
import PageLayout from "../../Layouts/PageLayout";
import { convertEnumToReadableString } from "../../helpers";
import {PropertyIdContext} from "../../Containers/PrivateMainContainer";


const SearchApplications: React.FunctionComponent = () => {

    const propertyId = useContext(PropertyIdContext)

    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(AlertType.SUCCESS);
    const path = useLocation();

    const [applications, setApplications] = useState<Application[]>([]);
    const [property, setProperty] = useState<Property>();
    const [status, setStatus] = useState("");
    const [applicationId, setApplicationId] = useState("");
    const [createdAfter, setCreatedAfter] = useState("");

    type Form = {
        property: FormElementValidation,
        status: FormElementValidation,
        applicationId: FormElementValidation,
        createdAfter: FormElementValidation
    }

    const form: Form = {
        property: {
            id: "property",
            required: false,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        status: {
            id: "status",
            required: false,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        applicationId: {
            id: "applicationId",
            required: false,
            validate: formInputValidate,
            regex: ApplicationIdRegex
        },
        createdAfter: {
            id: "createdAfter",
            required: false,
            validate: formInputValidate,
            regex: DateRegex
        }
    }


    useEffect(() => {
        if (propertyId) {
            setLoading(true);
            Promise.all([
                getProperty(propertyId),
                getApplications({})
            ])
                .then(values => {
                    setProperty(values[0])
                    setApplications(values[1])
                })
                .catch(e => {
                    doShowAlert("Failed to load property: " + e.message, AlertType.DANGER);
                }).finally(() => setLoading(false));
        }
    }, [propertyId]);

    const doShowAlert = (message: string, type: AlertType) => {
        setShowAlert(true);
        setAlertMessage(message);
        setAlertType(type);
    }

    const doHideAlert = () => {
        setShowAlert(false);
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        doHideAlert();
        setLoading(true);
        getApplications({
            propertyId: propertyId,
            status: status,
            applicationId: applicationId,
            createdAfter: createdAfter
        })
            .then((a) => setApplications(a))
            .catch(e => {
                doShowAlert("Failed to get applicants: " + e.message, AlertType.DANGER);
            }).finally(() => setLoading(false));
    }

    const getBadgeColor = (status: ApplicationStatus) => {
        switch (status) {
            case ApplicationStatus.submitted: return "bg-primary"
            case ApplicationStatus.approved: return "bg-success"
            case ApplicationStatus.cancelled: return "bg-danger"
            default: return "bg-secondary"
        }
    }

    return <>
        <Alert type={alertType} message={alertMessage} show={showAlert} />
        {property ?
            <PageLayout title="Applications" property={property} applicationPage={true} addInviteLabel="Invite Applicant to Apply" addInvitePath={path.pathname + "/invite"} addButtonLabel="Start New Application" addButtonPath={path.pathname + "/add/1"}>
                <h6>Filters</h6>
                <div className="col-lg-8 mb-3">
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <Select id={form.status.id} validation={form.status.regex.expression}
                                    doValidate={form.status.validate}
                                    value={status} setValue={setStatus}
                                    required={form.status.required}
                                    invalidFeedbackText={form.status.regex.helpText} label={"Status"}>
                                    <option value={""}>All Statuses</option>
                                    <option value={"submitted"}>Submitted</option>
                                    <option value={"in_progress"}>In Progress</option>
                                    <option value={"approved"}>Approved</option>
                                </Select>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Input id={form.applicationId.id} validation={form.applicationId.regex.expression}
                                    doValidate={form.applicationId.validate}
                                    value={applicationId}
                                    setValue={setApplicationId}
                                    required={form.applicationId.required}
                                    invalidFeedbackText={form.applicationId.regex.helpText}
                                    type={"text"} label={"Application Id"}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <Input id={form.createdAfter.id} validation={form.createdAfter.regex.expression}
                                    doValidate={form.createdAfter.validate}
                                    value={createdAfter}
                                    setValue={setCreatedAfter}
                                    required={form.createdAfter.required}
                                    invalidFeedbackText={form.createdAfter.regex.helpText}
                                    type={"date"} label={"Created after date"}
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary mt-3">Apply Filters</button>
                    </form>
                </div>
                {loading ? <LoadingOverlay /> :
                    <div className={"table-responsive"}>
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Application Id</th>
                                    <th scope={"col"}>Date</th>
                                    <th scope="col">Property</th>
                                    <th scope="col">Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {applications.filter(a => a.unit.floorPlan.property.uuid === propertyId).map((a, index) => (
                                    <tr key={"app_" + index}>
                                        <td>{a.uuid}</td>
                                        <td>{moment(a.createdAt, "YYYY-MM-DD").format("MMMM Do YYYY")}</td>
                                        <td>{a.unit?.floorPlan?.property.name}</td>
                                        <td><h6><span className={"badge " + getBadgeColor(a.status)}>{convertEnumToReadableString(a.status)}</span></h6></td>
                                        <td>
                                            {a.status !== ApplicationStatus.approved ? <>
                                                <Link to={path.pathname + "/add/1?editApplication=true"}>
                                                    <button type={"button"} className={"me-1 btn btn-secondary btn-sm"} onClick={() => {
                                                        localStorage.removeItem("app.application.id")
                                                        localStorage.setItem("app.application.id", a.uuid)
                                                        }}>Edit</button>
                                                </Link></> : <></>}
                                            <Link to={path.pathname + "/view/" + a.uuid}>
                                                <button type={"button"} className={"btn btn-primary btn-sm"}>View</button>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                }
            </PageLayout>
            :
            <LoadingOverlay />
        }
    </>
};

export default SearchApplications;
