import * as React from "react";
import {useEffect, useState} from "react";
import AddAmenityReservation from "./Add/AddAmenityReservation";
import {Amenity, AmenityReservation} from "../../../Api/AmenityApi";
import {ARAccount} from "../../../types";
import {getARAccounts} from "../../../Api/AccountApi";
import {useParams} from "react-router";
import LoadingOverlay from "../../LoadingOverlay";
import {SelectOptions} from "../../Form/FieldBuilder/FormFieldBuilder";
import SearchAmenityReservationForm from "../Forms/AmenityReservationSearchForm";
import AddAmenityReservationForm from "../Forms/AddAmenityReservationForm";
import Tabs, {Tab} from "../../Tabs/Tabs";
import SearchAmenityReservations from "./Search/SearchAmenityReservations";
import {AmenityDayViews} from "../Tabs/AmenityDayTabs";

const AmenityReservations: React.FunctionComponent<{ amenity: Amenity, dayView: AmenityDayViews, setDayView: Function, setReservationCount: Function }> = ({amenity, dayView, setDayView, setReservationCount}) => {

    enum AmenityReservationView {
        SEARCH="SEARCH",ADD="ADD"
    }

    const AmenityReservationTabs: Tab[] = [
        {
            label: "Search",
            targetView: AmenityReservationView.SEARCH
        },
        {
            label: "Add",
            targetView: AmenityReservationView.ADD
        }
    ]

    const { propertyId } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [view, setView] = useState<AmenityReservationView>(AmenityReservationView.SEARCH);

    const [accounts, setAccounts] = useState<ARAccount[]>();
    const [addSelectOptions, setAddSelectOptions] = useState<SelectOptions[]>([]);
    const [searchSelectOptions, setSearchSelectOptions] = useState<SelectOptions[]>([]);

    const updateAccountSelectOptions = (accounts: ARAccount[]) => {
        setAccounts(accounts);
        const addOptions: SelectOptions[] = JSON.parse(JSON.stringify(AddAmenityReservationForm.accountId.defaultSelectOptions));
        const searchOptions: SelectOptions[] = JSON.parse(JSON.stringify(SearchAmenityReservationForm.accountId.defaultSelectOptions));
        accounts?.forEach(account => {
            addOptions.push({ value: account.uuid, label: account.unitName });
            searchOptions.push({ value: account.uuid, label: account.unitName });
        });
        setAddSelectOptions(addOptions);
        setSearchSelectOptions(searchOptions);
    }

    useEffect(() => {
        setLoading(true);
        getARAccounts(propertyId)
            .then(updateAccountSelectOptions)
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [propertyId]);

    return (loading ? <LoadingOverlay show={loading} /> : <>
        <div className="row widget">
            <Tabs view={view} setView={setView} tabs={AmenityReservationTabs} />
            {accounts && <>
                <div hidden={view !== AmenityReservationView.SEARCH}>
                    <SearchAmenityReservations reservations={amenity.reservations ? amenity.reservations : []} accounts={accounts} selectOptions={searchSelectOptions} />
                </div>
                <div hidden={view !== AmenityReservationView.ADD}>
                    <AddAmenityReservation amenity={amenity} dayView={dayView} setDayView={setDayView} selectOptions={addSelectOptions} done={(reservation: AmenityReservation) => {
                        amenity.reservations?.push(reservation);
                        setReservationCount(amenity.reservations?.length);
                        setView(AmenityReservationView.SEARCH);
                    }}/>
                </div>
            </>}
        </div>
    </>)
}

export default AmenityReservations;
