import * as React from 'react';
import {useEffect, useState} from 'react';
import {
    ApplicationStatus, EmailRegex,
    FormElementValidation,
    formInputValidate,
    FullNameRegex,
    PhoneNumberRegex
} from "../../../types";
import Alert, {AlertProps, AlertType} from "../../Alert";
import {useHistory, useParams} from "react-router";
import {getPortfolioId} from "../../../helpers";
import LoadingOverlay from "../../LoadingOverlay";
import Input from "../../Form/Input";
import {getApplication, updateApplication} from "../../../Api/ApplicationApi";
import ApplicationStageLayout from "../../../Layouts/ApplicationStageLayout";
import ButtonNew, {ButtonColors, ButtonTypes} from "../../ButtonNew";

type Stage7Props = {
    handleInvalidApplicationStatus: Function
    applicationId: string
    setStage: Function
}

const Stage7: React.FunctionComponent<Stage7Props> = (p) => {

    const { propertyId } = useParams();

    const [error, setError] = useState<Error>()
    const [alert, setAlert] = useState<AlertProps>()
    const [loading, setLoading] = useState(false);
    const [doRedirectToStage6, setDoRedirectToStage6] = useState(false);
    const [doRedirectToStage8, setDoRedirectToStage8] = useState(false);
    const history = useHistory();
    const [doRedirectToStage1, setDoRedirectToStage1] = useState(false);
    const [doHandleInvalidApplicationStatus, setDoHandleInvalidApplicationStatus] = useState(false);

    //form
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    type Form = {
        name: FormElementValidation,
        email: FormElementValidation,
        phone: FormElementValidation
    }

    const form: Form = {
        name: {
            id: "name",
            required: true,
            validate: formInputValidate,
            regex: FullNameRegex
        },
        email: {
            id: "email",
            required: true,
            validate: formInputValidate,
            regex: EmailRegex
        },
        phone: {
            id: "phone",
            required: true,
            validate: formInputValidate,
            regex: PhoneNumberRegex
        }
    };


    useEffect(() => {
        if (doHandleInvalidApplicationStatus) {
            p.handleInvalidApplicationStatus();
        }
    }, [doHandleInvalidApplicationStatus, p]);

    useEffect(() => {
        if (doRedirectToStage1) {
            setDoRedirectToStage1(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/1"
            });
        }

    }, [doRedirectToStage1, history])

    useEffect(() => {
        console.log("stage 7 init");
        p.setStage(7)
        setLoading(true);
        getApplication(getPortfolioId(), p.applicationId)
            .then((a) => {
                if (a.status !== ApplicationStatus.approved) {
                    if (!a.unit || (a.unit && !a.unit.uuid)) {
                        console.log("redirect to stage 1, missing unit")
                        setDoRedirectToStage1(true);
                        return;
                    }
                    if (a.emergencyContacts && a.emergencyContacts.length > 0) {
                        setName(a.emergencyContacts[0].name);
                        setPhone(a.emergencyContacts[0].phone);
                        setEmail(a.emergencyContacts[0].email);
                    }
                    setLoading(false);
                } else {
                    setDoHandleInvalidApplicationStatus(true);
                }
            })
            .catch(e => {
                doShowAlert("Failed to start application: " + e.message, AlertType.DANGER);
                setLoading(false);
            })
    }, [p]);

    const validateForm = (form: Form): boolean => {
        if (!form.name.validate(name, form.name.required, form.name.regex.expression)) {
            doShowAlert("Invalid name.", AlertType.DANGER);
            return false;
        }
        if (!form.email.validate(email, form.email.required, form.email.regex.expression)) {
            doShowAlert("Invalid email.", AlertType.DANGER);
            return false;
        }
        if (!form.phone.validate(phone, form.phone.required, form.phone.regex.expression)) {
            doShowAlert("Invalid phone.", AlertType.DANGER);
            return false;
        }
        return true;
    }

    const saveAndContinueStage8 = () => {
        if(!name && !phone && !email) {
            setDoRedirectToStage8(true);
        }
        if (!validateForm(form)) {
            return;
        }
        setLoading(true);
        updateApplication(getPortfolioId(), p.applicationId, {
            emergencyContacts: [{
                name: name,
                email: email,
                phone: phone
            }]
        })
            .then((a) => {
                setDoRedirectToStage8(true);
            })
            .catch(e => {
                doShowAlert("Failed to add addresses: " + e.message, AlertType.DANGER);
            }).finally(() => setLoading(false));
    }

    const doShowAlert = (message: string, type: AlertType) => {
        setAlert({type, message})
    }

    useEffect(() => {
        if (doRedirectToStage8) {
            setDoRedirectToStage8(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/8"
            });
        }

    }, [doRedirectToStage8, history])

    useEffect(() => {
        if (doRedirectToStage6) {
            setDoRedirectToStage6(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/6"
            });
        }

    }, [doRedirectToStage6, history])

    const [isStageValid, setIsStageValid] = useState<boolean>(false)

    useEffect(() => {
        setIsStageValid(
            name !== "" &&
            email !== "" &&
            phone !== ""
        )
    }, [name, email, phone])

    return (loading ? <div className="col-md-6"><LoadingOverlay /></div> : <>
        <ApplicationStageLayout
            error={error}
            alert={alert}
            title="Emergency Contact"
            description="Please provide a contact in case of an emergency."
        >
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <div className={"row"}>
                        <div className={"col-md-12 mb-3"}>

                            <Input id={form.name.id} validation={form.name.regex.expression}
                                   doValidate={form.name.validate}
                                   value={name}
                                   setValue={setName}
                                   required={form.name.required}
                                   invalidFeedbackText={form.name.regex.helpText}
                                   type={"text"} label={"Name"}
                            />
                        </div>
                        <div className={"col-md-6 mb-3"}>

                            <Input id={form.email.id} validation={form.email.regex.expression}
                                   doValidate={form.email.validate}
                                   value={email}
                                   setValue={setEmail}
                                   required={form.email.required}
                                   invalidFeedbackText={form.email.regex.helpText}
                                   type={"text"} label={"Email"}
                            />
                        </div>
                        <div className={"col-md-6 mb-3"}>

                            <Input id={form.phone.id} validation={form.phone.regex.expression}
                                   doValidate={form.phone.validate}
                                   value={phone}
                                   setValue={setPhone}
                                   required={form.phone.required}
                                   invalidFeedbackText={form.phone.regex.helpText}
                                   placeholder={"xxx-xxx-xxxx"}
                                   type={"text"} label={"Phone"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-start gap-2 mt-3">
                <ButtonNew
                    type={ButtonTypes.BUTTON}
                    color={ButtonColors.SECONDARY}
                    label="Back"
                    onClick={() => setDoRedirectToStage6(true)}
                />
                <ButtonNew
                    type={ButtonTypes.BUTTON}
                    color={ButtonColors.PRIMARY}
                    label="Save & Continue"
                    loading={loading}
                    disabled={false}
                    onClick={saveAndContinueStage8}
                />
            </div>
        </ApplicationStageLayout>
    </>);
};

export default Stage7;
