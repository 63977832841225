import {FormBuilderField, FormFieldType} from "../../../Components/Form/FieldBuilder/FormFieldBuilder";
import {EmailRegex, FirstNameRegex, formInputValidate, GenericInputRegex, LastNameRegex} from "../../../types";

interface SearchUserForm {
    firstName: FormBuilderField
    lastName: FormBuilderField
    email: FormBuilderField
}


const SearchUserForm: SearchUserForm = {
    firstName: {
        type: FormFieldType.INPUT,
        element: {
            id: 'firstName',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'First Name',
        value: undefined,
        setValue: () => {
        }
    },
    lastName: {
        type: FormFieldType.INPUT,
        element: {
            id: 'lastName',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Last Name',
        value: undefined,
        setValue: () => {
        }
    },
    email: {
        type: FormFieldType.INPUT,
        element: {
            id: 'email',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        label: 'Email',
        value: undefined,
        setValue: () => {
        }
    },
}

export default SearchUserForm;