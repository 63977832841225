import React, {useEffect, useState} from "react";
import PageLayout from "../../Layouts/PageLayout";
import Input from "../../Components/Form/Input";
import {DateRegex, ExpenseRegister, formInputValidate} from "../../types";
import moment from "moment";
import {createBillCheck, getExpenseRegister} from "../../Api/PortfolioApi";
import {getPortfolioId} from "../../helpers";
import {openFile} from "../../Api/FileApi";


const ViewExpenseRegister: React.FunctionComponent = () => {
    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [startDate, setStartDate] = useState(() => {
        let today = new Date();
        let daysAgo = new Date(today.setDate(today.getDate() - 30));
        return moment(daysAgo).format("YYYY-MM-DD");
    })
    const [endDate, setEndDate] = useState(() => moment(new Date()).format("YYYY-MM-DD"));
    const [expenseRegistry, setExpenseRegistry] = useState<ExpenseRegister>();
    const [selectedBillId, setSelectedBillId] = useState("");

    function doOpenCheck(billId: string) {
        setSelectedBillId(billId);
        createBillCheck(getPortfolioId(), billId)
            .then(r => {
                openFile(r.uuid, r.uuid)
                    .then((b) => {
                    })
                    .catch(() => {
                    });
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => {
                setSelectedBillId("");
            });
    }

    function load() {
        setLoading(true);
        getExpenseRegister(getPortfolioId(), startDate, endDate)
            .then((dr) => {
                setExpenseRegistry(dr);
            })
            .catch((e) => setErrorMessage(e.message))
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (startDate && endDate) {
            load();
        }
    }, [startDate, endDate]);


    function getPostDate(date: string, cleared: boolean) {

        if (cleared) {
            return <>
                {date}
            </>;
        }
        return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    className="bi bi-clock-history" viewBox="0 0 16 16">
            <path
                d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022zm2.004.45a7 7 0 0 0-.985-.299l.219-.976q.576.129 1.126.342zm1.37.71a7 7 0 0 0-.439-.27l.493-.87a8 8 0 0 1 .979.654l-.615.789a7 7 0 0 0-.418-.302zm1.834 1.79a7 7 0 0 0-.653-.796l.724-.69q.406.429.747.91zm.744 1.352a7 7 0 0 0-.214-.468l.893-.45a8 8 0 0 1 .45 1.088l-.95.313a7 7 0 0 0-.179-.483m.53 2.507a7 7 0 0 0-.1-1.025l.985-.17q.1.58.116 1.17zm-.131 1.538q.05-.254.081-.51l.993.123a8 8 0 0 1-.23 1.155l-.964-.267q.069-.247.12-.501m-.952 2.379q.276-.436.486-.908l.914.405q-.24.54-.555 1.038zm-.964 1.205q.183-.183.35-.378l.758.653a8 8 0 0 1-.401.432z"/>
            <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0z"/>
            <path
                d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5"/>
        </svg>
    }

    function getPrintCheckSpinner(billId: string) {
        if(selectedBillId === billId) {
            return <>
                <span className="spinner-border spinner-border-sm ms-1" aria-hidden="true"></span>
                <span className="visually-hidden" role="status">Loading...</span>
            </>
        }
        return <></>;
    }

    return <PageLayout title={"Expense Register"}>

        <div className={"row"}>

            <div className={"col-md-12 mb-3"}>
                <button className={"btn btn-primary me-3 mb-3"} onClick={(e) => {
                    e.preventDefault();
                    var element = document.getElementById('pdf-print');
                    // @ts-ignore
                    html2pdf(element, {
                        margin: .1,
                        jsPDF: {
                            unit: 'in',
                            format: 'letter',
                            orientation: 'landscape'
                        },
                        filename: "Deposit Register"
                    });
                }}>Download PDF
                </button>


            </div>
        </div>
        <div >
            <div className={"row"}>

                <div className={"col-md-3 mb-3"}>
                    <Input id={"startDate"} validation={DateRegex.expression}
                           doValidate={formInputValidate}
                           value={startDate}
                           setValue={setStartDate}
                           required={true}
                           invalidFeedbackText={DateRegex.helpText}
                           type={"date"} label={"Start Date"}
                    />
                </div>
                <div className={"col-md-3 mb-3"}>
                    <Input id={"endDate"} validation={DateRegex.expression}
                           doValidate={formInputValidate}
                           value={endDate}
                           setValue={setEndDate}
                           required={true}
                           invalidFeedbackText={DateRegex.helpText}
                           type={"date"} label={"End Date"}
                    />
                </div>

            </div>
            <div className="row">
                <div className="col-md-12">
                    {loading && <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>}
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    <div >
                        <table id="pdf-print" className="table ">
                            <thead style={{
                                position: 'sticky',
                                top: 0, backgroundColor: "white"
                            }}>
                            <tr>
                                <th>Deposit Number</th>
                                <th>Date</th>
                                <th>Pay To</th>
                                <th>Check Number</th>
                                <th>Property</th>
                                <th>Type</th>
                                <th>Reference</th>
                                <th>Amount</th>
                                <th>Cleared</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {expenseRegistry &&
                                expenseRegistry.bankAccounts.map((b) => (
                                    <React.Fragment key={b.uuid}>
                                        <tr style={{backgroundColor: "#CCC"}}>
                                            <td colSpan={10}>Bank Account {b.name}</td>

                                        </tr>
                                        {
                                            b.charges.map(d => (
                                                <React.Fragment key={d.id}>
                                                    <tr style={{backgroundColor: "#cccccc47"}}>
                                                        <td>{d.id}</td>
                                                        <td>{getPostDate(d.date, d.cleared)}</td>
                                                        <td>{d.payTo}</td>
                                                        <td>{d.checkNumber}</td>
                                                        <td></td>
                                                        <td>{d.type}</td>
                                                        <td></td>
                                                        <td><strong>{currencyFormat.format(d.amount)}</strong></td>
                                                        <td>{d.cleared ? "Yes" : "No"}</td>
                                                        <td>
                                                            {d.type === "manual" && <button type={"button"} className={"btn btn-sm btn-primary"}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                doOpenCheck(d.uuid);
                                                            }}
                                                            >
                                                                Print Check
                                                                {getPrintCheckSpinner(d.uuid)}
                                                            </button>}
                                                        </td>
                                                    </tr>
                                                    {

                                                        d.transactions.map((t, count) => (
                                                            <tr key={count+"_trans"}>
                                                                <td></td>
                                                                <td>{t.date}</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>{t.property}</td>
                                                                <td></td>
                                                                <td>{t.description}</td>
                                                                <td>{currencyFormat.format(t.amount)}</td>
                                                                <td></td>
                                                            </tr>
                                                        ))
                                                    }
                                                </React.Fragment>
                                            ))
                                        }

                                    </React.Fragment>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </PageLayout>
}
export default ViewExpenseRegister;