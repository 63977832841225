import * as React from 'react';
import {useEffect, useState} from 'react';
import {Application, ApplicationStatus} from "../../../types";
import Alert, {AlertProps, AlertType} from "../../Alert";
import {useHistory, useParams} from "react-router";
import ViewApplication from "../../ViewApplication";
import {getPortfolioId} from "../../../helpers";
import LoadingOverlay from "../../LoadingOverlay";
import {getApplication} from "../../../Api/ApplicationApi";
import App from "../../../App";
import ApplicationStageLayout from "../../../Layouts/ApplicationStageLayout";
import ButtonNew, {ButtonColors, ButtonTypes} from "../../ButtonNew";

type Stage8Props = {
    handleInvalidApplicationStatus: Function
    applicationId: string
    setStage: Function
}

const Stage8: React.FunctionComponent<Stage8Props> = (p) => {

    const { propertyId } = useParams();

    const [error, setError] = useState<Error>()
    const [alert, setAlert] = useState<AlertProps>()
    const [loading, setLoading] = useState(false);
    const [doRedirectToStage7, setDoRedirectToStage7] = useState(false);
    const [doRedirectToStage9, setDoRedirectToStage9] = useState(false);
    const history = useHistory();

    //form
    const [application, setApplication] = useState<Application>();

    const [doRedirectToStage1, setDoRedirectToStage1] = useState(false);
    const [doHandleInvalidApplicationStatus, setDoHandleInvalidApplicationStatus] = useState(false);

    useEffect(() => {
        if(doHandleInvalidApplicationStatus) {
            p.handleInvalidApplicationStatus();
        }
    }, [doHandleInvalidApplicationStatus, p]);

    useEffect(() => {
        if (doRedirectToStage1) {
            setDoRedirectToStage1(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/1"
            });
        }

    }, [doRedirectToStage1, history])

    useEffect(() => {
        console.log("stage 8 init");
        p.setStage(8)
        setLoading(true);
        getApplication(getPortfolioId(), p.applicationId)
            .then((a) => {
                if (a.status !== ApplicationStatus.approved) {
                    if(!a.unit || (a.unit && !a.unit.uuid)) {
                        console.log("redirect to stage 1, missing unit")
                        setDoRedirectToStage1(true);
                        return;
                    }
                    setApplication(a);
                } else {
                    setDoHandleInvalidApplicationStatus(true);
                }
            })
            .catch(e => {
                doShowAlert("Failed to start application: " + e.message, AlertType.DANGER);
            })
            .finally(() => setLoading(false))


    }, [p])

    const saveAndContinueStage9 = () => {
        setDoRedirectToStage9(true);
    }

    const doShowAlert = (message: string, type: AlertType) => {
        setAlert({type, message})
    }

    useEffect(() => {
        if (doRedirectToStage9) {
            setDoRedirectToStage9(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/9"
            });
        }

    }, [doRedirectToStage9, history])

    useEffect(() => {
        if (doRedirectToStage7) {
            setDoRedirectToStage7(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/7"
            });
        }

    }, [doRedirectToStage7, history])


    return (!application ? <div className="col-md-6"><LoadingOverlay /></div> : <>
        <div className="mb-5">
            <h4>Review Application</h4>
            <p>Please review your application before submitting.</p>
            <hr />
            <ViewApplication application={application}/>
            <div className="d-flex justify-content-start gap-2 mt-3">
                <ButtonNew
                    type={ButtonTypes.BUTTON}
                    color={ButtonColors.SECONDARY}
                    label="Back"
                    onClick={() => setDoRedirectToStage7(true)}
                />
                <ButtonNew
                    type={ButtonTypes.BUTTON}
                    color={ButtonColors.PRIMARY}
                    label="Save & Continue"
                    loading={loading}
                    onClick={saveAndContinueStage9}
                />
            </div>
        </div>
    </>);
};

export default Stage8;
