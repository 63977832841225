import {fetchWithAuth, HttpMethod} from "../api";
import {getPortfolioId} from "../helpers";
import {Invoice, LineItem} from "../types";

export interface PaymentRequestBody {
    idempotentKey: string,
    method: string,
    amount: string,
    checkSerialNumber?: string,
    bankAccount?: {
        accountHolderName: string,
        accountType: string,
        accountNumber: string,
        routingNumber: string
    }
}

interface AddLineItemBody {
    description: string,
    price: number,
    quantity: number,
    taxAmount: number
}

type getInvoices = (startDate: string, endDate: string, propertyId: string, status: string) => Promise<Invoice[]>;
type getInvoice = (invoiceId: string) => Promise<Invoice>;
type sendInvoiceNotification = (invoiceId: string,) => Promise<Response>;
type cancelInvoice = (invoiceId: string) => Promise<Response>;
type payInvoice = (invoiceId: string, paymentSourceId: string) => Promise<Response>;
type addLineItemToInvoice = (invoiceId: string, body: AddLineItemBody) => Promise<LineItem>;
type deleteLineItemOnInvoice = (invoiceId: string, lineItemId: string) => Promise<Response>;
type recordPayment = (invoiceId: string, body: PaymentRequestBody) => Promise<Response>;

export const getInvoices: getInvoices = (async (startDate, endDate, propertyId, status) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/invoices" + "?startDate=" + startDate + "&endDate=" + endDate + "&propertyId=" + propertyId + "&status=" + status, HttpMethod.GET);
});

export const getInvoice: getInvoice = (async (invoiceId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/invoices/" + invoiceId, HttpMethod.GET);
});

export const sendInvoiceNotification: sendInvoiceNotification = (async (invoiceId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/invoices/" + invoiceId + "/send-reminder", HttpMethod.POST);
});

export const cancelInvoice: cancelInvoice = (async (invoiceId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/invoices/" + invoiceId + "/cancel", HttpMethod.POST);
});

export const payInvoice: payInvoice = (async (invoiceId, paymentSourceId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/invoices/" + invoiceId + "/pay?withPaymentSource=" + paymentSourceId, HttpMethod.POST);
});

export const deleteLineItemOnInvoice: deleteLineItemOnInvoice = (async (invoiceId, lineItemId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/invoices/" + invoiceId + "/lineItems/" + lineItemId, HttpMethod.DELETE);
});

export const addLineItemToInvoice: addLineItemToInvoice = (async (invoiceId, body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/invoices/" + invoiceId + "/lineItems", HttpMethod.POST, JSON.stringify(body));
});

export const recordPayment: recordPayment = (async (invoiceId, body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/invoices/" + invoiceId + "/pay", HttpMethod.POST, JSON.stringify(body));
});
