import React from "react";
import {Redirect, Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import JourneyLeases from "../../Pages/JourneyLeases/JourneyLeases";
import AddJourneyLease from "../../Pages/JourneyLeases/AddJourneyLease/AddJourneyLease";
import ViewJourneyLease from "../../Pages/JourneyLeases/ViewJourneyLease/ViewJourneyLease";
import AutoSelectProperty from "../../Components/Form/SelectProperty/AutoSelectProperty";

const JourneyContainer: React.FunctionComponent = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path}>
                <AutoSelectProperty name={"Prospective Tenants"} />
            </Route>
            <Route exact path={path + "/:propertyId"}>
                <JourneyLeases />
            </Route>
            <Route exact path={path + "/:propertyId/add"}>
                <AddJourneyLease />
            </Route>
            <Route exact path={path + "/:propertyId/:journeyId"}>
                <ViewJourneyLease />
            </Route>
        </Switch>
    )
}

export default JourneyContainer;
