import * as React from "react";
import {AmenityDay, AmenityIsOpen} from "../../../Api/AmenityApi";
import {AmenityDayFormInterface} from "../Forms/AmenityDayForm";
import AmenityDayView from "../View/AmenityDayView";
import {FormBuilderField} from "../../Form/FieldBuilder/FormFieldBuilder";
import {simpleTimeToMilitaryTime} from "../Service/AmenityService";

const AmenityDayOverview: React.FunctionComponent<{ form: AmenityDayFormInterface, day: AmenityDay}> = ({form, day}) => {

    const assignFormValues = (field: FormBuilderField, value: any) => {
        field.value = value.toString();
        field.disabled = true;
    }

    assignFormValues(form.isOpen, day.open ? AmenityIsOpen.YES : AmenityIsOpen.NO)
    assignFormValues(form.openTime, simpleTimeToMilitaryTime(day.openTime));
    assignFormValues(form.closeTime, simpleTimeToMilitaryTime(day.closeTime));
    assignFormValues(form.minReservationMinutes, day.minReservationMinutes);
    assignFormValues(form.maxReservationMinutes, day.maxReservationMinutes);
    assignFormValues(form.timeslotBlockMinutes, day.timeslotBlockMinutes);
    assignFormValues(form.cancelBeforeMinute, day.cancelBeforeMinute);
    assignFormValues(form.basePrice, day.basePrice.toFixed(2));
    assignFormValues(form.pricePerMinute, day.pricePerMinute.toFixed(2));

    return <AmenityDayView form={form} />
}

export default AmenityDayOverview;
