import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import {
    deleteTenantFrontDeskInstruction,
    TenantFrontDeskInstruction,
    updateTenantFrontDeskInstruction
} from "../../../../Api/FrontDeskInstructionsApi";
import {ARAccount} from "../../../../types";
import FormFieldBuilder, {assignFormFieldValues, FormFieldType} from "../../../Form/FieldBuilder/FormFieldBuilder";
import FrontDeskInstructionForm from "../Forms/FrontDeskInstructionForm";
import {getTicketIdFromUuid} from "../../../../helpers";
import {useHistory, useParams} from "react-router";
import LoadingOverlay from "../../../LoadingOverlay";
import Alert, {AlertType} from "../../../Alert";
import {CheckCircleFill, XCircleFill} from "../../../Icons/Icons";

const EditFrontDeskInstruction: React.FunctionComponent<{ instruction: TenantFrontDeskInstruction, account: ARAccount, done: Function }> = ({instruction, account, done}) => {

    enum PERMANENT {
        YES = "Yes", NO = "No"
    }

    const { propertyId, accountId, instructionId } = useParams();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [confirm, setConfirm] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    const [comments, setComments] = useState<string>("");
    const [permanent, setPermanent] = useState<PERMANENT>();
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [isValid, setIsValid] = useState<boolean>(false);

    assignFormFieldValues({field: FrontDeskInstructionForm.accountId, value: account.unitName});
    assignFormFieldValues({field: FrontDeskInstructionForm.comments, value: comments, setValue: setComments, disabledOverride: false});
    assignFormFieldValues({field: FrontDeskInstructionForm.permanent, value: permanent, setValue: setPermanent, disabledOverride: false, typeOverride: FormFieldType.SELECT});
    assignFormFieldValues({field: FrontDeskInstructionForm.startDate, value: startDate, setValue: setStartDate, disabledOverride: permanent === PERMANENT.YES, requiredOverride: permanent === PERMANENT.NO, typeOverride: FormFieldType.DATE});
    assignFormFieldValues({field: FrontDeskInstructionForm.endDate, value: endDate, setValue: setEndDate, disabledOverride: permanent === PERMANENT.YES, requiredOverride: permanent === PERMANENT.NO, typeOverride: FormFieldType.DATE});

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (isValid) {
            setLoading(true);
            updateTenantFrontDeskInstruction(propertyId, {
                uuid: instructionId,
                accountId,
                comments,
                permanent: permanent === PERMANENT.YES,
                startDate,
                endDate
            }).then(() => {
                setLoading(false);
                done();
            })
                .catch(e => {
                    setError(e);
                    setLoading(false);
                });
        }
    }

    const onReset = (e: FormEvent | undefined) => {
        e?.preventDefault();
        setComments(instruction.comments);
        setPermanent(instruction.permanent ? PERMANENT.YES : PERMANENT.NO);
        setStartDate(!instruction.permanent ? instruction.startDate : "");
        setEndDate(!instruction.permanent ? instruction.endDate : "");
    }

    const onDelete = () => {
        if (confirm) {
            deleteTenantFrontDeskInstruction(propertyId, accountId, instructionId)
                .then(() => history.push("/p/m/tenant-services/front-desk/" + propertyId));
        }
    }

    useEffect(() => {
        const isCommentValid = comments !== "";
        const isStartDateValid = permanent === PERMANENT.YES || startDate !== "";
        const isEndDateValid = permanent === PERMANENT.YES || endDate !== "";
        setIsValid(isCommentValid && isStartDateValid && isEndDateValid);
    }, [comments, permanent, startDate, endDate])

    useEffect(() => {
        onReset(undefined);
    }, [])

    return (loading ? <LoadingOverlay show={loading} /> : <>
        <div className="row p-1">
            <div className="col-12">
                <div className="row">
                    <h4>Instruction</h4>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Id</label>
                        <h6>{getTicketIdFromUuid(instruction.uuid)}</h6>
                    </div>
                    <div className="col">
                        <label>Created By Management</label>
                        <h6>{instruction.createdByManagement ? <CheckCircleFill color="green"/> : <XCircleFill color="red"/>}</h6>
                    </div>
                    <div className="col">
                        <label>Updated By</label>
                        <h6>{instruction.updateBy.firstName + " " + instruction.updateBy.lastName}</h6>
                    </div>
                </div>
            </div>
        </div>
        <form onSubmit={onSubmit} onReset={onReset}>
            <div className="row p-1">
                <div className="col-md-4">
                    <div className="row d-grid gap-2">
                        <div className="col-12">
                            <FormFieldBuilder field={FrontDeskInstructionForm.accountId} />
                        </div>
                        <div className="col-12">
                            <FormFieldBuilder field={FrontDeskInstructionForm.permanent} selectOptions={FrontDeskInstructionForm.permanent.defaultSelectOptions}/>
                        </div>
                        <div className="col-12">
                            <FormFieldBuilder field={FrontDeskInstructionForm.startDate} />
                        </div>
                        <div className="col-12">
                            <FormFieldBuilder field={FrontDeskInstructionForm.endDate} />
                        </div>
                    </div>
                    <div className="row mt-3 mb-3">
                        <div className="col-8">
                            <div className="btn-group" role="btn-group">
                                <button type="reset" className="btn btn-outline-primary">Reset</button>
                                <button type="submit" className="btn btn-primary" disabled={!isValid}>Update</button>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="row justify-content-end">
                                <div className="btn-group" role="btn-group">
                                    <button disabled={false} type="button" className="btn btn-outline-danger" onClick={() => setConfirm(true)}>Delete</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {error && <Alert type={AlertType.DANGER} message={error.message} />}
                    {confirm && <>
                        <div className="alert alert-warning" role="alert">
                            <p>Are you sure you would like to delete this instruction?  This action cannot be undone.</p>
                            <button className="btn btn-danger me-2" type="button" onClick={() => setConfirm(false)}>No</button>
                            <button className="btn btn-success" type="button" onClick={() => onDelete()}>Yes</button>
                        </div>
                    </>}
                </div>
                <div className="col-md-8">
                    <FormFieldBuilder field={FrontDeskInstructionForm.comments} />
                </div>
            </div>
        </form>
    </>)
}

export default EditFrontDeskInstruction;
