import {FormBuilderField, FormFieldType} from "../../Form/FieldBuilder/FormFieldBuilder";
import {formInputValidate, formSelectValidate, GenericInputRegex, UuidRegex} from "../../../types";

interface CreateConversationForm {
    accountId: FormBuilderField,
    subject: FormBuilderField,
    message: FormBuilderField
}

const CreateConversationForm: CreateConversationForm = {
    accountId: {
        type: FormFieldType.SELECT,
        element: {
            id: 'accountId',
            required: false,
            validate: formSelectValidate,
            regex: UuidRegex,
        },
        label: "Unit",
        defaultSelectOptions: [{
            value: '',
            label: 'Please select...'
        }],
        value: undefined,
        setValue: () => {}
    },
    subject: {
        type: FormFieldType.INPUT,
        element: {
            id: 'subject',
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "Subject",
        value: undefined,
        setValue: () => {}
    },
    message: {
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 8,
        element: {
            id: "message",
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "Message",
        value: undefined,
        setValue: () => {}
    },
}

export default CreateConversationForm;
