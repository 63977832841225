import {ProgressPathStep} from "../index";

const CreateLeasePathSteps: ProgressPathStep[] = [
    {
        label: 'Terms',
        step: 1,
    },
    {
        label: 'Initial Transactions',
        step: 2,
    },
    {
        label: 'Recurring Transactions',
        step: 3,
    },
    {
        label: 'Users',
        step: 4,
    },
    {
        label: 'Review & Create',
        step: 5,
    }
]

export default CreateLeasePathSteps
