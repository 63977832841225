import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import Alert, {AlertProps, AlertType} from "../../Alert";
import {CommunityEvent, getCommunityEvents} from "../../../Api/CommunityEventsApi";
import PropertyHeader from "../../PropertyHeader/PropertyHeader";
import Table from "../../Table";
import {getTicketIdFromUuid} from "../../../helpers";
import moment from "moment";
import FormFieldBuilder, {assignFormFieldValues, SelectOptions} from "../../Form/FieldBuilder/FormFieldBuilder";
import CategoryFilterForm from "../Forms/CategoryFilterForm";
import {useLocation} from "react-router-dom";
import AddCommunityEvent from "../Add/AddCommunityEvent";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";

const ViewCommunityEvents: React.FunctionComponent = () => {

    enum EventsView {
        LIST="LIST",CALENDAR="CALENDAR"
    }

    const propertyId = useContext(PropertyIdContext)
    const { pathname } = useLocation();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [alert, setAlert] = useState<AlertProps>();
    const [error, setError] = useState<Error>();

    const [addEventSelected, setAddEventSelected] = useState<boolean>(false);
    const [view, setView] = useState<EventsView>(EventsView.LIST);
    const [events, setEvents] = useState<CommunityEvent[]>([]);
    const [filteredEvents, setFilteredEvents] = useState<CommunityEvent[]>([]);
    const [categoryFilter, setCategoryFilter] = useState<string>("");
    const [categorySelectOptions, setCategorySelectOption] = useState<SelectOptions[]>([]);

    assignFormFieldValues({field: CategoryFilterForm.category, value: categoryFilter, setValue: setCategoryFilter})

    useEffect(() => {
        const options: SelectOptions[] = [];
        CategoryFilterForm.category.defaultSelectOptions?.forEach(option => {
            options.push({value: option.value, label: option.label})
        });
        events.forEach(event => {
            options.push({value: event.category, label: event.category})
        });
        setCategorySelectOption(options);
    }, [events])

    useEffect(() => {
        const filteredEvents: CommunityEvent[] = [];
        events.forEach(e => {
            if (categoryFilter === "" || categoryFilter === e.category) {
                filteredEvents.push(e);
            }
        })
        setFilteredEvents(filteredEvents)
    }, [events, categoryFilter])

    useEffect(() => {
        if(propertyId) {
            setLoading(true);
            getCommunityEvents(propertyId)
                .then(setEvents)
                .catch(setError)
                .finally(() => setLoading(false));
        }
    }, [propertyId, refresh])

    return <>
        {addEventSelected &&
            <AddCommunityEvent doCancel={() => setAddEventSelected(false)} doSubmit={() => {
                setAddEventSelected(false);
                setRefresh(!refresh);
            }}/>
        }
        <div className="row">
            {error && <Alert type={AlertType.DANGER} message={error.message} />}
            <PropertyHeader title={"Community Events"} />
            <div className="col-xxl-9">
                <div className="col-12 widget">
                    <div className="row p-1">
                        <div className="col">
                            <div className="btn-group">
                                <input id="list_select" type="radio" className="btn-check" name="event_view" autoComplete="off" checked={view === EventsView.LIST} onClick={() => setView(EventsView.LIST)} onChange={() => {}}/>
                                <label htmlFor="list_select" className="btn btn-outline-primary">List</label>
                                <input id="calendar_select" type="radio" className="btn-check" name="event_view" autoComplete="off" checked={view === EventsView.CALENDAR} disabled onClick={() => setView(EventsView.CALENDAR)} onChange={() => {}}/>
                                <label htmlFor="calendar_select" className="btn btn-outline-primary">Calendar</label>
                            </div>
                        </div>
                        <div className="col">
                            <FormFieldBuilder field={CategoryFilterForm.category} selectOptions={categorySelectOptions} />
                        </div>
                        <div className="col">
                            <div className="d-flex justify-content-end">
                                <button type="button" className="btn btn-primary" onClick={() => setAddEventSelected(true)}>Add Event</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div hidden={view !== EventsView.LIST}>
                                <Table headers={[
                                    "Id",
                                    "Name",
                                    "Category",
                                    "Description",
                                    "Start",
                                    "End"
                                ]} defaultPaginationSize={10}>
                                    {filteredEvents.sort((a, b) => {
                                        return moment(a.startTime).isBefore(b.startTime) ? 0 : 1;
                                    }).map(event => (
                                        <tr key={event.uuid} className="clickable" onClick={() => history.push(pathname + "/event/" + event.uuid)}>
                                            <td>{getTicketIdFromUuid(event.uuid)}</td>
                                            <td>{event.name}</td>
                                            <td>{event.category}</td>
                                            <td>{event.description.substring(0, 25)}{event.description.length > 25 ? "..." : ""}</td>
                                            <td>{moment(event.startTime).format("MMM Do, YYYY @ hh:mm a")}</td>
                                            <td>{moment(event.endTime).format("MMM Do, YYYY @ hh:mm a")}</td>
                                        </tr>
                                    ))}
                                </Table>
                            </div>
                            <div hidden={view !== EventsView.CALENDAR}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ViewCommunityEvents;
