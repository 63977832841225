import * as React from 'react';
import {Link, useParams} from "react-router-dom";
import {FormEvent, useEffect, useState} from "react";
import {
    AddressLine1Regex,
    AddressLine2Regex, CityRegex,
    FormElementValidation,
    formInputValidate, GenericSelectRegex,
    Property,
    PropertyNameRegex, ZipRegex
} from "../../../types";
import Alert, {AlertType} from "../../Alert";
import LoadingOverlay from "../../LoadingOverlay";
import Modal from "../../Modal";
import Input from "../../Form/Input";
import StateSelect from "../../StateSelect";
import Table from "../../Table";
import {editPropertyGroup, getPropertyGroup} from "../../../Api/PropertyGroupApi";
import {getProperties} from "../../../Api/PropertyApi";


const EditPropertyGroup: React.FunctionComponent = () => {
    //form
    const [properties, setProperties] = useState(new Array<Property>());
    const [name, setName] = useState("");

    const [showSearchPropertyModal, setShowSearchPropertyModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(AlertType.SUCCESS);

    //Search properties
    const [searchProperties, setSearchProperties] = useState(new Array<Property>());
    const [checkedProperties, setCheckedProperties] = useState(new Array<Property>());
    const [propertyName, setPropertyName] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [searchLoading, setSearchLoading] = useState(false);

    const {propertyGroupUuid} = useParams();

    type Form = {
        name: FormElementValidation,
        propertyName: FormElementValidation,
        addressLine1: FormElementValidation,
        addressLine2: FormElementValidation,
        city: FormElementValidation,
        state: FormElementValidation,
        zip: FormElementValidation,
    }

    const form: Form = {
        name: {
            id: "name",
            required: true,
            validate: formInputValidate,
            regex: PropertyNameRegex
        },
        propertyName: {
            id: "propertyName",
            required: false,
            validate: formInputValidate,
            regex: PropertyNameRegex
        },
        addressLine1: {
            id: "addressLine1",
            required: false,
            validate: formInputValidate,
            regex: AddressLine1Regex
        },
        addressLine2: {
            id: "addressLine2",
            required: false,
            validate: formInputValidate,
            regex: AddressLine2Regex
        },
        city: {
            id: "city",
            required: false,
            validate: formInputValidate,
            regex: CityRegex
        },
        state: {
            id: "state",
            required: false,
            validate: formInputValidate,
            regex: GenericSelectRegex
        },
        zip: {
            id: "zip",
            required: false,
            validate: formInputValidate,
            regex: ZipRegex
        }
    }

    useEffect(() => {
        setLoading(true);
        getPropertyGroup(propertyGroupUuid)
            .then((data) => {
                setName(data.name || "");
                setProperties(data.properties || []);
            }).finally(() => setLoading(false));
    }, [propertyGroupUuid])

    const doShowAlert = (message: string, type: AlertType) => {
        setShowAlert(true);
        setAlertMessage(message);
        setAlertType(type);
    }


    const doHideAlert = () => {
        setShowAlert(false);
    }

    const validateForm = (form: Form): boolean => {
        if (!form.name.validate(name, form.name.required, form.name.regex.expression)) {
            doShowAlert("Invalid name.", AlertType.DANGER);
            return false;
        }

        return true;
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        doHideAlert();
        if (!validateForm(form)) {
            return;
        }
        setLoading(true);
        editPropertyGroup(propertyGroupUuid, {
            name: name,
            properties: properties
        })
            .then(() => {
                doShowAlert("Successfully edited property group: " + name, AlertType.SUCCESS);
            })
            .catch(e => {
                doShowAlert("Failed to edit property group: " + e.message, AlertType.DANGER);
            }).finally(() => setLoading(false))
    }

    const isPropertyChecked = (p: Property) => {
        return checkedProperties.some(c => c.uuid === p.uuid);
    }

    const isInProperties = (p: Property) => {
        return properties.some(c => c.uuid === p.uuid);
    }

    const doSearchPropertyModalCancel = () => {
        setShowSearchPropertyModal(false);
        setCheckedProperties([]);
    }

    const doSearchPropertyModalOk = () => {
        setShowSearchPropertyModal(false);
        checkedProperties.forEach(p => {
            if (!isInProperties(p)) {
                properties.push(p);
            }
        })
        setProperties([...properties])
        setCheckedProperties([]);
    }

    const handleSearch = (e: FormEvent) => {
        e.preventDefault();
        setCheckedProperties([]);
        setSearchLoading(true);
        getProperties({
            name: propertyName,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            stateCode: state,
            zip: zip
        })
            .then((data) => {
                setSearchProperties(data);
            })
            .catch(e => {
                setSearchProperties([]);
            }).finally(() => setSearchLoading(false))
    }

    const deletePropertyFromGroup = (p: Property) => {
        setProperties(properties.filter(i => i.uuid !== p.uuid));
    }

    return (<>

        <div className="row">
            <div className="col-md-6 mb-3">
                <h3>Edit Property Group</h3>
            </div>
        </div>
        <form onSubmit={onSubmit}>
            <div className="row">
                <div className="col-lg-6 mb-3">
                    <div className="row">
                        <div className={"col-md-12 mb-3"}>
                            <Input id={form.name.id} validation={form.name.regex.expression}
                                   doValidate={form.name.validate}
                                   value={name}
                                   setValue={setName}
                                   required={form.name.required}
                                   invalidFeedbackText={form.name.regex.helpText}
                                   type={"text"} label={"Name"}
                            />
                            <LoadingOverlay show={loading}/>
                        </div>

                    </div>

                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-12 mb-3"}>
                    <button disabled={loading} type="button" className="btn btn-primary"
                            onClick={e => setShowSearchPropertyModal(true)}
                    >Add property
                    </button>
                </div>
            </div>
            <div className={"row"}>
                <div className="col-md-6 mb-3">
                    <h5>Properties in group</h5>
                    <p>The following properties will be saved with the group upon submission.</p>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-lg-6 mb-3"}>
                    <LoadingOverlay show={loading}/>

                    <Table headers={["Name", "Address"]}
                           defaultPaginationSize={10}>

                        {
                            properties.map((p, index) => (
                                <tr key={p.uuid + "_" + index}>
                                    <td>{p.name}</td>
                                    <td>{(p.address.addressLine1 || "") + " " + (p.address.addressLine2 || "") + " " +
                                    (p.address.city || "") + " " + (p.address.stateCode || "") + " " + (p.address.zip || "")}</td>
                                    <td>
                                        <button type="button" className="btn btn-danger btn-sm"
                                                onClick={e => deletePropertyFromGroup(p)}
                                        >Remove
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }
                    </Table>

                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-12 mb-3"}>
                    <Link to={"/p/m/realestate/groups"}>
                        <button disabled={loading} type="button" className="btn btn-primary mr-3">Back to property group
                            search
                        </button>
                    </Link>
                    <button disabled={loading} type="submit" className="btn btn-primary"
                    >Save
                    </button>
                </div>
            </div>
            <Alert type={alertType} message={alertMessage} show={showAlert}/>
        </form>
        <Modal show={showSearchPropertyModal} title={"Search for properties to add to group"}
               cancelButtonText={"Cancel"} okButtonText={"Add"} doCancel={doSearchPropertyModalCancel}
               doOk={doSearchPropertyModalOk}>
            <>
                <div className={"container"}>
                    <form onSubmit={handleSearch}>
                        <div className="row form-container">
                            <div className="col-md-12 mb-3">
                                <div className="row">
                                    <div className={"col-md-6 mb-3"}>

                                        <Input id={form.propertyName.id} validation={form.propertyName.regex.expression}
                                               doValidate={form.propertyName.validate}
                                               value={propertyName}
                                               setValue={setPropertyName}
                                               required={form.propertyName.required}
                                               invalidFeedbackText={form.propertyName.regex.helpText}
                                               type={"text"} label={"Name"}
                                        />
                                    </div>
                                    <div className={"col-md-6 mb-3"}>

                                        <Input id={form.addressLine1.id} validation={form.addressLine1.regex.expression}
                                               doValidate={form.addressLine1.validate}
                                               value={addressLine1}
                                               setValue={setAddressLine1}
                                               required={form.addressLine1.required}
                                               invalidFeedbackText={form.addressLine1.regex.helpText}
                                               type={"text"} label={"Address"}
                                        />
                                    </div>
                                    <div className={"col-md-6 mb-3"}>

                                        <Input id={form.addressLine2.id} validation={form.addressLine2.regex.expression}
                                               doValidate={form.addressLine2.validate}
                                               value={addressLine2}
                                               setValue={setAddressLine2}
                                               required={form.addressLine2.required}
                                               invalidFeedbackText={form.addressLine2.regex.helpText}
                                               type={"text"} label={"Address 2"}
                                        />
                                    </div>
                                    <div className={"col-md-6 mb-3"}>
                                        <Input id={form.city.id} validation={form.city.regex.expression}
                                               doValidate={form.city.validate}
                                               value={city}
                                               setValue={setCity}
                                               required={form.city.required}
                                               invalidFeedbackText={form.city.regex.helpText}
                                               type={"text"} label={"City"}
                                        />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <StateSelect id={form.state.id} validation={form.state.regex.expression}
                                                     noSelectionText={"All states"}
                                                     doValidate={form.state.validate}
                                                     value={state} setValue={setState}
                                                     required={form.state.required}
                                                     invalidFeedbackText={form.state.regex.helpText}
                                                     label={"State"}/>
                                    </div>
                                    <div className={"col-md-3 mb-3"}>
                                        <Input id={form.zip.id} validation={form.zip.regex.expression}
                                               doValidate={form.zip.validate}
                                               value={zip}
                                               setValue={setZip}
                                               required={form.zip.required}
                                               invalidFeedbackText={form.zip.regex.helpText}
                                               type={"number"} label={"Zip"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-md-12 mb-3"}>
                                <button disabled={searchLoading} type="submit" className="btn btn-primary">Search
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className={"row"}>
                        <div className="col-md-12 mb-3">
                            <h5>Search results</h5>
                            <p>The following properties were found with your search criteria.
                                Select <strong>Add</strong> to
                                add them to the property group.</p>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-12 mb-3"}>
                            <LoadingOverlay show={searchLoading}/>

                            <Table headers={["Name", "Address"]} defaultPaginationSize={10}>
                                {
                                    searchProperties.map((p, index) => (
                                        <tr key={p.uuid + "_" + index}>
                                            <td>{p.name}</td>
                                            <td>{(p.address.addressLine1 || "") + " " + (p.address.addressLine2 || "") + " " +
                                            (p.address.city || "") + " " + (p.address.stateCode || "") + " " + (p.address.zip || "")}</td>
                                            <td><input className="form-check-input" type="checkbox"
                                                       checked={isPropertyChecked(p)}
                                                       id="defaultCheck1"
                                                       onChange={e => {
                                                           if (e.target.checked && !isPropertyChecked(p)) {
                                                               checkedProperties.push(p);
                                                               setCheckedProperties([...checkedProperties]);
                                                           }
                                                           if (!e.target.checked && isPropertyChecked(p)) {
                                                               setCheckedProperties(checkedProperties.filter(c => c.uuid !== p.uuid));
                                                           }
                                                       }}
                                            /></td>
                                        </tr>
                                    ))
                                }
                            </Table>

                        </div>
                    </div>

                </div>
            </>
        </Modal>

    </>);
};

export default EditPropertyGroup;
