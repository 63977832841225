import * as React from 'react';
import {Route, Switch, useRouteMatch} from "react-router";
import PropertyGeneralLedger from "../../Pages/Accounting/GeneralLedger";
import ViewPropertyGeneralLedger from "../../Pages/Accounting/GeneralLedger";
import AddExpense from "../../Pages/Accounting/AddExpense";
import Deposits from "../../Pages/Accounting/Deposits";
import ViewTrustAccountBalances from "../../Pages/Accounting/TrustAccountBalances";
import ViewManualJournalEntry from "../../Pages/Accounting/ManualJournal";
import ViewReconcile from "../../Pages/Accounting/Reconcile";
import ViewDepositRegister from "../../Pages/DepositRegister";
import ViewReceivables from "../../Pages/Accounting/Receivables";
import ViewPayables from "../../Pages/Accounting/Payables";
import ViewExpenseRegister from "../../Pages/ExpenseRegister";
import CashFlowComparison from "../../Pages/Accounting/CashFlowComparison";

const AccountingContainer: React.FunctionComponent = () => {
    let {path} = useRouteMatch();

    return (<>
            <Switch>
                <Route exact path={path + "/general-ledger"}>
                    <ViewPropertyGeneralLedger/>
                </Route>
                <Route exact path={path + "/add-expense"}>
                    <AddExpense/>
                </Route>
                <Route exact path={path + "/deposits"}>
                    <Deposits/>
                </Route>
                <Route exact path={path + "/trust-account-balances"}>
                    <ViewTrustAccountBalances/>
                </Route>
                <Route exact path={path + "/manual-journal-entry"}>
                    <ViewManualJournalEntry/>
                </Route>
                <Route exact path={path + "/reconcile"}>
                    <ViewReconcile/>
                </Route>
                <Route exact path={path + "/deposit-register"}>
                    <ViewDepositRegister/>
                </Route>
                <Route exact path={path + "/expense-register"}>
                    <ViewExpenseRegister/>
                </Route>
                <Route exact path={path + "/receivables"}>
                    <ViewReceivables/>
                </Route>
                <Route exact path={path + "/payables"}>
                    <ViewPayables/>
                </Route>

            </Switch>
        </>
    )
}

export default AccountingContainer;