import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import {addOwnerReport, getOwners} from "../../../Api/PortfolioApi";
import {getPortfolioId} from "../../../helpers";
import Input from "../../Form/Input";
import {
    formInputValidate,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex,
    GetBusiness
} from "../../../types";
import Select from "../../Form/Select";


const SendOwnerReport: React.FunctionComponent = () => {
    const [owners, setOwners] = useState<GetBusiness[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedOwners, setSelectedOwners] = useState<string[]>([]);
    const [name, setName] = useState("");
    const [selectedReport, setSelectedReport] = useState("")
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [view, setView] = useState("");

    useEffect(() => {
        if (selectedReport) {
            setSuccessMessage("");
        }
    }, [selectedReport]);

    useEffect(() => {
        setLoading(true);
        getOwners(getPortfolioId())
            .then(oz => setOwners(oz))
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }, []);

    function getParameters() {
        if (selectedReport === "owner-statement") {
            return [{
                key: "from_date",
                value: fromDate
            }, {
                key: "to_date",
                value: toDate
            }]
        }
        if (selectedReport === "cash-flow-property-comparison") {
            return [{
                key: "from_date",
                value: fromDate
            }, {
                key: "to_date",
                value: toDate
            }]
        }
        return []
    }

    function getOwnerIds() {
        if (owners && selectAll) {
            return owners.map(o => o.uuid);
        }
        return selectedOwners;
    }

    function doAddOwnerReport() {
        setLoading(true);
        addOwnerReport(getPortfolioId(), {
            name: name,
            type: selectedReport,
            ownerIds: getOwnerIds(),
            parameters: getParameters(),
            preview: false
        })
            .then(r => {
                setSelectedReport("");
                setView("send-select-report-type");
                setSuccessMessage("Successfully submitted report to owners!")
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }

    function getReportAttributes() {
        if (selectedReport && selectedReport === "owner-statement") {
            return getOwnerStatementOptions();
        }
        if (selectedReport && selectedReport === "cash-flow-property-comparison") {
            return getCashFlowPropertyComparisonOptions();
        }
        return <></>
    }

    function onReportSelectSubmit(e: FormEvent) {
        e.preventDefault();

        setView("send-select-owners");
    }

    function getButtonControl() {
        if (selectedReport) {
            return <div className={"row mt-3"}>
                <div className={"col"}>
                    <button type={"button"} className={"btn btn-danger"} onClick={() => setSelectedReport("")}>Cancel
                    </button>
                    <button type={"submit"} className={"btn btn-primary"}>Select Owners</button>
                </div>
            </div>
        }
        return <></>
    }

    function getCashFlowPropertyComparisonOptions() {
        return <>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <Input id={"name"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={name}
                           setValue={setName}
                           required={true}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"text"} label={"Name"}
                    />
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <Input id={"from"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={fromDate}
                           setValue={setFromDate}
                           required={true}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"date"} label={"From Date"}
                    />
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <Input id={"to"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={toDate}
                           setValue={setToDate}
                           required={true}
                           disabled={!fromDate}
                           min={fromDate}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"date"} label={"To Date"}
                    />
                </div>
            </div>
        </>
    }

    function getOwnerStatementOptions() {
        return <>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <Input id={"name"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={name}
                           setValue={setName}
                           required={true}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"text"} label={"Name"}
                    />
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <Input id={"from"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={fromDate}
                           setValue={setFromDate}
                           required={true}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"date"} label={"From Date"}
                    />
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-4"}>
                    <Input id={"to"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={toDate}
                           setValue={setToDate}
                           required={true}
                           disabled={!fromDate}
                           min={fromDate}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"date"} label={"To Date"}
                    />
                </div>
            </div>
        </>
    }

    function getSendContent() {
        if (view === "send-select-owners") {
            return getSelectOwnersView();
        }
        return getSelectReportTypeView();
    }


    function getSelectOwnersView() {
        return <>
            <div className={"row mb-3"}>
                <div className={"col-md-4"}>
                    <table className={"table table-striped"}>
                        <thead>
                        <tr>
                            <th>
                                <input className="form-check-input" type="checkbox" checked={selectAll}
                                       onChange={e => {
                                           if (e.target.checked) {
                                               setSelectAll(true);
                                           } else {
                                               setSelectAll(false);
                                           }

                                       }}/>
                            </th>
                            <th>Name</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            owners.map(o => (
                                <tr key={o.uuid}>
                                    <td>
                                        <input className="form-check-input" type="checkbox"
                                               checked={selectAll || (selectedOwners.includes(o.uuid))}
                                               disabled={selectAll}
                                               onChange={(e) => {
                                                   if (e.target.checked) {
                                                       selectedOwners.push(o.uuid);
                                                       setSelectedOwners([...selectedOwners]);
                                                   } else {
                                                       const index = selectedOwners.indexOf(o.uuid);
                                                       if (index > -1) {
                                                           selectedOwners.splice(index, 1);
                                                           setSelectedOwners([...selectedOwners]);
                                                       }
                                                   }

                                               }}

                                        />
                                    </td>
                                    <td>{o.name}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className={"row mb-3"}>
                <div className={"col"}>
                    <button type={"button"} className={"btn btn-danger"}
                            onClick={() => {
                                setView("send-select-report-type");
                            }}
                    >Cancel
                    </button>
                    <button type={"button"} className={"btn btn-primary"}
                            onClick={(e: FormEvent) => {
                                e.preventDefault();
                                doAddOwnerReport();
                            }}>Submit
                    </button>
                </div>
            </div>
        </>
    }

    function getSelectReportTypeView() {
        return <form onSubmit={onReportSelectSubmit}>
            <div className={"row mb-3"}>
                <div className={"col-md-4"}>
                    <Select id={"report"} validation={GenericSelectRegex.expression}
                            doValidate={formSelectValidate} value={selectedReport} setValue={setSelectedReport}
                            required={true}
                            invalidFeedbackText={GenericSelectRegex.helpText} label={"Owner Report"}>
                        <option disabled={true} value={""}>Select a report...</option>
                        <option value={"owner-statement"}>Owner Statement</option>
                        <option value={"cash-flow-property-comparison"}>Cash Flow Property Comparison</option>
                    </Select>
                </div>
            </div>
            {getReportAttributes()}
            {getButtonControl()}
        </form>
    }


    return <>
        {loading && <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>}
        {errorMessage && <div className="alert alert-danger" role="alert">
            {errorMessage}
        </div>}
        {successMessage && <div className="alert alert-success" role="alert">
            {successMessage}
        </div>}
        {getSendContent()}
    </>
}
export default SendOwnerReport;