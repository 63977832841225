import * as React from "react";
import {AmenityDayFormInterface} from "../Forms/AmenityDayForm";
import {AmenityDay, AmenityIsOpen} from "../../../Api/AmenityApi";
import AmenityDayView, {ApplyAll, CopyFrom} from "../View/AmenityDayView";
import {useEffect, useState} from "react";
import {assignFormFieldValues, parseStringToSimpleTime, simpleTimeToMilitaryTime} from "../Service/AmenityService";

const AddOrEditAmenityDay: React.FunctionComponent<{form: AmenityDayFormInterface, day: AmenityDay | undefined, setDay: Function, copyFrom: CopyFrom, applyAll: ApplyAll, refresh: boolean}> = ({form, day, setDay, copyFrom, applyAll, refresh}) => {

    const [isOpen, setIsOpen] = useState<AmenityIsOpen>(AmenityIsOpen.NO);
    const [openTime, setOpenTime] = useState<string>("");
    const [closeTime, setCloseTime] = useState<string>("")
    const [minReservationMinutes, setMinReservationMinutes] = useState<string>("");
    const [maxReservationMinutes, setMaxReservationMinutes] = useState<string>("");
    const [timeslotBlockMinutes, setTimeslotBlockMinutes] = useState<string>("");
    const [cancelBeforeMinute, setCancelBeforeMinute] = useState<string>("");
    const [basePrice, setBasePrice] = useState<string>("");
    const [pricePerMinute, setPricePerMinute] = useState<string>("");

    useEffect(() => {
        if (day) {
            setIsOpen(day.open ? AmenityIsOpen.YES : AmenityIsOpen.NO);
            setOpenTime(simpleTimeToMilitaryTime(day.openTime));
            setCloseTime(simpleTimeToMilitaryTime(day.closeTime));
            setMinReservationMinutes(day.minReservationMinutes.toString());
            setMaxReservationMinutes(day.maxReservationMinutes.toString());
            setTimeslotBlockMinutes(day.timeslotBlockMinutes.toString());
            setCancelBeforeMinute(day.cancelBeforeMinute.toString());
            setBasePrice(day.basePrice.toFixed(2));
            setPricePerMinute(day.pricePerMinute.toFixed(2));
        }
    }, [refresh]);

    useEffect(() => {
        const amenityDay: AmenityDay = {
            open: isOpen === AmenityIsOpen.YES,
            openTime: parseStringToSimpleTime(openTime.toString()),
            closeTime: parseStringToSimpleTime(closeTime.toString()),
            minReservationMinutes: Number(minReservationMinutes),
            maxReservationMinutes: Number(maxReservationMinutes),
            timeslotBlockMinutes: Number(timeslotBlockMinutes),
            cancelBeforeMinute: Number(cancelBeforeMinute),
            basePrice: Number(basePrice),
            pricePerMinute: Number(pricePerMinute)
        }
        setDay(amenityDay);
    }, [isOpen, openTime, closeTime, minReservationMinutes, maxReservationMinutes, timeslotBlockMinutes, cancelBeforeMinute, basePrice, pricePerMinute])

    assignFormFieldValues(form.isOpen, isOpen, setIsOpen, form.isOpen.element.required, false);
    assignFormFieldValues(form.openTime, openTime, setOpenTime, isOpen === AmenityIsOpen.YES, false);
    assignFormFieldValues(form.closeTime, closeTime, setCloseTime, isOpen === AmenityIsOpen.YES, false);
    assignFormFieldValues(form.minReservationMinutes, minReservationMinutes, setMinReservationMinutes, isOpen === AmenityIsOpen.YES, false);
    assignFormFieldValues(form.maxReservationMinutes, maxReservationMinutes, setMaxReservationMinutes, isOpen === AmenityIsOpen.YES, false);
    assignFormFieldValues(form.timeslotBlockMinutes, timeslotBlockMinutes, setTimeslotBlockMinutes, isOpen === AmenityIsOpen.YES, false);
    assignFormFieldValues(form.cancelBeforeMinute, cancelBeforeMinute, setCancelBeforeMinute, isOpen === AmenityIsOpen.YES, false);
    assignFormFieldValues(form.basePrice, basePrice, setBasePrice, isOpen === AmenityIsOpen.YES, false);
    assignFormFieldValues(form.pricePerMinute, pricePerMinute, setPricePerMinute, isOpen === AmenityIsOpen.YES, false);

    form.closeTime.min = openTime;

    return <AmenityDayView form={form} copyFrom={copyFrom} applyAll={applyAll} />
}

export default AddOrEditAmenityDay;
