import * as React from 'react';
import {FormEvent, useEffect, useState} from "react";
import Table from "../../../Table";
import Input from "../../../Form/Input";
import {
    DollarAmountRegex, EmailRegex,
    FirstNameRegex,
    formInputValidate,
    GenericInputRegex,
    GenericSelectRegex, LastNameRegex, LeaseUser, PhoneNumberRegex, Transaction
} from "../../../../types";
import Select from "../../../Form/Select";
import Card from "../../../Card";

type UsersProps = {
    back: Function,
    next: Function,
    users: LeaseUser[]
}

const LeaseUsers: React.FunctionComponent<UsersProps> = (p) => {

    const [users, setUsers] = useState<LeaseUser[]>([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    useEffect(() => {
        if (p.users) {
            setUsers(p.users)
        }
    }, [p.users])

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        users.push({
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone
        })
        setUsers([...users]);
        clearForm();
    }

    const clearForm = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPhone("");
    }

    const doDelete = (obj: LeaseUser) => {
        let spliced = users.filter(i => i !== obj);
        setUsers([...spliced]);
    }

    return (<>
            <Card title="Users">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <p>User accounts for tenants or co-signers on this lease. Each account will be able to login
                                    at <a target={"_blank"}
                                          href={"https://tenant.propertymq.com"}>tenant.propertymq.com</a> to
                                    view the terms of the lease, make payments, open maintenance requests, etc.</p>
                                <hr />
                            </div>
                            <div className="col-md-3">
                                <form className={""} onSubmit={onSubmit}>
                                    <div className={"row"}>
                                        <div className="col-md-12 mb-1">
                                            <Input id={"firstName"}
                                                   type={"text"}
                                                   label={"First Name"}
                                                   required={true}
                                                   invalidFeedbackText={FirstNameRegex.helpText}
                                                   validation={FirstNameRegex.expression}
                                                   doValidate={formInputValidate}
                                                   value={firstName}
                                                   setValue={setFirstName}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-1">
                                            <Input id={"lastName"}
                                                   type={"text"}
                                                   label={"Last Name"}
                                                   required={true}
                                                   invalidFeedbackText={LastNameRegex.helpText}
                                                   validation={LastNameRegex.expression}
                                                   doValidate={formInputValidate}
                                                   value={lastName}
                                                   setValue={setLastName}
                                            />
                                        </div>
                                        <div className="col-md-12 mb-1">
                                            <Input id={"email"}
                                                   type={"email"}
                                                   label={"Email"}
                                                   required={true}
                                                   invalidFeedbackText={EmailRegex.helpText}
                                                   validation={EmailRegex.expression}
                                                   doValidate={formInputValidate}
                                                   value={email}
                                                   setValue={setEmail}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <Input id={"phone"}
                                                   type={"tel"}
                                                   label={"Phone"}
                                                   required={true}
                                                   invalidFeedbackText={PhoneNumberRegex.helpText}
                                                   validation={PhoneNumberRegex.expression}
                                                   doValidate={formInputValidate}
                                                   value={phone}
                                                   setValue={setPhone}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <button type={"submit"} className={"btn btn-primary"} style={{marginTop: 20}}

                                            >Add User
                                            </button>
                                        </div>

                                    </div>
                                </form>
                            </div>

                            <div className="col-md-9">
                                <div className={""}>
                                    <Table headers={["First Name", "Last Name", "Email", "Phone", ""]}
                                           defaultPaginationSize={10}>
                                        {
                                            users.map((u, index) => (
                                                <tr key={u.email + "_" + index}>
                                                    <td>{u.firstName}</td>
                                                    <td>{u.lastName}</td>
                                                    <td>{u.email}</td>
                                                    <td>{u.phone}</td>
                                                    <td>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                             fill="currentColor" className="bi bi-x-circle"
                                                             viewBox="0 0 16 16"
                                                             onClick={() => {
                                                                 doDelete(u);
                                                             }}>
                                                            <path
                                                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                            <path
                                                                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                                        </svg>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </Table>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between gap-2 mt-3">
                            <button type={"button"} className={"btn btn-secondary"}
                                    onClick={() => {
                                        p.back();
                                    }}>
                                Back
                            </button>
                            <button type={"button"} className={"btn btn-primary"}
                                    onClick={() => {
                                        p.next(users);
                                    }}>
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    );
}

export default LeaseUsers;