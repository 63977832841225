import {
    Applicant,
    Application,
    ApplicationStatus,
    EmergencyContact,
    FileMetadata,
    Pet,
    TransactionAccount,
    Vehicle
} from "../types";
import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod, serializeFilter} from "../api";

interface GetApplicationsRequest {
    propertyId?: string,
    status?: string,
    applicationId?: string,
    createdAfter?: string
}

interface UpdateApplicationBody {
    unit?: {
        uuid: string
    },
    applicants?: Applicant[],
    pets?: Pet[],
    vehicles?: Vehicle[],
    emergencyContacts?: EmergencyContact[],
    status?: ApplicationStatus
}

interface SubmitApplicationBody {
    legalApproved: boolean
}

type AddApplicationFile = {
    uuid: string
}

type DocumentTransactionId = {
    transactionId: string
}

export type ApplicationInviteBody = {
    email?: string,
    note?: string
}

type getApplications = (query: GetApplicationsRequest) => Promise<Application[]>;
type getApplication = (portfolioId: string, applicationId: string) => Promise<Application>;
type createApplication = (portfolioId: string) => Promise<Application>;
type updateApplication = (portfolioId: string, applicationId: string, body: UpdateApplicationBody, journeyLeaseId?: string) => Promise<Application>;
type submitApplication = (portfolioId: string, applicationId: string, body: SubmitApplicationBody) => Promise<Application>;
type getTransactionAccountsByApplication = (applicationId: string) => Promise<TransactionAccount[]>;
type getApplicationFiles =  (applicationId: string) => Promise<FileMetadata[]>;
type addApplicationFile =  (applicationId: string, body: AddApplicationFile) => Promise<FileMetadata>;
type deleteApplicationFile =  (applicationId: string, id: string) => Promise<Response>;
type getDocumentTransactionId = (applicationId: string) => Promise<DocumentTransactionId>;
type sendApplicationInvite = (propertyId: string, body:ApplicationInviteBody ) => Promise<Response>;

export const getApplication: getApplication = (async (portfolioId, applicationId) => {
    const path = "/portfolios/" + getPortfolioId() + "/applications/" + applicationId;
    return fetchWithAuth(path, HttpMethod.GET);
});

export const createApplication: createApplication = async (portfolioId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/applications", HttpMethod.POST);
}

export const updateApplication: updateApplication = async (portfolioId, applicationId, body, journeyLeaseId) => {
    const path = "/portfolios/" + getPortfolioId() + "/applications/" + applicationId + (journeyLeaseId ? "?journeyLeaseId=" + journeyLeaseId : "")
    return fetchWithAuth(path, HttpMethod.PATCH, JSON.stringify(body));
}

export const submitApplication: submitApplication = async (portfolioId, applicationId, body) => {
    const path = "/portfolios/" + getPortfolioId() + "/applications/" + applicationId;
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
}

export const getApplications: getApplications = async (query) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/applications" + "?" + serializeFilter(query), HttpMethod.GET);
}

export const getTransactionAccountsByApplication: getTransactionAccountsByApplication = (async (applicationId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/applications/" + applicationId + "/transaction-accounts", HttpMethod.GET);
});

export const getApplicationFiles: getApplicationFiles = (async (applicationId) => {
    const path = "/portfolios/" + getPortfolioId() + "/applications/" + applicationId + "/files";
    return fetchWithAuth(path, HttpMethod.GET);
});

export const addApplicationFile: addApplicationFile = (async (applicationId, body) => {
    const path = "/portfolios/" + getPortfolioId() + "/applications/" + applicationId + "/files";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const deleteApplicationFile: deleteApplicationFile = (async (applicationId, id) => {
    const path = "/portfolios/" + getPortfolioId() + "/applications/" + applicationId + "/files/" + id;
    return fetchWithAuth(path, HttpMethod.DELETE);
});

export const getDocumentTransactionId: getDocumentTransactionId = (async (applicationId) => {
    const path = "/portfolios/" + getPortfolioId() + "/applications/" + applicationId + "/document-transactions";
    return fetchWithAuth(path, HttpMethod.POST);
});

export const sendApplicationInvite: sendApplicationInvite = (async (propertyId, body) => {
    const path = "/portfolios/" + getPortfolioId() + "/applications/invite?propertyId=" + propertyId;
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
})