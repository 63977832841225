import * as React from 'react';
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Table from "../../../Table";
import {
    DateRegex,
    DayOfMonthRegex,
    formInputValidate,
    LateFeeDto,
    LeaseUser,
    RecurringTransaction,
    Transaction,
    TransactionAccount
} from "../../../../types";
import moment from "moment";
import Card from "../../../Card";
import { LateFeeType } from '../../../../Pages/LateFeeConfig/Forms/LateFeeForm';
import { getLateFeeAccountName, renderPercentOrDollarAmount } from '../../../../helpers';

type ReviewProps = {
    startDate: string,
    endDate: string,
    dueDate: string,
    isMonthToMonth: boolean
    accounts: TransactionAccount[]
    initTransactions: Transaction[],
    recTransactions: RecurringTransaction[],
    recurringStartDate: string,
    leaseUsers: LeaseUser[],
    lateFeeDtoList?: LateFeeDto[],
    back: Function,
    next: Function
}

const Review: React.FunctionComponent<ReviewProps> = (p) => {

    const [leaseId, setLeaseId] = useState("");
    let history = useHistory();

    const [lateFeeExists, setLateFeeExists] = useState<boolean>(false);

    useEffect(() => {
        if (p.lateFeeDtoList && p.lateFeeDtoList.length !== 0) {
            setLateFeeExists(true)
        } else {
            setLateFeeExists(false)
        }
    }, [p.lateFeeDtoList])

    const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });



    const getAccountName = (accountId: string) => {
        if (accountId !== "") {
            let filtered = p.accounts.filter((a) => a.uuid === accountId);
            if (filtered) {
                return filtered[0].name;
            }
        }
        return "";
    }

    const getTotalRecurringTransactions = (tz: RecurringTransaction[]): number => {
        let total = 0;
        if (!tz) {
            return total;
        }
        tz.forEach(t => {
            let accountName = getAccountName(t.accountUuid);
            if (accountName.toLowerCase().indexOf("credit") >= 0) {
                total -= t.amount;
            } else {
                total += t.amount;
            }
        })
        return total;
    }

    const getTotalTransactions = (tz: Transaction[]): number => {
        let total = 0;
        if (!tz) {
            return total;
        }
        tz.forEach(t => {
            let accountName = getAccountName(t.accountId);
            if (accountName.toLowerCase().indexOf("credit") >= 0) {
                total -= parseFloat(t.amount);
            } else {
                total += parseFloat(t.amount);
            }
        })
        return total;
    }

    return (<>
        <div className="d-grid gap-4">
            <Card title="Terms">
                <div className={"row"}>
                    <div className={"col-lg-12 mb-3"}>
                        <div className={"row"}>
                            <div className={"col-md-6 mb-3"}>
                                <label htmlFor={"startDate"}>Start Date</label>
                                <input id={"startDate"}
                                    className={"form-control"}
                                    value={p.startDate}
                                    required={true}
                                    disabled={true}
                                    type={"date"}
                                />
                            </div>
                            <div className={"col-md-6 mb-3"}>
                                <label htmlFor={"dueDate"}>Due Date</label>
                                <input id={"dueDate"}
                                    className={"form-control"}
                                    value={p.dueDate}
                                    required={true}
                                    disabled={true}
                                    type={"number"}
                                />
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-md-6 pt-4 mb-3">
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox"
                                        id="defaultCheck1"
                                        checked={p.isMonthToMonth}
                                        disabled={true}
                                    />
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        Month to Month?
                                    </label>
                                </div>
                            </div>
                            <div className={"col-md-6 mb-3"}>
                                <input id={"endDate"}
                                    className={"form-control"}
                                    value={p.endDate}
                                    required={true}
                                    disabled={true}
                                    type={"date"}
                                />
                            </div>
                        </div>
                        <hr hidden={!lateFeeExists} />
                        <div className={"col-lg-12"} hidden={!lateFeeExists}>
                            <h5>Late Fee Configuration</h5>
                            <div>
                                <div className='row'>
                                    <Table headers={["Description", "Days after due date", "Type", "Frequency", "Amount / Percent", "Account", ""]} defaultPaginationSize={5}>
                                        {p.lateFeeDtoList && p.lateFeeDtoList.sort((a, b) => Number(a.gracePeriod) - Number(b.gracePeriod)).map((f, index) => (
                                            <tr key={index}>
                                                <td>{f.description}</td>
                                                <td>{f.gracePeriod}</td>
                                                <td>{f.feeType}</td>
                                                <td>{f.frequency}</td>
                                                <td>{renderPercentOrDollarAmount(f)}</td>
                                                <td>{getLateFeeAccountName(p.accounts, f.revenueAccountId)}</td>
                                                <td></td>
                                            </tr>
                                        ))}
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
            <Card title="Initial Transactions">
                <p><strong>{currencyFormat.format(getTotalTransactions(p.initTransactions))}</strong> due at start of lease</p>
                <Table headers={["Description", "Type", "Amount"]} defaultPaginationSize={10}>
                    {p.initTransactions.map((t, index) => (
                        <tr key={index + "_tran"}>
                            <td>
                                {t.description}
                            </td>
                            <td>
                                {getAccountName(t.accountId)}
                            </td>
                            <td>
                                {currencyFormat.format(parseFloat(t.amount))}
                            </td>

                        </tr>
                    ))}
                </Table>
            </Card>
            <Card title="Recurring Transactions">
                <Table headers={["Description", "Type", "Amount", "Start Date", "Stop Date", "Day of Month"]} defaultPaginationSize={10}>
                    {p.recTransactions.map((t, index) => (
                        <tr key={index + "_tran"}>
                            <td>
                                {t.description}
                            </td>
                            <td>
                                {getAccountName(t.accountUuid)}
                            </td>
                            <td>
                                {currencyFormat.format(t.amount)}
                            </td>
                            <td>
                                {moment(t.startDate).format("MMM Do, YYYY")}
                            </td>
                            <td>
                                {t.endDate ? moment(t.endDate).format("MMM Do, YYYY") : ""}
                            </td>
                            <td>
                                {t.dayOfMonth}
                            </td>
                        </tr>
                    ))}
                </Table>
            </Card>
            <Card title="User Accounts">
                <p>User accounts for tenants or co-signers on this lease. Each account will be able to login
                    at <a target={"_blank"}
                        href={"https://tenant.propertymq.com"}>tenant.propertymq.com</a> to
                    view the terms of the lease, make payments, open maintenance requests, etc.</p>
                <Table headers={["First Name", "Last Name", "Email", "Phone"]} defaultPaginationSize={10}>
                    {
                        p.leaseUsers.map((u, index) => (
                            <tr key={u.email + "_" + index}>
                                <td>{u.firstName}</td>
                                <td>{u.lastName}</td>
                                <td>{u.email}</td>
                                <td>{u.phone}</td>

                            </tr>
                        ))
                    }
                </Table>
            </Card>
        </div>
        <hr />
        <div className="d-flex justify-content-between gap-2 mt-3">
            <button type={"button"} className={"btn btn-secondary"}
                onClick={() => {
                    p.back();
                }}>
                Back
            </button>
            <button type={"button"} className={"btn btn-primary"}
                onClick={() => {
                    p.next();
                }}>
                Create
            </button>
        </div>
    </>);
};

export default Review;