import {FloorPlan, FloorPlanService, Term} from "../types";
import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod} from "../api";

interface AddUpdateFloorPlanBody {
    name: string,
    description: string,
    bed: string,
    bath: string,
    sqft: string,
    type: string,
    imageUrl: string,
    deposit: string,
    occupancy: string
}

interface AddFloorPlanTermsBody {
    length: string,
    units: string,
    price: string
}

interface AddFloorPlanServicesBody {
    units: string,
    price: string,
    name: string
    description: string,
    optional: boolean
}

type getFloorPlans = (propertyUuid: string) => Promise<FloorPlan[]>;
type getFloorPlan = (propertyUuid: string, floorPlanUuid: string) => Promise<FloorPlan>;
type addFloorPlan = (propertyUuid: string, floorPlanUuid: string, body: AddUpdateFloorPlanBody) => Promise<FloorPlan>;
type updateFloorPlan = (propertyUuid: string, floorPlanUuid: string, body: AddUpdateFloorPlanBody) => Promise<FloorPlan>;
type addFloorPlanTerms = (propertyUuid: string, floorPlanUuid: string, body: AddFloorPlanTermsBody) => Promise<Term>;
type getFloorPlanTerms = (propertyUuid: string, floorPlanUuid: string) => Promise<Term[]>;
type deleteFloorPlanTerms = (propertyUuid: string, floorPlanUuid: string, termUuid: string) => Promise<Response>;
type addFloorPlanServices = (propertyUuid: string, floorPlanUuid: string, body: AddFloorPlanServicesBody) => Promise<FloorPlanService>;
type getFloorPlanServices = (propertyUuid: string, floorPlanUuid: string) => Promise<FloorPlanService[]>;
type deleteFloorPlanService = (propertyUuid: string, floorPlanUuid: string, serviceUuid: string) => Promise<Response>;

export const getFloorPlans: getFloorPlans = async (propertyUuid) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/floorplans", HttpMethod.GET);
};

export const getFloorPlan: getFloorPlan = async (propertyUuid, floorPlanUuid) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/floorplans/" + floorPlanUuid, HttpMethod.GET);
};

export const addFloorPlan: addFloorPlan = async (propertyUuid, floorPlanUuid, body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/floorplans", HttpMethod.POST, JSON.stringify(body));
};

export const updateFloorPlan: updateFloorPlan = async (propertyUuid, floorPlanUuid, body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/floorplans/" + floorPlanUuid, HttpMethod.PUT, JSON.stringify(body));
};

export const getFloorPlanTerms: getFloorPlanTerms = async (propertyUuid, floorPlanUuid) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/floorplans/" + floorPlanUuid + "/terms", HttpMethod.GET);
};

export const addFloorPlanTerms: addFloorPlanTerms = async (propertyUuid, floorPlanUuid, body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/floorplans/" + floorPlanUuid + "/terms", HttpMethod.POST, JSON.stringify(body));
};

export const deleteFloorPlanTerms: deleteFloorPlanTerms = async (propertyUuid, floorPlanUuid, termUuid) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/floorplans/" + floorPlanUuid + "/terms/" + termUuid, HttpMethod.DELETE);
};

export const addFloorPlanServices: addFloorPlanServices = async (propertyUuid, floorPlanUuid, body) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/floor-plans/" + floorPlanUuid + "/additional-services", HttpMethod.POST, JSON.stringify(body));
};

export const getFloorPlanServices: getFloorPlanServices = async (propertyUuid, floorPlanUuid) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/floor-plans/" + floorPlanUuid + "/additional-services", HttpMethod.GET);
};

export const deleteFloorPlanService: deleteFloorPlanService = async (propertyUuid, floorPlanUuid, serviceUuid) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/properties/" + propertyUuid + "/floor-plans/" + floorPlanUuid + "/additional-services/" + serviceUuid, HttpMethod.DELETE);
};
