import React, {FormEvent, useEffect, useState} from "react";
import FormFieldBuilder, {assignFormFieldValues} from "../../../Components/Form/FieldBuilder/FormFieldBuilder";
import JourneyLeaseForm from "../Forms/JourneyLeaseForm";
import {createJourneyLease} from "../../../Api/JourneyApi";
import {useHistory, useParams} from "react-router";
import Alert, {AlertType} from "../../../Components/Alert";
import Card from "../../../Components/Card";

const AddJourneyLease: React.FunctionComponent = () => {

    const { propertyId } = useParams()
    const history = useHistory();

    const [error, setError] = useState<Error>();

    const [isValid, setIsValid] = useState<boolean>(false)
    const [firstName, setFirstName] = useState<string>("")
    const [lastName, setLastName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [phone, setPhone] = useState<string>("")
    const [notes, setNotes] = useState<string>("")
    const [followUpDate, setFollowUpDate] = useState<string>("")

    assignFormFieldValues({field: JourneyLeaseForm.firstName, value: firstName, setValue: setFirstName, disabledOverride: false})
    assignFormFieldValues({field: JourneyLeaseForm.lastName, value: lastName, setValue: setLastName, disabledOverride: false})
    assignFormFieldValues({field: JourneyLeaseForm.email, value: email, setValue: setEmail, disabledOverride: false})
    assignFormFieldValues({field: JourneyLeaseForm.phone, value: phone, setValue: setPhone, disabledOverride: false})
    assignFormFieldValues({field: JourneyLeaseForm.notes, value: notes, setValue: setNotes, disabledOverride: false})
    assignFormFieldValues({field: JourneyLeaseForm.followUpDate, value: followUpDate, setValue: setFollowUpDate, disabledOverride: false})

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (isValid) {
            createJourneyLease({
                propertyId,
                journeyUser: {
                    firstName,
                    lastName,
                    email,
                    phone
                },
                notes,
                followUpDate
            })
                .then(() => history.push({pathname: "/p/m/customers/prospective-tenants/" + propertyId}))
                .catch(setError)
        }
    }

    useEffect(() => {
        const isFirstNameValid = firstName !== "";
        const isLastNameValid = lastName !== "";
        const isEmailValid = email !== "";
        const isPhoneValid = phone !== "";
        setIsValid(isFirstNameValid && isLastNameValid && (isEmailValid || isPhoneValid))
    }, [firstName, lastName, email, phone])

    return <>
        <div className="row p-3">
            <div className="col-md-4">
                <Card title="Add Prospective Tenant">
                    {error && <Alert type={AlertType.DANGER} message={error.message} />}
                    <form onSubmit={onSubmit}>
                        <div className="d-grid gap-2">
                            <div>
                                <FormFieldBuilder field={JourneyLeaseForm.firstName} />
                            </div>
                            <div>
                                <FormFieldBuilder field={JourneyLeaseForm.lastName} />
                            </div>
                            <div>
                                <FormFieldBuilder field={JourneyLeaseForm.email} />
                            </div>
                            <div>
                                <FormFieldBuilder field={JourneyLeaseForm.phone} />
                            </div>
                            <div>
                                <FormFieldBuilder field={JourneyLeaseForm.notes} />
                            </div>
                            <div>
                                <FormFieldBuilder field={JourneyLeaseForm.followUpDate} />
                            </div>
                            <button type="submit" className="btn btn-primary mt-4" disabled={!isValid}>Add</button>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    </>
}

export default AddJourneyLease;