import React from "react";
import {getAppUserFullName, getTicketIdFromUuid} from "../../../helpers";
import {JourneyLease, JourneyStep} from "../../../Api/JourneyApi";
import moment from "moment";
import {DashCircleFillIcon, XCircleFill} from "../../../Components/Icons/Icons";

export const Item: React.FunctionComponent<{label: string, content: string}> = ({label, content}) => {
    return <>
        <label className="col-form-label-sm">{label}</label>
        <h6>{content}</h6>
    </>
}

const FollowUpBadge: React.FunctionComponent<{journeyLease: JourneyLease}> = ({journeyLease}) => {
    if (journeyLease.closed) {
        return <h6><span className="badge rounded-pill bg-secondary">Closed</span></h6>
    }
    else if (journeyLease.followUpDate) {
        const now = moment().startOf('day')
        const convertedDate = moment(journeyLease.followUpDate)
        if (convertedDate.isBefore(now)) {
            return <h6><span className="badge rounded-pill bg-danger">Past Due</span></h6>
        }
        else if (convertedDate.isSame(now)) {
            return <h6><span className="badge rounded-pill bg-success">Today</span></h6>
        }
        else if (convertedDate.isAfter(now)) {
            if (convertedDate.dayOfYear() - now.dayOfYear() <= 1) {
                return <h6><span className="badge rounded-pill bg-info">Tomorrow</span></h6>
            }
            else if (convertedDate.dayOfYear() - now.dayOfYear() === 2) {
                return <h6><span className="badge rounded-pill bg-info">Day After Tomorrow</span></h6>
            }
            return <h6><span className="badge rounded-pill bg-light text-black">{convertedDate.fromNow()}</span></h6>
        }
    }
    return <><DashCircleFillIcon color="var(--secondary)" /></>
}


const JourneyLeaseItem: React.FunctionComponent<{journeyLease: JourneyLease, step: JourneyStep, width: number, onClick: Function}> = ({journeyLease, step, width, onClick}) => {
    return <>
        <div className="row mb-2">
            <div className="col-1">
                <div className="d-flex justify-content-center">
                    <div className="mt-1">
                        <FollowUpBadge journeyLease={journeyLease} />
                    </div>
                </div>
            </div>
            <div className="col-11">
                <div className="progress position-relative shadow-sm" style={{height: 30}}>
                    <div className={"progress-bar bg-" + step.bgColor + (journeyLease.closed ? " bg-opacity-50" : "")} role="progressbar" style={{width: width + "%"}} aria-valuenow={width}
                         aria-valuemin={0} aria-valuemax={100}>
                        <h6 className="my-auto">{
                            !journeyLease.journeyUser.firstName || !journeyLease.journeyUser.lastName ?
                                "Prospective Tenant" :
                                getAppUserFullName(journeyLease.journeyUser)
                        }</h6>
                    </div>
                    <button
                        className="btn btn-block position-absolute h-100 w-100 start-0"
                        type="button"
                        role="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapse_" + getTicketIdFromUuid(journeyLease.uuid)}
                    >
                    </button>
                </div>
                <div className="collapse" id={"collapse_" + getTicketIdFromUuid(journeyLease.uuid)}>
                    <div className="row ms-1 mt-3"
                         style={{
                             borderWidth: "0 0 2px 2px",
                             borderStyle: "solid",
                             borderColor: "var(--border-color)",
                             padding: "0 0 .75rem 0",
                             boxShadow: "-0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.075)"
                         }}
                    >
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-6">
                                    <Item label="Id" content={getTicketIdFromUuid(journeyLease.uuid)} />
                                    <Item label="Current Step" content={step.label} />
                                </div>
                                <div className="col-md-6">
                                    <Item label="Quote Id" content={journeyLease.quoteId? getTicketIdFromUuid(journeyLease.quoteId) : '-'} />
                                    <Item label="Tenant Application Id" content={journeyLease.tenantApplicationId ? getTicketIdFromUuid(journeyLease.tenantApplicationId) : '-'} />
                                </div>
                            </div>
                            <hr className="bg-secondary"/>
                            <div className="row">
                                <div className="col-md-6">
                                    <Item label="First Name" content={journeyLease.journeyUser?.firstName || '-'} />
                                    <Item label="Last Name" content={journeyLease.journeyUser?.lastName || '-'} />
                                </div>
                                <div className="col-md-6">
                                    <Item label="Email" content={journeyLease.journeyUser?.email || '-'} />
                                    <Item label="Phone" content={journeyLease.journeyUser?.phone || '-'} />
                                </div>
                                <div className="col-md-12">
                                    {step.actionItem && <button type="button" className="btn btn-primary mt-4" onClick={() => onClick()}>View</button>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 position-relative">
                            <div className="h-100 border-start ps-4">
                                <Item label="Follow Up" content={journeyLease.followUpDate && moment(journeyLease.followUpDate).format('MMMM Do, YYYY') || '-'} />
                                <Item label="Notes" content={journeyLease.notes} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default JourneyLeaseItem;