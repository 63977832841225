import * as React from "react";
import {FormEvent, Fragment, useEffect, useState} from "react";
import PageLayout from "../../../Layouts/PageLayout";
import AutocompleteInput, {DataAttribute} from "../../../Components/Form/AutocompleteInput";
import {
    AddressLine1Regex, AddressLine2Regex,
    Bill, CityRegex,
    DateRegex,
    DollarAmountRegex,
    formInputValidate,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex,
    GetBankAccount,
    ManagementFee, PhoneNumberRegex,
    PortfolioVendor,
    Vendor, ZipRegex
} from "../../../types";
import Input from "../../../Components/Form/Input";
import moment from "moment/moment";
import {
    addBillItem,
    addBills,
    addManagementFees, addPortfolioVendors, createBillCheck, createOwnerPayoutCheck, deleteBillItem,
    getBills,
    getManagementFees,
    getPortfolioVendor,
    getPortfolioVendors, getTenants,
    getTrustAccounts
} from "../../../Api/PortfolioApi";
import {getPortfolioId} from "../../../helpers";
import Checkbox from "../../../Components/Form/Checkbox/Checkbox";
import {addVendor, getPropertiesByPortfolioId} from "../../../Api/PropertyApi";
import Select from "../../../Components/Form/Select";
import StateSelect from "../../../Components/StateSelect";
import {openFile} from "../../../Api/FileApi";


const ViewPayables: React.FunctionComponent = () => {
    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    const [view, setView] = useState("bills");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [propertyList, setPropertyList] = useState<DataAttribute[]>([]);
    const [selectedProperty, setSelectedProperty] = useState("");
    const [managementFees, setManagementFees] = useState<ManagementFee[]>([]);
    const [portfolioVendor, setPortfolioVendor] = useState<PortfolioVendor>()
    const [startDate, setStartDate] = useState(() => {
        let today = new Date();
        let daysAgo = new Date(today.setDate(today.getDate() - 30));
        return moment(daysAgo).format("YYYY-MM-DD");
    })
    const [endDate, setEndDate] = useState(() => {
        let today = new Date();
        // let daysAgo = new Date(today.setDate(today.getDate() - 30));
        return moment(today).format("YYYY-MM-DD");
    })
    const [reviewManagementFees, setReviewManagementFees] = useState(false);
    const [managementFeePaymentMethod, setManagementFeePaymentMethod] = useState("manual");
    const [loadingBills, setLoadingBills] = useState(false);
    const [bills, setBills] = useState<Bill[]>([]);
    const [billDate, setBillDate] = useState("");
    const [selectedVendorId, setSelectedVendorId] = useState("");
    const [selectedTrustAccountId, setSelectedTrustAccountId] = useState("");
    const [trustAccounts, setTrustAccounts] = useState<GetBankAccount[]>([]);
    const [selectedContext, setSelectedContext] = useState("");
    const [expenseAmount, setExpenseAmount] = useState("");
    const [expenseDescription, setExpenseDescription] = useState("");
    const [expenseAccount, setExpenseAccount] = useState("");
    const [cashAccount, setCashAccount] = useState("")
    const [expenseDate, setExpenseDate] = useState("");
    const [expenseReference, setExpenseReference] = useState("");
    const [doAddVendor, setDoAddVendor] = useState(false);
    const [vendorName, setVendorName] = useState("");
    const [vendorPhone, setVendorPhone] = useState("");
    const [vendorAddressLine1, setVendorAddressLine1] = useState("");
    const [vendorAddressLine2, setVendorAddressLine2] = useState("");
    const [vendorCity, setVendorCity] = useState("");
    const [vendorState, setVendorState] = useState("");
    const [vendorZip, setVendorZip] = useState("");
    const [checkNumber, setCheckNumber] = useState("");
    const [remitTo, setRemitTo] = useState("");
    const [tenantList, setTenantList] = useState<DataAttribute[]>([]);
    const [selectedTenant, setSelectedTenant] = useState("");
    const [vendorList, setVendorList] = useState<DataAttribute[]>([]);
    const [selectedDeleteBill, setSelectedDeleteBill] = useState("");

    function loadBills() {
        setLoadingBills(true);
        getBills(getPortfolioId())
            .then((b) => {
                setBills(b)
            })
            .catch(e => setError(e.message))
            .finally(() => setLoadingBills(false));
    }

    function loadVendors() {
        getPortfolioVendor(getPortfolioId())
            .then(pv => {
                setPortfolioVendor(pv);
                setManagementFeePaymentMethod("ach")
            })
            .catch(e => {
            })
            .finally(() => {
            });

        getPortfolioVendors(getPortfolioId())
            .then((r) => {
                let vendors: DataAttribute[] = [];
                r.forEach(t => {
                    let label = t.name;
                    vendors.push({
                        uuid: t.uuid,
                        label: label
                    })
                })
                setVendorList(vendors)
            })
            .catch(e => console.log(e.message))
            .finally(() => {
            });

        getTenants(getPortfolioId())
            .then((r) => {
                let tenants: DataAttribute[] = [];
                r.forEach(t => {
                    let label = t.lastName + ", " + t.firstName + " | " + t.property.unitName + " | " + t.property.name;
                    tenants.push({
                        uuid: t.uuid,
                        label: label
                    })
                })
                setTenantList(tenants)
            })
            .catch(e => setError(e.message))
            .finally(() => {});
    }

    useEffect(() => {
        setLoading(true);
        getPropertiesByPortfolioId(getPortfolioId())
            .then((pz) => {
                let mz: DataAttribute[] = [];
                pz.forEach((p) => {
                    mz.push({
                        uuid: p.uuid,
                        label: p.name
                    })
                })
                setPropertyList(mz);
            })
            .catch((e) => setError(e.message))
            .finally(() => setLoading(false));

        loadVendors();

        loadBills();

        getTrustAccounts(getPortfolioId())
            .then(r => {
                setTrustAccounts(r);
            })
            .catch(e => {
                console.error(e.message);
            })
            .finally(() => {
            });


    }, []);

    function getAccountName(code: string) {
        if (code === "1000") {
            return "Operating Cash"
        }
        if (code === "1001") {
            return "Security Deposit Cash"
        }
    }


    function clear() {

        setError("");
        setSelectedProperty("");
        setManagementFees([]);
        let daysAgo = new Date(new Date().setDate(new Date().getDate() - 30));
        setStartDate(moment(daysAgo).format("YYYY-MM-DD"));
        setEndDate(moment(new Date()).format("YYYY-MM-DD"))
        setSelectedVendorId("");
        setSelectedProperty("");
        setSelectedTrustAccountId("");
        setBillDate("");
        setExpenseAccount("");
        setExpenseAmount("");
        setExpenseDate("");
        setExpenseReference("");
        setExpenseDescription("");
        setCheckNumber("");
        setSelectedTenant("");
        setRemitTo("");
    }

    function doSearch() {
        setError("");
        setSuccessMessage("");
        setLoading(true);
        getManagementFees(getPortfolioId(), startDate, endDate, selectedProperty)
            .then(mz => {
                mz.forEach(i => {
                    i.include = true;
                })
                setManagementFees(mz);
            })
            .catch((e) => {
                setError(e.message);
            })
            .finally(() => setLoading(false));
    }

    function getTotalFees() {
        let total = 0;
        if (managementFees) {
            managementFees.forEach(m => {
                if (m.include) {
                    total += m.feeAmount;
                }
            })
        }
        return total;
    }

    function doSubmitFees() {
        if (managementFees) {
            setSuccessMessage("");
            setLoading(true);
            let body = managementFees.filter(m => m.include);
            addManagementFees(getPortfolioId(), {
                paymentMethod: managementFeePaymentMethod,
                managementFees: body
            })
                .then(r => {
                    setReviewManagementFees(false);
                    setSuccessMessage("Successfully charged management fees!");
                    loadBills();
                    clear();
                })
                .catch(e => setError(e.message))
                .finally(() => setLoading(false));
        }
    }

    function getReviewManagementFeesView() {
        return <>
            {loading && <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>}
            {error && <div className="alert alert-danger" role="alert">
                {error}
            </div>}
            <div className={"row"}>
                <div className={"col"}>
                    <h5>Review Management Fee Charges</h5>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <button type={"button"} className={"btn btn-danger"} onClick={() => {
                        setReviewManagementFees(false);
                    }}>Cancel
                    </button>
                    <button type={"button"} className={"btn btn-primary"}
                            onClick={() => {
                                doSubmitFees();
                            }}>Submit
                    </button>
                </div>
            </div>
            <div className={"row mt-3"}>
                <div className={"col"}>

                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="flexRadioDefault"
                               id="flexRadioDefault1"
                               checked={managementFeePaymentMethod === "ach"}
                               onChange={(e) => {
                                   if (e.currentTarget.checked) {
                                       setManagementFeePaymentMethod("ach")
                                   }
                               }}
                               disabled={!portfolioVendor}/>
                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                            ACH
                            payment {portfolioVendor && <>to {portfolioVendor?.bankName} - {portfolioVendor?.bankLastFour}</>}
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="radio" name="flexRadioDefault"
                               id="flexRadioDefault2"
                               checked={managementFeePaymentMethod === "manual"}
                               onChange={(e) => {
                                   if (e.currentTarget.checked) {
                                       setManagementFeePaymentMethod("manual")
                                   }
                               }}/>
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                            Manual Payment
                        </label>
                    </div>
                </div>
            </div>

            <div className={"row"}>
                <div className={"col"}>

                    <table className={"table table-striped"}>
                        <thead>
                        <tr>
                            <th>Property</th>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Transaction Amount</th>
                            <th>Rate</th>
                            <th>Fee Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            managementFees.filter(m => m.include).map(m => (
                                <tr key={m.uuid}>
                                    <td>{m.propertyName + " - " + m.unitName}</td>
                                    <td>{m.date}</td>
                                    <td>{m.description}</td>
                                    <td>{currencyFormat.format(m.amount)}</td>
                                    <td>{m.rate * 100}%</td>
                                    <td>{m.include ? currencyFormat.format(m.feeAmount) : currencyFormat.format(0)}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                        <tfoot>
                        <tr>
                            <th colSpan={5} className={"text-end"}>Total</th>
                            <th>{currencyFormat.format(getTotalFees())}</th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    }

    function getManagementFeesView() {
        if (reviewManagementFees) {
            return getReviewManagementFeesView();
        }
        return <>
            {loading && <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>}
            {error && <div className="alert alert-danger" role="alert">
                {error}
            </div>}
            {successMessage && <div className="alert alert-success" role="alert">
                {successMessage}
            </div>}
            <div className={"row"}>
                <div className={"col"}>
                    <h5>Charge Management Fees</h5>
                </div>
            </div>
            <form>
                <div className={"row"}>
                    <div className={"col-md-4 mb-3"}>
                        <AutocompleteInput id={"tenant"} validation={GenericInputRegex.expression}
                                           data={propertyList}
                                           doValidate={formInputValidate}
                                           value={selectedProperty}
                                           setValue={setSelectedProperty}
                                           placeholder={"Search by property name"}
                                           required={false}
                                           invalidFeedbackText={GenericInputRegex.helpText}
                                           type={"text"} label={"Property"}
                        />
                        <p>Search by a specific property or leave blank for all.</p>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-4 mb-3"}>
                        <div className={"col-md-12 mb-3"}>
                            <Input id={"date"} validation={DateRegex.expression}
                                   doValidate={formInputValidate}
                                   value={startDate}
                                   setValue={setStartDate}
                                   required={true}
                                   invalidFeedbackText={DateRegex.helpText}
                                   type={"date"} label={"Start Date"}
                            />
                        </div>
                    </div>

                </div>
                <div className={"row"}>
                    <div className={"col-md-4 mb-3"}>
                        <div className={"col-md-12 mb-3"}>
                            <Input id={"date"} validation={DateRegex.expression}
                                   doValidate={formInputValidate}
                                   value={endDate}
                                   setValue={setEndDate}
                                   required={true}
                                   invalidFeedbackText={DateRegex.helpText}
                                   type={"date"} label={"End Date"}
                            />
                        </div>
                    </div>

                </div>
                <div className={"row"}>
                    <div className={"col"}>
                        <button type={"submit"} className={"btn btn-primary"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    doSearch();
                                }}>Search
                        </button>
                        <button type={"button"} onClick={() => clear()} className={"btn btn-danger ms-3"}>Cancel
                        </button>
                    </div>
                </div>
            </form>
            {managementFees && managementFees.length > 0 && <>
                <div className={"row mt-3"}>
                    <hr/>
                    <div className={"col"}>
                        <button type={"button"} className={"btn btn-primary"}
                                onClick={(e) => {
                                    setReviewManagementFees(true);
                                }}
                        >Review Charges
                        </button>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col"}>

                        <table className={"table table-striped"}>
                            <thead>
                            <tr>
                                <th>

                                </th>
                                <th>Property</th>
                                <th>Date</th>
                                <th>Paid Date</th>
                                <th>Description</th>
                                <th>Transaction Amount</th>
                                <th>Rate</th>
                                <th>Fee Amount</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                managementFees.map((m, m_index) => (
                                    <Fragment key={m.uuid}>
                                        <tr>
                                            <td>
                                                <Checkbox
                                                    id={"checkbox"}
                                                    validateClass={''}
                                                    value={m.include}
                                                    setValue={(v: boolean) => {
                                                        m.include = v;
                                                        setManagementFees([...managementFees])
                                                    }}
                                                    required={false}
                                                    invalidFeedbackText={GenericInputRegex.helpText}
                                                    label={""}
                                                    disabled={false}
                                                />
                                            </td>
                                            <td>
                                                {m.propertyName + " - "}
                                                <a href={"/p/m/customers/leases/" + getPortfolioId() + "/view/" + m.leaseId}
                                                   target={"_blank"} rel="noopener noreferrer">{m.unitName}</a>
                                            </td>
                                            <td>{m.date}

                                            </td>

                                            <td onClick={() => {
                                                setSelectedContext(m.uuid);
                                            }}>{m.paidDate}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor"
                                                     className="bi bi-search ms-1" viewBox="0 0 16 16"
                                                >
                                                    <path
                                                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                                                </svg>
                                            </td>
                                            <td>{m.description}</td>
                                            <td>{currencyFormat.format(m.amount)}

                                            </td>
                                            <td><a href={"/p/m/realestate/properties/edit/" + m.propertyId}
                                                   target={"_blank"} rel="noopener noreferrer">{m.rate * 100}%</a></td>
                                            <td>{m.include ? currencyFormat.format(m.feeAmount) : currencyFormat.format(0)}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     fill="currentColor" className="ms-3 bi bi-x-circle"
                                                     viewBox="0 0 16 16"
                                                     onClick={() => {
                                                         m.feeAmount = 0;
                                                         setManagementFees([...managementFees])
                                                     }}>
                                                    <path
                                                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                                    <path
                                                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                                                </svg>
                                            </td>
                                        </tr>
                                        <tr className={(m.uuid === selectedContext) ? "" : "d-none"}>
                                            <td></td>
                                            <td colSpan={5}>
                                                <em><h5>Related transactions:</h5></em>
                                                <table className={"table"}>
                                                    <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Description</th>
                                                        <th>Amount</th>
                                                        <th>Balance</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {m.contexts.map((c, c_index) => (
                                                        <tr className={(c.source) ? "table-success" : ""}
                                                            key={c_index + m_index}>
                                                            <td>{c.date}</td>
                                                            <td>{c.description}</td>
                                                            <td>{currencyFormat.format(c.amount)}</td>
                                                            <td>{currencyFormat.format(c.balance)}</td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                            <td></td>
                                        </tr>
                                    </Fragment>
                                ))
                            }
                            </tbody>
                            <tfoot>
                            <tr>
                                <th colSpan={6} className={"text-end"}>Total</th>
                                <th>{currencyFormat.format(getTotalFees())}</th>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </>}
        </>;
    }



    function doSubmitBill(e: FormEvent) {
        e.preventDefault();
        setError("");
        setSuccessMessage("");
        setLoadingBills(true);
        addBills(getPortfolioId(), {
            items: bills.filter(b => b.include).map(b => b.uuid),
            vendorId: selectedVendorId,
            tenantId: selectedTenant,
            bankAccountId: selectedTrustAccountId,
            date: billDate,
            checkNumber: checkNumber
        })
            .then((r) => {
                setSuccessMessage("Successfully added bill!");
                setView("bills");
                loadBills();
                clear();
            })
            .catch(e => setError(e.message))
            .finally(() => {
                setLoadingBills(false);
            });
    }

    function clearVendor() {
        setVendorName("");
        setVendorPhone("");
        setVendorAddressLine1("");
        setVendorAddressLine2("");
        setVendorCity("");
        setVendorState("");
        setVendorZip("");
    }

    function handleVendor() {
        setLoading(true);

        addPortfolioVendors(getPortfolioId(), {
            name: vendorName,
            phone: vendorPhone,
            addressLine1: vendorAddressLine1,
            addressLine2: vendorAddressLine2,
            city: vendorCity,
            stateCode: vendorState,
            zip: vendorZip
        })
            .then((v) => {
                clearVendor();
                setDoAddVendor(false);
                setSelectedVendorId(v.uuid);
                loadVendors();
            })
            .catch((e) => setError(e.message))
            .finally(() => setLoading(false));
    }

    function getRemitToView() {
        if (remitTo === "vendor") {
            return getVendor();
        } else if (remitTo === "tenant") {
            return getTenant();
        }
        return <div className={"col-md-12 mb-2"}>
            <button type={"button"} className={"btn btn-primary me-3"} onClick={(e) => {
                e.preventDefault();
                setRemitTo("vendor")
            }}>Select Vendor
            </button>
            <button type={"button"} className={"btn btn-primary"} onClick={(e) => {
                e.preventDefault();
                setRemitTo("tenant")
            }}>Select Tenant
            </button>
        </div>
    }

    function getTenant() {
        return <>
            <div className={"col-md-7 mb-3"}>
                {tenantList && <AutocompleteInput id={"tenant"} validation={GenericInputRegex.expression}
                                   data={tenantList}
                                   doValidate={formInputValidate}
                                   value={selectedTenant}
                                   setValue={setSelectedTenant}
                                   placeholder={"Search by tenant, unit, or property name"}
                                   required={true}
                                   invalidFeedbackText={GenericInputRegex.helpText}
                                   type={"text"} label={"Tenant"}
                />}
            </div>
            <div className={"col-md-5 mb-3"} style={{paddingTop: 20}}>
                <button type={"button"} className={"btn btn-danger"} onClick={(e) => {
                    e.preventDefault();
                    setRemitTo("");
                    setSelectedTenant("");
                }}>Cancel
                </button>
            </div>
        </>
    }

    function getDeleteSpinner(id: string) {
        if (id === selectedDeleteBill) {
            return <>
                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                <span className="visually-hidden" role="status">Loading...</span>
            </>
        }
        return <></>;
    }

    function getVendor() {
        if (doAddVendor) {
            return <>
                <div className={"col-md-3 mb-3"}>
                    <Input id={"name"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={vendorName}
                           setValue={setVendorName}
                           required={true}
                           invalidFeedbackText={GenericSelectRegex.helpText}
                           type={"text"} label={"Name"}
                    />
                </div>
                <div className={"col-md-3 mb-2"}>
                    <Input id={"phone"} validation={PhoneNumberRegex.expression}
                           doValidate={formInputValidate}
                           value={vendorPhone}
                           setValue={setVendorPhone}
                           required={false}
                           invalidFeedbackText={PhoneNumberRegex.helpText}
                           type={"text"} label={"Phone"}
                    />
                </div>
                <div className={"col-md-6 mb-2"}>
                </div>
                <div className={"col-md-6 mb-2"}>
                    <Input id={"businessaddressLine1"} validation={AddressLine1Regex.expression}
                           doValidate={formInputValidate}
                           value={vendorAddressLine1}
                           setValue={setVendorAddressLine1}
                           required={true}
                           invalidFeedbackText={AddressLine1Regex.helpText}
                           type={"text"} label={"Address"}
                    />
                </div>
                <div className={"col-md-6 mb-2"}>
                    <Input id={"businessaddressLine2"} validation={AddressLine2Regex.expression}
                           doValidate={formInputValidate}
                           value={vendorAddressLine2}
                           setValue={setVendorAddressLine2}
                           required={false}
                           invalidFeedbackText={AddressLine2Regex.helpText}
                           type={"text"} label={"Address 2"}
                    />
                </div>
                <div className={"col-md-4 mb-2"}>
                    <Input id={"businesscity"} validation={CityRegex.expression}
                           doValidate={formInputValidate}
                           value={vendorCity}
                           setValue={setVendorCity}
                           required={true}
                           invalidFeedbackText={CityRegex.helpText}
                           type={"text"} label={"City"}
                    />
                </div>
                <div className="col-md-3 mb-2">
                    <StateSelect id={"businessstate"} validation={GenericSelectRegex.expression}
                                 noSelectionText={"Select a state..."}
                                 doValidate={formSelectValidate}
                                 value={vendorState} setValue={setVendorState}
                                 required={true}
                                 invalidFeedbackText={GenericSelectRegex.helpText}
                                 label={"State"}/>
                </div>
                <div className={"col-md-3 mb-2"}>
                    <Input id={"businesszip"} validation={ZipRegex.expression}
                           doValidate={formInputValidate}
                           value={vendorZip}
                           setValue={setVendorZip}
                           required={true}
                           invalidFeedbackText={ZipRegex.helpText}
                           type={"number"} label={"Zip"}
                    />
                </div>
                <div className={"col-md-12 mb-2"}>
                    <button type={"button"} className={"btn btn-primary me-3"} onClick={(e) => {
                        e.preventDefault();
                        handleVendor();
                    }}>Save Vendor
                    </button>
                    <button type={"button"} className={"btn btn-danger"} onClick={(e) => {
                        e.preventDefault();
                        setRemitTo("");
                        setSelectedVendorId("");
                    }}>Cancel
                    </button>
                </div>
            </>
        } else {
            return <>
                <div className={"col-md-7 mb-3"}>
                    {vendorList && <AutocompleteInput id={"vendors"} validation={GenericInputRegex.expression}
                                                      data={vendorList}
                                                      doValidate={formInputValidate}
                                                      value={selectedVendorId}
                                                      setValue={setSelectedVendorId}
                                                      placeholder={"Search by vendor name"}
                                                      required={true}
                                                      invalidFeedbackText={GenericInputRegex.helpText}
                                                      type={"text"} label={"Vendor"}
                    />}
                </div>
                <div className={"col-md-5 mb-3"} style={{paddingTop: 20}}>
                    <button type={"button"} className={"btn btn-primary me-3"} onClick={(e) => {
                        e.preventDefault();
                        setDoAddVendor(true);
                    }}>Add New Vendor
                    </button>

                    <button type={"button"} className={"btn btn-danger"} onClick={(e) => {
                        e.preventDefault();
                        setRemitTo("");
                        setSelectedVendorId("");
                    }}>Cancel
                    </button>
                </div>
            </>
        }
    }

    function reviewPayBill() {
        return <>
            {loadingBills && <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>}
            {error && <div className="alert alert-danger" role="alert">
                {error}
            </div>}
            {successMessage && <div className="alert alert-success" role="alert">
                {successMessage}
            </div>}
            <div className={"row"}>
                <div className={"col"}>
                    <h5>Review Payment</h5>
                </div>
            </div>
            <form onSubmit={doSubmitBill}>
                <div className={"row"}>
                    <div className={"col-md-6 mb-3"}>
                        <div className={"row"}>
                            <div className={"col-md-12 mb-3"}>
                                <Input id={"date"} validation={DateRegex.expression}
                                       doValidate={formInputValidate}
                                       value={billDate}
                                       setValue={setBillDate}
                                       required={true}
                                       invalidFeedbackText={DateRegex.helpText}
                                       type={"date"} label={"Date"}
                                />
                            </div>
                            <div className={"col-md-12 mb-3"}>
                                <Input id={"checkNumber"} validation={GenericInputRegex.expression}
                                       doValidate={formInputValidate}
                                       value={checkNumber}
                                       setValue={setCheckNumber}
                                       required={false}
                                       invalidFeedbackText={GenericInputRegex.helpText}
                                       type={"text"} label={"Check Number"}
                                />
                            </div>
                            <div className={"col-md-12 mb-3"}>
                                <Select id={"bankAccount"} validation={GenericSelectRegex.expression}
                                        doValidate={formSelectValidate}
                                        value={selectedTrustAccountId} setValue={setSelectedTrustAccountId}
                                        required={true}
                                        invalidFeedbackText={GenericSelectRegex.helpText} label={"Bank Account"}>
                                    <option disabled={true} value={""}>Select a bank account...</option>
                                    {
                                        trustAccounts.map(b => (
                                            <option key={b.uuid}
                                                    value={b.uuid}>{b.name + " | " + b.accountCode + " | " + getAccountName(b.accountCode)}</option>
                                        ))
                                    }
                                </Select>
                            </div>
                            <div className={"col-md-12 mb-3"}>
                                <div className={"row"}>
                                    {getRemitToView()}
                                </div>
                            </div>
                            <div className={"col-md-12 mb-3"}>

                                <button type={"button"} className={"btn btn-danger"}
                                        onClick={() => {
                                            setView("bills")
                                        }}>Cancel
                                </button>
                                <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className={"row"}>
                <div className={"col"}>
                    <table className={"table table-striped"}>
                        <thead>
                        <tr>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Property</th>
                            <th>Reference</th>
                            <th>Cash Account</th>
                            <th>Expense Account</th>
                            <th>Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            bills.filter(b => b.include).map(b => (
                                <tr key={b.uuid}>
                                    <td>{b.transactionDate}</td>
                                    <td>{b.description}</td>
                                    <td>{b.propertyName}</td>
                                    <td>{b.reference}</td>
                                    <td>{b.cashAccountId}</td>
                                    <td>{b.accountName}</td>
                                    <td>{currencyFormat.format(b.amount)}</td>
                                </tr>
                            ))
                        }

                        </tbody>
                    </table>
                </div>
            </div>
        </>
    }

    function doSubmitExpense(e: FormEvent) {
        e.preventDefault();
        setLoading(true);

        addBillItem(getPortfolioId(), {
            date: expenseDate,
            amount: parseFloat(expenseAmount),
            description: expenseDescription,
            accountId: expenseAccount,
            cashAccountId: cashAccount,
            reference: expenseReference,
            propertyId: selectedProperty
        })
            .then((r) => {
                loadBills();
                clear();
            })
            .catch((e) => setError(e.message))
            .finally(() => setLoading(false));
    }

    function isBillSelected() {
        if (bills && bills.length > 0) {
            let filter = bills.filter(b => b.include);
            if (filter && filter.length > 0) {
                return true;
            }
        }
        return false;
    }

    function doDeleteBillItem(billItemId: string) {
        setSelectedDeleteBill(billItemId);
        deleteBillItem(getPortfolioId(), billItemId)
            .then(r => {
                loadBills();
            })
            .catch(e => {
                setError(e.message)
            })
            .finally(() => setSelectedDeleteBill(""));
    }

    function getBillsView() {
        return <>
            {loadingBills && <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>}
            {error && <div className="alert alert-danger" role="alert">
                {error}
            </div>}
            {successMessage && <div className="alert alert-success" role="alert">
                {successMessage}
            </div>}
            <div className={"row"}>
                <div className={"col"}>
                    <form onSubmit={doSubmitExpense}>
                        <div className={"row"}>
                            <div className={"col-md-4 mb-3"}>
                                <AutocompleteInput id={"tenant"} validation={GenericInputRegex.expression}
                                                   data={propertyList}
                                                   doValidate={formInputValidate}
                                                   value={selectedProperty}
                                                   setValue={setSelectedProperty}
                                                   placeholder={"Search by property name"}
                                                   required={true}
                                                   invalidFeedbackText={GenericInputRegex.helpText}
                                                   type={"text"} label={"Property"}
                                />
                            </div>
                            <div className={"col-md-4 mb-3"}>
                                <div className={"row"}>
                                    <div className={"col-md-12 mb-3"}>
                                        <Input id={"amount"} validation={DollarAmountRegex.expression}
                                               doValidate={formInputValidate}
                                               value={expenseAmount}
                                               setValue={setExpenseAmount}
                                               required={true}
                                               step={.01}
                                               min={0}
                                               invalidFeedbackText={DollarAmountRegex.helpText}
                                               type={"number"} label={"Amount"}
                                        />
                                    </div>
                                    <div className={"col-md-12 mb-3"}>
                                        <Input id={"description"} validation={GenericInputRegex.expression}
                                               doValidate={formInputValidate}
                                               value={expenseDescription}
                                               setValue={setExpenseDescription}
                                               required={true}
                                               invalidFeedbackText={GenericInputRegex.helpText}
                                               type={"text"} label={"Description"}
                                        />
                                    </div>
                                    <div className={"col-md-12 mb-3"}>
                                        <Select id={"type"} validation={GenericSelectRegex.expression}
                                                doValidate={formSelectValidate} value={cashAccount}
                                                setValue={setCashAccount}
                                                required={true}
                                                invalidFeedbackText={GenericSelectRegex.helpText}
                                                label={"Cash Account"}>
                                            <option disabled={true} value={""}>Select a cash account...</option>
                                            <option value={"1000"}>1000 - Operating Cash</option>
                                            <option value={"1001"}>1001 - Security Deposit Cash</option>
                                        </Select>
                                    </div>


                                </div>
                            </div>
                            <div className={"col-md-4 mb-3"}>
                                <div className={"col-md-12 mb-3"}>
                                    <Input id={"date"} validation={DateRegex.expression}
                                           doValidate={formInputValidate}
                                           value={expenseDate}
                                           setValue={setExpenseDate}
                                           required={true}
                                           invalidFeedbackText={DateRegex.helpText}
                                           type={"date"} label={"Date"}
                                    />
                                </div>
                                <div className={"col-md-12 mb-3"}>
                                    <Input id={"reference"} validation={GenericInputRegex.expression}
                                           doValidate={formInputValidate}
                                           value={expenseReference}
                                           setValue={setExpenseReference}
                                           required={true}
                                           invalidFeedbackText={GenericInputRegex.helpText}
                                           type={"text"} label={"Reference"}
                                    />
                                </div>
                                <div className={"col-md-12 mb-3"}>
                                    <Select id={"type"} validation={GenericSelectRegex.expression}
                                            doValidate={formSelectValidate} value={expenseAccount}
                                            setValue={setExpenseAccount}
                                            required={true}
                                            invalidFeedbackText={GenericSelectRegex.helpText} label={"GL Account"}>
                                        <option disabled={true} value={""}>Select an account...</option>
                                        <option value={"6000"}>6000 - Other Expense</option>
                                        <option value={"6001"}>6001 - Payment Fees</option>
                                        <option value={"6002"}>6002 - Repairs</option>
                                        <option value={"6003"}>6003 - Management Fees</option>
                                        <option value={"6004"}>6004 - Cleaning and Maintenance</option>
                                        <option value={"6005"}>6005 - Legal and Other Professional Fees</option>
                                        <option value={"6005"}>6005 - Legal and Other Professional Fees</option>
                                        <option value={"2011"}>2011 - Owner Held Security Deposits</option>
                                        <option value={"2101"}>2101 - Mgmt Held Security Deposits</option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col"}>
                                <button type={"submit"} className={"btn btn-primary"}>Save</button>
                                <button type={"button"} onClick={() => clear()} className={"btn btn-danger ms-3"}>Cancel
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className={"row mt-3"}>
                <div className={"col"}>
                    <h5>View Bills</h5>
                </div>

            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <button type={"button"} className={"btn btn-primary"}
                            disabled={!isBillSelected()}
                            onClick={() => {
                                setView("review_bills")
                            }}>Review Payment
                    </button>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <table className={"table table-striped"}>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Property</th>
                            <th>Reference</th>
                            <th>Cash Account</th>
                            <th>Expense Account</th>
                            <th>Amount</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            bills.map(b => (
                                <tr key={b.uuid}>
                                    <td>
                                        <Checkbox
                                            id={"checkbox"}
                                            validateClass={''}
                                            value={b.include}
                                            setValue={(v: boolean) => {
                                                b.include = v;
                                                setBills([...bills])
                                            }}
                                            required={false}
                                            invalidFeedbackText={GenericInputRegex.helpText}
                                            label={""}
                                            disabled={false}
                                        />
                                    </td>
                                    <td>{b.transactionDate}</td>
                                    <td>{b.description}</td>
                                    <td>{b.propertyName}</td>
                                    <td>{b.reference}</td>
                                    <td>{b.cashAccountId}</td>
                                    <td>{b.accountName}</td>
                                    <td>{currencyFormat.format(b.amount)}</td>
                                    <td>
                                        <button
                                            disabled={selectedDeleteBill === b.uuid}
                                            className={"btn btn-sm btn-primary"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            doDeleteBillItem(b.uuid);
                                        }}>
                                            {getDeleteSpinner(b.uuid)}
                                            Reverse
                                        </button>
                                    </td>
                                </tr>
                            ))
                        }

                        </tbody>
                    </table>
                </div>
            </div>
        </>;

    }

    function getView() {
        if (view === "management-fees") {
            return getManagementFeesView();
        }
        if (view === "bills") {
            return getBillsView();
        }
        if (view === "review_bills") {
            return reviewPayBill();
        }
    }

    return <PageLayout title={"Payables"}>
        <div className={"row"}>
            <div className={"col"}>
                <ul className="nav ">
                    <li className={"nav-item"}>
                        <button
                            className={view.endsWith("bills") ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                            aria-current="page"
                            onClick={(e) => {
                                e.preventDefault();
                                setView("bills");
                            }}>Bills
                        </button>
                        <div className={view.endsWith("bills") ? " triangle-down" : ""}></div>
                    </li>
                    <li className={"nav-item"}>
                        <button
                            className={view === "management-fees" ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                            aria-current="page"
                            onClick={(e) => {
                                e.preventDefault();
                                setView("management-fees");
                            }}>Management Fees
                        </button>
                        <div className={view === "management-fees" ? " triangle-down" : ""}></div>
                    </li>
                    <li className={"nav-item"}>
                        <button
                            className={view === "owners" ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                            aria-current="page"
                            onClick={(e) => {
                                e.preventDefault();
                                setView("owners");
                            }}>Owners
                        </button>
                        <div className={view === "owners" ? " triangle-down" : ""}></div>
                    </li>
                </ul>
            </div>
        </div>
        <div className={"row"}>
            <div className={"col"}>
                {getView()}
            </div>
        </div>
    </PageLayout>
}
export default ViewPayables;