import * as React from 'react';
import {Route, Switch, useRouteMatch} from "react-router";
import ViewFloorPlans from "./ViewFloorPlans";
import EditFloorPlan from "./EditFloorPlan";
import AddFloorPlan from "./AddFloorPlan";
import AutoSelectProperty from "../Form/SelectProperty/AutoSelectProperty";

const ManageFloorPlans: React.FunctionComponent = () => {

    let {path} = useRouteMatch();

    return (<>
        <Switch>
            <Route exact path={path}>
                <AutoSelectProperty name={"Floor Plans"}/>
            </Route>
            <Route exact path={path + "/:propertyUuid"}>
                <ViewFloorPlans />
            </Route>
            <Route exact path={path + "/:propertyUuid/edit/:floorPlanUuid"}>
                <EditFloorPlan />
            </Route>
            <Route exact path={path + "/:propertyUuid/add"}>
                <AddFloorPlan />
            </Route>
        </Switch>
    </>);
};

export default ManageFloorPlans;