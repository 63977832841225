import * as React from "react";
import {BootstrapValidateClass, ValidateElementFunction} from "../../../types";
import {useEffect, useState} from "react";

interface TimeLengthProps {
    id: string,
    validation: RegExp,
    doValidate: ValidateElementFunction,
    value: string,
    setValue: Function,
    required: boolean,
    invalidFeedbackText: string,
    label: string,
    placeholder?: string,
    disabled?: boolean,
    setValidFlag?: Function,
    step?: number | string
}

const TimeLength: React.FunctionComponent<TimeLengthProps> = (p) => {

    enum TimeFrame {
        MIN="min(s)",HOUR="hr(s)",DAY="day(s)"
    }

    const [validateClass, setValidateClass] = useState(BootstrapValidateClass.unvalidated);
    const [timeFrame, setTimeFrame] = useState<TimeFrame>();
    const {value} = p;

    useEffect(() => {
        if (value.length === 0 || p.disabled) {
            setValidateClass(BootstrapValidateClass.unvalidated);
            if(p.setValidFlag) {
                p.setValidFlag(false);
            }
            return;
        }
        if (p.doValidate(value, p.required, p.validation)) {
            setValidateClass(BootstrapValidateClass.isvalid);
            if(p.setValidFlag) {
                p.setValidFlag(true);
            }
        } else {
            setValidateClass(BootstrapValidateClass.isinvalid);
            if(p.setValidFlag) {
                p.setValidFlag(false);
            }
        }

    }, [value, p]);

    useEffect(() => {
        if (Number(value) >= 1440) {
            setTimeFrame(TimeFrame.DAY);
        }
        else if (Number(value) >= 60) {
            setTimeFrame(TimeFrame.HOUR);
        }
        else {
            setTimeFrame(TimeFrame.MIN);
        }
    }, [value, TimeFrame.DAY, TimeFrame.HOUR, TimeFrame.MIN])

    const calculateCurrentValue = (value: number | string): number | string => {
        switch (timeFrame) {
            case TimeFrame.HOUR: return Math.round(Number(value) / 60);
            case TimeFrame.DAY: return Math.round(Number(value) / (24 * 60));
            case TimeFrame.MIN:
            default: return value;
        }
    }

    return (<>
        <label htmlFor={p.id}>{p.label}</label>
        <div className="input-group">
            <input type="number" className={"form-control " + validateClass}
                   id={p.id} disabled={p.disabled} placeholder={p.placeholder}
                   required={p.required} value={calculateCurrentValue(p.value)} min={1} step={p.step} onChange={e => {
                let value = Number(e.target.value);
                switch (timeFrame) {
                    case TimeFrame.DAY:
                        value *= 24 * 60;
                        break;
                    case TimeFrame.HOUR:
                        value *= 60;
                }
                p.setValue(value.toString());
            }}/>
            {timeFrame &&
                <select className="form-select custom-select"
                        id={p.id}
                        disabled={p.disabled}
                        value={timeFrame}
                        onChange={e => {
                            switch (e.target.value) {
                                case TimeFrame.MIN: setTimeFrame(TimeFrame.MIN); break;
                                case TimeFrame.HOUR: setTimeFrame(TimeFrame.HOUR); break;
                                case TimeFrame.DAY: setTimeFrame(TimeFrame.DAY); break;
                            }
                        }}>
                    <option value={TimeFrame.MIN}>{TimeFrame.MIN}</option>
                    <option value={TimeFrame.HOUR}>{TimeFrame.HOUR}</option>
                    <option value={TimeFrame.DAY}>{TimeFrame.DAY}</option>
                </select>
            }
            <div className="invalid-feedback">
                {p.invalidFeedbackText}
            </div>
        </div>
    </>)
}

export default TimeLength;
