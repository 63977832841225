import React, {useCallback, useContext, useEffect, useState} from "react"
import { useHistory, useLocation, useParams } from "react-router";
import { CommunityLink, deleteCommunityLink, getCommunityLinks } from "../../../Api/CommunityLinkApi";
import Table from "../../Table";
import AddCommunityLink from "../AddCommunityLink";
import PageLayout from "../../../Layouts/PageLayout";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";

const ViewCommunityLinks: React.FunctionComponent = () => {

    const propertyUuid = useContext(PropertyIdContext)
    const {pathname} = useLocation();
    const history = useHistory();

    const [communityLinks, setCommunityLinks] = useState<CommunityLink[]>([]);
    

    const refresh = useCallback(() => {
        if(propertyUuid) {
            getCommunityLinks(propertyUuid)
                .then(setCommunityLinks)
                .catch(console.error);
        }
    }, [propertyUuid]);

    const removeCommunityLink =  (communityLinkUuid:string) => { 
        deleteCommunityLink(propertyUuid, communityLinkUuid)
        .then(refresh)
        .catch(console.error);
    }


    useEffect(() => {
        refresh();
    }, [propertyUuid, refresh])

    return (<>
        <PageLayout title="Community Links" propertyId={propertyUuid}>
            <div className="row">
                <div className="col-md-6">
                    <AddCommunityLink done={() => refresh()} />
                </div>
                <div className="col-md-6">
                    <Table
                        headers={["Name", "Category", "Description"]}
                        defaultPaginationSize={10}>
                        {communityLinks?.map((cl) => (
                            <tr className={"clickable"} key={cl.uuid}>
                                <td className="text-start"  onClick={() => history.push({pathname: pathname + "/manage/" + cl.uuid})} > {cl.name} </td>
                                <td  onClick={() => history.push({pathname: pathname + "/manage/" + cl.uuid})} >{cl.category}</td>
                                <td  onClick={() => history.push({pathname: pathname + "/manage/" + cl.uuid})} >{cl.description}</td>
                                <td>
                                    <a className="btn btn-primary" href={cl.url} target="_blank" role="button">Link</a>
                                </td>
                                <td>
                                    <button  className="btn btn-danger"  onClick={() => (removeCommunityLink(cl.uuid))}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </Table>
                </div>
            </div>
        </PageLayout>
    </>)
}

export default ViewCommunityLinks