import * as React from 'react';
import {useEffect, useState} from 'react';
import {BootstrapValidateClass, ValidateElementFunction} from "../../../types";

interface PasswordProps {
    id: string,
    validation: RegExp,
    doValidate: ValidateElementFunction,
    value: string,
    setValue: Function,
    required: boolean,
    maxLength?: number,
    minLength?: number,
    invalidFeedbackText: string,
    label: string,
    placeholder?: string,
    disabled?: boolean,
    setValidFlag?: Function,
}

const Password: React.FunctionComponent<PasswordProps> = (p) => {

    enum InputType {
        PASSWORD="password",TEXT="text"
    }

    const [validateClass, setValidateClass] = useState(BootstrapValidateClass.unvalidated);
    const {value} = p;

    const [inputType, setInputType] = useState<InputType>(InputType.PASSWORD);

    useEffect(() => {
        if (value.length === 0 || p.disabled) {
            setValidateClass(BootstrapValidateClass.unvalidated);
            if(p.setValidFlag) {
                p.setValidFlag(false);
            }
            return;
        }
        if (p.doValidate(value, p.required, p.validation)) {
            setValidateClass(BootstrapValidateClass.isvalid);
            if(p.setValidFlag) {
                p.setValidFlag(true);
            }
        } else {
            setValidateClass(BootstrapValidateClass.isinvalid);
            if(p.setValidFlag) {
                p.setValidFlag(false);
            }
        }

    }, [value, p])

    return (<>
        <label htmlFor={p.id}>{p.label}</label>
        <div className="input-group">
            <input type={inputType} className={"form-control " + validateClass}
               id={p.id} disabled={p.disabled}
               maxLength={p.maxLength} minLength={p.minLength} placeholder={p.placeholder}
               required={p.required} value={p.value} onChange={e => {
                   let value = e.target.value;
                   p.setValue(value);
               }}/>
            <div className="input-group-text">
                <input className="form-check-input mt-0" type="checkbox" value="" onChange={e => {
                    e.target.checked ? setInputType(InputType.TEXT) : setInputType(InputType.PASSWORD);
                }}/>
                <label className="ms-2" style={{fontSize: 14}}>Show</label>
            </div>
        </div>
        <div className="invalid-feedback">
            {p.invalidFeedbackText}
        </div>
    </>);
};

export default Password;
