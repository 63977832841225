import * as React from 'react';
import {Redirect, Route, Switch, useRouteMatch} from "react-router";
import InvoicesReport from "../../Components/InvoicesReport";
import PaymentsReport from "../../Components/PaymentsReport";
import PayoutsReport from "../../Components/PayoutsReport";
import IncomeStatement from "../../Components/Reports/IncomeStatement";
import ARAgingSummaryContainer from "../ARAgingSummaryContainer";
import CashFlowComparison from "../../Pages/Accounting/CashFlowComparison";

const ReportsContainer: React.FunctionComponent = (p) => {
    let {path} = useRouteMatch();
    return (<>
        <Switch>
            <Route exact path={path}>
                <Redirect to={path + "/ar-aging-summary"} />
            </Route>
            <Route exact path={path + "/invoices"}>
                <InvoicesReport/>
            </Route>
            <Route exact path={path + "/payments"}>
                <PaymentsReport/>
            </Route>
            <Route exact path={path + "/payouts"}>
                <PayoutsReport/>
            </Route>
            <Route exact path={path + "/income-statement"}>
                <IncomeStatement/>
            </Route>
            <Route path={path + "/ar-aging-summary"}>
                <ARAgingSummaryContainer />
            </Route>

        </Switch>
    </>);
};

export default ReportsContainer;