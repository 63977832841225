import * as React from 'react';
import { FormEvent, useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import Terms from "./Terms";
import InitialTransactions from "./InitialTransactions";
import RecurringTransactions from "./RecurringTransactions";
import { Application, LateFeeDto, LeaseUser, RecurringTransaction, Transaction, TransactionAccount } from "../../../types";
import Review from "./Review";
import LeaseUsers from "./Users";
import { getPortfolioId } from "../../../helpers";
import moment from "moment";
import { useHistory } from "react-router";
import { getApplication, getTransactionAccountsByApplication } from "../../../Api/ApplicationApi";
import { createLease } from "../../../Api/LeaseApi";
import PageLayout from "../../../Layouts/PageLayout";
import LoadingOverlay from "../../LoadingOverlay";
import ProgressStepper, { ProgressPath } from "../../ProgressStepper";
import CreateLeasePathSteps from "../../ProgressStepper/ProgressPaths/CreateLeasePathSteps";
import LeaseRecurringTransactions from '../ViewLease/RecurringTransaction';


const CreateLease: React.FunctionComponent = () => {
    const { propertyId, applicationId } = useParams();
    const [accounts, setAccounts] = useState<TransactionAccount[]>([]);

    const [view, setView] = useState("terms");

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isMonthToMonth, setIsMonthToMonth] = useState(false);
    const [dueDate, setDueDate] = useState("");
    const [recurringStartDate, setRecurringStartDate] = useState("");
    const [initTransactions, setInitTransactions] = useState<Transaction[]>([])
    const [recTransactions, setRecTransactions] = useState<RecurringTransaction[]>([])
    const [leaseUsers, setLeaseUsers] = useState<LeaseUser[]>([]);
    const [application, setApplication] = useState<Application>();
    const [lateFeeDtoList, setLateFeeDtoList] = useState<LateFeeDto[]>([]);

    const [leaseId, setLeaseId] = useState("");
    let history = useHistory();


    useEffect(() => {
        if (leaseId) {
            history.push({
                pathname: "/p/m/customers/leases/" + propertyId + '/view/' + leaseId
            })
        }
    }, [leaseId, history, propertyId]);

    const getRentAccount = () => {
        return accounts.filter(a => a.name.toLowerCase() === "rent")[0].uuid;
    }

    const getFeeAccount = () => {
        return accounts.filter(a => a.name.toLowerCase() === "tenant fee")[0].uuid;
    }

    const getServiceAccount = () => {
        return accounts.filter(a => a.name.toLowerCase() === "tenant services")[0].uuid;
    }

    const getDepositAccount = () => {
        return accounts.filter(a => a.name.toLowerCase() === "security deposit")[0].uuid;
    }

    const getAccountByDescription = (d: string) => {
        if (d.toLowerCase().indexOf("rent") >= 0) {
            return getRentAccount();
        }
        if (d.toLowerCase().indexOf("fee") >= 0) {
            return getFeeAccount();
        }
        return getServiceAccount();
    }

    function getDayOfMonthForRecurringTransactions(dueDate: number) {
        if (dueDate - 7 < 1) {
            return 28 + (dueDate - 7);
        } else {
            return dueDate - 7;
        }
    }

    useEffect(() => {
        if (accounts && accounts.length > 0) {
            getApplication(getPortfolioId(), applicationId)
                .then((a) => {
                    setApplication(a);
                    if (a.applicants) {
                        a.applicants.forEach(ap => {
                            leaseUsers.push({

                                email: ap.email,
                                phone: ap.phone,
                                firstName: ap.firstName,
                                lastName: ap.lastName
                            })
                        })
                        setLeaseUsers([...leaseUsers]);
                    }
                    if (a.quote) {
                        let quote = a.quote;
                        setStartDate(quote.startDate);
                        if (quote.termUnits === "month-to-month") {
                            setIsMonthToMonth(true);
                        }
                        if (quote.termUnits === "fixed_month" && quote.termLength) {
                            let mEndDate = moment(quote.startDate, "YYYY-MM-DD");
                            mEndDate.add(quote.termLength, "months").subtract(1, "day");
                            setEndDate(mEndDate.format("YYYY-MM-DD"));
                        }
                        if (quote.quoteLineItems && startDate) {

                            quote.quoteLineItems.forEach(li => {
                                initTransactions.push({
                                    accountId: getAccountByDescription(li.description),
                                    amount: li.price + "",
                                    description: li.description
                                })
                                recTransactions.push({
                                    accountUuid: getAccountByDescription(li.description),
                                    amount: li.price,
                                    description: li.description,
                                    startDate: startDate,
                                    endDate: endDate,
                                    dayOfMonth: getDayOfMonthForRecurringTransactions(Number(dueDate)) + ""
                                })
                            })

                            setInitTransactions([...initTransactions]);
                            setRecTransactions([...recTransactions]);
                        }
                    }
                    if (a.unit.deposit) {
                        initTransactions.push({
                            accountId: getDepositAccount(),
                            amount: a.unit.deposit + "",
                            description: "Security Deposit"
                        })
                        setInitTransactions([...initTransactions]);
                    } else {


                        initTransactions.push({
                            accountId: getDepositAccount(),
                            amount: a.unit.floorPlan.deposit + "",
                            description: "Security Deposit"
                        })
                        setInitTransactions([...initTransactions]);
                    }
                })
                .catch((e) => console.error(e));
        }
    }, [accounts]);


    useEffect(() => {
        if (application && application.quote && application.quote.quoteLineItems) {
            let recurringTransactions: RecurringTransaction[] = [];
            application.quote.quoteLineItems.forEach(li => {
                recurringTransactions.push({
                    accountUuid: getAccountByDescription(li.description),
                    amount: li.price,
                    description: li.description,
                    startDate: startDate,
                    endDate: endDate,
                    dayOfMonth: getDayOfMonthForRecurringTransactions(Number(dueDate)) + ""
                })
            });
            setRecTransactions(recurringTransactions);
        }
    }, [startDate, endDate, isMonthToMonth]);



    useEffect(() => {
        getTransactionAccountsByApplication(applicationId)
            .then((a) => {
                setAccounts(a);
            })
            .catch((e) => console.error(e));
    }, [applicationId]);

    const doCreateLease = () => {
        createLease({
            applicationId: applicationId,
            startDate: startDate,
            endDate: endDate,
            isMonthToMonth: isMonthToMonth,
            dueDate: dueDate,
            recurringStartDate: recurringStartDate,
            initialTransactions: initTransactions,
            recurringTransactions: recTransactions,
            lateFeeDtoList: lateFeeDtoList,
            users: leaseUsers
        })
            .then((l) => {
                setLeaseId(l.uuid);
            })
            .catch((e) => console.error(e))
    }

    const [activeStep, setActiveStep] = useState<number>(0)

    useEffect(() => {
        switch (view) {
            case "terms": setActiveStep(1); break;
            case "init_transactions": setActiveStep(2); break;
            case "recurring_transactions": setActiveStep(3); break;
            case "users": setActiveStep(4); break;
            case "review": setActiveStep(5); break;
        }
    }, [view])

    const progressPath: ProgressPath = {
        activeStep: activeStep,
        steps: CreateLeasePathSteps
    }

    return (!application ? <LoadingOverlay /> : <>
        <PageLayout
            title="Create Lease"
            property={application.unit.floorPlan.property}
        >
            <div className="col-lg-8 d-flex justify-content-between align-items-center">
                <p>Create a lease for application: <h6 className="mt-2">{applicationId}</h6></p>
                <Link to={"/p/m/customers/applications/" + propertyId + "/view/" + applicationId}>
                    <button type={"button"} className={"btn btn-danger"}>
                        Cancel
                    </button>
                </Link>
            </div>
            <div className="col-lg-8">
                <hr className="mt-0 mb-0" />
                <div className="mb-1 py-3">
                    <ProgressStepper progressPath={progressPath} />
                </div>
                <div hidden={view !== "terms"}>
                    <Terms
                        startDate={startDate}
                        endDate={endDate}
                        isMonthToMonth={isMonthToMonth}
                        dueDate={dueDate}
                        accounts={accounts}
                        next={(p: { startDate: string, endDate: string, isMonthToMonth: boolean, dueDate: string, lateFeeDtoList: LateFeeDto[] }) => {
                            setStartDate(p.startDate);
                            setEndDate(p.endDate);
                            setIsMonthToMonth(p.isMonthToMonth);
                            setDueDate(p.dueDate);
                            setView("init_transactions");
                            setLateFeeDtoList(p.lateFeeDtoList)
                        }}
                    />
                </div>
                <div hidden={view !== "init_transactions"}>
                    <InitialTransactions
                        accounts={accounts}
                        transactions={initTransactions}
                        back={() => {
                            setView("terms")
                        }}
                        next={(t: Transaction[]) => {
                            setInitTransactions([...t]);
                            setView("recurring_transactions")
                        }}
                    />
                </div>
                <div hidden={view !== "recurring_transactions"}>
                    <RecurringTransactions
                        accounts={accounts}
                        transactions={recTransactions}
                        startDate={startDate}
                        endDate={endDate}
                        isMonthToMonth={isMonthToMonth}
                        dueDate={dueDate}
                        recurringStartDate={recurringStartDate}
                        back={() => {
                            setView("init_transactions")
                        }}
                        next={(t: RecurringTransaction[], s: string) => {
                            setRecTransactions([...t]);
                            setRecurringStartDate(s);
                            setView("users")
                        }}
                    />
                </div>
                <div hidden={view !== "users"}>
                    <LeaseUsers
                        users={leaseUsers}
                        back={() => {
                            setView("recurring_transactions")
                        }}
                        next={(u: LeaseUser[]) => {
                            setLeaseUsers([...u])
                            setView("review")
                        }}
                    />
                </div>
                <div hidden={view !== "review"}>
                    <Review
                        dueDate={dueDate}
                        isMonthToMonth={isMonthToMonth}
                        startDate={startDate}
                        endDate={endDate}
                        accounts={accounts}
                        initTransactions={initTransactions}
                        recTransactions={recTransactions}
                        lateFeeDtoList={lateFeeDtoList}
                        recurringStartDate={recurringStartDate}
                        leaseUsers={leaseUsers}
                        back={() => {
                            setView("users")
                        }}
                        next={() => {
                            doCreateLease();
                        }}
                    />
                </div>
            </div>
        </PageLayout>
    </>)
};

export default CreateLease;
