import {StripePublicKey} from "../types";
import {CORE_API_ENDPOINT, handleNetworkError, handleResponse, HttpMethod} from "../api";


type getStripePublicKey = () => Promise<StripePublicKey>;

export const getStripePublicKey: getStripePublicKey = (async () => {
    const path = "/stripe/key";
    return fetch(CORE_API_ENDPOINT + path, {
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            Pragma: 'no-cache'
        },
        method: HttpMethod.GET
    })
        .then(handleResponse, handleNetworkError)
})
