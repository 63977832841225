import {CORE_API_ENDPOINT, HttpMethod} from "../api";
import {AUTH_CODE_EXPIRED_TOKEN, currentSession, doLogoutRedirect} from "../Auth";
import {FileMetadata, FileUploadResponse} from "../types";

type uploadFile = (file: File) => Promise<FileUploadResponse>;
type getFile = (fileId: string) => Promise<FileMetadata>;
type downloadFile = (fileId: string, fileName?: string) => Promise<void>;
type openFile = (fileId: string, fileName?: string) => Promise<void>;


export const uploadFile: uploadFile = (async (file) => {
    const formData = new FormData();
    formData.append(
        "file",
        file,
        file.name
    );
    return fetchFormDataWithAuth("/files", HttpMethod.POST, formData);
});

export const getFile: getFile = async (fileId) => {
    return fetchWithAuth("/files/" + fileId + "/metadata", HttpMethod.GET);
};


export const downloadFile: downloadFile = async (fileId, fileName?) => {
    return fetchWithAuth("/files/" + fileId, HttpMethod.GET)
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "";
            if(fileName) {
                a.download = fileName;
            }
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            a.remove();
        });
};

export const openFile: openFile = async (fileId, fileName?) => {
    return fetchWithAuth("/files/" + fileId, HttpMethod.GET)
        .then(response => response.blob())
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            window.open(url);
        });
};

export const fetchWithAuth = (path: string, method: HttpMethod, payload?: string) => {
    return currentSession()
        .then((o) => {
            return fetch(CORE_API_ENDPOINT + path, {
                mode: 'cors',
                credentials: 'include',
                headers: {
                    Authorization: 'Bearer ' + o.access_token,
                    'Content-Type': 'application/json',
                    Pragma: 'no-cache'
                },
                method: method,
                body: payload
            })
                .then(handleResponse, handleNetworkError)
        })
        .catch((e) => {
            if (e.code === AUTH_CODE_EXPIRED_TOKEN) {
                doLogoutRedirect();
            }
            throw e;
        })
}

const fetchFormDataWithAuth = (path: string, method: HttpMethod, payload?: FormData) => {
    return currentSession()
        .then((o) => {
            return fetch(CORE_API_ENDPOINT + path, {
                mode: 'cors',
                credentials: 'include',
                headers: {
                    Authorization: 'Bearer ' + o.access_token,
                    Pragma: 'no-cache'
                },
                method: method,
                body: payload
            })
                .then(handleResponse, handleNetworkError)
        })
        .catch((e) => {
            if (e.code === AUTH_CODE_EXPIRED_TOKEN) {
                doLogoutRedirect();
            }
            throw e;
        })
}


function handleResponse(response: Response) {
    if (response.ok) {
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json();
        }
        return response;
    } else {
        return response.json().then(function (error) {
            throw error;
        });
    }
}

function handleNetworkError(error: Error) {
    throw error;
}
