import * as React from "react";
import {ARAccount} from "../../../../types";
import {TenantGuest} from "../../../../Api/TenantGuestApi";
import FormFieldBuilder, {assignFormFieldValues, FormFieldType} from "../../../Form/FieldBuilder/FormFieldBuilder";
import TenantGuestForm from "../Forms/TenantGuestForm";
import {getTicketIdFromUuid} from "../../../../helpers";
import moment from "moment/moment";
import {CheckCircleFill, XCircleFill} from "../../../Icons/Icons";

const ViewTenantGuest: React.FunctionComponent<{account: ARAccount, guest: TenantGuest}> = ({account, guest}) => {

    assignFormFieldValues({field: TenantGuestForm.accountId, value: account.unitName});
    assignFormFieldValues({field: TenantGuestForm.firstName, value: guest.firstName, disabledOverride: true});
    assignFormFieldValues({field: TenantGuestForm.lastName, value: guest.lastName, disabledOverride: true});
    assignFormFieldValues({field: TenantGuestForm.details, value: guest.details, disabledOverride: true});
    assignFormFieldValues({field: TenantGuestForm.vehicleDescription, value: guest.vehicleDescription, disabledOverride: true});
    assignFormFieldValues({field: TenantGuestForm.vehicleTagNumber, value: guest.vehicleTagNumber, disabledOverride: true});
    assignFormFieldValues({field: TenantGuestForm.allowedKey, value: guest.allowedKey ? "Yes" : "No", disabledOverride: true, typeOverride: FormFieldType.INPUT});
    assignFormFieldValues({field: TenantGuestForm.secretPhrase, value: guest.secretPhrase, disabledOverride: true});
    assignFormFieldValues({field: TenantGuestForm.permanent, value: guest.permanent ? "Yes" : "No", disabledOverride: true, typeOverride: FormFieldType.INPUT});
    assignFormFieldValues({field: TenantGuestForm.startDate, value: guest.permanent ? "Permanent" : moment(guest.startDate).format("MM / DD / YYYY"), disabledOverride: true, typeOverride: FormFieldType.INPUT});
    assignFormFieldValues({field: TenantGuestForm.endDate, value: guest.permanent ? "Permanent" : moment(guest.endDate).format("MM / DD / YYYY"), disabledOverride: true, typeOverride: FormFieldType.INPUT});

    return (<>
        <div className="row p-1">
            <div className="col-12">
                <div className="row">
                    <h4>Tenant Guest</h4>
                </div>
                <div className="row">
                    <div className="col">
                        <label>Id</label>
                        <h6>{getTicketIdFromUuid(guest.uuid)}</h6>
                    </div>
                    <div className="col">
                        <label>Verified</label>
                        <h6>{guest.verified ? <CheckCircleFill color="green"/> : <XCircleFill color="red"/>}</h6>
                    </div>
                    <div className="col">
                        <label>Created By Management</label>
                        <h6>{guest.createdByManagement ? <CheckCircleFill color="green"/> : <XCircleFill color="red"/>}</h6>
                    </div>
                    <div className="col">
                        <label>Updated By</label>
                        <h6>{guest.updatedBy.firstName + " " + guest.updatedBy.lastName}</h6>
                    </div>
                </div>
            </div>
        </div>
        <div className="row d-grid gap-2 mb-3 p-1">
            <div className="row">
                <div className="col-6">
                    <FormFieldBuilder field={TenantGuestForm.accountId} />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FormFieldBuilder field={TenantGuestForm.firstName} />
                </div>
                <div className="col-6">
                    <FormFieldBuilder field={TenantGuestForm.lastName} />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FormFieldBuilder field={TenantGuestForm.allowedKey} selectOptions={TenantGuestForm.allowedKey.defaultSelectOptions}/>
                </div>
                <div className="col-6">
                    <FormFieldBuilder field={TenantGuestForm.permanent} selectOptions={TenantGuestForm.permanent.defaultSelectOptions}/>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FormFieldBuilder field={TenantGuestForm.startDate} />
                </div>
                <div className="col-6">
                    <FormFieldBuilder field={TenantGuestForm.endDate} />
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <FormFieldBuilder field={TenantGuestForm.details} />
                </div>
                <div className="col-6 d-grid gap-2">
                    <div className="row">
                        <div className="col-12">
                            <FormFieldBuilder field={TenantGuestForm.vehicleDescription} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormFieldBuilder field={TenantGuestForm.vehicleTagNumber} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormFieldBuilder field={TenantGuestForm.secretPhrase} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default ViewTenantGuest;
