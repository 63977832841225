import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import Alert, {AlertProps, AlertType} from "../../Alert";
import {CommunityEvent, getCommunityEvent} from "../../../Api/CommunityEventsApi";
import PropertyHeader from "../../PropertyHeader/PropertyHeader";
import Tabs, {Tab} from "../../Tabs/Tabs";
import FormFieldBuilder from "../../Form/FieldBuilder/FormFieldBuilder";
import CommunityEventForm from "../Forms/CommunityEventForm";
import {getTicketIdFromUuid} from "../../../helpers";
import OverviewCommunityEvent from "../Overview/OverviewCommunityEvent";
import EditCommunityLink from "../../CommunityLinks/EditCommunityLink";
import EditCommunityEvent from "../Edit/EditCommunityEvent";

const ManageCommunityEvent: React.FunctionComponent = () => {

    enum TabView {
        VIEW="View",EDIT="Edit"
    }

    const tabs: Tab[] = [
        {
            targetView: TabView.VIEW,
            label: TabView.VIEW
        },
        {
            targetView: TabView.EDIT,
            label: TabView.EDIT
        },
    ]

    const { propertyId, communityEventId } = useParams();

    const [loading, setLoading] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [alert, setAlert] = useState<AlertProps>();
    const [error, setError] = useState<Error>();

    const [view, setView] = useState<TabView>(TabView.VIEW);

    const [event, setEvent] = useState<CommunityEvent>();

    useEffect(() => {
        setLoading(true);
        getCommunityEvent(propertyId, communityEventId)
            .then(setEvent)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [propertyId, communityEventId, refresh])

    return <>
        <div className="col-md-9">
            <div className="row p-1 mb-2">
                {error && <Alert type={AlertType.DANGER} message={error.message} />}
                <PropertyHeader title={"Community Events"} />
                <Tabs view={view} setView={setView} tabs={tabs} />
            </div>
            {event && <>
                <div hidden={view !== TabView.VIEW}>
                    <OverviewCommunityEvent event={event} />
                </div>
                <div hidden={view !== TabView.EDIT}>
                    <EditCommunityEvent event={event} done={() => {
                        setRefresh(!refresh);
                        setView(TabView.VIEW);
                    }}/>
                </div>
            </>}
        </div>
    </>
}

export default ManageCommunityEvent;
