import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {
    AddressLine1Regex, AddressLine2Regex,
    Applicant,
    ApplicantAddress,
    ApplicantType,
    ApplicationStatus, CityRegex, DateRegex, DollarAmountRegex,
    FormElementValidation, formInputValidate,
    formSelectValidate, FullNameRegex, GenericSelectRegex, PhoneNumberRegex, ZipRegex
} from "../../../types";
import Alert, {AlertProps, AlertType} from "../../Alert";
import {useHistory, useParams} from "react-router";
import StateSelect from "../../StateSelect";
import {getPortfolioId} from "../../../helpers";
import LoadingOverlay from "../../LoadingOverlay";
import Select from "../../Form/Select";
import Input from "../../Form/Input";
import {getApplication, updateApplication} from "../../../Api/ApplicationApi";
import ApplicationStageLayout from "../../../Layouts/ApplicationStageLayout";
import ButtonNew, {ButtonColors, ButtonTypes} from "../../ButtonNew";

type Stage3Props = {
    handleInvalidApplicationStatus: Function
    applicationId: string
    setStage: Function
}

const Stage3: React.FunctionComponent<Stage3Props> = (p) => {
    const { propertyId } = useParams();

    const [error, setError] = useState<Error>()
    const [alert, setAlert] = useState<AlertProps>()
    const [loading, setLoading] = useState(false);
    const [doRedirectToStage2, setDoRedirectToStage2] = useState(false);
    const [doRedirectToStage4, setDoRedirectToStage4] = useState(false);
    const history = useHistory();

    type Form = {
        applicants: FormElementValidation,
        addressLine1: FormElementValidation,
        addressLine2: FormElementValidation,
        city: FormElementValidation,
        state: FormElementValidation,
        zip: FormElementValidation,
        landlordName: FormElementValidation,
        landlordPhone: FormElementValidation,
        rentAmount: FormElementValidation,
        moveInDate: FormElementValidation,
        moveOutDate: FormElementValidation
    }


    const form: Form = {
        applicants: {
            id: "applicants",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        addressLine1: {
            id: "addressLine1",
            required: true,
            validate: formInputValidate,
            regex: AddressLine1Regex
        },
        addressLine2: {
            id: "addressLine2",
            required: false,
            validate: formInputValidate,
            regex: AddressLine2Regex
        },
        city: {
            id: "city",
            required: true,
            validate: formInputValidate,
            regex: CityRegex
        },
        state: {
            id: "state",
            required: true,
            validate: formInputValidate,
            regex: GenericSelectRegex
        },
        zip: {
            id: "zip",
            required: true,
            validate: formInputValidate,
            regex: ZipRegex
        },
        landlordName: {
            id: "landlordName",
            required: true,
            validate: formInputValidate,
            regex: FullNameRegex
        },
        landlordPhone: {
            id: "landlordPhone",
            required: true,
            validate: formInputValidate,
            regex: PhoneNumberRegex
        },
        rentAmount: {
            id: "rentAmount",
            required: true,
            validate: formInputValidate,
            regex: DollarAmountRegex
        },
        moveInDate: {
            id: "moveInDate",
            required: true,
            validate: formInputValidate,
            regex: DateRegex
        },
        moveOutDate: {
            id: "moveOutDate",
            required: true,
            validate: formInputValidate,
            regex: DateRegex
        }
    };

    //form
    const [applicants, setApplicants] = useState(new Array<Applicant>());

    const [applicant, setApplicant] = useState("");
    const [addressLine1, setAddressLine1] = useState("");
    const [addressLine2, setAddressLine2] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [landlordName, setLandlordName] = useState("");
    const [landlordPhone, setLandlordPhone] = useState("");
    const [rentAmount, setRentAmount] = useState("");
    const [moveInDate, setMoveInDate] = useState("");
    const [isCurrentAddress, setIsCurrentAddress] = useState(false);
    const [moveOutDate, setMoveOutDate] = useState("");

    const [doRedirectToStage1, setDoRedirectToStage1] = useState(false);
    const [doHandleInvalidApplicationStatus, setDoHandleInvalidApplicationStatus] = useState(false);

    useEffect(() => {
        if (doHandleInvalidApplicationStatus) {
            p.handleInvalidApplicationStatus();
        }
    }, [doHandleInvalidApplicationStatus, p]);

    useEffect(() => {
        if (doRedirectToStage1) {
            setDoRedirectToStage1(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/1"
            });
        }

    }, [doRedirectToStage1, history])

    useEffect(() => {
        console.log("stage 3 init");
        p.setStage(3)
        setLoading(true);
        getApplication(getPortfolioId(), p.applicationId)
            .then((a) => {
                if (a.status !== ApplicationStatus.approved) {
                    if (!a.unit || (a.unit && !a.unit.uuid)) {
                        console.log("redirect to stage 1, missing unit")
                        setDoRedirectToStage1(true);
                        return;
                    }
                    if (a.applicants && a.applicants.length > 0) {
                        setApplicants(a.applicants);
                    }
                    setLoading(false);
                } else {
                    setDoHandleInvalidApplicationStatus(true);
                }
            })
            .catch(e => {
                setLoading(false);
                doShowAlert("Failed to start application: " + e.message, AlertType.DANGER);
            })


    }, [p])

    const saveAndContinueStage4 = () => {
        setLoading(true);
        updateApplication(getPortfolioId(), p.applicationId, {
            applicants: applicants
        })
            .then((a) => {
                setDoRedirectToStage4(true);
            })
            .catch(e => {
                doShowAlert("Failed to add addresses: " + e.message, AlertType.DANGER);
            }).finally(() => setLoading(false));
    }

    const clearForm = () => {
        setApplicant("");
        setAddressLine1("");
        setAddressLine2("");
        setCity("");
        setState("");
        setZip("");
        setLandlordName("");
        setLandlordPhone("");
        setRentAmount("");
        setMoveInDate("");
        setIsCurrentAddress(false);
        setMoveOutDate("");
    }

    const doShowAlert = (message: string, type: AlertType) => {
        setAlert({type, message})
    }


    useEffect(() => {
        if (doRedirectToStage4) {
            setDoRedirectToStage4(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/4"
            });
        }

    }, [doRedirectToStage4, history])

    useEffect(() => {
        if (doRedirectToStage2) {
            setDoRedirectToStage2(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/2"
            });
        }

    }, [doRedirectToStage2, history])

    const deleteAddressFromApplicant = (ap: Applicant, ad: ApplicantAddress) => {
        applicants.forEach(i => {
            if (i === ap && ap.applicantAddresses) {
                ap.applicantAddresses = ap.applicantAddresses.filter(j => j !== ad)
            }
        })
        setApplicants([...applicants])
    }


    const validateForm = (form: Form): boolean => {
        if (!form.applicants.validate(applicant, form.applicants.required, form.applicants.regex.expression)) {
            doShowAlert("An applicant must be selected.", AlertType.DANGER);
            return false;
        }
        if (!form.addressLine1.validate(addressLine1, form.addressLine1.required, form.addressLine1.regex.expression)) {
            doShowAlert("Invalid address 1.", AlertType.DANGER);
            return false;
        }
        if (!form.addressLine2.validate(addressLine2, form.addressLine2.required, form.addressLine2.regex.expression)) {
            doShowAlert("Invalid address 2.", AlertType.DANGER);
            return false;
        }
        if (!form.city.validate(city, form.city.required, form.city.regex.expression)) {
            doShowAlert("Invalid city.", AlertType.DANGER);
            return false;
        }
        if (!form.state.validate(state, form.state.required, form.state.regex.expression)) {
            doShowAlert("Invalid state.", AlertType.DANGER);
            return false;
        }
        if (!form.zip.validate(zip, form.zip.required, form.zip.regex.expression)) {
            doShowAlert("Invalid zip.", AlertType.DANGER);
            return false;
        }
        if (!form.landlordName.validate(landlordName, form.landlordName.required, form.landlordName.regex.expression)) {
            doShowAlert("Invalid landlord name.", AlertType.DANGER);
            return false;
        }
        if (!form.landlordPhone.validate(landlordPhone, form.landlordPhone.required, form.landlordPhone.regex.expression)) {
            doShowAlert("Invalid landlord phone.", AlertType.DANGER);
            return false;
        }
        if (!form.rentAmount.validate(rentAmount, form.rentAmount.required, form.rentAmount.regex.expression)) {
            doShowAlert("Invalid rent amount.", AlertType.DANGER);
            return false;
        }
        if (!form.moveInDate.validate(moveInDate, form.moveInDate.required, form.moveInDate.regex.expression)) {
            doShowAlert("Invalid move in date.", AlertType.DANGER);
            return false;
        }
        if (!form.moveOutDate.validate(moveOutDate, !isCurrentAddress, form.moveOutDate.regex.expression)) {
            doShowAlert("Invalid move out date.", AlertType.DANGER);
            return false;
        }
        return true;
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!validateForm(form)) {
            return;
        }
        applicants.forEach(i => {
            if (i.uuid === applicant) {
                if(i.applicantAddresses === undefined) {
                    i.applicantAddresses = [];
                }
                i.applicantAddresses.push({
                    uuid: "",
                    addressLine1: addressLine1,
                    addressLine2: addressLine2,
                    city: city,
                    state: state,
                    zip: zip,
                    landLordName: landlordName,
                    landLordPhone: landlordPhone,
                    rentAmount: parseFloat(rentAmount),
                    moveInDate: moveInDate,
                    isCurrentAddress: isCurrentAddress,
                    moveOutDate: moveOutDate === "" ? undefined : moveOutDate
                })
            }
        })
        setApplicants([...applicants]);
        clearForm();
    }

    const [isStageValid, setIsStageValid] = useState<boolean>(false)

    useEffect(() => {
        setIsStageValid(applicants.filter(a => a.applicantAddresses?.length > 0).length === applicants.length)
    }, [applicants])

    return (loading ? <div className="col-md-6"><LoadingOverlay /></div> : <>
        <ApplicationStageLayout
            error={error}
            alert={alert}
            title="Address History"
            description="Please provide two years of address history for each resident applicant"
        >
            <div className="col-lg-6 col-md-8 mb-5">
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <Select id={form.applicants.id} validation={form.applicants.regex.expression}
                                    doValidate={form.applicants.validate}
                                    value={applicant} setValue={setApplicant}
                                    required={form.applicants.required}
                                    invalidFeedbackText={form.applicants.regex.helpText} label={"Resident Applicant"}>
                                <option value={""} disabled={true}>Select a resident applicant...</option>
                                {
                                    applicants.filter(a => (a.applicantType === ApplicantType.occupant || a.applicantType === ApplicantType.cooccupant)).map((a, index) => (
                                        <option key={"appl_" + index}
                                                value={a.uuid}>{a.firstName + " " + a.lastName}</option>
                                    ))
                                }
                            </Select>
                        </div>
                        <div className={"col-md-12 mb-2"}>
                            <Input id={form.addressLine1.id} validation={form.addressLine1.regex.expression}
                                   doValidate={form.addressLine1.validate}
                                   value={addressLine1}
                                   setValue={setAddressLine1}
                                   required={form.addressLine1.required}
                                   invalidFeedbackText={form.addressLine1.regex.helpText}
                                   type={"text"} label={"Address"}
                            />
                        </div>
                        <div className={"col-md-12 mb-2"}>
                            <Input id={form.addressLine2.id} validation={form.addressLine2.regex.expression}
                                   doValidate={form.addressLine2.validate}
                                   value={addressLine2}
                                   setValue={setAddressLine2}
                                   required={form.addressLine2.required}
                                   invalidFeedbackText={form.addressLine2.regex.helpText}
                                   type={"text"} label={"Address 2 (Optional)"}
                            />
                        </div>

                        <div className="col-md-5 mb-2">
                            <Input id={form.city.id} validation={form.city.regex.expression}
                                   doValidate={form.city.validate}
                                   value={city}
                                   setValue={setCity}
                                   required={form.city.required}
                                   invalidFeedbackText={form.city.regex.helpText}
                                   type={"text"} label={"City"}
                            />
                        </div>
                        <div className="col-md-4 mb-2">
                            <StateSelect id={form.state.id} validation={form.state.regex.expression}
                                         noSelectionText={"Select a State..."}
                                         doValidate={form.state.validate}
                                         value={state} setValue={setState}
                                         required={form.state.required}
                                         invalidFeedbackText={form.state.regex.helpText}
                                         label={"State"}/>
                        </div>
                        <div className="col-md-3 mb-2">
                            <Input id={form.zip.id} validation={form.zip.regex.expression}
                                   doValidate={form.zip.validate}
                                   value={zip}
                                   setValue={setZip}
                                   placeholder={"xxxxx-xxxx"}
                                   required={form.zip.required}
                                   invalidFeedbackText={form.zip.regex.helpText}
                                   type={"text"} label={"Zip"}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <Input id={form.landlordName.id} validation={form.landlordName.regex.expression}
                                   doValidate={form.landlordName.validate}
                                   value={landlordName}
                                   setValue={setLandlordName}
                                   required={form.landlordName.required}
                                   invalidFeedbackText={form.landlordName.regex.helpText}
                                   type={"text"} label={"Landlord name"}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <Input id={form.landlordPhone.id} validation={form.landlordPhone.regex.expression}
                                   doValidate={form.landlordPhone.validate}
                                   value={landlordPhone}
                                   setValue={setLandlordPhone}
                                   placeholder={"xxx-xxx-xxxx"}
                                   required={form.landlordPhone.required}
                                   invalidFeedbackText={form.landlordPhone.regex.helpText}
                                   type={"text"} label={"Landlord phone"}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <Input id={form.rentAmount.id} validation={form.rentAmount.regex.expression}
                                   doValidate={form.rentAmount.validate}
                                   value={rentAmount}
                                   setValue={setRentAmount}
                                   required={form.rentAmount.required}
                                   invalidFeedbackText={form.rentAmount.regex.helpText}
                                   type={"number"} label={"Rent amount per month"}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <Input id={form.moveInDate.id} validation={form.moveInDate.regex.expression}
                                   doValidate={form.moveInDate.validate}
                                   value={moveInDate}
                                   setValue={setMoveInDate}
                                   required={form.moveInDate.required}
                                   invalidFeedbackText={form.moveInDate.regex.helpText}
                                   type={"date"} label={"Move in date"}
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                       id="defaultCheck1"
                                       checked={isCurrentAddress}
                                       onChange={e => {
                                           let value = e.target.checked;
                                           setIsCurrentAddress(value);
                                           if (value) {
                                               setMoveOutDate("");
                                           }

                                       }}/>
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                    Current address
                                </label>
                            </div>
                        </div>
                        <div className="col-md-6 mb-2">
                            <Input id={form.moveOutDate.id} validation={form.moveOutDate.regex.expression}
                                   doValidate={form.moveOutDate.validate}
                                   value={moveOutDate}
                                   setValue={setMoveOutDate}
                                   required={!isCurrentAddress}
                                   disabled={isCurrentAddress}
                                   invalidFeedbackText={form.moveOutDate.regex.helpText}
                                   type={"date"} label={"Move out date"}
                            />
                        </div>
                        <div className={"col-md-12 mb-2"}>
                            <button type="submit" className="btn btn-primary">Add to address history
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <div className={"table-responsive"}>
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">Applicant</th>
                                <th scope="col">Address</th>
                                <th scope="col">Address 2</th>
                                <th scope="col">City</th>
                                <th scope="col">State</th>
                                <th scope="col">Zip</th>
                                <th scope="col">From</th>
                                <th scope="col">To</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                applicants.filter(ap => (ap.applicantType === ApplicantType.occupant || ap.applicantType === ApplicantType.cooccupant) && ap.applicantAddresses)
                                    .map((ap, appindex) => (
                                            ap.applicantAddresses.map((a, addindex) => (
                                                <tr key={"app_" + appindex + "_add_" + addindex}>
                                                    <td>{ap.firstName + " " + ap.lastName}</td>
                                                    <td>{a.addressLine1}</td>
                                                    <td>{a.addressLine2}</td>
                                                    <td>{a.city}</td>
                                                    <td>{a.state}</td>
                                                    <td>{a.zip}</td>
                                                    <td>{a.moveInDate}</td>
                                                    <td>{a.moveOutDate}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-danger btn-sm"
                                                                onClick={e => deleteAddressFromApplicant(ap, a)}
                                                        >Remove
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        )
                                    )
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="d-flex justify-content-start gap-2 mt-3">
                    <ButtonNew
                        type={ButtonTypes.BUTTON}
                        color={ButtonColors.SECONDARY}
                        label="Back"
                        onClick={() => setDoRedirectToStage2(true)}
                    />
                    <ButtonNew
                        type={ButtonTypes.BUTTON}
                        color={ButtonColors.PRIMARY}
                        label="Save & Continue"
                        loading={loading}
                        disabled={false}
                        onClick={saveAndContinueStage4}
                    />
                </div>
            </div>
        </ApplicationStageLayout>
    </>);
};

export default Stage3;
