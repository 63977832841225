import * as React from 'react';
import {useEffect} from "react";

type ModalProps = {
    show: boolean,
    title: string,
    cancelButtonText: string,
    okButtonText: string,
    doCancel: Function,
    doOk: Function,
    disableOk?: boolean
}

const Modal: React.FunctionComponent<ModalProps> = (p) => {

    return (<>
        <div hidden={!p.show} style={{display: "block"}} className="modal fade show" id="staticBackdrop"
             data-backdrop="static" data-keyboard="false" tabIndex={-1}
             aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel">{p.title}</h5>
                        <button type="button" className="btn btn-close" data-dismiss="modal" aria-label="Close"
                                onClick={e => {
                                    e.preventDefault();
                                    p.doCancel();
                                }}
                        />
                    </div>
                    <div className="modal-body">
                        {p.children}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                onClick={e => {
                                    e.preventDefault();
                                    p.doCancel();
                                }}
                        >{p.cancelButtonText}</button>
                        <button type="button" className="btn btn-primary"
                                disabled={(p.disableOk === undefined) ? false : p.disableOk}
                                onClick={e => {
                                    e.preventDefault();
                                    p.doOk();
                                }}
                        >{p.okButtonText}</button>
                    </div>
                </div>
            </div>
        </div>
        <div hidden={!p.show} className="modal-backdrop show"></div>
    </>);
};

export default Modal;
