import React, { useEffect, useState, useRef } from "react";
import PageLayout from "../../../Layouts/PageLayout";
import { getProperties } from "../../../Api/PortfolioApi";
import { getPortfolioId } from "../../../helpers";
import { Property } from "../../../types";
import { TabulatorFull as Tabulator } from "tabulator-tables";

const ViewPropertyDirectory: React.FunctionComponent = () => {
  const [properties, setProperties] = useState<Property[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const tableRef = useRef<HTMLDivElement>(null);
  const tableInstance = useRef<Tabulator | null>(null);

  useEffect(() => {
    getProperties(getPortfolioId())
      .then((pz) => {
        setProperties(pz);
      })
      .catch((e) => setErrorMessage(e.message));
  }, []);

  useEffect(() => {
    if (tableRef.current && properties.length) {
      const columns = [
        { title: "Property Name", field: "name", formatter: (cell: any) => `<a href="/p/m/realestate/properties/edit/${cell.getRow().getData().uuid}">${cell.getValue()}</a>` },
        { title: "Bank Statement Name", field: "bankStatementName" },
        { title: "Address", field: "address", formatter: (cell: any) => cell.getValue() || "Address not available" },
        { title: "Email Reply To", field: "emailReplyTo" },
        { title: "Owner", field: "ownerName" },
        { title: "Reserve Amount", field: "reserveAmount", sorter: "number" as const },
        { title: "Security Deposit Trust Account", field: "securityDepositTrustAccount", formatter: (cell: any) => cell.getValue() || "N/A" },
        { title: "Rent Trust Account", field: "rentTrustAccount", formatter: (cell: any) => cell.getValue() || "N/A" },
        { title: "ACH Verified", field: "achAccountVerified", formatter: (cell: any) => (cell.getValue() ? "Yes" : "No") },
        { title: "CC Verified", field: "ccAccountVerified", formatter: (cell: any) => (cell.getValue() ? "Yes" : "No") },
      ];

      const flattenedData: any[] = properties.map((p) => ({
        uuid: p.uuid,
        name: p.name,
        bankStatementName: p.bankStatementName,
        address: p.address
          ? `${p.address.addressLine1}, ${p.address.addressLine2 ? p.address.addressLine2 + ', ' : ''}${p.address.city}, ${p.address.stateCode} ${p.address.zip}`
          : "Address not available",
        emailReplyTo: p.emailReplyTo,
        ownerName: p.owner ? `${p.owner.name} (${p.owner.person?.firstName} ${p.owner.person?.lastName})` : "N/A",
        reserveAmount: p.reserveAmount,
        securityDepositTrustAccount: p.securityDepositTrustAccount?.name || "N/A",
        rentTrustAccount: p.rentTrustAccount?.name || "N/A",
        achAccountVerified: p.achAccountVerified,
        ccAccountVerified: p.ccAccountVerified,
      }));

      const table = new Tabulator(tableRef.current, {
        data: flattenedData,
        layout: "fitDataStretch",
        columns: columns,
        pagination: false,
        paginationSize: 25,
        initialSort: [{ column: "name", dir: "asc" }],
      });
      tableInstance.current = table;

      const exportButton = document.createElement("button");
      exportButton.textContent = "Export to PDF";
      exportButton.className = "btn btn-primary mb-3";
      exportButton.onclick = () => {
        table.download("pdf", "PropertyDirectory.pdf", {
          orientation: "portrait",
          title: "Property Directory Report",
        });
      };

      if (tableRef.current?.parentElement) {
        tableRef.current.parentElement.insertBefore(exportButton, tableRef.current);
      }

      return () => {
        table.destroy();
      };
    }
  }, [properties]);

  return (
    <PageLayout title={"Property Directory"}>
      <div className="row">
        <div className="col-md-12">
          {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
          <div ref={tableRef}></div> {/* Table placeholder */}
        </div>
      </div>
    </PageLayout>
  );
};

export default ViewPropertyDirectory;
