import * as React from 'react';
import {useEffect, useState} from 'react';
import Alert, {AlertProps, AlertType} from "../../Alert";
import {useHistory, useParams} from "react-router";
import {getPortfolioId} from "../../../helpers";
import {ApplicationStatus} from "../../../types";
import {getApplication} from "../../../Api/ApplicationApi";
import LoadingOverlay from "../../LoadingOverlay";
import ApplicationStageLayout from "../../../Layouts/ApplicationStageLayout";

type Stage10Props = {
    handleInvalidApplicationStatus: Function
    applicationId: string
    setStage: Function
}

const Stage10: React.FunctionComponent<Stage10Props> = (p) => {
    const [error, setError] = useState<Error>()
    const [alert, setAlert] = useState<AlertProps>()
    const [loading, setLoading] = useState(false);
    const [doRedirectToStage9, setDoRedirectToStage9] = useState(false);
    const history = useHistory();
    const [doRedirectToStage1, setDoRedirectToStage1] = useState(false);

    //form
    const [applicationTrackingNumber, setApplicationTrackingNumber] = useState("");

    useEffect(() => {
        console.log("stage 10 init");
        p.setStage(10)
        setLoading(true);
        getApplication(getPortfolioId(), p.applicationId)
            .then((a) => {
                if (a.status === ApplicationStatus.submitted) {
                    setApplicationTrackingNumber(a.uuid);
                } else {
                    setDoRedirectToStage1(true);
                }
            })
            .catch(e => {
                doShowAlert("Failed to get application: " + e.message, AlertType.DANGER);
            }).finally(() => setLoading(false))


    }, [p])

    useEffect(() => {
        if (doRedirectToStage1) {
            setDoRedirectToStage1(false);
            history.push({
                pathname: "/p/m/customers/applications/add/1"
            })
        }

    }, [doRedirectToStage1, history])


    const doShowAlert = (message: string, type: AlertType) => {
        setAlert({type, message})
    }

    useEffect(() => {
        if (doRedirectToStage9) {
            setDoRedirectToStage9(false);
            history.push({
                pathname: "/p/m/customers/applicants/add/9"
            })
        }

    }, [doRedirectToStage9, history])


    return (loading ? <div className="col-md-6"><LoadingOverlay /></div> : <>
        <ApplicationStageLayout
            error={error}
            alert={alert}
            title="Application Submitted"
            description="Thank you! Your application is under review."
        >
            <p>Please keep this application ID for your records:</p>
            <h3><strong>{applicationTrackingNumber}</strong></h3>
        </ApplicationStageLayout>
    </>);
};

export default Stage10;
