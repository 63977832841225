import {FormBuilderField, FormFieldType} from "../../../Form/FieldBuilder/FormFieldBuilder";
import {
    DateRegex,
    formInputValidate,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex,
} from "../../../../types";

interface FrontDeskInstructionForm {
    accountId: FormBuilderField,
    comments: FormBuilderField,
    permanent: FormBuilderField,
    startDate: FormBuilderField,
    endDate: FormBuilderField,
}

const AddFrontDeskInstructionForm: FrontDeskInstructionForm = {
    accountId: {
        type: FormFieldType.INPUT,
        element: {
            id: "accountId",
            required: false,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        disabled: true,
        label: "Unit",
        value: undefined,
        setValue: () => {}
    },
    comments: {
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 8,
        element: {
            id: "comments",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "Comments",
        value: undefined,
        setValue: () => {}
    },
    permanent: {
        type: FormFieldType.SELECT,
        element: {
            id: "permanent",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex,
        },
        label: "Permanent",
        defaultSelectOptions: [
            {
                value: "No",
                label: "No"
            },
            {
                value: "Yes",
                label: "Yes"
            },
        ],
        value: undefined,
        setValue: () => {}
    },
    startDate: {
        type: FormFieldType.DATE,
        element: {
            id: "startDate",
            required: false,
            validate: formInputValidate,
            regex: DateRegex,
        },
        label: "Start Date",
        value: undefined,
        setValue: () => {}
    },
    endDate: {
        type: FormFieldType.DATE,
        element: {
            id: "endDate",
            required: false,
            validate: formInputValidate,
            regex: DateRegex,
        },
        label: "End Date",
        value: undefined,
        setValue: () => {}
    },
}

export default AddFrontDeskInstructionForm;
