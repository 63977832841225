import {FormElementValidation, formInputValidate, GenericInputRegex} from "../../../types";

interface OverviewMaintenanceRequestForm {
    category: FormElementValidation,
    description: FormElementValidation,
    entryPermission: FormElementValidation,
    accessDetails: FormElementValidation,
    scheduledDate: FormElementValidation,
    technicalNotes: FormElementValidation,
    assignedMaintenanceUser: FormElementValidation
}

const OverviewMaintenanceRequestForm: OverviewMaintenanceRequestForm = {
    category: {
        id: "category",
        required: false,
        validate: formInputValidate,
        regex: GenericInputRegex
    },
    description: {
        id: "description",
        required: false,
        validate: formInputValidate,
        regex: GenericInputRegex
    },
    entryPermission: {
        id: "category",
        required: false,
        validate: formInputValidate,
        regex: GenericInputRegex
    },
    accessDetails: {
        id: "category",
        required: false,
        validate: formInputValidate,
        regex: GenericInputRegex
    },
    scheduledDate: {
        id: "category",
        required: false,
        validate: formInputValidate,
        regex: GenericInputRegex
    },
    technicalNotes: {
        id: "category",
        required: false,
        validate: formInputValidate,
        regex: GenericInputRegex
    },
    assignedMaintenanceUser: {
        id: "category",
        required: false,
        validate: formInputValidate,
        regex: GenericInputRegex
    }
}

export default OverviewMaintenanceRequestForm;
