import * as React from 'react';
import {FormEvent, useCallback, useEffect, useState} from 'react';
import {
    ApplicationStatus,
    FormElementValidation,
    formSelectValidate,
    GenericSelectRegex,
    Property,
    Unit
} from "../../../types";
import {AlertProps, AlertType} from "../../Alert";
import {useHistory, useParams} from "react-router";
import {getPortfolioId} from "../../../helpers";
import Select from "../../Form/Select";
import {getUnits} from "../../../Api/UnitApi";
import {getApplication, updateApplication} from "../../../Api/ApplicationApi";
import {getProperties} from "../../../Api/PropertyApi";
import ButtonNew, {ButtonColors, ButtonTypes} from "../../ButtonNew";
import ApplicationStageLayout from "../../../Layouts/ApplicationStageLayout";
import LoadingOverlay from "../../LoadingOverlay";

type Stage1Props = {
    handleInvalidApplicationStatus: Function
    applicationId: string
    setStage: Function
}
const Stage1: React.FunctionComponent<Stage1Props> = (p) => {

    const { propertyId,journeyLeaseId } = useParams();

    const [error, setError] = useState<Error>()
    const [alert, setAlert] = useState<AlertProps>()

    const [loading, setLoading] = useState(false);
    const [doRedirectToStage2, setDoRedirectToStage2] = useState(false);
    const [doHandleInvalidApplicationStatus, setDoHandleInvalidApplicationStatus] = useState(false);
    const history = useHistory();

    //form
    const [properties, setProperties] = useState(new Array<Property>());
    const [units, setUnits] = useState(new Array<Unit>());
    const [applicationStatus, setApplicationStatus] = useState<ApplicationStatus>(ApplicationStatus.in_progress);

    type Form = {
        properties: FormElementValidation,
        units: FormElementValidation
    }

    const form: Form = {
            properties: {
                id: "properties",
                required: true,
                validate: formSelectValidate,
                regex: GenericSelectRegex
            },
            units: {
                id: "units",
                required: true,
                validate: formSelectValidate,
                regex: GenericSelectRegex
            }
        };

    //form data
    const [property, setProperty] = useState(propertyId || "");
    const [unit, setUnit] = useState("");

    useEffect(() => {
        if (doHandleInvalidApplicationStatus) {
            p.handleInvalidApplicationStatus();
        }
    }, [doHandleInvalidApplicationStatus, p]);

    const loadUnits = useCallback((p: string) => {
        setLoading(true);
        getUnits(p, {})
            .then(u => setUnits(u))
            .catch(e => {
                setAlert({type: AlertType.DANGER, message: "Failed to load units: " + e.message})
            }).finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        console.log("stage 1 init");
        p.setStage(1)
        setLoading(true);
        getApplication(getPortfolioId(), p.applicationId)
            .then((a) => {
                setApplicationStatus(a.status);
                if (a.status !== ApplicationStatus.approved) {
                    if (a && a.unit && a.unit.uuid && a.unit.floorPlan && a.unit.floorPlan.property && a.unit.floorPlan.property.uuid) {
                        setProperty(a.unit.floorPlan.property.uuid || "");
                        setUnit(a.unit.uuid || "");
                    }

                } else {
                    setDoHandleInvalidApplicationStatus(true);
                }
            })
            .catch(e => {
                setAlert({message: "Failed to start application: " + e.message, type: AlertType.DANGER});
            }).finally(() => setLoading(false))

        setLoading(true);
        getProperties({})
            .then((p) => setProperties(p))
            .catch(e => {
                setAlert({message: "Failed to load properties: " + e.message, type: AlertType.DANGER});
            }).finally(() => setLoading(false));
    }, [p, loadUnits])

    const validateForm = (form: Form): boolean => {
        if(!form.properties.validate(property, form.properties.required, form.properties.regex.expression)) {
            return false;
        }
        if(!form.units.validate(unit, form.properties.required, form.properties.regex.expression)) {
            return false;
        }
        return true;
    }

    const saveAndContinueStage2 = (e: FormEvent) => {
        e.preventDefault();
        if(!validateForm(form)) {
            return;
        }
        setLoading(true);
        updateApplication(
            getPortfolioId(),
            p.applicationId,
            {
                unit: {
                    uuid: unit
                },
                status: applicationStatus
            },
            journeyLeaseId,
        )
            .then((a) => {
                setDoRedirectToStage2(true);
            })
            .catch(e => {
                console.log(e);
                setAlert({message: "Failed to save application: " + e.message, type: AlertType.DANGER});
            }).finally(() => setLoading(false));
    }

    useEffect(() => {
        if (doRedirectToStage2) {
            console.log("do redirect to stage 2")
            setDoRedirectToStage2(false);
            history.push({
                pathname: "/p/m/customers/applications/"+propertyId+"/add/2"
            });
        }
    }, [doRedirectToStage2, history])

    useEffect(() => {
        if(property && property.length > 0) {
            loadUnits(property);
        }
    }, [property, loadUnits]);

    return (loading ? <div className="col-md-6"><LoadingOverlay /></div> : <>
        <ApplicationStageLayout
            error={error}
            alert={alert}
            title="Unit Selection"
            description="Select an available unit for this application."
        >
            <div className="col-lg-3">
                <form onSubmit={saveAndContinueStage2}>
                    {/*
                    <Select id={form.properties.id} validation={form.properties.regex.expression}
                            doValidate={form.properties.validate} value={property} setValue={setProperty}
                            required={form.properties.required}
                            invalidFeedbackText={form.properties.regex.helpText} label={"Property"}>
                        <option disabled={true} value={""}>Select a property...</option>
                        {
                            properties.map((p: Property) => (
                                <option key={p.uuid + "_p"}
                                        value={p.uuid}>{p.name + " - " + p.address.city + ", " + p.address.stateCode}</option>
                            ))
                        }
                    </Select>
                    */}
                    <Select id={form.units.id} validation={form.units.regex.expression}
                            doValidate={form.units.validate} value={unit} setValue={setUnit}
                            required={form.units.required}
                            invalidFeedbackText={form.units.regex.helpText} label={"Unit"}>
                        <option disabled={true} value={""}>Select a unit...</option>
                        {
                            units.map((u: Unit) => (
                                <option key={u.uuid + "_unit"}
                                        value={u.uuid}>{u.name}</option>
                            ))
                        }
                    </Select>
                    <div className="d-flex justify-content-between gap-2 mt-3">
                        <ButtonNew
                            type={ButtonTypes.SUBMIT}
                            color={ButtonColors.PRIMARY}
                            label="Save & Continue"
                            loading={loading}
                            disabled={!unit}
                        />
                    </div>
                </form>
            </div>
        </ApplicationStageLayout>
    </>);
};

export default Stage1;
