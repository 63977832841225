import * as React from "react";
import {useEffect, useState} from "react";
import './index.css'
import InformationTooltip from "../../InformationTooltip";


type Params = {
    setPlanId: Function,
    setPlanAmount: Function
}
const PlanSelectionCard: React.FunctionComponent<Params> = (p) => {

    const BASIC_PLAN_NAME="basic";
    const PRO_PLAN_NAME="pro";
    const ENTERPRISE_PLAN_NAME="enterprise";
    const BASIC_PLAN_START_PRICE="19.99";
    const PRO_PLAN_START_PRICE="39.99";
    const ENTERPRISE_PLAN_START_PRICE="74.99";

    const [plan, setPlan] = useState("");
    const [nosUnits, setNosUnits] = useState("1");

    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

    useEffect(() => {
        if (plan) {
            p.setPlanId(plan);
            if(plan === BASIC_PLAN_NAME) {
                p.setPlanAmount(BASIC_PLAN_START_PRICE);
            }
            if(plan === PRO_PLAN_NAME) {
                p.setPlanAmount(PRO_PLAN_START_PRICE);
            }
            if(plan === ENTERPRISE_PLAN_NAME) {
                p.setPlanAmount(ENTERPRISE_PLAN_START_PRICE);
            }
        }
    }, [p, plan]);

    const getCardClassName = (p: string) => {
        let clazz = "card   mb-4 rounded-3 shadow-sm";
        if (plan === p) {
            clazz += " border-primary"
        }
        return clazz;
    }

    const getCardHeaderClassName = (p: string) => {
        let clazz = "card-header py-3 ";
        if (plan === p) {
            clazz += " text-white bg-primary border-primary"
        }
        return clazz;
    }

    const getBasicPrice = () => {
        let base = 39.99;
        let numberOfUnits = parseInt(nosUnits);
        if (numberOfUnits > 10) {
            return base + numberOfUnits;
        }
        return base;
    }

    const getBasicIntroPrice = () => {
        let base = 19.99;
        let numberOfUnits = parseInt(nosUnits);
        if (numberOfUnits > 10) {
            return base + numberOfUnits;
        }
        return base;
    }

    const getProPrice = () => {
        let base = 79.99;
        let numberOfUnits = parseInt(nosUnits);
        if (numberOfUnits > 20) {
            return base + numberOfUnits * 2
        }
        return base;
    }

    const getProIntroPrice = () => {
        let base = 39.99;
        let numberOfUnits = parseInt(nosUnits);
        if (numberOfUnits > 20) {
            return base + numberOfUnits * 2
        }
        return base;
    }

    const getEnterprisePrice = () => {
        let base = 149.99;
        let numberOfUnits = parseInt(nosUnits);
        if (numberOfUnits > 30) {
            return base + numberOfUnits * 2
        }
        return base;
    }

    const getEnterpriseIntroPrice = () => {
        let base = 74.99;
        let numberOfUnits = parseInt(nosUnits);
        if (numberOfUnits > 30) {
            return base + numberOfUnits * 2
        }
        return base;
    }

    return <>
        <div className={"row justify-content-md-center"}>
            <div className={"col-lg-6 mb-3"}>
                <h4>Plan Selection</h4>
            </div>
        </div>


        <div className={"row"}>
            <div className={"col text-center mb-3"}>
                <div className={"form-inline"}>
                    <label style={{display: 'inline'}} htmlFor="nosUnits"
                           className="col-sm-2 col-form-label me-2">Number
                        of units:</label>
                    <input id="nosUnits" style={{width: 90}}
                           className={"form-control unit-size-form"} value={nosUnits}
                           required={true}
                           type={"number"}
                           min={1}
                           onChange={e => {
                               setNosUnits(e.target.value)
                           }}/>
                </div>
            </div>

        </div>

        <div className={"row"}>
            <div className="col-md-4">
                <div className={getCardClassName("basic")}>
                    <div className={getCardHeaderClassName("basic")}>
                        <h4 className="my-0 fw-normal">Basic</h4></div>
                    <div className="card-body plan-card">
                        <h4 className={"text-muted"}>
                            <del>{currencyFormat.format(getBasicPrice())}</del>
                        </h4>
                        <h2>{currencyFormat.format(getBasicIntroPrice())}</h2>
                        <h6><small className="text-muted fw-light">per month for the first three months.
                            Billed monthly.</small></h6>
                        <table style={{width: "100%"}}>
                            <tbody>
                            <tr>
                                <td className={"text-start pb-1"} colSpan={2}><strong>Basic plan includes:</strong></td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>Online Payments Service</td>
                                <td><InformationTooltip tooltipText={"Accept Tenant payments via ACH or Credit Card."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>Tenant Portal</td>
                                <td><InformationTooltip
                                    tooltipText={"Portal for Tenants to apply, make payments, and track their lease."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>Property Accounting</td>
                                <td><InformationTooltip
                                    tooltipText={"Track property financials with accounting books and reports per property."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>Applications and E-signing</td>
                                <td><InformationTooltip
                                    tooltipText={"Accept online applications, screen Tenants, and sign documents electronically. Additional fees may apply."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>Tenant Communication</td>
                                <td><InformationTooltip
                                    tooltipText={"Send and receive Tenant messages from the Tenant portal."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>File Upload</td>
                                <td><InformationTooltip
                                    tooltipText={"Attach all important documents directly to the Leases e.g. lease agreements, " +
                                        "eviction notices, inspection reports, and more."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>Basic Support</td>
                                <td><InformationTooltip tooltipText={"Email support."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>10 Units free</td>
                                <td><InformationTooltip tooltipText={"Units beyond 10 will be charged $1/month."}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="card-body">
                        <button type="button" className="w-100 btn btn-lg btn-primary" onClick={(e) => {
                            e.preventDefault()
                            setPlan(BASIC_PLAN_NAME);
                        }}>Select
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className={getCardClassName("pro")}>
                    <div
                        className={getCardHeaderClassName("pro")}>
                        <h4 className="my-0 fw-normal">Pro</h4></div>
                    <div className="card-body plan-card">
                        <div className="card-title pricing-card-title">
                            <h4 className={"text-muted"}>
                                <del>{currencyFormat.format(getProPrice())}</del>
                            </h4>
                            <h2>{currencyFormat.format(getProIntroPrice())}</h2>
                            <h6><small className="text-muted fw-light">per month for the first three months.
                                Billed monthly.</small></h6>
                        </div>

                        <table>
                            <tbody>
                            <tr>
                                <td className={"text-start pb-1"} colSpan={2}><strong>Everything in the Basic
                                    Plan</strong></td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>Maintenance Ticketing System</td>
                                <td><InformationTooltip tooltipText={"Manage and track Tenant maintenance requests."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>User Role Based Access</td>
                                <td><InformationTooltip tooltipText={"Limit functionality for some users."}/></td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>Automated Communications</td>
                                <td><InformationTooltip
                                    tooltipText={"Automate communication of payment reminders, past-due notices, and more."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start"}>Premium Support</td>
                                <td><InformationTooltip tooltipText={"Premium chat, email, zoom, and phone support."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>20 units free</td>
                                <td><InformationTooltip tooltipText={"Units beyond 30 will ."}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                    <div className="card-body">
                        <button type="button" className="w-100 btn btn-lg btn-primary" onClick={(e) => {
                            e.preventDefault()
                            setPlan(PRO_PLAN_NAME);
                        }}>Select
                        </button>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className={getCardClassName("enterprise")}>
                    <div className={getCardHeaderClassName("enterprise")}>
                        <h4 className="my-0 fw-normal">Enterprise</h4>
                    </div>
                    <div className="card-body plan-card">
                        <h4 className={"text-muted"}>
                            <del>{currencyFormat.format(getEnterprisePrice())}</del>
                        </h4>
                        <h2>{currencyFormat.format(getEnterpriseIntroPrice())}</h2>
                        <h6><small className="text-muted fw-light">per month for the first three months.
                            Billed monthly.</small></h6>
                        <table>
                            <tbody>
                            <tr>
                                <td className={"text-start pb-1"} colSpan={2}><strong>Everything in the Pro
                                    Plan</strong></td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>Free ACH Payment for Tenants</td>
                                <td><InformationTooltip tooltipText={"No incoming ACH fees for Tenant payments."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>API Access</td>
                                <td><InformationTooltip
                                    tooltipText={"Build apps that integrate directly with our APIs."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>Unlimited Users</td>
                                <td><InformationTooltip tooltipText={"Get unlimited management portal user accounts."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>1000GB of Data Storage</td>
                                <td><InformationTooltip
                                    tooltipText={"Search and store up to 1000GB of files on a lease."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>Priority Premium Support</td>
                                <td><InformationTooltip tooltipText={"Unlimited support and priority response times."}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"text-start pb-1"}>30 units free</td>
                                <td><InformationTooltip tooltipText={"Units beyond 30 will ."}/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="card-body">
                        <a href="/book-a-demo">
                            <button type="button" className="w-100 btn btn-lg btn-primary" onClick={(e) => {
                                e.preventDefault()
                                setPlan(ENTERPRISE_PLAN_NAME);
                            }}>Select
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </>
}

export default PlanSelectionCard;