import React, { useEffect, useState,useRef} from "react";
import PageLayout from "../../../Layouts/PageLayout";
import { getOwnersWithProperties } from "../../../Api/PortfolioApi";
import { getPortfolioId } from "../../../helpers";
import { Business, Property } from "../../../types";
import {TabulatorFull as Tabulator} from "tabulator-tables";

const ViewOwnerDirectory: React.FunctionComponent = () => {
    const [owners, setOwners] = useState<Business[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const tableRef = useRef<HTMLDivElement>(null); 
    
  
    useEffect(() => {
      getOwnersWithProperties(getPortfolioId())
        .then((oz) => setOwners(oz))
        .catch((e) => setErrorMessage(e.message));
    }, []);
  
    useEffect(() => {
      if (tableRef.current && owners.length) {
       const columns = [
        { title: "Owner Name", field: "ownerName", sorter: "string" as const, formatter: () => "",}, 
        { title: "Property Name", field: "propertyName", sorter: "string" as const}, 
        { title: "Address", field: "address" },
        { title: "Reserve Amount", field: "reserveAmount", sorter: "number" as const }
      ];
  
      const flattenedData: any[] = [];

      for (const owner of owners) {
          if (owner.properties) {
              for (const property of owner.properties) {
                  const flatProperty = {
                    ownerName: owner.name || owner.person?.lastName || "Unknown Last Name",
                    propertyName: property.name || 'Unnamed Property', 
                      address: `${property.address.addressLine1}, ${property.address.city}, ${property.address.stateCode} ${property.address.zip}`,
                      reserveAmount: property.reserveAmount,
                      ownerUuid: owner.uuid
                  };
                  flattenedData.push(flatProperty);
              }
          }
      }
      
       
        const table = new Tabulator(tableRef.current, {
          data: flattenedData, 
          layout: "fitDataStretch",
          columns: columns,
          pagination: false,
          groupBy: "ownerName", 
          initialSort: [{ column: "ownerName", dir: "asc" }],
          groupHeader: (value: string, count: number) => {
            const ownerUuid = flattenedData.find(owner => owner.ownerName === value)?.ownerUuid;
            return `<a href="/p/m/customers/businesses/${ownerUuid}" >${value} (${count} items)</a>`;
        }
        });
  
        const exportButton = document.createElement("button");
        exportButton.textContent = "Export to PDF";
        exportButton.className = "btn btn-primary mb-3";
        exportButton.onclick = () => {
            const groups = table.getGroups();
            groups.forEach(group => group.show());
          table.download("pdf", "OwnerDirectory.pdf", {
            orientation: "portrait", 
            title: "Owner Directory Report"
          });
        };
  
        if (tableRef.current?.parentElement) {
          tableRef.current.parentElement.insertBefore(exportButton, tableRef.current);
        }
  
        return () => {
          table.destroy(); 
        };
      }
    }, [owners]);
  
    return (
      <PageLayout title={"Owner Directory"}>
        <div className="row">
          <div className="col-md-12">
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <div ref={tableRef}></div> {}
          </div>
        </div>
      </PageLayout>
    );
  };
  
  export default ViewOwnerDirectory;