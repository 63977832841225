import React from "react";

interface Props {
    show?: boolean
}

const LoadingOverlay: React.FunctionComponent<Props> = (p) => {
    return <>
        <div className="h-100 w-100">
            <div className="d-flex justify-content-center align-items-center w-100 h-100 my-2">
                <div className="spinner-border">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </>
}

export default LoadingOverlay