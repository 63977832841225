import React from "react";
import {Link} from "react-router-dom";

export interface Breadcrumb {
    label: string,
    path: string
}

interface Props {
    breadcrumbs: Breadcrumb[] | undefined
}

const Breadcrumbs: React.FunctionComponent<Props> = (p) => {
    return <>
        <nav className={"d-print-none"} aria-label="breadcrumb" style={{height:'3vh'}}>
            <ol className="breadcrumb">
                <li key={"bc-home"} className="breadcrumb-item"><Link to={"/"}>Home</Link></li>
                {p.breadcrumbs && p.breadcrumbs.map(b => (
                        <li key={"bc-" + b.label} className="breadcrumb-item"><Link to={b.path}>{b.label}</Link></li>
                    ))
                }
            </ol>
        </nav>
    </>
}

export default Breadcrumbs