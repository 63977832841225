import {MenuItem} from "../types";
import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod} from "../api";

type getMenuItems = (id: string) => Promise<MenuItem[]>;

export const getMenuItems: getMenuItems = (async (id: string) => {
    const path = "/portfolios/" + getPortfolioId() + "/menus/" + id;
    return fetchWithAuth(path, HttpMethod.GET);
})
