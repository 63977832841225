import {
    DateRegex,
    DayOfMonthRegex,
    DollarAmountRegex,
    FormElementValidation, formInputValidate, GenericInputRegex,
    GenericNumberRegex, GenericSelectRegex
} from "../../../../../types";

import {FormBuilderField, FormFieldType} from "../../../../Form/FieldBuilder/FormFieldBuilder";

interface CreateRecurringTransactionForm {
    startDate: FormBuilderField,
    stopDate: FormBuilderField,
    amount: FormBuilderField,
    dayOfMonth: FormBuilderField,
    description: FormBuilderField,
    type: FormBuilderField,
}

const CreateRecurringTransactionForm: CreateRecurringTransactionForm = {
    startDate: {
        type: FormFieldType.DATE,
        element: {
            id: 'startDate',
            required: true,
            validate: formInputValidate,
            regex: DateRegex,
        },
        label: "Start Date",
        value: undefined,
        setValue: () => {}
    },
    stopDate: {
        type: FormFieldType.DATE,
        element: {
            id: 'stopDate',
            required: true,
            validate: formInputValidate,
            regex: DateRegex,
        },
        label: "Stop Date",
        value: undefined,
        setValue: () => {}
    },
    description: {
        type: FormFieldType.INPUT,
        element: {
            id: "description",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "Description",
        value: undefined,
        setValue: () => {}
    },
    amount: {
        type: FormFieldType.INPUT,
        element: {
            id: "amount",
            required: true,
            validate: formInputValidate,
            regex: DollarAmountRegex,
        },
        label: "Amount",
        value: undefined,
        setValue: () => {}
    },
    dayOfMonth: {
        type: FormFieldType.INPUT,
        element: {
            id: "dayOfMonth",
            required: true,
            validate: formInputValidate,
            regex: DayOfMonthRegex,
        },
        label: "Day of Month",
        value: undefined,
        setValue: () => {}
    },
    type: {
        type: FormFieldType.SELECT,
        element: {
            id: "type",
            required: true,
            validate: formInputValidate,
            regex: GenericSelectRegex,
        },
        defaultSelectOptions: [{
            value: '',
            label: 'Please select...',
            disabled: true
        }],
        label: "Type",
        value: undefined,
        setValue: () => {}
    },
}

export default CreateRecurringTransactionForm;
