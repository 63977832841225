import {AppUser} from "../types";
import {fetchWithAuth, HttpMethod} from "../api";
import {getPortfolioId} from "../helpers";

export interface TenantFrontDeskInstruction {
    uuid: string,
    accountId: string,
    comments: string,
    permanent: boolean,
    startDate: string,
    endDate: string,
    createdByManagement: boolean,
    updateBy: AppUser
}

export interface CreateTenantFrontDeskInstruction {
    accountId: string,
    comments: string,
    permanent: boolean,
    startDate?: string,
    endDate?: string,
}

export interface UpdateTenantFrontDeskInstruction {
    uuid: string,
    accountId: string,
    comments: string,
    permanent: boolean,
    startDate?: string,
    endDate?: string,
}

type GetTenantFrontDeskInstructions = (propertyId: string, accountId: string) => Promise<TenantFrontDeskInstruction[]>;
type GetTenantFrontDeskInstruction = (propertyId: string, accountId: string, instructionId: string) => Promise<TenantFrontDeskInstruction>;
type PostTenantFrontDeskInstruction = (propertyId: string, tenantFrontDeskInstruction: CreateTenantFrontDeskInstruction) => Promise<TenantFrontDeskInstruction>;
type PutTenantFrontDeskInstruction = (propertyId: string, tenantFrontDeskInstruction: UpdateTenantFrontDeskInstruction) => Promise<TenantFrontDeskInstruction>;
type DeleteTenantFrontDeskInstruction = (propertyId: string, accountId: string, instructionId: string) => Promise<void>;

const pathBuilder = (propertyId: string, accountId: string, extension = ""): string => {
    return "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/accounts/" + accountId + "/front-desk-instructions/" + extension;
}

export const getTenantFrontDeskInstructions: GetTenantFrontDeskInstructions = (propertyId, accountId) => {
    const path = pathBuilder(propertyId, accountId);
    return fetchWithAuth(path, HttpMethod.GET);
}

export const getTenantFrontDeskInstruction: GetTenantFrontDeskInstruction = (propertyId, accountId, instructionId) => {
    const path = pathBuilder(propertyId, accountId, instructionId);
    return fetchWithAuth(path, HttpMethod.GET);
}

export const createTenantFrontDeskInstruction: PostTenantFrontDeskInstruction = (propertyId, tenantFrontDeskInstruction) => {
    const path = pathBuilder(propertyId, tenantFrontDeskInstruction.accountId);
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(tenantFrontDeskInstruction));
}

export const updateTenantFrontDeskInstruction: PutTenantFrontDeskInstruction = (propertyId, tenantFrontDeskInstruction) => {
    const path = pathBuilder(propertyId, tenantFrontDeskInstruction.accountId, tenantFrontDeskInstruction.uuid);
    return fetchWithAuth(path, HttpMethod.PUT, JSON.stringify(tenantFrontDeskInstruction));
}

export const deleteTenantFrontDeskInstruction: DeleteTenantFrontDeskInstruction = (propertyId, accountId, instructionId) => {
    const path = pathBuilder(propertyId, accountId, instructionId);
    return fetchWithAuth(path, HttpMethod.DELETE);
}
