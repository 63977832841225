import {ProgressPathStep} from "../index";

const AddApplicationPathSteps: ProgressPathStep[] = [
    {
        label: 'Unit Selection',
        step: 1,
    },
    {
        label: 'Applicants',
        step: 2,
    },
    {
        label: 'Address History',
        step: 3,
    },
    {
        label: 'Employment',
        step: 4,
    },
    {
        label: 'Pets',
        step: 5,
    },
    {
        label: 'Vehicles',
        step: 6,
    },
    {
        label: 'Emergency Contact',
        step: 7,
    },
    {
        label: 'Review',
        step: 8,
    },
    {
        label: 'Submit',
        step: 9,
    },
]

export default AddApplicationPathSteps