import * as React from 'react';
import './index.css'
import {Fragment, useContext, useEffect, useState} from 'react';
import {ARAccount, ARAgingSummary} from "../../types";
import moment from "moment";
import {Link} from "react-router-dom";
import {getArAgingSummary} from "../../Api/PropertyApi";
import LoadingOverlay from "../LoadingOverlay";
import {PropertyIdContext} from "../../Containers/PrivateMainContainer";

const ViewARAgingSummary: React.FunctionComponent = (p) => {

    const propertyId = useContext(PropertyIdContext)

    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    const [arSummary, setArSummary] = useState<ARAgingSummary>();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [includeCurrentAccounts, setIncludeCurrentAccounts] = useState(false);
    const [selectedSortColumn, setSelectedSortColumn] = useState(1);
    const [sortDirection, setSortDirection] = useState(0);
    const [accounts, setAccounts] = useState<ARAccount[]>([]);


    useEffect(() => {
        setLoading(true);
        getArAgingSummary()
            .then(setArSummary)
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }, []);

    function getSortLHS() {
        if (sortDirection === 0) {
            return -1
        }
        return 1;
    }

    function getSortRHS() {
        if (sortDirection === 0) {
            return 1
        }
        return -1;
    }

    useEffect(() => {

        if(arSummary) {
            let az: ARAccount[] = [];
            arSummary.properties.forEach(p => {
                p.accounts.forEach(a => {
                    a.propertyName = p.name;
                    a.propertyId = p.uuid;
                    if (includeCurrentAccounts || a.balance !== a.balanceCurrent) {
                        az.push(a);
                    }

                })
            })
            if(selectedSortColumn === 1) {
               az.sort((a, b) => a.propertyName < b.propertyName ? getSortLHS() : getSortRHS());
            }
            if(selectedSortColumn === 2) {
                az.sort((a, b) => a.unitName < b.unitName ? getSortLHS() : getSortRHS());
            }
            if(selectedSortColumn === 3) {
                az.sort((a, b) => a.balanceCurrent > b.balanceCurrent ? getSortLHS() : getSortRHS());
            }
            if(selectedSortColumn === 4) {
                az.sort((a, b) => a.balance1to30DaysPastDue > b.balance1to30DaysPastDue ? getSortLHS() : getSortRHS());
            }
            if(selectedSortColumn === 5) {
                az.sort((a, b) =>
                    (a.balanceOver90DaysPastDue + a.balance61to90DaysPastDue + a.balance31to60DaysPastDue)
                    >
                    (b.balanceOver90DaysPastDue + b.balance61to90DaysPastDue + b.balance31to60DaysPastDue)  ? getSortLHS() : getSortRHS());
            }
            if(selectedSortColumn === 6) {
                az.sort((a, b) => a.balance > b.balance ? getSortLHS() : getSortRHS());
            }
            setAccounts([...az]);
        }
    }, [arSummary, includeCurrentAccounts, selectedSortColumn, sortDirection])

    function isPastDue(a: ARAccount) {
        if (a.balance1to30DaysPastDue > 0
            || a.balance31to60DaysPastDue > 0
            || a.balance61to90DaysPastDue > 0
            || a.balanceOver90DaysPastDue > 0) {
            return true;
        }
        return false;
    }

    function getAR30PlusTotal(arSummary: ARAgingSummary) {
        return arSummary?.total31to60DaysPastDue + arSummary?.total61to90DaysPastDue + arSummary?.totalOver90DaysPastDue;
    }

    function sortColumn(column: number) {
        if (selectedSortColumn === column) {
            setSortDirection(sortDirection === 0 ? 1 : 0)
        } else {
            setSortDirection(0);
            setSelectedSortColumn(column);
        }

    }

    function getColor(column: number) {
        if (column === selectedSortColumn) {
            return {color: '#0d6efd', marginLeft: 3, marginBottom: 3};
        } else {
            return {color: '999', marginLeft: 3, marginBottom: 3};
        }
    }

    function getTotalColumnIcon() {
        let column = 6;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-down" viewBox="0 0 16 16">
                <path d="M12.438 1.668V7H11.39V2.684h-.051l-1.211.859v-.969l1.262-.906h1.046z"/>
                <path fillRule="evenodd"
                      d="M11.36 14.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.835 1.973-1.835 1.09 0 2.063.636 2.063 2.687 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>;
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-up-alt" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>;
        }
    }

    function getTableHeaderStyle() {
        return {display: 'flex', alignItems: 'flex-end'};
    }

    function getPropertyColumnIcon() {
        let column = 1;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-alpha-up-alt" viewBox="0 0 16 16">
                <path d="M12.96 7H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645z"/>
                <path fillRule="evenodd"
                      d="M10.082 12.629 9.664 14H8.598l1.789-5.332h1.234L13.402 14h-1.12l-.419-1.371zm1.57-.785L11 9.688h-.047l-.652 2.156z"/>
                <path
                    d="M4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-alpha-down" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371zm1.57-.785L11 2.687h-.047l-.652 2.157z"/>
                <path
                    d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>
        }
    }

    function getUnitColumnIcon() {
        let column = 2;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-alpha-up-alt" viewBox="0 0 16 16">
                <path d="M12.96 7H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645z"/>
                <path fillRule="evenodd"
                      d="M10.082 12.629 9.664 14H8.598l1.789-5.332h1.234L13.402 14h-1.12l-.419-1.371zm1.57-.785L11 9.688h-.047l-.652 2.156z"/>
                <path
                    d="M4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-alpha-down" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371zm1.57-.785L11 2.687h-.047l-.652 2.157z"/>
                <path
                    d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>
        }
    }

    function getCurrentColumnIcon() {
        let column = 3;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-down" viewBox="0 0 16 16">
                <path d="M12.438 1.668V7H11.39V2.684h-.051l-1.211.859v-.969l1.262-.906h1.046z"/>
                <path fillRule="evenodd"
                      d="M11.36 14.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.835 1.973-1.835 1.09 0 2.063.636 2.063 2.687 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>;
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-up-alt" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>;
        }
    }

    function getOneThirtyColumnIcon() {
        let column = 4;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-down" viewBox="0 0 16 16">
                <path d="M12.438 1.668V7H11.39V2.684h-.051l-1.211.859v-.969l1.262-.906h1.046z"/>
                <path fillRule="evenodd"
                      d="M11.36 14.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.835 1.973-1.835 1.09 0 2.063.636 2.063 2.687 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>;
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-up-alt" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>;
        }
    }

    function get30PlusColumnIcon() {
        let column = 5;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-down" viewBox="0 0 16 16">
                <path d="M12.438 1.668V7H11.39V2.684h-.051l-1.211.859v-.969l1.262-.906h1.046z"/>
                <path fillRule="evenodd"
                      d="M11.36 14.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.835 1.973-1.835 1.09 0 2.063.636 2.063 2.687 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>;
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-up-alt" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>;
        }
    }

    return (<>
        {(!loading && arSummary) ?
            <div className={"row"}>
                <div className={"col-12 mt-3"} hidden={loading}>
                    <div className={"text-center"}>
                        <h3 className="mb-4">Delinquent Leases and Aging Summary</h3>
                        <hr/>
                        <h6>As of {moment().format("MMMM Do, YYYY")}</h6>
                        <hr/>
                    </div>

                </div>
                <div className={"col-12 mt-3"}>
                    <label className="form-check-label" htmlFor={"showCurrent"}>Include current accounts</label>
                    <input id="showCurrent" className={"ms-3 form-check-input"}
                           type="checkbox"
                           onChange={(event) => {
                               setIncludeCurrentAccounts(event.target.checked);
                           }}/>
                </div>
                <div className={"col-12 mt-3"}>
                    <div id="deliquencies" style={{
                        width: '100%',
                        height: 500,
                        overflow: 'auto'
                    }}>
                        <table className="table table-sm">
                            <thead style={{
                                position: 'sticky',
                                top: 0, backgroundColor: "white"
                            }}>
                            <tr style={{backgroundColor: '#CCC'}}>
                                <th scope="col">Account</th>
                                <th scope="col" onClick={e => sortColumn(1)}>
                                    Property{getPropertyColumnIcon()}
                                </th>
                                <th scope="col" onClick={e => sortColumn(2)}>
                                    Unit{getUnitColumnIcon()}
                                </th>
                                <th scope="col">Address</th>
                                <th scope="col" onClick={e => sortColumn(3)}>
                                    Current{getCurrentColumnIcon()}
                                </th>
                                <th scope="col" onClick={e => sortColumn(4)}>
                                    1-30 Days{getOneThirtyColumnIcon()}
                                </th>
                                <th scope="col" onClick={e => sortColumn(5)}>
                                    30+{get30PlusColumnIcon()}
                                </th>
                                <th scope="col" onClick={e => sortColumn(6)}>
                                    <span style={getTableHeaderStyle()}>
                                    Total{getTotalColumnIcon()}
                                    </span>
                                </th>
                            </tr>
                            <tr className={"fw-bold"} style={{backgroundColor: '#CCC'}}>
                                <td colSpan={4}>Total in USD</td>
                                <td>{currencyFormat.format(arSummary?.totalCurrent || 0)}</td>
                                <td>{currencyFormat.format(arSummary?.total1to30DaysPastDue || 0)}</td>
                                <td>{currencyFormat.format(getAR30PlusTotal(arSummary) || 0)}</td>
                                <td>{currencyFormat.format(arSummary?.total || 0)}</td>
                            </tr>
                            </thead>
                            <tbody>


                            {
                                accounts.map(a => (
                                    <Fragment key={a.uuid}>
                                        <tr key={a.uuid} className={isPastDue(a) ? "table-danger" : ""}>
                                            <td><Link
                                                to={"/p/m/customers/leases/" + a.propertyId + "/view/" + a.accountId}>{a.accountNumber}</Link>
                                            </td>
                                            <td>{a.propertyName}</td>
                                            <td>{a.unitName}</td>
                                            <td>{a.address}</td>
                                            <td>{currencyFormat.format(a.balanceCurrent || 0)}</td>
                                            <td>{currencyFormat.format(a.balance1to30DaysPastDue || 0)}</td>
                                            <td>{currencyFormat.format(a.balance31to60DaysPastDue + a.balance61to90DaysPastDue + a.balanceOver90DaysPastDue || 0)}</td>
                                            <td>{currencyFormat.format(a.balance || 0)}</td>
                                        </tr>
                                        {a.applicants.map(ap => (
                                            <tr key={ap.uuid}>
                                                <td></td>
                                                <td>{ap.name}</td>
                                                <td>{ap.type}</td>
                                                <td>{ap.phone}</td>
                                                <td colSpan={4}></td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan={8} style={{borderBottom: '1px solid black'}}></td>
                                        </tr>

                                    </Fragment>
                                ))
                            }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            :
            <LoadingOverlay/>
        }
    </>);
};

export default ViewARAgingSummary;
