import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Input from "../../../Form/Input";
import moment from "moment";

import LoadingOverlay from "../../../LoadingOverlay";
import {
    Autopay,
    DayOfMonthRegex,
    DollarAmountRegex,
    FormElementValidation,
    formInputValidate,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex,
    LateFeeDto,
    LeaseTransaction,
    PaymentonLease,
    TransactionAccount
} from "../../../../types";
import ViewLeaseCustomTable from "../../../Table/ViewLeaseCustomTable";
import {
    editTransactionOnLease,
    getLease,
    getTransactionAccountsByLease,
    updateTermsOnLease,
    voidTransactionOnLease
} from "../../../../Api/LeaseApi";
import Card from "../../../Card";
import LateFeeConfig from '../../../../Pages/LateFeeConfig';
import Alert, {AlertType} from '../../../Alert';
import DateInput from "../../../Form/Date";
import Select from "../../../Form/Select";

type OverviewParams = {
    currentView: string
}

const Overview: React.FunctionComponent<OverviewParams> = (p) => {
    const { leaseId } = useParams();

    const [leaseLoading, setLeaseLoading] = useState(false);
    const [paymentsLoading, setPaymentsLoading] = useState(false);
    const [autopayLoading, setAutopayLoading] = useState(false);
    const currencyFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [isMonthToMonth, setIsMonthToMonth] = useState<boolean>(false);
    const [monthlyAmount, setMonthlyAmount] = useState("");
    const [depositAmount, setDepositAmount] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [transactions, setTransactions] = useState<LeaseTransaction[]>();
    const [transactionSummaries, setTransactionSummaries] = useState<LeaseTransactionParent[]>([])
    const [balance, setBalance] = useState(0);
    const [autopay, setAutopay] = useState<Autopay>();
    const [paymentDetailSelect, setPaymentDetailSelect] = useState("");
    const [isPastDue, setIsPastDue] = useState(false);
    const [pastDueDate, setPastDueDate] = useState("");
    const [pastDueAmount, setPastDueAmount] = useState(0);
    const [lateFeeDtoList, setLateFeeDtoList] = useState<LateFeeDto[]>();
    const [leaseIdentificationId, setLeaseIdentificationId] = useState("");
    const [accounts, setAccounts] = useState<TransactionAccount[]>([]);
    const [updateTerms, setUpdateTerms] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(AlertType.SUCCESS);
    const [leaseUUID, setLeaseUUID] = useState("");
    const [status, setStatus] = useState("");


    const [editTransactionId, setEditTransactionId] = useState("");
    const [transactionDate, setTransactionDate] = useState("");
    const [transactionDescription, setTransactionDescription] = useState("");
    const [transactionAmount, setTransactionAmount] = useState("");
    const [editTransactionLoading, setEditTransactionLoading] = useState(false);


    useEffect(() => {
        if (p.currentView === "overview") {
            fetchLease(leaseId);
            getTransactionAccountsByLease(leaseId).then((a) => {
                setAccounts(a);
            })
        }
    }, [leaseId, p.currentView]);

    function fetchLease(leaseId: string) {
        setLeaseLoading(true);
        getLease(leaseId)
            .then((data) => {
                setStatus(data.status || "");
                setStartDate(data.startDate || "");
                setEndDate(data.endDate || "");
                setIsMonthToMonth(data.isMonthToMonth);
                setMonthlyAmount(data.monthlyAmount + "" || "");
                setDepositAmount(data.depositAmount + "" || "");
                setDueDate(data.dueDate + "" || "");
                setBalance(data.balance || 0)
                setTransactions(data.transactions);
                setIsPastDue(data.isPastDue);
                setPastDueDate(data.pastDueDate);
                setPastDueAmount(data.pastDueAmount)
                setLateFeeDtoList(data.lateFeeDtoList);
                setLeaseIdentificationId(data.identificationId);
                setLeaseUUID(data.uuid)
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => setLeaseLoading(false));
    }

    type Form = {
        startDate: FormElementValidation,
        endDate: FormElementValidation,
        amount: FormElementValidation,
        depositAmount: FormElementValidation,
        dueDate: FormElementValidation
    }

    const form: Form = {
        startDate: {
            id: "startDate",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        endDate: {
            id: "endDate",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        amount: {
            id: "amount",
            required: true,
            validate: formInputValidate,
            regex: DollarAmountRegex
        },
        depositAmount: {
            id: "depositAmount",
            required: true,
            validate: formInputValidate,
            regex: DollarAmountRegex
        },
        dueDate: {
            id: "dueDate",
            required: true,
            validate: formInputValidate,
            regex: DayOfMonthRegex
        },
    }

    const getPaymentType = (code: string) => {
        switch (code) {
            case "ACH_WEB":
                return "Web authorized bank payment";
            default:
                return "Manually authorized bank payment";
        }
    }

    const doShowAlert = (message: string, type: AlertType) => {
        setShowAlert(true);
        setAlertMessage(message);
        setAlertType(type);
    }

    const doHideAlert = () => {
        setShowAlert(false);
        setAlertMessage("");
    }

    function getBalance(p: LeaseTransactionParent) {
        if (!p.pending) {
            return p.balance >= 0 ? currencyFormat.format(p.balance) : "(" + currencyFormat.format(Math.abs(p.balance)) + ")";
        } else {
            return "";
        }
    }

    function getDateText(p: LeaseTransactionParent) {
        if (!p.pending) {
            return moment(p.date, "YYYY-MM-DD").format("MMMM Do YYYY");
        } else {
            return <i>{moment(p.date, "YYYY-MM-DD").format("MMMM Do YYYY")}* (pending)</i>;
        }
    }

    function getDescriptionText(p: LeaseTransactionParent) {
        if (p.pending) {
            return <i>{p.description}</i>;
        }
        return p.description;
    }

    function onTermSubmit(e: FormEvent) {
        e.preventDefault();
        updateTermsOnLease(leaseId, {
            startDate: startDate,
            endDate: endDate,
            isMonthToMonth: isMonthToMonth,
            dueDate: Number(dueDate),
            status: status
        }).then((t) => {
            setUpdateTerms(false);
            fetchLease(leaseId);
        }).catch((e) => {
            //Throw alert banner on Terms section
            doShowAlert("Issue Updating Lease Terms. If problem persists for over 24hrs please contact support: contact@propertymq.com", AlertType.DANGER)
        });
    }

    function getLeaseTerms() {
        return <>
            <form onSubmit={onTermSubmit} onChange={() => setUpdateTerms(true)}>
                <div className="col-md-12">
                    <h5>Terms</h5>
                </div>
                <div className={"row"}>
                    <div className={"col mb-3"}>
                        <Alert show={showAlert} message={alertMessage} type={alertType} />
                    </div>
                </div>
                <div className={"col-lg-10"}>
                    <div className="row">
                        <div className="col-lg-6 mb-1">
                            <Input id={form.dueDate.id} validation={form.dueDate.regex.expression}
                                doValidate={form.dueDate.validate}
                                value={dueDate}
                                setValue={setDueDate}
                                required={true}
                                disabled={false}
                                invalidFeedbackText={form.dueDate.regex.helpText}
                                type={"number"} label={"Due Date"}
                            />
                        </div>
                        <div className="col-lg-6 mb-1">
                            <Input id={form.startDate.id} validation={form.startDate.regex.expression}
                                doValidate={form.startDate.validate}
                                value={startDate}
                                setValue={setStartDate}
                                required={true}
                                disabled={false}
                                invalidFeedbackText={form.startDate.regex.helpText}
                                type={"date"} label={"Start Date"}
                            />
                        </div>
                        <div className="col-lg-6 mb-1 d-flex align-items-center">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                    id="defaultCheck1"
                                    checked={isMonthToMonth.valueOf()}
                                    disabled={false}
                                    value={String(isMonthToMonth)}
                                    onChange={e => {
                                        let value = e.target.checked;
                                        setIsMonthToMonth(!isMonthToMonth);
                                        if (value) {
                                            setEndDate("");
                                        }
                                    }} />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                    Month to Month?
                                </label>
                            </div>
                        </div>
                        <div className={"col-lg-6 mb-1"}>
                            <Input id={form.endDate.id} validation={form.endDate.regex.expression}
                                doValidate={form.endDate.validate}
                                value={endDate}
                                setValue={setEndDate}
                                required={!isMonthToMonth}
                                disabled={isMonthToMonth}
                                invalidFeedbackText={form.endDate.regex.helpText}
                                type={"date"} label={"End Date"}
                            />
                        </div>
                        <div className={"col-lg-6 mb-3"}>
                            <Select id={"status"} validation={GenericSelectRegex.expression}
                                    doValidate={formSelectValidate} value={status} setValue={setStatus}
                                    required={true} invalidFeedbackText={GenericSelectRegex.helpText} label={"Status"}>
                                <option disabled={true} value={""}>Select one...</option>
                                <option value={"active"}>Active</option>
                                <option value={"closed"}>Closed</option>
                                <option value={"eviction"}>Eviction</option>
                                <option value={"collections"}>Collections</option>
                            </Select>
                        </div>
                    </div>
                    <div hidden={!updateTerms}>
                        <button className={"btn btn-danger"} type={"button"} onClick={() => {
                            setUpdateTerms(false);
                            fetchLease(leaseId)
                        }}>Cancel
                        </button>
                        <button className={"btn btn-primary"} type={"submit"}>Update Lease</button>
                    </div>
                </div>
            </form>
        </>
    }


    function getAmountText(p: LeaseTransactionParent) {
        if (p.pending) {
            return <i>{p.amount > 0 ? currencyFormat.format(p.amount) : "(" + currencyFormat.format(Math.abs(p.amount)) + ")"}</i>
        }
        return p.amount > 0 ? currencyFormat.format(p.amount) : "(" + currencyFormat.format(Math.abs(p.amount)) + ")";
    }

    function showLateFeeConfig() {
        if (lateFeeDtoList) {
            return <>
                <div>
                    <LateFeeConfig lateFeeDtoList={lateFeeDtoList} accountsList={accounts} leaseId={leaseUUID} load={() => fetchLease(leaseUUID)}></LateFeeConfig>
                </div>
            </>
        } else {
            return <>
                <div className='row'>
                    <div className='col-lg-8 mt-3'>
                        <div className="alert alert-danger" role="alert">
                            Issue fetching Late Fee for lease.
                        </div>
                    </div>
                </div>
            </>
        }
    }


    const getAmountComponent = (p: LeaseTransactionParent) => {

        return getAmountText(p);
    }

    const getBalanceComponent = (p: LeaseTransactionParent) => {
        return getBalance(p);
    }

    const getDateComponent = (p: LeaseTransactionParent) => {
        if (p.uuid === editTransactionId) {
            return <DateInput id={"date"}
                label={""}
                required={true}
                invalidFeedbackText={GenericInputRegex.helpText}
                validation={GenericInputRegex.expression}
                doValidate={formInputValidate}
                value={transactionDate}
                setValue={setTransactionDate}
            />
        } else {
            return getDateText(p);
        }
    }

    const getPaymentDetailComponent = (p: LeaseTransactionParent) => {
        if (p.uuid === paymentDetailSelect && p.paymentDetails && p.paymentDetails.method) {
            return <div>
                <div>
                    {getPaymentType(p.paymentDetails?.method)}
                </div>
                <div hidden={p.paymentDetails?.status !== "failed"}>
                    <div className="progress">
                        <div className={"progress-bar bg-danger"} role="progressbar"
                            style={{ width: '100%' }}
                            aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}>
                            Failed
                        </div>
                    </div>
                    <div>
                        The payment failed with
                        error: {p.paymentDetails?.failureDescription}
                    </div>
                </div>
                <div hidden={p.paymentDetails?.status === "failed"}
                    className="progress">
                    <div
                        className={p.paymentDetails?.status === "authorized" ? "progress-bar" : "progress-bar bg-secondary"}
                        role="progressbar"
                        style={{ width: '33.33%' }}
                        aria-valuenow={33.33} aria-valuemin={0} aria-valuemax={100}>
                        Authorized
                    </div>
                    <div
                        className={p.paymentDetails?.status === "at_bank" ? "progress-bar" : "progress-bar bg-secondary"}
                        role="progressbar" style={{ width: '33.33%' }}
                        aria-valuenow={33.33} aria-valuemin={0} aria-valuemax={100}>
                        Awaiting Bank
                    </div>
                    <div
                        className={p.paymentDetails?.status === "settled" ? "progress-bar" : "progress-bar bg-secondary"}
                        role="progressbar"
                        style={{ width: '33.33%' }}
                        aria-valuenow={33.33} aria-valuemin={0} aria-valuemax={100}>
                        Settled
                    </div>
                </div>
                <div>
                    Submitted
                    on {moment(p.paymentDetails?.createdAt, "YYYY-MM-DD").format("MMMM Do YYYY")}.
                    This payment incurred a fee
                    of {currencyFormat.format(p.paymentDetails?.facilitatorFee)} payed
                    by receiver.
                </div>

            </div>
        }
        return <></>
    }

    const getDescriptionComponent = (p: LeaseTransactionParent) => {
        if (p.uuid === editTransactionId) {
            return <Input id={"description"}
                type={"text"}
                label={""}
                required={true}
                invalidFeedbackText={GenericInputRegex.helpText}
                validation={GenericInputRegex.expression}
                doValidate={formInputValidate}
                value={transactionDescription}
                setValue={setTransactionDescription}
            />
        } else {
            return <span>
                {getDescriptionText(p)}
                <button hidden={!p.paymentDetails} className={"btn btn-link"}
                        type={"button"}
                    style={{ padding: 0, verticalAlign: 'unset' }}
                    onClick={(e) => {
                        e.preventDefault();
                        setPaymentDetailSelect(p.uuid);
                    }}>
                    ...(details)
                </button>
                {getPaymentDetailComponent(p)}

            </span>
        }
    }

    const voidTransaction = () => {
        setEditTransactionLoading(true);
        voidTransactionOnLease(leaseId, editTransactionId)
            .then((r) => {
                setEditTransactionId("");
                fetchLease(leaseId);
            })
            .catch(e => console.error(e))
            .finally(() => setEditTransactionLoading(false));
    }

    const getActionComponent = (p: LeaseTransactionParent) => {
        if (p.uuid === editTransactionId && !editTransactionLoading) {
            return <span>
                <button type={"submit"}
                    style={{ color: "#212529", boxShadow: 'none', marginTop: 5, padding: 0 }}
                    className={"btn btn-link"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        className="bi bi-floppy" viewBox="0 0 16 16">
                        <path d="M11 2H9v3h2V2Z" />
                        <path
                            d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0ZM1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5Zm3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4v4.5ZM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5V15Z" />
                    </svg>
                </button>
                <button type={"button"}
                    style={{ color: "#212529", boxShadow: 'none', marginTop: 5, marginLeft: 5, padding: 0 }}
                    className={"btn btn-link"}
                    onClick={(e) => {
                        e.preventDefault();
                        voidTransaction();
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        className="bi bi-trash3" viewBox="0 0 16 16">
                        <path
                            d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                    </svg>
                </button>
                <button type={"button"}
                    style={{ color: "#212529", boxShadow: 'none', marginTop: 5, marginLeft: 5, padding: 0 }}
                    className={"btn btn-link"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        className="bi bi-x-circle" viewBox="0 0 16 16"
                        onClick={(e) => {
                            e.preventDefault();
                            setEditTransactionId("");
                        }}>
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                        <path
                            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                    </svg>
                </button>
            </span>
        }
        if (p.uuid === editTransactionId && editTransactionLoading) {
            return <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        }
        if (p.paymentDetails === undefined) {
            return <svg style={{ marginTop: -5 }}
                onClick={(event) => {
                    event.preventDefault();
                    setTransactionDate(p.date);
                    setTransactionDescription(p.description);
                    setEditTransactionId(p.uuid);
                    setTransactionAmount(Math.abs(p.amount) + "")
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16" fill="currentColor" className="bi bi-pencil"
                viewBox="0 0 16 16">
                <path
                    d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z" />
            </svg>
        }
        return "";
    }

    const onEditTransaction = (e: FormEvent) => {
        if(editTransactionId && leaseId) {
            e.preventDefault();
            doHideAlert();
            editTransactionOnLease(leaseId, editTransactionId, {
                amount: parseFloat(transactionAmount),
                date: transactionDate,
                description: transactionDescription
            })
                .then((r) => {
                    setEditTransactionId("");
                    fetchLease(leaseId);
                })
                .catch((e) => {
                    doShowAlert(e.message, AlertType.DANGER);
                })
        }
    }

    type LeaseTransactionParent = {
        uuid: string,
        description: string,
        amount: number,
        balance: number,
        date: string,
        paymentDetails: PaymentonLease,
        pending: boolean,
        transactions: LeaseTransaction[]
    }

    useEffect(() => {
        if (transactions) {
            const map = new Map<string, LeaseTransaction[]>();
            transactions.forEach(t => {
                let value = map.get(t.parentId);
                if (value) {
                    value.push(t);
                } else {
                    map.set(t.parentId, [t]);
                }

            })
            const toReturn: LeaseTransactionParent[] = [];
            map.forEach((i) => {
                if (i && i.length === 1) {
                    let lt = i[0];
                    if (lt) {
                        toReturn.push({
                            amount: (lt?.debit || 0) - (lt?.credit || 0),
                            balance: lt?.balance,
                            date: lt?.date,
                            description: lt?.description,
                            transactions: i,
                            uuid: lt.uuid,
                            paymentDetails: lt.paymentDetails,
                            pending: lt.pending
                        })
                    }
                } else if (i && i.length >= 1) {
                    let lt = i.reduce((accum, current, index) => {
                        if (index >= 0) {
                            accum.credit += current.credit;
                            accum.debit += current.debit;
                        }
                        return accum;

                    })
                    toReturn.push({
                        amount: (lt?.debit || 0) - (lt?.credit || 0),
                        balance: lt?.balance,
                        date: lt?.date,
                        description: lt?.description,
                        transactions: i,
                        uuid: lt.uuid,
                        paymentDetails: lt.paymentDetails,
                        pending: lt.pending
                    })
                }
            })
            setTransactionSummaries([...toReturn]);
        }
    }, [transactions]);

    const getTransactionComponent = () => {
        if (transactions) {
            return transactionSummaries.map((p, index) => (
                <tr key={"trans_" + index}>
                    <td>{getDateComponent(p)}</td>
                    <td>
                        {getDescriptionComponent(p)}
                    </td>
                    <td>
                        {getAmountComponent(p)}
                    </td>
                    <td className={"text-end"}>
                        {getBalanceComponent(p)}
                    </td>
                    <td>
                        {getActionComponent(p)}
                    </td>
                </tr>
            ))
        }
        return <></>;
    }

    return (leaseLoading ? <LoadingOverlay /> : <>
        <Card title={"Id: " + leaseIdentificationId}>
            <h5>Balance: {balance >= 0 ? currencyFormat.format(balance) : "(" + currencyFormat.format(Math.abs(balance)) + ")"}</h5>
            <div hidden={!isPastDue} className={"col-lg-8 mt-3"}>
                <div className="alert alert-danger" role="alert">
                    This lease is past due as of {moment(pastDueDate, "YYYY-MM-DD").format("MMMM Do YYYY")} with a late balance of {currencyFormat.format(pastDueAmount)}.
                </div>
            </div>
            <hr />
            <div className="row">
                {getLeaseTerms()}
            </div>
            <hr className={"mt-3"} />
            <div className={"row"}>

                <div className={"row"}>
                    {showLateFeeConfig()}
                </div>
            </div>
            <hr />
            <div className={"row"}>
                <div className="col-md-12">
                    <h5><span>Transaction History</span>
                    </h5>
                </div>
                <div className={"col-md-12"}>
                    <div className={"table-responsive"}>
                        <form onSubmit={onEditTransaction}>
                            <ViewLeaseCustomTable defaultPaginationSize={10}>
                                {
                                    getTransactionComponent()
                                }
                            </ViewLeaseCustomTable>
                        </form>
                        {paymentsLoading && <LoadingOverlay />}
                    </div>
                </div>
            </div>
        </Card>
    </>);
};

export default Overview;
