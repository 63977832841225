import React from "react";

interface Props {
    title: string,
}

const Card: React.FunctionComponent<Props> = (p) => {
    return <>
        <div className="card border-0">
            <h5
                className="card-header mb-2"
                style={{
                    boxShadow: "-0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.075)"
                }}
            >{p.title}</h5>
            <div className="card-body"
                 style={{
                     borderWidth: "0 0 2px 2px",
                     borderStyle: "solid",
                     borderColor: "var(--border-color)",
                     padding: "0 0 .75rem .75rem",
                     boxShadow: "-0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.075)"
                 }}
            >
                {p.children}
            </div>
        </div>
    </>
}

export default Card