import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import Table from "../../../Table";
import {RecurringTransaction, TransactionAccount} from "../../../../types";
import {useParams} from "react-router";
import CreateRecurringTransaction from "./Add";
import {
    deleteRecurringTransactionsForLease,
    getRecurringTransactionsForLease,
    getTransactionAccountsByLease
} from "../../../../Api/LeaseApi";
import Card from "../../../Card";

type LeaseRecurringTransactionParam = {
    currentView: string,
    done: Function
}

const LeaseRecurringTransactions: React.FunctionComponent<LeaseRecurringTransactionParam> = (p) => {

    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    const {leaseId} = useParams();

    const [recurringTransactions, setRecurringTransactions] = useState<RecurringTransaction[]>([]);
    const [accounts, setAccounts] = useState<TransactionAccount[]>([])
    const [loading, setLoading] = useState(false);
    const [showAdd, setShowAdd] = useState(false);

    const doDelete = (l: string) => {
        setLoading(true)
        deleteRecurringTransactionsForLease(leaseId, l)
            .then(() => {
                load();
            })
            .catch( (e) => {console.error(e)})
            .finally(() => setLoading(false));
    }

    const getAccountName = (id: string) => {
        let result = accounts.filter((a) => a.uuid === id);
        if(result && result.length === 1) {
            return result[0].name;
        }
        return "";

    }

    const load = useCallback(() => {
        setLoading(true);
        if (leaseId) {
            Promise.all([
                getRecurringTransactionsForLease(leaseId),
                getTransactionAccountsByLease(leaseId)

            ]).then((r) => {
                setRecurringTransactions(r[0]);
                setAccounts(r[1]);
            })
        }
    }, [leaseId])

    useEffect(() => {
        if (p.currentView === "recurring_transaction") {
            load();
        }
    }, [p.currentView, load]);


    return (<>
        <Card title="Recurring Transactions">
            {showAdd ?
                <CreateRecurringTransaction
                    done={() => {
                        load();
                        setShowAdd(false);
                    }}/>
                :
                <button type={"button"} className={"btn btn-primary"}
                        onClick={() => {
                            setShowAdd(true);
                        }}
                >Add Transaction
                </button>
            }
            <hr />
            <Table headers={["Description", "Type", "Amount", "Start Date", "Stop Date", "Day of Month to Apply", ""]}
                   defaultPaginationSize={10}>
                {recurringTransactions.map((a) => (
                    <tr key={a.uuid}>
                        <td>{a.description}</td>
                        <td>{getAccountName(a.accountUuid)}</td>
                        <td>{currencyFormat.format(a.amount)}</td>
                        <td>{moment(a.startDate, "YYYY-MM-DD").format("MMMM Do YYYY")}</td>
                        <td>{a.endDate ? moment(a.endDate, "YYYY-MM-DD").format("MMMM Do YYYY") : ""}</td>
                        <td>{a.dayOfMonth}</td>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                 fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16"
                                 onClick={() => {
                                    if (a.uuid) {
                                     doDelete(a.uuid);
                                    }
                                 }}>
                                <path
                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path
                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>

                        </td>
                    </tr>
                ))}
            </Table>
        </Card>
    </>);
};

export default LeaseRecurringTransactions;
