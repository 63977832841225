import React from "react";
import {APP_PORTFOLIO_LANDING} from "../../../types";
import {Link} from "react-router-dom";

interface Props {
    logoUrl: string
}

const CompanyLogo: React.FunctionComponent<Props> = (p) => {
    return <>
        <Link className="navbar-brand" to={APP_PORTFOLIO_LANDING}>
            <img width={200} src={p.logoUrl}/>
        </Link>
    </>
}

export default CompanyLogo