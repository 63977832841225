import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod, serializeFilter} from "../api";

export type InboundCommunication = {
    id: string,
    date: string,
    type: string,
    fromNumber: string,
    toNumber: string,
    message: string,
    audioUrl: string
}

export interface InboundCommunicationRequestFilter {
    fromDate?: string,
    endDate?: string
}

type getAllInboundCommunications = (propertyId: string, filter: InboundCommunicationRequestFilter) => Promise<InboundCommunication[]>;


const INBOUND_COMMUNICATION_API_BASE_URL = (propertyId: string, pathExtention: string) => {
    return "/portfolios/" + getPortfolioId()
        + "/properties/" + propertyId + "/communications" + pathExtention
}


export const getAllInboundCommunications: getAllInboundCommunications = (async (propertyId, filter) => {
    return fetchWithAuth(INBOUND_COMMUNICATION_API_BASE_URL(propertyId, "?" + serializeFilter(filter)), HttpMethod.GET);
});
