import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {
    DollarAmountRegex,
    FloorPlanService,
    FormElementValidation,
    formInputValidate,
    formSelectValidate,
    GenericInputRegex,
    GenericNumberRegex,
    GenericSelectRegex,
    Property,
    PropertyNameRegex,
    Term
} from "../../../types";
import {useLocation, useParams} from "react-router";
import Input from "../../Form/Input";
import TextArea from "../../Form/TextArea";
import {AlertProps, AlertType} from "../../Alert";
import {Link} from "react-router-dom";
import LoadingOverlay from "../../LoadingOverlay";
import Select from "../../Form/Select";
import Table from "../../Table";
import {
    addFloorPlanServices,
    addFloorPlanTerms,
    deleteFloorPlanService,
    deleteFloorPlanTerms,
    getFloorPlan,
    getFloorPlanServices,
    getFloorPlanTerms,
    updateFloorPlan
} from "../../../Api/FloorPlanApi";
import {getProperty} from "../../../Api/PropertyApi";
import PageLayout from "../../../Layouts/PageLayout";
import ButtonNew, {ButtonColors, ButtonTypes} from "../../ButtonNew";
import ImageCard from "../../ImageCard";
import Card from "../../Card";

const EditFloorPlan: React.FunctionComponent = () => {
    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

    const { search } = useLocation()
    const query = new URLSearchParams(search)
    const success = query.has("success")
    const [loading, setLoading] = useState(false);
    const [property, setProperty] = useState<Property>();
    const {propertyUuid, floorPlanUuid} = useParams();
    const [alert, setAlert] = useState<AlertProps>()
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [type, setType] = useState("");
    const [bed, setBed] = useState("");
    const [bath, setBath] = useState("");
    const [sqft, setSqft] = useState("");
    const [imageUrl, setImageUrl] = useState("");
    const [deposit, setDeposit] = useState("");
    const [occupancy, setOccupancy] = useState("");

    const [termUnit, setTermUnit] = useState("");
    const [length, setLength] = useState("");
    const [price, setPrice] = useState("");
    const [optional, setOptional] = useState("");
    const [floorPlansTerms, setFloorPlansTerms] = useState<Term[]>([]);
    const [updateFloorPlanTerms, setUpdateFloorPlanTerms] = useState(false);

    const [addServicePrice, setAddServicePrice] = useState("");
    const [addServiceName, setAddServiceName] = useState("");
    const [addServiceDescription, setAddServiceDescription] = useState("");
    const [addServiceUnits, setAddServiceUnits] = useState("");
    const [floorPlansServices, setFloorPlansServices] = useState<FloorPlanService[]>([]);
    const [updateFloorPlanServices, setUpdateFloorPlanServices] = useState(false);

    const [isDefaultTermValid, setIsDefaultTermValid] = useState<boolean>(false)
    const [isDefaultAdditionalServiceValid, setIsDefaultAdditionalServiceValid] = useState<boolean>(false)

    useEffect(() => {
        setIsDefaultTermValid(
            termUnit !== "" &&
            (termUnit === "month-to-month" || length !== "") &&
            price !== ""
        )
    }, [termUnit, length, price])

    useEffect(() => {
        if (success && name !== "") {
            doShowAlert("Successfully added floor plan: " + name, AlertType.SUCCESS);
        }
    }, [success, name])

    useEffect(() => {
        setIsDefaultAdditionalServiceValid(
            optional !== "" &&
            addServicePrice !== "" &&
            addServiceName !== "" &&
            addServiceDescription !== "" &&
            addServiceUnits !== ""
        )
    }, [optional, addServicePrice, addServiceName, addServiceDescription, addServiceUnits])

    type Form = {
        name: FormElementValidation,
        description: FormElementValidation,
        bed: FormElementValidation,
        bath: FormElementValidation,
        sqft: FormElementValidation,
        type: FormElementValidation,
        termUnit: FormElementValidation
        length: FormElementValidation
        price: FormElementValidation,
        optional: FormElementValidation,
        deposit: FormElementValidation,
        occupancy: FormElementValidation,
        addServicePrice: FormElementValidation,
        addServiceName: FormElementValidation,
        addServiceDescription: FormElementValidation,
        addServiceUnits: FormElementValidation
    }

    const form: Form = {
        name: {
            id: "name",
            required: true,
            validate: formInputValidate,
            regex: PropertyNameRegex
        },
        description: {
            id: "description",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        bed: {
            id: "bed",
            required: true,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        bath: {
            id: "bath",
            required: true,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        sqft: {
            id: "sqft",
            required: true,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        deposit: {
            id: "deposit",
            required: true,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        occupancy: {
            id: "occupancy",
            required: true,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        type: {
            id: "type",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        termUnit: {
            id: "termUnit",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        length: {
            id: "length",
            required: true,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        price: {
            id: "price",
            required: true,
            validate: formInputValidate,
            regex: DollarAmountRegex
        },
        optional: {
            id: "optional",
            required: true,
            validate: formInputValidate,
            regex: GenericSelectRegex
        },
        addServiceName: {
            id: "addServiceName",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        addServiceDescription: {
            id: "addServiceDescription",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        addServicePrice: {
            id: "addServicePrice",
            required: true,
            validate: formInputValidate,
            regex: DollarAmountRegex
        },
        addServiceUnits: {
            id: "addServiceUnits",
            required: true,
            validate: formInputValidate,
            regex: GenericSelectRegex
        },

    };

    useEffect(() => {
        if (updateFloorPlanTerms) {
            setUpdateFloorPlanTerms(false);
            getFloorPlanTerms(propertyUuid, floorPlanUuid)
                .then((data) => {
                    setFloorPlansTerms(data);
                })
                .catch((e) => {
                    console.error(e);
                })
        }
    }, [updateFloorPlanTerms, propertyUuid, floorPlanUuid])

    useEffect(() => {
        if (updateFloorPlanServices) {
            setUpdateFloorPlanServices(false);
            getFloorPlanServices(propertyUuid, floorPlanUuid)
                .then((data) => {
                    setFloorPlansServices(data);
                })
                .catch((e) => {
                    console.error(e);
                })
        }
    }, [updateFloorPlanServices, propertyUuid, floorPlanUuid])

    useEffect(() => {
        setLoading(true);
        Promise.all([
            getProperty(propertyUuid)
                .then(data => data),
            getFloorPlan(propertyUuid, floorPlanUuid)
                .then(data => data),
            getFloorPlanTerms(propertyUuid, floorPlanUuid)
                .then(data => data),
            getFloorPlanServices(propertyUuid, floorPlanUuid)
        ])
            .then(values => {
                setProperty(values[0]);
                const floorPlan = values[1];
                if (floorPlan) {
                    setName(floorPlan.name);
                    setDescription(floorPlan.description);
                    setBath(floorPlan.bath + "");
                    setBed(floorPlan.bed + "");
                    setSqft(floorPlan.sqft + "");
                    setDeposit(floorPlan.deposit + "")
                    setType(floorPlan.type);
                    setImageUrl(floorPlan.imageUrl);
                    setOccupancy(floorPlan.occupancy + "");
                }
                setFloorPlansTerms(values[2]);
                setFloorPlansServices(values[3]);
            })
            .catch(e => console.error(e))
            .finally(() => setLoading(false));

    }, [propertyUuid, floorPlanUuid]);

    const doShowAlert = (message: string, type: AlertType) => {
        setAlert({type, message})
    }

    const validateForm = (form: Form): boolean => {
        if (!property) {
            doShowAlert("Failed to add floor plan.  Could not retrieve property details", AlertType.DANGER);
            return false;
        }
        if (!form.name.validate(name, form.name.required, form.name.regex.expression)) {
            doShowAlert("Name invalid.", AlertType.DANGER);
            return false;
        }
        if (!form.description.validate(description, form.description.required, form.description.regex.expression)) {
            doShowAlert("Description invalid.", AlertType.DANGER);
            return false;
        }
        return true;

    }

    const [isSaving, setIsSaving] = useState<boolean>(false)

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!validateForm(form)) {
            return;
        }
        setIsSaving(true)
        updateFloorPlan(propertyUuid, floorPlanUuid, {
            name: name,
            description: description,
            bed: (bed === "") ? "0" : bed,
            bath: (bath === "") ? "0" : bath,
            sqft: sqft,
            type: type,
            imageUrl: imageUrl,
            deposit: deposit,
            occupancy: occupancy
        }).then((fp) => {
            doShowAlert("Successfully updated floor plan: " + name, AlertType.SUCCESS);
        }).catch(e => {
            doShowAlert("Failed to update floor plan: " + e.message, AlertType.DANGER);
        }).finally(() => setIsSaving(false))
    }

    const encodeImageFileAsURL = (element: EventTarget & HTMLInputElement) => {
        // @ts-ignore
        let file = element.files[0];
        let filesize = ((file.size / 1024) / 1024);
        if (filesize > 5) {
            setImageUrl("");
            doShowAlert("Image size too large. Please choose an image under 5mb.", AlertType.DANGER)
            return;
        }
        let reader = new FileReader();
        reader.onloadend = function () {
            setImageUrl(reader.result + "");
        }
        reader.readAsDataURL(file);
    }

    const getDisplayNameOfTerm = (units: string, length: string) => {
        switch (units) {
            case "daily":
                return "Daily";
            case "week-to-week":
                return "Week-to-Week";
            case "month-to-month":
                return "Month-to-Month";
            case "fixed_day":
                return length + " Day" + ((parseInt(length) > 1) ? "s" : "");
            case "fixed_week":
                return length + " Week" + ((parseInt(length) > 1) ? "s" : "");
            case "fixed_month":
                return length + " Month" + ((parseInt(length) > 1) ? "s" : "");
            case "fixed_year":
                return length + " Year" + ((parseInt(length) > 1) ? "s" : "");
            default:
                return "";
        }
    }

    const addFloorPlanTerm = () => {
        addFloorPlanTerms(propertyUuid, floorPlanUuid, {
            units: termUnit,
            price: price,
            length: (length === undefined || length === "") ? "0" : length
        }).then((r) => {
            setTermUnit("");
            setPrice("");
            setLength("");
            setUpdateFloorPlanTerms(true);
        }).catch((e) => {
            console.error(e);
        })
    }

    const addFloorPlanAdditionalService = () => {
        addFloorPlanServices(propertyUuid, floorPlanUuid, {
            units: addServiceUnits,
            price: addServicePrice,
            name: addServiceName,
            description: addServiceDescription,
            optional: optional === "yes"
        }).then((r) => {
            setAddServiceUnits("");
            setAddServicePrice("");
            setAddServiceName("");
            setAddServiceDescription("");
            setOptional("no");
            setUpdateFloorPlanServices(true);
        }).catch((e) => {
            console.error(e);
        })
    }

    const deleteTerm = (termUuid: string) => {
        deleteFloorPlanTerms(propertyUuid, floorPlanUuid, termUuid)
            .then((r) => {
                setUpdateFloorPlanTerms(true);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    const deleteService = (serviceUuid: string) => {
        deleteFloorPlanService(propertyUuid, floorPlanUuid, serviceUuid)
            .then((r) => {
                setUpdateFloorPlanServices(true);
            })
            .catch((e) => {
                console.log(e);
            })
    }

    return (loading ? <LoadingOverlay /> : <>
        <PageLayout title="Edit Floor Plan" property={property} alert={alert}>
            <form>
                <div className="d-flex justify-content-start gap-5">
                    <div>
                        <ImageCard
                            imageUrl={imageUrl}
                            setImageUrl={setImageUrl}
                            canUpload={true}
                            onError={m => setAlert({type: AlertType.DANGER, message: m})}
                            required={true}
                        />
                    </div>
                    <div className="col-lg-6">
                        <div className="mb-4">
                            <Card title="General Information">
                                <div className={"row"}>
                                    <div className={"col-lg-6 mb-1"}>
                                        <Input id={form.name.id} validation={form.name.regex.expression}
                                               doValidate={form.name.validate}
                                               value={name}
                                               setValue={setName}
                                               required={form.name.required}
                                               invalidFeedbackText={form.name.regex.helpText}
                                               type={"text"} label={"Name"}
                                        />
                                    </div>
                                    <div className={"col-lg-6 mb-1"}>
                                        <Select id={form.type.id} validation={form.type.regex.expression}
                                                doValidate={form.type.validate}
                                                value={type}
                                                setValue={setType}
                                                required={form.type.required}
                                                invalidFeedbackText={form.type.regex.helpText}
                                                label={"Type"}
                                        >
                                            <option disabled={true} value={""}>Choose a type...</option>
                                            <option value={"retail"}>Retail</option>
                                            <option value={"office"}>Office</option>
                                            <option value={"multifamily"}>Multifamily</option>
                                            <option value={"residential"}>Residential</option>
                                        </Select>
                                    </div>
                                    <div className={"col-lg-6 mb-1"} hidden={type !== "multifamily" && type !== "residential"}>
                                        <Input id={form.bed.id} validation={form.bed.regex.expression}
                                               doValidate={form.bed.validate}
                                               value={bed}
                                               setValue={setBed}
                                               required={form.bed.required}
                                               invalidFeedbackText={form.bed.regex.helpText}
                                               type={"text"} label={"Bed"}
                                        />
                                    </div>
                                    <div className={"col-lg-6 mb-1"} hidden={type !== "multifamily" && type !== "residential"}>
                                        <Input id={form.bath.id} validation={form.bath.regex.expression}
                                               doValidate={form.bath.validate}
                                               value={bath}
                                               setValue={setBath}
                                               required={form.bath.required}
                                               invalidFeedbackText={form.bath.regex.helpText}
                                               type={"text"} label={"Bath"}
                                        />
                                    </div>
                                    <div className={"col-lg-6 mb-1"}>
                                        <Input id={form.sqft.id} validation={form.sqft.regex.expression}
                                               doValidate={form.sqft.validate}
                                               value={sqft}
                                               setValue={setSqft}
                                               required={form.sqft.required}
                                               invalidFeedbackText={form.sqft.regex.helpText}
                                               type={"text"} label={"Square Feet"}
                                        />
                                    </div>
                                    <div className={"col-lg-6 mb-1"}>
                                        <Input id={form.occupancy.id} validation={form.occupancy.regex.expression}
                                               doValidate={form.occupancy.validate}
                                               value={occupancy}
                                               setValue={setOccupancy}
                                               required={form.occupancy.required}
                                               invalidFeedbackText={form.occupancy.regex.helpText}
                                               type={"number"} label={"Occupancy"}
                                        />
                                    </div>
                                    <div className={"col-lg-6 mb-1"}>
                                        <Input id={form.deposit.id} validation={form.deposit.regex.expression}
                                               doValidate={form.deposit.validate}
                                               value={deposit}
                                               setValue={setDeposit}
                                               required={form.deposit.required}
                                               invalidFeedbackText={form.deposit.regex.helpText}
                                               type={"number"} label={"Deposit Amount"}
                                        />
                                    </div>
                                    <div className={"col-lg-6 mb-1"}>
                                        <TextArea id={form.description.id} validation={form.description.regex.expression}
                                                  doValidate={form.description.validate}
                                                  value={description}
                                                  setValue={setDescription}
                                                  required={form.description.required}
                                                  invalidFeedbackText={form.description.regex.helpText}
                                                  rows={3} label={"Description"}
                                        />
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="mb-4">
                            <Card title="Default Terms">
                                <div className={"row"}>
                                    <div className={"col-lg-3 mb-1"}>
                                        <Select id={form.termUnit.id} validation={form.termUnit.regex.expression}
                                                doValidate={form.termUnit.validate}
                                                value={termUnit}
                                                setValue={setTermUnit}
                                                required={form.termUnit.required}
                                                invalidFeedbackText={form.termUnit.regex.helpText}
                                                label={"Unit"}
                                        >
                                            <option disabled={true} value={""}>Choose a term...</option>
                                            <option value={"month-to-month"}>Month-to-Month</option>
                                            <option value={"fixed_month"}>Month(s)</option>
                                        </Select>
                                    </div>
                                    <div className={"col-lg-3 mb-1"}>
                                        <Input id={form.length.id} validation={form.length.regex.expression}
                                               doValidate={form.length.validate}
                                               value={length}
                                               setValue={setLength}
                                               required={form.length.required}
                                               disabled={!termUnit.startsWith("fixed_")}
                                               invalidFeedbackText={form.length.regex.helpText}
                                               type={"text"} label={"Length"}
                                        />
                                    </div>
                                    <div className={"col-lg-3 mb-1"}>
                                        <Input id={form.price.id} validation={form.price.regex.expression}
                                               doValidate={form.price.validate}
                                               value={price}
                                               setValue={setPrice}
                                               required={form.price.required}
                                               invalidFeedbackText={form.price.regex.helpText}
                                               type={"text"} label={"Price"}
                                        />
                                    </div>
                                    <div className={"col-lg-3 mb-1"}>
                                        <button type={"button"} className={"btn btn-primary"}
                                                style={{marginTop: 20}}
                                                disabled={!isDefaultTermValid}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    addFloorPlanTerm();
                                                }}>
                                            Add
                                        </button>
                                    </div>
                                    <div className="col-md-12">
                                        <Table headers={["Term", "Price"]} defaultPaginationSize={10}>
                                            {
                                                floorPlansTerms.map((t, index) => (
                                                    <tr key={t.uuid + "_" + index}>
                                                        <td>{getDisplayNameOfTerm(t.units, t.length)}</td>
                                                        <td>{currencyFormat.format(parseFloat(t.price))}</td>
                                                        <td className="d-flex justify-content-end">
                                                            <button className={"btn btn-sm btn-primary"}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        deleteTerm(t.uuid);
                                                                    }}
                                                            >Remove
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </Table>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="mb-4">
                            <Card title="Default Additional Services">
                                <div className={"row"}>
                                    <div className={"col-lg-4 mb-1"}>
                                        <Select id={form.addServiceUnits.id} validation={form.addServiceUnits.regex.expression}
                                                doValidate={form.addServiceUnits.validate}
                                                value={addServiceUnits}
                                                setValue={setAddServiceUnits}
                                                required={form.addServiceUnits.required}
                                                invalidFeedbackText={form.addServiceUnits.regex.helpText}
                                                label={"Unit"}
                                        >
                                            <option disabled={true} value={""}>Choose one...</option>
                                            <option value={"monthly"}>Monthly</option>
                                        </Select>
                                    </div>
                                    <div className={"col-lg-4 mb-1"}>
                                        <Select id={form.optional.id} validation={form.optional.regex.expression}
                                                doValidate={form.optional.validate}
                                                value={optional}
                                                setValue={setOptional}
                                                required={form.optional.required}
                                                invalidFeedbackText={form.optional.regex.helpText}
                                                label={"Optional"}
                                        >
                                            <option value={""} disabled>Please Select...</option>
                                            <option value={"no"}>No</option>
                                            <option value={"yes"}>Yes</option>
                                        </Select>
                                    </div>
                                    <div className={"col-lg-4 mb-1"}>
                                        <Input id={form.addServiceName.id} validation={form.addServiceName.regex.expression}
                                               doValidate={form.addServiceName.validate}
                                               value={addServiceName}
                                               setValue={setAddServiceName}
                                               required={form.addServiceName.required}
                                               invalidFeedbackText={form.addServiceName.regex.helpText}
                                               type={"text"} label={"Name"}
                                        />
                                    </div>
                                    <div className={"col-lg-4 mb-1"}>
                                        <Input id={form.addServiceDescription.id}
                                               validation={form.addServiceDescription.regex.expression}
                                               doValidate={form.addServiceDescription.validate}
                                               value={addServiceDescription}
                                               setValue={setAddServiceDescription}
                                               required={form.addServiceDescription.required}
                                               invalidFeedbackText={form.addServiceDescription.regex.helpText}
                                               type={"text"} label={"Description"}
                                        />
                                    </div>
                                    <div className={"col-lg-4 mb-1"}>
                                        <Input id={form.addServicePrice.id} validation={form.addServicePrice.regex.expression}
                                               doValidate={form.addServicePrice.validate}
                                               value={addServicePrice}
                                               setValue={setAddServicePrice}
                                               required={form.addServicePrice.required}
                                               invalidFeedbackText={form.addServicePrice.regex.helpText}
                                               type={"number"} label={"Price"}
                                        />
                                    </div>
                                    <div className={"col-lg-4 mb-1"}>
                                        <button type={"button"} className={"btn btn-primary"}
                                                style={{marginTop: 20}}
                                                disabled={!isDefaultAdditionalServiceValid}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    addFloorPlanAdditionalService();
                                                }}>
                                            Add
                                        </button>
                                    </div>
                                    <div className="col-lg-12">
                                        <Table headers={["Term", "Optional", "Name", "Description", "Price"]} defaultPaginationSize={10}>
                                            {
                                                floorPlansServices.map((t, index) => (
                                                    <tr key={t.uuid + "_" + index}>
                                                        <td>{t.units}</td>
                                                        <td>{(t.optional) ? "Yes" : "No"}</td>
                                                        <td>{t.name}</td>
                                                        <td>{t.description}</td>
                                                        <td>{currencyFormat.format(parseFloat(t.price))}</td>
                                                        <td className="d-flex justify-content-end">
                                                            <button className={"btn btn-sm btn-primary"}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        deleteService(t.uuid);
                                                                    }}
                                                            >Remove
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </Table>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div className="d-flex justify-content-start gap-2 my-3">
                            <Link to={"/p/m/realestate/floorplans/" + propertyUuid}>
                                <button type="button" className="btn btn-secondary"
                                        onClick={() => {
                                        }}>Back
                                </button>
                            </Link>
                            <ButtonNew
                                type={ButtonTypes.BUTTON}
                                color={ButtonColors.PRIMARY}
                                label="Save"
                                loading={isSaving}
                                onClick={onSubmit}
                                style={{width: "5rem"}}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </PageLayout>
    </>);
};

export default EditFloorPlan;
