import React from "react";
import {Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import AutoSelectProperty from "../../Components/Form/SelectProperty/AutoSelectProperty";
import ARAgingSummary from "../../Components/ARAgingSummary";

const ARAgingSummaryContainer: React.FunctionComponent = (p) => {

    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <AutoSelectProperty name={"AR Aging Summary"} />
            </Route>
            <Route exact path={path + "/:propertyId"}>
                <ARAgingSummary />
            </Route>
        </Switch>
    );
};
export default ARAgingSummaryContainer;