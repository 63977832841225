import * as React from "react";
import {useEffect, useState} from "react";
import {getUnreadConversations} from "../../../Api/PortfolioApi";
import {Conversation} from "../../../Api/ConversationApi";
import Table from "../../Table";
import {getProperty} from "../../../Api/PropertyApi";
import {getARAccountById} from "../../../Api/AccountApi";
import {ARAccount, Property} from "../../../types";
import {useHistory} from "react-router";
import Alert, {AlertType} from "../../Alert";
import moment from "moment";
import Card from "../../Card";
import LoadingOverlay from "../../LoadingOverlay";


const TenantMessagesWidget: React.FunctionComponent = () => {

    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    const [properties, setProperties] = useState<Property[]>([]);
    const [accounts, setAccounts] = useState<ARAccount[]>([]);
    const [conversations, setConversations] = useState<Conversation[]>([]);

    useEffect(() => {
        setLoading(true);
        conversations.forEach(conversation => {
            Promise.all([
                getProperty(conversation.propertyId).then(data => data),
                getARAccountById(conversation.propertyId, conversation.accountId).then(data => data)
            ])
                .then(values => {
                    if (!properties.find(property => { return property.uuid === values[0].uuid })) {
                        setProperties(properties => [...properties, values[0]]);
                    }
                    if (!accounts.find(account => { return account.uuid === values[1].uuid })) {
                        setAccounts(accounts => [...accounts, values[1]]);
                    }
                })
                .catch(setError)
        });
        setLoading(false);
    }, [conversations])

    useEffect(() => {
        setLoading(true);
        getUnreadConversations()
            .then(setConversations)
            .catch(setError)
            .finally(() => setLoading(false));
    }, [])

    return <>
        <Card title="Tenant Messages">
            {error && <Alert type={AlertType.DANGER} message={error.message} />}
            {loading ? <LoadingOverlay /> :
                <Table headers={[
                    "Property",
                    "Unit",
                    "Subject",
                    "Message",
                    "Received",
                ]} defaultPaginationSize={10}>
                    {conversations.map((conversation, i) => (
                        <tr className="clickable" key={i + "_tc"} onClick={() => {history.push("/p/m/tenant-services/messages/" + conversation.propertyId + "/" + conversation.uuid)}}>
                            <td>{properties.find(p => { return p.uuid === conversation.propertyId })?.name}</td>
                            <td>{accounts.find(a => { return a.uuid === conversation.accountId })?.unitName}</td>
                            <td>{conversation.subject}</td>
                            <td>{conversation.lastMessage.body.substring(0, 25)}{conversation.lastMessage.body.length > 25 ? "..." : ""}</td>
                            <td>{moment(conversation.lastMessage.createdAt).fromNow()}</td>
                        </tr>
                    ))}
                </Table>
            }
        </Card>
    </>
}

export default TenantMessagesWidget;
