import React, {Fragment, useEffect, useRef, useState} from "react";
import PageLayout from "../../../Layouts/PageLayout";
import {getTrustAccountBalances} from "../../../Api/PortfolioApi";
import {getPortfolioId} from "../../../helpers";
import {formInputValidate, GenericInputRegex, TrustAccountBalances} from "../../../types";
import moment from "moment";
import './index.css'

import Input from "../../../Components/Form/Input";
import {Link} from "react-router-dom";


const ViewTrustAccountBalances: React.FunctionComponent = () => {

    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [trustAccountBalances, setTrustAccountBalances] = useState<TrustAccountBalances>();
    const [search, setSearch] = useState("");
    const [selectedSortColumn, setSelectedSortColumn] = useState(1);
    const [sortDirection, setSortDirection] = useState(0);

    function load() {
        setLoading(true);
        getTrustAccountBalances(getPortfolioId())
            .then(tb => {
                tb.owners.sort((a, b) => a.name < b.name ? getSortLHS() : getSortRHS());
                setTrustAccountBalances(tb);
            })
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        load()
    }, []);

    function getSortLHS() {
        if (sortDirection === 0) {
            return -1
        }
        return 1;
    }

    function getSortRHS() {
        if (sortDirection === 0) {
            return 1
        }
        return -1;
    }

    function sortByOwner() {
        if (trustAccountBalances && selectedSortColumn === 1) {
            trustAccountBalances.owners.sort((a, b) => a.name < b.name ? getSortLHS() : getSortRHS());
            setTrustAccountBalances({...trustAccountBalances})
        }
    }

    function sortByProperty() {
        if (trustAccountBalances && selectedSortColumn === 2) {
            trustAccountBalances.owners.forEach(o => {
                o.lineItems.sort((a, b) => a.name < b.name ? getSortLHS() : getSortRHS());
            })
            setTrustAccountBalances({...trustAccountBalances})
        }
    }

    function sortByOperatingCash() {
        if (trustAccountBalances && selectedSortColumn === 3) {
            trustAccountBalances.owners.forEach(o => {
                o.lineItems.sort((a, b) => a.operating > b.operating ? getSortLHS() : getSortRHS());
            })
            setTrustAccountBalances({...trustAccountBalances})
        }
    }

    function sortBySecurityDepositCash() {
        if (trustAccountBalances && selectedSortColumn === 4) {
            trustAccountBalances.owners.forEach(o => {
                o.lineItems.sort((a, b) => a.securityDeposits > b.securityDeposits ? getSortLHS() : getSortRHS());
            })
            setTrustAccountBalances({...trustAccountBalances})
        }
    }

    function sortByMgmtSecurityDeposit() {
        if (trustAccountBalances && selectedSortColumn === 5) {
            trustAccountBalances.owners.forEach(o => {
                o.lineItems.sort((a, b) => a.mgmtHeldSecurityDeposits > b.mgmtHeldSecurityDeposits ? getSortLHS() : getSortRHS());
            })
            setTrustAccountBalances({...trustAccountBalances})
        }
    }

    function sortByUnpaidBillsAmount() {
        if (trustAccountBalances && selectedSortColumn === 6) {
            trustAccountBalances.owners.forEach(o => {
                o.lineItems.sort((a, b) => a.unpaidBillsAmount > b.unpaidBillsAmount ? getSortLHS() : getSortRHS());
            })
            setTrustAccountBalances({...trustAccountBalances})
        }
    }

    function sortByUnsettledPayments() {
        if (trustAccountBalances && selectedSortColumn === 7) {
            trustAccountBalances.owners.forEach(o => {
                o.lineItems.sort((a, b) => a.pendingPayments > b.pendingPayments ? getSortLHS() : getSortRHS());
            })
            setTrustAccountBalances({...trustAccountBalances})
        }
    }

    function sortByUnusedPrepayment() {
        if (trustAccountBalances && selectedSortColumn === 8) {
            trustAccountBalances.owners.forEach(o => {
                o.lineItems.sort((a, b) => a.unusedPrepaymentAmount > b.unusedPrepaymentAmount ? getSortLHS() : getSortRHS());
            })
            setTrustAccountBalances({...trustAccountBalances})
        }
    }

    function sortByReserveAmount() {
        if (trustAccountBalances && selectedSortColumn === 9) {
            trustAccountBalances.owners.forEach(o => {
                o.lineItems.sort((a, b) => a.reserveAmount > b.reserveAmount ? getSortLHS() : getSortRHS());
            })
            setTrustAccountBalances({...trustAccountBalances})
        }
    }

    function sortByWorkOrderEstimate() {
        if (trustAccountBalances && selectedSortColumn === 10) {
            trustAccountBalances.owners.forEach(o => {
                o.lineItems.sort((a, b) => a.workOrderEstimates > b.workOrderEstimates ? getSortLHS() : getSortRHS());
            })
            setTrustAccountBalances({...trustAccountBalances})
        }
    }

    useEffect(() => {
        if (selectedSortColumn === 1) {
            sortByOwner();
        }
        if (selectedSortColumn === 2) {
            sortByProperty();
        }
        if (selectedSortColumn === 3) {
            sortByOperatingCash();
        }
        if (selectedSortColumn === 4) {
            sortBySecurityDepositCash();
        }
        if (selectedSortColumn === 5) {
            sortByMgmtSecurityDeposit();
        }
        if (selectedSortColumn === 6) {
            sortByUnpaidBillsAmount();
        }
        if (selectedSortColumn === 7) {
            sortByUnsettledPayments();
        }
        if (selectedSortColumn === 8) {
            sortByUnusedPrepayment();
        }
        if (selectedSortColumn === 9) {
            sortByReserveAmount();
        }
        if (selectedSortColumn === 10) {
            sortByWorkOrderEstimate();
        }
    }, [selectedSortColumn, sortDirection]);


    function getSearchClassNames(value: string) {
        if (value && search && value.toLowerCase().indexOf(search.toLowerCase()) >= 0) {
            return "table-success";
        }
    }

    function getColor(column: number) {
        if (column === selectedSortColumn) {
            return {color: '#0d6efd'};
        } else {
            return {color: '999'};
        }
    }

    function getOwnerColumnIcon() {
        let column = 1;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-alpha-up-alt" viewBox="0 0 16 16">
                <path d="M12.96 7H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645z"/>
                <path fillRule="evenodd"
                      d="M10.082 12.629 9.664 14H8.598l1.789-5.332h1.234L13.402 14h-1.12l-.419-1.371zm1.57-.785L11 9.688h-.047l-.652 2.156z"/>
                <path
                    d="M4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-alpha-down" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371zm1.57-.785L11 2.687h-.047l-.652 2.157z"/>
                <path
                    d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>
        }
    }

    function getPropertyColumnIcon() {
        let column = 2;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-alpha-up-alt" viewBox="0 0 16 16">
                <path d="M12.96 7H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645z"/>
                <path fillRule="evenodd"
                      d="M10.082 12.629 9.664 14H8.598l1.789-5.332h1.234L13.402 14h-1.12l-.419-1.371zm1.57-.785L11 9.688h-.047l-.652 2.156z"/>
                <path
                    d="M4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-alpha-down" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M10.082 5.629 9.664 7H8.598l1.789-5.332h1.234L13.402 7h-1.12l-.419-1.371zm1.57-.785L11 2.687h-.047l-.652 2.157z"/>
                <path
                    d="M12.96 14H9.028v-.691l2.579-3.72v-.054H9.098v-.867h3.785v.691l-2.567 3.72v.054h2.645zM4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>
        }
    }

    function getOperatingCashColumnIcon() {
        let column = 3;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-down" viewBox="0 0 16 16">
                <path d="M12.438 1.668V7H11.39V2.684h-.051l-1.211.859v-.969l1.262-.906h1.046z"/>
                <path fillRule="evenodd"
                      d="M11.36 14.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.835 1.973-1.835 1.09 0 2.063.636 2.063 2.687 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>;
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-up-alt" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>;
        }
    }

    function getSecurityDepositCashColumnIcon() {
        let column = 4;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-down" viewBox="0 0 16 16">
                <path d="M12.438 1.668V7H11.39V2.684h-.051l-1.211.859v-.969l1.262-.906h1.046z"/>
                <path fillRule="evenodd"
                      d="M11.36 14.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.835 1.973-1.835 1.09 0 2.063.636 2.063 2.687 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>;
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-up-alt" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>;
        }
    }

    function getMgmtHeldSecurityDepositsColumnIcon() {
        let column = 5;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-down" viewBox="0 0 16 16">
                <path d="M12.438 1.668V7H11.39V2.684h-.051l-1.211.859v-.969l1.262-.906h1.046z"/>
                <path fillRule="evenodd"
                      d="M11.36 14.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.835 1.973-1.835 1.09 0 2.063.636 2.063 2.687 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>;
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-up-alt" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>;
        }
    }

    function getUnpaidBillsColumnIcon() {
        let column = 6;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-down" viewBox="0 0 16 16">
                <path d="M12.438 1.668V7H11.39V2.684h-.051l-1.211.859v-.969l1.262-.906h1.046z"/>
                <path fillRule="evenodd"
                      d="M11.36 14.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.835 1.973-1.835 1.09 0 2.063.636 2.063 2.687 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>;
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-up-alt" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>;
        }
    }

    function getUnsettledPaymentsColumnIcon() {
        let column = 7;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-down" viewBox="0 0 16 16">
                <path d="M12.438 1.668V7H11.39V2.684h-.051l-1.211.859v-.969l1.262-.906h1.046z"/>
                <path fillRule="evenodd"
                      d="M11.36 14.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.835 1.973-1.835 1.09 0 2.063.636 2.063 2.687 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>;
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-up-alt" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>;
        }
    }

    function getUnusedPrepaymentColumnIcon() {
        let column = 8;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-down" viewBox="0 0 16 16">
                <path d="M12.438 1.668V7H11.39V2.684h-.051l-1.211.859v-.969l1.262-.906h1.046z"/>
                <path fillRule="evenodd"
                      d="M11.36 14.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.835 1.973-1.835 1.09 0 2.063.636 2.063 2.687 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>;
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-up-alt" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>;
        }
    }

    function getReserveAmountColumnIcon() {
        let column = 9;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-down" viewBox="0 0 16 16">
                <path d="M12.438 1.668V7H11.39V2.684h-.051l-1.211.859v-.969l1.262-.906h1.046z"/>
                <path fillRule="evenodd"
                      d="M11.36 14.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.835 1.973-1.835 1.09 0 2.063.636 2.063 2.687 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>;
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-up-alt" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>;
        }
    }

    function getWorkOrderEstimatesColumnIcon() {
        let column = 10;
        if (selectedSortColumn === column && sortDirection === 1) {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-down" viewBox="0 0 16 16">
                <path d="M12.438 1.668V7H11.39V2.684h-.051l-1.211.859v-.969l1.262-.906h1.046z"/>
                <path fillRule="evenodd"
                      d="M11.36 14.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.835 1.973-1.835 1.09 0 2.063.636 2.063 2.687 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M4.5 2.5a.5.5 0 0 0-1 0v9.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L4.5 12.293z"/>
            </svg>;
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        style={getColor(column)}
                        className="bi bi-sort-numeric-up-alt" viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="M11.36 7.098c-1.137 0-1.708-.657-1.762-1.278h1.004c.058.223.343.45.773.45.824 0 1.164-.829 1.133-1.856h-.059c-.148.39-.57.742-1.261.742-.91 0-1.72-.613-1.72-1.758 0-1.148.848-1.836 1.973-1.836 1.09 0 2.063.637 2.063 2.688 0 1.867-.723 2.848-2.145 2.848zm.062-2.735c.504 0 .933-.336.933-.972 0-.633-.398-1.008-.94-1.008-.52 0-.927.375-.927 1 0 .64.418.98.934.98"/>
                <path
                    d="M12.438 8.668V14H11.39V9.684h-.051l-1.211.859v-.969l1.262-.906h1.046zM4.5 13.5a.5.5 0 0 1-1 0V3.707L2.354 4.854a.5.5 0 1 1-.708-.708l2-1.999.007-.007a.5.5 0 0 1 .7.006l2 2a.5.5 0 1 1-.707.708L4.5 3.707z"/>
            </svg>;
        }
    }

    function getTableHeaderStyle() {
        return {display: 'flex', alignItems: 'flex-end'};
    }

    function sortColumn(column: number) {
        if (selectedSortColumn === column) {
            setSortDirection(sortDirection === 0 ? 1 : 0)
        } else {
            setSortDirection(0);
            setSelectedSortColumn(column);
        }

    }

    return <PageLayout title={"Trust Account Balances"}>
        {loading && <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>}
        {errorMessage && <div className="alert alert-danger" role="alert">
            {errorMessage}
        </div>}
        <div className={"row"}>
            <div className={"col-md-3 mb-3"}>
                <Input id={"search"} validation={GenericInputRegex.expression} doValidate={formInputValidate}
                       value={search} setValue={setSearch} required={false}
                       invalidFeedbackText={GenericInputRegex.helpText}
                       type={"text"} label={"Search"}/>
            </div>

            <div className={"col-md-12"}>
                As of {moment().format("MMMM Do, YYYY")}
            </div>
            <div className={"col-md-12"}>
                <div id="trust-account-balance" style={{
                    width: '100%',
                    height: 500,
                    overflow: 'auto'
                }}>
                    <table className="table table-sm table-responsive">
                        <thead style={{
                            position: 'sticky',
                            top: 0, backgroundColor: "white"
                        }}>
                        <tr>
                            <th scope="col" onClick={() => sortColumn(1)}>
                            <span style={getTableHeaderStyle()}>
                            Owner{getOwnerColumnIcon()}
                            </span>
                            </th>
                            <th scope="col" onClick={() => sortColumn(2)}>
                            <span style={getTableHeaderStyle()}>
                            Property{getPropertyColumnIcon()}
                            </span>
                            </th>
                            <th scope="col" onClick={() => sortColumn(3)}>
                            <span style={getTableHeaderStyle()}>
                            1150: Operating Cash{getOperatingCashColumnIcon()}
                            </span>
                            </th>
                            <th scope="col" onClick={() => sortColumn(4)}>
                            <span style={getTableHeaderStyle()}>
                            1160: Security Deposit Cash{getSecurityDepositCashColumnIcon()}
                            </span>
                            </th>
                            <th scope="col" onClick={() => sortColumn(5)}>
                            <span style={getTableHeaderStyle()}>
                            2101: Mgmt Held Security Deposits
                                {getMgmtHeldSecurityDepositsColumnIcon()}
                            </span>
                            </th>
                            <th scope="col" onClick={() => sortColumn(6)}>
                            <span style={getTableHeaderStyle()}>
                            Unpaid Bills Amount{getUnpaidBillsColumnIcon()}
                            </span>
                            </th>
                            <th scope="col" onClick={() => sortColumn(7)}>
                            <span style={getTableHeaderStyle()}>
                            Unsettled Payments{getUnsettledPaymentsColumnIcon()}
                            </span>
                            </th>
                            <th scope="col" onClick={() => sortColumn(8)}>
                            <span style={getTableHeaderStyle()}>
                            Unused Prepayment Amount{getUnusedPrepaymentColumnIcon()}
                            </span>
                            </th>
                            <th scope="col" onClick={() => sortColumn(9)}>
                            <span style={getTableHeaderStyle()}>
                            Reserve Amount{getReserveAmountColumnIcon()}
                            </span>
                            </th>
                            <th scope="col" onClick={() => sortColumn(10)}>
                            <span style={getTableHeaderStyle()}>
                            Work Order Estimates{getWorkOrderEstimatesColumnIcon()}
                            </span>
                            </th>

                        </tr>
                        </thead>


                        <tbody>
                        {

                            trustAccountBalances?.owners.map((o, index) => (
                                <Fragment key={o.name + index}>
                                    <tr className={getSearchClassNames(o.name)}>
                                        <th scope="col" colSpan={13}>
                                            <Link to={"/p/m/customers/businesses/" + o.uuid}>{o.name}</Link>
                                        </th>
                                    </tr>
                                    {
                                        o.lineItems.map((li, index) => (
                                            <tr key={li.name + index} className={getSearchClassNames(li.name)}>
                                                <td scope="col"></td>
                                                <td scope="col"><Link
                                                    to={"/p/m/realestate/properties/edit/" + li.uuid}>{li.name}</Link>
                                                </td>
                                                <td scope="col">{currencyFormat.format(li?.operating || 0)}</td>
                                                <td scope="col">{currencyFormat.format(li?.securityDeposits || 0)}</td>
                                                <td scope="col">{currencyFormat.format(li?.mgmtHeldSecurityDeposits || 0)}</td>
                                                <td scope="col">{currencyFormat.format(li?.unpaidBillsAmount || 0)}</td>
                                                <td scope="col">{currencyFormat.format(li?.pendingPayments || 0)}</td>
                                                <td scope="col">{currencyFormat.format(li?.unusedPrepaymentAmount || 0)}</td>
                                                <td scope="col">{currencyFormat.format(li?.reserveAmount || 0)}</td>
                                                <td scope="col">{currencyFormat.format(li?.workOrderEstimates || 0)}</td>
                                            </tr>
                                        ))
                                    }
                                    <tr>
                                        <td scope="col" colSpan={2}></td>
                                        <td scope="col">{currencyFormat.format(o?.totalOperating || 0)}</td>
                                        <td scope="col">{currencyFormat.format(o?.totalSecurityDeposits || 0)}</td>
                                        <td scope="col">{currencyFormat.format(o?.totalMgmtHeldSecurityDeposits || 0)}</td>
                                        <td scope="col">{currencyFormat.format(o?.totalUnpaidBillsAmount || 0)}</td>
                                        <td scope="col">{currencyFormat.format(o?.totalPendingPayments || 0)}</td>
                                        <td scope="col">{currencyFormat.format(o?.totalUnusedPrepaymentAmount || 0)}</td>
                                        <td scope="col">{currencyFormat.format(o?.totalReserveAmount || 0)}</td>
                                        <td scope="col">{currencyFormat.format(o?.totalWorkOrderEstimates || 0)}</td>

                                    </tr>
                                </Fragment>
                            ))
                        }

                        <tr id={"trust-account-balances-footer"} style={{
                            position: 'sticky',
                            bottom: 0,
                            backgroundColor: '#cccccc'
                        }}>
                            <th scope="col" colSpan={2}>Total</th>
                            <th scope="col">{currencyFormat.format(trustAccountBalances?.totalOperating || 0)}</th>
                            <th scope="col">{currencyFormat.format(trustAccountBalances?.totalSecurityDeposits || 0)}</th>
                            <th scope="col">{currencyFormat.format(trustAccountBalances?.totalMgmtHeldSecurityDeposits || 0)}</th>
                            <th scope="col">{currencyFormat.format(trustAccountBalances?.totalUnpaidBillsAmount || 0)}</th>
                            <th scope="col">{currencyFormat.format(trustAccountBalances?.totalPendingPayments || 0)}</th>
                            <th scope="col">{currencyFormat.format(trustAccountBalances?.totalUnusedPrepaymentAmount || 0)}</th>
                            <th scope="col">{currencyFormat.format(trustAccountBalances?.totalReserveAmount || 0)}</th>
                            <th scope="col">{currencyFormat.format(trustAccountBalances?.totalWorkOrderEstimates || 0)}</th>
                            <th scope={"col"} colSpan={4}></th>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </PageLayout>
};

export default ViewTrustAccountBalances;