import {FormBuilderField, FormFieldType} from "../../../Components/Form/FieldBuilder/FormFieldBuilder";
import {EmailRegex, formInputValidate, formSelectValidate, GenericSelectRegex} from "../../../types";

interface EditUserForm {
    email: FormBuilderField,
    roles: FormBuilderField
}

const EditUserForm: EditUserForm = {
    email: {
        type: FormFieldType.INPUT,
        element: {
            id: 'email',
            required: true,
            validate: formInputValidate,
            regex: EmailRegex
        },
        label: 'Email',
        disabled: true,
        value: undefined,
        setValue: () => {
        }
    },
    roles: {
        type: FormFieldType.SELECT,
        element: {
            id: 'roles',
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        label: 'Role',
        defaultSelectOptions: [
            {
                value: "",
                label: "Please select...",
                disabled: true
            },

        ],
        value: undefined,
        setValue: () => {
        }
    }
}

export default EditUserForm;