import {FormBuilderField, FormFieldType} from "../../Form/FieldBuilder/FormFieldBuilder";
import {
    DateRegex,
    formInputValidate,
    formSelectValidate,
    FullNameRegex,
    GenericSelectRegex, USBankAccountNumberRegex, USBankRoutingNumberRegex,
    UuidRegex
} from "../../../types";

interface AddAmenityReservationForm {
    accountId: FormBuilderField,
    date: FormBuilderField,
    startTime: FormBuilderField,
    endTime: FormBuilderField
    accountHolderName: FormBuilderField,
    accountType: FormBuilderField,
    accountNumber: FormBuilderField,
    routingNumber: FormBuilderField
}

const AddAmenityReservationForm: AddAmenityReservationForm = {
    accountId: {
        type: FormFieldType.SELECT,
        element: {
            id: 'accountId',
            required: true,
            validate: formSelectValidate,
            regex: UuidRegex,
        },
        label: "Unit",
        defaultSelectOptions: [{
            value: '',
            label: 'Please select...'
        }],
        value: undefined,
        setValue: () => {}
    },
    accountHolderName: {
        type: FormFieldType.INPUT,
        element: {
            id: 'accountHolderName',
            required: true,
            validate: formInputValidate,
            regex: FullNameRegex,
        },
        label: 'Account Holder',
        value: undefined,
        setValue: () => {}
    },
    accountType: {
        type: FormFieldType.SELECT,
        element: {
            id: 'accountType',
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex,
        },
        label: 'Account Type',
        defaultSelectOptions: [
            {
                value: "",
                label: "Please select...",
                disabled: true
            },
            {
                value: "personal_checking",
                label: "Personal Checking",
            },
            {
                value: "personal_savings",
                label: "Personal Savings",
            },
            {
                value: "business_checking",
                label: "Business Checking",
            },
            {
                value: "business_savings",
                label: "Business Savings",
            }
        ],
        value: undefined,
        setValue: () => {}
    },
    accountNumber: {
        type: FormFieldType.INPUT,
        element: {
            id: 'accountNumber',
            required: true,
            validate: formInputValidate,
            regex: USBankAccountNumberRegex,
        },
        label: 'Account Number',
        value: undefined,
        setValue: () => {}
    },
    routingNumber: {
        type: FormFieldType.INPUT,
        element: {
            id: 'routingNumber',
            required: true,
            validate: formInputValidate,
            regex: USBankRoutingNumberRegex,
        },
        label: 'Routing Number',
        value: undefined,
        setValue: () => {}
    },
    date: {
        type: FormFieldType.DATE,
        element: {
            id: 'date',
            required: true,
            validate: formInputValidate,
            regex: DateRegex,
        },
        label: 'Date',
        value: undefined,
        setValue: () => {}
    },
    startTime: {
        type: FormFieldType.SELECT,
        element: {
            id: 'startTime',
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex,
        },
        label: 'Start Time',
        value: undefined,
        setValue: () => {}
    },
    endTime: {
        type: FormFieldType.SELECT,
        element: {
            id: 'endTime',
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex,
        },
        label: 'End Time',
        value: undefined,
        setValue: () => {}
    }
}

export default AddAmenityReservationForm;
