import React, {useEffect, useState} from "react";
import PageLayout from "../../../../Layouts/PageLayout";
import {createOwnerPayout, CreateOwnerPayout, getOwnerBalancesPayoutCalculator} from "../../../../Api/PortfolioApi";
import {getPortfolioId} from "../../../../helpers";
import {
    DollarAmountRegex,
    formInputValidate,
    GenericInputRegex,
    OwnerBalancesPayoutSummary,
    OwnerPayoutBalance,
    PropertyOwnerPayoutBalance
} from "../../../../types";
import Input from "../../../../Components/Form/Input";
import Checkbox from "../../../../Components/Form/Checkbox/Checkbox";
import {useHistory} from "react-router";


const ViewOwnerPayoutCalculator: React.FunctionComponent = () => {

    let history = useHistory();
    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    const [ownerBalancePayoutCalculator, setOwnerBalancePayoutCalculator] = useState<OwnerBalancesPayoutSummary>();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isReview, setIsReview] = useState(false);
    const [doRedirect, setDoRedirect] = useState(false);

    useEffect(() => {
        if (doRedirect) {
            setDoRedirect(false);
            history.push({
                pathname: "payouts"
            })
        }
    }, [history, doRedirect]);

    useEffect(() => {
        setLoading(true);
        getOwnerBalancesPayoutCalculator(getPortfolioId())
            .then(setOwnerBalancePayoutCalculator)
            .catch(e => setErrorMessage(e.message))
            .finally(() => setLoading(false));
    }, []);

    function handleOwnerPayout() {
        if (ownerBalancePayoutCalculator) {
            let payout: CreateOwnerPayout = {
                owners: ownerBalancePayoutCalculator.owners.filter(o => o.include).map(o => ({
                    uuid: o.uuid,
                    properties: o.lineItems.map(li => ({
                        uuid: li.uuid,
                        amount: li.paymentAmount
                    }))
                }))
            };

            setLoading(true);
            createOwnerPayout(getPortfolioId(), payout)
                .then(r => {
                    setDoRedirect(true);
                })
                .catch(e => setErrorMessage(e.message))
                .finally(() => setLoading(false));
        }

    }

    function getRowClass(o: OwnerPayoutBalance, li: PropertyOwnerPayoutBalance) {

        if (o.include && li.suggestedPayout < li.paymentAmount) {
            return "table-warning";
        }
        if (o.include) {
            return "table-success";
        }
    }

    function getTotalOperating() {
        let total = 0;
        ownerBalancePayoutCalculator?.owners.forEach(o => {
            total += o.totalOperating;
        })
        return total;
    }

    function getTotalPayout() {
        let total = 0;
        ownerBalancePayoutCalculator?.owners.filter(o => o.include).forEach(o => {
            o.lineItems.forEach(li => {
                total += li.paymentAmount;
            })

        })
        return total;
    }

    function getOwnerPayoutTotal(o: OwnerPayoutBalance) {
        let total = 0;
        o.lineItems.forEach(li => {
            total += li.paymentAmount;
        })
        return total;
    }

    function getReviewView() {
        return <>
            <div className={"row"}>
                <p>Review the following payout to owners for accuracy.</p>
                <div className={"col-md-12"}>
                    <button type={"button"} className={"btn btn-primary"} onClick={(e) => {
                        e.preventDefault();
                        handleOwnerPayout();
                    }}>Submit
                    </button>
                    <button type={"button"} className={"btn btn-danger ms-3"}
                            onClick={() => setIsReview(false)}>Cancel
                    </button>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <table className="table table-sm table-responsive">
                        <thead>
                        <tr>
                            <th scope="col">Owner</th>
                            <th scope="col">Amount</th>


                        </tr>
                        </thead>
                        <tbody>
                        {
                            ownerBalancePayoutCalculator?.owners.filter(o => o.include && getOwnerPayoutTotal(o) > 0).map(o => (
                                <tr key={o.uuid}>
                                    <td>{o.name}</td>
                                    <td>{currencyFormat.format(getOwnerPayoutTotal(o))}</td>
                                </tr>
                            ))
                        }
                        </tbody>
                        <tfoot>
                        <tr>
                            <th className={"text-end"}>Total</th>
                            <th>{currencyFormat.format(getTotalPayout())}</th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    }

    function getSelectionView() {
        return <>
            <div className={"row"}>
                <p>Enter a payout amount and select the owners to include the in payout.</p>
                <div className={"col-md-12"}>
                    <button type={"button"} className={"btn btn-primary"} onClick={() => setIsReview(true)}>Continue to
                        Review
                    </button>
                </div>
            </div>

            <div className={"row"}>
                <div className={"col-md-12"}>
                    <table className="table table-sm table-responsive">
                        <thead>
                        <tr>
                            <th scope="col" colSpan={2}></th>
                            <th scope="col">Property</th>
                            <th scope="col">1150: Operating Cash</th>
                            <th scope="col">1160: Security Deposit Cash</th>
                            <th scope="col">2101: Mgmt Held Security Deposits</th>
                            <th scope="col">Unpaid Bills Amount</th>
                            <th scope="col">Unsettled Payments</th>
                            <th scope="col">Unused Prepayment Amount</th>
                            <th scope="col">Reserve Amount</th>
                            <th scope="col">Work Order Estimates</th>
                            <th scope="col">Payout Method</th>
                            <th scope="col">Payout Amount</th>

                        </tr>
                        </thead>
                        <tbody>
                        {
                            ownerBalancePayoutCalculator?.owners.map(o => (

                                <React.Fragment key={o.uuid}>
                                    <tr>
                                        <th>
                                            <Checkbox
                                                id={"checkbox"}
                                                validateClass={''}
                                                value={o.include}
                                                setValue={(v: boolean) => {
                                                    o.include = v;
                                                    setOwnerBalancePayoutCalculator(Object.assign({}, ownerBalancePayoutCalculator));
                                                }}
                                                required={false}
                                                invalidFeedbackText={GenericInputRegex.helpText}
                                                label={""}
                                                disabled={false}
                                            />
                                        </th>
                                        <th>
                                            {o.name}
                                        </th>
                                        <th colSpan={9}></th>
                                        <th>{o.payoutMethod}</th>
                                        <th></th>
                                    </tr>
                                    {
                                        o.lineItems.map(li => (
                                            <tr key={li.uuid} className={getRowClass(o, li)}>
                                                <td colSpan={2}>


                                                </td>
                                                <td>{li.name}</td>
                                                <td>{currencyFormat.format(li.operating)}</td>
                                                <td>{currencyFormat.format(li.securityDeposits)}</td>
                                                <td>{currencyFormat.format(li.mgmtHeldSecurityDeposits)}</td>
                                                <td>{currencyFormat.format(li.unpaidBillsAmount)}</td>
                                                <td>{currencyFormat.format(li.pendingPayments)}</td>
                                                <td>{currencyFormat.format(li.unusedPrepaymentAmount)}</td>
                                                <td>{currencyFormat.format(li.reserveAmount)}</td>
                                                <td>{currencyFormat.format(li.workOrderEstimates)}</td>
                                                <td></td>
                                                <td>
                                                    <Input id={"amount"} validation={DollarAmountRegex.expression}
                                                           doValidate={formInputValidate}
                                                           value={li.paymentAmount + ""}
                                                           setValue={(v: string) => {
                                                               li.paymentAmount = parseFloat(v);
                                                               setOwnerBalancePayoutCalculator(Object.assign({}, ownerBalancePayoutCalculator));
                                                           }}
                                                           required={false}
                                                           disabled={o.include}
                                                           invalidFeedbackText={DollarAmountRegex.helpText}
                                                           type={"number"} label={""}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    <tr>
                                        <th colSpan={3}>


                                        </th>
                                        <th>{currencyFormat.format(o.totalOperating)}</th>
                                        <th>{currencyFormat.format(o.totalSecurityDeposits)}</th>
                                        <th>{currencyFormat.format(o.totalMgmtHeldSecurityDeposits)}</th>
                                        <th>{currencyFormat.format(o.totalUnpaidBillsAmount)}</th>
                                        <th>{currencyFormat.format(o.totalPendingPayments)}</th>
                                        <th>{currencyFormat.format(o.totalUnusedPrepaymentAmount)}</th>
                                        <th>{currencyFormat.format(o.totalReserveAmount)}</th>
                                        <th>{currencyFormat.format(o.totalWorkOrderEstimates)}</th>
                                        <th></th>
                                        <th>
                                            {currencyFormat.format(getOwnerPayoutTotal(o))}
                                        </th>
                                    </tr>
                                </React.Fragment>
                            ))
                        }
                        </tbody>
                        <tfoot>
                        <tr>
                            <th colSpan={3}></th>
                            <th>{currencyFormat.format(getTotalOperating())}</th>
                            <th colSpan={8}></th>
                            <th>{currencyFormat.format(getTotalPayout())}</th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </>
    }

    return <PageLayout title={"Create Owner Payout"}>
        {loading && <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>}
        {errorMessage && <div className="alert alert-danger" role="alert">
            {errorMessage}
        </div>}
        {!isReview && getSelectionView()}
        {isReview && getReviewView()}
    </PageLayout>
}

export default ViewOwnerPayoutCalculator;