import * as React from 'react';
import {FormEvent, useContext, useEffect, useState} from 'react';
import {
    FormElementValidation,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex,
    IdentificationCodeRegex,
    Lease,
    Property,
    Unit
} from "../../../types";
import Select from "../../Form/Select";
import Alert, {AlertType} from "../../Alert";
import {Link} from "react-router-dom";
import LoadingOverlay from "../../LoadingOverlay";
import Input from "../../Form/Input";
import {getProperty} from "../../../Api/PropertyApi";
import {getUnits} from "../../../Api/UnitApi";
import {getLeases} from "../../../Api/LeaseApi";
import PageLayout from "../../../Layouts/PageLayout";
import {useLocation} from "react-router";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";

const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

const SearchLeases: React.FunctionComponent = () => {

    const propertyId = useContext(PropertyIdContext)

    const { pathname } = useLocation()
    const [loading, setLoading] = useState(false);
    const [units, setUnits] = useState<Unit[]>([]);
    const [leases, setLeases] = useState<Lease[]>();
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState(AlertType.SUCCESS);

    //form
    const [property, setProperty] = useState<Property>();
    const [unit, setUnit] = useState("");
    const [startDate, setStartDate] = useState("");
    const [leaseIdentificationId, setLeaseIdentificationId] = useState("");

    const fetchUnits = (propertyId: string) => {
        setLoading(true);
        getUnits(propertyId, {})
            .then((data) => {
                    setUnits(data);
                }
            ).finally(() => setLoading(false));
    }

    const fetchLeases = (propertyId: string, unitId: string, startDate: string, leaseId: string) => {
        setLoading(true);
        getLeases(propertyId, unitId, startDate, leaseId)
            .then((data) => {
                setLeases(data);
            }).catch(e => {
            setLeases([]);
            doShowAlert("Failed to get leases", AlertType.DANGER);
        }).finally(() => setLoading(false));
    }

    const doShowAlert = (message: string, type: AlertType) => {
        setShowAlert(true);
        setAlertMessage(message);
        setAlertType(type);
    }

    const doHideAlert = () => {
        setShowAlert(false);
    }

    useEffect(() => {
        if (propertyId) {
            Promise.all([
                getProperty(propertyId),
                getUnits(propertyId, {}),
                getLeases(propertyId)
            ])
                .then(values => {
                    setProperty(values[0])
                    setUnits(values[1])
                    setLeases(values[2])
                })
        }
    }, [propertyId]);

    useEffect(() => {
        if (property) {
            fetchUnits(property?.uuid || "");
        }
    }, [property]);

    const doSearch = (e: FormEvent) => {
        e.preventDefault();
        doHideAlert();
        fetchLeases(property?.uuid || "", unit, startDate, leaseIdentificationId);
    }

    type Form = {
        unit: FormElementValidation,
        startDate: FormElementValidation,
        leaseIdentificationId: FormElementValidation
    }

    const form: Form = {

        unit: {
            id: "unit",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        startDate: {
            id: "startDate",
            required: false,
            validate: formSelectValidate,
            regex: GenericInputRegex
        },
        leaseIdentificationId: {
            id: "leaseIdentificationId",
            required: false,
            validate: formSelectValidate,
            regex: IdentificationCodeRegex
        }
    }

    return <>
        <Alert type={alertType} message={alertMessage} show={showAlert}/>
        {property ?
            <PageLayout title="Leases" property={property}>
                <h6>Filters</h6>
                <div className="col-lg-8 mb-3">
                    <form onSubmit={doSearch}>
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <Select id={form.unit.id} validation={form.unit.regex.expression}
                                        doValidate={form.unit.validate}
                                        value={unit} setValue={setUnit}
                                        required={false}
                                        invalidFeedbackText={form.unit.regex.helpText} label={"Unit"}>
                                    <option value={""} disabled={true}>Select a unit...</option>
                                    {
                                        units.map((u: Unit) => (
                                            <option key={u.uuid + "_p"}
                                                    value={u.uuid}>{u.name + " | " + u.floorPlan.name}</option>
                                        ))
                                    }
                                </Select>
                            </div>
                            <div className="col-md-6 mb-2">
                                <Input id={form.startDate.id} validation={form.startDate.regex.expression}
                                       doValidate={form.startDate.validate}
                                       value={startDate}
                                       setValue={setStartDate}
                                       required={form.startDate.required}
                                       invalidFeedbackText={form.startDate.regex.helpText}
                                       type={"date"} label={"Started After Date"}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <Input id={form.leaseIdentificationId.id} validation={form.leaseIdentificationId.regex.expression}
                                       doValidate={form.leaseIdentificationId.validate}
                                       value={leaseIdentificationId}
                                       setValue={setLeaseIdentificationId}
                                       required={form.leaseIdentificationId.required}
                                       invalidFeedbackText={form.leaseIdentificationId.regex.helpText}
                                       type={"text"} label={"Lease Id"}
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary mt-3">Apply Filters</button>
                    </form>
                </div>
                {loading ? <LoadingOverlay /> :
                    <div className={"table-responsive"}>
                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">Lease ID</th>
                                <th scope="col">Start Date</th>
                                <th scope="col">End Date</th>
                                <th scope="col">Due Date</th>
                                <th scope="col">Balance</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            {leases &&
                                leases.map((l, index) => (
                                    <tr key={"leas_" + index}>
                                        <td>{l.identificationId}</td>
                                        <td>{l.startDate}</td>
                                        <td>{l.endDate}</td>
                                        <td>{l.dueDate}</td>
                                        <td>{currencyFormat.format(l.balance)}</td>
                                        <td>
                                            <Link to={pathname + "/view/" + l.uuid}><button className="btn btn-sm btn-primary">View</button></Link>
                                        </td>
                                    </tr>
                                ))
                            }
                            </tbody>
                        </table>
                        {property && leases?.length === 0 && <p>No leases found for {property.name}</p>}
                    </div>
                }
            </PageLayout>
            :
            <LoadingOverlay />
        }
    </>
};

export default SearchLeases;
