import * as React from "react";
import {useEffect, useState} from "react";
import {BootstrapValidateClass, ValidateElementFunction} from "../../../types";

interface RangeProps {
    id: string,
    validation: RegExp,
    doValidate: ValidateElementFunction,
    value: string,
    setValue: Function,
    required: boolean,
    invalidFeedbackText: string,
    label: string,
    placeholder?: string,
    disabled?: boolean,
    setValidFlag?: Function,
    step?: number | string,
    min?: number | string,
    max?: number | string,
}

const Range: React.FunctionComponent<RangeProps> = (p) => {

    const [validateClass, setValidateClass] = useState(BootstrapValidateClass.unvalidated);
    const {value} = p;

    useEffect(() => {
        if (value.length === 0 || p.disabled) {
            setValidateClass(BootstrapValidateClass.unvalidated);
            if(p.setValidFlag) {
                p.setValidFlag(false);
            }
            return;
        }
        if (p.doValidate(value, p.required, p.validation)) {
            setValidateClass(BootstrapValidateClass.isvalid);
            if(p.setValidFlag) {
                p.setValidFlag(true);
            }
        } else {
            setValidateClass(BootstrapValidateClass.isinvalid);
            if(p.setValidFlag) {
                p.setValidFlag(false);
            }
        }

    }, [value, p]);

    return (<>
        <div className="row">
            <div className="col-md-12 mb-3">
                <label htmlFor={p.id}>{p.label}</label>
                <input type="range" className={"form-range " + validateClass}
                       id={p.id} disabled={p.disabled}
                       max={p.max} min={p.min} placeholder={p.placeholder}
                       required={p.required} value={p.value} step={p.step} onChange={e => {
                    let value = e.target.value.toString();
                    p.setValue(value);
                }}/>
                <div className="invalid-feedback">
                    {p.invalidFeedbackText}
                </div>
            </div>
        </div>
    </>)
}

export default Range;
