import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {
    ApplicationStatus,
    FormElementValidation, formInputValidate,
    formSelectValidate, FullNameRegex,
    Gender, GenericInputRegex, GenericNumberRegex,
    GenericSelectRegex,
    Pet,
    PetType
} from "../../../types";
import Alert, {AlertProps, AlertType} from "../../Alert";
import {useHistory, useParams} from "react-router";
import {getPortfolioId} from "../../../helpers";
import LoadingOverlay from "../../LoadingOverlay";
import Select from "../../Form/Select";
import Input from "../../Form/Input";
import TextArea from "../../Form/TextArea";
import {getApplication, updateApplication} from "../../../Api/ApplicationApi";
import ApplicationStageLayout from "../../../Layouts/ApplicationStageLayout";
import ButtonNew, {ButtonColors, ButtonTypes} from "../../ButtonNew";

type Stage5Props = {
    handleInvalidApplicationStatus: Function
    applicationId: string
    setStage: Function
}

const Stage5: React.FunctionComponent<Stage5Props> = (p) => {

    const { propertyId } = useParams();

    const [error, setError] = useState<Error>()
    const [alert, setAlert] = useState<AlertProps>()
    const [loading, setLoading] = useState(false);
    const [doRedirectToStage4, setDoRedirectToStage4] = useState(false);
    const [doRedirectToStage6, setDoRedirectToStage6] = useState(false);
    const history = useHistory();

    const [doRedirectToStage1, setDoRedirectToStage1] = useState(false);
    const [doHandleInvalidApplicationStatus, setDoHandleInvalidApplicationStatus] = useState(false);

    //form
    const [pets, setPets] = useState(new Array<Pet>());

    const [petType, setPetType] = useState("");
    const [name, setName] = useState("");
    const [breed, setBreed] = useState("");
    const [weight, setWeight] = useState("");
    const [color, setColor] = useState("");
    const [age, setAge] = useState("");
    const [petLocation, setPetLocation] = useState("");
    const [declawed, setDeclawed] = useState("");
    const [gender, setGender] = useState("");
    const [neutered, setNeutered] = useState("");
    const [vaccinationsUpToDate, setVaccinationsUpToDate] = useState("");
    const [petTypeDescription, setPetTypeDescription] = useState("");
    const [description, setDescription] = useState("");

    type Form = {
        petType: FormElementValidation,
        name: FormElementValidation,
        breed: FormElementValidation,
        weight: FormElementValidation,
        color: FormElementValidation,
        age: FormElementValidation,
        petLocation: FormElementValidation,
        declawed: FormElementValidation,
        gender: FormElementValidation,
        neutered: FormElementValidation,
        vaccinationsUpToDate: FormElementValidation,
        petTypeDescription: FormElementValidation,
        description: FormElementValidation
    }


    const form: Form = {
        petType: {
            id: "petType",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        name: {
            id: "name",
            required: true,
            validate: formInputValidate,
            regex: FullNameRegex
        },
        breed: {
            id: "breed",
            required: true,
            validate: formInputValidate,
            regex: FullNameRegex
        },
        weight: {
            id: "weight",
            required: true,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        color: {
            id: "color",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        age: {
            id: "age",
            required: true,
            validate: formInputValidate,
            regex: GenericNumberRegex
        },
        petLocation: {
            id: "petLocation",
            required: true,
            validate: formInputValidate,
            regex: FullNameRegex
        },
        petTypeDescription: {
            id: "petTypeDescription",
            required: true,
            validate: formInputValidate,
            regex: FullNameRegex
        },
        description: {
            id: "description",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex
        },
        declawed: {
            id: "declawed",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        gender: {
            id: "gender",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        neutered: {
            id: "neutered",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
        vaccinationsUpToDate: {
            id: "vaccinationsUpToDate",
            required: true,
            validate: formSelectValidate,
            regex: GenericSelectRegex
        },
    };


    useEffect(() => {
        if (doHandleInvalidApplicationStatus) {
            p.handleInvalidApplicationStatus();
        }
    }, [doHandleInvalidApplicationStatus, p]);

    useEffect(() => {
        if (doRedirectToStage1) {
            setDoRedirectToStage1(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/1"
            });
        }

    }, [doRedirectToStage1, history])

    useEffect(() => {
        console.log("stage 5 init");
        p.setStage(5)
        setLoading(true);
        getApplication(getPortfolioId(), p.applicationId)
            .then((a) => {
                if (a.status !== ApplicationStatus.approved) {
                    if (!a.unit || (a.unit && !a.unit.uuid)) {
                        console.log("redirect to stage 1, missing unit")
                        setDoRedirectToStage1(true);
                        return;
                    }
                    setPets(a.pets || []);
                    setLoading(false);
                } else {
                    setDoHandleInvalidApplicationStatus(true);
                }
            })
            .catch(e => {
                doShowAlert("Failed to start application: " + e.message, AlertType.DANGER);
                setLoading(false);
            })


    }, [p])

    const saveAndContinueStage6 = () => {
        setLoading(true);
        updateApplication(getPortfolioId(), p.applicationId, {
            pets: pets
        })
            .then((a) => {
                setDoRedirectToStage6(true);
            })
            .catch(e => {
                doShowAlert("Failed to add addresses: " + e.message, AlertType.DANGER);
            }).finally(() => setLoading(false));
    }

    const clearForm = () => {
        setName("");
        setBreed("");
        setWeight("");
        setColor("");
        setAge("");
        setPetLocation("");
        setDeclawed("");
        setGender("");
        setNeutered("");
        setVaccinationsUpToDate("");
        setPetType("")
        setPetTypeDescription("");
        setDescription("");
    }

    const doShowAlert = (message: string, type: AlertType) => {
        setAlert({type, message})
    }

    useEffect(() => {
        if (doRedirectToStage6) {
            setDoRedirectToStage6(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/6"
            });
        }

    }, [doRedirectToStage6, history])

    useEffect(() => {
        if (doRedirectToStage4) {
            setDoRedirectToStage4(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/4"
            });
        }

    }, [doRedirectToStage4, history])


    const validateForm = (form: Form): boolean => {
        if (!form.petType.validate(petType, form.petType.required, form.petType.regex.expression)) {
            doShowAlert("Pet type option required.", AlertType.DANGER);
            return false;
        }
        if (!form.name.validate(name, form.name.required, form.name.regex.expression)) {
            doShowAlert("Invalid name.", AlertType.DANGER);
            return false;
        }
        if (!form.breed.validate(breed, form.breed.required, form.breed.regex.expression)) {
            doShowAlert("Invalid breed.", AlertType.DANGER);
            return false;
        }
        if (!form.weight.validate(weight, form.weight.required, form.weight.regex.expression)) {
            doShowAlert("Invalid weight.", AlertType.DANGER);
            return false;
        }
        if (!form.color.validate(color, form.color.required, form.color.regex.expression)) {
            doShowAlert("Invalid color.", AlertType.DANGER);
            return false;
        }
        if (!form.age.validate(age, form.age.required, form.age.regex.expression)) {
            doShowAlert("Invalid age.", AlertType.DANGER);
            return false;
        }
        if (!form.petLocation.validate(petLocation, form.petLocation.required, form.petLocation.regex.expression)) {
            doShowAlert("Invalid pet location.", AlertType.DANGER);
            return false;
        }
        if (!form.declawed.validate(declawed, form.declawed.required, form.declawed.regex.expression)) {
            doShowAlert("Declawed option required.", AlertType.DANGER);
            return false;
        }
        if (!form.gender.validate(gender, form.gender.required, form.gender.regex.expression)) {
            doShowAlert("Gender option required.", AlertType.DANGER);
            return false;
        }
        if (!form.neutered.validate(neutered, form.neutered.required, form.neutered.regex.expression)) {
            doShowAlert("Neutered option required.", AlertType.DANGER);
            return false;
        }
        if (!form.vaccinationsUpToDate.validate(vaccinationsUpToDate, form.vaccinationsUpToDate.required, form.vaccinationsUpToDate.regex.expression)) {
            doShowAlert("Vaccinations selection required.", AlertType.DANGER);
            return false;
        }
        if (!form.petTypeDescription.validate(petTypeDescription, (petType === "other" ? true : false), form.petTypeDescription.regex.expression)) {
            doShowAlert("Invalid type description.", AlertType.DANGER);
            return false;
        }
        if (!form.description.validate(description, form.description.required, form.description.regex.expression)) {
            doShowAlert("Invalid description.", AlertType.DANGER);
            return false;
        }
        return true;
    }


    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (!validateForm(form)) {
            return;
        }
        pets.push({
            uuid: "",
            age: parseFloat(age),
            breed: breed,
            color: color,
            declawed: (declawed === 'yes'),
            description: description,
            gender: Gender[gender as keyof typeof Gender],
            name: name,
            neutered: (neutered === 'yes'),
            petLocation: petLocation,
            petType: PetType[petType as keyof typeof PetType],
            otherPetTypeDescription: petTypeDescription,
            vaccinationsUpToDate: (vaccinationsUpToDate === 'yes'),
            weight: parseFloat(weight)
        });
        setPets([...pets])
        clearForm();
    }

    const deletePet = (p: Pet) => {
        setPets(pets.filter(pet => pet !== p));
    }

    return (loading ? <div className="col-md-6"><LoadingOverlay /></div> : <>
        <ApplicationStageLayout
            error={error}
            alert={alert}
            title="Pets"
            description="Please provide pet information."
        >
            <form onSubmit={onSubmit}>
                <div className={"col-lg-6 col-md-8 mb-5"}>
                    <div className={"row"}>
                        <div className="col-md-12 mb-2">
                            <Select id={form.petType.id} validation={form.petType.regex.expression}
                                    doValidate={form.petType.validate}
                                    value={petType} setValue={setPetType}
                                    required={form.petType.required}
                                    invalidFeedbackText={form.petType.regex.helpText} label={"Type"}>
                                <option value={""} disabled={true}>Select pet type...</option>
                                <option value={"dog"}>Dog</option>
                                <option value={"cat"}>Cat</option>
                                <option value={"other"}>Other</option>
                            </Select>
                        </div>
                        <div hidden={(petType !== "other" ? true : false)} className={"col-md-6 mb-2"}>

                            <Input id={form.petTypeDescription.id} validation={form.petTypeDescription.regex.expression}
                                   doValidate={form.petTypeDescription.validate}
                                   value={petTypeDescription}
                                   setValue={setPetTypeDescription}
                                   required={(petType === "other") ? true : false}
                                   invalidFeedbackText={form.petTypeDescription.regex.helpText}
                                   type={"text"} label={"Type description"}
                            />
                        </div>
                        <div className={"col-md-6 mb-2"}>

                            <Input id={form.name.id} validation={form.name.regex.expression}
                                   doValidate={form.name.validate}
                                   value={name}
                                   setValue={setName}
                                   required={form.name.required}
                                   invalidFeedbackText={form.name.regex.helpText}
                                   type={"text"} label={"Name"}
                            />
                        </div>
                        <div className={"col-md-6 mb-2"}>

                            <Input id={form.breed.id} validation={form.breed.regex.expression}
                                   doValidate={form.breed.validate}
                                   value={breed}
                                   setValue={setBreed}
                                   required={form.breed.required}
                                   invalidFeedbackText={form.breed.regex.helpText}
                                   type={"text"} label={"Breed"}
                            />
                        </div>
                        <div className={"col-md-6 mb-2"}>

                            <Input id={form.weight.id} validation={form.weight.regex.expression}
                                   doValidate={form.weight.validate}
                                   value={weight}
                                   setValue={setWeight}
                                   required={form.weight.required}
                                   invalidFeedbackText={form.weight.regex.helpText}
                                   type={"number"} label={"Weight (lbs)"}
                            />
                        </div>
                        <div className={"col-md-6 mb-2"}>

                            <Input id={form.color.id} validation={form.color.regex.expression}
                                   doValidate={form.color.validate}
                                   value={color}
                                   setValue={setColor}
                                   required={form.color.required}
                                   invalidFeedbackText={form.color.regex.helpText}
                                   type={"text"} label={"Color"}
                            />
                        </div>
                        <div className={"col-md-6 mb-2"}>

                            <Input id={form.age.id} validation={form.age.regex.expression}
                                   doValidate={form.age.validate}
                                   value={age}
                                   setValue={setAge}
                                   required={form.age.required}
                                   invalidFeedbackText={form.age.regex.helpText}
                                   type={"number"} label={"Age (years)"}
                            />
                        </div>
                        <div className={"col-md-6 mb-2"}>

                            <Input id={form.petLocation.id} validation={form.petLocation.regex.expression}
                                   doValidate={form.petLocation.validate}
                                   value={petLocation}
                                   setValue={setPetLocation}
                                   required={form.petLocation.required}
                                   invalidFeedbackText={form.petLocation.regex.helpText}
                                   type={"text"} label={"Where is the pet kept?"}
                            />
                        </div>
                        <div className="col-md-6 mb-2">

                            <Select id={form.declawed.id} validation={form.declawed.regex.expression}
                                    doValidate={form.declawed.validate}
                                    value={declawed} setValue={setDeclawed}
                                    required={form.declawed.required}
                                    invalidFeedbackText={form.declawed.regex.helpText}
                                    label={"Declawed?"}>
                                <option value={""} disabled={true}>Select an option...</option>
                                <option value={"yes"}>Yes</option>
                                <option value={"no"}>No</option>
                            </Select>
                        </div>
                        <div className="col-md-6 mb-2">

                            <Select id={form.gender.id} validation={form.gender.regex.expression}
                                    doValidate={form.gender.validate}
                                    value={gender} setValue={setGender}
                                    required={form.gender.required}
                                    invalidFeedbackText={form.gender.regex.helpText}
                                    label={"Gender"}>
                                <option value={""} disabled={true}>Select a gender...</option>
                                <option value={Gender["male"]}>Male</option>
                                <option value={Gender["female"]}>Female</option>
                            </Select>
                        </div>
                        <div className="col-md-6 mb-2">

                            <Select id={form.neutered.id} validation={form.neutered.regex.expression}
                                    doValidate={form.neutered.validate}
                                    value={neutered} setValue={setNeutered}
                                    required={form.neutered.required}
                                    invalidFeedbackText={form.neutered.regex.helpText}
                                    label={"Neutered"}>
                                <option value={""} disabled={true}>Select an option...</option>
                                <option value={"yes"}>Yes</option>
                                <option value={"no"}>No</option>
                            </Select>
                        </div>
                        <div className="col-md-6 mb-2">

                            <Select id={form.vaccinationsUpToDate.id}
                                    validation={form.vaccinationsUpToDate.regex.expression}
                                    doValidate={form.vaccinationsUpToDate.validate}
                                    value={vaccinationsUpToDate} setValue={setVaccinationsUpToDate}
                                    required={form.vaccinationsUpToDate.required}
                                    invalidFeedbackText={form.vaccinationsUpToDate.regex.helpText}
                                    label={"Vaccinations up to date?"}>
                                <option value={""} disabled={true}>Select an option...</option>
                                <option value={"yes"}>Yes</option>
                                <option value={"no"}>No</option>
                            </Select>
                        </div>

                        <div className={"col-md-12 mb-2"}>

                            <TextArea id={form.description.id} validation={form.description.regex.expression}
                                      doValidate={form.description.validate}
                                      value={description}
                                      setValue={setDescription}
                                      required={form.description.required}
                                      invalidFeedbackText={form.description.regex.helpText}
                                      rows={3} label={"Describe pet"}
                            />
                        </div>
                        <div className={"col-md-12 mt-3 mb-2"}>
                            <button type="submit" className="btn btn-primary">Add pet</button>
                        </div>
                    </div>
                </div>
            </form>
            <div className={"row"}>
                <div className={"col-md-12 mb-2"}>
                    <div className={"table-responsive"}>
                        <table className="table table-striped">
                            <thead>
                            <tr>

                                <th scope="col">Name</th>
                                <th scope="col">Type</th>
                                <th scope="col">Information</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                pets.map((p, index) => (
                                    <tr key={"pet_" + index}>
                                        <td>{p.name}</td>
                                        <td>{p.petType}</td>
                                        <td>{(p.otherPetTypeDescription ? p.otherPetTypeDescription + ", " : "")
                                            + p.color + ", " + p.weight + " lbs, " + p.age + " years old, "
                                            + p.gender + ", "
                                            + (p.neutered ? "neutered, " : "not neutered, ")
                                            + (p.vaccinationsUpToDate ? "vaccines up to date, " : "vaccines not up to date, ")
                                            + (p.declawed ? "declawed, " : "not declawed, ") + p.petLocation + ", "
                                            + p.description}
                                        </td>
                                        <td>
                                            <button type="button" className="btn btn-danger btn-sm"
                                                    onClick={e => deletePet(p)}
                                            >Remove
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }


                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="d-flex justify-content-start gap-2 mt-3">
                    <ButtonNew
                        type={ButtonTypes.BUTTON}
                        color={ButtonColors.SECONDARY}
                        label="Back"
                        onClick={() => setDoRedirectToStage4(true)}
                    />
                    <ButtonNew
                        type={ButtonTypes.BUTTON}
                        color={ButtonColors.PRIMARY}
                        label="Save & Continue"
                        loading={loading}
                        onClick={saveAndContinueStage6}
                    />
                </div>
            </div>
        </ApplicationStageLayout>
    </>);
};

export default Stage5;
