import * as React from 'react';
import {useEffect, useState} from "react";
import {
    APP_CREATE_PORTFOLIO,
    APP_LOGIN_PATH,
    APP_PORTFOLIO_ID,
    APP_PORTFOLIO_LANDING,
    APP_PORTFOLIO_SELECT_PATH, APP_PROPERTY_ID,
    Portfolio
} from "../../types";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import {getPortfolios} from "../../Api/PortfolioApi";
import {Link} from "react-router-dom";
import {doLogoutRedirect} from "../../Auth";
import './portfolio-selection.css';

const PortfolioSelection: React.FunctionComponent = () => {

    let [portfolios, setPortfolios] = useState<Portfolio[]>([]);
    let [doRedirectToLanding, setDoRedirectToLanding] = useState(false);
    let [doRedirectToCreatePortfolio, setDoRedirectToCreatePortfolio] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const fullPath = location.pathname;


    useEffect(() => {
        getPortfolios()
            .then((data) => {
                setPortfolios(data);
                if (data.length === 0) {
                    setDoRedirectToCreatePortfolio(true);
                }
            })
            .catch((e) => console.error(e))
    }, []);

    useEffect(() => {
        if (doRedirectToLanding) {
            let redirectPath = new URLSearchParams(location.search).get("redirect_path");
            setDoRedirectToLanding(false);
            if (redirectPath) {
                history.push({
                    pathname: redirectPath,
                })
            } else {
                history.push({
                    pathname: APP_PORTFOLIO_LANDING,
                })
            }
        }
    }, [doRedirectToLanding, history]);

    useEffect(() => {
        if (doRedirectToCreatePortfolio) {
            setDoRedirectToCreatePortfolio(false);
            history.push({
                pathname: APP_CREATE_PORTFOLIO,
            })
        }
    }, [doRedirectToCreatePortfolio, history]);


    const onPortfolioClick = (portfolio: Portfolio) => {
        localStorage.setItem(APP_PORTFOLIO_ID, portfolio.uuid);
        localStorage.setItem(APP_PROPERTY_ID, "")
        setDoRedirectToLanding(true);
    };

    return ((!portfolios) ? <>Loading...</> : <>
        <nav className="navbar navbar-dark sticky-top bg-theme-1 flex-md-nowrap shadow">
            <Link className="navbar-brand col-md-3 col-lg-2 mr-0 px-3"
                  to={APP_PORTFOLIO_LANDING}>
                <img width={200} src={"/assets/dist/img/propertymq_logo.png"}/>

            </Link>
            <button className="navbar-toggler position-absolute d-md-none collapsed" type="button"
                    data-bs-toggle="collapse" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <ul className="navbar-nav px-3">
                <li className="nav-item text-nowrap">

                    <button type="button" className="ms-3 btn btn-signup bg-theme-4 border-theme-4"
                            onClick={(e) => {
                                localStorage.clear();
                                doLogoutRedirect();
                            }}>
                        Logout
                    </button>
                </li>
            </ul>
        </nav>
        <div className={"row justify-content-md-center"}>
            <div className={"col-lg-4 mb-3"}>
                <div id={"container"} className={"container mt-3 text-center pb-3"}>
                    <div className={"col mb-3"}>
                        <h1 className="h3 mb-3 font-weight-normal">Choose a Portfolio</h1>
                        <p>Your account is registered with one or more portfolios. Select a portfolio from the list
                            below to
                            proceed or <Link to={"/p/s/create-portfolio"}> add a new one.</Link></p>
                    </div>
                    <div className={"col mb-3"}>
                        <div className="list-group">
                            {
                                portfolios.map(p => (
                                    <button onClick={e => onPortfolioClick(p)} key={p.uuid} type="button"
                                            className="list-group-item-p list-group-item-action-p">
                                        {p.name}
                                    </button>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </>);
};

export default PortfolioSelection;
