import * as React from 'react';
import {FormEvent, useCallback, useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import FormFieldBuilder, {
    assignFormFieldValues,
    SelectOptions
} from "../../../Components/Form/FieldBuilder/FormFieldBuilder";
import {addUserWithRoles, deleteUserInPortfolio, getRoles, getUserInPortfolio} from "../../../Api/UserApi";
import {useHistory, useParams} from "react-router";
import EditUserForm from "../Forms/EditUserForm";
import Alert, {AlertType} from "../../../Components/Alert";
import PageLayout from "../../../Layouts/PageLayout";
import Card from "../../../Components/Card";


const EditUser: React.FunctionComponent = () => {

    const [email, setEmail] = useState("");
    const [selectedRole, setSelectedRole] = useState("");
    const [roleOptions, setRoleOptions] = useState<SelectOptions[]>([]);
    const [error, setError] = useState("");

    const history = useHistory();
    const {userId} = useParams();

    assignFormFieldValues({field: EditUserForm.email, value: email, setValue: setEmail});
    assignFormFieldValues({field: EditUserForm.roles, value: selectedRole, setValue: setSelectedRole});

    const load = useCallback(() => {
        getRoles()
            .then((rz) => {
                let options: SelectOptions[] = []
                options.push({
                    value: "",
                    label: "Please select...",
                    disabled: true
                })
                rz.forEach((r) => {
                    options.push({
                        value: r.uuid,
                        disabled: false,
                        label: r.name
                    })
                })
                setRoleOptions(options);
            })
            .catch((e) => console.error(e));


        getUserInPortfolio(userId)
            .then((r) => {
                setEmail(r.email);
                if (r.roles) {
                    setSelectedRole(r.roles[0].uuid)
                }

            })
            .catch((e) => console.error(e));
    }, [])

    useEffect(() => {
        load();
    }, []);

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setError("");
        addUserWithRoles({
            email: email,
            roles: [{
                uuid: selectedRole
            }]
        }).then((r) => {
            history.push({
                pathname: "/p/m/settings/users"
            })
        }).catch((e) => setError(e.message))
    }

    const deleteUser = () => {
        setError("");
        deleteUserInPortfolio(userId)
            .then((r) => {
                history.push({
                    pathname: "/p/m/settings/users"
                })
            })
            .catch((e) => setError(e.message));
    }

    return (<>
        <PageLayout title="Edit User">
            <div className="col-lg-6">
                {error && <Alert type={AlertType.DANGER} message={error}/>}
                <Card title="Details">
                    <form onSubmit={onSubmit}>
                        <div className={"row"}>
                            <div className={"col mb-3"}>
                                <FormFieldBuilder field={EditUserForm.email}/>
                            </div>
                            <div className={"col mb-3"}>
                                <FormFieldBuilder field={EditUserForm.roles}
                                                  selectOptions={roleOptions}/>
                            </div>

                            <div className="d-flex justify-content-between gap-2 mt-3">
                                <div className="d-flex justify-content-start gap-2">
                                    <Link to={"/p/m/settings/users"}>
                                        <button type="button" className="btn btn-secondary">Cancel</button>
                                    </Link>
                                    <button type="button" className="btn btn-link text-danger text-decoration-none" onClick={() => {
                                        deleteUser();
                                    }}>Delete</button>
                                </div>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </div>
                    </form>
                </Card>
            </div>
        </PageLayout>
        <div id="container" className={"row p-3 mb-3"}>
            <div className={"col-md-6 mb-3"}>

            </div>
        </div>
    </>);
};

export default EditUser;