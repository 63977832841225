import * as React from "react";
import {Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import SearchVendorInvoices from "./SearchVendorInvoices";

const VendorInvoiceRouter: React.FunctionComponent = () => {
    let {path} = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path + "/"}>
                <SearchVendorInvoices />
            </Route>
        </Switch>
    )
}

export default VendorInvoiceRouter;