import * as React from 'react';
import {FormEvent, useEffect, useState} from 'react';
import {
    MaintenanceRequest,
    MaintenanceRequestStatus,
    updateMaintenanceRequest
} from "../../../../Api/MaintenanceRequestApi";
import {AppUser} from "../../../../types";
import Input from "../../../Form/Input";
import Select from "../../../Form/Select";
import TextArea from "../../../Form/TextArea";
import EditMaintenanceRequestForm from "../../Forms/EditMaintenanceRequestForm";
import {getMaintenanceRequestCategories} from "../../../../Api/MaintenanceRequestCategoriesApi";
import {getUsersWithMaintenanceRole} from "../../../../Api/UserApi";
import LoadingOverlay from "../../../LoadingOverlay";
import {useParams} from "react-router";

interface EditMaintenanceRequestParam {
    maintenanceRequest: MaintenanceRequest | undefined,
    done: Function
}

const EditMaintenanceRequest: React.FunctionComponent<EditMaintenanceRequestParam> = ({maintenanceRequest, done}) => {

    const { propertyId, maintenanceRequestId } = useParams();

    const [loading, setLoading] = useState<boolean>(true);

    const [category, setCategory] = useState<string>("");
    const [scheduledDate, setScheduledDate] = useState<string>("");
    const [technicalNotes, setTechnicalNotes] = useState<string>("");
    const [assignedMaintenanceUserId, setAssignedMaintenanceUserId] = useState<string>("");
    const [status, setStatus] = useState<MaintenanceRequestStatus>(MaintenanceRequestStatus.UNKNOWN);

    const [categories, setCategories] = useState<string[]>();
    const [maintenanceUsers, setMaintenanceUsers] = useState<AppUser[]>([]);

    const setFields = () => {
        if (maintenanceRequest) {
            setCategory(maintenanceRequest.category);
            setTechnicalNotes(maintenanceRequest.technicalNotes || '');
            setScheduledDate(maintenanceRequest.scheduledDate || '');
            setAssignedMaintenanceUserId(maintenanceRequest.assignedMaintenanceUser?.uuid || '');
            setStatus(maintenanceRequest.status);
        }
    }

    useEffect(() => {
        setFields();
        Promise.all([
            getMaintenanceRequestCategories()
                .then(data => data),
            getUsersWithMaintenanceRole(propertyId)
                .then(data => data),
        ])
            .then(values => {
                setCategories(values[0]);
                setMaintenanceUsers(values[1]);
            })
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [propertyId, maintenanceRequestId])

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (maintenanceRequest) {
            updateMaintenanceRequest(maintenanceRequest.uuid,
                {
                    category: category,
                    technicalNotes: technicalNotes,
                    scheduledDate: scheduledDate,
                    assignedMaintenanceUserId: assignedMaintenanceUserId,
                    status: status
                })
                .then(() => done())
                .catch(console.error)
        }
    }

    const onReset = (e: FormEvent) => {
        e.preventDefault();
        setFields();
    }

    return (<>
        {loading ? <LoadingOverlay show={loading} /> :
            <form onSubmit={onSubmit} onReset={onReset}>
                <div className="row">
                    <div className="col-md-6 mb-1">
                        <Select
                            id={EditMaintenanceRequestForm.category.id}
                            validation={EditMaintenanceRequestForm.category.regex.expression}
                            doValidate={EditMaintenanceRequestForm.category.validate}
                            value={category}
                            setValue={setCategory}
                            required={EditMaintenanceRequestForm.category.required}
                            invalidFeedbackText={EditMaintenanceRequestForm.category.regex.helpText}
                            label={"Category"}
                        >
                            <option value={""} disabled={true}>Select Category...</option>
                            {
                                categories?.map(c => (
                                    <option key={c + "_mrc"}
                                            value={c}>{c}</option>
                                ))
                            }
                        </Select>
                    </div>
                    <div className="col-md-6 mb-1">
                        <TextArea
                            id={EditMaintenanceRequestForm.description.id}
                            validation={EditMaintenanceRequestForm.description.regex.expression}
                            doValidate={EditMaintenanceRequestForm.description.validate}
                            value={maintenanceRequest?.description || ''}
                            setValue={() => {}}
                            required={EditMaintenanceRequestForm.description.required}
                            invalidFeedbackText={EditMaintenanceRequestForm.description.regex.helpText}
                            rows={3}
                            label={"Description"}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-1">
                        <Input
                            id={EditMaintenanceRequestForm.entryPermission.id}
                            validation={EditMaintenanceRequestForm.entryPermission.regex.expression}
                            doValidate={EditMaintenanceRequestForm.entryPermission.validate}
                            value={maintenanceRequest?.entryPermission ? "Yes" : "No"}
                            setValue={() => {}}
                            required={EditMaintenanceRequestForm.entryPermission.required}
                            invalidFeedbackText={EditMaintenanceRequestForm.entryPermission.regex.helpText}
                            type="text"
                            label="Entry Permission"
                            disabled={true}
                        />
                    </div>
                    <div className="col-md-6 mb-1">
                        <TextArea
                            id={EditMaintenanceRequestForm.accessDetails.id}
                            validation={EditMaintenanceRequestForm.accessDetails.regex.expression}
                            doValidate={EditMaintenanceRequestForm.accessDetails.validate}
                            value={maintenanceRequest?.accessDetails || ''}
                            setValue={() => {}}
                            required={EditMaintenanceRequestForm.accessDetails.required}
                            invalidFeedbackText={EditMaintenanceRequestForm.accessDetails.regex.helpText}
                            rows={3}
                            label={"Access Details"}
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-1">
                        <div className="row">
                            <div className="col-md-12 mb-1">
                                <Select
                                    id={EditMaintenanceRequestForm.assignedMaintenanceUser.id}
                                    validation={EditMaintenanceRequestForm.assignedMaintenanceUser.regex.expression}
                                    doValidate={EditMaintenanceRequestForm.assignedMaintenanceUser.validate}
                                    value={assignedMaintenanceUserId}
                                    setValue={setAssignedMaintenanceUserId}
                                    required={EditMaintenanceRequestForm.assignedMaintenanceUser.required}
                                    invalidFeedbackText={EditMaintenanceRequestForm.assignedMaintenanceUser.regex.helpText}
                                    label={"Ticket Assigned"}
                                >
                                    <option value={""} disabled={true}>Select a Maintenance User...</option>
                                    {
                                        maintenanceUsers?.map((u: AppUser) => (
                                            <option key={u.uuid + "_p"}
                                                    value={u.uuid}>{u.firstName + " " + u.lastName}</option>
                                        ))
                                    }
                                </Select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-1">
                                <Input id={EditMaintenanceRequestForm.scheduledDate.id}
                                       validation={EditMaintenanceRequestForm.scheduledDate.regex.expression}
                                       doValidate={EditMaintenanceRequestForm.scheduledDate.validate}
                                       value={scheduledDate}
                                       setValue={setScheduledDate}
                                       required={EditMaintenanceRequestForm.scheduledDate.required}
                                       invalidFeedbackText={EditMaintenanceRequestForm.scheduledDate.regex.helpText}
                                       type={"date"}
                                       label={"Scheduled Date"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-1">
                        <TextArea
                            id={EditMaintenanceRequestForm.technicalNotes.id}
                            validation={EditMaintenanceRequestForm.technicalNotes.regex.expression}
                            doValidate={EditMaintenanceRequestForm.technicalNotes.validate}
                            value={technicalNotes}
                            setValue={setTechnicalNotes}
                            required={EditMaintenanceRequestForm.technicalNotes.required}
                            invalidFeedbackText={EditMaintenanceRequestForm.technicalNotes.regex.helpText}
                            rows={5}
                            label="Notes"
                        />
                        <Select
                            id={EditMaintenanceRequestForm.status.id}
                            validation={EditMaintenanceRequestForm.status.regex.expression}
                            doValidate={EditMaintenanceRequestForm.status.validate}
                            value={status}
                            setValue={setStatus}
                            required={EditMaintenanceRequestForm.status.required}
                            invalidFeedbackText={EditMaintenanceRequestForm.status.regex.helpText}
                            label={"Status"}
                        >
                            <option value="" disabled={true}>Select a status...</option>
                            <option value={MaintenanceRequestStatus.OPEN}>Open</option>
                            <option value={MaintenanceRequestStatus.COMPLETE}>Complete</option>
                        </Select>
                    </div>
                </div>
                <div className="d-flex justify-content-start gap-2 mt-3">
                    <button type="reset" className="btn btn-outline-primary">Reset</button>
                    <button type="submit" className="btn btn-primary">Save</button>
                </div>
            </form>
        }
        </>
    )
}

export default EditMaintenanceRequest;
