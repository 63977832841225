import * as React from 'react';
import {MaintenanceRequest} from "../../../../Api/MaintenanceRequestApi";
import Input from "../../../Form/Input";
import OverviewMaintenanceRequestForm from "../../Forms/OverviewMaintenanceRequestForm";
import TextArea from "../../../Form/TextArea";
import moment from "moment/moment";

type OverviewParams = {
    maintenanceRequest: MaintenanceRequest | undefined
}

const Overview: React.FunctionComponent<OverviewParams> = ({maintenanceRequest}) => {

    return <>
        <div className="row">
            <div className="col-md-6 mb-1">
                <Input
                    id={OverviewMaintenanceRequestForm.category.id}
                    validation={OverviewMaintenanceRequestForm.category.regex.expression}
                    doValidate={OverviewMaintenanceRequestForm.category.validate}
                    value={maintenanceRequest?.category || ''}
                    setValue={() => {}}
                    required={OverviewMaintenanceRequestForm.category.required}
                    invalidFeedbackText={OverviewMaintenanceRequestForm.category.regex.helpText}
                    type="text"
                    label="Category"
                    disabled={true}
                />
            </div>
            <div className="col-md-6 mb-1">
                <TextArea
                    id={OverviewMaintenanceRequestForm.description.id}
                    validation={OverviewMaintenanceRequestForm.description.regex.expression}
                    doValidate={OverviewMaintenanceRequestForm.description.validate}
                    value={maintenanceRequest?.description || ''}
                    setValue={() => {}}
                    required={OverviewMaintenanceRequestForm.description.required}
                    invalidFeedbackText={OverviewMaintenanceRequestForm.description.regex.helpText}
                    rows={3}
                    label="Description"
                    disabled={true}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-6 mb-1">
                <Input
                    id={OverviewMaintenanceRequestForm.entryPermission.id}
                    validation={OverviewMaintenanceRequestForm.entryPermission.regex.expression}
                    doValidate={OverviewMaintenanceRequestForm.entryPermission.validate}
                    value={maintenanceRequest?.entryPermission ? "Yes" : "No"}
                    setValue={() => {}}
                    required={OverviewMaintenanceRequestForm.entryPermission.required}
                    invalidFeedbackText={OverviewMaintenanceRequestForm.entryPermission.regex.helpText}
                    type="text"
                    label="Entry Permission"
                    disabled={true}
                />
            </div>
            <div className="col-md-6 mb-1">
                <TextArea
                    id={OverviewMaintenanceRequestForm.accessDetails.id}
                    validation={OverviewMaintenanceRequestForm.accessDetails.regex.expression}
                    doValidate={OverviewMaintenanceRequestForm.accessDetails.validate}
                    value={maintenanceRequest?.accessDetails || ''}
                    setValue={() => {}}
                    required={OverviewMaintenanceRequestForm.accessDetails.required}
                    invalidFeedbackText={OverviewMaintenanceRequestForm.accessDetails.regex.helpText}
                    rows={3}
                    label="Access Details"
                    disabled={true}
                />
            </div>
        </div>
        <div className="row">
            <div className="col-md-6 mb-1">
                <div className="row">
                    <div className="col-md-12 mb-1">
                        <Input
                            id={OverviewMaintenanceRequestForm.assignedMaintenanceUser.id}
                            validation={OverviewMaintenanceRequestForm.assignedMaintenanceUser.regex.expression}
                            doValidate={OverviewMaintenanceRequestForm.assignedMaintenanceUser.validate}
                            value={maintenanceRequest?.assignedMaintenanceUser ? maintenanceRequest.assignedMaintenanceUser.firstName + " " + maintenanceRequest.assignedMaintenanceUser.lastName : "Not assigned"}
                            setValue={() => {}}
                            required={OverviewMaintenanceRequestForm.assignedMaintenanceUser.required}
                            invalidFeedbackText={OverviewMaintenanceRequestForm.assignedMaintenanceUser.regex.helpText}
                            type="text"
                            label="Ticket Assigned"
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-1">
                        <Input
                            id={OverviewMaintenanceRequestForm.scheduledDate.id}
                            validation={OverviewMaintenanceRequestForm.scheduledDate.regex.expression}
                            doValidate={OverviewMaintenanceRequestForm.scheduledDate.validate}
                            value={maintenanceRequest?.scheduledDate ? moment(maintenanceRequest.scheduledDate).format("MM/DD/YYYY"): 'Pending'}
                            setValue={() => {}}
                            required={OverviewMaintenanceRequestForm.scheduledDate.required}
                            invalidFeedbackText={OverviewMaintenanceRequestForm.scheduledDate.regex.helpText}
                            type="text"
                            label="Scheduled Date"
                            disabled={true}
                        />
                    </div>
                </div>
            </div>
            <div className="col-md-6 mb-1">
                <TextArea
                    id={OverviewMaintenanceRequestForm.technicalNotes.id}
                    validation={OverviewMaintenanceRequestForm.technicalNotes.regex.expression}
                    doValidate={OverviewMaintenanceRequestForm.technicalNotes.validate}
                    value={maintenanceRequest?.technicalNotes || ''}
                    setValue={() => {}}
                    required={OverviewMaintenanceRequestForm.technicalNotes.required}
                    invalidFeedbackText={OverviewMaintenanceRequestForm.technicalNotes.regex.helpText}
                    rows={5}
                    label="Notes"
                    disabled={true}
                />
            </div>
        </div>
</>;
};

export default Overview;
