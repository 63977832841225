import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import PageLayout from "../../../Layouts/PageLayout";
import Input from "../../../Components/Form/Input";
import {
    DateRegex,
    DollarAmountRegex,
    formInputValidate,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex,
    GetBankAccount,
    Tenant,
    TenantReceipt,
} from "../../../types";
import AutocompleteInput, {DataAttribute} from "../../../Components/Form/AutocompleteInput";
import {
    addDeposit,
    addOwnerReceipt,
    addTenantReceipt, deleteOwnerReceipt, deleteTenantReceipt,
    getTenantReceipts,
    getTenants,
    getTrustAccounts
} from "../../../Api/PortfolioApi";
import {getPortfolioId} from "../../../helpers";
import Select from "../../../Components/Form/Select";
import Table from "../../../Components/Table";
import {Link} from "react-router-dom";
import ViewReconcile from "../Reconcile";
import {getPropertiesByPortfolioId} from "../../../Api/PropertyApi";


const ViewReceivables: React.FunctionComponent = () => {
    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    const [view, setView] = useState("receipts");
    const [amount, setAmount] = useState("");
    const [description, setDescription] = useState("");
    const [tenantList, setTenantList] = useState<DataAttribute[]>([]);
    const [propertyList, setPropertyList] = useState<DataAttribute[]>([]);
    const [tenants, setTenants] = useState<Tenant[]>([])
    const [selectedTenant, setSelectedTenant] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [date, setDate] = useState("");
    const [reference, setReference] = useState("");
    const [type, setType] = useState("");
    const [account, setAccount] = useState("");
    const [tenantReceipts, setTenantReceipts] = useState<TenantReceipt[]>([])
    const [filteredTenantReceipts, setFilteredTenantReceipts] = useState<TenantReceipt[]>([])
    const [selectedBankAccount, setSelectedBankAccount] = useState("");
    const [bankAccounts, setBankAccounts] = useState<GetBankAccount[]>([]);
    const [selectedTenantReceipts, setSelectedTenantReceipts] = useState<string[]>([]);
    const [reviewDeposit, setReviewDeposit] = useState(false);
    const [depositDate, setDepositDate] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [depositType, setDepositType] = useState("");
    const [selectedProperty, setSelectedProperty] = useState("");
    const [glAccount, setGlAccount] = useState("");
    const [checkNumber, setCheckNumber] = useState("");


    function load() {
        setError("");
        setLoading(true);
        getTenants(getPortfolioId())
            .then((r) => {
                let tenants: DataAttribute[] = [];
                r.forEach(t => {
                    let label = t.lastName + ", " + t.firstName + " | " + t.property.unitName + " | " + t.property.name;
                    tenants.push({
                        uuid: t.uuid,
                        label: label
                    })
                })
                setTenants(r);
                setTenantList(tenants)
            })
            .catch(e => setError(e.message))
            .finally(() => setLoading(false));

        getPropertiesByPortfolioId(getPortfolioId())
            .then((pz) => {
                let mz: DataAttribute[] = [];
                pz.forEach((p) => {
                    mz.push({
                        uuid: p.uuid,
                        label: p.name
                    })
                })
                setPropertyList(mz);
            })
            .catch((e) => setError(e.message))
            .finally(() => setLoading(false));


        getTenantReceipts(getPortfolioId())
            .then(d => {
                setTenantReceipts(d);
                if (selectedBankAccount) {
                    let filter = bankAccounts.filter(b => b.uuid === selectedBankAccount);
                    if (filter && filter.length > 0) {
                        setFilteredTenantReceipts(d.filter(r => r.accountId === filter[0].accountCode))
                    }
                }
            })
            .catch(e => setError(e.message))
            .finally(() => {
            });

        getTrustAccounts(getPortfolioId())
            .then(d => setBankAccounts(d))
            .catch(e => setError(e.message))
            .finally(() => {
            });
    }


    useEffect(() => {
        load();
    }, []);

    function getSelectedProperty() {
        if (depositType === "tenant" && tenants) {
            let filter = tenants.filter(t => t.uuid === selectedTenant);
            if (filter) {
                return filter[0].property.uuid
            }
        }
        if (depositType === "owner") {
            return selectedProperty;
        }
        return "";
    }

    function getSelectedLease() {
        if (depositType === "tenant" && tenants) {
            let filter = tenants.filter(t => t.uuid === selectedTenant);
            if (filter) {
                return filter[0].lease.uuid
            }
        }
        return "";
    }

    function getSelectedPayer() {
        if (depositType === "tenant" && tenants) {
            let filter = tenants.filter(t => t.uuid === selectedTenant);
            if (filter) {
                return filter[0].firstName + " " + filter[0].lastName + " | " + filter[0].property.unitName + " | " + filter[0].property.name;
            }
        }
        return "";
    }

    function doSubmit(e: FormEvent) {
        e.preventDefault();
        if (depositType === "tenant") {

            setLoading(true)
            addTenantReceipt(getPortfolioId(), {
                payer: getSelectedPayer(),
                type: type,
                amount: parseFloat(amount),
                date: date,
                reference: reference,
                accountId: account,
                description: description,
                leaseId: getSelectedLease(),
                propertyId: getSelectedProperty(),
                checkNumber: checkNumber
            })
                .then((r) => {
                    clear();
                    load();
                })
                .catch(e => setError(e.message))
                .finally(() => setLoading(false))
        }
        if (depositType === "owner") {
            setLoading(true)
            addOwnerReceipt(getPortfolioId(), {
                type: type,
                amount: parseFloat(amount),
                date: date,
                reference: reference,
                accountId: account,
                description: description,
                propertyId: getSelectedProperty(),
                glAccount: glAccount,
                checkNumber: checkNumber
            })
                .then((r) => {
                    clear();
                    load();
                })
                .catch(e => setError(e.message))
                .finally(() => setLoading(false))
        }
    }

    function clear() {
        setSelectedTenant("");
        setAmount("");
        setDate("");
        setError("");
        setType("");
        setReference("");
        setDescription("");
        setAccount("");

    }

    function doReversal(uuid: string, leaseId: string) {
        if(leaseId) {
            setLoading(true);
            deleteTenantReceipt(getPortfolioId(), uuid)
                .then((r) => {
                    load()
                })
                .catch((e) => {
                    setError(e.message);
                })
                .finally(() => setLoading(false))
        } else {
            setLoading(true);
            deleteOwnerReceipt(getPortfolioId(), uuid)
                .then((r) => {
                    load()
                })
                .catch((e) => {
                    setError(e.message);
                })
                .finally(() => setLoading(false))
        }
    }

    function getPendingReceipts() {
        return <>
            <h5>Pending Receipts</h5>
            <Table headers={["Date", "Payer", "Cash Account", "Description", "Reference", "Type", "Amount",""]}
                   defaultPaginationSize={10}>
                {
                    tenantReceipts.map(t => (
                        <tr key={t.uuid}>
                            <td>{t.date}</td>
                            <td>
                                {getPayerName(t)}
                            </td>
                            <td>{t.accountId + " - " + getAccountName(t.accountId)}</td>
                            <td>{t.description}</td>
                            <td>{t.reference}</td>
                            <td>{t.type}</td>
                            <td>{currencyFormat.format(t.amount)}</td>
                            <td>
                                <button className={"btn btn-danger"} type={"button"} onClick={(e) => {
                                    e.preventDefault();
                                    doReversal(t.uuid,t.leaseId)
                                }}>Reverse</button>
                            </td>

                        </tr>
                    ))
                }
            </Table>
        </>
    }

    function getPayerName(t: TenantReceipt) {
        if (t.leaseId) {
            return <Link
                to={"/p/m/customers/leases/" + getPortfolioId() + "/view/" + t.leaseId}>Tenant: {t.payer}</Link>
        }
        return <>Owner: {t.payer}</>;
    }

    function getFilteredReceipts(isReview?: boolean) {
        return <>

            <Table headers={["", "Date", "Payer", "Cash Account", "Description", "Reference", "Type", "Amount"]}
                   defaultPaginationSize={10}>
                {
                    filteredTenantReceipts.filter(f => !isReview || selectedTenantReceipts.includes(f.uuid)).map(t => (
                        <tr key={t.uuid}>
                            <td style={{width: 30}}>
                                {!isReview && <input className="form-check-input" type="checkbox"
                                                     checked={selectedTenantReceipts.includes(t.uuid)}
                                                     onChange={(e) => {
                                                         if (e.target.checked) {
                                                             selectedTenantReceipts.push(t.uuid);
                                                             setSelectedTenantReceipts([...selectedTenantReceipts]);
                                                         } else {
                                                             const index = selectedTenantReceipts.indexOf(t.uuid);
                                                             if (index > -1) {
                                                                 selectedTenantReceipts.splice(index, 1);
                                                                 setSelectedTenantReceipts([...selectedTenantReceipts]);
                                                             }
                                                         }

                                                     }}
                                />}
                            </td>
                            <td>{t.date}</td>
                            <td>
                                {getPayerName(t)}
                            </td>
                            <td>{t.accountId + " - " + getAccountName(t.accountId)}</td>
                            <td>{t.description}</td>
                            <td>{t.reference}</td>
                            <td>{t.type}</td>
                            <td>{currencyFormat.format(t.amount)}</td>
                        </tr>
                    ))
                }
            </Table>
        </>
    }

    function getCheckNumberField() {
        if(type === "check") {
            return <div className={"col-md-6 mb-3"}>
                <Input id={"checkNumber"} validation={GenericInputRegex.expression}
                       doValidate={formInputValidate}
                       value={checkNumber}
                       setValue={setCheckNumber}
                       required={true}
                       invalidFeedbackText={GenericInputRegex.helpText}
                       type={"text"} label={"Check Number"}
                />
            </div>
        }
    }

    function getReceipts() {
        return <>
            {loading && <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>}
            {error && <div className="alert alert-danger" role="alert">
                {error}
            </div>}
            <div className={"row"}>
                <div className={"col"}>
                    <h5>Create Deposit Receipt</h5>
                </div>
            </div>
            <form onSubmit={doSubmit}>
                <div className={"row"}>
                    <div className={"col-md-4 mb-3"}>
                        <div className={"row"}>
                            <div className={"col-md-12 mb-3"}>
                                <Select id={"type"} validation={GenericSelectRegex.expression}
                                        doValidate={formSelectValidate} value={depositType} setValue={setDepositType}
                                        required={true}
                                        invalidFeedbackText={GenericSelectRegex.helpText} label={"Deposit Type"}>
                                    <option disabled={true} value={""}>Select a deposit type...</option>
                                    <option value={"tenant"}>Tenant</option>
                                    <option value={"owner"}>Owner</option>

                                </Select>
                            </div>
                            <div className={"col-md-12 mb-3"}>
                                {(depositType === "tenant") &&
                                    <AutocompleteInput id={"tenant"} validation={GenericInputRegex.expression}
                                                       data={tenantList}
                                                       doValidate={formInputValidate}
                                                       value={selectedTenant}
                                                       setValue={setSelectedTenant}
                                                       placeholder={"Search by tenant, unit, or property name"}
                                                       required={true}
                                                       invalidFeedbackText={GenericInputRegex.helpText}
                                                       type={"text"} label={"Tenant"}
                                    />}
                                {(depositType === "owner") &&
                                    <AutocompleteInput id={"owner"} validation={GenericInputRegex.expression}
                                                       data={propertyList}
                                                       doValidate={formInputValidate}
                                                       value={selectedProperty}
                                                       setValue={setSelectedProperty}
                                                       placeholder={"Search by property name"}
                                                       required={true}
                                                       invalidFeedbackText={GenericInputRegex.helpText}
                                                       type={"text"} label={"Property"}
                                    />}
                            </div>
                        </div>
                    </div>
                    <div className={"col-md-8 mb-3"}>
                        <div className={"row"}>
                            <div className={"col-md-6 mb-3"}>
                                <Input id={"amount"} validation={DollarAmountRegex.expression}
                                       doValidate={formInputValidate}
                                       value={amount}
                                       setValue={setAmount}
                                       required={true}
                                       step={.01}
                                       min={0}
                                       invalidFeedbackText={DollarAmountRegex.helpText}
                                       type={"number"} label={"Amount"}
                                />
                            </div>
                            <div className={"col-md-6 mb-3"}>
                                <Input id={"description"} validation={GenericInputRegex.expression}
                                       doValidate={formInputValidate}
                                       value={description}
                                       setValue={setDescription}
                                       required={true}
                                       invalidFeedbackText={GenericInputRegex.helpText}
                                       type={"text"} label={"Description"}
                                />
                            </div>

                            <div className={"col-md-6 mb-3"}>
                                <Select id={"type"} validation={GenericSelectRegex.expression}
                                        doValidate={formSelectValidate} value={account} setValue={setAccount}
                                        required={true}
                                        invalidFeedbackText={GenericSelectRegex.helpText} label={"Cash Account"}>
                                    <option disabled={true} value={""}>Select an account...</option>
                                    <option value={"1000"}>Operating Cash | 1000</option>
                                    <option value={"1001"}>Security Deposit Cash | 1001</option>

                                </Select>
                            </div>
                            {(depositType === "owner") && <div className={"col-md-6 mb-3"}>
                                <Select id={"type"} validation={GenericSelectRegex.expression}
                                        doValidate={formSelectValidate} value={glAccount} setValue={setGlAccount}
                                        required={true}
                                        invalidFeedbackText={GenericSelectRegex.helpText} label={"GL Account"}>
                                    <option disabled={true} value={""}>Select a GL account...</option>
                                    <option value={"3150"}>Owner Contribution - Equity</option>
                                    <option value={"3250"}>Owner Distribution - Equity</option>
                                </Select>
                            </div>}

                            <div className={"col-md-6 mb-3"}>
                                <Input id={"date"} validation={DateRegex.expression}
                                       doValidate={formInputValidate}
                                       value={date}
                                       setValue={setDate}
                                       required={true}
                                       invalidFeedbackText={DateRegex.helpText}
                                       type={"date"} label={"Date"}
                                />
                            </div>
                            <div className={"col-md-6 mb-3"}>
                                <Input id={"reference"} validation={GenericInputRegex.expression}
                                       doValidate={formInputValidate}
                                       value={reference}
                                       setValue={setReference}
                                       required={true}
                                       invalidFeedbackText={GenericInputRegex.helpText}
                                       type={"text"} label={"Reference"}
                                />
                            </div>
                            <div className={"col-md-6 mb-3"}>
                                <Select id={"type"} validation={GenericSelectRegex.expression}
                                        doValidate={formSelectValidate} value={type} setValue={setType}
                                        required={true}
                                        invalidFeedbackText={GenericSelectRegex.helpText} label={"Type"}>
                                    <option disabled={true} value={""}>Select a type...</option>
                                    <option value={"cash"}>Cash</option>
                                    <option value={"check"}>Check</option>
                                    <option value={"money-order"}>Money Order</option>

                                </Select>
                            </div>
                            {getCheckNumberField()}
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col"}>
                        <button type={"submit"} className={"btn btn-primary"}>Save</button>
                        <button type={"button"} onClick={() => clear()} className={"btn btn-danger ms-3"}>Cancel
                        </button>
                    </div>
                </div>
            </form>
            <div className={"row mt-3"}>
                <div className={"col"}>
                    {getPendingReceipts()}
                </div>
            </div>
        </>;
    }

    function getAccountName(code: string) {
        if (code === "1000") {
            return "Operating Cash"
        }
        if (code === "1001") {
            return "Security Deposit Cash"
        }
    }

    function getChooseBankAccount() {
        return <>
            <div className={"col-md-12"}>
                <h5>Find receipts to deposit</h5>
            </div>
            <div className={"col-md-4"}>
                <Select id={"bankAccount"} validation={GenericSelectRegex.expression} doValidate={formSelectValidate}
                        value={selectedBankAccount} setValue={setSelectedBankAccount} required={true}
                        invalidFeedbackText={GenericSelectRegex.helpText} label={"Bank Account"}>
                    <option disabled={true} value={""}>Select a bank account...</option>
                    {
                        bankAccounts.map(b => (
                            <option key={b.uuid}
                                    value={b.uuid}>{b.name + " | " + b.accountCode + " - " + getAccountName(b.accountCode)}</option>
                        ))
                    }
                </Select>
            </div>
            <div className={"col-md-2"} style={{paddingTop: 21}}>
                <button disabled={!selectedBankAccount} type={"button"} className={"btn btn-primary"}
                        onClick={(e) => {
                            e.preventDefault();
                            setError("");
                            setSuccessMessage("");
                            load();
                        }}>Search
                </button>
            </div>
            <div className={"col-md-6"}>
            </div>
        </>
    }

    function getTotalDeposit() {
        let total = 0;
        let filter = filteredTenantReceipts.filter(f => selectedTenantReceipts.includes(f.uuid));
        if (filter) {
            filter.forEach(f => total += f.amount);
        }
        return <div className={"col-md-12 mt-3"}>
            <h5>Total: {currencyFormat.format(total)}</h5>
        </div>
    }

    function doDeposit(e: FormEvent) {
        e.preventDefault();
        setLoading(true)
        addDeposit(getPortfolioId(), {
            date: depositDate,
            receipts: selectedTenantReceipts,
            bankAccountId: selectedBankAccount
        })
            .then((r) => {
                setSuccessMessage("Successfully added deposit.");
                setSelectedTenantReceipts([]);
                load();
            })
            .catch((e) => setError(e.message))
            .finally(() => setLoading(false));
    }

    function getDeposits() {
        if (reviewDeposit) {
            return <div className={"row"}>

                {getChooseBankAccount()}
                <form onSubmit={doDeposit}>
                    <div className={"col-md-12 mt-3"}>
                        <button type={"button"} onClick={() => setReviewDeposit(false)}
                                className={"btn btn-danger"}>Cancel
                        </button>
                        <button type={"submit"} className={"btn btn-primary"}>Save</button>
                    </div>
                    <div className={"col-md-4 mt-3"}>
                        {loading && <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                        {error && <div className="alert alert-danger" role="alert">
                            {error}
                        </div>}
                        {successMessage && <div className="alert alert-success" role="alert">
                            {successMessage}
                        </div>}
                        <h5>Review Deposit</h5>
                        {getTotalDeposit()}
                        <Input id={"depositDate"} validation={DateRegex.expression}
                               doValidate={formInputValidate}
                               value={depositDate}
                               setValue={setDepositDate}
                               required={true}
                               invalidFeedbackText={DateRegex.helpText}
                               type={"date"} label={"Date"}
                        />
                    </div>
                </form>
                <div className={"col-md-12 mt-3"}>

                    {getFilteredReceipts(true)}
                </div>
            </div>
        }
        return <div className={"row"}>
            {getChooseBankAccount()}
            {selectedBankAccount && <div className={"col-md-12 mt-3"}>
                <button type={"button"} className={"btn btn-danger"}
                        onClick={(e) => {
                            e.preventDefault();
                            setSelectedTenant("");
                            setSelectedTenantReceipts([])
                        }}>Cancel
                </button>
                <button type={"button"} className={"btn btn-primary"}
                        onClick={(e) => {
                            e.preventDefault();
                            setReviewDeposit(true);
                        }}>Review
                </button>
            </div>}
            {selectedBankAccount && <div className={"col-md-12 mt-3"}>
                <h5>Select pending receipts to deposit</h5>
                {getFilteredReceipts()}
            </div>}
        </div>;
    }

    function getView() {
        if (view === "deposits") {
            return getDeposits();
        }
        if (view === "ach") {
            return <ViewReconcile/>
        }
        return getReceipts();
    }

    return <PageLayout title={"Receivables"}>
        <div className={"row"}>
            <div className={"col-md-12"}>
                <ul className="nav ">
                    <li className={"nav-item"}>
                        <button
                            className={view === "receipts" ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                            aria-current="page"
                            onClick={(e) => {
                                e.preventDefault();
                                setView("receipts");
                            }}>Receipts
                        </button>
                        <div className={view === "receipts" ? " triangle-down" : ""}></div>
                    </li>
                    <li className="nav-item">
                        <button
                            className={view === "ach" ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                            onClick={(e) => {
                                e.preventDefault();
                                setView("ach");
                            }}>ACH
                        </button>
                        <div className={view === "ach" ? " triangle-down" : ""}></div>
                    </li>
                    <li className="nav-item">
                        <button
                            className={view === "deposits" ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                            onClick={(e) => {
                                e.preventDefault();
                                setView("deposits");
                            }}>Bank Deposits
                        </button>
                        <div className={view === "deposits" ? " triangle-down" : ""}></div>
                    </li>
                </ul>
            </div>
            <div className={"col-md-12"}>
            {getView()}
            </div>
        </div>
    </PageLayout>
}
export default ViewReceivables;