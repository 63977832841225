import {FormBuilderField, FormFieldType} from "../../Form/FieldBuilder/FormFieldBuilder";
import {formSelectValidate, GenericSelectRegex, UuidRegex} from "../../../types";

interface SearchFrontDeskInstructionsAndGuestsForm {
    accountId: FormBuilderField
}

const SearchFrontDeskInstructionsAndGuestsForm: SearchFrontDeskInstructionsAndGuestsForm = {
    accountId: {
        type: FormFieldType.SELECT,
        element: {
            id: "accountId",
            required: false,
            validate: formSelectValidate,
            regex: GenericSelectRegex,
        },
        label: "Unit",
        defaultSelectOptions: [{
            value: "",
            label: "Please select..."
        }],
        value: undefined,
        setValue: () => {}
    }
}

export default SearchFrontDeskInstructionsAndGuestsForm;
