import React from 'react'
import {CheckCircleFillIcon, CircleFillIcon, CircleNumberFillIcon} from "../Icons/Icons";

export interface ProgressPath {
    steps: ProgressPathStep[]
    activeStep: number
}

export interface ProgressPathStep {
    label: string,
    step: number
}

interface Props {
    progressPath: ProgressPath
}

const ProgressSteps: React.FunctionComponent<Props> = (p) => {

    const totalSteps = p.progressPath.steps.length

    return <>
        <div className="d-flex justify-content-between align-items-center py-3">
            {p.progressPath.steps.map(({ step, label}, i) => (
                <React.Fragment key={i}>
                    <div key={step} className="d-flex justify-content-center align-items-center me-2" style={{minWidth: "24px"}}>
                        <div style={{zIndex: 0, position: "absolute"}}>
                            <CircleFillIcon size={20} color="var(--dark)" />
                        </div>
                        {step > 0 && step < 10 &&
                            <div style={{zIndex: 2, position: "absolute"}}>
                                {p.progressPath.activeStep === step && <CircleNumberFillIcon size={24} color="var(--warning)" number={step} />}
                                {p.progressPath.activeStep > step && <CheckCircleFillIcon size={24} color="var(--warning)" />}
                                {p.progressPath.activeStep < step && <CircleNumberFillIcon size={24} color="var(--border-color)" number={step} />}
                            </div>
                        }
                        {step >= 10 && <>
                            <div style={{zIndex: 2, position: "absolute"}}>
                                {p.progressPath.activeStep === step && <CircleFillIcon size={24} color="var(--warning)" />}
                                {p.progressPath.activeStep > step && <CheckCircleFillIcon size={24} color="var(--warning)" />}
                                {p.progressPath.activeStep < step && <CircleFillIcon size={24} color="var(--border-color)" />}
                            </div>
                            {p.progressPath.activeStep <= step &&
                                <div style={{zIndex: 3, position: "absolute"}}>
                                    <h6 className="p-0 m-0">{step}</h6>
                                </div>
                            }
                        </>}
                    </div>
                    <div style={{flex: "1 1 auto"}}>
                        <span className="font-weight-bold small text-nowrap">{label}</span>
                    </div>
                    {i < totalSteps - 1 &&
                        <div style={{flex: "1 1 auto", height: "2px", width: "100%"}} className="mx-2">
                            <span style={{
                                display: "block",
                                border: "1px solid var(--border-color)",
                            }}></span>
                        </div>
                    }
                </React.Fragment>
            ))}
        </div>
    </>
}

export default ProgressSteps