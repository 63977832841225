import {FormOption} from "../FieldBuilder/FormFieldBuilder";
import React from "react";
import RequiredLabel from "../../RequiredLabel";

interface DatalistProps {
    id: string,
    validateClass: string,
    value: string,
    setValue: Function,
    type: string,
    label: string | JSX.Element,
    required: boolean,
    disabled?: boolean,
    placeholder?: string,
    minLength?: number,
    maxLength?: number,
    invalidFeedbackText: string,
    step?: number | string,
    min?: number | string,
    max?: number | string,
    options: FormOption[],
    className?: string
}


const Datalist: React.FunctionComponent<DatalistProps> = (p) => {
    return <>
        {p.label !== "" && <label htmlFor={p.id} className="col-form-label-sm mb-0 pb-0">{!p.disabled && p.required ? <RequiredLabel>{p.label}</RequiredLabel> : p.label}</label>}
        <input
            id={p.id}
            list={'datalist_' + p.id}
            className={`form-control ${p.className && p.className} ${p.validateClass}`}
            type={p.type}
            placeholder={p.placeholder}
            required={p.required}
            disabled={p.disabled}
            minLength={p.minLength}
            maxLength={p.maxLength}
            step={p.step}
            min={p.min}
            max={p.max}
            value={p.value}
            onChange={e => {
                let value = e.target.value;
                p.setValue(value);
            }}
        />
        <datalist id={'datalist_' + p.id}>
            {p.options.map((option) => (
                <option key={p.id + "_" + option.value} value={option.value} disabled={option.disabled}>{option.label}</option>
            ))}
        </datalist>
        <div className="invalid-feedback">
            {p.invalidFeedbackText}
        </div>
    </>
}

export default Datalist
