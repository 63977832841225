import * as React from 'react';
import {Redirect, Route, Switch, useRouteMatch} from "react-router";
import SearchPropertyGroups from "../../Components/ManagePropertyGroups/SearchPropertyGroups";
import SearchProperties from "../../Components/ManageProperties/SearchProperties";
import AddProperty from "../../Components/ManageProperties/AddProperty";
import EditProperty from "../../Components/ManageProperties/EditProperty";
import AddPropertyGroup from "../../Components/ManagePropertyGroups/AddPropertyGroup";
import EditPropertyGroup from "../../Components/ManagePropertyGroups/EditPropertyGroup";
import ManageFloorPlans from "../../Components/ManageFloorPlans";
import ManageUnits from "../../Components/ManageUnits";
import PropertyPhoneNumbers from "../../Pages/PhoneNumbers/PropertyPhoneNumber";
import EditPropertyPhoneNumber from "../../Pages/PhoneNumbers/EditPropertyPhoneNumbers";

const RealEstateContainer: React.FunctionComponent = () => {
    let {path} = useRouteMatch();
    return (<>
        <Switch>
            <Route exact path={path}>
                <Redirect to={path + "/properties"} />
            </Route>
            <Route exact path={path + "/groups"}>
                <SearchPropertyGroups/>
            </Route>
            <Route exact path={path + "/groups/add"}>
                <AddPropertyGroup/>
            </Route>
            <Route exact path={path + "/groups/edit/:propertyGroupUuid"}>
                <EditPropertyGroup/>
            </Route>
            <Route exact path={path + "/properties"}>
                <SearchProperties/>
            </Route>
            <Route exact path={path + "/properties/add"}>
                <AddProperty/>
            </Route>
            <Route exact path={path + "/properties/edit/:propertyUuid"}>
                <EditProperty/>
            </Route>
            <Route exact path={path + "/properties/edit/:propertyUuid/phone-numbers"}>
                <PropertyPhoneNumbers/>
            </Route>
            <Route exact={false} path={path + "/floorplans"}>
                <ManageFloorPlans />
            </Route>
            <Route exact={false} path={path + "/units"}>
                <ManageUnits />
            </Route>
            <Route exact path={path + "/properties/edit/:propertyUuid/phone-numbers/:phoneNumberId"}>
                <EditPropertyPhoneNumber/>
            </Route>
        </Switch>
    </>);
};

export default RealEstateContainer;
