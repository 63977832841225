import * as React from 'react';
import {useEffect, useState} from 'react';
import {ApplicationStatus} from "../../../types";
import Alert, {AlertProps, AlertType} from "../../Alert";
import {useHistory, useParams} from "react-router";
import {getPortfolioId} from "../../../helpers";
import LoadingOverlay from "../../LoadingOverlay";
import {getApplication, submitApplication} from "../../../Api/ApplicationApi";
import ButtonNew, {ButtonColors, ButtonTypes} from "../../ButtonNew";
import ApplicationStageLayout from "../../../Layouts/ApplicationStageLayout";

type Stage9Props = {
    handleInvalidApplicationStatus: Function
    applicationId: string
    setStage: Function
}

const Stage9: React.FunctionComponent<Stage9Props> = (p) => {
    const { propertyId } = useParams();

    const [error, setError] = useState<Error>()
    const [alert, setAlert] = useState<AlertProps>()
    const [loading, setLoading] = useState(false);
    const [doRedirectToStage8, setDoRedirectToStage8] = useState(false);
    const [doRedirectToStage10, setDoRedirectToStage10] = useState(false);
    const history = useHistory();

    //form
    const [approvesLegal, setApprovesLegal] = useState(false);

    const [doRedirectToStage1, setDoRedirectToStage1] = useState(false);
    const [doHandleInvalidApplicationStatus, setDoHandleInvalidApplicationStatus] = useState(false);

    useEffect(() => {
        if(doHandleInvalidApplicationStatus) {
            p.handleInvalidApplicationStatus();
        }
    }, [doHandleInvalidApplicationStatus, p]);

    useEffect(() => {
        if (doRedirectToStage1) {
            setDoRedirectToStage1(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/1"
            });
        }

    }, [doRedirectToStage1, history])

    useEffect(() => {
        console.log("stage 9 init");
        p.setStage(9)
        setLoading(true);
        getApplication(getPortfolioId(), p.applicationId)
            .then((a) => {
                if (a.status !== ApplicationStatus.approved) {
                    if(!a.unit || (a.unit && !a.unit.uuid)) {
                        console.log("redirect to stage 1, missing unit")
                        setDoRedirectToStage1(true);
                        return;
                    }
                    setLoading(false);
                } else {
                    setDoHandleInvalidApplicationStatus(true);
                }
            })
            .catch(e => {
                doShowAlert("Failed to load application: " + e.message, AlertType.DANGER);
                setLoading(false);
            })


    }, [p])

    const saveAndContinueStage10 = () => {
        if (!approvesLegal) {
            doShowAlert("You must agree to all terms to submit the application. Please check the box above to proceed.", AlertType.DANGER);
            return;
        }
        setLoading(true);
        submitApplication(getPortfolioId(), p.applicationId, {
            legalApproved: approvesLegal,
        })
            .then((a) => {
                setDoRedirectToStage10(true);
            })
            .catch(e => {
                console.log(e)
                doShowAlert("Failed to submit: " + e.message, AlertType.DANGER);
            }).finally(() => setLoading(false));
    }

    const doShowAlert = (message: string, type: AlertType) => {
        setAlert({type, message})
    }

    useEffect(() => {
        if (doRedirectToStage10) {
            setDoRedirectToStage10(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/10"
            });
        }

    }, [doRedirectToStage10, history])

    useEffect(() => {
        if (doRedirectToStage8) {
            setDoRedirectToStage8(false);
            history.push({
                pathname: "/p/m/customers/applications/" + propertyId + "/add/8"
            });
        }

    }, [doRedirectToStage8, history])

    return (loading ? <div className="col-md-6"><LoadingOverlay /></div> : <>
        <ApplicationStageLayout
            error={error}
            alert={alert}
            title="Legal"
            description="Please read the terms & conditions."
        >
            <div className={"row"}>
                <div className={"col-md-8"}>
                    <div className={"row"}>
                        <div className={"col-md-12"}>
                            <p>
                                I have read, agree and affirm that all of my statements and information provided in this
                                application are true and complete.
                                I acknowledge that false, undisclosed, incomplete or misleading information herein may
                                constitute grounds for rejection of this application, termination of right of occupancy
                                of all residents and occupants under a lease and/or forfeiture of deposits and fees,
                                and may constitute a criminal offense under the laws of this state.
                            </p>
                            <p>
                                I understand that this application is preliminary only and does not bind you to execute a
                                Lease or to deliver possession
                                of the premises to me. I authorize you to contact any references listed above and to
                                obtain consumer reports, which may include credit, rental payment and/or eviction
                                history and criminal background information about me and in order to verify the above
                                information. I understand that as my prospective landlord, any co-applicants, occupants,
                                or guarantors that may be added to this application may need to consent to and
                                successfully pass consumer background screening reports.
                            </p>
                            <p>
                                By agreeing, I authorize you to obtain subsequent consumer reports, including credit
                                reports, to ensure that I continue
                                to satisfy the terms of my tenancy, for the collection and recovery of any financial
                                obligations relating to my tenancy, or for any other permissible purpose. Further,
                                if I have included information on co-applicants, other adult household members, and/or
                                guarantors on this application, I affirm that those individuals have knowledge of
                                their inclusion on this application and expressly authorize you to obtain subsequent
                                consumer reports, including credit reports. I understand that you may report all
                                positive and negative rental payment history to consumer reporting agencies who track
                                this information for landlords, mortgage companies and other creditors. I and all
                                occupants or guarantors hereby release from all liability or responsibility all persons
                                and corporations requesting or supplying such information.
                            </p>
                        </div>
                        <div className="col-md-12 mb-3">
                            <hr />
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value=""
                                       id="defaultCheck1"
                                       checked={approvesLegal}
                                       onChange={e => {
                                           setApprovesLegal(e.target.checked);
                                       }}/>
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                    By checking this box and clicking Submit, I acknowledge that I have read
                                    and agree to all of the above terms and this arbitration
                                    agreement and class action waiver.
                                </label>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start gap-2 mt-3">
                            <ButtonNew
                                type={ButtonTypes.BUTTON}
                                color={ButtonColors.SECONDARY}
                                label="Back"
                                onClick={() => setDoRedirectToStage8(true)}
                            />
                            <ButtonNew
                                type={ButtonTypes.BUTTON}
                                color={ButtonColors.PRIMARY}
                                label="Save & Continue"
                                loading={loading}
                                disabled={!approvesLegal}
                                onClick={saveAndContinueStage10}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ApplicationStageLayout>
    </>);
};

export default Stage9;
