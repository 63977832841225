import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router";
import {useLocation} from "react-router-dom";
import {
    getTenantPackages,
    TenantPackage,
    TenantPackageFilter,
    TenantPackageStatus
} from "../../../../Api/PackageManangementApi";
import {ARAccount} from "../../../../types";
import SearchTenantPackagesForm from "../../Forms/SearchTenantPackagesForm";
import LoadingOverlay from "../../../LoadingOverlay";
import FormFieldBuilder, {SelectOptions} from "../../../Form/FieldBuilder/FormFieldBuilder";
import Table from "../../../Table";
import {getTicketIdFromUuid} from "../../../../helpers";
import moment from "moment/moment";

const SearchTenantPackages: React.FunctionComponent<{tenantAccounts: ARAccount[], selectOptions: SelectOptions[], refresh: boolean}> = ({tenantAccounts, selectOptions, refresh}) => {
    const {propertyId} = useParams();
    const {pathname} = useLocation();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [accountId, setAccountId] = useState<string>("");
    const [status, setStatus] = useState<TenantPackageStatus>(TenantPackageStatus.RECEIVED);
    const [dateReceived, setDateReceived] = useState<string>("");
    const [tenantPackages, setTenantPackages] = useState<TenantPackage[]>([]);

    SearchTenantPackagesForm.accountId.value = accountId;
    SearchTenantPackagesForm.accountId.setValue = setAccountId;
    SearchTenantPackagesForm.status.value = status;
    SearchTenantPackagesForm.status.setValue = setStatus;
    SearchTenantPackagesForm.dateReceived.value = dateReceived;
    SearchTenantPackagesForm.dateReceived.setValue = setDateReceived;

    const fetchTenantPackages = (filter: TenantPackageFilter) => {
        setLoading(true);
        getTenantPackages(propertyId, filter)
            .then(data => setTenantPackages(data))
            .catch(console.error)
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        fetchTenantPackages({status: TenantPackageStatus.RECEIVED})
    }, [propertyId, refresh])

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        fetchTenantPackages( {
            accountId: accountId,
            status: status,
            dateReceived: dateReceived
        })
    }

    const onReset = (e: FormEvent) => {
        e.preventDefault();
        setAccountId('')
        setStatus(TenantPackageStatus.UNKNOWN)
        setDateReceived('')
    }

    return (loading ? <LoadingOverlay show={loading} /> : <>
        <div className="row">
            <div className="col-md-12">
                <div className="row">
                    <h4>Search Tenant Packages</h4>
                </div>
                <div className="row">
                    <form onSubmit={onSubmit} onReset={onReset}>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <FormFieldBuilder field={SearchTenantPackagesForm.accountId} selectOptions={selectOptions}/>
                                <FormFieldBuilder field={SearchTenantPackagesForm.status} selectOptions={SearchTenantPackagesForm.status.defaultSelectOptions}/>
                            </div>
                            <div className="col-md-6 mb-3">
                                <FormFieldBuilder field={SearchTenantPackagesForm.dateReceived}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-2 mb-3">
                                <div id="btn-group-tp-search" className="btn-group" role="btn-group">
                                    <button disabled={loading} type="reset" className="btn btn-outline-primary">Clear</button>
                                    <button disabled={loading} type="submit" className="btn btn-primary">Search</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="row">
                    <div className="col-md-12 mb-3">
                        <Table headers={[
                            "Ticked Id",
                            "Unit",
                            "Addressed To",
                            "Received",
                            "Status"
                        ]} defaultPaginationSize={10}>
                            {tenantPackages?.map((tenantPackage, i) => (
                                <tr className={"clickable"} key={i + "_tp"}
                                    onClick={() => history.push({pathname: pathname + "/manage/" + tenantPackage.uuid})}
                                >
                                    <td>{getTicketIdFromUuid(tenantPackage.uuid)}</td>
                                    <td>{tenantAccounts.find((account: ARAccount) => { return account.uuid === tenantPackage.tenantAccountId })?.unitName}</td>
                                    <td>{tenantPackage.addressedToName}</td>
                                    <td>{moment(tenantPackage.dateReceived).format("MMM Do, YYYY")}</td>
                                    <td>
                                    <span className={
                                        tenantPackage.status === TenantPackageStatus.RECEIVED
                                            ? "badge rounded-pill bg-info"
                                            : "badge rounded-pill bg-success"}>{
                                        tenantPackage.status === TenantPackageStatus.RECEIVED
                                            ? <>Received</> : <>Picked Up</>
                                    }</span></td>
                                </tr>
                            ))}
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default SearchTenantPackages;
