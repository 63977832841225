import {fetchWithAuth, HttpMethod, serializeFilter} from "../api";
import {getPortfolioId} from "../helpers";

export type CommunityLink = {
    name: string,
    url: string,
    description: string,
    category: string,
    uuid: string
}

export interface CreateCommunityLink {
    name: string,
    url: string,
    description: string,
    category: string
}

type getCommunityLinks = (propertyId: string) => Promise<CommunityLink[]>;
type createCommunityLink = (propertyId: string, communityLink: CreateCommunityLink) => Promise<CommunityLink>;
type getCommunityLinkById = (propertyId: string, communityLinkId: string) => Promise<CommunityLink>;
type updateCommunityLink = (propertyId: string, communityLink: CommunityLink) => Promise<CommunityLink>;
type deleteCommunityLink = (propertyId: string, communityLinkId: string) => Promise<CommunityLink>;
const COMMUNITY_LINKS_API_BASE_URL = (propertyId: string, pathExtension = "/") => {return "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/community-links" + pathExtension}

export const getCommunityLinks: getCommunityLinks = (async (propertyId) => {
    return fetchWithAuth(COMMUNITY_LINKS_API_BASE_URL(propertyId), HttpMethod.GET);
});

export const createCommunityLink: createCommunityLink = (async (propertyId, communityLink) => {
    return fetchWithAuth(COMMUNITY_LINKS_API_BASE_URL(propertyId), HttpMethod.POST, JSON.stringify(communityLink));
});

export const getCommunityLinkById: getCommunityLinkById = (async (propertyId, communityLinkId) => {
    return fetchWithAuth(COMMUNITY_LINKS_API_BASE_URL(propertyId, "/" + communityLinkId), HttpMethod.GET);
});

export const updateCommunityLink: updateCommunityLink = (async (propertyId, communityLink) => {
    return fetchWithAuth(COMMUNITY_LINKS_API_BASE_URL(propertyId, "/" + communityLink.uuid),HttpMethod.PUT, JSON.stringify(communityLink));
})

export const deleteCommunityLink: deleteCommunityLink = (async (propertyId, communityLinkId) => {
    return fetchWithAuth(COMMUNITY_LINKS_API_BASE_URL(propertyId, "/" + communityLinkId), HttpMethod.DELETE);
});