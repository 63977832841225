import {APP_PORTFOLIO_ID, AppUser, LateFeeDto, LeaseUser, SigningUserStatus, TransactionAccount} from "./types";

const jwtDecode = require('jwt-decode');

export const validateToken = (token: any): boolean => {
    if (!token) {
        return false;
    }
    try {
        const decodedJwt: any = jwtDecode(token);
        return decodedJwt.exp >= Date.now() / 1000;
    } catch (e) {
        return false;
    }
};

export const getPortfolioId = (): string => {
    const pId = localStorage.getItem(APP_PORTFOLIO_ID);
    if (pId === null || pId === undefined) {
        throw Error("No portfolio id selected");
    }
    return pId;
}

export const getTicketIdFromUuid = (uuid: string | undefined): string => {
    if (uuid)
        return uuid?.substring(0,7);
    return "";
}

export const getAppUserFullName = (user: AppUser | LeaseUser): string => {
    return (user.firstName && user.lastName) ? user.firstName + " " + user.lastName : "-"
}

export const getAppUserAbbreviatedName = (user: AppUser): string => {
    return user.firstName.substring(0,1) + ". " + user.lastName;
}

export const convertEnumToReadableString = (enumString: string) => {
    let camelCaseString = enumString.toLowerCase().replace(/_/g, " ")
    return camelCaseString.substr(0, 1).toUpperCase() + camelCaseString.substr(1)
}

export const getSigningUserStatusBadgeColor = (status: SigningUserStatus) => {
    let color = ""
    switch (status) {
        case SigningUserStatus.NOT_READY: color = "var(--danger)"; break;
        case SigningUserStatus.READY: color = "var(--success)"; break;
        case SigningUserStatus.SIGNATURE_REQUESTED: color = "var(--primary)"; break;
        case SigningUserStatus.IN_PROGRESS: color = "var(--info)"; break;
        case SigningUserStatus.COMPLETE: color = "var(--success)"; break;
    }
    return color
}

export const getLateFeeAccountName = (accountsList:TransactionAccount[] ,accountUuid: string): string  => {
    try {
        return accountsList.filter(p => p.uuid === accountUuid)[0].name
    } catch(e) {
        return "";
    }
}

export const renderPercentOrDollarAmount = (f: LateFeeDto): string => {
    if (f.feeType === "Fixed Amount") {
        return "$" + f.amount
    } else if (f.feeType === "Percent") {
        return f.amount + "%"
    } else {
        return f.amount.toFixed()
    }
}