import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import PageLayout from "../../Layouts/PageLayout";
import {formInputValidate, formSelectValidate, GenericInputRegex, GenericSelectRegex, GetBusiness} from "../../types";
import Input from "../Form/Input";
import Select from "../Form/Select";
import {addOwnerReport, getOwners} from "../../Api/PortfolioApi";
import {getPortfolioId} from "../../helpers";
import OwnerReportPreview from "./OwnerReportPreview";
import SendOwnerReport from "./SendOwnerReport";


const OwnerReports: React.FunctionComponent = () => {

    const [view, setView] = useState("preview");



    function getContent() {
        if(view.startsWith("send")) {
            return <SendOwnerReport/>
        }
        return <OwnerReportPreview/>;
    }

    return <PageLayout title={"Owner Reports"}>
        <div className={"row"}>
            <div className={"col"}>
                <ul className="nav ">
                    <li className={"nav-item"}>
                        <button
                            className={view.startsWith("preview") ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                            aria-current="page"
                            onClick={(e) => {
                                e.preventDefault();
                                setView("preview");
                            }}>Preview
                        </button>
                        <div className={view.startsWith("preview") ? " triangle-down" : ""}></div>
                    </li>
                    <li className={"nav-item"}>
                        <button
                            className={view.startsWith('send') ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                            aria-current="page"
                            onClick={(e) => {
                                e.preventDefault();
                                setView("send");
                            }}>Send
                        </button>
                        <div className={view.startsWith("send") ? " triangle-down" : ""}></div>
                    </li>
                </ul>
            </div>
        </div>
        {getContent()}
    </PageLayout>
}
export default OwnerReports;