import * as React from "react";
import {Amenity} from "../../../Api/AmenityApi";
import AmenityDayTabs, {AmenityDayViews} from "../Tabs/AmenityDayTabs";
import FormFieldBuilder, {FormBuilderField} from "../../Form/FieldBuilder/FormFieldBuilder";
import AmenityDayOverview from "./AmenityDayOverview";
import AmenityForm from "../Forms/AmenityForm";

const AmenityOverview: React.FunctionComponent<{ amenity: Amenity, dayView: AmenityDayViews, setDayView: Function }> = ({ amenity, dayView, setDayView }) => {

    const assignFormValues = (field: FormBuilderField, value: any) => {
        field.value = value;
        field.disabled = true;
    }

    assignFormValues(AmenityForm.name, amenity.name);
    assignFormValues(AmenityForm.location, amenity.location);
    assignFormValues(AmenityForm.description, amenity.description);

    return (<>
        <div className="row">
            <div className="col-lg-12">
                <div className="row widget">
                    <div className="col-md-4 bg-light">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 mt-2 mb-2">
                                        <h5>Details</h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <FormFieldBuilder field={AmenityForm.name} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <FormFieldBuilder field={AmenityForm.location} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <FormFieldBuilder field={AmenityForm.description} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-12 bg-light mb-3">
                                <AmenityDayTabs view={dayView} setView={setDayView} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <div hidden={dayView !== AmenityDayViews.SUNDAY}>
                                    <AmenityDayOverview form={AmenityForm.sunday} day={amenity.sunday} />
                                </div>
                                <div hidden={dayView !== AmenityDayViews.MONDAY}>
                                    <AmenityDayOverview form={AmenityForm.monday} day={amenity.monday} />
                                </div>
                                <div hidden={dayView !== AmenityDayViews.TUESDAY}>
                                    <AmenityDayOverview form={AmenityForm.tuesday} day={amenity.tuesday} />
                                </div>
                                <div hidden={dayView !== AmenityDayViews.WEDNESDAY}>
                                    <AmenityDayOverview form={AmenityForm.wednesday} day={amenity.wednesday} />
                                </div>
                                <div hidden={dayView !== AmenityDayViews.THURSDAY}>
                                    <AmenityDayOverview form={AmenityForm.thursday} day={amenity.thursday} />
                                </div>
                                <div hidden={dayView !== AmenityDayViews.FRIDAY}>
                                    <AmenityDayOverview form={AmenityForm.friday} day={amenity.friday} />
                                </div>
                                <div hidden={dayView !== AmenityDayViews.SATURDAY}>
                                    <AmenityDayOverview form={AmenityForm.saturday} day={amenity.saturday} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default AmenityOverview;
