import React from "react";
import { CommunityLink } from "../../../Api/CommunityLinkApi";
import FormFieldBuilder from "../../Form/FieldBuilder/FormFieldBuilder";
import CommunityLinkForm from "../Forms/CommunityLinkForm";

const OverviewCommunityLink: React.FunctionComponent<{communityLink:CommunityLink}> = ({communityLink}) => {
    
    CommunityLinkForm.name.value = communityLink.name;
    CommunityLinkForm.url.value = communityLink.url;
    CommunityLinkForm.description.value = communityLink.description;
    CommunityLinkForm.category.value = communityLink.category;

    return (<>
        <div className="row widget bg-light">
            <div className="col-sm-12 mb-3">
                    <div className="row">
                        <div className="col-xxl-6 mb-3">
                            <FormFieldBuilder field={CommunityLinkForm.name} />
                            <FormFieldBuilder field={CommunityLinkForm.url} />
                        </div>
                        <div className="col-xxl-6 mb-3">
                            <FormFieldBuilder field={CommunityLinkForm.description} />
                            <FormFieldBuilder field={CommunityLinkForm.category} />
                        </div>
                    </div>
            </div>
        </div>
    </>)
}

export default OverviewCommunityLink;