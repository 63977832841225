import * as React from 'react';
import {useEffect, useState} from 'react';
import {BootstrapValidateClass, ValidateElementFunction} from "../../../types";

type InputProps = {
    id: string,
    validation: RegExp,
    doValidate: ValidateElementFunction,
    value: string,
    setValue: Function,
    required: boolean,
    label: string,
    disabled?: boolean,
    setValidFlag?: Function,
    accept: string,
    loading: boolean,
    maxSize?: number,
    hasError?: boolean
    hasErrorText?: string
}

const File: React.FunctionComponent<InputProps> = (p) => {

    const [validateClass, setValidateClass] = useState(BootstrapValidateClass.unvalidated);
    const [invalidFeedbackText, setInvalidFeedbackText] = useState("");
    const [fileName, setFileName] = useState("");

    useEffect(() => {
        if (!p.value) {
            setFileName("");
            if (p.setValidFlag) {
                p.setValidFlag(true);
            }
            setValidateClass(BootstrapValidateClass.unvalidated);
        }
    }, [p.value]);

    useEffect(() => {
        if (p.hasError) {
            setValidateClass(BootstrapValidateClass.isinvalid);
            if (p.setValidFlag) {
                p.setValidFlag(false);
            }
            setInvalidFeedbackText(p.hasErrorText || "");
        }
    }, [p.hasError, p.hasErrorText]);

    // useEffect(() => {
    //     if (value.length === 0 || p.disabled) {
    //         setValidateClass(BootstrapValidateClass.unvalidated);
    //         if (p.setValidFlag) {
    //             p.setValidFlag(false);
    //         }
    //         return;
    //     }
    //     if (p.doValidate(value, p.required, p.validation)) {
    //         setValidateClass(BootstrapValidateClass.isvalid);
    //         if (p.setValidFlag) {
    //             p.setValidFlag(true);
    //         }
    //     } else {
    //         setValidateClass(BootstrapValidateClass.isinvalid);
    //         if (p.setValidFlag) {
    //             p.setValidFlag(false);
    //         }
    //     }
    //
    // }, [value, p])

    return (<>
        <label htmlFor={p.id}>{p.label}</label>
        <input hidden={p.loading} type="file" accept={p.accept}
               className={"form-control " + validateClass}
               id={p.id} disabled={p.disabled}
               required={p.required}
               value={fileName}
               onChange={e => {
                   setInvalidFeedbackText("");
                   if (p.setValidFlag) {
                       p.setValidFlag(true);
                   }
                   setValidateClass(BootstrapValidateClass.unvalidated);

                   let value = e.target?.files?.[0];
                   if (value?.size && p.maxSize && value.size > p.maxSize) {
                       setValidateClass(BootstrapValidateClass.isinvalid);
                       if (p.setValidFlag) {
                           p.setValidFlag(false);
                       }
                       setInvalidFeedbackText("File must be less than 5 MB.")
                   } else {
                       setValidateClass(BootstrapValidateClass.isvalid);
                       p.setValue(value);
                   }
               }}/>
        <div hidden={p.loading} className="invalid-feedback">
            {invalidFeedbackText}
        </div>
        <div className="spinner-border text-primary" role="status" hidden={!p.loading}>
            <span className="visually-hidden">Loading...</span>
        </div>
    </>);
};

export default File;
