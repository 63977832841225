import * as React from "react";
import {Route, Switch, useRouteMatch} from "react-router";
import ViewOwnerPayoutCalculator from "../../Pages/Owners/OwnerPayout/CreatePayout";
import ViewOwnerPayouts from "../../Pages/Owners/OwnerPayout/ViewPayouts";
import ViewOwnerStatement from "../../Components/ManageOwnerStatement/View";
import OwnerReports from "../../Components/OwnerReports";
import CashFlowComparison from "../../Pages/Accounting/CashFlowComparison";


const OwnerContainer: React.FunctionComponent = () => {
    let {path} = useRouteMatch();

    return (<>
            <Switch>
                <Route exact path={path + "/payouts"}>
                    <ViewOwnerPayouts/>
                </Route>
                <Route exact path={path + "/payout-calculator"}>
                    <ViewOwnerPayoutCalculator/>
                </Route>

                <Route exact path={path + "/owner-statement/:reportId"}>
                    <ViewOwnerStatement/>
                </Route>

                <Route exact path={path + "/owner-reports"}>
                    <OwnerReports/>
                </Route>

                <Route exact path={path + "/cash-flow-property-comparison/:reportId"}>
                    <CashFlowComparison/>
                </Route>
            </Switch>
        </>
    );
}
export default OwnerContainer;