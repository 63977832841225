import {PropertyGroup, PropertyGroupExt} from "../types";
import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod} from "../api";

type getPropertyGroups = () => Promise<PropertyGroup[]>;
type addPropertyGroup = (body: PropertyGroupExt) => Promise<PropertyGroup>;
type getPropertyGroup = (uuid: string) => Promise<PropertyGroupExt>;
type editPropertyGroup = (uuid: string, body: PropertyGroupExt) => Promise<PropertyGroup>;

export const editPropertyGroup: editPropertyGroup = (async (uuid, body) => {
    const path = "/portfolios/" + getPortfolioId() + "/propertygroups/" + uuid;
    return fetchWithAuth(path, HttpMethod.PUT, JSON.stringify(body));
});

export const getPropertyGroups: getPropertyGroups = (async () => {
    const path = "/portfolios/" + getPortfolioId() + "/propertygroups";
    return fetchWithAuth(path, HttpMethod.GET);
});

export const addPropertyGroup: addPropertyGroup = (async body => {
    const path = "/portfolios/" + getPortfolioId() + "/propertygroups";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const getPropertyGroup: getPropertyGroup = (async (uuid) => {
    const path = "/portfolios/" + getPortfolioId() + "/propertygroups/" + uuid;
    return fetchWithAuth(path, HttpMethod.GET);
});
