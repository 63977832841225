import * as React from "react";
import {Switch, useRouteMatch} from "react-router";
import {Route} from "react-router-dom";
import VendorInvoiceRouter from "../../Components/VendorInvoice/VendorInvoiceRouter";

const VendorContainer: React.FunctionComponent = () => {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={path + "/:vendorId/invoices"}>
                <VendorInvoiceRouter />
            </Route>
        </Switch>
    )
}

export default VendorContainer;