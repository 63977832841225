import {AppUser} from "../types";
import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod} from "../api";

export interface CreateConversation {
    accountId: string,
    subject: string,
    message: CreateMessage,
}

export interface CreateMessage {
    body: string
}

export interface Conversation {
    uuid: string,
    accountId: string,
    propertyId: string,
    subject: string,
    lastMessage: Message,
    createdAt: string,
    messageCount: number
}

export interface Message {
    uuid: string,
    sender: AppUser,
    body: string,
    createdAt: string,
    isRead: boolean,
    sentByUser: boolean
}

type GetConversations = (propertyId: string) => Promise<Conversation[]>;
type PostConversation = (propertyId: string, conversation: CreateConversation) => Promise<Conversation>;
type GetConversationMessages = (propertyId: string, conversationId: string) => Promise<Message[]>;
type PostConversationMessage = (propertyId: string, conversationId: string, message: CreateMessage) => Promise<Message>;

const pathBuilder = (propertyId: string, extension = "") => {
    return "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/conversations/" + extension;
}

export const getConversations: GetConversations = (propertyId) => {
    const path = pathBuilder(propertyId);
    return fetchWithAuth(path, HttpMethod.GET);
}

export const createConversation: PostConversation = (propertyId, conversation) => {
    const path = pathBuilder(propertyId);
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(conversation));
}

export const getConversationMessages: GetConversationMessages = (propertyId, conversationId) => {
    const path = pathBuilder(propertyId, conversationId + "/messages");
    return fetchWithAuth(path, HttpMethod.GET);
}

export const createConversationMessage: PostConversationMessage = (propertyId, conversationId, message) => {
    const path = pathBuilder(propertyId, conversationId + "/messages");
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(message));
}
