import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from "react-router";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";

type Params = {
    name: string
}
const AutoSelectProperty: React.FunctionComponent<Params> = (p) => {

    const propertyId = useContext(PropertyIdContext)

    const {path} = useRouteMatch();
    const history = useHistory();

    const [isMissingProperty, setIsMissingProperty] = useState(false);
    const [doRedirect, setDoRedirect] = useState(false);

    useEffect(() => {
        if(doRedirect) {
            history.push({
                pathname: path + "/" + propertyId
            })
        }
    }, [doRedirect]);

    useEffect(() => {
        if (propertyId) {
            setDoRedirect(true);
            setIsMissingProperty(false);

        } else {
            setIsMissingProperty(true);
        }
    }, [propertyId]);


    return (isMissingProperty ? <>
        <p>{"<---"} Please select a property.</p>
    </> : <></>);
};

export default AutoSelectProperty;
