import * as React from 'react';
import {useEffect, useState} from 'react';

import Overview from "./Overview";
import CreateTransaction from "./CreateTransaction";
import LeaseAutopay from "./Autopay";
import Users from "./Users";
import LeaseRecurringTransactions from "./RecurringTransaction";
import PageLayout from "../../../Layouts/PageLayout";
import {useParams} from "react-router";
import {getLease} from "../../../Api/LeaseApi";
import {Lease} from "../../../types";
import {getTenants} from "../../../Api/PortfolioApi";


const ViewLease: React.FunctionComponent = () => {

    const [view, setView] = useState("overview")
    const { propertyId, leaseId } = useParams();
    const [leaseLoading, setLeaseLoading] = useState(false);
    const [lease, setLease] = useState<Lease>();


    useEffect(() => {
        if(leaseId) {
            fetchLease(leaseId);
        }
    }, [leaseId]);
    function fetchLease(leaseId: string) {
        setLeaseLoading(true);
        getLease(leaseId)
            .then((data) => {
                setLease(data);
            })
            .catch(e => {
                console.log(e);
            })
            .finally(() => setLeaseLoading(false));
    }

    function formatTenants(lease: Lease) {
        let tenants = "";
        if(lease.tenants) {
            lease.tenants.forEach(t => {
                if(tenants) {
                    tenants += ", " + t;
                } else {
                    tenants = t;
                }

            })
        }
        return tenants;
    }

    return (<>
        <PageLayout title="View Lease">
            <div className={"row"}>
                {lease && <div className={"col mb-2"}>
                    <strong>Property:</strong> {lease?.property.name}<br />
                    <strong>Unit:</strong> {lease?.unit.name}<br/>
                    <strong>Address:</strong><br/>
                    {lease?.unit.address.addressLine1}<br/>
                    {lease?.unit.address.city}, {lease?.unit.address.state} {lease?.unit.address.zip}<br/>
                    <strong>Tenants:</strong>{formatTenants(lease)}
                </div>}
            </div>
            <div className={"col mb-2"}>
                <ul className="nav ">
                    <li className={"nav-item"}>
                        <button className={view === "overview" ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                                aria-current="page"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setView("overview");
                                }}>Overview
                        </button>
                        <div className={view === "overview" ? " triangle-down" : ""}></div>
                    </li>
                    <li className="nav-item">
                        <button
                            className={view === "create_transaction" ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                            onClick={(e) => {
                                e.preventDefault();
                                setView("create_transaction");
                            }}>Create Transaction
                        </button>
                        <div className={view === "create_transaction" ? " triangle-down" : ""}></div>
                    </li>
                    <li className="nav-item">
                        <button
                            className={view === "recurring_transaction" ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                            onClick={(e) => {
                                e.preventDefault();
                                setView("recurring_transaction");
                            }}>Recurring Transactions
                        </button>
                        <div className={view === "recurring_transaction" ? " triangle-down" : ""}></div>
                    </li>
                    <li className="nav-item">
                        <button className={view === "autopay" ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setView("autopay");
                                }}>Autopay
                        </button>
                        <div className={view === "autopay" ? " triangle-down" : ""}></div>
                    </li>
                    <li className="nav-item">
                        <button className={view === "users" ? "nav-link btn btn-link active" : "nav-link btn btn-link"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setView("users");
                                }}>Users
                        </button>
                        <div className={view === "users" ? " triangle-down" : ""}></div>
                    </li>
                </ul>
            </div>
            <div hidden={view !== "overview"}>
                <Overview currentView={view}/>
            </div>
            <div hidden={view !== "create_transaction"}>
                <CreateTransaction done={() => {
                    setView("overview")
                }}/>
            </div>
            <div hidden={view !== "autopay"}>
                <LeaseAutopay
                    currentView={view}
                    done={() => {
                        setView("overview")
                    }}
                />
            </div>
            <div hidden={view !== "recurring_transaction"}>
                <LeaseRecurringTransactions
                    currentView={view}
                    done={() => {
                        setView("overview")
                    }}
                />
            </div>
            <div hidden={view !== "users"}>
                <Users />
            </div>
        </PageLayout>
    </>);
};

export default ViewLease;