import React, {FormEvent, useContext, useEffect, useState} from "react";
import Input from "../../../Components/Form/Input";
import {
    DateRegex,
    formInputValidate,
    formSelectValidate,
    GenericInputRegex,
    GenericSelectRegex,
    PropertyAccount
} from "../../../types";
import PageLayout from "../../../Layouts/PageLayout";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";
import Select from "../../../Components/Form/Select";
import {addManualJournalEntry, getPropertyAccounts} from "../../../Api/PropertyApi";

const ViewManualJournalEntry: React.FunctionComponent = () => {

    const propertyId = useContext(PropertyIdContext);

    const [transactionDate, setTransactionDate] = useState("");
    const [description, setDescription] = useState("");
    const [accountId1, setAccountId1] = useState("");
    const [creditAmount1, setCreditAmount1] = useState("0");
    const [debitAmount1, setDebitAmount1] = useState("0");
    const [accountId2, setAccountId2] = useState("");
    const [accounts, setAccounts] = useState<PropertyAccount[]>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    function clear() {
        setTransactionDate("");
        setDescription("");
        setAccountId1("");
        setAccountId2("");
        setDebitAmount1("");
        setCreditAmount1("");
        setErrorMessage("");
    }

    function doSubmit(e: FormEvent) {
        e.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");
        addManualJournalEntry(propertyId, {
            date: transactionDate,
            description: description,
            account1: accountId1,
            debit1: parseFloat(debitAmount1),
            credit1: parseFloat(creditAmount1),
            account2: accountId2,
            debit2: parseFloat(creditAmount1),
            credit2: parseFloat(debitAmount1)
        })
            .then((r) => {
                if (r.ok) {
                    clear();
                    setSuccessMessage("Successfully added journal entry.")
                } else {
                    setErrorMessage("Error processing request")
                }
            })
            .catch((e) => setErrorMessage(e.message))
            .finally(() => {})
    }

    useEffect(() => {
        if(propertyId) {
            getPropertyAccounts(propertyId)
                .then((az) => {
                    setAccounts(az);
                })
                .catch(e => setErrorMessage(e.message));
        }
    }, [propertyId]);

    useEffect(() => {
        if(debitAmount1 && debitAmount1.length > 1 && debitAmount1.startsWith("0")) {
            setDebitAmount1(debitAmount1.substring(1));
        }
        if(creditAmount1 && creditAmount1.length > 1 && creditAmount1.startsWith("0")) {
            setCreditAmount1(creditAmount1.substring(1));
        }
        if(!debitAmount1) {
            setDebitAmount1("0");
        }
        if(!creditAmount1) {
            setCreditAmount1("0");
        }
    }, [debitAmount1, creditAmount1]);

    return <PageLayout title={"Manual Journal Entry"} propertyId={propertyId}>
        <form onSubmit={doSubmit}>
            <div className={"row"}>

                <div className={"col-md-4 mb-3"}>
                    <Input id={"startDate"} validation={DateRegex.expression}
                           doValidate={formInputValidate}
                           value={transactionDate}
                           setValue={setTransactionDate}
                           required={true}
                           invalidFeedbackText={DateRegex.helpText}
                           type={"date"} label={"Date"}
                    />
                </div>
                <div className={"col-md-8 mb-3"}>
                    <Input id={"description"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={description}
                           setValue={setDescription}
                           required={true}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           type={"text"} label={"Description"}
                    />
                </div>
            </div>
            <div className={"row"}>

                <div className={"col-md-4 mb-3"}>
                    <Select id={"account1"} validation={GenericSelectRegex.expression}
                            doValidate={formSelectValidate} value={accountId1} setValue={setAccountId1}
                            required={true}
                            invalidFeedbackText={GenericSelectRegex.helpText} label={"Account"}>
                        <option disabled={true} value={""}>Choose one...</option>
                        {
                            accounts.map(e => (
                                <option key={e.uuid} value={e.uuid}>{e.code} - {e.description}</option>
                            ))
                        }
                    </Select>
                </div>
                <div className={"col-md-4 mb-3"}>
                    <Input id={"debit1"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={debitAmount1}
                           setValue={setDebitAmount1}
                           required={true}
                           disabled={creditAmount1 !== undefined && parseFloat(creditAmount1) > 0}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           min={0}
                           step={.01}
                           type={"number"} label={"Debit"}
                    />
                </div>
                <div className={"col-md-4 mb-3"}>
                    <Input id={"credit1"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={creditAmount1}
                           setValue={setCreditAmount1}
                           disabled={debitAmount1 !== undefined && parseFloat(debitAmount1) > 0}
                           required={true}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           min={0}
                           step={.01}
                           type={"number"} label={"Credit"}
                    />
                </div>
            </div>
            <div className={"row"}>

                <div className={"col-md-4 mb-3"}>
                    <Select id={"account"} validation={GenericSelectRegex.expression}
                            doValidate={formSelectValidate} value={accountId2} setValue={setAccountId2}
                            required={true}
                            invalidFeedbackText={GenericSelectRegex.helpText} label={"Account"}>
                        <option disabled={true} value={""}>Choose one...</option>
                        {
                            accounts.map(e => (
                                <option key={e.uuid} value={e.uuid}>{e.code} - {e.description}</option>
                            ))
                        }
                    </Select>
                </div>
                <div className={"col-md-4 mb-3"}>
                    <Input id={"debit2"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={creditAmount1}
                           setValue={setCreditAmount1}
                           required={true}
                           disabled={debitAmount1 !== undefined && parseFloat(debitAmount1) > 0}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           min={0}
                           step={.01}
                           type={"number"} label={"Debit"}
                    />
                </div>
                <div className={"col-md-4 mb-3"}>
                    <Input id={"credit2"} validation={GenericInputRegex.expression}
                           doValidate={formInputValidate}
                           value={debitAmount1}
                           setValue={setDebitAmount1}
                           disabled={creditAmount1 !== undefined && parseFloat(creditAmount1) > 0}
                           required={true}
                           invalidFeedbackText={GenericInputRegex.helpText}
                           min={0}
                           step={.01}
                           type={"number"} label={"Credit"}
                    />
                </div>
            </div>
            <div className={"row"}>

                <div className={"col-md-4 mb-3"}>
                    <button className={"btn btn-primary"} type={"submit"}>Submit</button>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-md-12"}>
                    <div hidden={!successMessage} className="alert alert-success" role="alert">
                        {successMessage}
                    </div>

                    <div hidden={!errorMessage} className="alert alert-danger" role="alert">
                        {errorMessage}
                    </div>
                </div>
            </div>
        </form>
    </PageLayout>;
}

export default ViewManualJournalEntry;