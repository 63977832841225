import * as React from 'react';
import {createContext, useCallback, useEffect, useState} from 'react';
import {Route, Switch, useHistory, useLocation, useRouteMatch} from 'react-router';
import './container.css';
import CustomersContainer from "../CustomersContainer";
import SettingsContainer from "../SettingsContainer";
import {APP_PORTFOLIO_ID, APP_PORTFOLIO_SELECT_PATH, APP_PROPERTY_ID, Portfolio} from "../../types";
import RealEstateContainer from "../RealEstateContainer";
import ReportsContainer from "../ReportsContainer";
import {currentSession, doLoginRedirect} from "../../Auth";
import DashboardContainer from "../DashboardContainer";
import MaintenanceContainer from "../MaintenanceContainer";
import TenantServicesContainer from "../TenantServicesContainer";
import VendorContainer from "../VendorContainer";
import ContainerLayout from "../../Layouts/ContainerLayout";
import DocumentsContainer from "../DocumentsContainer";
import {getPortfolio} from "../../Api/PortfolioApi";
import AccountingContainer from "../AccountingContainer";
import OwnerContainer from "../OwnerContainer";

export const PortfolioIdContext = createContext<string>("")
export const PropertyIdContext = createContext<string>("")

const PrivateMainContainer: React.FunctionComponent = () => {

    const [doPortfolioSelectedRedirect, setDoPortfolioSelectedRedirect] = useState(false);

    const [portfolioId, setPortfolioId] = useState<string>("")
    const [propertyId, setPropertyId] = useState<string>("")

    const [portfolio, setPortfolio] = useState<Portfolio>()

    const { path} = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const fullPath = location.pathname;

    const [validToken, setValidToken] = useState(false);

    useEffect(() => {
        if (portfolioId) {
            getPortfolio(portfolioId)
                .then(setPortfolio)
                .catch(console.error)
        }
    }, [portfolioId])

    useEffect(() => {
        if (doPortfolioSelectedRedirect) {
            setDoPortfolioSelectedRedirect(false);
            history.push({
                pathname: APP_PORTFOLIO_SELECT_PATH,
                search: "?redirect_path=" + window.location.pathname
            })
        }
    }, [doPortfolioSelectedRedirect, history]);


    useEffect(() => {
        currentSession().then(() => {
            setValidToken(true);
            const portfolioId = localStorage.getItem(APP_PORTFOLIO_ID)
            const propertyId = localStorage.getItem(APP_PROPERTY_ID)
            if (portfolioId) {
                setPortfolioId(portfolioId)
            }
            else {
                setDoPortfolioSelectedRedirect(true)
            }
            if (propertyId) {
                setPropertyId(propertyId)
            }
        }).catch(e => {
            console.error("error" + e)
            doLoginRedirect(fullPath);
        })
    }, []);

    const updatePortfolioId = useCallback((portfolioId: string) => {
        setPortfolioId(portfolioId)
        setPropertyId("")
        localStorage.setItem(APP_PORTFOLIO_ID, portfolioId)
        localStorage.setItem(APP_PROPERTY_ID, "")
    }, [])

    const updatePropertyId = useCallback((propertyId: string) => {
        setPropertyId(propertyId)
        localStorage.setItem(APP_PROPERTY_ID, propertyId)
    }, [])

    return <>
        {validToken &&
            <PortfolioIdContext.Provider value={portfolioId}>
                <PropertyIdContext.Provider value={propertyId}>
                    <ContainerLayout
                        setPortfolioId={updatePortfolioId}
                        setPropertyId={updatePropertyId}
                        showGettingStartedChecklist={portfolio?.onBoardingStatus !== "DISMISSED"}
                    >
                        <Switch>
                            <Route path={path + "/dashboard"}>
                                <DashboardContainer />
                            </Route>
                            <Route path={path + "/reports"}>
                                <ReportsContainer />
                            </Route>
                            <Route path={path + "/customers"}>
                                <CustomersContainer />
                            </Route>
                            <Route path={path + "/esign"}>
                                <DocumentsContainer />
                            </Route>
                            <Route path={path + "/settings"}>
                                <SettingsContainer />
                            </Route>
                            <Route path={path + "/realestate"}>
                                <RealEstateContainer />
                            </Route>
                            <Route path={path + "/maintenance"}>
                                <MaintenanceContainer />
                            </Route>
                            <Route path={path + "/tenant-services"}>
                                <TenantServicesContainer />
                            </Route>
                            <Route path={path + "/vendors"}>
                                <VendorContainer/>
                            </Route>
                            <Route path={path + "/accounting"}>
                                <AccountingContainer/>
                            </Route>
                            <Route path={path + "/owners"}>
                                <OwnerContainer/>
                            </Route>
                        </Switch>
                    </ContainerLayout>
                </PropertyIdContext.Provider>
            </PortfolioIdContext.Provider>
        }
    </>
}

export default PrivateMainContainer;
