import {
    AddOwnerReportResponse,
    Bill,
    Business, CashFlowComparisonReport, CheckResponse,
    CreatePortfolio,
    DepositRegister,
    ExpenseRegister,
    GetAppUser,
    GetBankAccount,
    GetBusiness,
    GetOwnerPayout, LetterTemplate,
    ManagementFee,
    OwnerBalancesPayoutSummary,
    Portfolio,
    PortfolioExt,
    PortfolioVendor,
    Property, PropertyVendor,
    ReconcileDto,
    Tenant,
    TenantReceipt,
    TrustAccountBalances,
    Vendor
} from "../types";
import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod} from "../api";
import {Conversation} from "./ConversationApi";

interface UpdatePortfolioBody {
    payoutFlowType?: string
}

type getPortfolios = () => Promise<Portfolio[]>;
type getPortfolio = (portfolioId: string) => Promise<Portfolio>;
type getPortfoloBusinesses = () => Promise<Business[]>;
type updatePortfolio = (body: UpdatePortfolioBody) => Promise<Portfolio>;
type createPortfolioBusiness = (body: Business) => Promise<Business>;
type updatePortfolioBusiness = (body: Business) => Promise<Business>;
type deletePortfolioBusiness = () => Promise<Response>;
type getPortfolioSettings = () => Promise<PortfolioExt>;
type getUnreadConversations = () => Promise<Conversation[]>;
type createPortfolio = (body: CreatePortfolio) => Promise<Portfolio>;
type PortfolioOnBoardingProgress = (portfolioId: string) => Promise<string>;
type getTrustAccounts = (portfolioId: string) => Promise<GetBankAccount[]>;
type getOwners = (portfolioId: string) => Promise<GetBusiness[]>;
type getOwnersWithProperties = (portfolioId: string) => Promise<Business[]>;
type getOwner = (portfolioId: string, businessId: string) => Promise<Business>;
type getOwnerPortalUsers = (portfolioId: string, businessId: string) => Promise<GetAppUser[]>;
type addOwnerPortalUser = (portfolioId: string, businessId: string, body: {
    email: string
}) => Promise<Response>;
type addOwnerReport = (portfolioId: string, body: {
    type: string,
    name: string,
    preview: boolean,
    parameters: {
        key: string,
        value: string
    }[],
    ownerIds: string[]
}) => Promise<AddOwnerReportResponse[]>;
type deleteOwnerPortalUser = (portfolioId: string, businessId: string, userId: string) => Promise<Response>;
type updateOwner = (portfolioId: string, businessId: string, body: Business) => Promise<Response>;

export type UpdateBankAccountBody = {
    accountHolderName: string,
    accountType: string,
    routingNumber: string,
    accountNumber: string
}
type updateOwnerBankAccount = (portfolioId: string, businessId: string, body: UpdateBankAccountBody) => Promise<Response>;
type addOwner = (portfolioId: string, body: Business) => Promise<Business>;
type addTenantReceipt = (portfolioId: string, body: {
    date: string,
    payer: string,
    amount: number,
    description: string,
    accountId: string,
    reference: string,
    type: string,
    propertyId: string,
    leaseId: string,
    checkNumber: string
}) => Promise<Response>;
type getTenantReceipts = (portfolioId: string) => Promise<TenantReceipt[]>;

type addOwnerReceipt = (portfolioId: string, body: {
    date: string,
    amount: number,
    description: string,
    accountId: string,
    reference: string,
    type: string,
    propertyId: string,
    glAccount: string,
    checkNumber: string
}) => Promise<Response>;
type getOwnerReceipts = (portfolioId: string) => Promise<TenantReceipt[]>;

type addDeposit = (portfolioId: string, body: {
    date: string,
    bankAccountId: string,
    receipts: string[]
}) => Promise<Response>;
type addTrustAccount = (portfolioId: string, body: AddTrustAccountBody) => Promise<GetBankAccount>;
type getTrustAccountBalances = (portfolioId: string) => Promise<TrustAccountBalances>;
type getTenants = (portfolioId: string) => Promise<Tenant[]>;
type getReconcile = (portfolioId: string) => Promise<ReconcileDto>;
type reconcilePayout = (portfolioId: string, payoutId: string, body: {
    date: string
}) => Promise<Response>;
type reconcileCCMPayment = (portfolioId: string, paymentId: string) => Promise<Response>;
type getOwnerBalancesPayoutCalculator = (portfolioId: string) => Promise<OwnerBalancesPayoutSummary>;
export type CreateOwnerPayout = {
    owners: {
        uuid: string,
        properties: {
            uuid: string,
            amount: number
        }[]
    }[]
}
type createOwnerPayout = (portfolioId: string, body: CreateOwnerPayout) => Promise<Response>;

type getOwnerPayouts = (portfolioId: string) => Promise<GetOwnerPayout[]>;

type getManagementFees = (portfolioId: string, startDate: string, endDate: string, propertyId?: string) => Promise<ManagementFee[]>;

type addManagementFees = (portfolioId: string, body: {
    paymentMethod: string,
    managementFees: ManagementFee[]
}) => Promise<Response>;

type addBillItem = (portfolioId: string, body: {
    accountId: string,
    cashAccountId: string,
    description: string,
    amount: number,
    date: string,
    propertyId: string,
    reference: string
}) => Promise<Response>;

type AddTrustAccountBody = {
    accountHolderName: string,
    accountType: string,
    accountNumber: string,
    routingNumber: string
}

type getDepositRegister = (portfolioId: string, startDate: string, endDate: string) => Promise<DepositRegister>;
type getExpenseRegister = (portfolioId: string, startDate: string, endDate: string) => Promise<ExpenseRegister>;
type getPortfolioVendor = (portfolioId: string) => Promise<PortfolioVendor>;
type updatePortfolioVendor = (portfolioId: string, body: {
    name: string,
    phone: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    stateCode: string,
    zip: string
}) => Promise<Response>;

type updatePortfolioVendorBank = (portfolioId: string, body: {
    accountHolderName: string,
    accountType: string,
    routingNumber: string,
    accountNumber: string
}) => Promise<Response>;

type getPortfolioVendors = (portfolioId: string) => Promise<Vendor[]>;

type getBills = (portfolioId: string) => Promise<Bill[]>;
type addBills = (portfolioId: string, body: {
    vendorId: string,
    tenantId: string,
    bankAccountId: string,
    items: string[],
    date: string,
    checkNumber: string
}) => Promise<Response>;

type addPortfolioVendors = (portfolio: string, body: {
    name: string,
    phone: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    stateCode: string,
    zip: string
}) => Promise<PortfolioVendor>;

type resendUserInvite = (userId: string) => Promise<Response>;

type addLetter = (portfolioId: string, body: {
    tenants: string[],
    owners: string[],
    subject: string,
    body: string
}) => Promise<Response>;

type addLetterTemplate = (portfolioId: string, body: {
    name: string,
    content: string
}) => Promise<Response>;

type getLetterTemplates = (portfolioId: string) => Promise<LetterTemplate[]>;

type getLetterTemplate = (portfolioId: string, id: string) => Promise<LetterTemplate>;

type createOwnerPayoutCheck = (portfolioId: string, payoutId: string, ownerId: string, body: {
    date: string,
    checkNumber: number
}) => Promise<CheckResponse>;

type createBillCheck = (portfolioId: string, billId: string) => Promise<CheckResponse>;

export const createOwnerPayoutCheck: createOwnerPayoutCheck = (async (portfolioId, payoutId, ownerId, body) => {
    const path = "/portfolios/" + portfolioId + "/owner-payouts/" + payoutId + "/owners/" + ownerId + "/create-check";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const createBillCheck: createBillCheck = (async (portfolioId, billId) => {
    const path = "/portfolios/" + portfolioId + "/bills/" + billId + "/create-check";
    return fetchWithAuth(path, HttpMethod.POST);
});

export const getLetterTemplate: getLetterTemplate = (async (portfolioId, id) => {
    const path = "/portfolios/" + portfolioId + "/letter-templates/" + id;
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getLetterTemplates: getLetterTemplates = (async (portfolioId) => {
    const path = "/portfolios/" + portfolioId + "/letter-templates";
    return fetchWithAuth(path, HttpMethod.GET);
});


export const addLetterTemplate: addLetterTemplate = (async (portfolioId, body) => {
    const path = "/portfolios/" + portfolioId + "/letter-templates";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const addLetter: addLetter = (async (portfolioId, body) => {
    const path = "/portfolios/" + portfolioId + "/letters";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});
export const resendUserInvite: resendUserInvite = (async (userId) => {
    const path = "/portfolios/" + getPortfolioId() + "/user-invites/" + userId;
    return fetchWithAuth(path, HttpMethod.GET);
});

export const reconcilePayout: reconcilePayout = (async (portfolioId, payoutId, body) => {
    const path = "/portfolios/" + portfolioId + "/reconcile-payout/" + payoutId;
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const getOwnerPayouts: getOwnerPayouts = (async (portfolioId) => {
    const path = "/portfolios/" + portfolioId + "/owner-payouts";
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getPortfolioVendor: getPortfolioVendor = (async (portfolioId) => {
    let path = "/portfolios/" + portfolioId + "/vendor";
    return fetchWithAuth(path, HttpMethod.GET);
});

export const updatePortfolioVendor: updatePortfolioVendor = (async (portfolioId, body) => {
    let path = "/portfolios/" + portfolioId + "/vendor";
    return fetchWithAuth(path, HttpMethod.PUT, JSON.stringify(body));
});

export const updatePortfolioVendorBank: updatePortfolioVendorBank = (async (portfolioId, body) => {
    let path = "/portfolios/" + portfolioId + "/vendor-bank";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const getPortfolioVendors: getPortfolioVendors = (async (portfolioId) => {
    let path = "/portfolios/" + portfolioId + "/vendors";
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getBills: getBills = (async (portfolioId) => {
    let path = "/portfolios/" + portfolioId + "/bills";
    return fetchWithAuth(path, HttpMethod.GET);
});

export const addPortfolioVendors: addPortfolioVendors = (async (portfolioId, body) => {
    let path = "/portfolios/" + portfolioId + "/vendors";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const addBills: addBills = (async (portfolioId, body) => {
    let path = "/portfolios/" + portfolioId + "/bills";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const getManagementFees: getManagementFees = (async (portfolioId, startDate, endDate, propertyId) => {
    let path = "/portfolios/" + portfolioId + "/management-fees?startDate=" + startDate + "&endDate=" + endDate;
    if (propertyId) {
        path += "&propertyId=" + propertyId;
    }
    return fetchWithAuth(path, HttpMethod.GET);
});

export const addManagementFees: addManagementFees = (async (portfolioId, body) => {
    let path = "/portfolios/" + portfolioId + "/management-fees";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const addBillItem: addBillItem = (async (portfolioId, body) => {
    let path = "/portfolios/" + portfolioId + "/bill-items";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const getTenantReceipts: getTenantReceipts = (async (portfolioId) => {
    const path = "/portfolios/" + portfolioId + "/tenant-receipts";
    return fetchWithAuth(path, HttpMethod.GET);
});

export const createOwnerPayout: createOwnerPayout = (async (portfolioId, body) => {
    const path = "/portfolios/" + portfolioId + "/owner-payouts";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const addTenantReceipt: addTenantReceipt = (async (portfolioId, body) => {
    const path = "/portfolios/" + portfolioId + "/tenant-receipts";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

type deleteTenantReceipt = (portfolioId: string, receiptId: string) => Promise<Response>;
export const deleteTenantReceipt: deleteTenantReceipt = (async (portfolioId, receiptId) => {
    const path = "/portfolios/" + portfolioId + "/tenant-receipts/" + receiptId;
    return fetchWithAuth(path, HttpMethod.DELETE);
});

type deleteBillItem = (portfolioId: string, billItemId: string) => Promise<Response>;
export const deleteBillItem: deleteBillItem = (async (portfolioId, billItemId) => {
    const path = "/portfolios/" + portfolioId + "/bill-items/" + billItemId;
    return fetchWithAuth(path, HttpMethod.DELETE);
});

type deleteOwnerReceipt = (portfolioId: string, receiptId: string) => Promise<Response>;
export const deleteOwnerReceipt: deleteOwnerReceipt = (async (portfolioId, receiptId) => {
    const path = "/portfolios/" + portfolioId + "/owner-receipts/" + receiptId;
    return fetchWithAuth(path, HttpMethod.DELETE);
});

export const addOwnerReceipt: addOwnerReceipt = (async (portfolioId, body) => {
    const path = "/portfolios/" + portfolioId + "/owner-receipts";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const addDeposit: addDeposit = (async (portfolioId, body) => {
    const path = "/portfolios/" + portfolioId + "/bank-deposits";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const reconcileCCMPayment: reconcileCCMPayment = (async (portfolioId, paymentId) => {
    const path = "/portfolios/" + portfolioId + "/reconcile-ccm-payment/" + paymentId;
    return fetchWithAuth(path, HttpMethod.POST);
});

export const getReconcile: getReconcile = (async (portfolioId) => {
    const path = "/portfolios/" + portfolioId + "/reconcile"
    return fetchWithAuth(path, HttpMethod.GET);
});

type getCashFlowComparison = (portfolioId: string, reportId: string) => Promise<CashFlowComparisonReport>;
export const getCashFlowComparison: getCashFlowComparison = (async (portfolioId, reportId) => {
    const path = "/portfolios/" + portfolioId + "/reports/cash-flow-property-comparison/" + reportId;
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getTrustAccountBalances: getTrustAccountBalances = (async (portfolioId) => {
    const path = "/portfolios/" + portfolioId + "/trust-account-balances"
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getOwnerBalancesPayoutCalculator: getOwnerBalancesPayoutCalculator = (async (portfolioId) => {
    const path = "/portfolios/" + portfolioId + "/owner-payout-calculator"
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getTenants: getTenants = (async (portfolioId) => {
    const path = "/portfolios/" + portfolioId + "/tenants"
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getPortfolios: getPortfolios = (async () => {
    const path = "/portfolios"
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getPortfolio: getPortfolio = (async (portfolioId) => {
    const path = "/portfolios/" + portfolioId
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getTrustAccounts: getTrustAccounts = (async (portfolioId) => {
    const path = "/portfolios/" + portfolioId + "/trust-accounts"
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getOwners: getOwners = (async (portfolioId) => {
    const path = "/portfolios/" + portfolioId + "/owners"
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getOwnersWithProperties: getOwnersWithProperties = async (portfolioId) => {
    const path = `/portfolios/${portfolioId}/owners-with-properties`;
    return fetchWithAuth(path, HttpMethod.GET);
};

export const getOwner: getOwner = async (portfolioId, businessId) => {
    const path = `/portfolios/${portfolioId}/owners/${businessId}`;
    return fetchWithAuth(path, HttpMethod.GET);
};

export const getOwnerPortalUsers: getOwnerPortalUsers = async (portfolioId, businessId) => {
    const path = `/portfolios/${portfolioId}/owners/${businessId}/portal-users`;
    return fetchWithAuth(path, HttpMethod.GET);
};


export const updateOwner: updateOwner = async (portfolioId, businessId, body) => {
    const path = `/portfolios/${portfolioId}/owners/${businessId}`;
    return fetchWithAuth(path, HttpMethod.PUT, JSON.stringify(body));
};

export const updateOwnerBankAccount: updateOwnerBankAccount = async (portfolioId, businessId, body) => {
    const path = `/portfolios/${portfolioId}/owners/${businessId}/bank-accounts`;
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
};

export const addOwnerPortalUser: addOwnerPortalUser = async (portfolioId, businessId, body) => {
    const path = `/portfolios/${portfolioId}/owners/${businessId}/portal-users`;
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
};

export const addOwnerReport: addOwnerReport = async (portfolioId, body) => {
    const path = `/portfolios/${portfolioId}/owners-reports`;
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
};

export const deleteOwnerPortalUser: deleteOwnerPortalUser = async (portfolioId, businessId, userId) => {
    const path = `/portfolios/${portfolioId}/owners/${businessId}/portal-users/${userId}`;
    return fetchWithAuth(path, HttpMethod.DELETE);
};

export const addOwner: addOwner = (async (portfolioId, body) => {
    const path = "/portfolios/" + portfolioId + "/owners"
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const addTrustAccount: addTrustAccount = (async (portfolioId, body) => {
    const path = "/portfolios/" + portfolioId + "/trust-accounts"
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const getDepositRegister: getDepositRegister = (async (portfolioId, startDate, endDate) => {
    const path = `/portfolios/${portfolioId}/deposit-register?startDate=${startDate}&endDate=${endDate}`
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getExpenseRegister: getExpenseRegister = (async (portfolioId, startDate, endDate) => {
    const path = `/portfolios/${portfolioId}/expense-register?startDate=${startDate}&endDate=${endDate}`
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getPortfolioBusinesses: getPortfoloBusinesses = (async () => {
    const path = "/portfolios/" + getPortfolioId() + "/businesses";
    return fetchWithAuth(path, HttpMethod.GET);
});

export const updatePortfolio: updatePortfolio = (async (body) => {
    const path = "/portfolios/" + getPortfolioId();
    return fetchWithAuth(path, HttpMethod.PATCH, JSON.stringify(body));
});

export const createPortfolioBusiness: createPortfolioBusiness = (async (body) => {
    const path = "/portfolios/" + getPortfolioId() + "/business";
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const updatePortfolioBusiness: updatePortfolioBusiness = (async (body) => {
    const path = "/portfolios/" + getPortfolioId() + "/business";
    return fetchWithAuth(path, HttpMethod.PUT, JSON.stringify(body));
});

export const deletePortfolioBusiness: deletePortfolioBusiness = (async () => {
    const path = "/portfolios/" + getPortfolioId() + "/business";
    return fetchWithAuth(path, HttpMethod.DELETE);
});

export const getPortfolioSettings: getPortfolioSettings = (async () => {
    const path = "/portfolios/" + getPortfolioId() + "/settings";
    return fetchWithAuth(path, HttpMethod.GET);
});

export const getUnreadConversations: getUnreadConversations = () => {
    const path = "/portfolios/" + getPortfolioId() + "/unread-conversations";
    return fetchWithAuth(path, HttpMethod.GET);
}

export const createPortfolio: createPortfolio = (async (body) => {
    const path = "/portfolios";
    console.log(body);
    return fetchWithAuth(path, HttpMethod.POST, JSON.stringify(body));
});

export const getPortfolioOnBoardingProgress: PortfolioOnBoardingProgress = (async (portfolioId) => {
    return fetchWithAuth("/portfolios/" + portfolioId + "/onboarding", HttpMethod.GET)
})

export const dismissPortfolioOnBoardingProgress: PortfolioOnBoardingProgress = (async (portfolioId) => {
    return fetchWithAuth("/portfolios/" + portfolioId + "/onboarding", HttpMethod.POST)
})

// export const getOwners: (portfolioId: string) => Promise<Owner[]> = async (portfolioId) => {
//     const path = "/portfolios/" + portfolioId + "/owners";
//     return fetchWithAuth(path, HttpMethod.GET);
// };

export const getProperties: (portfolioId: string) => Promise<Property[]> = async (portfolioId) => {
    const path = "/portfolios/" + portfolioId + "/properties";
    return fetchWithAuth(path, HttpMethod.GET);
};