import {IdempotentKey, PaymentReportPayment} from "../types";
import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod} from "../api";

type getPayments = (propertyId: string, startDate: string, endDate: string, status: string) => Promise<PaymentReportPayment[]>;
type getIdempotentPaymentKey = () => Promise<IdempotentKey>;
type sendPaymentReceipt = (paymentId: string) => Promise<Response>;

export const getPayments: getPayments = (async (propertyId: string, startDate: string, endDate, status: string) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/payments" + "?startDate=" + startDate + "&endDate=" + endDate + "&propertyId=" + propertyId + "&status=" + status, HttpMethod.GET);
});

export const getIdempotentPaymentKey: getIdempotentPaymentKey = (async () => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/payments/idempotent-key", HttpMethod.GET);
});

export const sendPaymentReceipt: sendPaymentReceipt = (async (paymentId) => {
    return fetchWithAuth("/portfolios/" + getPortfolioId() + "/payments/" + paymentId + "/send-receipt", HttpMethod.POST);
});
