import * as React from "react";
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {getTenantPackageById, TenantPackage, TenantPackageStatus} from "../../../Api/PackageManangementApi";
import LoadingOverlay from "../../LoadingOverlay";
import Tabs, {Tab} from "../../Tabs/Tabs";
import {getTicketIdFromUuid} from "../../../helpers";
import EditTenantPackage from "./Edit/EditTenantPackage";
import {ARAccount, Property} from "../../../types";
import {getProperty} from "../../../Api/PropertyApi";
import {getARAccounts} from "../../../Api/AccountApi";
import OverviewTenantPackage from "./Overview/OverviewTenantPackage";

const ManageTenantPackage: React.FunctionComponent = () => {

    enum PackageView {
        OVERVIEW="OVERVIEW", EDIT="EDIT"
    }

    const { propertyId, tenantPackageId } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [view, setView] = useState<string>(PackageView.OVERVIEW);
    const [property, setProperty] = useState<Property>();
    const [tenantAccounts, setTenantAccounts] = useState<ARAccount[]>([]);
    const [tenantPackage, setTenantPackage] = useState<TenantPackage>();

    const tabs: Tab[] = [
        {
            targetView: PackageView.OVERVIEW,
            label: "Overview"
        },
        {
            targetView: PackageView.EDIT,
            label: "Edit"
        }
    ]

    const fetchTenantPackage = () => {
        setLoading(true);
        Promise.all([
            getProperty(propertyId)
                .then(data => data),
            getARAccounts(propertyId)
                .then(data => data),
            getTenantPackageById(propertyId, tenantPackageId)
                .then(data => data)
        ]).then(values => {
            setProperty(values[0]);
            setTenantAccounts(values[1]);
            setTenantPackage(values[2]);
        })
            .catch(console.error)
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchTenantPackage();
    }, [tenantPackageId])

    return (loading ? <LoadingOverlay show={loading} /> : <>
            <Tabs view={view} setView={setView} tabs={tabs} />
            <div className="col-md-12 widget">
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <h3>Tenant Package</h3>
                        <label htmlFor="tp_uuid">Ticket Id</label>
                        <h5 id="tp_uuid">{getTicketIdFromUuid(tenantPackage?.uuid || '')}</h5>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label htmlFor="tp_status">Status</label>
                        <h3 id="tp_status">
                            <span className={tenantPackage?.status === TenantPackageStatus.RECEIVED ? "badge rounded-pill bg-info" : "badge rounded-pill bg-success"}>
                                {tenantPackage?.status.substring(0,1).toUpperCase() + "" + tenantPackage?.status.substring(1).toLowerCase().replace('_', ' ')}
                            </span>
                        </h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <label htmlFor="tp_pn">Property</label>
                        <h5 id="tp_pn">{property?.name}</h5>
                        <h6 id="tp_un">{}</h6>
                    </div>
                </div>
                <div className="row">
                    <div hidden={view !== PackageView.OVERVIEW}>
                        {tenantPackage &&
                            <OverviewTenantPackage tenantPackage={tenantPackage} unitName={tenantAccounts.find((account: ARAccount) => { return account.uuid === tenantPackage?.tenantAccountId })?.unitName || ''}/>
                        }
                    </div>
                    <div hidden={view !== PackageView.EDIT}>
                        {tenantPackage &&
                            <EditTenantPackage tenantPackage={tenantPackage} done={() => {
                                setView(PackageView.OVERVIEW);
                                fetchTenantPackage();
                            }}/>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ManageTenantPackage;
