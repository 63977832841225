import {FormBuilderField, FormFieldType, InputFieldType} from "../../Form/FieldBuilder/FormFieldBuilder";
import {DateRegex, formInputValidate, GenericInputRegex} from "../../../types";

interface CommunityEventForm {
    name: FormBuilderField,
    category: FormBuilderField,
    description: FormBuilderField,
    startDate: FormBuilderField,
    startTime: FormBuilderField,
    endDate: FormBuilderField,
    endTime: FormBuilderField
}

const CommunityEventForm: CommunityEventForm = {
    name: {
        type: FormFieldType.INPUT,
        element: {
            id: "name",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "Name",
        value: undefined,
        setValue: () => {}
    },
    category: {
        type: FormFieldType.INPUT,
        element: {
            id: "category",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "Category",
        value: undefined,
        setValue: () => {}
    },
    description: {
        type: FormFieldType.TEXT_AREA,
        textAreaRows: 4,
        element: {
            id: "description",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "Description",
        value: undefined,
        setValue: () => {}
    },
    startDate: {
        type: FormFieldType.DATE,
        element: {
            id: "startDate",
            required: true,
            validate: formInputValidate,
            regex: DateRegex,
        },
        label: "Start Date",
        value: undefined,
        setValue: () => {}
    },
    startTime: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.TIME,
        element: {
            id: "startTime",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "Start Time",
        value: undefined,
        setValue: () => {}
    },
    endDate: {
        type: FormFieldType.DATE,
        element: {
            id: "endDate",
            required: true,
            validate: formInputValidate,
            regex: DateRegex,
        },
        label: "End Date",
        value: undefined,
        setValue: () => {}
    },
    endTime: {
        type: FormFieldType.INPUT,
        inputFieldType: InputFieldType.TIME,
        element: {
            id: "endTime",
            required: true,
            validate: formInputValidate,
            regex: GenericInputRegex,
        },
        label: "End Time",
        value: undefined,
        setValue: () => {}
    },
}

export default CommunityEventForm;
