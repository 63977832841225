import * as React from 'react';
import { FormEvent, useEffect, useState } from 'react';
import {
    BootstrapValidateClass,
    DateRegex,
    DayOfMonthRegex,
    DollarAmountRegex,
    FormElementValidation,
    formInputValidate,
    GenericInputRegex,
    GenericSelectRegex,
    USAccountNameRegex,
    USBankAccountNumberRegex,
    USBankRoutingNumberRegex
} from "../../../types";
import Input from "../../Form/Input";
import { useHistory, useParams } from "react-router";
import Select from "../../Form/Select";
import { addAutopay } from "../../../Api/AutopayApi";
import moment from 'moment';

type CreateAutopayParam = {
    done: Function
}

const CreateAutopay: React.FunctionComponent<CreateAutopayParam> = (p) => {

    const { leaseId } = useParams();

    const [verfiyAccountNumber, setVerifyAccountNumber] = useState("");
    const [accountNumbersMatch, setAccountNumbersMatch] = useState(BootstrapValidateClass.unvalidated)

    const [isValid, setIsValid] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [stopDate, setStopDate] = useState("");
    const [amount, setAmount] = useState("");
    const [dayOfMonth, setDayOfMonth] = useState("");
    const [accountHolderName, setAccountHolderName] = useState("");
    const [accountHolderType, setAccountHolderType] = useState("");
    const [routingNumber, setRoutingNumber] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [doRedirectToLease, setDoRedirectToLease] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (doRedirectToLease) {
            history.push({
                pathname: "/p/m/customers/leases/" + leaseId
            });
        }
    }, [doRedirectToLease])

    type Form = {
        startDate: FormElementValidation,
        stopDate: FormElementValidation,
        amount: FormElementValidation,
        dayOfMonth: FormElementValidation,
        accountHolderName: FormElementValidation,
        accountHolderType: FormElementValidation,
        routingNumber: FormElementValidation,
        accountNumber: FormElementValidation,
    }

    const form: Form = {
        startDate: {
            id: "startDate",
            required: true,
            validate: formInputValidate,
            regex: DateRegex
        },
        stopDate: {
            id: "stopDate",
            required: true,
            validate: formInputValidate,
            regex: DateRegex
        },
        amount: {
            id: "amount",
            required: true,
            validate: formInputValidate,
            regex: DollarAmountRegex
        },
        dayOfMonth: {
            id: "dayOfMonth",
            required: true,
            validate: formInputValidate,
            regex: DayOfMonthRegex
        },
        accountHolderName: {
            id: "accountHolderName",
            required: true,
            validate: formInputValidate,
            regex: USAccountNameRegex
        },
        accountHolderType: {
            id: "accountHolderType",
            required: true,
            validate: formInputValidate,
            regex: GenericSelectRegex
        },
        routingNumber: {
            id: "routingNumber",
            required: true,
            validate: formInputValidate,
            regex: USBankRoutingNumberRegex
        },
        accountNumber: {
            id: "accountNumber",
            required: true,
            validate: formInputValidate,
            regex: USBankAccountNumberRegex
        }
    }

    const clear = () => {
        setStartDate("");
        setStopDate("");
        setAmount("");
        setDayOfMonth("");
        setAccountHolderName("");
        setAccountHolderType("");
        setRoutingNumber("");
        setAccountNumber("");
        setVerifyAccountNumber("");
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (isValid) {
            addAutopay(leaseId, {
                startDate: startDate,
                stopDate: stopDate,
                dayOfMonth: parseInt(dayOfMonth),
                amount: parseFloat(amount),
                tenantBankAccount: {
                    accountNumber: accountNumber,
                    accountType: accountHolderType,
                    routingNumber: routingNumber,
                    accountHolderName: accountHolderName
                },
            }).then((ap) => {
                if (ap) {
                    clear();
                    p.done();
                }
            }).catch((e) => console.error(e));
        }
    }

    useEffect(() => {
        const isValidStartDate = moment(startDate).isBefore(moment(stopDate));
        const isValidStopDate = moment(stopDate).isAfter(moment(startDate));
        const isValidDayOfMonth = parseFloat(dayOfMonth) >= 1 && parseFloat(dayOfMonth) <= 28
        const isValidAmount = parseFloat(amount) > 0;
        const isAccountHolderNameValid = accountHolderName !== ''
        const isRoutingNumberValid = routingNumber !== ''
        const isAccountNumberValid = accountNumber !== ''
        const isAccountNumberMatch = verfiyAccountNumber !== ''
        const isAccountTypeValid = accountHolderType !== ''
        const confirmMatch = accountNumbersMatch === BootstrapValidateClass.isvalid

        setIsValid(isValidStartDate && isValidStopDate && isValidDayOfMonth && isValidAmount
            && isAccountHolderNameValid && isRoutingNumberValid && isAccountNumberValid
            && isAccountNumberMatch && isAccountTypeValid && confirmMatch);

    }, [startDate, stopDate, dayOfMonth, amount, accountHolderName, routingNumber, accountNumber, verfiyAccountNumber, accountHolderType, accountNumbersMatch])

    useEffect(() => {
        if (accountNumber && verfiyAccountNumber && accountNumber === verfiyAccountNumber) {
            setAccountNumbersMatch(BootstrapValidateClass.isvalid);
        } else if (!verfiyAccountNumber) {
            setAccountNumbersMatch(BootstrapValidateClass.unvalidated)
        } else {
            setAccountNumbersMatch(BootstrapValidateClass.isinvalid);
        }
    }, [accountNumber, verfiyAccountNumber])

    return (<>
        <form onSubmit={onSubmit}>
            <div className={"row"}>
                <div className={"col-md-6"}>
                    <div className="row">
                        <div className="col-md-12">
                            <h3>Add Autopay Method</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-lg-12 mb-3"}>
                            <div className="row">
                                <div className={"col-md-6 mb-3"}>

                                    <Input id={form.startDate.id} validation={form.startDate.regex.expression}
                                        doValidate={form.startDate.validate}
                                        value={startDate}
                                        setValue={setStartDate}
                                        required={form.startDate.required}
                                        invalidFeedbackText={form.startDate.regex.helpText}
                                        type={"date"} label={"Start Date"}
                                    />
                                </div>
                                <div className={"col-md-6 mb-3"}>

                                    <Input id={form.stopDate.id} validation={form.stopDate.regex.expression}
                                        doValidate={form.stopDate.validate}
                                        value={stopDate}
                                        setValue={setStopDate}
                                        required={form.stopDate.required}
                                        invalidFeedbackText={form.stopDate.regex.helpText}
                                        type={"date"} label={"Stop Date"}
                                    />
                                </div>
                                <div className={"col-md-4 mb-3"}>

                                    <Input id={form.amount.id} validation={form.amount.regex.expression}
                                        doValidate={form.amount.validate}
                                        value={amount}
                                        setValue={setAmount}
                                        required={form.amount.required}
                                        invalidFeedbackText={form.amount.regex.helpText}
                                        type={"number"} label={"Amount"}
                                    />
                                </div>
                                <div className={"col-md-2 mb-3"}>

                                    <Input id={form.dayOfMonth.id} validation={form.dayOfMonth.regex.expression}
                                        doValidate={form.dayOfMonth.validate}
                                        value={dayOfMonth}
                                        setValue={setDayOfMonth}
                                        required={form.dayOfMonth.required}
                                        invalidFeedbackText={form.dayOfMonth.regex.helpText}
                                        type={"number"} label={"Day of Month"}
                                    />
                                </div>
                                <div className={"col-md-6 mb-3"}>

                                    <Input id={form.accountHolderName.id}
                                        validation={form.accountHolderName.regex.expression}
                                        doValidate={form.accountHolderName.validate}
                                        value={accountHolderName}
                                        setValue={setAccountHolderName}
                                        required={form.accountHolderName.required}
                                        invalidFeedbackText={form.accountHolderName.regex.helpText}
                                        type={"text"} label={"Account Holder Name"}
                                    />
                                </div>
                                <div className={"col-md-6 mb-3"}>

                                    <Select id={form.accountHolderType.id}
                                        validation={form.accountHolderType.regex.expression}
                                        doValidate={form.accountHolderType.validate} value={accountHolderType}
                                        setValue={setAccountHolderType}
                                        required={form.accountHolderType.required}
                                        invalidFeedbackText={form.accountHolderType.regex.helpText}
                                        label={"Account Type"}>
                                        <option disabled={true} value={""}>Select a type...</option>
                                        <option value={"personal_checking"}>Personal Checking</option>
                                        <option value={"personal_savings"}>Personal Savings</option>
                                        <option value={"business_checking"}>Business Checking</option>
                                        <option value={"business_savings"}>Business Savings</option>
                                    </Select>
                                </div>
                                <div className={"col-md-6 mb-3"}>

                                    <Input id={form.routingNumber.id} validation={form.routingNumber.regex.expression}
                                        doValidate={form.routingNumber.validate}
                                        value={routingNumber}
                                        setValue={setRoutingNumber}
                                        required={form.routingNumber.required}
                                        invalidFeedbackText={form.routingNumber.regex.helpText}
                                        type={"text"} label={"Routing Number"}
                                    />
                                </div>
                                <div className={"col-md-6 mb-3"}>

                                    <Input id={form.accountNumber.id} validation={form.accountNumber.regex.expression}
                                        doValidate={form.accountNumber.validate}
                                        value={accountNumber}
                                        setValue={setAccountNumber}
                                        required={form.accountNumber.required}
                                        invalidFeedbackText={form.accountNumber.regex.helpText}
                                        type={"text"} label={"Account Number"}
                                    />
                                </div>
                                <div className={"col-md-6 mb-3"}>
                                    <label htmlFor="verifyAccountNumber">
                                        <span>Confirm Account Number</span>
                                        <span style={{ paddingLeft: 5, color: "var(--bs-danger)" }}>*</span>
                                    </label>
                                    <input id="verifyAccountNumber"
                                        className={"form-control " + accountNumbersMatch}
                                        required={true}
                                        type={"text"}
                                        onChange={e => {
                                            let value = e.target.value;
                                            setVerifyAccountNumber(value);
                                        }}
                                        value={verfiyAccountNumber}>

                                    </input>
                                    <div className={"invalid-feedback"}>
                                        {"Account numbers do not match"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-start gap-2 mt-3">
                        <button type={"submit"} className={"btn btn-danger"}
                            disabled={loading}
                            onClick={(e) => {
                                p.done();
                            }}>
                            Cancel
                        </button>
                        <button type={"submit"} className={"btn btn-primary"}
                            disabled={loading}>
                            Save
                        </button>
                    </div>
                </div>
            </div>

        </form>
    </>);
};

export default CreateAutopay;
