import {fetchWithAuth, HttpMethod, serializeFilter} from "../api";
import {getPortfolioId} from "../helpers";

export interface TenantPackage {
    uuid: string,
    tenantAccountId: string,
    addressedToName: string,
    description: string,
    dateReceived: string,
    datePickedUp: string,
    pickupPersonName: string,
    pickupInstructions: string,
    status: TenantPackageStatus,
}

export interface CreateTenantPackage {
    tenantAccountId: string,
    addressedToName: string,
    description: string,
    dateReceived: string,
    pickupInstructions: string
}

export interface UpdateTenantPackage {
    uuid: string,
    tenantAccountId: string,
    addressedToName: string,
    description: string,
    dateReceived: string,
    datePickedUp: string,
    pickupPersonName: string,
    pickupInstructions: string,
    status: TenantPackageStatus,
}

export interface TenantPackageFilter {
    accountId?: string,
    status?: TenantPackageStatus,
    dateReceived?: string,
}

export enum TenantPackageStatus {
    RECEIVED="RECEIVED", PICKED_UP="PICKED_UP", UNKNOWN=""
}

type getTenantPackages = (propertyId: string, filter: TenantPackageFilter) => Promise<TenantPackage[]>;
type getTenantPackageById = (propertyId: string, tenantPackageId: string) => Promise<TenantPackage>;
type createTenantPackage = (propertyId: string, tenantPackage: CreateTenantPackage) => Promise<TenantPackage>;
type updateTenantPackage = (propertyId: string, tenantPackage: UpdateTenantPackage) => Promise<TenantPackage>;
type deleteTenantPackage = (propertyId: string, tenantPackageId: string) => Promise<void>;

const PACKAGE_MANAGEMENT_API_BASE_URL = (propertyId: string, pathExtension = "/") => {return "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/packages" + pathExtension}

export const getTenantPackages: getTenantPackages = (async (propertyId, filter) => {
    return fetchWithAuth(PACKAGE_MANAGEMENT_API_BASE_URL(propertyId, "?" + serializeFilter(filter)), HttpMethod.GET);
});

export const getTenantPackageById: getTenantPackageById = (async (propertyId, tenantPackageId) => {
    return fetchWithAuth(PACKAGE_MANAGEMENT_API_BASE_URL(propertyId, "/" + tenantPackageId), HttpMethod.GET);
});

export const createTenantPackage: createTenantPackage = (async (propertyId, tenantPackage) => {
    console.log(JSON.stringify(tenantPackage))
    return fetchWithAuth(PACKAGE_MANAGEMENT_API_BASE_URL(propertyId), HttpMethod.POST, JSON.stringify(tenantPackage));
});

export const updateTenantPackage: updateTenantPackage = (async (propertyId, tenantPackage) => {
    return fetchWithAuth(PACKAGE_MANAGEMENT_API_BASE_URL(propertyId, "/" + tenantPackage.uuid), HttpMethod.PUT, JSON.stringify(tenantPackage));
});
