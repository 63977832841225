import * as React from 'react';
import {useEffect, useState} from 'react';
import {BootstrapValidateClass, ValidateElementFunction} from "../../../types";
import moment from "moment";

type InputProps = {
    id: string,
    validation: RegExp,
    doValidate: ValidateElementFunction,
    value: string,
    setValue: Function,
    required: boolean,
    min?: Date
    invalidFeedbackText: string,
    label: string,
    placeholder?: string,
    disabled?: boolean
}

const DateInput: React.FunctionComponent<InputProps> = (p) => {

    const [validateClass, setValidateClass] = useState(BootstrapValidateClass.unvalidated);
    const {value} = p;

    useEffect(() => {
        if (value.length === 0 || p.disabled) {
            setValidateClass(BootstrapValidateClass.unvalidated);
            return;
        }
        if (p.doValidate(value, p.required, p.validation)) {
            setValidateClass(BootstrapValidateClass.isvalid);
        } else {
            setValidateClass(BootstrapValidateClass.isinvalid);
        }

    }, [value, p]);

    const getLabelComponent = () => {
        if(p.label) {
            return <label htmlFor={p.id}>{p.label}</label>
        }
    }

    return (<>
        {getLabelComponent()}
        <input type={"date"} className={"form-control " + validateClass}
               id={p.id} disabled={p.disabled}
               min={moment(p.min, "YYYY-MM-DD").format("YYYY-MM-DD")}
               placeholder={p.placeholder}
               required={p.required} value={p.value} onChange={e => {
            let value = e.target.value;
            p.setValue(value);
        }}/>
        <div className="invalid-feedback">
            {p.invalidFeedbackText}
        </div>
    </>);
};

export default DateInput;