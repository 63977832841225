import * as React from 'react';
import {useCallback, useEffect, useState} from "react";
import moment from 'moment';
import Table from "../../../Table";
import {Autopay} from "../../../../types";
import {applyAutopayTransactions, deleteAutopay, getAutopay} from "../../../../Api/AutopayApi";
import {useParams} from "react-router";
import CreateAutopay from "../../CreateAutopay";
import Card from "../../../Card";

type LeaseAutopayParam = {
    currentView: string,
    done: Function
}

const LeaseAutopay: React.FunctionComponent<LeaseAutopayParam> = (p) => {

    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    const {leaseId} = useParams();

    const [autopays, setAutopays] = useState<Autopay[]>([]);
    const [autopayLoading, setAutopayLoading] = useState(false);
    const [showAddAutopay, setShowAddAutopay] = useState(false);

    const doDelete = (autopayId: string) => {
        deleteAutopay(leaseId, autopayId)
            .then((r) => {
                if (r.ok) {
                    load();
                }
            })
            .catch((e) => console.error(e))
    }

    const load = useCallback(() => {
        setAutopayLoading(true);
        if (leaseId) {
            getAutopay(leaseId)
                .then((ap) => {
                    setAutopays(ap);
                }).catch(e => {
                console.log(e);
            })
                .finally(() => setAutopayLoading(false))
        }
    }, [leaseId])

    useEffect(() => {
        if (p.currentView === "autopay") {
            load();
        }
    }, [p.currentView, load]);

    const doApplyAutopayTransactions = () => {
        applyAutopayTransactions(leaseId)
            .then((r) => {
                if (r.ok) {
                    p.done();
                }
            })
            .catch((e) => console.error(e))
    }


    return (<>
        <Card title="Autopay">
            {showAddAutopay ?
                <CreateAutopay
                    done={() => {
                        load();
                        setShowAddAutopay(false);
                    }}/>
                :
                <div className="d-flex justify-content-between gap-2">
                    <button type={"button"} className={"btn btn-primary"}
                            onClick={() => {
                                setShowAddAutopay(true);
                            }}
                    >Add Autopay Method
                    </button>
                    <button type={"button"} className={"btn btn-warning"}
                            onClick={() => {
                                doApplyAutopayTransactions();
                            }}
                    >Apply Transactions
                    </button>
                </div>
            }
            <hr />
            <Table headers={["Payment Method", "Amount", "Start Date", "Stop Date", "Day of Month", ""]}
                   defaultPaginationSize={10}>
                {autopays.map((a) => (
                    <tr>
                        <td>{"**" + a.tenantBankAccount.lastFour}</td>
                        <td>{currencyFormat.format(a.amount)}</td>
                        <td>{moment(a.startDate, "YYYY-MM-DD").format("MMMM Do YYYY")}</td>
                        <td>{moment(a.stopDate, "YYYY-MM-DD").format("MMMM Do YYYY")}</td>
                        <td>{a.dayOfMonth}</td>
                        <td>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                 fill="currentColor" className="bi bi-x-circle" viewBox="0 0 16 16"
                                 onClick={() => {
                                     doDelete(a.uuid);
                                 }}>
                                <path
                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                <path
                                    d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                            </svg>

                        </td>
                    </tr>
                ))}
            </Table>
        </Card>
    </>);
};

export default LeaseAutopay;
