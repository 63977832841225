import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import {TenantPackage, TenantPackageStatus, updateTenantPackage} from "../../../../Api/PackageManangementApi";
import {useParams} from "react-router";
import {ARAccount} from "../../../../types";
import {getARAccounts} from "../../../../Api/AccountApi";
import EditTenantPackageForm from "../../Forms/EditTenantPackageForm";
import LoadingOverlay from "../../../LoadingOverlay";
import FormFieldBuilder, {SelectOptions} from "../../../Form/FieldBuilder/FormFieldBuilder";

const EditTenantPackage: React.FunctionComponent<{tenantPackage: TenantPackage, done: Function}> = ({tenantPackage, done}) => {

    const { propertyId } = useParams();

    const [loading, setLoading] = useState<boolean>(true);

    const [tenantAccountId, setTenantAccountId] = useState<string>("");
    const [addressedToName, setAddressedToName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [dateReceived, setDateReceived] = useState<string>("");
    const [datePickedUp, setDatePickedUp] = useState<string>("");
    const [pickupPersonName, setPickupPersonName] = useState<string>("");
    const [pickupInstructions, setPickupInstructions] = useState<string>("");
    const [status, setStatus] = useState<TenantPackageStatus>(TenantPackageStatus.UNKNOWN);

    EditTenantPackageForm.tenantAccountId.value = tenantAccountId;
    EditTenantPackageForm.tenantAccountId.setValue = setTenantAccountId;
    EditTenantPackageForm.addressedToName.value = addressedToName;
    EditTenantPackageForm.addressedToName.setValue = setAddressedToName;
    EditTenantPackageForm.description.value = description;
    EditTenantPackageForm.description.setValue = setDescription;
    EditTenantPackageForm.dateReceived.value = dateReceived;
    EditTenantPackageForm.dateReceived.setValue = setDateReceived;
    EditTenantPackageForm.datePickedUp.value = datePickedUp;
    EditTenantPackageForm.datePickedUp.setValue = setDatePickedUp;
    EditTenantPackageForm.pickupPersonName.value = pickupPersonName;
    EditTenantPackageForm.pickupPersonName.setValue = setPickupPersonName;
    EditTenantPackageForm.pickupInstructions.value = pickupInstructions;
    EditTenantPackageForm.pickupInstructions.setValue = setPickupInstructions;
    EditTenantPackageForm.status.value = status;
    EditTenantPackageForm.status.setValue = setStatus;


    const [selectOptions, setSelectOption] = useState<SelectOptions[]>([]);

    const setFields = () => {
        if (tenantPackage) {
            setTenantAccountId(tenantPackage.tenantAccountId);
            setAddressedToName(tenantPackage.addressedToName);
            setDescription(tenantPackage.description);
            setDateReceived(tenantPackage.dateReceived.split(' ')[0]);
            if (tenantPackage.datePickedUp) {
                setDatePickedUp(tenantPackage.datePickedUp.split(' ')[0]);
            }
            setPickupPersonName(tenantPackage.pickupPersonName);
            setPickupInstructions(tenantPackage.pickupInstructions);
            setStatus(tenantPackage.status);
        }
    }

    useEffect(() => {
        setLoading(true);
        setFields();
        getARAccounts(propertyId)
            .then(updateAccountSelectOptions)
            .catch(console.error)
            .finally(() => setLoading(false));
    }, [propertyId])

    const updateAccountSelectOptions = (accounts: ARAccount[]) => {
        const options: SelectOptions[] = JSON.parse(JSON.stringify(EditTenantPackageForm.tenantAccountId.defaultSelectOptions));
        accounts.forEach(account => {
            options.push({ value: account.uuid, label: account.unitName })
        });
        setSelectOption(options);
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (tenantPackage) {
            updateTenantPackage(propertyId, {
                uuid: tenantPackage.uuid,
                tenantAccountId: tenantAccountId,
                addressedToName: addressedToName,
                description: description,
                dateReceived: dateReceived + " 00:00:00",
                datePickedUp: datePickedUp + " 00:00:00",
                pickupPersonName: pickupPersonName,
                pickupInstructions: pickupInstructions,
                status: status
            })
                .then(() => done())
                .catch(console.error)
        }
    }

    const onReset = (e: FormEvent) => {
        e.preventDefault();
        setFields();
    }

    return (loading ? <LoadingOverlay show={loading} /> : <>
        <div className="row">
            <div className="col-md-12">
                <form onSubmit={onSubmit} onReset={onReset}>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <FormFieldBuilder field={EditTenantPackageForm.tenantAccountId} selectOptions={selectOptions}/>
                        </div>
                        <div className="col-md-6 mb-3">
                            <FormFieldBuilder field={EditTenantPackageForm.dateReceived} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <FormFieldBuilder field={EditTenantPackageForm.addressedToName} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <FormFieldBuilder field={EditTenantPackageForm.description} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                            <FormFieldBuilder field={EditTenantPackageForm.pickupInstructions} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <FormFieldBuilder field={EditTenantPackageForm.pickupPersonName} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <FormFieldBuilder field={EditTenantPackageForm.datePickedUp} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <FormFieldBuilder field={EditTenantPackageForm.status} selectOptions={EditTenantPackageForm.status.defaultSelectOptions}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="btn btn-group" role="btn-group">
                                <button type="reset" className="btn btn-outline-primary mb-3">Reset</button>
                                <button type="submit" className="btn btn-primary mb-3">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>)
}

export default EditTenantPackage;
