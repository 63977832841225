import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation, useRouteMatch} from "react-router";
import {Link} from "react-router-dom";
import {
    APP_PORTFOLIO_LANDING,
    formSelectValidate,
    GenericSelectRegex,
    MenuItem,
    Portfolio,
    Property
} from "../../types";
import {getMenuItems} from "../../Api/MenuApi";
import ReactTooltip from "react-tooltip";
import {getPortfolios} from "../../Api/PortfolioApi";
import {getPropertiesByPortfolioId} from "../../Api/PropertyApi";
import FormFieldBuilder, {
    assignFormFieldValues,
    deepCopySelectOptions,
    SelectOptions
} from "../Form/FieldBuilder/FormFieldBuilder";
import NavigationForm from "./Forms/NavigationForm";
import Card from "../Card";
import {DocsIcon} from "../Icons/Icons";
import {PortfolioIdContext, PropertyIdContext} from "../../Containers/PrivateMainContainer";
import AutocompleteInput, {DataAttribute} from "../Form/AutocompleteInput";

type NavigationParams = {
    setPortfolioId: Function,
    setPropertyId: Function,
}
const Navigation: React.FunctionComponent<NavigationParams> = (p) => {

    const portfolioId = useContext(PortfolioIdContext)
    const propertyId = useContext(PropertyIdContext)

    const [previousPropertyId, setPreviousPropertyId] = useState("");
    const location = useLocation();
    const [error, setError] = useState<Error>()
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [properties, setProperties] = useState<Property[]>();
    const [portfolios, setPortfolios] = useState<Portfolio[]>();
    const [doCreatePortfolioRedirect, setDoCreatePortfolioRedirect] = useState(false);
    const history = useHistory();
    const allowedMenuItemsWhenNoPropertySelected = ["Dashboard", "Real Estate", "Properties", "Property Groups",
        "Reports", "Payouts", "Payments", "Settings", "Subscriptions", "Create Portfolio", "Users",
        "Accounting","Deposit Register","Receivables","Trust Account Balances",
    "Cash Flow Property Comparison","Reconcile"]

    const [portfolioOptions, setPortfolioOptions] = useState<SelectOptions[]>([])
    const [propertyAttributeLists, setPropertyAttributeLists] = useState<DataAttribute[]>()

    useEffect(() => {
    }, [propertyId]);

    const setPropertyId = (v: any) => {
        p.setPropertyId(v)
    }

    const setPortfolioId = (v: any) => {
        p.setPortfolioId(v)
    }

    assignFormFieldValues({field: NavigationForm.portfolioId, value: portfolioId, setValue: setPortfolioId})

    useEffect(() => {
        if (doCreatePortfolioRedirect) {
            history.push({
                pathname: "/p/s/create-portfolio"
            })
        }
    }, [doCreatePortfolioRedirect, history]);

    useEffect(() => {
        if (portfolioId) {
            getPropertiesByPortfolioId(portfolioId)
                .then(setProperties)
                .catch(setError)
        }
    }, [portfolioId]);

    useEffect(() => {
        Promise.all([
            getPortfolios(),
            getMenuItems("default")
        ])
            .then(values => {
                setDoCreatePortfolioRedirect(values[0].length === 0)
                setPortfolios(values[0])
                setMenuItems(values[1].sort(function (a, b) {
                    return a.weight - b.weight;
                }));
            })
            .catch(setError)
    }, [])

    useEffect(() => {
        if (portfolios) {
            const options: SelectOptions[] = deepCopySelectOptions(NavigationForm.portfolioId)
            portfolios.forEach(portfolio => {
                options.push({label: portfolio.name, value: portfolio.uuid})
            })
            setPortfolioOptions(options)
        }
    }, [portfolios])

    useEffect(() => {
        if (properties) {
            // const options: SelectOptions[] = deepCopySelectOptions(NavigationForm.propertyId)
            // properties.forEach(property => {
            //     options.push({label: property.name, value: property.uuid})
            // })
            // setPropertyOptions(options)
            let da: DataAttribute[] = [];
            properties.forEach(p => {
                da.push({
                    uuid: p.uuid,
                    label: p.name
                })
            })
            setPropertyAttributeLists(da);
        }
    }, [properties])

    interface DocLinksMap {
        [key: string]: string;
    }

    const parentMenuItemLinks: DocLinksMap = {
        "documents": "/category/e-sign",
        "customers": "/category/leasing",
        "real-estate": "/category/leasing",
        "reports": "/category/reports",
        "maintenance": "/category/maintenance",
        "tenant-services": "/category/tenant-services",
        "settings": "/category/settings",
        "owners": "/category/owners",
        "dashboard": "/",
    };

    function getParentMenuItem(g: MenuItem) {
        if (propertyId || allowedMenuItemsWhenNoPropertySelected.includes(g.label)) {
            const docKey = g.label.replace(" ", "-").toLowerCase();
            const docURL = parentMenuItemLinks[docKey] || "/";

            return (Array.isArray(g.menuItems) && g.menuItems.length) ?
                <>
                    <div
                        className="d-flex justify-content-between align-items-center p-2 bg-light border-bottom border-top">
                        <p className="m-0 p-0">{g.label}</p>
                        <a href={"https://docs.propertymq.com/docs" + docURL}
                           target={"_blank"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                 className="bi bi-file-earmark-text text-black" viewBox="0 0 16 16" data-tip
                                 data-for="menuTip">
                                <path
                                    d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z"/>
                                <path
                                    d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                            </svg>
                        </a>
                        <ReactTooltip id="menuTip" place="top" effect="solid">
                            <span style={{textTransform: "none"}}>Link for documentation on this topic.</span>
                        </ReactTooltip>
                    </div>
                    <div>
                        {getSubMenuItems(g.menuItems.sort(function (a, b) {
                            return a.weight - b.weight;
                        }))}
                    </div>
                </>
                :
                <Link
                    className={"nav-link" + ((location.pathname.startsWith(g.path)) ? " active" : "")}
                    to={g.path}>
                    <span data-feather="layers"></span>
                    {g.label}
                </Link>
        }
    }

    const getParentMenuItems = () => {
        return menuItems.map((g, gindex) => (<React.Fragment key={"g_" + gindex}>
            {getParentMenuItem(g)}

        </React.Fragment>))
    }


    function getSubMenuItem(uindex: number, u: MenuItem) {
        if (propertyId || allowedMenuItemsWhenNoPropertySelected.includes(u.label)) {
            return <li key={"nav_" + uindex} className="nav-item">
                <Link
                    className={"nav-link" + ((location.pathname.startsWith(u.path)) ? " active" : "")}
                    to={u.path}>
                    <span data-feather="layers"></span>
                    {u.label}
                </Link>
            </li>;
        }
    }

    const getSubMenuItems = (mz: MenuItem[]) => {
        return (mz.map((u, uindex) => getSubMenuItem(uindex, u)))
    }

    const [property, setProperty] = useState<Property>()
    const [showPaymentAccountStatus, setShowPaymentAccountStatus] = useState<boolean>(false)

    useEffect(() => {
        if (propertyId) {
            if (previousPropertyId && previousPropertyId !== propertyId) {
                window.location.href = "/p/m/dashboard";
            }
            setPreviousPropertyId(propertyId);
        }
        setProperty(properties?.find(pr => pr.uuid === propertyId))
    }, [propertyId, properties, previousPropertyId])

    useEffect(() => {
        if (property) {
            setShowPaymentAccountStatus(!(property.achAccountVerified && property.ccAccountVerified))
        }
    }, [property])


    return (
        <div id="sidebarMenu"
             className="col-md-3 col-lg-2 d-md-block bg-white sidebar collapse shadow overflow-auto pb-5">
            <div className="p-3 border-bottom bg-light shadow-sm">
                <div className="mb-2">
                    {portfolios &&
                        <FormFieldBuilder field={NavigationForm.portfolioId} selectOptions={portfolioOptions}/>}
                </div>
                {propertyAttributeLists &&
                    <AutocompleteInput id={"propertyId"} validation={GenericSelectRegex.expression}
                                       doValidate={formSelectValidate} value={propertyId}
                                       setValue={setPropertyId} required={false}
                                       invalidFeedbackText={GenericSelectRegex.helpText}
                                       placeholder={"Search by property name"}
                                       type={"text"} label={"Property"} data={propertyAttributeLists}/>}

                {properties && properties.length === 0 &&
                    <Link to="/p/m/realestate/properties/add">
                        <button type={"button"} className="btn btn-primary w-100 mt-3">
                            Add Property
                        </button>
                    </Link>
                }

            </div>
            <nav className="nav flex-column">
                {getParentMenuItems()}
            </nav>
        </div>


    );
};

export default Navigation;
