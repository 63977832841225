import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import FormFieldBuilder, {SelectOptions} from "../../../Form/FieldBuilder/FormFieldBuilder";
import SearchAmenityReservationForm from "../../Forms/AmenityReservationSearchForm";
import Table from "../../../Table";
import {getTicketIdFromUuid} from "../../../../helpers";
import {ARAccount} from "../../../../types";
import moment from "moment";
import {AmenityReservation, AmenityReservationStatus} from "../../../../Api/AmenityApi";
import {simpleTimeToString} from "../../Service/AmenityService";

const SearchAmenityReservations: React.FunctionComponent<{ reservations: AmenityReservation[], accounts: ARAccount[], selectOptions: SelectOptions[] }> = ({reservations, accounts, selectOptions}) => {

    const [filteredReservations, setFilteredReservations] = useState<AmenityReservation[]>([]);

    const [searchAccount, setSearchAccount] = useState<string>("");
    const [searchDate, setSearchDate] = useState<string>("");
    const [searchStatus, setSearchStatus] = useState<AmenityReservationStatus>(AmenityReservationStatus.UNKNOWN);

    SearchAmenityReservationForm.accountId.value = searchAccount;
    SearchAmenityReservationForm.accountId.setValue = setSearchAccount;
    SearchAmenityReservationForm.date.value = searchDate
    SearchAmenityReservationForm.date.setValue = setSearchDate;
    SearchAmenityReservationForm.status.value = searchStatus;
    SearchAmenityReservationForm.status.setValue = setSearchStatus;

    const parseStatus = (status: AmenityReservationStatus) => {
        switch (status) {
            case AmenityReservationStatus.RESERVED: return "Reserved";
            case AmenityReservationStatus.IN_PROGRESS: return "In Progress";
            case AmenityReservationStatus.COMPLETED: return "Completed";
        }
    }

    const onSubmit = (e: FormEvent | undefined) => {
        e?.preventDefault();
        let filteredReservations = reservations.filter(() => { return true });
        if (searchAccount.length > 0) {
            filteredReservations = filteredReservations.filter(reservation => { return reservation.accountId === searchAccount });
        }
        if (searchDate.length > 0) {
            filteredReservations = filteredReservations.filter(reservation => { return reservation.date === searchDate });
        }
        if (searchStatus !== AmenityReservationStatus.UNKNOWN) {
            filteredReservations = filteredReservations.filter(reservation => { return reservation.status === searchStatus });
        }
        setFilteredReservations(filteredReservations);
    }

    useEffect(() => {
        onSubmit(undefined);
    }, [reservations.length]);

    const onReset = (e: FormEvent) => {
        e.preventDefault();
        setSearchAccount("")
        setSearchDate("")
        setSearchStatus(AmenityReservationStatus.UNKNOWN)
    }

    return (<>
        <div className="row">
            <div className="col-md-4 mb-3 bg-light">
                <div className="row">
                    <div className="col-md-12 mt-2 mb-2">
                        <h5>Search</h5>
                    </div>
                </div>
                <form onSubmit={onSubmit} onReset={onReset}>
                    <div className="row">
                        <div className="col-md-12 mb-2">
                            <FormFieldBuilder field={SearchAmenityReservationForm.accountId} selectOptions={selectOptions} />
                        </div>
                        <div className="col-xxl-6 mb-2">
                            <FormFieldBuilder field={SearchAmenityReservationForm.date} />
                        </div>
                        <div className="col-xxl-6 mb-2">
                            <FormFieldBuilder field={SearchAmenityReservationForm.status} selectOptions={SearchAmenityReservationForm.status.defaultSelectOptions}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="btn-group mt-3 mb-3" role="btn-group">
                                <button type="reset" className="btn btn-outline-primary">Clear</button>
                                <button type="submit" className="btn btn-primary">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div className="col-md-8 mb-3">
                <Table headers={[
                    "Reservation Id",
                    "Unit",
                    "Reservation Date",
                    "Start Time",
                    "End Time",
                    "Status"
                ]} defaultPaginationSize={10}>
                    {filteredReservations.map(reservation => (
                        <tr key={reservation.uuid}>
                            <td>{getTicketIdFromUuid(reservation.uuid)}</td>
                            <td>{accounts?.find((account: ARAccount) => { return account.uuid === reservation.accountId })?.unitName}</td>
                            <td>{moment(reservation.date).format("MMM Do, YYYY")}</td>
                            <td>{simpleTimeToString(reservation.startTime)}</td>
                            <td>{simpleTimeToString(reservation.endTime)}</td>
                            <td><span className={
                                reservation.status === AmenityReservationStatus.RESERVED
                                    ? "badge rounded-pill bg-primary"
                                    : reservation.status === AmenityReservationStatus.IN_PROGRESS
                                        ? "badge rounded-pill bg-info"
                                        : "badge rounded-pill bg-success"
                            }>{parseStatus(reservation.status)}</span></td>
                        </tr>
                    ))}
                </Table>
            </div>
        </div>
    </>)
}

export default SearchAmenityReservations;
