import {LeaseUser} from "../types";
import {getPortfolioId} from "../helpers";
import {fetchWithAuth, HttpMethod, serializeFilter} from "../api";

export interface JourneyLease {
    uuid?: string
    propertyId: string
    quoteId?: string
    tenantApplicationId?: string
    leaseId?: string
    journeyUser: LeaseUser
    step?: JourneyLeaseStep
    notes: string
    followUpDate?: string
    closed?: boolean
}



export enum JourneyLeaseStep {
    ONE="ONE",
    TWO="TWO",
    THREE="THREE",
    FOUR="FOUR",
    FIVE="FIVE",
    //SIX="SIX"
}

export interface JourneyStep {
    id: JourneyLeaseStep,
    label: string,
    bgColor: string,
    actionItem?: ActionItem,
}

export interface ActionItem {
    label: string
    path: Function
}

export const journeySteps: JourneyStep[] = [
    {
        id: JourneyLeaseStep.ONE,
        label: "Lead Created",
        bgColor: "primary",
        actionItem: {
            label: "Start Application",
            path: (propertyId: string, journeyLeaseId: string) => "/p/m/customers/applications/" + propertyId + "/add/1/" + journeyLeaseId
        }
    },
    {
        id: JourneyLeaseStep.TWO,
        label: "Quote Generated",
        bgColor: "secondary",
        actionItem: {
            label: "Start Application",
            path: (propertyId: string, journeyLeaseId: string) => "/p/m/customers/applications/" + propertyId + "/add/1/" + journeyLeaseId
        }
    },
    {
        id: JourneyLeaseStep.THREE,
        label: "Application Started",
        bgColor: "danger",
        actionItem: {
            label: "View Application",
            path: (propertyId: string, applicationId: string) => "/p/m/customers/applications/" + propertyId + "/view/" + applicationId
        },
    },
    {
        id: JourneyLeaseStep.FOUR,
        label: "Application Submitted",
        bgColor: "warning",
        actionItem: {
            label: "Review Application",
            path: (propertyId: string, applicationId: string) => "/p/m/customers/applications/" + propertyId + "/view/" + applicationId
        },
    },
    {
        id: JourneyLeaseStep.FIVE,
        label: "Application Approved",
        bgColor: "info",
        actionItem: {
            label: "View Lease",
            path: (propertyId: string, leaseId: string) => "/p/m/customers/leases/" + propertyId + "/view/" + leaseId
        }
    },
    /*
    {
        id: JourneyLeaseStep.SIX,
        label: "Lease Signed",
        bgColor: "success",
        actionItem: {
            label: "View Lease",
            path: (leaseId: string) => "/p/m/customers/leases/" + leaseId
        }
    }
     */
]

type GetJourneyLeases = (propertyId: string, closed: boolean) => Promise<JourneyLease[]>
type GetJourneyLease = (propertyId: string, journeyId: string) => Promise<JourneyLease>
type PostJourneyLease = (journeyLease: JourneyLease) => Promise<JourneyLease>
type PatchJourneyLease = (journeyLease: JourneyLease) => Promise<JourneyLease>
//type DeleteJourneyLease = (propertyId: string, journeyId: string) => Promise<void>

const JOURNEY_API_URL = (propertyId: string, pathExtension = "") => {
    return "/portfolios/" + getPortfolioId() + "/properties/" + propertyId + "/journeys" + pathExtension;
}

export const getJourneyLeases: GetJourneyLeases = (async (propertyId, closed) => {
    return fetchWithAuth(JOURNEY_API_URL(propertyId, "/leases?closed=" + closed.toString()), HttpMethod.GET);
})

export const getJourneyLease: GetJourneyLease = (async (propertyId, journeyId) => {
    return fetchWithAuth(JOURNEY_API_URL(propertyId, "/leases/" + journeyId), HttpMethod.GET)
})

export const createJourneyLease: PostJourneyLease = (async journeyLease => {
    return fetchWithAuth(JOURNEY_API_URL(journeyLease.propertyId, "/leases"), HttpMethod.POST, JSON.stringify(journeyLease));
})

export const updateJourneyLease: PatchJourneyLease = (async journeyLease => {
    return fetchWithAuth(JOURNEY_API_URL(journeyLease.propertyId, "/leases/" + journeyLease.uuid), HttpMethod.PATCH, JSON.stringify(journeyLease))
})

/*
export const deleteJourneyLease: DeleteJourneyLease = (async (propertyId, journeyId) => {
    return fetchWithAuth(JOURNEY_API_URL(propertyId, "/leases/" + journeyId), HttpMethod.DELETE)
})
*/