import * as React from 'react';
import {useState} from "react";

type TableProps = {
    headers: string[],
    defaultPaginationSize: number,
}

const Table: React.FunctionComponent<TableProps> = (props) => {

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(props.defaultPaginationSize);

    const rows = React.Children.toArray(props.children);
    const numberOfPages = Math.ceil(rows.length / paginationSize);


    const paginationComponent = [];
    for (let i = 0; i < numberOfPages; i++) {
        paginationComponent.push(<li key={"page-" + i}
                                     className={((i + 1) === page) ? "page-item active" : "page-item"}>
            <button type={"button"} className="page-link" onClick={() => setPage(i + 1)}>{i + 1}</button>
        </li>)
    }


    return (<>
        <div className={"table-responsive"}>
            <table className="table">
                <thead>
                <tr>
                    {
                        props.headers.map((h, index) => (
                            <th scope="col" key={"h_" + index}>{h}</th>
                        ))
                    }
                </tr>
                </thead>
                <tbody>
                {
                    rows.filter((r, rindex) => rindex >= (page - 1) * paginationSize && rindex < page * paginationSize)
                }

                </tbody>
            </table>
            <div className="d-flex justify-content-start align-items-center gap-3">
                <ul className="pagination m-0 p-0">
                    <li className={(page === 1) ? "page-item disabled" : "page-item"}>
                        <button type={"button"} className={"page-link"}
                                onClick={() => (page > 1) ? setPage(page - 1) : page}>Previous
                        </button>
                    </li>
                    {paginationComponent}
                    <li className={(page === numberOfPages) ? "page-item disabled" : "page-item"}>
                        <button type={"button"} className="page-link"
                                onClick={() => (page < numberOfPages) ? setPage(page + 1) : page}>Next
                        </button>
                    </li>
                </ul>
                <div>
                    <select className={"form-select"}
                            value={paginationSize}
                            onChange={e => {
                                let value = e.target.value;
                                setPaginationSize(parseInt(value));
                                setPage(1);
                            }}>
                        <option value={2}>2</option>
                        <option value={10}>10</option>
                        <option value={50}>50</option>
                    </select>
                </div>
            </div>
        </div>
    </>);
};

export default Table;