import React, {FormEvent, Fragment, useContext, useState} from "react";
import PageLayout from "../../../Layouts/PageLayout";
import {getPropertyGeneralLedger} from "../../../Api/PropertyApi";
import {PropertyIdContext} from "../../../Containers/PrivateMainContainer";
import Input from "../../../Components/Form/Input";
import {DateRegex, formInputValidate, PropertyGeneralLedger} from "../../../types";
import moment from "moment/moment";
import {v4 as uuidv4} from 'uuid';


const ViewPropertyGeneralLedger: React.FunctionComponent = () => {

    const currencyFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});
    const propertyId = useContext(PropertyIdContext)
    const [startDate, setStartDate] = useState(() => {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return moment(firstDay, "YYYY-MM-DD").format("YYYY-MM-DD");
    });
    const [endDate, setEndDate] = useState(() => {
        var date = new Date();
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return moment(lastDay, "YYYY-MM-DD").format("YYYY-MM-DD");
    });
    const [loading, setLoading] = useState(false);
    const [generalLedger, setGeneralLedger] = useState<PropertyGeneralLedger>()

    function load() {
        getPropertyGeneralLedger(propertyId, startDate, endDate)
            .then(gl => {
                setGeneralLedger(gl);
            })
            .catch((e) => console.error(e.message))
            .finally(() => setLoading(false));
    }

    function doSubmit(e: FormEvent) {
        e.preventDefault();
        load();
    }

    return <PageLayout title={"General Ledger"} propertyId={propertyId}>
        <form onSubmit={doSubmit}>
            <div className={"row"}>

                <div className={"col-md-3 mb-3"}>
                    <Input id={"startDate"} validation={DateRegex.expression}
                           doValidate={formInputValidate}
                           value={startDate}
                           setValue={setStartDate}
                           required={true}
                           invalidFeedbackText={DateRegex.helpText}
                           type={"date"} label={"Start Date"}
                    />
                </div>
                <div className={"col-md-3 mb-3"}>
                    <Input id={"endDate"} validation={DateRegex.expression}
                           doValidate={formInputValidate}
                           value={endDate}
                           setValue={setEndDate}
                           required={true}
                           invalidFeedbackText={DateRegex.helpText}
                           type={"date"} label={"End Date"}
                    />
                </div>
                <div className={"col-md-2 mb-3"} style={{paddingTop: 20}}>
                    <button type={"submit"} className={"btn btn-primary"}>Submit</button>
                </div>

            </div>
        </form>
        <div className={"row"}>
            <div className={"col-md-12"}>
                <table className="table table-sm">
                    <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Description</th>
                        <th scope="col">Debit</th>
                        <th scope="col">Credit</th>
                        <th scope="col">Balance</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        generalLedger?.assets.map((a, index) => (
                            <Fragment key={"assets" + index}>
                                <tr>
                                    <th scope="col" colSpan={5}>{a.code}-{a.description}</th>
                                </tr>
                                <tr>
                                    <th scope="col" colSpan={4}>Starting Balance</th>
                                    <th scope="col">{currencyFormat.format(a.startingBalance)}</th>
                                </tr>
                                {
                                    a.journalEntries.map((j, index) => (
                                        <tr key={uuidv4()}>
                                            <td scope="col">{j.date}</td>
                                            <td scope="col">{j.description}</td>
                                            <td scope="col">{currencyFormat.format(j.debit)}</td>
                                            <td scope="col">{currencyFormat.format(j.credit)}</td>
                                            <td scope="col">{currencyFormat.format(j.balance)}</td>
                                        </tr>
                                    ))
                                }
                                <tr>
                                    <th scope="col" colSpan={4}>Net Change</th>
                                    <th scope="col">{currencyFormat.format(a.netChange)}</th>
                                </tr>
                                <tr>
                                    <th scope="col" colSpan={2}></th>
                                    <th scope="col">{currencyFormat.format(a.totalDebits)}</th>
                                    <th scope="col">{currencyFormat.format(a.totalCredits)}</th>
                                    <th scope="col">{currencyFormat.format(a.endingBalance)}</th>
                                </tr>
                            </Fragment>

                        ))
                    }
                    {
                        generalLedger?.liabilities.map((a, index) => (
                            <Fragment key={"liabilities" + index}>
                                <tr>
                                    <th scope="col" colSpan={5}>{a.code}-{a.description}</th>
                                </tr>
                                <tr>
                                    <th scope="col" colSpan={4}>Starting Balance</th>
                                    <th scope="col">{currencyFormat.format(a.startingBalance)}</th>
                                </tr>
                                {
                                    a.journalEntries.map(j => (
                                        <tr key={uuidv4()}>
                                            <td scope="col">{j.date}</td>
                                            <td scope="col">{j.description}</td>
                                            <td scope="col">{currencyFormat.format(j.debit)}</td>
                                            <td scope="col">{currencyFormat.format(j.credit)}</td>
                                            <td scope="col">{currencyFormat.format(j.balance)}</td>
                                        </tr>
                                    ))
                                }
                                <tr>
                                    <th scope="col" colSpan={4}>Net Change</th>
                                    <th scope="col">{currencyFormat.format(a.netChange)}</th>
                                </tr>
                                <tr>
                                    <th scope="col" colSpan={2}></th>
                                    <th scope="col">{currencyFormat.format(a.totalDebits)}</th>
                                    <th scope="col">{currencyFormat.format(a.totalCredits)}</th>
                                    <th scope="col">{currencyFormat.format(a.endingBalance)}</th>
                                </tr>
                            </Fragment>

                        ))
                    }
                    {
                        generalLedger?.equity.map((a, index) => (
                            <Fragment key={"equity"+index}>
                                <tr>
                                    <th scope="col" colSpan={5}>{a.code}-{a.description}</th>
                                </tr>
                                <tr>
                                    <th scope="col" colSpan={4}>Starting Balance</th>
                                    <th scope="col">{currencyFormat.format(a.startingBalance)}</th>
                                </tr>
                                {
                                    a.journalEntries.map(j => (
                                        <tr key={uuidv4()}>
                                            <td scope="col">{j.date}</td>
                                            <td scope="col">{j.description}</td>
                                            <td scope="col">{currencyFormat.format(j.debit)}</td>
                                            <td scope="col">{currencyFormat.format(j.credit)}</td>
                                            <td scope="col">{currencyFormat.format(j.balance)}</td>
                                        </tr>
                                    ))
                                }
                                <tr>
                                    <th scope="col" colSpan={4}>Net Change</th>
                                    <th scope="col">{currencyFormat.format(a.netChange)}</th>
                                </tr>
                                <tr>
                                    <th scope="col" colSpan={2}></th>
                                    <th scope="col">{currencyFormat.format(a.totalDebits)}</th>
                                    <th scope="col">{currencyFormat.format(a.totalCredits)}</th>
                                    <th scope="col">{currencyFormat.format(a.endingBalance)}</th>
                                </tr>
                            </Fragment>

                        ))
                    }
                    {
                        generalLedger?.revenue.map((a, index) => (
                            <Fragment key={"revenue"+index}>
                                <tr>
                                    <th scope="col" colSpan={5}>{a.code}-{a.description}</th>
                                </tr>
                                <tr>
                                    <th scope="col" colSpan={4}>Starting Balance</th>
                                    <th scope="col">{currencyFormat.format(a.startingBalance)}</th>
                                </tr>
                                {
                                    a.journalEntries.map(j => (
                                        <tr key={uuidv4()}>
                                            <td scope="col">{j.date}</td>
                                            <td scope="col">{j.description}</td>
                                            <td scope="col">{currencyFormat.format(j.debit)}</td>
                                            <td scope="col">{currencyFormat.format(j.credit)}</td>
                                            <td scope="col">{currencyFormat.format(j.balance)}</td>
                                        </tr>
                                    ))
                                }
                                <tr>
                                    <th scope="col" colSpan={4}>Net Change</th>
                                    <th scope="col">{currencyFormat.format(a.netChange)}</th>
                                </tr>
                                <tr>
                                    <th scope="col" colSpan={2}></th>
                                    <th scope="col">{currencyFormat.format(a.totalDebits)}</th>
                                    <th scope="col">{currencyFormat.format(a.totalCredits)}</th>
                                    <th scope="col">{currencyFormat.format(a.endingBalance)}</th>
                                </tr>
                            </Fragment>

                        ))
                    }
                    {
                        generalLedger?.expenses.map((a, index) => (
                            <Fragment key={"expenses"+index}>
                                <tr>
                                    <th scope="col" colSpan={5}>{a.code}-{a.description}</th>
                                </tr>
                                <tr>
                                    <th scope="col" colSpan={4}>Starting Balance</th>
                                    <th scope="col">{currencyFormat.format(a.startingBalance)}</th>
                                </tr>
                                {
                                    a.journalEntries.map(j => (
                                        <tr key={uuidv4()}>
                                            <td scope="col">{j.date}</td>
                                            <td scope="col">{j.description}</td>
                                            <td scope="col">{currencyFormat.format(j.debit)}</td>
                                            <td scope="col">{currencyFormat.format(j.credit)}</td>
                                            <td scope="col">{currencyFormat.format(j.balance)}</td>
                                        </tr>
                                    ))
                                }
                                <tr>
                                    <th scope="col" colSpan={4}>Net Change</th>
                                    <th scope="col">{currencyFormat.format(a.netChange)}</th>
                                </tr>
                                <tr>
                                    <th scope="col" colSpan={2}></th>
                                    <th scope="col">{currencyFormat.format(a.totalDebits)}</th>
                                    <th scope="col">{currencyFormat.format(a.totalCredits)}</th>
                                    <th scope="col">{currencyFormat.format(a.endingBalance)}</th>
                                </tr>
                            </Fragment>

                        ))

                    }
                    <tr>
                        <th scope="col" colSpan={2}></th>
                        <th scope="col">{currencyFormat.format(generalLedger?.totalDebits || 0)}</th>
                        <th scope="col">{currencyFormat.format(generalLedger?.totalCredits || 0)}</th>
                        <th scope="col">{currencyFormat.format(generalLedger?.totalBalance || 0)}</th>
                    </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </PageLayout>
}

export default ViewPropertyGeneralLedger;