import * as React from "react";
import {useHistory, useParams} from "react-router";
import {FormEvent, useEffect, useState} from "react";
import FormFieldBuilder, {assignFormFieldValues} from "../../../Form/FieldBuilder/FormFieldBuilder";
import {ARAccount, Property} from "../../../../types";
import {getARAccountById} from "../../../../Api/AccountApi";
import Alert, {AlertType} from "../../../Alert";
import FrontDeskInstructionForm from "../Forms/FrontDeskInstructionForm";
import LoadingOverlay from "../../../LoadingOverlay";
import {getProperty} from "../../../../Api/PropertyApi";
import {createTenantFrontDeskInstruction} from "../../../../Api/FrontDeskInstructionsApi";

const AddFrontDeskInstruction: React.FunctionComponent = () => {

    enum PERMANENT {
        YES = "Yes", NO = "No"
    }

    const { propertyId, accountId } = useParams();
    const history = useHistory();

    const [loading, setLoading] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [error, setError] = useState<Error>();

    const [property, setProperty] = useState<Property>();
    const [account, setAccount] = useState<ARAccount>();

    const [unitName, setUnitName] = useState<string>("");
    const [comments, setComments] = useState<string>("");
    const [permanent, setPermanent] = useState<PERMANENT>(PERMANENT.NO);
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");

    assignFormFieldValues({field: FrontDeskInstructionForm.accountId, value: unitName, setValue: setUnitName});
    assignFormFieldValues({field: FrontDeskInstructionForm.comments, value: comments, setValue: setComments});
    assignFormFieldValues({field: FrontDeskInstructionForm.permanent, value: permanent, setValue: setPermanent});
    assignFormFieldValues({field: FrontDeskInstructionForm.startDate, value: startDate, setValue: setStartDate, disabledOverride: permanent === PERMANENT.YES, requiredOverride: permanent === PERMANENT.NO});
    assignFormFieldValues({field: FrontDeskInstructionForm.endDate, value: endDate, setValue: setEndDate, disabledOverride: permanent === PERMANENT.YES, requiredOverride: permanent === PERMANENT.NO});

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (isValid) {
            setLoading(true);
            createTenantFrontDeskInstruction(propertyId, {
                accountId,
                comments,
                permanent: permanent === PERMANENT.YES,
                startDate,
                endDate
            })
                .then(instruction => history.push({ pathname: "/p/m/tenant-services/front-desk/" + propertyId + "/accounts/" + accountId + "/instructions/" + instruction.uuid}))
                .catch(e => {
                    setError(e);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        const isUnitNameValid = unitName !== "";
        const isCommentValid = comments !== "";
        const isStartDateValid = permanent === PERMANENT.YES || startDate !== "";
        const isEndDateValid = permanent === PERMANENT.YES || startDate !== "";
        setIsValid(isUnitNameValid && isCommentValid && isStartDateValid && isEndDateValid);
    }, [unitName, comments, permanent, startDate, endDate])

    useEffect(() => {
        setUnitName(account?.unitName || "")
    }, [account])

    useEffect(() => {
        setLoading(true);
        Promise.all([
            getProperty(propertyId).then(data => data),
            getARAccountById(propertyId, accountId).then(data => data)
        ])
            .then(values => {
                setProperty(values[0]);
                setAccount(values[1]);
            })
            .catch(setError)
            .finally(() => setLoading(false));
    }, [propertyId, accountId])

    return (loading ? <LoadingOverlay show={loading} /> : <>
        <div className="row">
            <div className="col-md-12">
                {error && <Alert type={AlertType.DANGER} message={error.message} />}
                <div className="row">
                    <h3>Front Desk Information</h3>
                    <div className="col-12 mb-3">
                        <h5>{property?.name}</h5>
                        {property?.address.addressLine1}<br/>
                        {property?.address.addressLine2}<br hidden={(property?.address.addressLine2) !== undefined}/>
                        {property?.address.city}, {property?.address.stateCode} {property?.address.zip}
                    </div>
                </div>
            </div>
            <div className="col-md-9 widget">
                <div className="row p-1">
                    <h5>Add Instruction</h5>
                </div>
                <form onSubmit={onSubmit}>
                    <div className="row p-1">
                        <div className="col-md-4">
                            <div className="row d-grid gap-2">
                                <div className="col-12">
                                    <FormFieldBuilder field={FrontDeskInstructionForm.accountId} />
                                </div>
                                <div className="col-12">
                                    <FormFieldBuilder field={FrontDeskInstructionForm.permanent} selectOptions={FrontDeskInstructionForm.permanent.defaultSelectOptions}/>
                                </div>
                                <div className="col-12">
                                    <FormFieldBuilder field={FrontDeskInstructionForm.startDate} />
                                </div>
                                <div className="col-12">
                                    <FormFieldBuilder field={FrontDeskInstructionForm.endDate} />
                                </div>
                                <div className="col-12">
                                    <button className="btn btn-primary mt-3" type="submit" disabled={!isValid}>Submit</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <FormFieldBuilder field={FrontDeskInstructionForm.comments} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>)
}

export default AddFrontDeskInstruction;
