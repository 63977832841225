import {AmenityDay, AmenityIsOpen, SimpleTime} from "../../../Api/AmenityApi";
import {AmenityDayFormInterface} from "../Forms/AmenityDayForm";
import {AssignFormFieldValues, FormBuilderField, FormFieldType} from "../../Form/FieldBuilder/FormFieldBuilder";


export const copyAmenityDay = (day: AmenityDay | undefined, form: AmenityDayFormInterface) => {
    form.isOpen.setValue(day?.open ? AmenityIsOpen.YES : AmenityIsOpen.NO);
    form.openTime.setValue(simpleTimeToMilitaryTime(day?.openTime || {hour: 0, minute: 0}));
    form.closeTime.setValue(simpleTimeToMilitaryTime(day?.closeTime || {hour: 0, minute: 0}));
    form.minReservationMinutes.setValue(day?.minReservationMinutes.toString());
    form.maxReservationMinutes.setValue(day?.maxReservationMinutes.toString());
    form.timeslotBlockMinutes.setValue(day?.timeslotBlockMinutes.toString());
    form.cancelBeforeMinute.setValue(day?.cancelBeforeMinute.toString());
    form.basePrice.setValue(day?.basePrice.toFixed(2));
    form.pricePerMinute.setValue(day?.pricePerMinute.toFixed(2));
}

export const setAmenityDay = (day: AmenityDay | undefined, form: AmenityDayFormInterface) => {
    if (day) {
        day.open = form.isOpen.value === AmenityIsOpen.YES;
        day.openTime = parseStringToSimpleTime(form.openTime.value);
        day.closeTime = parseStringToSimpleTime(form.closeTime.value);
        day.minReservationMinutes = Number(form.minReservationMinutes.value);
        day.maxReservationMinutes = Number(form.maxReservationMinutes.value);
        day.timeslotBlockMinutes = Number(form.timeslotBlockMinutes.value);
        day.cancelBeforeMinute = Number(form.cancelBeforeMinute.value);
        day.basePrice = Number(form.basePrice.value)
        day.pricePerMinute = Number(form.pricePerMinute.value);
    }
}

export const assignFormFieldValues = (field: FormBuilderField, value: any, setValue: Function, required?: boolean, disabled?: boolean) => {
    field.value = value;
    field.setValue = setValue ? setValue : () => {};
    if (required !== undefined) {
        field.element.required = required;
    }
    if (disabled !== undefined) {
        field.disabled = disabled;
    }
}

export const simpleTimeToString = (time: SimpleTime): string => {
    let hour = time.hour;
    let minute = time.minute < 10 ? "0" + time.minute : time.minute;
    let amOrPm = "AM";
    if (hour >= 12) {
        amOrPm = "PM";
    }
    if (hour === 0 || hour === 24) {
        hour = 12
    }
    if (hour > 12 && hour < 24) {
        hour -= 12;
    }
    return hour + ":" + minute + " " + amOrPm;
}

export const simpleTimeToMilitaryTime = (time: SimpleTime): string => {
    let hour;
    let minute;

    if (time.hour === 0 || time.hour === 24) {
        hour = "00"
    }
    else if (time.hour < 10) {
        hour = "0" + time.hour;
    }
    else {
        hour = time.hour.toString();
    }

    if (time.minute === 0) {
        minute = "00";
    }
    else if (time.minute < 10) {
        minute = "0" + time.minute.toString();
    }
    else {
        minute = time.minute.toString();
    }
    return hour + ":" + minute;
}

export const parseStringToSimpleTime = (time: string): SimpleTime => {
    if (time.includes(":")) {
        const split1 = time.split(":");
        const split2 = split1[1].split(" ");
        let hour = Number(split1[0])
        let minute = Number(split2[0]);
        let amOrPm = split2[1];
        if (amOrPm === "PM" && hour > 12) {
            hour += 12;
        }
        return { hour, minute }
    }
    return { hour: 0, minute: 0 }
}
