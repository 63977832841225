import * as React from "react";
import { useHistory, useParams } from "react-router";
import { FormEvent, useEffect, useState } from "react";

import FormFieldBuilder, { SelectOptions } from "../../Form/FieldBuilder/FormFieldBuilder";
import { createCommunityLink } from "../../../Api/CommunityLinkApi";
import CommunityLinkForm from "../Forms/CommunityLinkForm";


const AddCommunityLink: React.FunctionComponent<{done: Function}> = ({done}) => {

    const { propertyUuid } = useParams();

    const [name, setName] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [category, setCategory] = useState<string>("");

    CommunityLinkForm.name.value = name;
    CommunityLinkForm.name.setValue = setName;
    CommunityLinkForm.url.value = url;
    CommunityLinkForm.url.setValue = setUrl;
    CommunityLinkForm.description.value = description;
    CommunityLinkForm.description.setValue = setDescription;
    CommunityLinkForm.category.value = category;
    CommunityLinkForm.category.setValue = setCategory;

    const clearFields = () => {
        setName("");
        setUrl("");
        setDescription("");
        setCategory("");
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        createCommunityLink(propertyUuid, {
            name: name,
            url: url,
            description: description,
            category: category
        })
            .then(() => clearFields())
            .then(() => done())
            
    }

    const onReset = (e: FormEvent) => {
        e.preventDefault();
        clearFields();
    }

    return (<>
        <div className="row widget bg-light form-container">
            <div className="col-sm-12 mb-3">
                <div className="row">
                    <h4>Add Community Link</h4>
                </div>
                <form onSubmit={onSubmit} onReset={onReset}>
                    <div className="row">
                        <div className="col-xxl-6 mb-3">
                            <FormFieldBuilder field={CommunityLinkForm.name} />
                            <FormFieldBuilder field={CommunityLinkForm.url} />
                        </div>
                        <div className="col-xxl-6 mb-3">
                            <FormFieldBuilder field={CommunityLinkForm.description} />
                            <FormFieldBuilder field={CommunityLinkForm.category} />
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-md-12 mb-3"}>
                            <div className="btn-group" role="btn-group">
                                <button type="reset" className="btn btn-outline-primary">Clear</button>
                                <button type="submit" className="btn btn-primary">Add</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </>)
}



export default AddCommunityLink